import {
  questionTypes,
  specialTypes,
  typeRequired,
} from "../constants/questions";

export const isTypeRequired = (type) => {
  return typeRequired.includes(type);
};

export const isAnswerEmpty = (type, answer) => {
  if (answer && !specialTypes.includes(type)) {
    return answer.length === 0;
  }
  let isEmpty = false;
  switch (type) {
    case questionTypes.TEXT:
      return answer.length === 0 || answer[0] === "";
    case questionTypes.IMAGE_COMMENT:
      return answer.length === 0 || answer[0] === "";
    case questionTypes.VIDEO_COMMENT:
      return answer.length === 0 || answer[0] === "";
    case questionTypes.IMAGE_STARRATINGCHOICE:
      Object.keys(answer[0]).forEach((el) => {
        if (answer[0][el] === 0) {
          isEmpty = true;
          return;
        }
      });
      return isEmpty;
    case questionTypes.VIDEO_STARRATINGCHOICE:
      Object.keys(answer[0]).forEach((el) => {
        if (answer[0][el] === 0) {
          isEmpty = true;
          return;
        }
      });
      return isEmpty;
    default:
      return false;
  }
};

export const getQuestionText = ({ type, text, options }) => {
  switch (type) {
    case questionTypes.NPS:
      const { npsOption } = options;
      return `How likely is it that you would recommend ${npsOption} to a friend or collegue?`;
    default:
      return text;
  }
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
