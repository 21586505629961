import React from "react";
import { getAgeRange, getQuestion } from "./CriteriaUtils";

const RatioDescription = ({ criterion, adminQuestions }) => {
  const option_ratio_object = [];

  criterion.selectedValues.forEach((selectedValue) => {
    if (selectedValue !== -1) {
      if (
        getQuestion(criterion.questionId, adminQuestions).questionObject
          .questionType == "age"
      ) {
        option_ratio_object.push(
          `${criterion.ratios[selectedValue]}% ${
            getAgeRange({
              start: getQuestion(criterion.questionId, adminQuestions)
                .questionObject.start,
              end: getQuestion(criterion.questionId, adminQuestions)
                .questionObject.end,
              interval: getQuestion(criterion.questionId, adminQuestions)
                .questionObject.interval,
            })[selectedValue].label
          }`
        );
      } else {
        option_ratio_object.push(
          `${criterion.ratios[selectedValue]}% ${
            getQuestion(criterion.questionId, adminQuestions).questionObject
              .options[selectedValue]
          }`
        );
      }
    }
  });

  const label = getQuestion(criterion.questionId, adminQuestions).clientField;

  return (
    !!option_ratio_object?.length && (
      <div
        style={{
          display: "flex",
          columnGap: "10px",
          fontSize: "0.89em",
          padding: "10px 0 0 0",
        }}
      >
        {label}: {option_ratio_object.join(", ")}
      </div>
    )
  );
};

export default RatioDescription;
