import { notification } from "antd";
import { set } from "dot-prop-immutable";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { completeSurvey } from "../../../../../actions/users";
import handleNetworkErrors from "../../../../../pages/user/constants/handleNetworkErrors";
import axios, { Routes } from "../../../../../services/API";
import {
  decrementPendingRequests,
  incrementPendingRequests,
  notify,
} from "../../../../../utils";
import { getScheduledSlotForUser } from "../../../utils/moderated/schedule";
import { getSurveyType } from "../../../utils/utils";
import Meeting from "./meeting/index";
import QuestionPopup from "./QuestionPopup/QuestionPopup";

//Need to refactor according to newer standards
function ModeratedSurvey({ survey, userId, surveyAccess, token, clientId }) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);

  const [slot, setSlot] = useState({});
  const [questionPopup, setQuestionPopup] = useState(false);
  const [question, setQuestion] = useState(null);
  const [showModeratedSurvey, setShowModeratedSurvey] = useState(false);
  const [triggerSubmitAnswer, setTriggerSubmitAnswer] = useState({
    trigger: true,
    data: {},
  });
  const [UID, setUID] = useState();

  const surveyType = getSurveyType(survey);

  const showQuestionPopup = (question) => {
    setQuestionPopup(true);
    setQuestion(question);
  };

  const setOpenUID = (data) => {
    setUID([data])
  }
  //dupliacte code. Fix needed. Duplicate in useSurveyEventHandlersFile
  const handleEndSurvey = async (surveyId, response) => {
    incrementPendingRequests();
    dispatch(completeSurvey(surveyId, response))
      .then(() => notify("success", "Success", "Survey completed"))
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  };

  
  const handleSubmitLiveQuestion = async (userId, answer, slot, survey) => {
    if(survey.surveyAccess){
      const { url, method } = Routes.user.answerOpenModeratedSurveyQuestions(
        UID,
        survey._id
        );
        
        const { data } = await axios[method](url, {
          data: {
            slotKey: survey.surveyAccess ? token : slot.surveyDate + "_" + slot.surveyTime,
            answer: answer,
          },
        });

    } else {

      const { url, method } = Routes.user.answerModeratedSurveyQuestions(
        userId,
        survey._id
        );
        
        const { data } = await axios[method](url, {
          data: {
            slotKey: survey.surveyAccess ? token : slot.surveyDate + "_" + slot.surveyTime,
            answer: answer,
          },
        });
      }
  };

  const onSubmitLiveQuestion = async (userId, answer, slot, survey) => {
    if (await handleSubmitLiveQuestion(userId, answer, slot, survey)) {
      try {
        await handleSubmitLiveQuestion(userId, answer, slot, survey);
        if(surveyAccess) {
          notification.success({ message: "Response Submitted Successfully" });
        } else {
        let userName = userId;
        if (profile.firstName && profile.lastName) {
          userName = profile.firstName + " " + profile.lastName;
        }

        notification.success({ message: "Response Submitted Successfully" });
        setTriggerSubmitAnswer({
          trigger: true,
          data: { message: userName + " answered the question" },
        });
      }
      } catch (err) {
        handleNetworkErrors(err);
      }
    }
  };

  useEffect(() => {
    const { surveyDate, surveyTime } = getScheduledSlotForUser(
      survey.moderatedSlots,
      userId
    );
    setSlot({ surveyDate, surveyTime });
    setShowModeratedSurvey(true);
  }, []);

  return (
    <>
      {showModeratedSurvey && (
        <>
          <Meeting
            surveyId={survey._id}
            date={ !surveyAccess? slot.surveyDate.replace(/\//g, "|") : undefined}
            time={ !surveyAccess? slot.surveyTime : undefined}
            token={ surveyAccess? token : undefined}
            surveyAccess = {surveyAccess}
            isClient={false}
            isOneToOne={!survey.setting}
            handleEndSurvey={handleEndSurvey}
            showQuestionPopup={showQuestionPopup}
            triggerSubmitAnswer={triggerSubmitAnswer}
            setTriggerSubmitAnswer={setTriggerSubmitAnswer}
            setOpenUID = {setOpenUID}
          />
          <QuestionPopup
            questions={[question]}
            isOpen={questionPopup}
            onClose={() => setQuestionPopup(false)}
            onDisqualification={() => {}}
            surveyType={surveyType}
            userId={userId}
            onSubmitLiveQuestion={(userId, answer) =>
              onSubmitLiveQuestion(userId, answer, slot, survey)
            }
          />
        </>
      )}
    </>
  );
}

export default ModeratedSurvey;
