// export const wordCloudTestData = {
//   analysedComments: [
//     {
//       sentiment: [
//         {
//           tag_name: "Positive",
//           tag_id: 122921383,
//           confidence: 0.996,
//         },
//       ],
//       wordCloud: [
//         {
//           text: "awesome label",
//           value: "0.952",
//           count: 1,
//         },
//         {
//           text: "random comments",
//           value: "0.952",
//           count: 1,
//         },
//         {
//           text: "color",
//           value: "0.476",
//           count: 1,
//         },
//         {
//           text: "label",
//           value: "0.476",
//           count: 2,
//         },
//       ],
//     },
//     {
//       sentiment: [
//         {
//           tag_name: "Positive",
//           tag_id: 122921383,
//           confidence: 0.922,
//         },
//       ],
//       wordCloud: [
//         {
//           text: "nice image",
//           value: "0.952",
//           count: 1,
//         },
//       ],
//     },
//     {
//       sentiment: [
//         {
//           tag_name: "Negative",
//           tag_id: 122921385,
//           confidence: 0.878,
//         },
//       ],
//       wordCloud: [
//         {
//           text: "lack of freedom",
//           value: "0.968",
//           count: 1,
//         },
//         {
//           text: "freedom of speech",
//           value: "0.968",
//           count: 1,
//         },
//         {
//           text: "blue box",
//           value: "0.645",
//           count: 1,
//         },
//         {
//           text: "color",
//           value: "0.645",
//           count: 2,
//         },
//         {
//           text: "blue sky",
//           value: "0.645",
//           count: 1,
//         },
//         {
//           text: "key",
//           value: "0.323",
//           count: 1,
//         },
//         {
//           text: "user",
//           value: "0.323",
//           count: 1,
//         },
//         {
//           text: "human",
//           value: "0.323",
//           count: 1,
//         },
//         {
//           text: "world",
//           value: "0.323",
//           count: 1,
//         },
//       ],
//     },
//     {
//       sentiment: [
//         {
//           tag_name: "Positive",
//           tag_id: 122921383,
//           confidence: 0.801,
//         },
//       ],
//       wordCloud: [
//         {
//           text: "random paragraph",
//           value: "0.988",
//           count: 5,
//         },
//         {
//           text: "number of paragraph",
//           value: "0.370",
//           count: 1,
//         },
//         {
//           text: "number of reason",
//           value: "0.370",
//           count: 1,
//         },
//         {
//           text: "next logical step",
//           value: "0.370",
//           count: 1,
//         },
//         {
//           text: "random paragraph generator",
//           value: "0.370",
//           count: 1,
//         },
//         {
//           text: "chosen number",
//           value: "0.247",
//           count: 1,
//         },
//         {
//           text: "random sentence",
//           value: "0.247",
//           count: 1,
//         },
//         {
//           text: "random word",
//           value: "0.247",
//           count: 1,
//         },
//         {
//           text: "rights place",
//           value: "0.247",
//           count: 1,
//         },
//         {
//           text: "everyone",
//           value: "0.123",
//           count: 1,
//         },
//       ],
//     },
//     {
//       sentiment: [
//         {
//           tag_name: "Neutral",
//           tag_id: 122921384,
//           confidence: 0.507,
//         },
//       ],
//       wordCloud: [
//         {
//           text: "writing project",
//           value: "0.952",
//           count: 1,
//         },
//         {
//           text: "random paragraph",
//           value: "0.952",
//           count: 1,
//         },
//         {
//           text: "productive way",
//           value: "0.952",
//           count: 1,
//         },
//         {
//           text: "original meaning",
//           value: "0.952",
//           count: 1,
//         },
//         {
//           text: "word",
//           value: "0.476",
//           count: 1,
//         },
//         {
//           text: "tools",
//           value: "0.476",
//           count: 1,
//         },
//         {
//           text: "writer",
//           value: "0.476",
//           count: 1,
//         },
//         {
//           text: "writing",
//           value: "0.476",
//           count: 3,
//         },
//         {
//           text: "intention",
//           value: "0.476",
//           count: 1,
//         },
//         {
//           text: "purpose",
//           value: "0.476",
//           count: 1,
//         },
//       ],
//     },
//     {
//       sentiment: [
//         {
//           tag_name: "Positive",
//           tag_id: 122921383,
//           confidence: 0.989,
//         },
//       ],
//       wordCloud: [
//         {
//           text: "block of text",
//           value: "0.968",
//           count: 1,
//         },
//         {
//           text: "free online tools",
//           value: "0.968",
//           count: 1,
//         },
//         {
//           text: "tools",
//           value: "0.645",
//           count: 3,
//         },
//         {
//           text: "great way",
//           value: "0.645",
//           count: 1,
//         },
//         {
//           text: "good way",
//           value: "0.645",
//           count: 1,
//         },
//         {
//           text: "writer",
//           value: "0.323",
//           count: 1,
//         },
//         {
//           text: "programming",
//           value: "0.323",
//           count: 1,
//         },
//         {
//           text: "project",
//           value: "0.323",
//           count: 1,
//         },
//         {
//           text: "programmer",
//           value: "0.323",
//           count: 1,
//         },
//       ],
//     },
//     {
//       sentiment: [
//         {
//           tag_name: "Negative",
//           tag_id: 122921385,
//           confidence: 0.994,
//         },
//       ],
//       wordCloud: [
//         {
//           text: "generator",
//           value: "0.968",
//           count: 3,
//         },
//         {
//           text: "paragraph",
//           value: "0.968",
//           count: 3,
//         },
//         {
//           text: "computer",
//           value: "0.645",
//           count: 2,
//         },
//         {
//           text: "many time",
//           value: "0.645",
//           count: 1,
//         },
//         {
//           text: "sense",
//           value: "0.323",
//           count: 1,
//         },
//         {
//           text: "human",
//           value: "0.323",
//           count: 1,
//         },
//         {
//           text: "time",
//           value: "0.323",
//           count: 2,
//         },
//       ],
//     },
//     {
//       sentiment: [
//         {
//           tag_name: "Neutral",
//           tag_id: 122921384,
//           confidence: 0.448,
//         },
//       ],
//       wordCloud: [
//         {
//           text: "sixth senses",
//           value: "0.952",
//           count: 1,
//         },
//         {
//           text: "feeling",
//           value: "0.476",
//           count: 2,
//         },
//         {
//           text: "back",
//           value: "0.476",
//           count: 1,
//         },
//         {
//           text: "neck",
//           value: "0.476",
//           count: 1,
//         },
//       ],
//     },
//     {
//       sentiment: [
//         {
//           tag_name: "Positive",
//           tag_id: 122921383,
//           confidence: 0.959,
//         },
//       ],
//       wordCloud: [
//         {
//           text: "much love",
//           value: "0.952",
//           count: 1,
//         },
//         {
//           text: "love",
//           value: "0.476",
//           count: 2,
//         },
//         {
//           text: "pie",
//           value: "0.476",
//           count: 1,
//         },
//         {
//           text: "day",
//           value: "0.476",
//           count: 1,
//         },
//         {
//           text: "friend",
//           value: "0.476",
//           count: 1,
//         },
//         {
//           text: "one",
//           value: "0.476",
//           count: 1,
//         },
//       ],
//     },
//     {
//       sentiment: [
//         {
//           tag_name: "Negative",
//           tag_id: 122921385,
//           confidence: 0.497,
//         },
//       ],
//       wordCloud: [
//         {
//           text: "carefree way",
//           value: "0.952",
//           count: 1,
//         },
//         {
//           text: "sound",
//           value: "0.476",
//           count: 1,
//         },
//         {
//           text: "boy",
//           value: "0.476",
//           count: 1,
//         },
//         {
//           text: "notice",
//           value: "0.476",
//           count: 1,
//         },
//         {
//           text: "ball",
//           value: "0.476",
//           count: 1,
//         },
//         {
//           text: "fate",
//           value: "0.476",
//           count: 1,
//         },
//         {
//           text: "step",
//           value: "0.476",
//           count: 1,
//         },
//         {
//           text: "street",
//           value: "0.476",
//           count: 1,
//         },
//         {
//           text: "road",
//           value: "0.476",
//           count: 1,
//         },
//       ],
//     },
//     {
//       sentiment: [
//         {
//           tag_name: "Positive",
//           tag_id: 122921383,
//           confidence: 0.73,
//         },
//       ],
//       wordCloud: [
//         {
//           text: "tom",
//           value: "0.952",
//           count: 2,
//         },
//         {
//           text: "devious plan",
//           value: "0.952",
//           count: 1,
//         },
//         {
//           text: "dad",
//           value: "0.476",
//           count: 1,
//         },
//         {
//           text: "lessons",
//           value: "0.476",
//           count: 1,
//         },
//         {
//           text: "hate",
//           value: "0.476",
//           count: 1,
//         },
//         {
//           text: "hater",
//           value: "0.476",
//           count: 1,
//         },
//       ],
//     },
//   ],
// };

export const wordCloudTestData = {
  analysedComments: [
    {
      sentiment: {
        Index: 0,
        Sentiment: "MIXED",
        SentimentScore: {
          Positive: 0.08229811489582062,
          Negative: 0.0034206025302410126,
          Neutral: 0.0031430176459252834,
          Mixed: 0.9111382961273193,
        },
      },
      wordCloud: [
        {
          text: "a random comment",
          value: 1,
        },
        {
          text: "the label",
          value: 1,
        },
        {
          text: "an awesome label",
          value: 1,
        },
        {
          text: "the color",
          value: 1,
        },
        {
          text: "the U",
          value: 1,
        },
      ],
    },
    {
      sentiment: {
        Index: 1,
        Sentiment: "POSITIVE",
        SentimentScore: {
          Positive: 0.9951318502426147,
          Negative: 0.000465965160401538,
          Neutral: 0.0024560377933084965,
          Mixed: 0.0019461987540125847,
        },
      },
      wordCloud: [],
    },
    {
      sentiment: {
        Index: 2,
        Sentiment: "NEGATIVE",
        SentimentScore: {
          Positive: 0.14600315690040588,
          Negative: 0.8173885941505432,
          Neutral: 0.0354309044778347,
          Mixed: 0.0011773464502766728,
        },
      },
      wordCloud: [
        {
          text: "This logo",
          value: 0.9999855756759644,
        },
        {
          text: "the state",
          value: 1,
        },
        {
          text: "the world",
          value: 1,
        },
        {
          text: "Its color",
          value: 0.9999991059303284,
        },
        {
          text: "the blue skies",
          value: 0.9999852776527405,
        },
        {
          text: "the letter",
          value: 0.9999040961265564,
        },
        {
          text: "#34",
          value: 0.9995771646499634,
        },
        {
          text: "#34",
          value: 0.9980799555778503,
        },
        {
          text: "color",
          value: 0.9999997615814209,
        },
        {
          text: "the blue box",
          value: 0.9999998807907104,
        },
        {
          text: "the lack",
          value: 0.9999998211860657,
        },
        {
          text: "freedom",
          value: 0.9999997615814209,
        },
        {
          text: "humans",
          value: 0.9999994039535522,
        },
        {
          text: "this earth",
          value: 0.999999463558197,
        },
        {
          text: "Freedom",
          value: 0.9999797344207764,
        },
        {
          text: "speech",
          value: 0.9999997615814209,
        },
        {
          text: "the key",
          value: 0.9999942779541016,
        },
        {
          text: "happiness",
          value: 0.9999096393585205,
        },
        {
          text: "any user",
          value: 0.9999998807907104,
        },
      ],
    },
    {
      sentiment: {
        Index: 3,
        Sentiment: "POSITIVE",
        SentimentScore: {
          Positive: 0.4442999064922333,
          Negative: 0.005591574590653181,
          Neutral: 0.38128167390823364,
          Mixed: 0.16882683336734772,
        },
      },
      wordCloud: [
        {
          text: "random paragraphs",
          value: 0.9999998807907104,
        },
        {
          text: "the right place",
          value: 0.9999999403953552,
        },
        {
          text: "a random word",
          value: 0.9999998211860657,
        },
        {
          text: "a random sentence",
          value: 0.9999999403953552,
        },
        {
          text: "the next logical step",
          value: 0.9999999403953552,
        },
        {
          text: "a random paragraph",
          value: 1,
        },
        {
          text: "the Random Paragraph Generator",
          value: 0.9999997615814209,
        },
        {
          text: "mind",
          value: 1,
        },
        {
          text: "The process",
          value: 0.9999997615814209,
        },
        {
          text: "the number",
          value: 1,
        },
        {
          text: "random paragraphs",
          value: 0.9999985694885254,
        },
        {
          text: "the button",
          value: 1,
        },
        {
          text: "Your chosen number",
          value: 0.999999463558197,
        },
        {
          text: "paragraphs",
          value: 0.9999997615814209,
        },
        {
          text: "a number",
          value: 1,
        },
        {
          text: "reasons",
          value: 0.9999997615814209,
        },
        {
          text: "random paragraphs",
          value: 0.9999999403953552,
        },
        {
          text: "A few examples",
          value: 0.9999975562095642,
        },
        {
          text: "some people",
          value: 1,
        },
        {
          text: "this generator",
          value: 0.9999999403953552,
        },
        {
          text: "the following paragraphs",
          value: 0.9999883770942688,
        },
      ],
    },
    {
      sentiment: {
        Index: 4,
        Sentiment: "POSITIVE",
        SentimentScore: {
          Positive: 0.8398491144180298,
          Negative: 0.06463920325040817,
          Neutral: 0.07277948409318924,
          Mixed: 0.022732166573405266,
        },
      },
      wordCloud: [
        {
          text: "Another productive way",
          value: 0.9999999403953552,
        },
        {
          text: "this tool",
          value: 0.9999997615814209,
        },
        {
          text: "a daily writing routine",
          value: 0.9999998807907104,
        },
        {
          text: "One way",
          value: 0.9999999403953552,
        },
        {
          text: "a random paragraph",
          value: 1,
        },
        {
          text: "the intention",
          value: 1,
        },
        {
          text: "the original meaning",
          value: 0.999999463558197,
        },
        {
          text: "The purpose",
          value: 0.9999694228172302,
        },
        {
          text: "the writing",
          value: 1,
        },
        {
          text: "the writer",
          value: 0.9999997615814209,
        },
        {
          text: "their day",
          value: 0.9999971985816956,
        },
        {
          text: "writing projects",
          value: 0.8479079008102417,
        },
        {
          text: "words",
          value: 0.9999994039535522,
        },
        {
          text: "their fingers",
          value: 0.9999999403953552,
        },
      ],
    },
    {
      sentiment: {
        Index: 5,
        Sentiment: "POSITIVE",
        SentimentScore: {
          Positive: 0.9969587326049805,
          Negative: 0.00019932868599426,
          Neutral: 0.0027231546118855476,
          Mixed: 0.00011880663805641234,
        },
      },
      wordCloud: [
        {
          text: "only writers",
          value: 0.9005135893821716,
        },
        {
          text: "this free online tool",
          value: 1,
        },
        {
          text: "a programmer",
          value: 0.9999999403953552,
        },
        {
          text: "a project",
          value: 1,
        },
        {
          text: "blocks",
          value: 0.9999996423721313,
        },
        {
          text: "text",
          value: 1,
        },
        {
          text: "this tool",
          value: 0.9999995827674866,
        },
        {
          text: "a great way",
          value: 0.9999998211860657,
        },
        {
          text: "a good way",
          value: 0.9999998211860657,
        },
        {
          text: "your programming",
          value: 1,
        },
        {
          text: "the tool",
          value: 0.9999999403953552,
        },
      ],
    },
    {
      sentiment: {
        Index: 6,
        Sentiment: "NEGATIVE",
        SentimentScore: {
          Positive: 0.0028258319944143295,
          Negative: 0.9888590574264526,
          Neutral: 0.005087283905595541,
          Mixed: 0.0032278045546263456,
        },
      },
      wordCloud: [
        {
          text: "the paragraphs",
          value: 0.9999881982803345,
        },
        {
          text: "the generator",
          value: 1,
        },
        {
          text: "humans",
          value: 0.999977707862854,
        },
        {
          text: "computers",
          value: 0.9947909116744995,
        },
        {
          text: "this generator",
          value: 1,
        },
        {
          text: "computers",
          value: 0.999998927116394,
        },
        {
          text: "the paragraphs",
          value: 0.9999998211860657,
        },
        {
          text: "many times",
          value: 0.9999053478240967,
        },
        {
          text: "any sense",
          value: 1,
        },
        {
          text: "the time",
          value: 1,
        },
        {
          text: "paragraphs",
          value: 0.9996434450149536,
        },
        {
          text: "this generator",
          value: 0.9999998807907104,
        },
        {
          text: "the best",
          value: 0.9985896944999695,
        },
      ],
    },
    {
      sentiment: {
        Index: 7,
        Sentiment: "MIXED",
        SentimentScore: {
          Positive: 0.036955174058675766,
          Negative: 0.26187363266944885,
          Neutral: 0.1907292753458023,
          Mixed: 0.5104419589042664,
        },
      },
      wordCloud: [
        {
          text: "that tingly feeling",
          value: 0.8999176025390625,
        },
        {
          text: "the back",
          value: 0.9999997615814209,
        },
        {
          text: "your neck",
          value: 0.9999992847442627,
        },
        {
          text: "that feeling",
          value: 0.9999839663505554,
        },
        {
          text: "sixth senses",
          value: 0.9999998807907104,
        },
      ],
    },
    {
      sentiment: {
        Index: 8,
        Sentiment: "POSITIVE",
        SentimentScore: {
          Positive: 0.6630426645278931,
          Negative: 0.013157353736460209,
          Neutral: 0.20429331064224243,
          Mixed: 0.11950665712356567,
        },
      },
      wordCloud: [
        {
          text: "Love",
          value: 0.9999990463256836,
        },
        {
          text: "pie",
          value: 0.9999109506607056,
        },
        {
          text: "all your friends",
          value: 0.9989756345748901,
        },
        {
          text: "loved ones",
          value: 0.8807447552680969,
        },
        {
          text: "how much",
          value: 0.9871357083320618,
        },
        {
          text: "one day",
          value: 0.9999623894691467,
        },
      ],
    },
    {
      sentiment: {
        Index: 9,
        Sentiment: "NEUTRAL",
        SentimentScore: {
          Positive: 0.18264415860176086,
          Negative: 0.1501716822385788,
          Neutral: 0.6283667087554932,
          Mixed: 0.038817450404167175,
        },
      },
      wordCloud: [
        {
          text: "The boy",
          value: 1,
        },
        {
          text: "the street",
          value: 1,
        },
        {
          text: "a carefree way",
          value: 1,
        },
        {
          text: "notice",
          value: 1,
        },
        {
          text: "the sound",
          value: 1,
        },
        {
          text: "the car",
          value: 1,
        },
        {
          text: "his ball",
          value: 1,
        },
        {
          text: "the road",
          value: 1,
        },
        {
          text: "a step",
          value: 1,
        },
        {
          text: "his fate",
          value: 1,
        },
      ],
    },
    {
      sentiment: {
        Index: 10,
        Sentiment: "NEGATIVE",
        SentimentScore: {
          Positive: 0.0617872029542923,
          Negative: 0.41322067379951477,
          Neutral: 0.19016407430171967,
          Mixed: 0.3348279893398285,
        },
      },
      wordCloud: [
        {
          text: "the haters",
          value: 0.9999998211860657,
        },
        {
          text: "the lesson",
          value: 1,
        },
        {
          text: "Tom",
          value: 1,
        },
        {
          text: "dad",
          value: 1,
        },
        {
          text: "Tom",
          value: 1,
        },
        {
          text: "their hate",
          value: 0.999999463558197,
        },
        {
          text: "his devious plan",
          value: 0.9999907612800598,
        },
      ],
    },
  ],
};
