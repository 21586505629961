import React, { useState } from "react";
import styles from "./ImageAssociationChoices.module.css";
import c from "classnames/bind";
import {
  CheckOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Input, Tooltip, Upload, message, Button, Spin } from "antd";
import axios, { Routes } from "../../../../../services/API";
import { GetFilename } from "../../../../../constants";

const cx = c.bind(styles);

const ImageAssociationChoices = (props) => {
  // Props
  const {
    dragHandleProps,
    choiceNumber,
    choice,
    isFilled,
    addChoice,
    removeChoice,
    totalChoices,
    setValue,
  } = props;
  const [uploading, setUploading] = useState(false);

  const uploadProps = {
    accept: ".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.jif,.JIF,.gif,.GIF",
    customRequest: async function ({ file, onError }) {
      const userId = localStorage.getItem("client");
      const { name, type } = file;
      setUploading(true);
      const { data } = await axios({
        ...Routes.client.uploadFile(userId),
        data: { data: { name, type } },
      });
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", data.signedRequest);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            choice.imageData = data.url;
            setValue({ ...choice, imageData: data.url });
            message.success(`${file.name} file uploaded successfully`);
            setUploading(false);
          } else {
            onError();
            message.error(`${file.name} file upload failed.`);
            setUploading(false);
          }
        }
      };
      xhr.send(file);
    },
  };

  return (
    <div className={cx("grid-container")} style={{ marginBottom: 5 }}>
      <div className={cx("choiceNumber")}>
        <div
          style={{ margin: "auto", height: 20, width: 20, textAlign: "center" }}
        >
          <span className="choice-numberCircle">{choiceNumber}</span>
          <MenuOutlined className={cx("menu-outlined")} {...dragHandleProps} />
        </div>
      </div>
      <div className={cx("fields")}>
        <div className={cx("inputFields")}>
          <div className={cx("firstField")}>
            <Input
              size="large"
              placeholder="No Image Uploaded"
              style={{ borderRadius: 3 }}
              value={GetFilename(choice.imageData, 10)}
              disabled
              onChange={(event) =>
                setValue({ ...choice, imageData: event.target.value })
              }
              suffix={
                isFilled.imageData ? (
                  <CheckOutlined style={{ color: "green" }} />
                ) : (
                  <Tooltip title="You need to fill this option!">
                    <InfoCircleOutlined style={{ color: "#ff9480" }} />
                  </Tooltip>
                )
              }
              className="input-border"
            />
            <Upload
              className={cx("uploadButton")}
              {...uploadProps}
              showUploadList={false}
            >
              <Button style={{ height: "100%" }}>
                <Spin style={{ background: "none" }} spinning={uploading}>
                  Upload Image
                </Spin>
              </Button>
            </Upload>
          </div>
          <div className={cx("secondField")}>
            <Input
              size="large"
              placeholder="Association"
              style={{ borderRadius: 3 }}
              value={choice.association}
              onChange={(event) =>
                setValue({ ...choice, association: event.target.value })
              }
              suffix={
                isFilled.association ? (
                  <CheckOutlined style={{ color: "green" }} />
                ) : (
                  <Tooltip title="You need to fill this option!">
                    <InfoCircleOutlined style={{ color: "#ff9480" }} />
                  </Tooltip>
                )
              }
              className="input-border"
            />
          </div>
        </div>
        <div
          className={cx("controlFields")}
          style={{ margin: "auto 2px", minWidth: 50 }}
        >
          {
            <div className={cx("controls")} style={{ margin: "auto 2px" }}>
              <PlusCircleOutlined
                onClick={() => addChoice(choiceNumber)}
                className="action-icon"
              />
              {totalChoices > 2 && (
                <MinusCircleOutlined
                  className="action-icon"
                  onClick={() => removeChoice(choiceNumber)}
                />
              )}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default ImageAssociationChoices;
