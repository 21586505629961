import React, { useState } from "react";
import { SketchPicker } from "react-color";

const ColorPicker = ({ colorAttr, setColor, colorNumber }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  let styles = {
    color: {
      width: "auto",
      height: "100%",
      borderRadius: "2px",
      background: `rgba(${colorAttr.r}, ${colorAttr.g}, ${colorAttr.b}, ${colorAttr.a})`,
    },
    swatch: {
      padding: "5px",
      background: "#fff",
      borderRadius: "1px",
      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
      display: "inline-block",
      cursor: "pointer",
      width: "60px",
      height: "60px",
    },
    popover: {
      position: "absolute",
      zIndex: "2",
    },
    cover: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  };

  const handleClick = () => {
    setDisplayColorPicker(true);
  };

  const handleClose = () => {
    // setColor(color, key)
    // setColor({
    //   displayColorPicker: false,
    //   color: colorAttr.color,
    // });
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    setColor(colorNumber, color.rgb);
  };

  return (
    <div style={{ padding: "10px", position: "relative" }}>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={colorAttr} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
