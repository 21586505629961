import React, { useCallback, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import Topbar from "../../components/topbar/Topbar";
import Sidenav from "../../components/client/sidenav/Sidenav";
import DraftForms from "./draft/DraftForms";
import Project from "./project/Project";
import NewSurvey from "./new-survey/NewSurvey";
import PublishedForms from "./published-survey/PublishedForms";
import Profile from "../user/profile/Profile";
import Settings from "../user/settings/Settings";
import History from "../user/history/History";
import Result from "./result";
import AddBalance from "./account";
import AccountModal from "./AccountModal";
import { useDispatch, useSelector } from "react-redux";
import {
  decrementPendingRequests,
  incrementPendingRequests,
  notify,
} from "../../utils";
import {
  updateSurveysAction,
  generateNewSurveyAction,
  deleteSurveyAction,
  getSurveyAction,
  updateSurveyAction,
  copySurveyAction,
  updateSurveysFromStoreAction,
  getProfile,
  editProfile,
  sendEmail,
  sendOtp,
  verifyOtp,
  createAccount,
  updateAccountName as updateAccountNameAction,
  createProjectAction,
  getProjectAction,
} from "../../actions/client";
import handleNetworkErrors from "./handleNetworkErrors";
import { ACCESS_LEVEL } from "../../constants";
import Requests from "./requests/Requests";

const Client = () => {
  const history = useHistory();
  const surveys = useSelector((state) => state.client.surveys);
  const profile = useSelector((state) => state.client.profile);
  const dispatch = useDispatch();

  const isAdmin = profile?.accessLevel === ACCESS_LEVEL.admin;

  const generateNewSurvey = useCallback(
    (projectId) => {
      incrementPendingRequests();
      dispatch(generateNewSurveyAction(projectId))
        .then((id) => {
          setTimeout(() => history.push(`/client/new-survey/${id}`), 5);
        })
        .catch((err) => {
          if (err.request || err.response) handleNetworkErrors(err);
        })
        .finally(() => decrementPendingRequests());
      // eslint-disable-next-line
    },
    [dispatch]
  );

  const updateSurveys = useCallback(() => {
    incrementPendingRequests();
    dispatch(updateSurveysAction())
      .then()
      .catch((err) => {
        if (err.request || err.response) handleNetworkErrors(err);
      })
      .finally(() => decrementPendingRequests());
  }, [dispatch]);

  const updateSurvey = useCallback(
    (survey) => {
      dispatch(updateSurveyAction(survey));
    },
    [dispatch]
  );

  const deleteSurvey = useCallback(
    (surveyId) => {
      incrementPendingRequests();
      dispatch(deleteSurveyAction(surveyId))
        .then(() => notify("success", "Success", "Survey deleted"))
        .catch((err) => {
          if (err.request || err.response) handleNetworkErrors(err);
        })
        .finally(() => decrementPendingRequests());
    },
    [dispatch]
  );

  const getSurvey = useCallback(
    async (id) => {
      return dispatch(getSurveyAction(id));
    },
    [dispatch]
  );

  const copySurvey = useCallback(
    (survey, projectId) => {
      incrementPendingRequests();
      dispatch(copySurveyAction(survey, projectId)).then(() => {
        decrementPendingRequests();
        dispatch(updateSurveysAction());
        notify("success", "Success", "Survey copied");
      });
    },
    [dispatch]
  );

  const newSurveyCleanUp = useCallback(() => {
    dispatch(updateSurveysFromStoreAction()).catch((err) => {
      console.log(err);
      if (err.request || err.response) handleNetworkErrors(err);
    });
  }, [dispatch]);

  const handleEditProfile = (values) => {
    incrementPendingRequests();
    dispatch(editProfile(values))
      .then(() => notify("success", "Success", "Profile edited"))
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  };

  const updateAccountName = async (name) => {
    incrementPendingRequests();
    try {
      await dispatch(
        updateAccountNameAction({
          accountId: profile?.account?._id,
          accountName: name,
        })
      );
    } catch (err) {
      notify("error", "Error", "Failed to update account name");
    } finally {
      decrementPendingRequests();
    }
  };

  const handleSendEmail = async (email) => {
    incrementPendingRequests();
    dispatch(sendEmail(email))
      .then(() =>
        notify(
          "success",
          "Email sent!",
          "Please click on the link sent to your email to verify it."
        )
      )
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  };

  const handleSendOtp = (mobileNumber) => {
    return new Promise((resolve, reject) => {
      incrementPendingRequests();
      dispatch(sendOtp(mobileNumber))
        .then(() => {
          notify(
            "success",
            "OTP sent!",
            "Please verify the OTP sent on your mobile"
          );
          resolve();
        })
        .catch((ex) => {
          notify("error", "Error", ex);
          reject();
        })
        .finally(() => decrementPendingRequests());
    });
  };

  const handleCreateAccount = (accountName) => {
    return new Promise((resolve, reject) => {
      incrementPendingRequests();
      dispatch(createAccount({ clientId: profile._id, accountName }))
        .then(() => {
          notify("success", "Account Created", "Account created successfully");
          return dispatch(getProfile());
        })
        .then(() => {
          resolve();
        })
        .catch((ex) => {
          notify("error", "Error", ex);
          reject();
        })
        .finally(() => decrementPendingRequests());
    });
  };

  const handleVerifyOtp = (value, mobileNumber) => {
    return new Promise((resolve, reject) => {
      incrementPendingRequests();
      dispatch(verifyOtp(mobileNumber, value))
        .then(() => {
          notify("success", "Success", "OTP Verified");
          resolve();
        })
        .catch((ex) => {
          notify("error", "Error", "Wrong OTP. Please enter the correct OTP.");
          reject();
        })
        .finally(() => decrementPendingRequests());
    });
  };

  const createProject = async (projectName) => {
    try {
      incrementPendingRequests();
      const project = await dispatch(createProjectAction(projectName));
      setTimeout(() => {
        history.push(`/client/project/${project._id}`);
      }, 5);
      notify("success", "Success", "Project created successfully");
    } catch (err) {
      notify("error", "Error", err.message);
    } finally {
      decrementPendingRequests();
    }
  };

  const getProject = useCallback(
    async (projectId) => await dispatch(getProjectAction(projectId)),
    [dispatch]
  );

  useEffect(() => {
    incrementPendingRequests();
    dispatch(updateSurveysAction())
      .then()
      .catch((err) => {
        if (err.request || err.response) handleNetworkErrors(err);
      })
      .finally(() => decrementPendingRequests());
  }, [dispatch]);

  useEffect(() => {
    incrementPendingRequests();
    dispatch(getProfile())
      .catch((err) => notify("error", "Error", err))
      .finally(() => decrementPendingRequests());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Topbar>
        <Sidenav drawer isAdmin={isAdmin} />
      </Topbar>
      <Sidenav isAdmin={isAdmin} />
      <Switch>
        <Route
          exact
          path="/client"
          component={(props) => (
            <Project
              createProject={createProject}
              profile={profile ? profile : {}}
            />
          )}
        />
        <Route
          path="/client/project/:projectId"
          component={(props) => (
            <DraftForms
              surveys={surveys}
              generateNewSurvey={generateNewSurvey}
              updateSurveys={updateSurveys}
              deleteSurvey={deleteSurvey}
              copySurvey={copySurvey}
              profile={profile ? profile : {}}
              getProject={getProject}
            />
          )}
        />
        <Route
          exact
          path="/client/published"
          component={(props) => (
            <PublishedForms
              surveys={surveys.filter((s) => s.isPublished)}
              updateSurveys={updateSurveys}
              {...props}
              profile={profile ? profile : {}}
            />
          )}
        />
        <Route
          exact
          path="/client/results"
          component={(props) => <Result surveys={surveys} {...props} />}
        />
        <Route exact path="/client/profile">
          <Profile
            usedForClient
            profile={profile}
            handleEditProfile={handleEditProfile}
            handleSendEmail={handleSendEmail}
            handleSendOtp={handleSendOtp}
            handleVerifyOtp={handleVerifyOtp}
          />
        </Route>
        <Route exact path="/client/requests">
          <Requests accountId={profile?.account?._id} />
        </Route>
        <Route exact path="/client/history">
          <History usedForClient profile={profile} />
        </Route>
        <Route exact path="/client/settings">
          <Settings
            usedForClient
            profile={profile}
            handleEditProfile={handleEditProfile}
            updateAccountName={updateAccountName}
          />
        </Route>
        <Route exact path="/client/addBalance">
          <AddBalance />
        </Route>
        <Route
          path="/client/new-survey/:formId"
          component={(props) => (
            <NewSurvey
              updateSurvey={updateSurvey}
              updateSurveys={updateSurveys}
              getSurvey={getSurvey}
              profile={profile}
              newSurveyCleanUp={newSurveyCleanUp}
              {...props}
            />
          )}
        />
      </Switch>
      {!!profile && !profile.account && (
        <AccountModal open={!profile.account} onSave={handleCreateAccount} />
      )}
    </React.Fragment>
  );
};

export default Client;
