import { Button, Space } from "antd";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Input } from "antd";
import ReactToPrint from "react-to-print";
import {
  dataColumns,
  basicStatisticsColumns,
  starTableColumns,
  starTableData,
  sliderDataColumns,
  npsDataColumns,
  calculatePercentage,
  TableCell,
  npsStatsColumns,
} from "../../constants/tableUtility";
import Dropdown from "../../../../../components/dropdown/dropdown";
import FilterCheckbox from "../../../../../components/filter-checkbox/FilterCheckbox";
import TabBar from "../../../../../components/tabbar/TabBar";
import ColorPicker from "../../../../../components/ColorPicker";
import FlexRow from "../../../../../components/flex-row";
import CreateChart from "../../../../../components/CreateChart/CreateChart";
import { COLOR, graphConfigTemplate } from "../../../../../constants";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import * as barIcons from "../../icons/index";
import {
  chartCheckboxStyle,
  greyButtonStyle,
  dullGreenButtonStyle,
  greyLabelStyle,
  darkBlueButtonStyle,
} from "../../constants/styles";
import ButtonToggle from "../../../../../components/ButtonToggle";
import styles from "./QuestionBox.module.css";
import c from "classnames/bind";
import { min, max, mean, median, std, typeOf } from "mathjs";
import ResponsiveTable from "../../../../../components/responsive-table/ResponsiveTable";
import StarRatingElement from "../../../../../components/starRating/StarRating";
import GaugeMeter from "../../../../../components/gaugeMeter/GaugeMeter";
import {
  questionNotation,
  questionTypes,
} from "../../../../../components/constants/questionType";
import Collapse from "@material-ui/core/Collapse";
import { wordCloudTestData } from "../../constants/wordCloudTestData";
import { npsValues } from "../../constants/npsValues";
import WordCloud from "../../../../../components/wordcloud/WordCloud";
import SentimentGraph from "../../../../../components/sentiment-graph/SentimentGraph";
const cx = c.bind(styles);

// let graphConfig = {};

const tableTypes = {
  type1: [
    "multiChoices",
    "imageMultiChoices",
    "videoMultiChoices",
    "checkBoxes",
    "imageCheckBoxes",
    "videoCheckBoxes",
  ],
  type2: [
    "stars",
    "imageStarRatingChoices",
    "videoStarRatingChoices",
    "dropDown",
    "likert",
    "ranking",
    "association",
    "imageRanking",
    "videoRanking",
    "imageAssociation",
    "videoAssociation",
  ],
  type3: ["slider"],
  type4: ["netPromotorScore"],
};

export const graphTypes = {
  type1: [
    "questionInstruction",
    "imageInstruction",
    "videoInstruction",
    "textABTest",
    "imageABTest",
    "videoABTest",
    "adAnalysis",
  ],
  type2: ["text", "imageComment", "videoComment"],
  type3: ["multiChoices", "imageMultiChoices", "videoMultiChoices"],
  type4: ["checkBoxes", "imageCheckBoxes", "videoCheckBoxes"],
  type5: [
    "stars",
    "imageStarRatingChoices",
    "videoStarRatingChoices",
    "likert",
    "dropDown",
  ],
  type6: ["slider"],
  type7: ["netPromotorScore"],
  type8: ["ranking", "imageRanking", "videoRanking"],
  type9: ["association", "imageAssociation", "videoAssociation"],
};

const setChartTypeInit = {
  type1: {
    show: [true, true, true, true],
    chartDepth: true,
    axisScale: true,
    percentage: [true, true, true, true, true, true, true, true],
    absolute: [true, true, true, true, true, true, true, true],
    weightedAverage: [true, true, true, true, true, true, true],
  },
  type2: {
    show: [true, true, true, false],
    chartDepth: true,
    axisScale: true,
    percentage: [true, true, true, true, true, true, true, true],
    absolute: [true, true, true, true, true, true, true, true],
    weightedAverage: [true, true, true, true, true, true, true, true],
  },
  type3: {
    show: [false, false, false, false],
    chartDepth: false,
    axisScale: false,
    percentage: [false, false, false, false, false, false, false, false],
    absolute: [false, false, false, false, false, false, false, false],
    weightedAverage: [false, false, true, true, true, true, true, true],
  },
  type4: {
    show: [false, false, false, false],
    chartDepth: true,
    axisScale: false,
    percentage: [false, false, true, true, true, true, false, false],
    absolute: [false, false, false, false, true, true, false, false],
    weightedAverage: [true, true, true, true, true, true, true, true],
  },
  type5: {
    show: [false, false, false, false],
    chartDepth: true,
    axisScale: false,
    percentage: [false, false, false, false, false, false, true, true],
    absolute: [false, false, false, false, true, true, true, true],
    weightedAverage: [true, true, true, true, true, true, true, true],
  },
  type6: {
    show: [false, false, false, false],
    chartDepth: true,
    axisScale: true, //question.sliderType = percentage then percentage or absolute
    percentage: [false, false, true, true, true, true, false, true],
    absolute: [false, false, true, true, true, true, false, true],
    weightedAverage: [true, true, true, true, true, true, true, true],
  },
  type7: {
    show: [false, false, false, false],
    chartDepth: true,
    axisScale: false,
    percentage: [false, false, false, false, false, false, true, true],
    absolute: [false, false, false, false, true, true, false, false],
    weightedAverage: [true, true, true, true, true, true, true, true],
  },
  type8: {
    show: [false, false, false, false],
    chartDepth: false,
    axisScale: false,
    percentage: [false, false, false, false, true, true, false, false],
    absolute: [false, false, false, false, true, true, false, false],
    weightedAverage: [false, false, true, true, true, true, false, false],
  },
  type9: {
    show: [false, false, false, false],
    chartDepth: false,
    axisScale: false,
    percentage: [false, false, false, false, true, true, false, false],
    absolute: [false, false, false, false, true, true, false, false],
    weightedAverage: [false, false, false, false, true, true, false, false],
  },
};

const initChartAttr = {
  options: [
    {
      label: "bar",
      value: "bar",
      icon: barIcons.barchart,
    },
    {
      label: "horizontalBar",
      value: "horizontalBar",
      icon: barIcons.barcharth,
    },
    {
      label: "stackedBar",
      value: "stackedBar",
      icon: barIcons.barh,
    },
    {
      label: "horizontalStackedBar",
      value: "horizontalStackedBar",
      icon: barIcons.barhh,
    },
    {
      label: "pie",
      value: "pie",
      icon: barIcons.pie,
    },
    {
      label: "doughnut",
      value: "doughnut",
      icon: barIcons.pieh,
    },
    {
      label: "line",
      value: "line",
      icon: barIcons.line,
    },
    {
      label: "lineFill",
      value: "lineFill",
      icon: barIcons.lineh,
    },
  ],
};

const QuestionBox = ({
  questionResponses,
  setQuestion,
  chartAttributes,
  setChartAttributes,
  question,
  answered,
  questionNumber,
  axisScale,
  setAxisScale,
  decimalPlaces,
  setDecimalPlaces,
  depth,
  setDepth,
  colors,
  setColors,
  chartType,
  setChartType,
  keyInfo,
  onSaveOptions,
  cloneConfiguration,
  customize,
  setCustomize,
  criteriaData,
  compareMode,
  compareChartTitle,
  setCompareChartTitle,
  graphTitle,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 450px)" });

  const [graphTab, setGraphTab] = useState("1");
  const [showDataTable, setShowDataTable] = useState(false);
  const [showStatisticsTable, setShowStatisticsTable] = useState(false);
  const [chartVisibility, setChartVisibility] = useState(false);
  const [selectedChart, setSelectedChart] = useState(1);
  const [graphConfig, setGraphConfig] = useState(null);
  const questionComponentRef = useRef(null);
  const [print, setPrint] = useState(false);

  let configurationType = null;
  Object.keys(graphTypes).map((key) => {
    if (graphTypes[key].indexOf(question.type) > -1) {
      configurationType = key;
    }
  });

  const chartSettings = setChartTypeInit[configurationType];

  const [tableColumns, setTableColumns] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [statsColumns, setStatsColumns] = useState(null);
  const [statsData, setStatsData] = useState(null);

  const [textAnalysis, setTextAnalysis] = useState(false);

  const [weightedAverage, setWeightedAverage] = useState(null);

  let stacked = false;
  let totalResponse = null;
  console.log(graphTypes, setChartTypeInit);
  const [totalColors, setTotalColors] = useState(10);
  const [disabledCustomize, setDisabledCustomize] = useState(false);
  const [chartTitle, setChartTitle] = useState("");
  const [wordCloud, setWordCloud] = useState(null);
  const [sentimentValues, setSentimentValues] = useState(null);

  const [defaultNPS, setDefaultNPS] = useState(0);

  useEffect(() => {
    if (answered > 0) {
      setGraphConfig({
        ...graphConfigTemplate,
        options: { ...graphConfigTemplate.options, plugins: {} },
      });
      setGraphTab("1");
      // setChartAttributes(initChartAttributes);
      setTableData(null);
      setStatsData(null);
      countWeightedAverage();
      setChartTitle("");
      setTextAnalysis(false);
      if (
        graphTypes.type1.indexOf(question.type) > -1 ||
        graphTypes.type2.indexOf(question.type) > -1
      ) {
        setDisabledCustomize(true);
      } else {
        setGraphInit("bar");
        setDisabledCustomize(false);
      }
      if (
        !(
          tableTypes.type1.indexOf(question.type) > -1 ||
          tableTypes.type2.indexOf(question.type) > -1 ||
          tableTypes.type3.indexOf(question.type) > -1 ||
          tableTypes.type4.indexOf(question.type) > -1
        )
      ) {
        setShowStatisticsTable(false);
        setShowDataTable(false);
      }
    }
  }, [question]);

  useEffect(() => {
    setWordCloud(null);
    prepareWordCloud();
  }, [textAnalysis]);

  const prepareWordCloud = async () => {
    if (graphTypes.type2.indexOf(question.type) > -1) {
      try {
        // const wordCloudReport = await getWordCloudAnalysis(question.comments);
        const wordCloudReport = wordCloudTestData;
        console.log("input comments:", question.comments);
        console.log("Report 1:", wordCloudReport);
        let words = {};
        let sentimentValues = [];
        const analysedComments = question.comments.map((commentData, index) => {
          if (commentData[1] && commentData[1].analysedComments?.[0]) {
            return commentData[1].analysedComments[0];
          } else {
            return null;
          }
        });
        console.log("analysedComment", analysedComments);
        analysedComments.map((report, index) => {
          if (report) {
            report.wordCloud.map((wordReport, index) => {
              if (Object.keys(words).indexOf(wordReport.text) > -1) {
                words[wordReport.text] += wordReport.value;
              } else {
                words[wordReport.text] = wordReport.value;
              }
            });
            sentimentValues.push(report.sentiment);
          }
        });
        let wordCloud = [];
        Object.keys(words).map((word, index) => {
          wordCloud.push({
            text: word,
            value: words[word],
          });
        });
        setWordCloud(wordCloud);
        setSentimentValues(sentimentValues);
      } catch (e) {
        alert(e);
      }
    }
  };

  useEffect(() => {
    initChartAttr.options.map((option, index) => {
      if (option.value === cloneConfiguration.chartType) {
        setSelectedChart(index);
      }
    });
  }, [cloneConfiguration.chartType]);

  useEffect(() => {
    if (
      cloneConfiguration.chartType &&
      answered > 0 &&
      !(
        graphTypes.type1.indexOf(question.type) > -1 ||
        graphTypes.type2.indexOf(question.type) > -1
      )
    ) {
      setGraphInit();
    }
  }, [weightedAverage, cloneConfiguration]);

  const countWeightedAverage = () => {
    switch (true) {
      case tableTypes.type1.indexOf(question.type) > -1:
        const stats = prepareStatistics(
          question.options.map((option) => option.answered)
        );
        setWeightedAverage([
          {
            label: "Average",
            answered: stats.length > 0 ? parseFloat(mean(stats)) : 0,
          },
        ]);
        break;
      case tableTypes.type2.indexOf(question.type) > -1:
        const options = question.options.map((option, index) => {
          const stats = prepareStatistics(option.answered);
          return {
            label: option.label,
            answered: stats.length > 0 ? parseFloat(mean(stats)) : 0,
          };
        });
        setWeightedAverage(options);
        break;
      default:
        setWeightedAverage(null);
    }
  };

  const checkChart = (fieldType, index, value) => {
    setChartAttributes({
      ...chartAttributes,
      [fieldType]: chartAttributes[fieldType].map((val, ind) =>
        ind === index ? { ...val, value } : val
      ),
    });
  };

  const setChartDepth = (depth) => {
    if (!depth) {
      setAxisScale(false);
    }
    setDepth(depth);
  };

  const setGraphInit = (chartTypeParam) => {
    const graphConfigTemp = {
      ...graphConfigTemplate,
      options: { ...graphConfigTemplate.options },
    };
    graphConfigTemp.options.scales.yAxes[0].ticks = { beginAtZero: true };
    graphConfigTemp.options.scales.xAxes[0].ticks = { beginAtZero: true };
    const propChartType = chartTypeParam
      ? chartTypeParam
      : cloneConfiguration.chartType;
    //set Labels in Graph
    graphConfigTemp.plugins = chartAttributes.showFilter[2].value;
    graphConfigTemp.type = getChartType(propChartType);
    // is it Stacked or not
    stacked =
      propChartType === "horizontalStackedBar" ||
      propChartType === "stackedBar";
    graphConfigTemp.options = {
      ...graphConfigTemp.options,
      title: {
        ...graphConfigTemp.options.title,
        text: graphTitle ? graphTitle : chartTitle,
      },
    };
    console.log("graphTitle=", graphTitle);

    //set Aspect Ratio to 1 when bar graph is horizontal
    // if (graphConfigTemp.type === "horizontalBar") {
    //   graphConfigTemp.options.aspectRatio = 1;
    // }

    // Labels as per stacked and not stacked graph
    const lableCode = (value, context) => {
      // hard-coded condition wrong
      if (cloneConfiguration.axisScale && question.type != "slider") {
        return value + "%";
      } else {
        return value;
      }
    };
    graphConfigTemp.options = {
      ...graphConfigTemp.options,
      plugins: {
        datalabels: {
          color:
            graphConfigTemp.type === "line"
              ? "black"
              : !stacked
              ? "black"
              : "white",
          formatter: lableCode,
          font: {
            weight: "bold",
            size: 10,
          },
          rotation: 0,
          padding: stacked ? 0 : 4,
          backgroundColor: !stacked ? null : "black",
          align:
            graphConfigTemp.type === "line"
              ? "top"
              : !stacked
              ? graphConfigTemp.type === "bar"
                ? "top"
                : "right"
              : "center",
          offset: 4,
          anchor: stacked ? "center" : "end",
        },
      },
    };
    // Prepares Dataset for all graph types
    let { dataSets, isNested } = cloneConfiguration.depth
      ? prepareDataSet(question.options)
      : prepareDataSet(weightedAverage);
    console.log("dataSets", keyInfo, dataSets);
    totalResponse = question.options.reduce(
      (sum, option) => sum + option.answered,
      0
    );

    // Set Max Column height when data is absolute to show labels correctly
    const columnMax = findColumnMax(dataSets);

    if (
      !cloneConfiguration.axisScale &&
      isNested &&
      propChartType === "lineFill"
    ) {
      graphConfigTemp.options = updateMax(graphConfigTemp, {
        max: answered,
        beginAtZero: true,
      });
    } else if (!stacked && propChartType !== "lineFill") {
      graphConfigTemp.options = updateMax(graphConfigTemp, {
        max: columnMax + columnMax / 4,
        beginAtZero: true,
      });
    } else {
      // Reset when graph is stacked or percentage
      graphConfigTemp.options = updateMax(graphConfigTemp, {
        beginAtZero: true,
      });
    }

    // Table and Statistics
    switch (true) {
      case tableTypes.type1.indexOf(question.type) > -1:
        setTableColumns(dataColumns);
        setTableData(
          question.options.map((option, index) => {
            return {
              key: index,
              answerChoices: option.label,
              responses:
                option.answered !== 0
                  ? ((option.answered * 100) / totalResponse).toFixed(
                      cloneConfiguration.decimalPlaces
                    )
                  : 0,
              number: option.answered,
            };
          })
        );
        const stats = prepareStatistics(
          question.options.map((option) => option.answered)
        );
        setStatsColumns(basicStatisticsColumns);
        setStatsData([
          {
            min: stats.length > 0 ? min(stats) : 0,
            max: stats.length > 0 ? max(stats) : 0,
            mean: (stats.length ? mean(stats) : 0).toFixed(
              cloneConfiguration.decimalPlaces
            ),
            median: stats.length ? median(stats) : 0,
            sd: (stats.length > 0 ? std(stats) : 0).toFixed(
              cloneConfiguration.decimalPlaces
            ),
          },
        ]);
        break;
      case tableTypes.type2.indexOf(question.type) > -1:
        setTableColumns(
          starTableColumns(question.options[0].answered.length, question.labels)
        );
        setTableData(
          starTableData(question.options, cloneConfiguration.decimalPlaces)
        );
        setStatsColumns([
          { title: "", dataIndex: "option" },
          ...basicStatisticsColumns,
        ]);
        setStatsData(
          question.options.map((option) => {
            const stats = prepareStatistics(option.answered);
            return {
              option: option.label,
              min: stats.length ? min(stats) : 0,
              max: stats.length ? max(stats) : 0,
              mean: (stats.length ? mean(stats) : 0).toFixed(
                cloneConfiguration.decimalPlaces
              ),
              median: stats.length ? median(stats) : 0,
              sd: (stats.length ? std(stats) : 0).toFixed(
                cloneConfiguration.decimalPlaces
              ),
            };
          })
        );
        break;
      case tableTypes.type3.indexOf(question.type) > -1:
        setTableColumns(sliderDataColumns);
        setTableData(
          question.options.map((option, index) => {
            return {
              answerChoices: "Responses",
              averageNumber: option.answered.toFixed(
                cloneConfiguration.decimalPlaces
              ),
              totalNumber: option.totalNumber,
              responses: answered,
            };
          })
        );
        setStatsColumns(basicStatisticsColumns);
        setStatsData([
          {
            min: question.options[0].min,
            max: question.options[0].max,
            mean: question.options[0].answered.toFixed(
              cloneConfiguration.decimalPlaces
            ),
            median: question.options[0].median,
            sd: question.options[0].sd.toFixed(
              cloneConfiguration.decimalPlaces
            ),
          },
        ]);
        dataSets[0] = parseFloat(dataSets[0]).toFixed(
          cloneConfiguration.decimalPlaces
        );
        break;
      case tableTypes.type4.indexOf(question.type) > -1:
        setTableColumns(npsDataColumns);
        const percentageValue = calculatePercentage([
          question.options[0].answered,
          question.options[1].answered,
          question.options[2].answered,
        ]);
        setTableData([
          {
            Responses: "Your Responses",
            detractors: (
              <TableCell
                value={question.options[0].answered}
                percentageValue={percentageValue[0]}
                decimalPlaces={cloneConfiguration.decimalPlaces}
              ></TableCell>
            ),
            passives: (
              <TableCell
                value={question.options[1].answered}
                percentageValue={percentageValue[1]}
                decimalPlaces={cloneConfiguration.decimalPlaces}
              ></TableCell>
            ),
            promotors: (
              <TableCell
                value={question.options[2].answered}
                percentageValue={percentageValue[2]}
                decimalPlaces={cloneConfiguration.decimalPlaces}
              ></TableCell>
            ),
            nps: question.NPS,
          },
          {
            Responses: npsValues[defaultNPS].name,
            detractors: npsValues[defaultNPS].detractors + "%",
            passives: npsValues[defaultNPS].passives + "%",
            promotors: npsValues[defaultNPS].promotors + "%",
            nps: npsValues[defaultNPS].npsValue,
          },
        ]);
        setStatsColumns(npsStatsColumns);
        setStatsData([
          {
            title: "Your Responses",
            min: -100,
            lower: -25,
            median: 50,
            upper: 25,
            max: 100,
          },
          {
            title: npsValues[defaultNPS].name,
            min: -100,
            lower: npsValues[defaultNPS].lower,
            median: npsValues[defaultNPS].median,
            upper: npsValues[defaultNPS].upper,
            max: 100,
          },
        ]);
        break;
    }

    // when Graph is pie or doughnut remove axis
    if (propChartType === "pie" || propChartType === "doughnut") {
      graphConfigTemp.options = {
        aspectRatio: 1,
        plugins: {
          datalabels: {
            color: "white",
            formatter: function (value, context) {
              if (cloneConfiguration.axisScale) {
                return value + "%";
              } else {
                return value;
              }
            },
            font: {
              weight: "bold",
            },
          },
        },
      };
    }

    //Graphs
    switch (true) {
      case !isNested && !stacked:
        // Labels are not required if graphs are on weighted Average
        if (cloneConfiguration.depth) {
          graphConfigTemp.data.labels = question.options.map(
            (option) => option.label
          );
        } else {
          graphConfigTemp.data.labels = weightedAverage.map(
            (option) => option.label
          );
        }
        if (propChartType === "bar" || propChartType === "horizontalBar") {
          graphConfigTemp.options.scales.yAxes[0].stacked = false;
          graphConfigTemp.options.scales.xAxes[0].stacked = false;
          graphConfigTemp.data.labels = [""];
          if (cloneConfiguration.depth) {
            graphConfigTemp.data = {
              ...graphConfigTemp.data,
              datasets: question.options.map((option, index) => {
                let colorIndex =
                  index % (cloneConfiguration.colors.length || 1);

                return {
                  barPercentage: question.options.length === 1 ? 0.3 : 1,
                  label: option.label,
                  backgroundColor: `rgba(${cloneConfiguration.colors[colorIndex].r},${cloneConfiguration.colors[colorIndex].g},${cloneConfiguration.colors[colorIndex].b},${cloneConfiguration.colors[colorIndex].a})`,
                  borderColor: "rgba(255,255,255,1)",
                  data: [dataSets[index]],
                };
              }),
            };
            setTotalColors(question.options.length);
          } else {
            graphConfigTemp.data = {
              ...graphConfigTemp.data,
              datasets: weightedAverage.map((option, index) => {
                let colorIndex =
                  index % (cloneConfiguration.colors.length || 1);

                return {
                  barPercentage: weightedAverage.length === 1 ? 0.3 : 1,
                  label: option.label,
                  backgroundColor: `rgba(${cloneConfiguration.colors[colorIndex].r},${cloneConfiguration.colors[colorIndex].g},${cloneConfiguration.colors[colorIndex].b},${cloneConfiguration.colors[colorIndex].a})`,
                  borderColor: "rgba(255,255,255,1)",
                  data: [dataSets[index]],
                };
              }),
            };
            setTotalColors(weightedAverage.length);
          }
          console.log("inner Data=", keyInfo, graphConfigTemp);
        } else {
          graphConfigTemp.data = {
            ...graphConfigTemp.data,
            datasets: [
              {
                barPercentage: 1,
                fill: propChartType === "lineFill" ? true : false,
                label: question.options.map((option) => option.label),
                backgroundColor: [...Array(question.options.length)].map(
                  (value, index) => {
                    let colorIndex =
                      index % (cloneConfiguration.colors.length || 1);
                    return `rgba(${cloneConfiguration.colors[colorIndex].r},${cloneConfiguration.colors[colorIndex].g},${cloneConfiguration.colors[colorIndex].b},${cloneConfiguration.colors[colorIndex].a})`;
                  }
                ),
                borderColor:
                  graphConfigTemp.type === "line"
                    ? "rgba(0,193,148,1)"
                    : "rgba(255,255,255,1)",
                data:
                  dataSets.length === 1 ? [null, ...dataSets, null] : dataSets,
              },
            ],
          };
          setTotalColors(dataSets.length);
          if (dataSets.length === 1 && graphConfig.type === "line") {
            graphConfigTemp.data = {
              ...graphConfigTemp.data,
              labels: ["", "", ""],
            };
          }
        }
        break;
      case !isNested && stacked:
        graphConfigTemp.options.scales.yAxes[0].stacked = true;
        graphConfigTemp.options.scales.xAxes[0].stacked = true;
        graphConfigTemp.data.labels = [""];
        graphConfigTemp.data = {
          ...graphConfigTemp.data,
          datasets: dataSets.map((data, index) => {
            let colorIndex = index % (cloneConfiguration.colors.length || 1);

            return {
              label: question.options[index].label,
              barPercentage: 0.5,
              data: [data],
              backgroundColor: `rgba(${cloneConfiguration.colors[colorIndex].r},${cloneConfiguration.colors[colorIndex].g},${cloneConfiguration.colors[colorIndex].b},${cloneConfiguration.colors[colorIndex].a})`,
            };
          }),
        };
        setTotalColors(dataSets.length);
        break;
      case isNested && !stacked:
        if (propChartType === "lineFill") {
          var add = [];
          dataSets = dataSets.map((data, outerIndex) => {
            if (outerIndex === 0) {
              add = data.map((value) => value);
            }
            if (outerIndex > 0) {
              add = data.map((value, index) => {
                const number = parseFloat(value) + parseFloat(add[index]);
                return Math.round(number) === 100
                  ? 100
                  : number.toFixed(cloneConfiguration.decimalPlaces);
              });
            }
            return add;
          });
        }
        if (propChartType === "pie" || propChartType === "doughnut") {
          graphConfigTemp.data.labels = dataSets.map(
            (data, index) => index + 1
          );
          graphConfigTemp.data = {
            ...graphConfigTemp.data,
            datasets: question.options.map((option, index) => {
              return {
                label: dataSets.map(
                  (data, index) => index + 1 + " " + option.label
                ),
                data: dataSets.map((data) => data[index]),
                backgroundColor: dataSets.map((data, index) => {
                  let colorIndex =
                    index % (cloneConfiguration.colors.length || 1);
                  return `rgba(${cloneConfiguration.colors[colorIndex].r},${cloneConfiguration.colors[colorIndex].g},${cloneConfiguration.colors[colorIndex].b},${cloneConfiguration.colors[colorIndex].a})`;
                }),
              };
            }),
          };
          setTotalColors(dataSets.length);
        } else {
          graphConfigTemp.options.scales.yAxes[0].stacked = false;
          graphConfigTemp.options.scales.xAxes[0].stacked = false;
          graphConfigTemp.data.labels = question.options.map(
            (option) => option.label
          );
          graphConfigTemp.data = {
            ...graphConfigTemp.data,
            datasets: dataSets.map((data, index) => {
              let colorIndex = index % (cloneConfiguration.colors.length || 1);
              return {
                fill: propChartType === "lineFill" ? true : false,
                label: question.labels ? question.labels[index] : index + 1,
                barPercentage: 1,
                data: data,
                backgroundColor: `rgba(${cloneConfiguration.colors[colorIndex].r},${cloneConfiguration.colors[colorIndex].g},${cloneConfiguration.colors[colorIndex].b},${cloneConfiguration.colors[colorIndex].a})`,
                borderColor: `rgba(${cloneConfiguration.colors[colorIndex].r},${cloneConfiguration.colors[colorIndex].g},${cloneConfiguration.colors[colorIndex].b},${cloneConfiguration.colors[colorIndex].a})`,
                datalabels: {
                  color:
                    graphConfigTemp.type === "line"
                      ? `rgba(${cloneConfiguration.colors[colorIndex].r},${cloneConfiguration.colors[colorIndex].g},${cloneConfiguration.colors[colorIndex].b},${cloneConfiguration.colors[colorIndex].a})`
                      : "black",
                  formatter: (value, context) => {
                    if (propChartType === "lineFill" && index !== 0) {
                      // console.log("dataValue", context.dataIndex, value);
                      return cloneConfiguration.axisScale
                        ? (
                            value - dataSets[index - 1][context.dataIndex]
                          ).toFixed(cloneConfiguration.decimalPlaces) + "%"
                        : value - dataSets[index - 1][context.dataIndex];
                    } else {
                      return cloneConfiguration.axisScale ? value + "%" : value;
                    }
                  },
                },
              };
            }),
          };
        }
        setTotalColors(dataSets.length);
        break;
      case isNested && stacked:
        graphConfigTemp.options.scales.yAxes[0].stacked = true;
        graphConfigTemp.options.scales.xAxes[0].stacked = true;
        graphConfigTemp.data.labels = question.options.map(
          (option) => option.label
        );
        graphConfigTemp.data = {
          ...graphConfigTemp.data,
          datasets: dataSets.map((data, index) => {
            let colorIndex = index % (cloneConfiguration.colors.length || 1);
            return {
              label: question.labels ? question.labels[index] : index + 1,
              barPercentage: data.length === 1 ? 0.5 : 1,
              data: data,
              backgroundColor: `rgba(${cloneConfiguration.colors[colorIndex].r},${cloneConfiguration.colors[colorIndex].g},${cloneConfiguration.colors[colorIndex].b},${cloneConfiguration.colors[colorIndex].a})`,
            };
          }),
        };
        setTotalColors(dataSets.length);
        break;
    }
    console.log(
      "inner data config",
      keyInfo,
      JSON.parse(JSON.stringify(graphConfigTemp))
    );
    // show Data Table if ticked
    setShowDataTable(chartAttributes.showFilter[1].value);
    // show Data Table if ticked
    setShowStatisticsTable(chartAttributes.showFilter[3].value);

    setChartVisibility(chartAttributes.showFilter[0].value);

    // setCustomize(false);

    setGraphConfig(graphConfigTemp);
    // setGraphTab(true);
    // graphConfig.data.datasets.background
  };

  useEffect(() => {
    if (
      question.type === questionTypes.NET_PROMOTOR_SCORE &&
      tableData &&
      statsData
    ) {
      setTableData([
        { ...tableData[0] },
        {
          Responses: npsValues[defaultNPS].name,
          detractors: npsValues[defaultNPS].detractors + "%",
          passives: npsValues[defaultNPS].passives + "%",
          promotors: npsValues[defaultNPS].promotors + "%",
          nps: npsValues[defaultNPS].npsValue,
        },
      ]);
      setStatsData([
        {
          ...statsData[0],
        },
        {
          title: npsValues[defaultNPS].name,
          min: -100,
          lower: npsValues[defaultNPS].lower,
          median: npsValues[defaultNPS].median,
          upper: npsValues[defaultNPS].upper,
          max: 100,
        },
      ]);
    }
  }, [defaultNPS]);

  console.log("weightedAverage", weightedAverage);

  const updateMax = (configOptions, ticks) => {
    return {
      ...configOptions.options,
      scales: {
        ...configOptions.options.scales,
        yAxes: [
          {
            ...configOptions.options.scales.yAxes[0],
            ticks: ticks,
          },
        ],
        xAxes: [
          {
            ...configOptions.options.scales.xAxes[0],
            ticks: ticks,
          },
        ],
      },
    };
  };

  const prepareDataSet = (options) => {
    let dataSets;
    dataSets = options.map((option, index) => {
      return option.answered;
    });
    let isNested = true;
    dataSets = dataSets.map((data, index) => {
      if (data.length) {
        return setAxisScaleArr(data);
      } else {
        isNested = false;
        return data;
      }
    });
    if (!isNested) {
      dataSets = setAxisScaleArr(dataSets);
    } else {
      dataSets = [...new Array(question.totalStar)].map((value, index) => {
        return dataSets.map((data, innerIndex) => {
          // console.log(data);
          return data[index];
        });
      });
    }
    // console.log({ dataSets, isNested });
    return { dataSets, isNested };
  };

  const setAxisScaleArr = (arr) => {
    if (cloneConfiguration.axisScale && question.type !== "slider") {
      const totalAnswered = arr.reduce((sum, value) => sum + value, 0);
      // console.log(totalAnswered);
      return arr.map((value) =>
        ((value * 100) / totalAnswered).toFixed(
          cloneConfiguration.decimalPlaces
        )
      );
    } else {
      return arr.map((value) =>
        cloneConfiguration.depth
          ? value
          : value.toFixed(cloneConfiguration.decimalPlaces)
      );
    }
  };

  const prepareStatistics = (arr) => {
    let stats = [];
    arr.map((value, index) => {
      [...new Array(value)].map(() => stats.push(index + 1));
    });
    return stats;
  };

  const findColumnMax = (dataSets) => {
    let maxNumber = 0;
    let isNested = true;
    dataSets.map((data, index) => {
      if (typeOf(data) === "Array") {
        let t = 0;
        if (data.length != 0 && data[0] instanceof String) t = max(data);
        maxNumber = t > maxNumber ? t : maxNumber;
      } else {
        isNested = false;
      }
    });
    if (!isNested) {
      maxNumber = max(dataSets.map((d) => (isNaN(d) ? 0 : d)));
    }
    return maxNumber;
  };

  const getChartType = (chartType) => {
    // console.log("chart type", chartType);
    switch (true) {
      case chartType === "bar" || chartType === "stackedBar":
        return "bar";
      case chartType === "horizontalBar" ||
        chartType === "horizontalStackedBar":
        return "horizontalBar";
      case chartType === "pie":
        return "pie";
      case chartType === "doughnut":
        return "doughnut";
      case chartType === "line" || chartType === "lineFill":
        return "line";
    }
  };

  const changeColor = (index, value) => {
    const colorArr = [...colors];
    colorArr[index] = value;
    setColors(colorArr);
    // console.log(colors);
  };

  console.log("axis", axisScale, depth);

  return (
    <div
      ref={questionComponentRef}
      style={{
        borderRadius: 5,
        width: "100%",
        pageBreakInside: "avoid",
      }}
      id="printDocument"
    >
      <div className={cx("question-details")}>
        <div
          className="question-summary"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ width: "70%", minHeight: "60px", fontSize: "large" }}>
            {questionNumber}. {question.text}
          </div>
          <div style={{ margin: "auto 0", display: "flex", gap: "10px" }}>
            <Button
              style={
                !(questionResponses && questionResponses[questionNumber - 1]) ||
                questionNumber === 1
                  ? {
                      ...greyButtonStyle,
                      marginRight: "0px",
                      marginBottom: "0px",
                    }
                  : {
                      ...darkBlueButtonStyle,
                      marginRight: "0px",
                      marginBottom: "0px",
                    }
              }
              disabled={
                !(questionResponses && questionResponses[questionNumber - 1]) ||
                questionNumber === 1
              }
              onClick={() => {
                if (questionNumber > 1) {
                  setQuestion(questionNumber - 1);
                }
              }}
              icon={<CaretLeftOutlined />}
            />
            <div style={{ fontSize: "0.8rem", opacity: "80%", margin: "auto" }}>
              {questionNumber + " of " + questionResponses.length}
            </div>
            <Button
              style={
                !(questionResponses && questionResponses[questionNumber - 1]) ||
                questionNumber === questionResponses.length
                  ? {
                      ...greyButtonStyle,
                      marginRight: "0px",
                      marginBottom: "0px",
                    }
                  : {
                      ...darkBlueButtonStyle,
                      marginRight: "0px",
                      marginBottom: "0px",
                    }
              }
              disabled={
                !(questionResponses && questionResponses[questionNumber - 1]) ||
                questionNumber === questionResponses.length
              }
              onClick={() => {
                if (true) {
                  setQuestion(questionNumber + 1);
                }
              }}
              icon={<CaretRightOutlined />}
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className={cx("question-text")}>
            {/* <div style={{ display: "flex" }}>
            <div className={cx("number")}>{questionNumber}</div>
            <div style={{ maxWidth: "92%" }}>{question.text}</div>
          </div> */}
            <div className={cx("questionInfo")} style={{ gap: "14px" }}>
              <div className={cx("question-type")}>
                {questionNotation[question.type]}
              </div>
              {question.type === "imageInstruction" ||
              question.type === "videoInstruction" ||
              question.type === "questionInstruction" ? null : (
                <div style={{ display: "flex", gap: "14px" }}>
                  <div>Answered {answered}</div>
                  <div>Unanswered 0</div>
                </div>
              )}
            </div>
            {question.type === "netPromotorScore" && (
              <div className={cx("questionInfo")} style={{ gap: "14px" }}>
                <div>DETRACTORS(0-6): {question.options[0].answered}</div>
                <div>PASSIVES(7-8): {question.options[1].answered}</div>
                <div>PROMOTERS(0-10): {question.options[2].answered}</div>
              </div>
            )}
          </div>
        </div>

        {onSaveOptions && answered > 0 && (
          <div style={{ margin: "10px 0", display: "flex" }}>
            <Button
              className={print ? "printButton" : ""}
              style={
                disabledCustomize
                  ? { ...greyButtonStyle, marginBottom: 0 }
                  : {
                      marginRight: "8px",
                      marginBottom: "0px",
                      backgroundColor: "#B9E8F6",
                      color: "black",
                      borderRadius: "5px",
                    }
              }
              onClick={() => setCustomize(!customize)}
              disabled={disabledCustomize}
            >
              Customize
            </Button>
            <ReactToPrint
              trigger={() => (
                <Button
                  className={print ? "printButton" : ""}
                  style={{
                    marginRight: "8px",
                    marginBottom: "0px",
                    backgroundColor: "#B9E8F6",
                    color: "black",
                    borderRadius: "5px",
                  }}
                >
                  Save as
                </Button>
              )}
              bodyClass={cx("print-body")}
              content={() => questionComponentRef.current}
            ></ReactToPrint>
          </div>
        )}
      </div>
      {onSaveOptions && (
        <Collapse in={customize} enter={2000} exit={1000}>
          <div
            className={cx("customize-block")}
            // style={
            //   customize ? { display: "block", height: "auto" } : { display: "none" }
            // }
          >
            <Fragment>
              <span
                style={{
                  color: COLOR.dullLightGreen,
                  borderColor: COLOR.dullLightGreen,
                }}
              >
                {isTabletOrMobile ? (
                  <Dropdown
                    style={{ width: "180px", margin: "5px" }}
                    handleChange={setGraphTab}
                    value={graphTab}
                    color={COLOR.grey}
                    options={[
                      {
                        label: "CHART TYPE",
                        value: "1",
                      },
                      {
                        label: "DISPLAY OPTIONS",
                        value: "2",
                      },
                      {
                        label: "COLORS",
                        value: "3",
                      },
                      {
                        label: "CHART TITLE",
                        value: "4",
                      },
                    ]}
                  />
                ) : (
                  <TabBar
                    activeKey={graphTab}
                    onChange={setGraphTab}
                    tabBarStyle={{
                      padding: "0 15px",
                      fontSize: 14,
                      marginBottom: 0,
                    }}
                    tabs={[
                      "CHART TYPE",
                      "DISPLAY OPTIONS",
                      "COLORS",
                      "CHART TITLE",
                    ]}
                  />
                )}
              </span>
              <div
                className="graphs"
                style={{
                  backgroundColor: COLOR.ultraLightGrey,
                  width: "100%",
                  padding: 10,
                }}
              >
                {graphTab === "1" && (
                  <Fragment>
                    <FlexRow>
                      <ButtonToggle
                        activeClass="fill-green"
                        value={chartType}
                        setValue={setChartType}
                        options={initChartAttr.options}
                        disabled={
                          !depth
                            ? chartSettings?.weightedAverage
                            : axisScale
                            ? chartSettings?.percentage
                            : chartSettings?.absolute
                        }
                      />
                    </FlexRow>
                  </Fragment>
                )}
                {graphTab === "2" && (
                  <Fragment>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div style={greyLabelStyle}>Show</div>
                        {chartAttributes.showFilter.map(
                          ({ label, value }, index) => (
                            <FilterCheckbox
                              labelStyle={{ color: "grey", fontSize: "14px" }}
                              style={chartCheckboxStyle}
                              label={label}
                              classes="fill green"
                              checked={value}
                              disabled={chartSettings.show[index]}
                              key={index}
                              onChange={(checked) =>
                                checkChart("showFilter", index, checked)
                              }
                            />
                          )
                        )}
                      </div>
                      <div>
                        <div style={greyLabelStyle}>Data Format</div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: isTabletOrMobile ? "150px" : "none",
                          }}
                        >
                          <div>
                            <Dropdown
                              label="Axis Scale"
                              handleChange={setAxisScale}
                              // If depth is weighted average then axis scale should be Absolute
                              value={axisScale}
                              color={COLOR.grey}
                              disabled={
                                !depth ||
                                chartSettings.axisScale ||
                                (axisScale
                                  ? chartSettings.absolute[selectedChart]
                                  : chartSettings.percentage[selectedChart])
                              }
                              options={[
                                {
                                  label: "Percentage",
                                  value: true,
                                },
                                {
                                  label: "Absolute",
                                  value: false,
                                },
                              ]}
                            ></Dropdown>
                          </div>
                          <div>
                            <Dropdown
                              label="Decimal Places"
                              handleChange={setDecimalPlaces}
                              value={decimalPlaces}
                              color={COLOR.grey}
                              disabled={!axisScale && depth}
                              options={[
                                {
                                  label: "0.0",
                                  value: 1,
                                },
                                {
                                  label: "0.00",
                                  value: 2,
                                },
                                {
                                  label: "0.000",
                                  value: 3,
                                },
                              ]}
                            ></Dropdown>
                          </div>
                          <div>
                            <Dropdown
                              label="Depth"
                              handleChange={(value) => setChartDepth(value)}
                              value={depth}
                              color={COLOR.grey}
                              disabled={
                                chartSettings.chartDepth ||
                                chartSettings.weightedAverage[selectedChart]
                              }
                              options={[
                                {
                                  label: "Distribution",
                                  value: true,
                                },
                                {
                                  label: "Weighted Average",
                                  value: false,
                                },
                              ]}
                            ></Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )}
                {graphTab === "3" && (
                  <Fragment>
                    <FlexRow>
                      {colors
                        .filter((v, index) => index < totalColors)
                        .map((color, index) => {
                          return (
                            <ColorPicker
                              colorAttr={color}
                              colorNumber={index}
                              key={index}
                              setColor={(index, value) =>
                                changeColor(index, value)
                              }
                            />
                          );
                        })}
                    </FlexRow>
                  </Fragment>
                )}
                {graphTab === "4" && (
                  <Fragment>
                    <div
                      style={{
                        display: "flex",
                        padding: 10,
                        width: "50%",
                        background: "#F0F0F0",
                      }}
                    >
                      {!compareMode && (
                        <Space style={{ width: "100%" }} direction="vertical">
                          <Input
                            value={chartTitle}
                            onChange={(e) => setChartTitle(e.target.value)}
                            placeholder="Chart Title"
                          />
                        </Space>
                      )}
                      {compareMode && (
                        <Space style={{ width: "100%" }} direction="vertical">
                          <Input
                            value={compareChartTitle.graph1}
                            onChange={(e) =>
                              setCompareChartTitle({
                                ...compareChartTitle,
                                graph1: e.target.value,
                              })
                            }
                            placeholder="Chart Title 1"
                          />
                          <Input
                            value={compareChartTitle.graph2}
                            onChange={(e) =>
                              setCompareChartTitle({
                                ...compareChartTitle,
                                graph2: e.target.value,
                              })
                            }
                            placeholder="Chart Title 2"
                          />
                          <Input
                            value={compareChartTitle.graph3}
                            onChange={(e) =>
                              setCompareChartTitle({
                                ...compareChartTitle,
                                graph3: e.target.value,
                              })
                            }
                            placeholder="Chart Title 3"
                          />
                        </Space>
                      )}
                    </div>
                  </Fragment>
                )}
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <Button
                    style={dullGreenButtonStyle}
                    onClick={() => onSaveOptions()}
                  >
                    Save
                  </Button>
                  <Button
                    style={greyButtonStyle}
                    onClick={() => {
                      setCustomize(false);
                    }}
                  >
                    CANCEL
                  </Button>
                </div>
              </div>
            </Fragment>
          </div>
        </Collapse>
      )}
      {criteriaData && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: 10,
            color: "#1E4479",
          }}
        >
          {criteriaData.map((c, index) => {
            return (
              <div
                style={{
                  display: "flex",
                }}
              >
                <div>
                  <strong>{c.fieldName}: &nbsp;</strong>
                </div>
                <div>{c.selectedValues.join(", ")}</div>
              </div>
            );
          })}
        </div>
      )}
      {answered > 0 && (
        <Fragment>
          <div>
            {question.type === "netPromotorScore" && (
              <GaugeMeter value={question.NPS}></GaugeMeter>
            )}
            {graphTypes.type2.indexOf(question.type) > -1 && (
              <div style={{ padding: 10 }}>
                <Button
                  style={{ ...darkBlueButtonStyle, marginBottom: 0 }}
                  onClick={() => setTextAnalysis(!textAnalysis)}
                >
                  Text Analysis
                </Button>
                {textAnalysis && sentimentValues && wordCloud && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <SentimentGraph sentimentAnalysis={sentimentValues} />
                    <WordCloud wordCloud={wordCloud} />
                  </div>
                )}
              </div>
            )}
            {[
              "stars",
              "imageStarRatingChoices",
              "videoStarRatingChoices",
            ].indexOf(question.type) > -1 && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                {tableData &&
                  question.options.map((option, index) => {
                    return (
                      <StarRatingElement
                        key={index}
                        label={option.label}
                        starType={option.starType}
                        totalStars={option.answered.length}
                        ratingLabel={
                          tableData[index] && tableData[index].weightedAverage
                            ? tableData[index].weightedAverage
                            : 0
                        }
                        ratings={
                          tableData[index] && tableData[index].weightedAverage
                            ? parseFloat(tableData[index].weightedAverage)
                            : 0
                        }
                      ></StarRatingElement>
                    );
                  })}
              </div>
            )}
            <Fragment>
              <div style={{ margin: 10 }}>
                <div className={cx("chartWrapper")}>
                  {chartVisibility && graphConfig && graphConfig.type ? (
                    <div className={cx("chartAreaWrapper")}>
                      <CreateChart
                        keyInfo={keyInfo}
                        type={graphConfig.type}
                        plugins={graphConfig.plugins}
                        options={graphConfig.options}
                        data={graphConfig.data}
                      ></CreateChart>
                    </div>
                  ) : null}
                </div>
                {showDataTable && (
                  <div className={cx(["table"])}>
                    <ResponsiveTable
                      size="small"
                      columns={tableColumns}
                      dataSource={tableData}
                      footer={() =>
                        totalResponse && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>Total</div>
                            <div>{totalResponse}</div>
                          </div>
                        )
                      }
                    />
                  </div>
                )}
                {showStatisticsTable && (
                  <div className={cx(["table"])}>
                    <div
                      style={{
                        padding: "10px 0 10px",
                        color: "#004479",
                        fontWeight: "bold",
                      }}
                    >
                      Statistics
                    </div>
                    <ResponsiveTable
                      size="small"
                      columns={statsColumns}
                      dataSource={statsData}
                    />
                    {question.type === "netPromotorScore" && (
                      <div
                        style={{
                          padding: 10,
                          fontSize: 18,
                          fontWeight: "bold",
                          color: "#1E4479",
                        }}
                      >
                        Your Net Promotor Score: {question.NPS}
                      </div>
                    )}
                  </div>
                )}
                {question.type === questionTypes.NET_PROMOTOR_SCORE &&
                  showDataTable && (
                    <Dropdown
                      style={{ width: "180px", margin: "5px" }}
                      handleChange={setDefaultNPS}
                      value={defaultNPS}
                      color={COLOR.grey}
                      options={npsValues.map((nps, index) => ({
                        label: nps.name,
                        value: index,
                      }))}
                    />
                  )}
              </div>
            </Fragment>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default QuestionBox;
