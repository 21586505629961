import React, { Fragment, useState } from "react";
import { Switch, Row, Col, Tag, Button } from "antd";
import { getClientId, notify } from "../../../utils";
import { useSelector } from "react-redux";
import DeletePanelUserConfirmModal from "./Modals/DeletePanelUserConfirmModal/DeletePanelUserConfirmModal";
import CopyPanelModal from "./Modals/CopyPanelModal/CopyPanelModal";
import axios, { Routes } from "../../../services/API";

const PastSurveyParticipants = ({
  usePanel,
  setUsePanel,
  panelUsers,
  setPanelUsers,
}) => {
  const surveys = useSelector((state) => state.client.surveys);

  const [
    deletePanelUserConfirmModal,
    setDeletePanelUserConfirmModal,
  ] = useState(false);
  const [copyPanelModal, setCopyPanelModal] = useState(false);

  const copySurveyPanel = async (survey) => {
    let surveyUsers = [];
    if (survey.isSurvey) {
      if (Array.isArray(survey.clientProvidedUsers)) {
        surveyUsers = [...survey.clientProvidedUsers];
      }

      if (Array.isArray(survey.responses)) {
        survey.responses.forEach((userResponse) => {
          if (userResponse.userId) {
            surveyUsers.push(userResponse.userId.email);
          }
        });
      }
    } else {
      const { data } = await axios({
        ...Routes.client.getPanelUsers(getClientId(), survey._id),
      });
      surveyUsers = data.users.map((user) => user.email);
    }

    if (!surveyUsers.length) {
      notify(
        "error",
        "Error",
        survey.name +
          " does not have any responses available to copy user details"
      );
      return;
    }

    setPanelUsers(surveyUsers);
  };

  return (
    <Fragment>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Row justify="space-between">
            <Col span={18}>
              <div style={{ margin: 0, fontSize: "1rem", fontWeight: "bold" }}>
                Past survey participants
              </div>
            </Col>
            <Col>
              <Switch
                checked={!!usePanel}
                onChange={(checked) => {
                  setUsePanel(checked);
                  if (!panelUsers) {
                    setPanelUsers([]);
                  }
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {!!usePanel && (
        <Row gutter={[20, 20]}>
          <Col span={24} style={{ padding: "0px" }}>
            <Row
              justify="space-between"
              style={{ padding: "20px 10px 0px 10px" }}
            >
              <Col xs={24} sm={18}>
                <h3 style={{ margin: 0 }}>Add users from previous survey</h3>
                <div>
                  All the users from selected survey will be added to this
                  survey.
                </div>
              </Col>
              <Col>
                <Button
                  style={{
                    backgroundColor: "#00233f",
                    color: "white",
                    border: "none",
                    borderRadius: 4,
                    height: 36,
                    textTransform: "uppercase",
                  }}
                  onClick={() => {
                    if (!!panelUsers?.length) {
                      setDeletePanelUserConfirmModal(true);
                    } else {
                      setCopyPanelModal(true);
                    }
                  }}
                >
                  {!!panelUsers?.length ? "Delete Panel" : "Select Panel"}
                </Button>
              </Col>
            </Row>
          </Col>
          {!!panelUsers?.length && (
            <Col
              span={24}
              style={{
                marginLeft: 8,
                marginRight: 8,
                backgroundColor: "#ecf9fa",
                padding: 16,
                display: "flex",
                flexWrap: "wrap",
                maxWidth: "calc(100% - 16px)",
              }}
            >
              {(panelUsers || []).map((emailId, index) => (
                <Tag
                  color={"#8edad0"}
                  key={index}
                  style={{
                    fontSize: 14,
                    marginBottom: 10,
                  }}
                >
                  {emailId}
                </Tag>
              ))}
            </Col>
          )}
        </Row>
      )}

      <CopyPanelModal
        surveyList={surveys.filter((survey) => survey.isPublished)}
        handleOk={async (survey) => {
          await copySurveyPanel(survey);
          setCopyPanelModal(false);
        }}
        handleCancel={() => setCopyPanelModal(false)}
        isVisible={copyPanelModal}
      />

      <DeletePanelUserConfirmModal
        handleOk={() => {
          setPanelUsers([]);
          setDeletePanelUserConfirmModal(false);
        }}
        handleCancel={() => {
          setDeletePanelUserConfirmModal(false);
        }}
        isVisible={deletePanelUserConfirmModal}
      />
    </Fragment>
  );
};

export default PastSurveyParticipants;
