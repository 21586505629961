import React, { useState } from "react";
import "./video-thumbnail.css";
import { findDOMNode } from "react-dom";
import ReactPlayer from "react-player/lazy";
// import screenfull from "screenfull";
import { PlayCircleTwoTone, CloseCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";

const VideoModal = ({ onCancel, url }) => {
  return (
    <Modal
      footer={null}
      visible={true}
      onCancel={onCancel}
      closable={false}
      wrapClassName="video-modal"
    >
      <ReactPlayer
        className="react-player"
        url={url}
        playing={true}
        width="100%"
        height="100%"
        controls={true}
      />
      <CloseCircleOutlined
        style={{
          fontSize: 24,
          color: "white",
          position: "absolute",
          cursor: "pointer",
          top: "-12px",
          right: "-12px",
        }}
        onClick={onCancel}
      />
    </Modal>
  );
};

const Thumbnail = ({ url, label, style = {}, noBorder }) => {
  // const ref = React.createRef();
  const [play, setPlay] = useState(false);

  const handleFullscreen = () => {
    // screenfull.request(findDOMNode(ref.current));
    setPlay(true);
  };

  return (
    <React.Fragment>
      <div style={{ ...style }}>
        <div className="video-container" onClick={handleFullscreen}>
          <video className="video-preview" src={url}></video>
          <PlayCircleTwoTone
            style={{
              position: "absolute",
              top: "calc(50% - 20px)",
              left: "calc(50% - 20px)",
              fontSize: "40px",
              borderRadius: "50%",
              boxShadow: "0 0px 4px 6px #0c4674c4",
            }}
          />
          {/* <ReactPlayer
            ref={ref}
            className="react-player"
            url={url}
            light={true}
            playing={true}
            width="100%"
            height="100%"
          /> */}
        </div>
        {label && (
          <div
            style={{ color: "white", wordBreak: "break-word", marginTop: 10 }}
          >
            {label}
          </div>
        )}
        {play && <VideoModal onCancel={() => setPlay(false)} url={url} />}
      </div>
    </React.Fragment>
  );
};

export default Thumbnail;
