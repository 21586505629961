import React from "react";
import "./App.less";
import "./App.css";
import Routes from "./Routes.js";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { BrowserRouter } from "react-router-dom";
import Modal from "react-modal";

Modal.setAppElement("#root");

function App() {
  const pendingRequests = useSelector((state) => state.pendingRequests);
  const loading = pendingRequests > 0 ? true : false;

  return (
    <div className="App" style={{ height: "100%" }}>
      <BrowserRouter>
        <Routes />
        {loading && <Spin spinning={true} size="large" />}
      </BrowserRouter>
    </div>
  );
}

export default App;
