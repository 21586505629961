import React, { useState } from "react";
import { Fragment } from "react";
import {
  Table,
  Modal,
  Dropdown,
  Menu,
  Button,
  Checkbox,
  Radio,
  List,
  Card,
} from "antd";
import { FilterFilled, CloseSquareFilled } from "@ant-design/icons";
import moment from "moment";
import { useMediaQuery } from "react-responsive";

import styles from "./PublishedSurveyListModal.module.css";
import "./PublishedSurveyListModal.css";
import c from "classnames/bind";
const RadioGroup = Radio.Group;
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const cx = c.bind(styles);

const filters = [
  {
    text: "Qualitative",
    value: "Qualitative",
  },
  {
    text: "Quantitative",
    value: "Quantitative",
  },
  {
    text: "Moderated",
    value: "Moderated",
  },
  {
    text: "UnModerated",
    value: "UnModerated",
  },
  /* {
    text: "Mobile",
    value: "Mobile",
  },
  {
    text: "Desktop",
    value: "Desktop",
  }, */
  {
    text: "Group",
    value: "Group",
  },
  {
    text: "Individual",
    value: "Individual",
  },
  {
    text: "15 min",
    value: "15 min",
  },
  {
    text: "30 min",
    value: "30 min",
  },
  {
    text: "45 min",
    value: "45 min",
  },
];

const PublishedSurveyListModal = ({
  surveyList,
  surveyListModal,
  handleOk,
  handleCancel,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState([]);

  const timing = {
    1: "15 min",
    2: "30 min",
    3: "45 min",
  };

  const surveyListColumns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "150px",
    },
    {
      title: "Published Date",
      dataIndex: "date",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      render: (date) => moment(date).format("DD MMM YYYY"),
      align: "left",
      width: "100px",
    },
    {
      title: "Survey Type",
      dataIndex: "type",
      width: "150px",
      className: "survey-filter",
      // onFilter: (value, record) => record.type.indexOf(value) > -1,
    },
    Table.SELECTION_COLUMN,
  ];

  const getSurveyType = (survey) => {
    let type = "";
    type += survey.surveyType ? "Qualitative" : "Quantitative";
    if (survey.surveyType) {
      type +=
        "/" +
        (isMobile ? "\n" : "") +
        (survey.mode ? "Moderated" : "UnModerated");
      type += "/" + (survey.device ? "Mobile" : "Desktop");
      if (survey.mode) {
        type += "/" + (survey.setting ? "Group" : "Individual");
      }
      type += "/" + timing[survey.timingType];
    }
    return type;
  };

  const getSurveyTags = (survey) => {
    let tags = [];
    tags.push(survey.surveyType ? "Qualitative" : "Quantitative");
    if (survey.surveyType) {
      tags.push(survey.mode ? "Moderated" : "UnModerated");
      tags.push(survey.device ? "Mobile" : "Desktop");
      if (survey.mode) {
        tags.push(survey.setting ? "Group" : "Individual");
      }
      tags.push(timing[survey.timingType]);
    }
    return tags;
  };

  const surveyListData = surveyList
    .map((publishedSurvey, index) => {
      return {
        key: publishedSurvey._id,
        name: publishedSurvey.name,
        date: publishedSurvey.createdAt,
        type: getSurveyType(publishedSurvey),
        tags: getSurveyTags(publishedSurvey),
        survey: publishedSurvey,
      };
    })
    .filter(({ tags }) => {
      if (appliedFilters.length === 0) {
        return true;
      }
      return appliedFilters.some((f) => tags.includes(f));
    });

  const handleRadioSelection = (e) => {
    setSelectedSurvey(e.target.value.survey);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedSurvey({
        ...selectedRows[0].survey,
        isPublished: false,
        responses: [],
      });
    },
  };

  return (
    <Fragment>
      <Modal
        visible={surveyListModal}
        onOk={() => handleOk(selectedSurvey)}
        onCancel={handleCancel}
        width={750}
        centered={true}
        className={cx("published-survey-popup")}
        okText="Copy Survey"
        cancelText="Cancel"
        okButtonProps={{
          size: "large",
          className: "yesButton",
          disabled: selectedSurvey ? false : true,
        }}
        cancelButtonProps={{ size: "large", className: "noButton" }}
        closeIcon={
          <CloseSquareFilled
            style={{
              fontSize: 24,
              color: "#dddddd",
            }}
          />
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 18 }}>Please Select Survey</div>
          <Dropdown
            overlay={
              <Menu>
                <Checkbox.Group
                  value={appliedFilters}
                  onChange={setAppliedFilters}
                >
                  {filters.map(({ text, value }) => {
                    return (
                      <Menu.Item key={value}>
                        <Checkbox value={value}>{text}</Checkbox>
                      </Menu.Item>
                    );
                  })}
                </Checkbox.Group>
              </Menu>
            }
            trigger={["click"]}
            visible={filterVisible}
            onVisibleChange={setFilterVisible}
            placement="bottomCenter"
          >
            <Button
              style={{
                backgroundColor: "#00233f",
                color: "white",
                borderRadius: 4,
                height: 32,
                marginRight: 40,
                textTransform: "uppercase",
              }}
            >
              <FilterFilled /> Filter
            </Button>
          </Dropdown>
        </div>
        <RadioGroup
          defaultValue=""
          onChange={handleRadioSelection}
          style={{ width: "100%" }}
        >
          <List
            itemLayout="vertical"
            size="large"
            pagination={{
              pageSize: 3,
              showSizeChanger: false,
            }}
            dataSource={surveyListData}
            renderItem={(item) => (
              <List.Item key={item.title}>
                <Card
                  bordered={false}
                  style={{
                    overflowX: "auto",
                    width: "auto",
                    borderRadius: "10px",
                    background: "#EEEEEE",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          color: "#013B69",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: "1.5rem",
                          }}
                        >
                          {new Date(item.date).getUTCDate()}
                        </div>
                        <div style={{ fontWeight: "bold", lineHeight: "0px" }}>
                          {months[new Date(item.date).getUTCMonth()]}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "35px",
                        }}
                      >
                        <div style={{ fontSize: "1.1rem", fontWeight: "600" }}>
                          {item.name}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            opacity: "70%",
                            fontSize: "0.8rem",
                          }}
                        >
                          {item.tags.map((it, index) => {
                            return index === item.tags.length - 1 ? (
                              <div>{it}</div>
                            ) : (
                              <div>{it}/</div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="radio-buttons">
                      <Radio value={item}></Radio>
                    </div>
                  </div>
                </Card>
              </List.Item>
            )}
          />
        </RadioGroup>
        {/* <Table
          rowSelection={{
            type: "radio",
            ...rowSelection,
          }}
          columns={surveyListColumns}
          dataSource={surveyListData}
          size="small"
          pagination={{
            defaultPageSize: 5,
            pageSizeOptions: [5, 10, 15],
            position: ["bottomLeft"],
          }}
        ></Table> */}
      </Modal>
    </Fragment>
  );
};

export default PublishedSurveyListModal;
