import { MenuOutlined } from "@ant-design/icons";
import React from "react";
import { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Thumbnail from "../image-thumbnail/ImageThumbnail";
import "./image-ranking.css";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ListItem = ({ dragHandleProps, imgUrl, label, number }) => {
  return (
    <div className="image-ranking-box">
      <div>
        <MenuOutlined {...dragHandleProps} />
      </div>
      <div className="image-ranking-numberCircle">{number}</div>
      <Thumbnail imgUrl={imgUrl} />
      <div>{label}</div>
    </div>
  );
};

const ImageRanking = ({ key, onChange = () => {}, value }) => {
  const [list, setList] = useState(value);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(list, result.source.index, result.destination.index);

    setList(items);
    onChange(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} key={key}>
      <Droppable droppableId="ranking">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {list.map((field, index) => (
              <Draggable
                draggableId={index.toString()}
                key={index.toString()}
                index={index}
              >
                {(provided) => (
                  <div
                    className="image-ranking-draggable-box"
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                  >
                    <ListItem
                      imgUrl={field.imageData}
                      label={field.imageLabel}
                      number={index + 1}
                      dragHandleProps={provided.dragHandleProps}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ImageRanking;
