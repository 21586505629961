import { defaultAdminQuestion } from "../criteria/defaultCriteria";

const userTypes = {
  true: "clientsUser",
  false: "UQUser",
};
const surveyTypes = {
  true: "qualitative",
  false: "quantitative",
};
const modes = {
  true: "moderated",
  false: "unModerated",
};
const timings = {
  1: "15",
  2: "30",
  3: "45",
};
export const CostCounter = (survey, tier) => {
  let price = 0;
  const userType = userTypes[survey.usersType];
  const pricingSegment = tier.pricing;
  const surveyType = surveyTypes[survey.surveyType];
  const questionTypesPricing = pricingSegment.additionalCost.questionType;
  const criteriaPricing = pricingSegment.additionalCost.additionalCriteria;
  const influencerAudiencePricing =
    pricingSegment.additionalCost.influencerAudience;

  if (survey.surveyType === undefined) {
    return 0;
  }

  if (survey.surveyType) {
    let modeType = modes[survey.mode];
    let time = timings[survey.timingType];
    if (survey.mode !== undefined && survey.timingType !== undefined) {
      price += pricingSegment[surveyType][modeType][time][userType];
    }
  } else {
    price += pricingSegment[surveyType][userType];
  }

  survey.questions.map((question, index) => {
    if (Object.keys(questionTypesPricing).indexOf(question.type) > -1) {
      price += questionTypesPricing[question.type];
    }
  });

  if (!survey.usersType) {
    survey.criteria.map((question, index) => {
      if (defaultAdminQuestion.indexOf(question.questionId) == -1) {
        price += criteriaPricing;
      }
    });

    if (survey.audienceType) {
      price += survey.influencerCategories.length * influencerAudiencePricing;
    }
  }

  return price;
};

// {
//     "usersType": false,
//     "influencerCategories": [],
//     "questions": [],
//     "numberOfParticipants": 10,
//     "clientProvidedUsers": [],
//     "currentlyActive": 0,
//     "surveyStartsAt": "2021-04-12T21:11:22.502Z",
//     "surveyEndsAt": "2021-04-12T21:11:22.502Z",
//     "paymentType": true,
//     "paymentValue": 20,
//     "isPublished": false,
//     "isAdminVerified": false,
//     "_id": "607679b35eb26cdc555c7af6",
//     "clientId": "5fd88e45f22c230004487e52",
//     "criteria": [],
//     "screeningQuestions": [],
//     "responses": [],
//     "createdAt": "2021-04-14T05:12:19.262Z",
//     "updatedAt": "2021-04-14T05:12:19.262Z",
//     "__v": 0,
//     "name": "afasf"
// }

// {
//     "results": {
//         "individualResponsesCompare": false,
//         "questionSummariesCompare": false
//     },
//     "pricing": {
//         "qualitative": {
//             "moderated": {
//                 "15": {
//                     "clientsUser": 5000,
//                     "UQUser": 8000
//                 },
//                 "30": {
//                     "clientsUser": 6000,
//                     "UQUser": 9000
//                 },
//                 "45": {
//                     "clientsUser": 7000,
//                     "UQUser": 10000
//                 }
//             },
//             "unModerated": {
//                 "15": {
//                     "clientsUser": 3000,
//                     "UQUser": 4500
//                 },
//                 "30": {
//                     "clientsUser": 4000,
//                     "UQUser": 5500
//                 },
//                 "45": {
//                     "clientsUser": 5000,
//                     "UQUser": 6500
//                 }
//             }
//         },
//         "quantitative": {
//             "clientsUser": 50,
//             "UQUser": 300
//         },
//         "additionalCost": {
//             "questionType": {
//                 "imageInstruction": 250,
//                 "imageComment": 250,
//                 "imageMultiChoices": 250,
//                 "imageRanking": 250,
//                 "imageCheckBoxes": 300,
//                 "imageStarRatingChoices": 250,
//                 "imageABTest": 250,
//                 "imageAssociation": 250,
//                 "videoInstruction": 1500,
//                 "videoComment": 1500,
//                 "videoMultiChoices": 1500,
//                 "videoRanking": 1500,
//                 "videoCheckBoxes": 1800,
//                 "videoStarRatingChoices": 1500,
//                 "videoABTest": 1500,
//                 "videoAssociation": 1500
//             },
//             "additionalCriteria": 250,
//             "influencerAudience": 500
//         }
//     },
//     "_id": "5fda700af15c210004d22248",
//     "influencerAudience": true,
//     "advancedCriteria": true,
//     "advancedCriteriaQuestions": false,
//     "subscription": 0
// }
