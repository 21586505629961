export const questionTypes = {
  TEXT: "text",
  MULTI_CHOICE: "multiChoices",
  CHECKBOX: "checkBoxes",
  STARS: "stars",
  SLIDER: "slider",
  LIKERT: "likert",
  NPS: "netPromotorScore",
  RANKING: "ranking",
  DROPDOWN: "dropDown",
  ASSOCIATION: "association",
  IMAGE_INSTRUCTION: "imageInstruction",
  IMAGE_COMMENT: "imageComment",
  IMAGE_ABTEST: "imageABTest",
  IMAGE_MULTICHOICE: "imageMultiChoices",
  IMAGE_CHECKBOX: "imageCheckBoxes",
  IMAGE_STARRATINGCHOICE: "imageStarRatingChoices",
  IMAGE_RANKING: "imageRanking",
  IMAGE_ASSOCIATION: "imageAssociation",
  VIDEO_INSTRUCTION: "videoInstruction",
  VIDEO_COMMENT: "videoComment",
  VIDEO_MULTICHOICE: "videoMultiChoices",
  VIDEO_CHECKBOX: "videoCheckBoxes",
  VIDEO_STARRATINGCHOICE: "videoStarRatingChoices",
  VIDEO_RANKING: "videoRanking",
  VIDEO_ASSOCIATION: "videoAssociation",
  AGE: "age",
  CHECKBOXESSPECIAL: "checkboxesSpecial",
  AD_ANALYSIS: "adAnalysis",
};

export const specialTypes = [
  questionTypes.IMAGE_STARRATINGCHOICE,
  questionTypes.VIDEO_STARRATINGCHOICE,
  questionTypes.TEXT,
  questionTypes.IMAGE_COMMENT,
  questionTypes.VIDEO_COMMENT,
];

export const typeRequired = [
  questionTypes.TEXT,
  questionTypes.MULTI_CHOICE,
  questionTypes.CHECKBOX,
  questionTypes.STARS,
  questionTypes.LIKERT,
  questionTypes.IMAGE_MULTICHOICE,
  questionTypes.IMAGE_CHECKBOX,
  questionTypes.NPS,
  questionTypes.VIDEO_COMMENT,
  questionTypes.IMAGE_COMMENT,
  questionTypes.VIDEO_MULTICHOICE,
  questionTypes.VIDEO_CHECKBOX,
  questionTypes.IMAGE_STARRATINGCHOICE,
  questionTypes.VIDEO_STARRATINGCHOICE,
];
