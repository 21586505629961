import { Radio } from "antd";
import React from "react";
import Thumbnail from "../video-thumbnail/VideoThumbnail";
import { useMediaQuery } from "react-responsive";

const VideoMultipleChoice = ({ key, options, value, onChange = () => {} }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  return (
    <Radio.Group
      key={key}
      className="img-multiple-choice"
      defaultValue={value}
      onChange={(e) => onChange(e.target.value)}
    >
      {options.videoMultiChoices.map((val, index) => (
        <div className="img-multi-option-container">
          <Radio value={index}></Radio>
          <div
            style={{
              marginLeft: 8,
            }}
          >
            <Thumbnail url={val.videoData} />
          </div>
          <div className="img-checkboxes-label">{val.videoLabel}</div>
        </div>
      ))}
    </Radio.Group>
  );
};

export default VideoMultipleChoice;
