import React, { useState } from "react";
import axios, { Routes } from "../../../services/API";
import logo from "../../../logo.svg";
import handleNetworkErrors from "../handleNetworkErrors";
import { getRoleByToken } from "../../../utils";

function Account() {
  const [addBalance, setBalance] = useState(2000);
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const options = {
      key: "rzp_test_wIjrjqqlDLfHKq",
      amount: addBalance * 100,
      currency: "INR",
      name: "UserQual",
      description: "Adding Balance To UserQual Account",
      image: { logo },
      handler: async function (response) {
        try {
          const userId =
            getRoleByToken(localStorage.getItem("token")) === "client"
              ? localStorage.getItem("client")
              : "";
          const { data } = await axios({
            ...Routes.client.addBalance(userId),
            data: {
              data: {
                amount: addBalance,
                paymentId: response.razorpay_payment_id,
              },
            },
          });
        } catch (err) {
          handleNetworkErrors(err);
        }
        console.log({
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        });
      },
      prefill: {
        name: "Soumya Dey", //here the users details will come
        email: "SoumyaDey@example.com",
        contact: "9999999999",
      },
      theme: {
        color: "#61dafb",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  return (
    <div>
      <header>
        <img src={logo} alt="logo" />
        <input
          type="number"
          placeholder="Add Balance"
          value={addBalance}
          onChange={(e) => setBalance(e.target.value)}
        />
        <button onClick={displayRazorpay}>Pay {addBalance}</button>
      </header>
    </div>
  );
}

export default Account;
