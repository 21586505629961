import { branchingActions } from "../../../constants";
import { isNumber } from "lodash-es";
import {
  CONDITION_TYPE_ONE,
  CONDITION_TYPE_TWO,
  CONDITION_TYPE_THREE,
  CONDITION_TYPE_FOUR,
  CONDITION_TYPE_FIVE,
} from "./BranchingLogic";

const getBranchingErrors = (rules) => {
  const err = [];

  rules.forEach((rule, idx) => {
    const ruleNumber = idx + 1;

    // Conditions
    rule.questions.forEach((ques, i) => {
      const condNumber = i + 1;

      if (!isNumber(ques.index)) {
        err.push({
          description: `Criteria missing for Rule No. ${ruleNumber}, Condition No. ${condNumber}`,
        });
      }

      if (
        CONDITION_TYPE_ONE.includes(ques.condition) &&
        ques.choices?.length === 0
      ) {
        err.push({
          description: `Choice missing for Rule No. ${ruleNumber}, Condition No. ${condNumber}`,
        });
      }

      if (CONDITION_TYPE_TWO.includes(ques.condition) && !isNumber(ques.from)) {
        err.push({
          description: `From missing for Rule No. ${ruleNumber}. Condition No. ${condNumber}`,
        });
      }

      if (CONDITION_TYPE_TWO.includes(ques.condition) && !isNumber(ques.to)) {
        err.push({
          description: `To from for Rule No. ${ruleNumber}. Condition No. ${condNumber}`,
        });
      }

      if (CONDITION_TYPE_THREE.includes(ques.condition) && !ques.label) {
        err.push({
          description: `Label missing for Rule No. ${ruleNumber}. Condition No. ${condNumber}`,
        });
      }

      if (CONDITION_TYPE_THREE.includes(ques.condition) && !ques.rank) {
        err.push({
          description: `Rank missing for Rule No. ${ruleNumber}. Condition No. ${condNumber}`,
        });
      }

      if (CONDITION_TYPE_FOUR.includes(ques.condition) && !ques.label) {
        err.push({
          description: `Label missing for Rule No. ${ruleNumber}. Condition No. ${condNumber}`,
        });
      }

      if (CONDITION_TYPE_FOUR.includes(ques.condition) && !ques.association) {
        err.push({
          description: `Association missing for Rule No. ${ruleNumber}. Condition No. ${condNumber}`,
        });
      }

      if (CONDITION_TYPE_FIVE.includes(ques.condition) && !ques.label) {
        err.push({
          description: `Label missing for Rule No. ${ruleNumber}. Condition No. ${condNumber}`,
        });
      }

      if (
        CONDITION_TYPE_FIVE.includes(ques.condition) &&
        !isNumber(ques.from)
      ) {
        err.push({
          description: `From missing for Rule No. ${ruleNumber}. Condition No. ${condNumber}`,
        });
      }

      if (CONDITION_TYPE_FIVE.includes(ques.condition) && !isNumber(ques.to)) {
        err.push({
          description: `To from for Rule No. ${ruleNumber}. Condition No. ${condNumber}`,
        });
      }
    });

    // Actions
    rule.actions.forEach((action, i) => {
      const actionNumber = i + 1;

      if (
        [
          branchingActions.SKIP_TO_QUESTION,
          branchingActions.HIDE_QUESTION,
          branchingActions.INVALIDATE_QUESTION,
        ].includes(action.name) &&
        !isNumber(action.question)
      ) {
        err.push({
          description: `Question missing for Rule No. ${ruleNumber}, Action No. ${actionNumber}`,
        });
      }
    });
  });

  return err;
};

export default getBranchingErrors;
