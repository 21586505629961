import React, { Fragment } from "react";
import { surveyTypes } from "../../constants/constants";
import {
  getSurveyTime,
  getSurveyType,
  isGroupSurvey,
  isQuantSurvey,
} from "../../utils/utils";
import SurveyStartOptions from "./SurveyStartOptions";

const getInstructions = (surveyType) => {
  if (surveyType === surveyTypes.QUANTITATIVE) {
    return (
      <ul>
        <li>You will be asked a set of questions on your background</li>
        <li>
          We will <b>NOT</b> share this information with clients
        </li>
        <li>
          Please be <b>HONEST</b> as this is used to qualify you for surveys
        </li>
        <li>On completion please click the End button to upload the results</li>
        <li>Click on Green button below to start</li>
      </ul>
    );
  }
  if (surveyType === surveyTypes.QUALITATIVE_UNMODERATED) {
    return (
      <ul>
        <li>
          This survey requires you to test a website/product and record
          video/audio from your device
        </li>
        <li>Please be candid and honest with your feedback</li>
        <li>There are NO wrong answers – we need your real feedback!</li>
        <li>
          Once the survey is complete, please click on the End Survey button to
          upload the results
        </li>
        <li>You will get paid as per the terms</li>
        <li>
          Important: We <b>DO NOT SHARE</b> your details with clients
        </li>
      </ul>
    );
  }
  if (surveyType === surveyTypes.QUALITATIVE_MODERATED) {
    return (
      <ul>
        <li>This survey requires you to test a website/product</li>
        <li>
          It will be conducted by a moderator and will happen via a video call
        </li>
        <li>
          In order to match the time/date with the moderator, please choose your
          availability from the calendar below
        </li>
        <li>
          After that, you will receive an email and text for the date/time for
          this survey
        </li>
        <li>Once you confirm, please come back and take the survey</li>
        <li>You will get paid once the survey is done</li>

        <li>
          Important: We <b>DO NOT SHARE</b> your details with clients
        </li>
      </ul>
    );
  }
};

function InstructionModal({ survey, handleCancel, handleStartSurveyClick }) {
  const surveyType = getSurveyType(survey);

  let surveyTypeText = "";
  let surveyModeText = "";
  let surveySizeText = "";
  switch (surveyType) {
    case surveyTypes.QUANTITATIVE:
      surveyTypeText = "Textual Survey";
      surveyModeText = "unmoderated";

      break;
    case surveyTypes.QUALITATIVE_UNMODERATED:
      surveyTypeText = "Video Survey";
      surveyModeText = "Unmoderated";
      break;
    case surveyTypes.QUALITATIVE_MODERATED:
      surveyTypeText = "Video Survey";
      surveyModeText = "Moderated";
      break;
    default:
      surveyTypeText = "Error";
      surveyModeText = "Error";
      break;
  }

  if (isGroupSurvey(survey)) {
    surveySizeText = "Group Survey";
  } else {
    surveySizeText = "Individual Survey";
  }

  let renderPairs = [
    ["Survey name", survey.name],
    ["Survey type", surveyTypeText],
    ["Survey mode", surveyModeText],
    ["Survey time", getSurveyTime(survey)],
    [
      "Survey amount",
      survey.paymentValue ? "Rs. " + survey.paymentValue : "N/A",
    ],
  ];

  if (isQuantSurvey(survey)) {
    renderPairs = [
      ...renderPairs,
      ["Survey questions", survey.questions.length],
    ];
  } else {
    renderPairs = [...renderPairs, ["Survey size", surveySizeText]];
  }

  return (
    <Fragment>
      <div style={{ fontSize: "1rem", fontWeight: "bold", opacity: "80%" }}>
        Survey Details
      </div>

      <div style={{ display: "grid", gridTemplateColumns: "40% 40% 20%" }}>
        {renderPairs.map((pair, index) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px 0",
            }}
            key={index}
          >
            <div
              style={{
                fontSize: "0.8rem",
                fontWeight: "bold",
                opacity: "60%",
              }}
            >
              {pair[0]}
            </div>
            <div style={{ padding: "3px 0" }}>{pair[1]}</div>
          </div>
        ))}
      </div>

      <SurveyStartOptions
        survey={survey}
        handleCancel={handleCancel}
        handleStartSurveyClick={handleStartSurveyClick}
      />

      <p style={{ color: "#194475", textAlign: "left", margin: 20 }}>
        <h3 style={{ color: "#194475" }}>Instructions:</h3>
        {survey && getInstructions(surveyType)}
      </p>
    </Fragment>
  );
}

export default InstructionModal;
