import { useRef } from "react";

export const useVideoRef = () => {
  const videoRef = useRef(null);

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { width: 300 } })
      .then((stream) => {
        let videoObject = videoRef.current;
        videoObject.srcObject = stream;
        videoObject.play();
      })
      .catch((err) => {
        console.error("error:", err);
      });
  };
  const stopVideo = () => {
    let videoObject = videoRef.current;
    videoObject.srcObject.getTracks().forEach(function(track) {
        if (track.readyState == 'live' && track.kind === 'video') {
            track.stop();
        }
    })
  }
 
  return [videoRef, getVideo, stopVideo];
};
