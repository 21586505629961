import { Select, Button, message } from "antd";
import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AdAnalysisChoices from "./AdAnalysisChoices";
import styles from "./ad-analysis-choices.module.css";
import c from "classnames/bind";
import axios, { Routes } from "../../../../services/API";
import { notify } from "../../../../utils";
import { LoadingOutlined } from "@ant-design/icons";
import { Vmodal } from "../../result/IndividualReponses/ResponseBox/VideoModal/VideoModal";

const cx = c.bind(styles);

const AdAnalysis = ({ options, setOptions }) => {
  const [combining, setCombining] = useState(false);
  const [view, setView] = useState(false);
  const adAnalysisChoices = options.adAnalysisChoices;
  const adAnalysisData = options.adAnalysisData;

  const add = (item, index) => {
    setOptions({
      ...options,
      adAnalysisChoices: [
        ...adAnalysisChoices.slice(0, index),
        item,
        ...adAnalysisChoices.slice(index, adAnalysisChoices.length),
      ],
    });
  };

  const move = (source, dest) => {
    const result = Array.from(adAnalysisChoices);
    const [removed] = result.splice(source, 1);
    result.splice(dest, 0, removed);
    setOptions({ ...options, adAnalysisChoices: result });
  };

  const remove = (index) => {
    setOptions({
      ...options,
      adAnalysisChoices: [
        ...adAnalysisChoices.slice(0, index),
        ...adAnalysisChoices.slice(index + 1, adAnalysisChoices.length),
      ],
    });
  };

  const set = (item, index) => {
    const result = Array.from(adAnalysisChoices);
    result[index] = item;
    setOptions({ ...options, adAnalysisChoices: result });
  };

  const setOptionValue = (item, index) => {
    set(item, index);
    console.log("updated", options);
  };

  const handleCombineVideos = async () => {
    const clientId = localStorage.getItem("client");
    const data = {
      inputs: adAnalysisChoices.map((val) => ({
        bucketName: "userqual-file-upload",
        fileName: val.videoData.split("/")[3],
        clippings: [],
      })),
      output: {
        resolution: {
          height: 720,
          width: 1280,
        },
        nameModifier: "combinedVideo",
        bucket: "userqual-file-upload",
      },
    };
    setCombining(true);
    try {
      const response = await axios({
        ...Routes.client.mergeVideos(clientId),
        data: { data },
      });
      setOptions({
        ...options,
        adAnalysisData: {
          url: response.data.url,
        },
      });
      message.success("Successfully combined video");
    } catch (err) {
      notify("error", "Error", err.toString());
    } finally {
      setCombining(false);
    }
  };

  return (
    <div>
      <DragDropContext
        onDragEnd={(result) =>
          move(result.source.index, result.destination.index)
        }
      >
        <Droppable droppableId="list">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                paddingTop: 20,
              }}
            >
              {adAnalysisChoices &&
                adAnalysisChoices.map((choice, index) => (
                  <Draggable
                    draggableId={`option-${index}`}
                    index={index}
                    key={index}
                  >
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        gutter={6}
                      >
                        <AdAnalysisChoices
                          dragHandleProps={provided.dragHandleProps}
                          choiceNumber={index + 1}
                          choice={choice}
                          setLabel={(item) => setOptionValue(item, index)}
                          totalChoices={adAnalysisChoices.length}
                          isFilled={{
                            videoType: choice.Type !== "",
                            videoData: choice.videoData !== "",
                          }}
                          addChoice={(newIndex) =>
                            add({ videoData: "" }, newIndex)
                          }
                          removeChoice={(index) => remove(index - 1)}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className={cx("grid-container")}>
        <div></div>
        <div>
          <Select
            defaultValue="16:9"
            size="large"
            className="input-border"
            style={{ marginRight: 5 }}
          >
            <Select.Option value="16:9">16:9</Select.Option>
            <Select.Option value="1:1">1:1</Select.Option>
            <Select.Option value="9:16">9:16</Select.Option>
            <Select.Option value="5:4">5:4</Select.Option>
          </Select>
          <Select
            defaultValue="fit"
            size="large"
            className="input-border"
            style={{ marginRight: 5 }}
          >
            <Select.Option value="fit">Fit with border</Select.Option>
          </Select>
          <Button
            size="large"
            style={{ backgroundColor: "#F2F2F2", marginRight: 5 }}
            onClick={handleCombineVideos}
          >
            Combine{combining ? <LoadingOutlined /> : null}
          </Button>
          <Button
            size="large"
            style={{ backgroundColor: "#F2F2F2" }}
            onClick={() => setView(true)}
          >
            View
          </Button>
          {view && (
            <Vmodal
              isOpen={view}
              onCancel={() => setView(false)}
              videoUrl={adAnalysisData.url}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdAnalysis;
