import { Button, Col, Input, Row, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import ProjectPageLayout, {
  Content,
} from "../../../components/page-layout/PageLayout";
import Topbar from "../../../components/topbar/Topbar";
import Sidenav from "../../../components/admin/sidenav/Sidenav";
import { PlusOutlined } from "@ant-design/icons";
import styles from "./client-info.module.css";
import ResponsiveTable from "../../../components/responsive-table/ResponsiveTable";
import ConfigClientInfoModal from "../../../components/admin/client-info/config-client-info-modal/ConfigClientInfoModal";
import c from "classnames/bind";
import {
  decrementPendingRequests,
  incrementPendingRequests,
  notify,
} from "../../../utils";
import { useDispatch } from "react-redux";
import { addClient, editClient, getClients } from "../../../actions/admin";
import { adminPages } from "../../../constants";
import moment from "moment";

const cx = c.bind(styles);

const ClientInfo = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [configClientInfoModal, setConfigClientInfoModal] = useState(false);
  const [selectedClientInfo, setSelectedClientInfo] = useState();

  // Api calls
  const loadClients = useCallback(async () => {
    incrementPendingRequests();
    dispatch(getClients())
      .then((clients) => setData(clients))
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  }, [dispatch]);

  useEffect(() => {
    loadClients();
  }, [loadClients]);

  // Handlers
  const onSubmit = (mode, values) => {
    switch (mode) {
      case "add":
        incrementPendingRequests();
        dispatch(addClient(values))
          .then(() => {
            notify("success", "Success", "Client added");
            loadClients();
          })
          .catch((ex) => notify("error", "Error", ex))
          .finally(() => decrementPendingRequests());
        break;

      case "edit":
        incrementPendingRequests();
        dispatch(editClient(values._id, values))
          .then(() => {
            notify("success", "Success", "Client updated");
            loadClients();
          })
          .catch((ex) => notify("error", "Error", ex))
          .finally(() => decrementPendingRequests());
        break;

      default:
        break;
    }
  };

  const columns = [
    {
      title: "#",
      key: "no",
      align: "center",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Client name",
      align: "center",
      dataIndex: "companyName",
      key: "client",
      className: "no-padding",
    },
    {
      title: "Tier",
      align: "center",
      dataIndex: "accountTier",
      key: "tier",
      render: (text) => {
        switch (text) {
          case 0:
            return "Basic";
          case 1:
            return "Elite";
          case 2:
            return "Platinum";
          default:
            return;
        }
      },
    },
    {
      title: "City",
      align: "center",
      dataIndex: "address",
      key: "city",
      render: (text) => text && text.city,
    },
    {
      title: "Quant Surveys",
      align: "center",
      key: "quant",
      render: (text, record) => {
        let count = 0;
        record.surveys.forEach((val) => {
          if (!val.surveyType) {
            count++;
          }
        });
        return count;
      },
    },
    {
      title: "Qual Surveys",
      align: "center",
      key: "qual",
      render: (text, record) => {
        let count = 0;
        record.surveys.forEach((val) => {
          if (val.surveyType) {
            count++;
          }
        });
        return count;
      },
    },
    {
      title: "Amount Spent",
      align: "center",
      key: "amount_spent",
      render: (text, record) => {
        let count = 0;
        record.surveys.forEach((val) => {
          count += val.paymentValue;
        });
        return count;
      },
    },
    {
      title: "Amount Owned",
      align: "center",
      dataIndex: "accountBalance",
      key: "amount_owned",
    },
    {
      title: "Date Completed",
      align: "center",
      dataIndex: "planExpiresAt",
      key: "date_completed",
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },
    {
      key: "edit",
      align: "center",
      dataIndex: "edit",
      render: (text, record) => (
        <Button
          onClick={() => {
            setConfigClientInfoModal(true);
            setSelectedClientInfo(record);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Topbar>
        <Sidenav drawer selectedItem={adminPages.CLIENTS} />
      </Topbar>
      <Sidenav selectedItem={adminPages.CLIENTS} />
      <ProjectPageLayout>
        <Content style={{ padding: 5 }}>
          <div className={cx("admin-page-container")}>
            <Row
              gutter={[6, 6]}
              style={{ marginBottom: 10 }}
              justify="space-between"
            >
              <Col>
                <Select
                  placeholder="Sort by"
                  className="input-border"
                  size="large"
                  style={{ minWidth: 188 }}
                >
                  <Select.Option value="ascend">Ascending</Select.Option>
                  <Select.Option value="descend">Descending</Select.Option>
                </Select>
              </Col>
              <Col>
                <Input
                  placeholder="Search"
                  className="input-border"
                  size="large"
                />
              </Col>
            </Row>
            <ResponsiveTable columns={columns} dataSource={data} />
            <div className={cx("client-info-actions")}>
              <Button
                type="primary"
                style={{ width: 200 }}
                onClick={() => setConfigClientInfoModal(true)}
              >
                <PlusOutlined /> Add client
              </Button>
            </div>
          </div>
        </Content>
      </ProjectPageLayout>
      {configClientInfoModal && (
        <ConfigClientInfoModal
          handleCancel={() => {
            setConfigClientInfoModal(false);
            setSelectedClientInfo(undefined);
          }}
          clientInfo={selectedClientInfo}
          onSubmit={onSubmit}
        />
      )}
    </React.Fragment>
  );
};

export default ClientInfo;
