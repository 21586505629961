import React, { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";
import ScreeningWrapper from "./screening/ScreeningWrapper";
import Survey from "./Survey";
import { getSurveyType, isModeratedSurvey } from "../../utils/utils";
import { useSurveyEventHandlers } from "../../hooks/useSurveyEventHandlers";
import { hasScreeningQuestions } from "../../utils/screeningUtils";
import useHandleDisqualification from "../../hooks/useHandleDisqualification";
import Meeting from "./moderatedCall/meeting";
import QuestionPopup from "./moderatedCall/QuestionPopup/QuestionPopup";
import ModeratedSurvey from "./moderatedCall/ModeratedSurvey";
import PermDenied from "./permDenied";

function SurveyWrapper({ survey, userId, onExit, token }) {
  const [isScreeningQuestion, setIsScreeningQuestion] = useState(
    hasScreeningQuestions(survey)
  );
  const questions = survey.questions;
  const [onStartSurvey, onEndSurvey, closeSurvey] = useSurveyEventHandlers(
    survey,
    userId
  );
  const handleDisqualification = useHandleDisqualification();
  const onDisqualification = async () => {
    handleDisqualification(survey);
  };
  const [showPermDeniedPage, setShowPermDeniedPage] = useState(false)
  useEffect( () => {
    if (isScreeningQuestion) return; 
    (async () => {
      const startSurveyResult = await onStartSurvey();
      if(startSurveyResult.showPermissionDeniedPage) {
        setShowPermDeniedPage(true)
      }
    })()
  }, [isScreeningQuestion]);

  return (
    <Fragment>
      {showPermDeniedPage ? (<PermDenied onClose={onExit}></PermDenied>) : 
      isScreeningQuestion ? (
        <ScreeningWrapper
          survey={survey}
          onPass={() => setIsScreeningQuestion(false)}
          onClose={onExit}
        />
      ) : isModeratedSurvey(survey) ? (
        <ModeratedSurvey survey={survey} userId={userId} surveyAccess={survey.surveyAccess} token={token}/>
      ) : (
        <Survey
          surveyType={getSurveyType(survey)}
          surveyAccess={survey.surveyAccess}
          questions={questions}
          rules={survey?.rules}
          isOpen={!isScreeningQuestion}
          onClose={() => {
            closeSurvey();
            onExit();
          }}
          onEndSurvey={(answers) => {
            onEndSurvey(answers, questions, survey.surveyAccess);
            if (
              window.location.pathname.includes("user")
            )
              onExit();
          }}
          onDisqualification={onDisqualification}
          brandLogo={survey.brandLogo}
          brandName={survey.brandName}
          surveyInstructions={survey.instructions}
        ></Survey>
      )
       } 
    </Fragment>
  );
}

export default SurveyWrapper;
