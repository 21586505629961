import React, { useState } from "react";
import Features from "../components/Features/Features";
import Market from "../components/Market/Market";
import Footer from "../components/Footer/Footer";
import Trial from "../components/Trial/Trial";
import Laptop from "../components/Laptop/Laptop";
import Homex from "../components/Home/Home";
import Brands from "../components/Brands/Brands";
// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import HomeCSSLoad from "./HomeCSSLoad";
import { useEffect } from "react";
import { Spin } from "antd";
import { Fragment } from "react";

const Home = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);
  return (
    <div>
      <HomeCSSLoad />
      {loading ? (
        <Spin spinning={true} size="large"></Spin>
      ) : (
        <Fragment>
          <Homex />
          <Laptop />
          <Features />
          <Market />
          <Brands />
          <Trial />
          <Footer />
        </Fragment>
      )}
    </div>
  );
};

export default Home;
