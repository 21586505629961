import React from "react";
import { Card } from "antd";
import { FileTextFilled, VideoCameraFilled } from "@ant-design/icons";
import { getScheduledSlotForUser } from "../utils/moderated/schedule";
import { getSurveyTime } from "../utils/utils";

function SurveyCard({ survey, userId, key, handleClick }) {
  const { surveyDate, surveyTime } = getScheduledSlotForUser(
    survey.moderatedSlots,
    userId
  );

  const surveyHeader =
    survey &&
    `${survey.name} - ${
      survey.surveyType
        ? "Video /" +
          (survey.mode ? " Moderated /" : " UnModerated /") +
          (survey.setting ? " Group" : " Individual")
        : "Quantitative"
    }`;

  const surveyTimeSlot =
    survey &&
    `${
      surveyDate && surveyTime
        ? survey.setting &&
          survey.moderatedSlots[surveyDate][surveyTime].length !== 5
          ? "Scheduling..."
          : "Scheduled for " + surveyTime + " on " + surveyDate
        : ""
    }`;

  return (
    <Card
      className="basic-questions-collapse"
      key={key}
      style={{ cursor: "pointer" }}
      onClick={() => handleClick(survey, key)}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <span
          style={{
            borderRadius: "50%",
            width: "45px",
            height: "45px",
            backgroundColor: "rgb(246, 144, 130)",
            textAlign: "center",
          }}
        >
          {!survey.surveyType ? (
            <FileTextFilled
              style={{
                fontSize: "1.25rem",
                position: "relative",
                top: "50%",
                transform: "translateY(-50%)",
                color: "white",
              }}
            />
          ) : (
            <VideoCameraFilled
              style={{
                fontSize: "1.25rem",
                position: "relative",
                top: "50%",
                transform: "translateY(-50%)",
                color: "white",
              }}
            />
          )}
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "20px",
          }}
        >
          <div
            style={{
              margin: "auto 0",
              fontWeight: "bold",
              opacity: "80%",
            }}
          >
            {`${surveyHeader} ${surveyTimeSlot}`}
          </div>
          <div
            style={{
              fontSize: "0.85rem",
              opacity: "60%",
              paddingTop: "5px",
            }}
          >
            {`${
              survey.surveyType ? "Video Survey" : "Textual Survey"
            } | ${getSurveyTime(survey)} ${
              survey.questions
                ? "| " + survey.questions.length + " questions"
                : ""
            } ${survey.paymentValue ? "| Rs. " + survey.paymentValue : ""}
`}
          </div>
        </div>
      </div>
    </Card>
  );
}

export default SurveyCard;
