import axios, { Routes } from "../../../../services/API";
import {
  decrementPendingRequests,
  getUserId,
  incrementPendingRequests,
} from "../../../../utils";

function useGetSurvey() {
  const getSurveyAPI = async (surveyId) => {
    try {
      incrementPendingRequests();
      const res = await axios({
        ...Routes.user.getSurvey(getUserId(), surveyId),
      });

      if (res.status === 200) {
        console.log("success", "Success", res.data.message);
        return res.data.survey;
      }
      return null;
    } catch (err) {
      console.log("error", "Error", err.response.data.message);
      return null;
    } finally {
      decrementPendingRequests();
    }
  };

  return getSurveyAPI;
}

export default useGetSurvey;
