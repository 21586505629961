import React from "react";
import { Form, Modal, Input, Select, Row, Col, Button } from "antd";
import { useMediaQuery } from "react-responsive";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

const EditInfluencerModal = (props) => {
  const { clientInfo, handleCancel } = props;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const [form] = Form.useForm();

  const size = isTabletOrMobile ? "medium" : "large";

  const onEditInfluencer = () => {};

  const footer = (
    <div>
      <Button
        onClick={handleCancel}
        style={{ border: "1px solid #004479", marginRight: 10, width: 100 }}
      >
        Cancel
      </Button>
      <Button type="primary" onClick={onEditInfluencer} style={{ width: 100 }}>
        Save
      </Button>
    </div>
  );

  return (
    <Modal
      title=""
      closable={false}
      visible={true}
      footer={footer}
      onCancel={handleCancel}
    >
      <Form initialValues={clientInfo} form={form}>
        <Row gutter={6}>
          <Col xs={12}>
            <Form.Item name="firstname">
              <Input
                placeholder="First Name"
                className="input-border"
                size={size}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item name="lastname">
              <Input
                placeholder="Last Name"
                className="input-border"
                size={size}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col xs={8}>
            <Form.Item name="gender">
              <Select placeholder="Gender" className="input-border" size={size}>
                <Select.Option value="male">Male</Select.Option>
                <Select.Option value="female">Female</Select.Option>
                <Select.Option value="other">Other</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item name="age">
              <Input placeholder="Age" className="input-border" size={size} />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item name="rate">
              <Input placeholder="Rate" className="input-border" size={size} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col xs={12}>
            <Form.Item name="company_name">
              <Input
                placeholder="Company Name"
                className="input-border"
                size={size}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item name="gst">
              <Input
                placeholder="GST Number"
                className="input-border"
                size={size}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col xs={14}>
            <Form.Item name="address">
              <Input
                placeholder="Address"
                className="input-border"
                size={size}
              />
            </Form.Item>
          </Col>
          <Col xs={10}>
            <Form.Item name="city">
              <Input placeholder="City" className="input-border" size={size} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col xs={14}>
            <Form.Item name="state">
              <Input placeholder="State" className="input-border" size={size} />
            </Form.Item>
          </Col>
          <Col xs={10}>
            <Form.Item name="postal">
              <Input
                placeholder="Postal Code"
                className="input-border"
                size={size}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.List name={["social_media"]}>
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((smField, index) => (
                  <Row gutter={12} key={smField.name}>
                    <Col span={10}>
                      <Form.Item
                        name={[smField.name, "platform"]}
                        key={[smField.name, "platform"]}
                      >
                        <Select
                          placeholder="Platform"
                          className="input-border"
                          size={size}
                        >
                          <Select.Option value="facebook">
                            Facebook
                          </Select.Option>
                          <Select.Option value="instagram">
                            Instagram
                          </Select.Option>
                          <Select.Option value="snapchat">
                            Snapchat
                          </Select.Option>
                          <Select.Option value="tiktok">Tiktok</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        name={[smField.name, "followers"]}
                        key={[smField.name, "followers"]}
                      >
                        <Input
                          placeholder="Number of followers"
                          className="input-border"
                          size={size}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4} className="no-hover actions">
                      <PlusCircleOutlined
                        onClick={() => add(null, smField.name + 1)}
                        className="action-icon"
                      />
                      <MinusCircleOutlined
                        className="action-icon"
                        onClick={() => remove(smField.name)}
                      />
                    </Col>
                  </Row>
                ))}
                {fields.length === 0 && (
                  <Button
                    size="large"
                    style={{
                      marginBottom: 26,
                      border: "1px solid #004479",
                      color: "#004479",
                    }}
                    onClick={() => add()}
                  >
                    Add Social Media
                  </Button>
                )}
              </>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default EditInfluencerModal;
