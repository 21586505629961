import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import RegQuestions from "./registration-questions/RegQuestions";
import SampleQuestions from "./sample-questions/SampleQuestions";
import AdditionalQuestions from "./additional-questions/AdditionalQuestions";
import UserSurvey from "./users-survey/UserSurvey";
import Influencer from "./influencer/Influencer";
import ClientInfo from "./client-info/ClientInfo";
import Pricing from "./pricing/Pricing";
import Features from "./features/Features";
import { useDispatch } from "react-redux";
import {
  decrementPendingRequests,
  incrementPendingRequests,
  notify,
} from "../../utils";
import { getTierInfo } from "../../actions/admin";

const Admin = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    incrementPendingRequests();
    dispatch(getTierInfo())
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Switch>
        <Route
          exact
          path="/admin/registration-questions"
          component={RegQuestions}
        />
        <Route
          exact
          path="/admin/sample-questions"
          component={SampleQuestions}
        />
        <Route
          exact
          path="/admin/additional-questions"
          component={AdditionalQuestions}
        />
        <Route exact path="/admin/surveys" component={UserSurvey} />
        <Route exact path="/admin/influencers" component={Influencer} />
        <Route exact path="/admin/clients" component={ClientInfo} />
        <Route exact path="/admin/pricing" component={Pricing} />
        <Route exact path="/admin/features" component={Features} />
      </Switch>
    </React.Fragment>
  );
};

export default Admin;
