import { Radio, Space } from "antd";
import React from "react";
import { capitalizeFirstCharacter } from "../../../../../../utils";
import "./multiple-choice.css";

const MultipleChoice = ({ key, choices, onChange = () => {}, value }) => {
  return (
    <Radio.Group
      key={key}
      size="large"
      className="multipleChoice"
      onChange={(e) => onChange(e.target.value)}
      defaultValue={value}
    >
      {choices.map((val, index) => (
        <Radio className="multi-choice-box" value={index}>
          {capitalizeFirstCharacter(val.label)}
        </Radio>
      ))}
    </Radio.Group>
  );
};

export default MultipleChoice;
