import React, { useEffect, useState } from "react";
import ProjectPageLayout, {
  InnerContainer,
  Content,
} from "../../../components/page-layout/PageLayout";
import { Row, Col, Table, Button, notification } from "antd";
import axios, { Routes } from "../../../services/API";
import "../../../components/responsive-table/responsive-table.css";
import handleNetworkErrors from "../handleNetworkErrors";
import moment from "moment";
import ShareSurveyModal from "../published-survey/Modals/ShareSurveyModal/ShareSurveyModal";
import { fetchUsers } from "../../../utils";

const fetchSurveyInfo = async () => {
  const clientId = localStorage.getItem("client");
  const { data } = await axios({
    ...Routes.client.fetchSurveyInfo(clientId),
  });

  return data;
};

const Requests = ({ accountId }) => {
  const [totalSurveyCount, setTotalSurveyCount] = useState(0);
  const [publishedSurveyCount, setPublishedSurveyCount] = useState(0);
  const [pendingSurveys, setPendingSurveys] = useState([]);
  const [showPublishSurveyModal, setShowPublishSurveyModal] = useState(false);
  const [activeSurvey, setActiveSurvey] = useState(null);
  const [activeSurveyReferToken, setActiveSurveyReferToken] = useState(null);
  const [activeSurveyId, setActiveSurveyId] = useState(null);
  const [accountUsersInfo, setAccountUsersInfo] = useState([]);

  useEffect(() => {
    (async () => {
      const users = await fetchUsers(accountId);
      setAccountUsersInfo(users);
    })();
  }, []);

  useEffect(() => {
    if (activeSurvey === null) {
      setActiveSurveyReferToken(null);
      setActiveSurveyId(null);
    } else {
      setActiveSurveyReferToken(activeSurvey.referSurveyToken);
      setActiveSurveyId(activeSurvey._id);
    }
  }, [activeSurvey]);

  const getName = (clientId) => {
    const user = accountUsersInfo.find((user) => user.clientId === clientId);
    if (!user) return "";
    return user.firstName ? user.firstName + " " + user.lastName : user.email;
  };

  const refetchData = async () => {
    try {
      const data = await fetchSurveyInfo();
      setTotalSurveyCount(data.count.totalSurveys);
      setPublishedSurveyCount(data.count.publishedSurveys);
      setPendingSurveys(data.pendingForApproval);
    } catch (err) {
      handleNetworkErrors(err);
    }
  };

  const showShareSurveyModal = (survey) => {
    setActiveSurvey(survey);
    setShowPublishSurveyModal(true);
  };

  const publishSurveyHandler = async () => {
    await publishSurvey(activeSurveyId);
    setShowPublishSurveyModal(false);
  };

  const publishSurvey = async (surveyId) => {
    try {
      const clientId = localStorage.getItem("client");
      await axios({
        ...Routes.client.publishSurvey(clientId, surveyId),
      });
      await refetchData();
      notification.success({
        message: "Survey published successfully.",
      });
    } catch (err) {
      handleNetworkErrors(err);
    }
  };

  const rejectSurvey = async (surveyId) => {
    try {
      const clientId = localStorage.getItem("client");
      await axios({
        ...Routes.client.rejectSurvey(clientId, surveyId),
      });
      await refetchData();
    } catch (err) {
      handleNetworkErrors(err);
    }
  };

  const columns = [
    {
      title: "Sr. no.",
      key: "no",
      align: "center",
      width: "4%",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Survey Name",
      key: "survey_name",
      align: "left",
      dataIndex: "name",
      width: "20%",
    },
    {
      title: "Survey Type",
      key: "survey_type",
      align: "left",
      render: (text, record) => (record.surveyType ? "Qual" : "Quant"),
      width: "10%",
    },
    {
      title: "Created By",
      key: "created_by",
      align: "left",
      render: (text, record) => <span>{getName(record.clientId)}</span>,
      width: "12%",
    },
    {
      title: "Published By",
      key: "published_by",
      align: "left",
      render: (text, record) => (
        <span>{getName(record.publisherId || record.clientId)}</span>
      ),
      width: "12%",
    },
    {
      title: "Created date",
      key: "createdDate",
      align: "left",
      dataIndex: "createdAt",
      render: (text) => moment(text).format("DD/MM/YYYY"),
      width: "10%",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "30%",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            columnGap: 12,
          }}
        >
          <Button
            style={{
              backgroundColor: "#fceccf",
              color: "#d2923b",
              border: "none",
              textTransform: "uppercase",
              borderRadius: 4,
              width: 150,
            }}
            onClick={() => {
              rejectSurvey(record._id);
            }}
          >
            Reject
          </Button>
          <Button
            style={{
              backgroundColor: "#00233f",
              color: "white",
              border: "none",
              textTransform: "uppercase",
              borderRadius: 4,
              width: 150,
            }}
            onClick={() => {
              showShareSurveyModal(record);
            }}
          >
            Publish
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.setAttribute("data-new-theme", true);

    return () => {
      document.body.removeAttribute("data-new-theme");
    };
  }, []);

  useEffect(() => {
    let fetching = true;
    fetchSurveyInfo().then((data) => {
      if (fetching) {
        setTotalSurveyCount(data.count.totalSurveys);
        setPublishedSurveyCount(data.count.publishedSurveys);
        setPendingSurveys(data.pendingForApproval);
      }
    });

    return () => {
      fetching = false;
    };
  }, []);

  return (
    <>
      <ProjectPageLayout>
        <Content contentClass="history-page-content">
          <InnerContainer
            style={{
              padding: 0,
            }}
          >
            <Row gutter={[8, 20]}>
              <Col span={24} style={{ padding: 20, paddingTop: 20 }}>
                <Row gutter={8} justify="space-between">
                  <Col xs={24} sm={18}>
                    <h2 style={{ margin: 0 }}>Publish Survey</h2>
                    <div>Manage publish status for all surveys</div>
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ padding: 20 }}>
                <Row gutter={[32, 8]}>
                  <Col xs={24} sm={8}>
                    <div
                      style={{
                        padding: 12,
                        backgroundColor: "#fceccf",
                        borderRadius: 4,
                      }}
                    >
                      <div
                        style={{
                          color: "#d2923b",
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {pendingSurveys.length}
                      </div>
                      <div style={{ color: "#d2923b" }}>
                        Pending for approval
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={8}>
                    <div
                      style={{
                        padding: 12,
                        backgroundColor: "#eeeeee",
                        borderRadius: 4,
                      }}
                    >
                      <div
                        style={{
                          color: "#424242",
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {publishedSurveyCount}
                      </div>
                      <div style={{ color: "#616161" }}>Surveys published</div>
                    </div>
                  </Col>
                  <Col xs={24} sm={8}>
                    <div
                      style={{
                        padding: 12,
                        backgroundColor: "#eeeeee",
                        borderRadius: 4,
                      }}
                    >
                      <div
                        style={{
                          color: "#424242",
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {totalSurveyCount}
                      </div>
                      <div style={{ color: "#616161" }}>Total surveys</div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ padding: 0 }}>
                <Table
                  size="small"
                  style={{
                    wordBreak: "break-word",
                  }}
                  pagination={false}
                  className="history-table"
                  dataSource={pendingSurveys}
                  columns={columns}
                />
              </Col>
            </Row>
          </InnerContainer>
          <ShareSurveyModal
            handleOk={publishSurveyHandler}
            handleCancel={() => setShowPublishSurveyModal(false)}
            isPublish={true}
            isVisible={showPublishSurveyModal}
            surveyToken={activeSurveyReferToken}
          />
        </Content>
      </ProjectPageLayout>
    </>
  );
};

export default Requests;
