import React, { useEffect, useState } from "react";
import { styled } from "@linaria/react";
// import c from "classnames/bind";
import { Flex } from "../draft/DraftFormBox";
import {
  MobileOutlined,
  DesktopOutlined,
  EyeOutlined,
  TeamOutlined,
  UserOutlined,
  StopOutlined,
} from "@ant-design/icons";
import Survey from "../../user/survey/Survey";
import moment from "moment";
import { notification, Row } from "antd";
import { useHistory } from "react-router-dom";
import SurveyStateModal from "./Modals/SurveyStateModal/SurveyStateModal";
import { surveyStates } from "../../../constants";
import { getRoleByToken, incrementPendingRequests } from "../../../utils";
import axios, { Routes } from "../../../services/API";
import styles from "./PublishedFormBoxNew.module.css";
import c from "classnames/bind";
import handleNetworkErrors from "../handleNetworkErrors";

const cx = c.bind(styles);

const ScheduledCallsBox = styled.div``;

const PublishedFormBoxRefactored = ({
  survey,
  surveyCost,
  surveyId,
  updateSurveys,
  name,
  surveyType,
  mode,
  device,
  questions,
  setting,
  timingType,
  createdBy,
  updatedAt,
  numberOfParticipants,
  numberOfResponses,
  isAdminVerified,
  onScheduledCallsClick,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [surveyState, setSurveyState] = useState(survey.status);
  const [surveyStateModal, setSurveyStateModal] = useState(false);

  const history = useHistory();

  const getStatus = () => {
    if (!isAdminVerified) return "Pending";
    if (numberOfResponses === numberOfParticipants) return "Completed";
    return "In Progress";
  };

  const updateSurvey = async (values) => {
    const id =
      getRoleByToken(localStorage.getItem("token")) === "client"
        ? localStorage.getItem("client")
        : "";
    try {
      const { data } = await axios({
        ...Routes.client.editSurvey(id, survey["_id"]),
        data: {
          data: values,
        },
      });
      notification.success({
        message: "Survey updated successfully",
      });
      updateSurveys();
    } catch (err) {
      handleNetworkErrors(err);
    }
  };

  return (
    <React.Fragment>
      <div
        className={cx("published-form-box")}
        style={{
          border:
            getStatus() === "Pending"
              ? "1px solid #BFBFBF"
              : getStatus() === "In Progress"
              ? "1px solid  #FF9480"
              : "1px solid #40E0D0",
        }}
      >
        <div className="flex-box" row>
          <span style={{ flexGrow: 1 }}>Published / {getStatus()}</span>
          <span style={{ display: "flex", flexDirection: "column" }}>
            <EyeOutlined
              style={{ fontSize: 24, cursor: "pointer" }}
              onClick={() => setShowModal(true)}
            />
            <StopOutlined
              onClick={() => setSurveyStateModal(true)}
              style={{
                fontSize: 20,
                cursor: "pointer",
                color:
                  surveyStates.IN_PROGRESS === surveyState
                    ? "#8693A1"
                    : surveyStates.PAUSE === surveyState
                    ? "#88CDD5"
                    : "#B02418",
              }}
              title={
                surveyStates.IN_PROGRESS === surveyState
                  ? "Survey Live"
                  : surveyStates.PAUSE === surveyState
                  ? "Survey Paused"
                  : "Survey Ended"
              }
            />
          </span>
        </div>
        {isAdminVerified === true && surveyType === true && mode === true ? (
          <Flex alignSelf="center">
            <div
              className={cx("scheduled-call-button")}
              onClick={() => onScheduledCallsClick(surveyId)}
            >
              Scheduled Calls
            </div>
          </Flex>
        ) : null}
        <div
          className="flex-box"
          style={{ alignSelf: "center" }}
          alignSelf="center"
        >
          <div
            style={{
              "background-color":
                getStatus() === "Pending"
                  ? "#BFBFBF"
                  : getStatus() === "In Progress"
                  ? "#FF9480"
                  : "#40E0D0",
              cursor: "pointer",
            }}
            className={cx("center-box")}
            onClick={() => history.push("/client/results", { surveyId })}
          >
            Results: {numberOfResponses}/{numberOfParticipants}
          </div>
        </div>
        <div className={cx("flex-box")}>
          <div>
            <div>{name && `${name}`}</div>
            <div>{createdBy}</div>
            <div>{moment(updatedAt).format("DD/MM/YYYY")}</div>
          </div>
          <div className={cx("survey-info")}>
            {surveyType !== undefined && (
              <div>
                {surveyType === true ? "Qualitative" : "Quantitative"}
                {surveyType === true &&
                  mode !== undefined &&
                  (mode === true ? "/Moderated" : "/Unmoderated")}
              </div>
            )}
            {surveyType === true && device !== undefined && (
              <div>
                {device === true ? <MobileOutlined /> : <DesktopOutlined />}
              </div>
            )}
            {surveyType === true && mode === true && setting !== undefined && (
              <div>
                {setting === true ? <TeamOutlined /> : <UserOutlined />}
              </div>
            )}
            {surveyType === true && timingType !== undefined && (
              <div>
                {timingType === 1 && "15 mins"}
                {timingType === 2 && "30 mins"}
                {timingType === 3 && "45 mins"}
              </div>
            )}
            {surveyType === false && (
              <div>
                <MobileOutlined /> / <DesktopOutlined />
              </div>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <Survey
          isOpen={showModal}
          questions={questions}
          surveyType={surveyType}
          onClose={() => setShowModal(false)}
        />
      )}
      {surveyStateModal && (
        <SurveyStateModal
          surveyCost={surveyCost}
          handleCancel={() => setSurveyStateModal(false)}
          handleOk={(state) => {
            setSurveyStateModal(false);
            updateSurvey({
              status: state,
            });
          }}
          surveyState={surveyState}
          updateSurvey={updateSurvey}
          survey={survey}
          isVisible={surveyStateModal}
        />
      )}
    </React.Fragment>
  );
};

export default PublishedFormBoxRefactored;
