import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Tooltip,
  Select,
  Button,
  Upload,
  message,
  Modal as Modal2,
} from "antd";
import Modal from "react-modal";
import { ExplainerVideo } from "../../../components/user/confused/Confused";
import styles from "./setup-style.module.css";
import Errors from "../new-survey/Errors";
import useArray from "../useArray";
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  DownOutlined,
  UpOutlined,
  CloudUploadOutlined,
  LoadingOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { InnerContainer } from "../../../components/page-layout/PageLayout";
import axios, { Routes } from "../../../services/API";
import Survey from "../../user/survey/Survey";
import { GetFilename } from "../../../constants";

export const RadioButton = ({ isMobile, label, onClick, selected, last }) => {
  let classes;
  if (selected) {
    classes = styles["selected-radio-button"];
  } else {
    classes = styles["radio-button"];
  }
  if (isMobile) {
    classes = styles["radio-button-mobile"];
  }
  const lastMargin = last ? styles["last-button"] : "";
  classes = `${classes} ${styles["span-col-2-3"]}`;
  classes = `${classes} ${lastMargin}`;

  return (
    <div
      className={classes}
      style={{ textAlign: "center" }}
      onClick={(e) => onClick()}
    >
      <label style={{ width: "100%" }}>{label}</label>
      {isMobile ? (
        <div style={{ fontSize: "50%", marginTop: "-15px" }}>
          <br />
          (coming soon)
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const surveyTimingOptions = [
  {
    value: 1,
    label: "15 mins",
  },
  {
    value: 2,
    label: "30 mins",
  },
  {
    value: 3,
    label: "45 mins",
  },
];

const Setup = ({
  formName,
  setFormName,
  surveyType,
  surveyAccess,
  formQuestions,
  branchingRules,
  setFormQuestions,
  setSurveyType,
  setSurveyAccess,
  device,
  setDevice,
  mode,
  setMode,
  setting,
  setSetting,
  timingType,
  setTimingType,
  errors,
  questionsErrors,
  surveyInstructions,
  setSurveyInstructions,
  brandName,
  setBrandName,
  brandLogo,
  setBrandLogo,
  guideFile,
  setGuideFile,
  hasQuestions,
  clearQuestions,
  viewOnly,
}) => {
  const [advancedOptions, setAdvancedOptions] = useState(() => {
    return !!surveyInstructions || !!brandName || !!brandLogo || !!guideFile;
  });

  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [uploadingGuide, setUploadingGuide] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [switchModeModal, setSwitchModeModal] = useState(false);
  const [questions, { add, remove, move, set }] = useArray(formQuestions);
  useEffect(() => {
    setFormQuestions(questions);
  }, [questions, setFormQuestions]);
  useEffect(() => {
    console.log(switchModeModal);
  }, [switchModeModal]);

  const modalStyles = {
    content: {
      top: "0",
      left: "0",
      width: "100vw",
      height: "100vh",
      border: 0,
      padding: 0,
      borderRadius: 0,
    },
  };

  const logoUploadProps = {
    accept: ".png,.PNG,.jpg,.JPG,.jpeg,.JPEG",
    customRequest: async ({ file, onError }) => {
      const userId = localStorage.getItem("client");
      const { name, type } = file;
      setUploadingLogo(true);
      const { data } = await axios({
        ...Routes.client.uploadFile(userId),
        data: { data: { name, type } },
      });
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", data.signedRequest);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            setBrandLogo(data.url);
            message.success(`Logo uploaded successfully`);
            setUploadingLogo(false);
          } else {
            onError();
            message.error(`Logo upload failed`);
            setUploadingLogo(false);
          }
        }
      };
      xhr.send(file);
    },
    maxCount: 1,
    showUploadList: false,
  };

  const guideUploadProps = {
    // accept: ".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.pdf,.PDF,.doc,.DOC,.",
    customRequest: async ({ file, onError }) => {
      const userId = localStorage.getItem("client");
      const { name, type } = file;
      setUploadingGuide(true);
      const { data } = await axios({
        ...Routes.client.uploadFile(userId),
        data: { data: { name, type } },
      });
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", data.signedRequest);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            setGuideFile(data.url);
            message.success(`Survey guide uploaded successfully`);
            setUploadingGuide(false);
          } else {
            onError();
            message.error(`Survey guide upload failed`);
            setUploadingGuide(false);
          }
        }
      };
      xhr.send(file);
    },
    maxCount: 1,
    showUploadList: false,
  };

  return (
    <React.Fragment>
      <InnerContainer
        style={{
          padding: 20,
          marginTop: 32,
          pointerEvents: viewOnly ? "none" : "auto",
        }}
      >
        <Row justify="space-between" gutter={[8, 8]}>
          <Col xs={24} sm={12}>
            <h3 style={{ margin: 0, fontWeight: "bold" }}>Name your survey</h3>
            <p style={{ fontSize: "0.75rem", margin: 0 }}>
              (The name will be only visible to you)
            </p>
            <Input
              placeholder="Enter survey name here"
              className={styles["input-box"]}
              onChange={(e) => setFormName(e.target.value)}
              value={formName}
              suffix={
                <Tooltip title="Extra information">
                  {formName ? (
                    <CheckCircleOutlined style={{ color: "#71cfd7" }} />
                  ) : (
                    <ExclamationCircleOutlined style={{ color: "#FF9480" }} />
                  )}
                </Tooltip>
              }
              style={{
                marginTop: 12,
              }}
            />
          </Col>
          <Col>
            <ExplainerVideo />
          </Col>
        </Row>
        <Row
          style={{
            marginTop: 20,
          }}
          gutter={[8, 16]}
        >
          <Col span={24}>
            <h3 style={{ margin: 0, fontWeight: "bold" }}>
              Select survey options
            </h3>
            <p style={{ fontSize: "0.75rem", margin: 0 }}>
              (You can modify these settings later)
            </p>
          </Col>
          <Col span={24}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Survey Accessibility</div>
              <div>
                <div
                  style={{ display: "flex", columnGap: "60px", width: "400px" }}
                >
                  <RadioButton
                    label="Open"
                    selected={surveyAccess === true}
                    onClick={() => setSurveyAccess(true)}
                  />
                  <RadioButton
                    label="Closed"
                    selected={surveyAccess === false}
                    onClick={() => {
                      setSurveyAccess(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Survey Type</div>
              <div>
                <div
                  style={{ display: "flex", columnGap: "60px", width: "400px" }}
                >
                  <RadioButton
                    label="Qualitative"
                    selected={surveyType === true}
                    onClick={() => setSurveyType(true)}
                  />
                  <RadioButton
                    label="Quantitative"
                    selected={surveyType === false}
                    onClick={() => {
                      setSurveyType(false);
                      setMode(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
          {surveyType === true && (
            <Col span={24}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Survey Mode</div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      columnGap: "60px",
                      width: "400px",
                    }}
                  >
                    <RadioButton
                      label="Unmoderated"
                      selected={mode === false}
                      onClick={() => setMode(false)}
                    />
                    <RadioButton
                      label="Moderated"
                      selected={mode === true}
                      onClick={() => {
                        hasQuestions ? setSwitchModeModal(true) : setMode(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Col>
          )}
          {surveyType === true && mode !== undefined && (
            <Col span={24}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Device</div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      columnGap: "60px",
                      width: "400px",
                    }}
                  >
                    <RadioButton
                      label="Desktop"
                      selected={device === false}
                      onClick={() => setDevice(false)}
                    />
                    <RadioButton
                      isMobile={true}
                      label="Mobile"
                      selected={device === true}
                      onClick={() => {}}
                    />
                  </div>
                </div>
              </div>
            </Col>
          )}
          {surveyType === true && mode === true && device !== undefined && (
            <Col span={24}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Setting</div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      columnGap: "60px",
                      width: "400px",
                    }}
                  >
                    <RadioButton
                      label="Group"
                      selected={setting === true}
                      onClick={() => setSetting(true)}
                    />
                    <RadioButton
                      label="Individual"
                      selected={setting === false}
                      onClick={() => setSetting(false)}
                    />
                  </div>
                </div>
              </div>
            </Col>
          )}
          {surveyType === true &&
            device !== undefined &&
            ((mode === true && setting !== undefined) || mode === false) && (
              <Col span={24}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>Survey Time</div>
                  <div>
                    <div style={{ display: "flex", columnGap: "60px" }}>
                      <Select
                        placeholder="Select survey time"
                        value={timingType}
                        onChange={(value) => setTimingType(value)}
                        // className={styles["survey-time-select"]}
                        style={{ width: "400px" }}
                      >
                        {surveyTimingOptions.map(({ value, label }) => (
                          <Select.Option key={value} value={value}>
                            {label}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </Col>
            )}
        </Row>
        <Row justify="end" style={{ marginTop: 20 }}>
          <div
            onClick={() => setAdvancedOptions(!advancedOptions)}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {advancedOptions ? "Hide" : "View"} advanced options{" "}
            {advancedOptions ? <UpOutlined /> : <DownOutlined />}
          </div>
        </Row>
        {advancedOptions && (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <h3 style={{ margin: 0 }}>Survey Instructions</h3>
              <p>You may add any specific instructions for the participants.</p>
              <Input.TextArea
                placeholder="Enter survey instructions here"
                value={surveyInstructions}
                onChange={(e) => {
                  setSurveyInstructions(e.target.value);
                }}
                className={styles["input-box"]}
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Col>
            <Col
              span={24}
              style={{
                paddingTop: 16,
                paddingBottom: 16,
                paddingLeft: 16,
                paddingRight: 16,
                backgroundColor: "#ecf9fa",
                borderRadius: 4,
                margin: "0px 8px",
                maxWidth: "calc(100% - 16px)",
              }}
            >
              <h3>Survey Branding</h3>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={14}>
                  <p>Enter company/brand name</p>
                  <Input
                    value={brandName}
                    onChange={(e) => setBrandName(e.target.value)}
                    placeholder="Enter survey brand name"
                    className={`${styles["input-box"]} ${styles["brand-name"]}`}
                  />
                </Col>
                <Col
                  xs={24}
                  sm={10}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p>{brandLogo ? "Brand logo" : "Upload brand logo"}</p>
                  {brandLogo ? (
                    <div className={styles["logo-container"]}>
                      <img
                        src={brandLogo}
                        alt="brand logo"
                        width={200}
                        height={200}
                      />
                      <div>
                        <DeleteOutlined
                          onClick={() => {
                            setBrandLogo("");
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <Upload {...logoUploadProps}>
                      <div className={styles["upload-logo-container"]}>
                        {uploadingLogo ? (
                          <LoadingOutlined />
                        ) : (
                          <CloudUploadOutlined />
                        )}
                        <div>Upload</div>
                      </div>
                    </Upload>
                  )}
                </Col>
              </Row>
            </Col>
            {surveyType && mode && (
              <Col span={24}>
                <h3 style={{ margin: 0 }}>Survey Discussion Guide</h3>
                <p>You may upload a discussion guide for the moderator.</p>
                {guideFile ? (
                  <div className={styles["guide-container"]}>
                    {GetFilename(guideFile, 10)}
                    <DeleteOutlined
                      style={{ fontSize: 20 }}
                      onClick={() => {
                        setGuideFile("");
                      }}
                    />
                  </div>
                ) : (
                  <Upload {...guideUploadProps}>
                    <div className={styles["guide-upload-container"]}>
                      <div>Upload file</div>
                      {uploadingGuide ? (
                        <LoadingOutlined style={{ fontSize: 20 }} />
                      ) : (
                        <CloudUploadOutlined style={{ fontSize: 20 }} />
                      )}
                    </div>
                  </Upload>
                )}
              </Col>
            )}
          </Row>
        )}
        <div style={{ padding: "40px 0 0 0", display: "flex" }}>
          <Button
            className={styles[("add-question-button", "grey-question-button")]}
            style={{
              color: "white",
              backgroundColor: "#00023F",
              border: "1px solid #00023F",
              margin: "auto",
              borderRadius: "5px",
            }}
            onClick={() => {
              // if (questionsErrors.length !== 0) {
              //   message.error(
              //     "You have errors in your form. Please fix them first."
              //   );
              //   return;
              // }
              setFormModal(true);
            }}
          >
            <PlusCircleOutlined className={styles["add-question-icon"]} />
            Try your Survey
          </Button>
          <Modal
            isOpen={formModal}
            onRequestClose={() => setFormModal(false)}
            style={modalStyles}
          >
            {formModal && (
              <Survey
                questions={questionsErrors.length > 0 ? [] : questions}
                rules={branchingRules}
                surveyType={surveyType}
                isOpen={formModal}
                onClose={() => setFormModal(false)}
                tryOwnForm={true}
                brandLogo={brandLogo}
                brandName={brandName}
                surveyInstructions={surveyInstructions}
              />
            )}
          </Modal>
        </div>
        <Errors errors={errors} />
      </InnerContainer>
      <Modal2
        visible={switchModeModal}
        closable={false}
        footer={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 12,
            }}
          >
            <Button
              style={{
                backgroundColor: "#bdbdbd",
                border: "none",
                borderRadius: 4,
                color: "white",
                marginRight: 24,
                width: 100,
                textTransform: "uppercase",
              }}
              onClick={() => setSwitchModeModal(false)}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: "#eb3f4a",
                border: "none",
                borderRadius: 4,
                color: "white",
                marginRight: 24,
                width: 100,
                textTransform: "uppercase",
              }}
              onClick={() => {
                setMode(true);
                clearQuestions();
                setSwitchModeModal(false);
              }}
            >
              Switch
            </Button>
          </div>
        }
      >
        <div
          style={{
            fontSize: 16,
            textAlign: "center",
            marginBottom: 8,
          }}
        >
          All questions created will be deleted on switching to Moderated
          Survey. Are you sure you want to switch?
        </div>
      </Modal2>
    </React.Fragment>
  );
};

export default Setup;
