import React from "react";
import { adminPages } from "../../../constants";
import { Link, useHistory } from "react-router-dom";

export const SidenavItem = (props) => {
  return (
    <div
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
      className={props.active ? "sidenav-item active" : "sidenav-item"}
    >
      {props.children}
    </div>
  );
};

const Sidenav = ({ drawer, selectedItem }) => {
  const history = useHistory();
  const className = drawer ? "sidenav-drawer" : "sidenav";
  return (
    <div className={className}>
      <Link to={`/admin/${adminPages.REGQUESTIONS}`}>
        <SidenavItem active={selectedItem === adminPages.REGQUESTIONS}>
          Registration Questions
        </SidenavItem>
      </Link>
      <Link to={`/admin/${adminPages.SAMPLEQUESTIONS}`}>
        <SidenavItem active={selectedItem === adminPages.SAMPLEQUESTIONS}>
          Sample Questions
        </SidenavItem>
      </Link>
      <Link to={`/admin/${adminPages.ADDITIONALQUESTIONS}`}>
        <SidenavItem active={selectedItem === adminPages.ADDITIONALQUESTIONS}>
          Additional Questions
        </SidenavItem>
      </Link>
      <Link to={`/admin/${adminPages.SURVEYS}`}>
        <SidenavItem active={selectedItem === adminPages.SURVEYS}>
          Surveys
        </SidenavItem>
      </Link>
      <Link to={`/admin/${adminPages.INFLUENCERS}`}>
        <SidenavItem active={selectedItem === adminPages.INFLUENCERS}>
          Influencers
        </SidenavItem>
      </Link>
      <Link to={`/admin/${adminPages.CLIENTS}`}>
        <SidenavItem active={selectedItem === adminPages.CLIENTS}>
          Clients
        </SidenavItem>
      </Link>
      <Link to={`/admin/${adminPages.PRICING}`}>
        <SidenavItem active={selectedItem === adminPages.PRICING}>
          Pricing
        </SidenavItem>
      </Link>
      <Link to={`/admin/${adminPages.FEATURES}`}>
        <SidenavItem active={selectedItem === adminPages.FEATURES}>
          Features
        </SidenavItem>
      </Link>
      <SidenavItem
        onClick={() => {
          localStorage.removeItem("token");
          localStorage.removeItem("admin");
          history.push("/signin/admin");
        }}
      >
        Logout
      </SidenavItem>
    </div>
  );
};

export default Sidenav;
