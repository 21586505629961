import React, { useEffect, useState } from "react";
import styles from "./profile.module.css";
import c from "classnames/bind";
import ProjectPageLayout, {
  Content,
  InnerContainer,
} from "../../../components/page-layout/PageLayout";
import { Col, Form, Input, Row, Card } from "antd";
import FormItemLabel from "../../../components/form-item-label/FormItemLabel";
import InputBox from "../../../components/input-box/InputBox";
import Button from "../../../components/button/Button";
import ProfileQuestionBox from "../../../components/user/profile-question-box/ProfileQuestionBox";
import { EMAIL_VALIDATION_REGEX, userPages } from "../../../constants";
import Sidenav from "../../../components/user/sidenav/Sidenav";
import Topbar from "../../../components/topbar/Topbar";
import { useMediaQuery } from "react-responsive";
import {
  decrementPendingRequests,
  incrementPendingRequests,
  notify,
} from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { getProfileQuestions } from "../../../actions/users";
import EmailInputBox from "../../../components/profile/email-input-box/EmailInputBox";
import MobileInputBox from "../../../components/profile/mobile-input-box/MobileInputBox";
import "./Profile.css";
import { InfoCircleOutlined } from "@ant-design/icons";

const cx = c.bind(styles);

const shouldDisableOTP = (number) => {
  if (!number) return true;
  else if (number.length !== 10) return true;
  return false;
};

const Profile = ({
  usedForClient,
  profile,
  handleEditProfile,
  handleSendEmail,
  handleSendOtp,
  handleVerifyOtp,
}) => {
  const dispatch = useDispatch();
  const smBreakpoint = useMediaQuery({ query: "(max-width: 768px)" });
  const mdBreakpoint = useMediaQuery({ query: "(max-width: 1000px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 450px)" });
  const [form] = Form.useForm();

  const profileQuestions = useSelector((state) => state.user.profileQuestions);
  const [otpStatus, setOtpStatus] = useState(
    profile && profile.isMobileNumberVerified ? "verified" : undefined
  );
  const [emailStatus, setEmailStatus] = useState(
    profile && profile.isEmailVerified ? "verified" : undefined
  );
  const [profileAnswers, setProfileAnswers] = useState([]);

  useEffect(() => {
    document.body.setAttribute("data-new-theme", true);

    return () => {
      document.body.removeAttribute("data-new-theme");
    };
  }, []);

  useEffect(() => {
    incrementPendingRequests();
    dispatch(
      getProfileQuestions([
        "additionalQuestions",
        "sampleQuestions",
        "regQuestions",
      ])
    )
      .then(() => setProfileAnswers(profile && profile.adminQuestionsAnswers))
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  }, [dispatch, profile]);

  // Handlers
  const onSubmit = (values) => {
    Object.keys(values).forEach(
      (key) => values[key] === undefined && delete values[key]
    );
    if (
      values.mobileNumber !== profile.mobileNumber &&
      otpStatus !== "verified"
    ) {
      notify("error", "Error", "Please verify mobile number first.");
      return;
    }
    handleEditProfile(values, profileAnswers);
  };

  const handleCancel = () => {
    setOtpStatus("verified");
  };

  const onSendEmail = () => {
    const email = form.getFieldValue("email");
    handleSendEmail(email);
  };

  const onEditEmail = () => {
    setEmailStatus(undefined);
  };

  const onSendOtp = () => {
    const mobileNumber = form.getFieldValue("mobileNumber");
    handleSendOtp(mobileNumber).then(() => setOtpStatus("verifying"));
  };

  const onVerifyOtp = (value) => {
    const mobileNumber = form.getFieldValue("mobileNumber");
    handleVerifyOtp(value, mobileNumber).then(() => setOtpStatus("verified"));
  };

  const onEditMobileNumber = () => {
    setOtpStatus(undefined);
  };

  const updateProfileAnswers = (value, id) => {
    const obj = profileAnswers.find((val) => val.questionId === id);
    if (obj) {
      setProfileAnswers(
        profileAnswers.map((val) => {
          if (val.questionId !== id) return val;
          return {
            ...val,
            answer: Array.isArray(value) ? value : [value],
          };
        })
      );
    } else {
      setProfileAnswers([
        ...profileAnswers,
        {
          questionId: id,
          answer: Array.isArray(value) ? value : [value],
        },
      ]);
    }
  };

  const input_filed_styles = {
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#EEF1F2",
    color: "#404040",
  };

  return (
    <React.Fragment>
      {!usedForClient && (
        <>
          <Topbar>
            <Sidenav drawer selectedItem={userPages.PROFILE} />
          </Topbar>
          <Sidenav selectedItem={userPages.PROFILE} />
        </>
      )}
      <ProjectPageLayout>
        <Content>
          <Form
            form={form}
            initialValues={profile}
            className="profileForm"
            onFinish={onSubmit}
          >
            <div className={cx("profile")}>
              <Card style={{ padding: "12px", borderRadius: "5px" }}>
                <h3
                  style={{
                    marginBottom: 0,
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                  }}
                >
                  Profile Details
                </h3>
                <div
                  style={{
                    paddingBottom: "12px",
                  }}
                >
                  This information will only be shared with us.
                </div>
                <div style={{ paddingBottom: 5, paddingTop: "15px" }}>
                  <FormItemLabel name="Email" />
                  <Row gutter={[12, 12]} style={{ padding: "0 0 15px 0" }}>
                    <Col xs={24} sm={24} md={10}>
                      {profile &&
                      profile.registeredVia !== 0 &&
                      emailStatus === "verified" ? (
                        <Input
                          style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingRight: 0,
                            backgroundColor: "#EEE",
                          }}
                          size={isTabletOrMobile ? "middle" : "large"}
                          value={profile && profile.email}
                          disabled
                          suffix={
                            <Button type="primary" onClick={onEditEmail}>
                              Edit
                            </Button>
                          }
                        />
                      ) : (
                        <Form.Item name="email" noStyle shouldUpdate>
                          <EmailInputBox
                            options={{
                              disabled: profile && profile.registeredVia === 0,
                            }}
                          />
                        </Form.Item>
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={10}>
                      {!emailStatus && (
                        <Form.Item noStyle shouldUpdate>
                          {() => (
                            <Button
                              type="primary"
                              style={{
                                width: smBreakpoint ? "200px" : "60%",
                                backgroundColor: "#133E6B",
                                backgroundRadius: "5px",
                                border: "none",
                                color: "#404040",
                              }}
                              onClick={onSendEmail}
                              disabled={
                                !EMAIL_VALIDATION_REGEX.test(
                                  form.getFieldValue("email")
                                )
                              }
                            >
                              Send Verification Link
                            </Button>
                          )}
                        </Form.Item>
                      )}
                      {emailStatus === "verified" && (
                        <Button
                          type="primary"
                          disabled
                          style={{
                            width: smBreakpoint ? "200px" : "60%",
                            backgroundColor: "#133E6B7",
                            border: "none",
                            borderRadius: "5px",
                          }}
                        >
                          Verified
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <FormItemLabel name="Phone" />
                  <Row gutter={[12, 12]} style={{ padding: "0 0 15px 0" }}>
                    <Col xs={24} sm={24} md={10}>
                      {profile &&
                      profile.registeredVia !== 1 &&
                      otpStatus === "verified" ? (
                        <Input
                          style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingRight: 0,
                            border: "none",
                            backgroundColor: "#EEE",
                          }}
                          size={isTabletOrMobile ? "middle" : "large"}
                          value={profile && profile.mobileNumber}
                          disabled
                          suffix={
                            <Button type="primary" onClick={onEditMobileNumber}>
                              Edit
                            </Button>
                          }
                        />
                      ) : (
                        <Form.Item name="mobileNumber" noStyle>
                          <MobileInputBox
                            placeholder="Phone"
                            rule
                            limit={10}
                            handleCancel={handleCancel}
                            options={{
                              disabled: profile && profile.registeredVia === 1,
                            }}
                          />
                        </Form.Item>
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={10}>
                      {!otpStatus && (
                        <Form.Item noStyle shouldUpdate>
                          {() => (
                            <Button
                              type="primary"
                              style={{
                                width: smBreakpoint ? "200px" : "60%",
                                backgroundColor: "#133E6B",
                                border: "none",
                                color: "#FFFFFF",
                                borderRadius: "5px",
                              }}
                              onClick={onSendOtp}
                              disabled={shouldDisableOTP(
                                form.getFieldValue("mobileNumber")
                              )}
                            >
                              Send OTP
                            </Button>
                          )}
                        </Form.Item>
                      )}
                      {otpStatus === "verifying" && (
                        <div className="otpInput">
                          <Input.Search
                            placeholder="Enter OTP"
                            enterButton="Verify"
                            onSearch={onVerifyOtp}
                            size={"middle"}
                          />
                        </div>
                      )}
                      {otpStatus === "verified" && (
                        <Button
                          type="primary"
                          disabled
                          style={{
                            width: smBreakpoint ? "200px" : "60%",
                            // backgroundColor: "#133E6B",
                            border: "none",
                            borderRadius: "5px",
                          }}
                        >
                          Verified
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Row gutter={[12, 12]} style={{ padding: "0 0 15px 0" }}>
                    <Col xs={24} sm={24} md={10}>
                      <FormItemLabel name="First Name" />
                      <Form.Item name="firstName" noStyle>
                        <InputBox
                          style={input_filed_styles}
                          placeholder="First Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={10}>
                      <FormItemLabel name="Last Name" />
                      <Form.Item name="lastName" noStyle>
                        <InputBox
                          style={input_filed_styles}
                          placeholder="Last Name"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {!usedForClient && (
                    <>
                      <FormItemLabel name="Display Name" />
                      {!usedForClient && (
                        <div style={{ fontSize: "0.9rem" }}>
                          This will be shared with the companies you work for.
                        </div>
                      )}

                      <Col xs={24} sm={24} md={10}>
                        <Form.Item name="displayName" noStyle>
                          <InputBox
                            style={{
                              border: "none",
                              borderRadius: "5px",
                              backgroundColor: "#EEE",
                            }}
                            placeholder="Display Name"
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  <br />
                </div>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  style={{
                    marginTop: 10,
                    width: "20%",
                    backgroundColor: "#133E6B",
                    marginLeft: "80%",
                    borderColor: "#71CFD7",
                    borderRadius: "5px",
                  }}
                >
                  Save
                </Button>
              </Card>
              <br />
              {/* <span style={{ fontSize: "90%" }}>
                <b>Terms of use and privacy policy</b>
                <br />
                Thanks for using UserQual as per the terms and privacy policy
                you accepted on sign up.
                <br />
              </span> */}
              <Card style={{ padding: "12px", borderRadius: "5px" }}>
                {!usedForClient && (
                  <div className={cx("profile")}>
                    <h3
                      style={{
                        marginBottom: 0,
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}
                    >
                      Profile Questions
                    </h3>
                    <div
                      style={{
                        fontSize: "0.85rem",
                        paddingBottom: "12px",
                        paddingTop: "5px",
                      }}
                    >
                      The more questions you answer, the more surveys you will
                      get and get paid.
                    </div>
                    <div style={{ paddingTop: "16px" }}>
                      {profileQuestions && profileQuestions.length > 0
                        ? profileQuestions
                            .filter((val) => val.questionType === "profile")
                            .map((val, index) => (
                              <ProfileQuestionBox
                                key={index}
                                question={val.userField}
                                questionObject={val.questionObject}
                                value={
                                  profileAnswers.find(
                                    (ans) => ans.questionId === val._id
                                  )
                                    ? profileAnswers.find(
                                        (ans) => ans.questionId === val._id
                                      ).answer
                                    : undefined
                                }
                                onChange={(values) =>
                                  updateProfileAnswers(values, val._id)
                                }
                              />
                            ))
                        : null}
                    </div>
                  </div>
                )}
              </Card>
            </div>
          </Form>
        </Content>
      </ProjectPageLayout>
    </React.Fragment>
  );
};

export default Profile;
