import "./question-box.css";
import {
  Switch,
  Input,
  Row,
  Col,
  Form,
  InputNumber,
  Popconfirm,
  Tooltip,
  Button,
  Slider,
  Select,
} from "antd";
import React, { Fragment, useState } from "react";
import {
  CheckOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  PlusCircleFilled,
  HeartOutlined,
  SmileOutlined,
  StarOutlined,
} from "@ant-design/icons";
import QuestionTextbox from "../../question-text-box/QuestionTextbox";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ChoiceInputBox from "../choice-input-box/ChoiceInputBox";
import TwoChoiceInputBox from "../two-choice-input-box/TwoChoiceInputBox";
import { COLOR, urlPattern } from "../../../constants";
import Dropdown from "../../dropdown/dropdown";
import ABTextTypeQuestion from "../a-b-texttypequestion/abTextTypeQuestion";
import CustomButton from "../../button/Button";
import ImageMultiChoiceInput from "../imageMultiChoiceInput/imageMultiChoiceInput";
import VideoMultiChoiceInput from "../videoMultiChoiceInput/videoMultiChoiceInput";
import ImageABTest from "../imageABTest/imageABTest";
import VideoABTest from "../videoABTest/videoABTest";
import ImageAssociation from "../imageAssociation/imageAssociation";
import VideoAssociation from "../videoAssociation/videoAssociation";
import {
  decrementPendingRequests,
  incrementPendingRequests,
  notify,
} from "../../../utils";
import { deleteAdminQuestion } from "../../../actions/admin";
import { useDispatch } from "react-redux";

const questionTypeOptions = [
  {
    label: "Multiple Choice",
    value: "multipleChoice",
  },
  {
    label: "Checkboxes",
    value: "checkboxes",
  },
  {
    label: "Checkboxes - Special",
    value: "checkboxesSpecial",
  },
  {
    label: "Location",
    value: "location",
  },
  {
    label: "Age",
    value: "age",
  },
  {
    label: "Add link",
    value: "addLink",
  },
  {
    label: "Likert Scale",
    value: "likertScale",
  },
  {
    label: "Association",
    value: "association",
  },
  {
    label: "Text A/B Test",
    value: "abTextType",
  },
  {
    label: "Text",
    value: "text",
  },
  {
    label: "Question/Instruction",
    value: "questionInstruction",
  },
  {
    label: "Star Rating",
    value: "starRating",
  },
  {
    label: "Image Multichoice",
    value: "imageMultiChoice",
  },
  {
    label: "Image Multichoice StarRating",
    value: "imageMultichoiceStarRating",
  },
  {
    label: "Slider",
    value: "slider",
  },
  {
    label: "Range",
    value: "range",
  },
  {
    label: "Video Instruction",
    value: "videoInstruction",
  },
  {
    label: "Video Multichoice StarRating",
    value: "videoMultiChoiceStarRating",
  },
  {
    label: "Image A/B Test",
    value: "imageABTest",
  },
  {
    label: "Video A/B Test",
    value: "videoABTest",
  },
  {
    label: "Image Association",
    value: "imageAssociation",
  },
  {
    label: "Video Association",
    value: "videoAssociation",
  },
];

const QuestionTypeCheckbox = ({ value, onChange }) => (
  <Switch
    style={{ marginLeft: 20 }}
    checked={value === "profile"}
    onChange={(checked) =>
      checked ? onChange("profile") : onChange("non-profile")
    }
  />
);

const QuestionBox = (props) => {
  // Props
  const {
    removeQuestion,
    addQuestion,
    onChange,
    value,
    questionNumber,
    dragHandleProps,
    displayAt,
  } = props;

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // Component states
  const [isQuestionNumberShown, setIsQuestionNumberShown] = useState(true);
  const [isAddNewQuestionIconShown, setIsAddNewQuestionIconShown] = useState(
    false
  );
  const [clientCriteria, setClientCriteria] = useState(
    !!(value && value.clientField)
  );
  const [range, setRange] = useState("3");
  const [questionType, setQuestionType] = useState(
    value && value.questionObject.questionType
      ? value.questionObject.questionType
      : undefined
  );

  // Handlers
  const onQuestionTypeChange = (value) => setQuestionType(value);
  const onClientCriteriaChange = () => {
    if (clientCriteria) {
      onChange({ ...form.getFieldsValue(), clientField: "" });
    }
    setClientCriteria(!clientCriteria);
  };

  const onValuesChange = (changedValues, allValues) => {
    onChange(allValues);
  };

  //new
  const [ratingSymbolType, setRatingSymbolType] = useState("star");
  const [symbolCount, setSymbolCount] = useState(5);

  const [sliderType, setSliderType] = useState("Percentage");
  const [sliderStart, setSliderStart] = useState(0);
  const [sliderEnd, setSliderEnd] = useState(100);

  const getCharacter = (str) => {
    switch (str) {
      case "star":
        return (
          <StarOutlined
            style={{ color: COLOR.darkBlue }}
            className="character"
          />
        );
      case "smile":
        return (
          <SmileOutlined
            style={{ color: COLOR.darkBlue }}
            className="character"
          />
        );
      case "heart":
        return (
          <HeartOutlined
            style={{ color: COLOR.darkBlue }}
            className="character"
          />
        );
      default:
        return;
    }
  };

  return (
    <React.Fragment>
      <div
        className="question-container"
        onMouseEnter={() => setIsQuestionNumberShown(false)}
        onMouseLeave={() => setIsQuestionNumberShown(true)}
      >
        <Popconfirm
          title="Are you sure delete this question?"
          onConfirm={() => {
            if (form.getFieldValue("_id")) {
              incrementPendingRequests();
              dispatch(
                deleteAdminQuestion({
                  ...form.getFieldsValue(),
                  isActive: false,
                })
              )
                .then(() => {
                  notify("success", "Success", "Question deleted");
                  removeQuestion();
                })
                .catch((ex) => notify("error", "Error", ex))
                .finally(() => decrementPendingRequests());
            } else {
              removeQuestion();
            }
          }}
          okText="Yes"
          cancelText="No"
        >
          <CloseCircleOutlined className="close-icon" />
        </Popconfirm>
        <Form
          form={form}
          initialValues={{
            isActive: true,
            questionType: "profile",
            displayAt,
            criteriaType: false,
            questionObject: {
              options: ["", ""],
            },
            associations: [
              { label: "", association: "" },
              { label: "", association: "" },
            ],
            abTextType: [
              { inputWord: "", percentage: "" },
              { inputWord: "", percentage: "" },
            ],
            imageMultiChoice: [
              { imageLabel: "", imageData: "" },
              { imageLabel: "", imageData: "" },
            ],
            videoMultiChoice: [
              { videoLabel: "", videoData: "" },
              { videoLabel: "", videoData: "" },
            ],
            imageABTest: [
              { imageLabel: "", imageData: "", percentage: "" },
              { imageLabel: "", imageData: "", percentage: "" },
            ],
            videoABTest: [
              { videoLabel: "", videoData: "", percentage: "" },
              { videoLabel: "", videoData: "", percentage: "" },
            ],
            imageAssociation: [
              { association: "", imageData: "" },
              { association: "", imageData: "" },
            ],
            videoAssociation: [
              { association: "", videoData: "" },
              { association: "", videoData: "" },
            ],
            ...value,
          }}
          onValuesChange={onValuesChange}
          layout="horizontal"
        >
          <Form.Item name="_id" noStyle></Form.Item>
          <Form.Item name="displayAt" noStyle></Form.Item>
          <Form.Item name="isActive" noStyle></Form.Item>
          <Form.Item name="createdAt" noStyle></Form.Item>
          <Form.Item name="updatedAt" noStyle></Form.Item>
          <Col offset={2}>
            <h3 style={{ color: "#004479" }}>
              Profile Question{" "}
              <Form.Item name="questionType" noStyle>
                <QuestionTypeCheckbox />
              </Form.Item>
              <div style={{ display: "inline-block", float: "right" }}>
                <Form.Item name="criteriaType" noStyle>
                  <Select className="select-background">
                    <Select.Option value={false}>Basic Criteria</Select.Option>
                    <Select.Option value={true}>Advance Criteria</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </h3>
          </Col>
          <Row>
            <Col span={2} style={{ display: "flex", justifyContent: "center" }}>
              <span
                style={{
                  fontSize: 26,
                  position: "relative",
                  top: 40,
                  left: -10,
                  color: "#004479",
                }}
              >
                {isQuestionNumberShown ? (
                  <span className="question-numberCircle">
                    {questionNumber}
                  </span>
                ) : (
                  <MenuOutlined {...dragHandleProps} />
                )}
              </span>
            </Col>
            <Col span={22}>
              <QuestionTextbox
                selectOptions={questionTypeOptions}
                onSelectChange={onQuestionTypeChange}
                isOptionSelected={!!questionType}
                buttonText="Client Criteria"
                onButtonClick={onClientCriteriaChange}
                isButtonActive={clientCriteria}
              />
            </Col>
          </Row>
          {clientCriteria && (
            <Row
              gutter={6}
              style={{ paddingTop: 20, borderTop: "1px solid #004479" }}
            >
              <Col xs={16} style={{ flexGrow: 1 }} offset={2}>
                <Form.Item
                  name="clientField"
                  rules={[
                    { required: true, message: "Please enter the criteria!" },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Client criteria"
                    style={{ borderRadius: 3 }}
                    suffix={
                      form.getFieldValue(["clientField"]) ? (
                        <CheckOutlined style={{ color: "green" }} />
                      ) : (
                        <Tooltip title="You need to fill client criteria!">
                          <InfoCircleOutlined style={{ color: "red" }} />
                        </Tooltip>
                      )
                    }
                    className="input-border"
                  />
                </Form.Item>
              </Col>
              <Col xs={5} className="actions">
                <CloseCircleOutlined
                  className="action-icon"
                  onClick={onClientCriteriaChange}
                />
              </Col>
            </Row>
          )}
          <div>
            {questionType === "checkboxesSpecial" && (
              <Form.Item name={["questionObject", "specialField"]}>
                <InputNumber
                  placeholder="Index"
                  size="large"
                  className="input-border"
                />
              </Form.Item>
            )}
            {questionType === "multipleChoice" ||
            questionType === "checkboxes" ||
            questionType === "checkboxesSpecial" ||
            questionType === "location" ? (
              <Form.List name={["questionObject", "options"]}>
                {(fields, { add, remove, move }) => {
                  return (
                    <DragDropContext
                      onDragEnd={(result) =>
                        move(result.source.index, result.destination.index)
                      }
                    >
                      <Droppable droppableId="list">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                              paddingTop: 20,
                              borderTop: "1px solid #004479",
                            }}
                          >
                            {fields.map((field, index) => (
                              <Draggable
                                draggableId={field.name.toString()}
                                index={index}
                                key={index}
                              >
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                    gutter={6}
                                  >
                                    <ChoiceInputBox
                                      dragHandleProps={provided.dragHandleProps}
                                      choiceNumber={index + 1}
                                      questionType={questionType}
                                      field={field}
                                      totalChoices={fields.length}
                                      isFilled={
                                        !!form.getFieldValue([
                                          "questionObject",
                                          "options",
                                          field.name,
                                        ])
                                      }
                                      addChoice={(newIndex) =>
                                        add(null, newIndex)
                                      }
                                      removeChoice={(index) =>
                                        remove(index - 1)
                                      }
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  );
                }}
              </Form.List>
            ) : null}
            {questionType === "age" && (
              <Row gutter={5} justify="center">
                <Col xs={7} offset={1}>
                  <Form.Item
                    name={["questionObject", "start"]}
                    label="Start value"
                    colon={false}
                  >
                    <InputNumber
                      className="input-border"
                      style={{ borderRadius: 3, width: 70 }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={7}>
                  <Form.Item
                    name={["questionObject", "end"]}
                    label="End value"
                    colon={false}
                  >
                    <InputNumber
                      className="input-border"
                      style={{ borderRadius: 3, width: 70 }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={7}>
                  <Form.Item
                    name={["questionObject", "interval"]}
                    label="Interval"
                    colon={false}
                  >
                    <InputNumber
                      className="input-border"
                      style={{ borderRadius: 3, width: 70 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {questionType === "addLink" && (
              <Row
                gutter={6}
                style={{ paddingTop: 20, borderTop: "1px solid #004479" }}
              >
                <Col xs={16} style={{ flexGrow: 1 }} offset={2}>
                  <Form.Item
                    name="link"
                    rules={[
                      { required: true, message: "Please enter the link" },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Add Link"
                      style={{ borderRadius: 3 }}
                      suffix={
                        urlPattern.test(form.getFieldValue(["link"])) ? (
                          <CheckOutlined style={{ color: "green" }} />
                        ) : (
                          <Tooltip title="You need to fill client criteria!">
                            <InfoCircleOutlined style={{ color: "red" }} />
                          </Tooltip>
                        )
                      }
                      className="input-border"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {questionType === "association" && (
              <Form.List name="associations">
                {(fields, { add, remove, move }) => {
                  console.log("gotcha", questionType, fields);
                  return (
                    <DragDropContext
                      onDragEnd={(result) =>
                        move(result.source.index, result.destination.index)
                      }
                    >
                      <Droppable droppableId="listall">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                              paddingTop: 20,
                              borderTop: "1px solid #004479",
                            }}
                          >
                            {fields.map((field, index) => (
                              <Draggable
                                draggableId={field.name.toString()}
                                index={index}
                                key={index}
                              >
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                    gutter={6}
                                  >
                                    <TwoChoiceInputBox
                                      dragHandleProps={provided.dragHandleProps}
                                      choiceNumber={index + 1}
                                      questionType={questionType}
                                      field={field}
                                      totalChoices={fields.length}
                                      isFilled={
                                        !!(
                                          form.getFieldValue([
                                            "associations",
                                            field.name,
                                            "label",
                                          ]) &&
                                          form.getFieldValue([
                                            "associations",
                                            field.name,
                                            "association",
                                          ])
                                        )
                                      }
                                      addChoice={(newIndex) =>
                                        add(null, newIndex)
                                      }
                                      removeChoice={(index) =>
                                        remove(index - 1)
                                      }
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  );
                }}
              </Form.List>
            )}
            {questionType === "likertScale" && (
              <Row
                gutter={6}
                style={{ paddingTop: 20, borderTop: "1px solid #004479" }}
              >
                <Col xs={16} style={{ flexGrow: 1 }} offset={2}>
                  <div style={{ width: "fit-content", margin: "auto" }}>
                    <div
                      style={{
                        padding: 6,
                        width: "max-content",
                        margin: "auto",
                      }}
                    >
                      {[...new Array(parseInt(range))].map((value, index) => (
                        <div
                          key={index}
                          style={{
                            margin: "auto 5px",
                            width: 24,
                            textAlign: "center",
                            display: "inline-block",
                          }}
                          icon=" "
                          shape="circle"
                          size="small"
                        >
                          {index + 1}
                        </div>
                      ))}
                    </div>
                    <div
                      style={{
                        padding: 5,
                        border: "1px solid #004479",
                        borderRadius: "6px",
                        width: "max-content",
                        margin: "auto 24px",
                      }}
                    >
                      {[...new Array(parseInt(range))].map((value, index) => (
                        <Button
                          key={index}
                          disabled
                          style={{ margin: "auto 5px", width: 24 }}
                          icon=" "
                          shape="circle"
                          size="small"
                        ></Button>
                      ))}
                    </div>
                    <div
                      style={{
                        padding: 5,
                        margin: "auto",
                        marginBottom: "24px",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      {
                        <Fragment>
                          <div>{form.getFieldValue(["Start Label"])}</div>
                          <div>{form.getFieldValue(["Middle Label"])}</div>
                          <div>{form.getFieldValue(["End Label"])}</div>
                        </Fragment>
                      }
                    </div>
                  </div>
                </Col>
                <Col xs={16} style={{ flexGrow: 1 }} offset={2}>
                  <Form.Item name="Range" label="Range" labelCol={{ span: 4 }}>
                    <Dropdown
                      value={range}
                      handleChange={setRange}
                      options={[
                        {
                          label: "3",
                          value: "3",
                        },
                        {
                          label: "4",
                          value: "4",
                        },
                        {
                          label: "5",
                          value: "5",
                        },
                        {
                          label: "6",
                          value: "6",
                        },
                        {
                          label: "7",
                          value: "7",
                        },
                        {
                          label: "8",
                          value: "8",
                        },
                        {
                          label: "9",
                          value: "9",
                        },
                        {
                          label: "10",
                          value: "10",
                        },
                      ]}
                    ></Dropdown>
                  </Form.Item>
                </Col>
                <Col xs={16} style={{ flexGrow: 1 }} offset={2}>
                  <Form.Item
                    name="Start Label"
                    label="Start Label"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Input
                      size="large"
                      placeholder="Start Label"
                      style={{ borderRadius: 3 }}
                      suffix={
                        form.getFieldValue(["Start Label"]) ? (
                          <CheckOutlined style={{ color: "green" }} />
                        ) : (
                          <Tooltip title="You need to fill client criteria!">
                            <InfoCircleOutlined style={{ color: "red" }} />
                          </Tooltip>
                        )
                      }
                      className="input-border"
                    />
                  </Form.Item>
                </Col>
                <Col xs={16} style={{ flexGrow: 1 }} offset={2}>
                  <Form.Item
                    name="Middle Label"
                    label="Middle Label"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Input
                      size="large"
                      placeholder="Middle Label"
                      style={{ borderRadius: 3 }}
                      suffix={
                        form.getFieldValue(["Middle Label"]) ? (
                          <CheckOutlined style={{ color: "green" }} />
                        ) : (
                          <Tooltip title="You need to fill client criteria!">
                            <InfoCircleOutlined style={{ color: "red" }} />
                          </Tooltip>
                        )
                      }
                      className="input-border"
                    />
                  </Form.Item>
                </Col>
                <Col xs={16} style={{ flexGrow: 1 }} offset={2}>
                  <Form.Item
                    name="End Label"
                    label="End Label"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Input
                      size="large"
                      placeholder="End Label"
                      style={{ borderRadius: 3 }}
                      suffix={
                        form.getFieldValue(["End Label"]) ? (
                          <CheckOutlined style={{ color: "green" }} />
                        ) : (
                          <Tooltip title="You need to fill client criteria!">
                            <InfoCircleOutlined style={{ color: "red" }} />
                          </Tooltip>
                        )
                      }
                      className="input-border"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {questionType === "abTextType" && (
              <Form.List name="abTextType">
                {(fields, { add, remove, move }) => {
                  console.log(
                    "gotcha",
                    questionType,
                    fields,
                    form.getFieldValue(["abTextType"])
                  );
                  return (
                    <DragDropContext
                      onDragEnd={(result) =>
                        move(result.source.index, result.destination.index)
                      }
                    >
                      <Droppable droppableId="listall">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                              paddingTop: 20,
                              borderTop: "1px solid #004479",
                            }}
                          >
                            {fields.map((field, index) => (
                              <Draggable
                                draggableId={field.name.toString()}
                                index={index}
                                key={index}
                              >
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                    gutter={6}
                                  >
                                    <ABTextTypeQuestion
                                      dragHandleProps={provided.dragHandleProps}
                                      choiceNumber={index + 1}
                                      questionType={questionType}
                                      field={field}
                                      totalChoices={fields.length}
                                      isFilled={
                                        !!form.getFieldValue([
                                          "abTextType",
                                          field.name,
                                          "inputWord",
                                        ])
                                      }
                                      isSumHundred={
                                        form
                                          .getFieldValue(["abTextType"])
                                          .filter(
                                            (option) => option && option !== ""
                                          )
                                          .reduce(
                                            (sum, option) =>
                                              sum + parseInt(option.percentage),
                                            0
                                          ) === 100
                                      }
                                      addChoice={(newIndex) =>
                                        add(null, newIndex)
                                      }
                                      removeChoice={(index) =>
                                        remove(index - 1)
                                      }
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  );
                }}
              </Form.List>
            )}
            {questionType === "questionInstruction" && (
              <Row
                gutter={6}
                style={{ paddingTop: 20, borderTop: "1px solid #004479" }}
              >
                <Col span={6} style={{ maxWidth: "fit-content" }} offset={2}>
                  <Form.Item name="upload button">
                    <CustomButton>Upload File</CustomButton>
                  </Form.Item>
                </Col>
                <Col span={14}>
                  <Form.Item
                    name="Imaage file"
                    rules={[
                      { required: true, message: "No Image is selected" },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="No Image chosen"
                      style={{ borderRadius: 3 }}
                      suffix={
                        urlPattern.test(form.getFieldValue(["Imaage file"])) ? (
                          <CheckOutlined style={{ color: "green" }} />
                        ) : (
                          <Tooltip title="You need to upload image">
                            <InfoCircleOutlined style={{ color: "red" }} />
                          </Tooltip>
                        )
                      }
                      className="input-border"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {questionType === "starRating" && (
              <Row
                gutter={6}
                style={{
                  paddingTop: 20,
                  borderTop: "1px solid #004479",
                  justifyContent: "space-between",
                }}
              >
                <Col xs={16} offset={2}>
                  <div
                    style={{
                      border: "1px solid #004479",
                      borderRadius: 3,
                      padding: 5,
                      margin: "auto",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    {[...new Array(symbolCount)].map((value, index) =>
                      getCharacter(ratingSymbolType)
                    )}
                  </div>
                </Col>
                <Row>
                  <Col xs={12}>
                    <Dropdown
                      value={ratingSymbolType}
                      handleChange={(value) => setRatingSymbolType(value)}
                      customStyle={{ width: 60 }}
                      options={[
                        {
                          value: "star",
                          label: "star",
                        },
                        {
                          value: "smile",
                          label: "smile",
                        },
                        {
                          value: "heart",
                          label: "heart",
                        },
                      ]}
                    ></Dropdown>
                  </Col>
                  <Col xs={12}>
                    <Dropdown
                      value={symbolCount}
                      handleChange={(value) => setSymbolCount(value)}
                      customStyle={{ width: 60 }}
                      options={[...new Array(6)].map((value, index) => {
                        return {
                          label: index + 5,
                          value: index + 5,
                        };
                      })}
                    ></Dropdown>
                  </Col>
                </Row>
              </Row>
            )}
            {questionType === "imageMultiChoice" && (
              <Form.List name="imageMultiChoice">
                {(fields, { add, remove, move }) => {
                  console.log("gotcha", questionType, fields);
                  return (
                    <DragDropContext
                      onDragEnd={(result) =>
                        move(result.source.index, result.destination.index)
                      }
                    >
                      <Droppable droppableId="listall">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                              paddingTop: 20,
                              borderTop: "1px solid #004479",
                            }}
                          >
                            {fields.map((field, index) => (
                              <Draggable
                                draggableId={field.name.toString()}
                                index={index}
                                key={index}
                              >
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                    gutter={6}
                                  >
                                    <ImageMultiChoiceInput
                                      dragHandleProps={provided.dragHandleProps}
                                      choiceNumber={index + 1}
                                      questionType={questionType}
                                      field={field}
                                      totalChoices={fields.length}
                                      isFilled={
                                        !!(
                                          form.getFieldValue([
                                            "imageMultiChoice",
                                            field.name,
                                            "imageLabel",
                                          ]) &&
                                          form.getFieldValue([
                                            "imageMultiChoice",
                                            field.name,
                                            "imageData",
                                          ])
                                        )
                                      }
                                      addChoice={(newIndex) =>
                                        add(null, newIndex)
                                      }
                                      removeChoice={(index) =>
                                        remove(index - 1)
                                      }
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  );
                }}
              </Form.List>
            )}
            {questionType === "imageMultichoiceStarRating" && (
              <Row
                gutter={6}
                style={{
                  paddingTop: 20,
                  borderTop: "1px solid #004479",
                  justifyContent: "space-between",
                }}
              >
                <Col xs={16} offset={2}>
                  <div
                    style={{
                      border: "1px solid #004479",
                      borderRadius: 3,
                      padding: 5,
                      margin: "auto",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    {[...new Array(symbolCount)].map((value, index) =>
                      getCharacter(ratingSymbolType)
                    )}
                  </div>
                </Col>
                <Row>
                  <Col xs={12}>
                    <Dropdown
                      value={ratingSymbolType}
                      handleChange={(value) => setRatingSymbolType(value)}
                      customStyle={{ width: 60 }}
                      options={[
                        {
                          value: "star",
                          label: "star",
                        },
                        {
                          value: "smile",
                          label: "smile",
                        },
                        {
                          value: "heart",
                          label: "heart",
                        },
                      ]}
                    ></Dropdown>
                  </Col>
                  <Col xs={12}>
                    <Dropdown
                      value={symbolCount}
                      handleChange={(value) => setSymbolCount(value)}
                      customStyle={{ width: 60 }}
                      options={[...new Array(6)].map((value, index) => {
                        return {
                          label: index + 5,
                          value: index + 5,
                        };
                      })}
                    ></Dropdown>
                  </Col>
                </Row>
                <Col xs={24}>
                  <Form.List name="imageMultiChoice">
                    {(fields, { add, remove, move }) => {
                      console.log("gotcha", questionType, fields);
                      return (
                        <DragDropContext
                          onDragEnd={(result) =>
                            move(result.source.index, result.destination.index)
                          }
                        >
                          <Droppable droppableId="listall">
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{
                                  paddingTop: 20,
                                }}
                              >
                                {fields.map((field, index) => (
                                  <Draggable
                                    draggableId={field.name.toString()}
                                    index={index}
                                    key={index}
                                  >
                                    {(provided) => (
                                      <div
                                        {...provided.draggableProps}
                                        ref={provided.innerRef}
                                        gutter={6}
                                      >
                                        <ImageMultiChoiceInput
                                          dragHandleProps={
                                            provided.dragHandleProps
                                          }
                                          choiceNumber={index + 1}
                                          questionType={questionType}
                                          field={field}
                                          totalChoices={fields.length}
                                          isFilled={
                                            !!(
                                              form.getFieldValue([
                                                "imageMultiChoice",
                                                field.name,
                                                "imageLabel",
                                              ]) &&
                                              form.getFieldValue([
                                                "imageMultiChoice",
                                                field.name,
                                                "imageData",
                                              ])
                                            )
                                          }
                                          addChoice={(newIndex) =>
                                            add(null, newIndex)
                                          }
                                          removeChoice={(index) =>
                                            remove(index - 1)
                                          }
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      );
                    }}
                  </Form.List>
                </Col>
              </Row>
            )}
            {questionType === "slider" && (
              <Row
                gutter={6}
                className="slider"
                style={{ paddingTop: 20, borderTop: "1px solid #004479" }}
              >
                <Col
                  xs={16}
                  style={{ flexGrow: 1, color: COLOR.darkBlue }}
                  offset={2}
                >
                  <Slider
                    min={sliderStart}
                    max={sliderEnd}
                    disabled={true}
                    defaultValue={(sliderStart + sliderEnd) / 2}
                    value={(sliderStart + sliderEnd) / 2}
                    marks={{
                      [sliderStart]: sliderStart,
                      [sliderEnd]: sliderEnd,
                    }}
                  />
                  <div
                    style={{
                      padding: 5,
                      margin: "auto",
                      marginBottom: "24px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      justifyContent: "space-between",
                    }}
                  >
                    {
                      <Fragment>
                        <div>{form.getFieldValue(["Start Label"])}</div>
                        <div>{form.getFieldValue(["Middle Label"])}</div>
                        <div>{form.getFieldValue(["End Label"])}</div>
                      </Fragment>
                    }
                  </div>
                </Col>
                <Col
                  xs={16}
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  offset={2}
                >
                  <div>
                    <Dropdown
                      value={sliderType}
                      handleChange={(value) => setSliderType(value)}
                      options={[
                        {
                          label: "Percentage",
                          value: "Percentage",
                        },
                        {
                          label: "Absolute",
                          value: "Absolute",
                        },
                      ]}
                    ></Dropdown>
                  </div>
                  <div>
                    <label style={{ color: COLOR.darkBlue, paddingRight: 10 }}>
                      Start Value
                    </label>
                    <Dropdown
                      value={sliderStart}
                      handleChange={(value) => setSliderStart(value)}
                      options={[...new Array(sliderEnd)].map((v, i) => {
                        return {
                          label: i,
                          value: i,
                        };
                      })}
                    ></Dropdown>
                  </div>
                  <div>
                    <label style={{ color: COLOR.darkBlue, paddingRight: 10 }}>
                      End Value
                    </label>
                    <Dropdown
                      disabled={sliderType === "Percentage" ? true : false}
                      value={sliderEnd}
                      handleChange={(value) => setSliderEnd(value)}
                      options={[...new Array(100 - sliderStart)].map((v, i) => {
                        return {
                          label: i + sliderStart + 1,
                          value: i + sliderStart + 1,
                        };
                      })}
                    ></Dropdown>
                  </div>
                </Col>
                <Col
                  xs={16}
                  style={{ flexGrow: 1, color: COLOR.darkBlue }}
                  offset={2}
                >
                  <Form.Item
                    name="Start Label"
                    label="Start Label"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                  >
                    <Input
                      size="large"
                      placeholder="Start Label"
                      style={{ borderRadius: 3 }}
                      suffix={
                        form.getFieldValue(["Start Label"]) ? (
                          <CheckOutlined style={{ color: "green" }} />
                        ) : (
                          <Tooltip title="You need to fill client criteria!">
                            <InfoCircleOutlined style={{ color: "red" }} />
                          </Tooltip>
                        )
                      }
                      className="input-border"
                    />
                  </Form.Item>
                </Col>
                <Col xs={16} style={{ flexGrow: 1 }} offset={2}>
                  <Form.Item
                    name="Middle Label"
                    label="Middle Label"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                  >
                    <Input
                      size="large"
                      placeholder="Middle Label"
                      style={{ borderRadius: 3 }}
                      suffix={
                        form.getFieldValue(["Middle Label"]) ? (
                          <CheckOutlined style={{ color: "green" }} />
                        ) : (
                          <Tooltip title="You need to fill client criteria!">
                            <InfoCircleOutlined style={{ color: "red" }} />
                          </Tooltip>
                        )
                      }
                      className="input-border"
                    />
                  </Form.Item>
                </Col>
                <Col xs={16} style={{ flexGrow: 1 }} offset={2}>
                  <Form.Item
                    name="End Label"
                    label="End Label"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                  >
                    <Input
                      size="large"
                      placeholder="End Label"
                      style={{ borderRadius: 3 }}
                      suffix={
                        form.getFieldValue(["End Label"]) ? (
                          <CheckOutlined style={{ color: "green" }} />
                        ) : (
                          <Tooltip title="You need to fill client criteria!">
                            <InfoCircleOutlined style={{ color: "red" }} />
                          </Tooltip>
                        )
                      }
                      className="input-border"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {questionType === "range" && (
              <Row
                gutter={6}
                className="slider"
                style={{ paddingTop: 20, borderTop: "1px solid #004479" }}
              >
                <Col xs={16} style={{ flexGrow: 1 }}>
                  <Form.Item name="Range" label="Range" labelCol={{ span: 4 }}>
                    <Dropdown
                      value={range}
                      handleChange={setRange}
                      options={[...new Array(10)].map((v, i) => {
                        return {
                          label: i + 1,
                          value: i + 1,
                        };
                      })}
                    ></Dropdown>
                  </Form.Item>
                </Col>
              </Row>
            )}
            {questionType === "videoInstruction" && (
              <Row
                gutter={6}
                className="slider"
                style={{ paddingTop: 20, borderTop: "1px solid #004479" }}
              >
                <Col xs={8} style={{ flexGrow: 1 }} offset={2}>
                  <Form.Item name="video label">
                    <Input
                      size="large"
                      placeholder="Video label Optional!"
                      style={{ borderRadius: 3 }}
                      suffix={
                        form.getFieldValue(["video label"]) ? (
                          <CheckOutlined style={{ color: "green" }} />
                        ) : (
                          <Tooltip title="You need to fill this option!">
                            <InfoCircleOutlined style={{ color: "red" }} />
                          </Tooltip>
                        )
                      }
                      className="input-border"
                    />
                  </Form.Item>
                </Col>
                <Col xs={8} style={{ flexGrow: 1 }}>
                  <Form.Item
                    name="video"
                    rules={[
                      {
                        required: true,
                        message: "Please upload the video!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="No Video Chosen!"
                      style={{ borderRadius: 3 }}
                      suffix={
                        form.getFieldValue(["video"]) ? (
                          <CheckOutlined style={{ color: "green" }} />
                        ) : (
                          <Tooltip title="You need to fill this option!">
                            <InfoCircleOutlined style={{ color: "red" }} />
                          </Tooltip>
                        )
                      }
                      className="input-border"
                    />
                  </Form.Item>
                </Col>
                <Col xs={4} style={{ flexGrow: 1 }}>
                  <CustomButton>Upload Video</CustomButton>
                </Col>
              </Row>
            )}
            {questionType === "videoMultiChoiceStarRating" && (
              <Row
                gutter={6}
                style={{
                  paddingTop: 20,
                  borderTop: "1px solid #004479",
                  justifyContent: "space-between",
                }}
              >
                <Col xs={16} offset={2}>
                  <div
                    style={{
                      border: "1px solid #004479",
                      borderRadius: 3,
                      padding: 5,
                      margin: "auto",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    {[...new Array(symbolCount)].map((value, index) =>
                      getCharacter(ratingSymbolType)
                    )}
                  </div>
                </Col>
                <Row>
                  <Col xs={12}>
                    <Dropdown
                      value={ratingSymbolType}
                      handleChange={(value) => setRatingSymbolType(value)}
                      customStyle={{ width: 60 }}
                      options={[
                        {
                          value: "star",
                          label: "star",
                        },
                        {
                          value: "smile",
                          label: "smile",
                        },
                        {
                          value: "heart",
                          label: "heart",
                        },
                      ]}
                    ></Dropdown>
                  </Col>
                  <Col xs={12}>
                    <Dropdown
                      value={symbolCount}
                      handleChange={(value) => setSymbolCount(value)}
                      customStyle={{ width: 60 }}
                      options={[...new Array(6)].map((value, index) => {
                        return {
                          label: index + 5,
                          value: index + 5,
                        };
                      })}
                    ></Dropdown>
                  </Col>
                </Row>
                <Col xs={24}>
                  <Form.List name="videoMultiChoice">
                    {(fields, { add, remove, move }) => {
                      console.log("gotcha", questionType, fields);
                      return (
                        <DragDropContext
                          onDragEnd={(result) =>
                            move(result.source.index, result.destination.index)
                          }
                        >
                          <Droppable droppableId="listall">
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{
                                  paddingTop: 20,
                                }}
                              >
                                {fields.map((field, index) => (
                                  <Draggable
                                    draggableId={field.name.toString()}
                                    index={index}
                                    key={index}
                                  >
                                    {(provided) => (
                                      <div
                                        {...provided.draggableProps}
                                        ref={provided.innerRef}
                                        gutter={6}
                                      >
                                        <VideoMultiChoiceInput
                                          dragHandleProps={
                                            provided.dragHandleProps
                                          }
                                          choiceNumber={index + 1}
                                          questionType={questionType}
                                          field={field}
                                          totalChoices={fields.length}
                                          isFilled={
                                            !!(
                                              form.getFieldValue([
                                                "videoMultiChoice",
                                                field.name,
                                                "videoLabel",
                                              ]) &&
                                              form.getFieldValue([
                                                "videoMultiChoice",
                                                field.name,
                                                "videoData",
                                              ])
                                            )
                                          }
                                          addChoice={(newIndex) =>
                                            add(null, newIndex)
                                          }
                                          removeChoice={(index) =>
                                            remove(index - 1)
                                          }
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      );
                    }}
                  </Form.List>
                </Col>
              </Row>
            )}
            {questionType === "imageABTest" && (
              <Form.List name="imageABTest">
                {(fields, { add, remove, move }) => {
                  return (
                    <DragDropContext
                      onDragEnd={(result) =>
                        move(result.source.index, result.destination.index)
                      }
                    >
                      <Droppable droppableId="listall">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                              paddingTop: 20,
                              borderTop: "1px solid #004479",
                            }}
                          >
                            {fields.map((field, index) => (
                              <Draggable
                                draggableId={field.name.toString()}
                                index={index}
                                key={index}
                              >
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                    gutter={6}
                                  >
                                    <ImageABTest
                                      dragHandleProps={provided.dragHandleProps}
                                      choiceNumber={index + 1}
                                      questionType={questionType}
                                      field={field}
                                      totalChoices={fields.length}
                                      isFilled={
                                        !!form.getFieldValue([
                                          "imageABTest",
                                          field.name,
                                          "imageLabel",
                                        ])
                                      }
                                      isSumHundred={
                                        form
                                          .getFieldValue(["imageABTest"])
                                          .filter(
                                            (option) => option && option !== ""
                                          )
                                          .reduce(
                                            (sum, option) =>
                                              sum + parseInt(option.percentage),
                                            0
                                          ) === 100
                                      }
                                      addChoice={(newIndex) =>
                                        add(null, newIndex)
                                      }
                                      removeChoice={(index) =>
                                        remove(index - 1)
                                      }
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  );
                }}
              </Form.List>
            )}
            {questionType === "videoABTest" && (
              <Form.List name="videoABTest">
                {(fields, { add, remove, move }) => {
                  return (
                    <DragDropContext
                      onDragEnd={(result) =>
                        move(result.source.index, result.destination.index)
                      }
                    >
                      <Droppable droppableId="listall">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                              paddingTop: 20,
                              borderTop: "1px solid #004479",
                            }}
                          >
                            {fields.map((field, index) => (
                              <Draggable
                                draggableId={field.name.toString()}
                                index={index}
                                key={index}
                              >
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                    gutter={6}
                                  >
                                    <VideoABTest
                                      dragHandleProps={provided.dragHandleProps}
                                      choiceNumber={index + 1}
                                      questionType={questionType}
                                      field={field}
                                      totalChoices={fields.length}
                                      isFilled={
                                        !!form.getFieldValue([
                                          "videoABTest",
                                          field.name,
                                          "videoLabel",
                                        ])
                                      }
                                      isSumHundred={
                                        form
                                          .getFieldValue(["videoABTest"])
                                          .reduce(
                                            (sum, option) =>
                                              sum + parseInt(option.percentage),
                                            0
                                          ) === 100
                                      }
                                      addChoice={(newIndex) =>
                                        add(null, newIndex)
                                      }
                                      removeChoice={(index) =>
                                        remove(index - 1)
                                      }
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  );
                }}
              </Form.List>
            )}
            {questionType === "imageAssociation" && (
              <Form.List name="imageAssociation">
                {(fields, { add, remove, move }) => {
                  console.log("gotcha", questionType, fields);
                  return (
                    <DragDropContext
                      onDragEnd={(result) =>
                        move(result.source.index, result.destination.index)
                      }
                    >
                      <Droppable droppableId="listall">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                              paddingTop: 20,
                              borderTop: "1px solid #004479",
                            }}
                          >
                            {fields.map((field, index) => (
                              <Draggable
                                draggableId={field.name.toString()}
                                index={index}
                                key={index}
                              >
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                    gutter={6}
                                  >
                                    <ImageAssociation
                                      dragHandleProps={provided.dragHandleProps}
                                      choiceNumber={index + 1}
                                      questionType={questionType}
                                      field={field}
                                      totalChoices={fields.length}
                                      isFilled={
                                        !!(
                                          form.getFieldValue([
                                            "imageAssociation",
                                            field.name,
                                            "imageData",
                                          ]) &&
                                          form.getFieldValue([
                                            "imageAssociation",
                                            field.name,
                                            "association",
                                          ])
                                        )
                                      }
                                      addChoice={(newIndex) =>
                                        add(null, newIndex)
                                      }
                                      removeChoice={(index) =>
                                        remove(index - 1)
                                      }
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  );
                }}
              </Form.List>
            )}
            {questionType === "videoAssociation" && (
              <Form.List name="videoAssociation">
                {(fields, { add, remove, move }) => {
                  console.log("gotcha", questionType, fields);
                  return (
                    <DragDropContext
                      onDragEnd={(result) =>
                        move(result.source.index, result.destination.index)
                      }
                    >
                      <Droppable droppableId="listall">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                              paddingTop: 20,
                              borderTop: "1px solid #004479",
                            }}
                          >
                            {fields.map((field, index) => (
                              <Draggable
                                draggableId={field.name.toString()}
                                index={index}
                                key={index}
                              >
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                    gutter={6}
                                  >
                                    <VideoAssociation
                                      dragHandleProps={provided.dragHandleProps}
                                      choiceNumber={index + 1}
                                      questionType={questionType}
                                      field={field}
                                      totalChoices={fields.length}
                                      isFilled={
                                        !!(
                                          form.getFieldValue([
                                            "videoAssociation",
                                            field.name,
                                            "videoData",
                                          ]) &&
                                          form.getFieldValue([
                                            "videoAssociation",
                                            field.name,
                                            "association",
                                          ])
                                        )
                                      }
                                      addChoice={(newIndex) =>
                                        add(null, newIndex)
                                      }
                                      removeChoice={(index) =>
                                        remove(index - 1)
                                      }
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  );
                }}
              </Form.List>
            )}
          </div>
        </Form>
      </div>
      <div
        className="add-between-question"
        onMouseEnter={() => setIsAddNewQuestionIconShown(true)}
        onMouseLeave={() => setIsAddNewQuestionIconShown(false)}
      >
        {isAddNewQuestionIconShown || window.innerWidth < 768 ? (
          <PlusCircleFilled
            className="action-icon"
            onClick={() => addQuestion(questionNumber)}
          />
        ) : (
          <span className="action-icon">•</span>
        )}
      </div>
    </React.Fragment>
  );
};

export default QuestionBox;
