import React, { useState } from "react";
import "./Market.css";
import { a, b, c, d, e, f } from "../../assets";
import Card from "../Card/Card";
import Description from "./Description";

export default function Market() {
  const data = [
    {
      heading: "BRAND LOYALTY",
      description:
        "Brand loyalty is the biggest determining factor of a company’s long-term success. Brands need a reliable way to measure and understand changes in brand affinity over time.",
      list: [
        "Product reviews",
        "Rewards offering",
        "Social promotions",
        "Customer support",
      ],
    },
    {
      heading: "CONCEPT TESTING",
      description:
        "Brands need to reliably measure the potential viability of products and concepts, as well as test their market potential before investing in development efforts.",
      list: [
        "Monadic testing",
        "Reaction testing",
        "Adoption testing",
        "Migration testing",
      ],
    },
    {
      heading: "IN-HOME USAGE TESTING",
      description:
        "Brands need an accurate and reliable way to test and measure if their product is ready for the perfect launch throughout every stage of the development phase.",
      list: [
        "Diary studies",
        "Video/photo diaries",
        "Pre and post-test evaluation",
        "Discussions",
      ],
    },
    {
      heading: "PRICE TESTING",
      description:
        "Brands need a reliable way to measure price sensitivity in the market before making important business decisions.",
      list: [
        "Discrete choice",
        "Van Westendorp Sensitivity Meter",
        "Max diff",
        "Monadic testing",
        "Paired testing",
      ],
    },
    {
      heading: "AD TESTING",
      description:
        "Brands need an accurate way to engage with their target market and test audience perception.",
      list: [
        "Pre to post-interest life",
        "Copy testing",
        "Heat mapping",
        "Monadic testing",
        "Storyboard testing",
      ],
    },
    {
      heading: "MEDIA & CONTENT EVALUATION",
      description:
        "Brands need a high degree confidence that the marketing content they are creating will resonate with their customers.",
      list: [
        "Highlighting",
        "Hot spotting",
        "Dial testing",
        "Monadic testing",
        "Purchase evaluation",
        "Ad testing",
      ],
    },
    {
      heading: "USABILITY TESTING",
      description:
        "Brands need access to the most sophisticated tools to gauge and enhance the user experience.",
      list: [
        "Card sorts",
        "User tasks",
        "User funnel",
        "Heatmaps",
        "Tree testing",
        "Competitive benchmarks",
      ],
    },
    {
      heading: "BRAND HEALTH TRACKING",
      description:
        "Brands need a reliable way to measure and understand changes in brand perception over time, as well as how they compare to competitors.",
      list: [
        "Brand funnel",
        "Brand personality testing",
        "Competitive benchmarking",
        "Real-time feedback from engaged customers",
      ],
    },
  ];
  const boxes = [
    {
      title: "Retail and CPG",
      image: a,
      body: [
        {
          heading: "Heading1",
          desc:
            " Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident cupiditate necessitatibus sapiente?",
        },
        {
          heading: "dwadaw",
          desc:
            " Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident cupiditate necessitatibus sapiente?",
        },
        {
          heading: "grsgse",
          desc:
            " Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident cupiditate necessitatibus sapiente?",
        },
      ],
    },
    {
      title: "Technology",
      image: c,
      body: [
        {
          heading: "working Click",
          desc:
            " Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident cupiditate necessitatibus sapiente?",
        },
        {
          heading: "working Click2",
          desc:
            " Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident cupiditate necessitatibus sapiente?",
        },
        {
          heading: "working Click3",
          desc:
            " Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident cupiditate necessitatibus sapiente?",
        },
      ],
    },
    {
      title: "Financial Services",
      image: d,
      body: [
        {
          heading: "Heading3",
          desc:
            " Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident cupiditate necessitatibus sapiente?",
        },
      ],
    },
    {
      title: "Healthcare and Insurance",
      image: b,
      body: [
        {
          heading: "Heading4",
          desc:
            " Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident cupiditate necessitatibus sapiente?",
        },
      ],
    },
    {
      title: "Automotive",
      image: f,
      body: [
        {
          heading: "Heading5",
          desc:
            " Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident cupiditate necessitatibus sapiente?",
        },
      ],
    },
    {
      title: "Media and Entertainment",
      image: e,
      body: [
        {
          heading: "Heading5",
          desc:
            " Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident cupiditate necessitatibus sapiente?",
        },
      ],
    },
  ];

  return (
    <section id="market">
      <div className="container-fluid" style={{ padding: 15 }}>
        <div className="content">
          <h1 className="title font-weight-bold">
            See how UserQual is disrupting Market Research
          </h1>
          <div className="market_cards">
            <div className="row justify-content-center">
              {boxes.map(({ image, title }, index) => {
                return (
                  <Card
                    key={index * Math.random() * 20}
                    image={image}
                    heading={title}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row my-3 ">
            <ul className="list">
              {data &&
                data.map(({ heading, description, list }, index) => {
                  return (
                    <div key={index}>
                      <Description
                        desc={description}
                        heading={heading}
                        list={list}
                      />
                    </div>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
