import React, { useState } from "react";
import { message, Upload, Input, Tooltip } from "antd";
import styles from "./SurveyBrief.module.css";
import c from "classnames/bind";
import {
  CheckOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import axios, { Routes } from "../../../../services/API";
import { GetFilename } from "../../../../constants";

const cx = c.bind(styles);

const SurveyBrief = ({ options, setOptions }) => {
  const [uploading, setUploading] = useState(false);
  const uploadProps = {
    customRequest: async function ({ file, onError }) {
      const userId = localStorage.getItem("client");
      const { name, type } = file;
      setUploading(true);
      const { data } = await axios({
        ...Routes.client.uploadFile(userId),
        data: { data: { name, type } },
      });
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", data.signedRequest);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            options.surveyBrief = data.url;
            setOptions({
              ...options,
              surveyBrief: data.url,
            });
            message.success(`${file.name} file uploaded successfully`);
            setUploading(false);
          } else {
            onError();
            message.error(`${file.name} file upload failed.`);
            setUploading(false);
          }
        }
      };
      xhr.send(file);
    },
  };
  return (
    <div className={cx("grid-container")}>
      <div className={cx("fields")} style={{ padding: "0 33px" }}>
        <div style={{ margin: "auto 0" }}>
          <Upload {...uploadProps} showUploadList={false}>
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                border: `1px solid #004479`,
                borderRadius: 3,
                textAlign: "center",
                cursor: "pointer",
                color: "#004479",
                padding: "0 12px",
                width: "100%",
              }}
            >
              Upload File{uploading ? <LoadingOutlined /> : null}
            </div>
          </Upload>
        </div>

        <div>
          {options.surveyBrief ? (
            <Input
              size="large"
              placeholder="No Image Uploaded"
              style={{ borderRadius: 3 }}
              value={GetFilename(options.surveyBrief, 10)}
              disabled
              onChange={(event) =>
                setOptions({
                  ...options,
                  surveyBrief: event.target.value,
                })
              }
              suffix={
                options.surveyBrief ? (
                  <CheckOutlined style={{ color: "green" }} />
                ) : (
                  <Tooltip title="You need to fill this option!">
                    <InfoCircleOutlined style={{ color: "red" }} />
                  </Tooltip>
                )
              }
              className="input-border"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SurveyBrief;
