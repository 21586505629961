import React from "react";
import { Input } from "antd";
import Thumbnail from "../video-thumbnail/VideoThumbnail";

const VideoComment = ({ key, options, value, onChange = () => {} }) => {
  return (
    <div key={key}>
      <Thumbnail url={options.videoData} label={options.videoLabel} />
      <Input.TextArea
        defaultValue={value}
        rows={5}
        placeholder="Please write your answer here"
        style={{
          maxWidth: 800,
          marginTop: 20,
          resize: "none",
          backgroundColor: "#0A3157",
          color: "white",
          borderRadius: 5,
          borderColor: "#0A3157",
        }}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default VideoComment;
