import { HeartFilled, SmileFilled, StarFilled } from "@ant-design/icons";
import { Rate } from "antd";
import React from "react";
import "./star-rating.css";

const StarRating = ({ key, options, onChange = () => {}, value }) => {
  const getCharacter = () => {
    switch (options.starType) {
      case "star":
        return <StarFilled className="character" />;
      case "smiley":
        return <SmileFilled className="character" />;
      case "heart":
        return <HeartFilled className="character" />;
      default:
        return;
    }
  };
  return (
    <div className="rating_container">
      <Rate
        key={key}
        allowClear
        character={getCharacter()}
        count={options.stars}
        defaultValue={value}
        onChange={(val) => onChange(val)}
        className=""
      />
    </div>
  );
};

export default StarRating;
