export default {
  sendOTP: (mobileNumber) => {
    return {
      url: `/verification/OTP?mobileNumber=${mobileNumber}`,
      method: `get`,
    };
  },
  verifyOTP: () => {
    return {
      url: `/verification/OTP`,
      method: `post`,
    };
  },
  sendEmail: (email, role) => {
    return {
      url: `/verification/EMAIL?email=${email}&role=${role}`,
      method: `get`,
    };
  },
  verifyEmail: () => {
    return {
      url: `/verification/EMAIL`,
      method: `post`,
    };
  },
};
