import { COLOR } from "../../../../constants";
export const greyButtonStyle = {
  marginRight: 8,
  marginBottom: 12,
  backgroundColor: "grey",
  color: "white",
  borderRadius: 5,
};

export const darkBlueButtonStyle = {
  marginRight: 8,
  marginBottom: 12,
  backgroundColor: COLOR.darkBlue,
  color: "white",
  borderRadius: 5,
};

export const tealButtonStyle = {
  marginRight: 8,
  marginBottom: 12,
  backgroundColor: COLOR.teal,
  color: "#01213f",
  borderRadius: 5,
  border: "none",
};

export const dullGreenButtonStyle = {
  marginRight: 8,
  marginBottom: 12,
  backgroundColor: COLOR.dullLightGreen,
  color: "white",
  borderRadius: 5,
};

export const chartCheckboxStyle = {
  border: "none",
  backgroundColor: "none",
  margin: 5,
  padding: "5px 5px 5px 8px",
};

export const greyLabelStyle = {
  color: COLOR.grey,
  fontSize: 14,
  fontWeight: "bold",
  margin: 5,
  padding: "5px 5px 5px 8px",
};

export const holoButtonStyle = {
  marginRight: 8,
  marginBottom: 12,
  borderRadius: 5,
  color: "rgb(0, 68, 121)",
  borderColor: "rgb(0, 68, 121)",
};

export const inlineLabelStyle = {
  display: "inline-block",
  minWidth: "110px",
};
