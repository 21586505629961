import React from "react";
import { COLOR } from "../../constants";
import { Checkbox } from "antd";

const FilterCheckbox = ({
  label,
  checked,
  onChange,
  style,
  labelStyle,
  classes,
  id,
  disabled,
}) => {
  return (
    <div
      className={classes ? classes : null}
      style={
        style
          ? style
          : {
              borderRadius: 5,
              border: `solid lightgrey 2px`,
              backgroundColor: "white",
              margin: 5,
              padding: "5px 5px 5px 8px",
            }
      }
    >
      <Checkbox
        disabled={disabled}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      >
        <span
          style={
            labelStyle ? labelStyle : { color: COLOR.darkBlue, fontSize: 18 }
          }
        >
          {label}
        </span>
      </Checkbox>
    </div>
  );
};

export default FilterCheckbox;
