import React, { Fragment, useState } from "react";
import Survey from "../Survey";
import useHandleScreeningQuestions from "../../../hooks/useHandleScreeningQuestions";
import { getScreeningQuestions } from "../../../utils/screeningUtils";
import { surveyTypes } from "../../../constants/constants";
import {
  LogoutOutlined
} from "@ant-design/icons"
import c from "classnames/bind";
import styles from "../survey.module.css";

const cx = c.bind(styles);

function ScreeningWrapper({ survey, onPass, onClose }) {
  const [showFailedScreeningPage, setShowFailedScreeningPage] = useState(false)
  const questions = getScreeningQuestions(survey);
  const handleScreeningQuestions = useHandleScreeningQuestions();

  const onEnd = async (answers) => {
    const result = await handleScreeningQuestions(survey, answers)
      console.log(result)
    
    if (result) {
      onPass();
    } else {
      
        setShowFailedScreeningPage(true)
    
    }
  };

  return (
    <Fragment>
      {showFailedScreeningPage ?  
      <div className={cx("survey-container")}>
        {!window.location.pathname.includes('/attemptSurvey') && <div
                    onClick={() => {
                      onClose();
                    }}
                    className={cx("quit-button")}
                    title="Quit Survey"
                  >
                    QUIT SURVEY <LogoutOutlined />
                  </div>
                  }
      <div
        className={cx("center")}
      >
    <div className={cx("question-box")}>
    <div
          style={{
              flexGrow: 1,
              display:"block",
              alignItems: "center",
              fontSize: 16,
            }}
          >
    <p 
    style={{
      fontSize: 20,
      fontWeight: 600,
      textAlign: "center",
    }}>Sorry!</p>
    <p style={{ margin: 0, textAlign: "center" }}>
    You failed the screening test.
    </p>
    </div>
    </div>
    </div>
    </div>
      : <Survey
        surveyType={surveyTypes.QUANTITATIVE}
        surveyAccess={survey.surveyAccess}
        isOpen={true}
        questions={questions}
        onEndSurvey={onEnd}
        onClose={onClose}
        brandLogo={survey.brandLogo}
        brandName={survey.brandName}
        surveyInstructions={survey.instructions}
      />
}
    </Fragment>
  );
}

export default ScreeningWrapper;
