import axios from "axios";
import user from "./user.js";
import client from "./client.js";
import admin from "./admin.js";
import verification from "./verification.js";
import others from "./others.js";
import { BASEURL } from "../../constants.js";

axios.defaults.baseURL = `${BASEURL}/api`;

axios.interceptors.request.use(
  function (config) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    return { ...config, headers };
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const Routes = {
  user,
  client,
  admin,
  verification,
  others,
};

export default axios;
