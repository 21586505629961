import { LeftOutlined, ManOutlined, WomanOutlined } from "@ant-design/icons";
import { Slider, Row, Col, Select } from "antd";
import React, { useState } from "react";
import Modal from "react-modal";
import ReactPlayer from "react-player/lazy";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import "./emotional-analysis-modal.css";
import Chart from "react-google-charts";
import { useMediaQuery } from "react-responsive";
import axios, { Routes } from "../../../../../../services/API";
import { notify } from "../../../../../../utils";

const emotionColor = {
  CONFUSED: "#000000",
  SAD: "#000080",
  CALM: "#FFA500",
  ANGRY: "#FF0000",
  FEAR: "#9400D3",
  DISGUSTED: "#7B68EE",
  SURPRISED: "#800080",
  HAPPY: "#32CD32",
};

const modalStyles = {
  content: {
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    border: 0,
    padding: 0,
    borderRadius: 0,
    backgroundColor: "#ECF9FA",
  },
};

const getFeatures = (figures) => {
  const features = Object.keys(figures)
    .map((val) => {
      if (figures[val].Value === true) {
        switch (val) {
          case "EyesOpen":
            return "Eyes open";
          case "MouthOpen":
            return "Mouth open";
          default:
            return val;
        }
      }
      return;
    })
    .filter((val) => val);

  return features;
};

const getRoundOffNumber = (num) => {
  return (num + "").indexOf("9") > -1 ? num + 1 : num;
};

const getTimelineData = (emotionData, seconds) => {
  const data = [];
  emotionData.forEach((val) => {
    const emotion = val.Face.Emotions[0].Type;
    const timestamp = getRoundOffNumber(val.Timestamp) / 1000;

    if (data.length === 0) {
      data.push([
        "Emotion",
        emotion,
        emotionColor[emotion],
        new Date(0, 0, 0, 0, 0, 0),
        new Date(0, 0, 0, 0, 0, timestamp),
      ]);
    } else {
      if (data[data.length - 1][1] === emotion) {
        data[data.length - 1][4] = new Date(0, 0, 0, 0, 0, timestamp);
      } else {
        data.push([
          "Emotion",
          emotion,
          emotionColor[emotion],
          data[data.length - 1][4],
          new Date(0, 0, 0, 0, 0, timestamp),
        ]);
      }
    }
  });
  return data.filter((val) => val[3] < new Date(0, 0, 0, 0, 0, seconds));
};

const getLineChartData = (emotionData, seconds) => {
  // [seconds, 'CONFUSED', 'SAD', 'CALM', 'ANGRY', 'FEAR', 'DISGUSTED', 'SURPRISED', 'HAPPY' ]
  const data = emotionData.map((val) => {
    const seconds = val.Timestamp / 1000;
    const confused = val.Face.Emotions.find((el) => el.Type === "CONFUSED")
      .Confidence;
    const sad = val.Face.Emotions.find((el) => el.Type === "SAD").Confidence;
    const calm = val.Face.Emotions.find((el) => el.Type === "CALM").Confidence;
    const angry = val.Face.Emotions.find((el) => el.Type === "ANGRY")
      .Confidence;
    const fear = val.Face.Emotions.find((el) => el.Type === "FEAR").Confidence;
    const disgusted = val.Face.Emotions.find((el) => el.Type === "DISGUSTED")
      .Confidence;
    const surprised = val.Face.Emotions.find((el) => el.Type === "SURPRISED")
      .Confidence;
    const happy = val.Face.Emotions.find((el) => el.Type === "HAPPY")
      .Confidence;
    return [
      seconds,
      confused,
      sad,
      calm,
      angry,
      fear,
      disgusted,
      surprised,
      happy,
    ];
  });
  return data.filter((val) => val[0] <= seconds);
};

const VerticalBar = ({ percent = 0, color = "#4c839e" }) => {
  return (
    <div
      style={{
        height: "100%",
        width: 12,
        backgroundColor: "#f5f5f5",
        margin: "0 auto",
        position: "relative",
        borderRadius: 6,
      }}
    >
      <div
        style={{
          height: percent + "%",
          width: "100%",
          backgroundColor: color,
          position: "absolute",
          bottom: 0,
          borderRadius: 6,
        }}
      ></div>
    </div>
  );
};

const getAverageAgeRange = (data) => {
  const low = data.map((val) => val.Face.AgeRange.Low);
  const high = data.map((val) => val.Face.AgeRange.High);

  const lowAverage = low.reduce((total, val) => total + val, 0) / low.length;
  const highAverage = high.reduce((total, val) => total + val, 0) / high.length;
  return `${lowAverage.toFixed(0)}-${highAverage.toFixed(0)}`;
};

const emotions = [
  "CONFUSED",
  "SAD",
  "CALM",
  "ANGRY",
  "FEAR",
  "DISGUSTED",
  "SURPRISED",
  "HAPPY",
];

const EmotionalAnalysisModal = ({
  isOpen,
  closeModal,
  data,
  videoUrl,
  userId,
  survey,
}) => {
  const [emotionData, setEmotionData] = useState(data);
  const [url, setUrl] = useState(videoUrl);
  const [figures, setFigures] = useState({});
  const [seconds, setSeconds] = useState(0);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const xlBreakpoint = useMediaQuery({ query: "(max-width: 1200px)" });

  const participants = survey.responses.map((val) => val.userId._id);

  const updateEmotionDataOnProgress = ({ playedSeconds }) => {
    const playedMilliSeconds = playedSeconds.toFixed(0) * 1000;
    if (emotionData.find((val) => val.Timestamp >= playedMilliSeconds)) {
      setFigures(
        emotionData.find((val) => val.Timestamp >= playedMilliSeconds)?.Face
      );
    }
    setSeconds(playedSeconds.toFixed(0));
  };

  const onParticipantChange = async (userId) => {
    const uploadURL = `${survey._id}_${userId}`;
    if (!survey.emotionJobId || !survey.emotionJobId[uploadURL]) {
      notify(
        "error",
        "Error",
        "No emotional analysis found for this participant."
      );
      return;
    }
    const jobId = survey.emotionJobId[uploadURL];
    const clientId = localStorage.getItem("client");
    try {
      let result = [];
      let nextToken = "";
      while (true) {
        const { data } = await axios({
          ...Routes.client.getVideoAnalysis(
            clientId,
            survey._id,
            jobId,
            nextToken
          ),
        });
        result = result.concat(data.Faces);
        if (data.NextToken) {
          nextToken = data.NextToken;
        } else {
          break;
        }
      }
      setEmotionData(data.Faces);
      setUrl(
        `https://userqual-video-recording.s3.ap-south-1.amazonaws.com/${survey._id}_${userId}.mp4`
      );
    } catch (err) {
      notify(
        "error",
        "Error",
        "No emotional analysis found for this participant."
      );
    }
  };

  const onCloseModal = () => {
    closeModal();
  };
  return (
    <Modal isOpen={isOpen} onRequestClose={onCloseModal} style={modalStyles}>
      <RemoveScrollBar />
      <div
        style={{
          backgroundColor: "#00233F",
          padding: isTabletOrMobile ? "10px 24px" : "10px 56px",
          marginBottom: 20,
        }}
      >
        <h3
          onClick={onCloseModal}
          style={{
            cursor: "pointer",
            color: "white",
            marginBottom: 0,
            display: "flex",
          }}
        >
          <div>
            <LeftOutlined /> Back
          </div>
          <div style={{ flexGrow: 1, textAlign: "center" }}>
            Video sentiment analysis
          </div>
        </h3>
      </div>
      <div style={{ padding: isTabletOrMobile ? "0px 24px" : "0px 56px" }}>
        <Row gutter={[12, 12]}>
          <Col
            xs={{ span: 24, order: 2 }}
            xl={{ span: 5, order: 1 }}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Select
              defaultValue={userId}
              onChange={onParticipantChange}
              size="large"
              className="emotional-participant"
              style={{ width: "100%", marginBottom: 12 }}
            >
              {participants.map((val, index) => (
                <Select.Option value={val}>
                  Participant {index + 1}
                </Select.Option>
              ))}
            </Select>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 20,
                padding: "24px",
                flexGrow: 1,
              }}
            >
              <h2>
                <b>Facial features</b>
              </h2>
              {getFeatures(figures).map((val) => (
                <li style={{ marginBottom: 10 }}>
                  <b>{val}</b>
                </li>
              ))}
            </div>
          </Col>
          <Col xs={{ span: 24, order: 1 }} xl={{ span: 10, order: 2 }}>
            <ReactPlayer
              url={url}
              playing={true}
              controls={true}
              onProgress={updateEmotionDataOnProgress}
              width="100%"
              height="100%"
            />
          </Col>
          <Col
            xs={{ span: 24, order: 3 }}
            xl={{ span: 9, order: 3 }}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {/* AGE AND GENDER */}
            <div
              style={{
                backgroundColor: "#00233F",
                borderRadius: 20,
                color: "white",
                fontWeight: "bold",
                padding: "24px",
                marginBottom: 12,
                width: xlBreakpoint ? "100%" : 250,
              }}
            >
              <Row gutter={24}>
                <Col>
                  <h1 style={{ color: "white", fontWeight: "bold" }}>
                    {/* {figures?.AgeRange?.Low}-{figures?.AgeRange?.High} */}
                    {getAverageAgeRange(emotionData)}
                  </h1>
                  Likely age
                </Col>
                <Col>
                  <h1 style={{ color: "white", fontWeight: "bold" }}>
                    <ManOutlined
                      style={{
                        opacity:
                          figures?.Gender?.Value === "Male" ? "1" : "0.5",
                        marginRight: 20,
                      }}
                    />
                    <WomanOutlined
                      style={{
                        opacity:
                          figures?.Gender?.Value === "Female" ? "1" : "0.5",
                      }}
                    />
                  </h1>
                  Likely gender
                </Col>
              </Row>
            </div>
            {/* VIDEO QUALITY */}
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 20,
                padding: "12px",
                width: xlBreakpoint ? "100%" : 250,
                textAlign: "center",
                flexGrow: 1,
              }}
            >
              <h3 style={{ fontWeight: "bold" }}>Video quality</h3>
              {figures.Quality &&
                Object.keys(figures.Quality).map((val) => (
                  <div
                    style={{
                      display: "inline-block",
                      marginRight: 10,
                      marginBottom: 12,
                    }}
                  >
                    {val}
                    <div style={{ height: 90 }}>
                      <VerticalBar percent={figures.Quality[val]} />
                    </div>
                  </div>
                ))}
            </div>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          {/* LEGENDS */}
          <Col xs={24} xl={5}>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 20,
                padding: "12px",
              }}
            >
              <h3 style={{ fontWeight: "bold" }}>Legends</h3>
              {Object.keys(emotionColor).map((val) => (
                <div>
                  <div
                    style={{
                      display: "inline-block",
                      height: 10,
                      width: 10,
                      backgroundColor: emotionColor[val],
                      marginRight: 20,
                    }}
                  ></div>
                  {val}
                </div>
              ))}
            </div>
          </Col>
          {/* GRAPHS */}
          <Col xs={24} xl={19}>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 20,
                padding: "12px",
                height: "100%",
              }}
            >
              <Chart
                width={"100%"}
                height="95px"
                chartType="Timeline"
                loader={<div>Loading Chart</div>}
                data={[
                  [
                    { type: "string", id: "Position" },
                    { type: "string", id: "Name" },
                    { type: "string", role: "style" },
                    { type: "date", id: "Start" },
                    { type: "date", id: "End" },
                  ],
                  ...getTimelineData(emotionData, seconds),
                ]}
                options={{
                  timeline: { showRowLabels: false },
                  avoidOverlappingGridLines: false,
                }}
              />
              <Chart
                width={"100%"}
                height={"100px"}
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={[
                  [
                    "x",
                    "CONFUSED",
                    "SAD",
                    "CALM",
                    "ANGRY",
                    "FEAR",
                    "DISGUSTED",
                    "SURPRISED",
                    "HAPPY",
                  ],
                  ...getLineChartData(emotionData, seconds),
                ]}
                options={{
                  hAxis: {
                    title: "Time (in seconds)",
                  },
                  legend: "none",
                  series: {
                    3: { color: emotionColor.ANGRY },
                    1: { color: emotionColor.SAD },
                    2: { color: emotionColor.CALM },
                    0: { color: emotionColor.CONFUSED },
                    4: { color: emotionColor.FEAR },
                    5: { color: emotionColor.DISGUSTED },
                    6: { color: emotionColor.SURPRISED },
                    7: { color: emotionColor.HAPPY },
                  },
                  // chartArea: { width: '100%', height: '80%' }
                  theme: "maximized",
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24} xl={{ offset: 5, span: 19 }}>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 20,
                padding: "12px",
                textAlign: "center",
              }}
            >
              <h3 style={{ fontWeight: "bold" }}>Emotions</h3>
              {emotions.map((val) => (
                <div
                  style={{
                    display: "inline-block",
                    marginRight: 10,
                    marginBottom: 12,
                    width: 80,
                    textAlign: "center",
                  }}
                >
                  {val}
                  <div style={{ height: 90 }}>
                    <VerticalBar
                      percent={
                        figures?.Emotions?.find((el) => el.Type === val)
                          .Confidence
                      }
                      color={emotionColor[val]}
                    />
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default EmotionalAnalysisModal;
