import React from "react";
import c from "classnames/bind";
import styles from "./additional-criteria.module.css";
import { Select, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import MultiChoices from "../questions/multi-choices/MultiChoices";
import AddLink from "../questions/add-link/AddLink";

const cx = c.bind(styles);
const { Option } = Select;

const AdditionalCriteria = ({ question, setQuestion }) => {
  return (
    <>
      {!question && <div></div>}
      {question && (
        <div className={cx("question-box")}>
          <textarea
            className={cx("textarea", {
              "textarea-invalid": question.text === "",
            })}
            style={{ border: "1px solid #EDEDED" }}
            value={question.text}
            onChange={(e) => setQuestion({ ...question, text: e.target.value })}
          />
          <div className={styles["question-buttons"]}>
            <Select
              bordered={false}
              value={question.type}
              defaultValue="default"
              style={{ border: "1px solid #EDEDED" }}
              className={cx("question-button", {
                "question-button-active": question.type !== "default",
              })}
              suffixIcon={
                <DownOutlined
                  className={cx("select-arrow", {
                    "select-arrow-active": question.type !== "default",
                  })}
                />
              }
              onChange={(type) => setQuestion({ ...question, type })}
            >
              <Option value="default">Question Type</Option>
              <Option value="multiChoices">Multiple Choice</Option>
              <Option value="checkBoxes">Checkboxes</Option>
            </Select>
            {question.type !== "default" && false && (
              <Button
                style={{ alignSelf: "end" }}
                className={cx("question-button", {
                  "question-button-active": question.hasLink,
                })}
                onClick={() => {
                  setQuestion({ ...question, hasLink: !question.hasLink });
                }}
              >
                Add Link
              </Button>
            )}
          </div>
          <div className={cx("question-grid")}>
            {question.hasLink && (
              <AddLink
                link={question.link}
                setLink={(link) => setQuestion({ ...question, link })}
              />
            )}
            {question.type !== "default" && (
              <div style={{ marginTop: 12, borderTop: "1px solid #404040" }}>
                <MultiChoices
                  options={question.options}
                  setOptions={(options) =>
                    setQuestion({ ...question, options })
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AdditionalCriteria;
