import React, { useEffect, useState } from "react";
import styles from "./QuestionPopup.module.css";
import c from "classnames/bind";
import {
  CloseCircleOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { Button, message, Badge, InputNumber, Modal as AntdModal } from "antd";
import { useMediaQuery } from "react-responsive";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import Text from "../../questions/text/Text";
import MultipleChoice from "../../questions/multiple-choice/MultipleChoice";
import Checkboxes from "../../questions/checkboxes/Checkboxes";
import StarRating from "../../questions/star-rating/StarRating";
import SliderType from "../../questions/slider/Slider";
import Likert from "../../questions/likert/Likert";
import NPS from "../../questions/nps/NPS";
import Ranking from "../../questions/ranking/Ranking";
import Dropdown from "../../questions/dropdown/Dropdown";
import Association from "../../questions/association/Association";
import ImageInstructions from "../../questions/image/instructions/ImageInstructions";
import ImageComment from "../../questions/image/comment/ImageComment";
import ImageMultipleChoice from "../../questions/image/multiple-choice/ImageMultipleChoice";
import ImageCheckboxes from "../../questions/image/checkboxes/ImageCheckboxes";
import ImageRating from "../../questions/image/rating/ImageRating";
import ImageRanking from "../../questions/image/ranking/ImageRanking";
import ImageAssociation from "../../questions/image/association/ImageAssociation";
import Modal from "react-modal";
import VideoInstruction from "../../questions/video/instruction/VideoInstruction";
import VideoRating from "../../questions/video/rating/VideoRating";
import VideoAssociation from "../../questions/video/association/VideoAssociation";
import VideoRanking from "../../questions/video/ranking/VideoRanking";
import VideoMultipleChoice from "../../questions/video/multiple-choice/VideoMultipleChoice";
import VideoCheckboxes from "../../questions/video/checkboxes/VideoCheckboxes";
import VideoComment from "../../questions/video/comment/VideoComment";
import CheckboxesSpecial from "../../../../../../components/user/profile-questions/checkboxes-special/CheckboxesSpecial";
import AdAnalysis from "../../questions/ad-analysis/AdAnalysis";
// import { getWordCloudAnalysis } from "../../constants/getWordCloudReport";
const modalStyles = {
  content: {
    top: "10%",
    left: "20%",
    width: "80vh",
    height: "80vh",
    border: 0,
    padding: 0,
    borderRadius: 0,
  },
};

const cx = c.bind(styles);

const isTypeRequired = (type) => {
  return [
    "text",
    "multiChoices",
    "checkBoxes",
    "stars",
    "likert",
    "likert",
    "imageMultiChoices",
    "imageCheckBoxes",
    "netPromotorScore",
    "videoComment",
    "imageComment",
    "videoMultiChoices",
    "videoCheckBoxes",
    "imageStarRatingChoices",
    "videoStarRatingChoices",
  ].includes(type);
};

const emptyCheck = (type, answer) => {
  const specialTypes = [
    "imageStarRatingChoices",
    "videoStarRatingChoices",
    "text",
    "imageComment",
    "videoComment",
  ];
  if (answer && !specialTypes.includes(type)) {
    return answer.length === 0;
  }
  let isEmpty = false;
  switch (type) {
    case "text":
      return answer.length === 0 || answer[0] === "";
    case "imageComment":
      return answer.length === 0 || answer[0] === "";
    case "videoComment":
      return answer.length === 0 || answer[0] === "";
    case "imageStarRatingChoices":
      Object.keys(answer[0]).forEach((el) => {
        if (answer[0][el] === 0) {
          isEmpty = true;
          return;
        }
      });
      return isEmpty;
    case "videoStarRatingChoices":
      Object.keys(answer[0]).forEach((el) => {
        if (answer[0][el] === 0) {
          isEmpty = true;
          return;
        }
      });
      return isEmpty;
    default:
      return false;
  }
};

let answers = [];
let hiddenQuestions = [];
const QuestionPopup = ({
  questions = [],
  isOpen,
  onClose,
  onEndQuestion = () => {},
  onDisqualification = () => {},
  surveyType,
  video,
  userId = null,
  onSubmitLiveQuestion,
}) => {
  // Hooks
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [isDisqualified, setIsDisqualified] = useState(false);

  useEffect(() => {
    // Populate answers with empty array
    [...Array(questions.length).keys()].map((i) => (answers[i] = []));
    console.log("population done");
  }, []);

  console.log("question in popup", questions);

  // Derived state
  const index = 0;
  const getQuestionText = () => {
    const { type, text, options } = questions[index];

    switch (type) {
      case "netPromotorScore":
        const { npsOption } = options;
        return `How likely is it that you would recommend ${npsOption} to a friend or collegue?`;
      default:
        return text;
    }
  };

  const getComponentByQuestionType = () => {
    const { type, options } = questions[index];

    const onChange = (values) => {
      answers[index] = Array.isArray(values) ? values : [values];
    };

    if (answers.length === 0) {
      [...Array(questions.length)].map(() => answers.push([]));
    }

    if (answers[index]) {
      switch (type) {
        case "text":
          return (
            <Text key={index} onChange={onChange} value={answers[index][0]} />
          );
        case "multiChoices":
          return (
            <MultipleChoice
              key={index}
              choices={options.multiChoices}
              onChange={onChange}
              value={answers[index][0]}
            />
          );
        case "checkBoxes":
          return (
            <Checkboxes
              key={index}
              choices={options.multiChoices}
              onChange={onChange}
              value={answers[index]}
            />
          );
        case "stars":
          const { stars, starType } = options;
          return (
            <StarRating
              key={index}
              options={{ stars, starType }}
              onChange={onChange}
              value={answers[index][0]}
            />
          );
        case "slider":
          answers[index][0] = answers[index][0] || 0;
          const {
            sliderType,
            sliderValue,
            sliderMin,
            sliderMax,
            sliderStartLabel,
            sliderMiddleLabel,
            sliderEndLabel,
          } = options;
          return (
            <SliderType
              key={index}
              options={{
                sliderType,
                sliderValue,
                sliderMin,
                sliderMax,
                sliderStartLabel,
                sliderMiddleLabel,
                sliderEndLabel,
              }}
              onChange={onChange}
              value={answers[index][0]}
            />
          );
        case "likert":
          const {
            likertRange,
            likertStartLabel,
            likertMiddleLabel,
            likertEndLabel,
          } = options;
          return (
            <Likert
              key={index}
              options={{
                likertRange,
                likertStartLabel,
                likertMiddleLabel,
                likertEndLabel,
              }}
              onChange={onChange}
              value={answers[index][0]}
            />
          );
        case "netPromotorScore":
          return (
            <NPS
              key={index}
              options={{ npsOption: options.npsOption }}
              onChange={onChange}
              value={answers[index][0]}
            />
          );
        case "ranking":
          answers[index] =
            answers[index].length > 0 ? answers[index] : options.multiChoices;
          return (
            <Ranking key={index} onChange={onChange} value={answers[index]} />
          );
        case "dropDown":
          answers[index][0] = answers[index][0] || 1;
          const range = options.range;
          return (
            <Dropdown
              key={index}
              options={{ range }}
              onChange={onChange}
              value={answers[index][0]}
            />
          );
        case "association":
          answers[index] =
            answers[index].length > 0
              ? answers[index]
              : options.associationChoices;
          return (
            <Association
              key={index}
              onChange={onChange}
              value={answers[index]}
            />
          );
        case "imageInstruction":
          return <ImageInstructions key={index} options={options} />;
        case "imageComment":
          return (
            <ImageComment
              key={index}
              options={{
                imageData: options.imageData,
                imageLabel: options.imageLabel,
              }}
              value={answers[index][0]}
              onChange={onChange}
            />
          );
        /* case "imageABTest":
      const { imageABTestChoices } = options
      return <ImageInstructions options={{ imageABTestChoices }} />; */
        case "imageMultiChoices":
          const { imageMultiChoices } = options;
          return (
            <ImageMultipleChoice
              key={index}
              options={{ imageMultiChoices }}
              value={answers[index][0]}
              onChange={onChange}
            />
          );
        case "imageCheckBoxes":
          return (
            <ImageCheckboxes
              key={index}
              options={{ imageMultiChoices: options.imageMultiChoices }}
              value={answers[index]}
              onChange={onChange}
            />
          );
        case "imageStarRatingChoices":
          if (answers[index].length === 0) {
            answers[index][0] = {};
            options.imageStarRatingChoices.forEach((val) => {
              answers[index][0][val.imageLabel] = 0;
            });
          }
          const { imageStarRatingChoices, imageStar, imageStarType } = options;
          return (
            <ImageRating
              key={index}
              options={{ imageStarRatingChoices, imageStar, imageStarType }}
              value={answers[index][0]}
              onChange={onChange}
            />
          );
        case "imageRanking":
          answers[index] =
            answers[index].length > 0
              ? answers[index]
              : options.imageMultiChoices;
          return (
            <ImageRanking
              key={index}
              onChange={onChange}
              value={answers[index]}
            />
          );
        case "imageAssociation":
          answers[index] =
            answers[index].length > 0
              ? answers[index]
              : options.imageAssociationChoices;
          return (
            <ImageAssociation
              key={index}
              options={options}
              onChange={onChange}
              value={answers[index]}
            />
          );
        case "videoInstruction":
          return <VideoInstruction key={index} options={options} />;
        case "videoComment":
          return (
            <VideoComment
              key={index}
              options={{
                videoData: options.videoData,
                videoLabel: options.videoLabel,
              }}
              value={answers[index][0]}
              onChange={onChange}
            />
          );
        case "videoMultiChoices":
          const { videoMultiChoices } = options;
          return (
            <VideoMultipleChoice
              key={index}
              options={{ videoMultiChoices }}
              value={answers[index][0]}
              onChange={onChange}
            />
          );
        case "videoCheckBoxes":
          return (
            <VideoCheckboxes
              key={index}
              options={{ videoMultiChoices: options.videoMultiChoices }}
              value={answers[index]}
              onChange={onChange}
            />
          );
        case "videoStarRatingChoices":
          if (answers[index].length === 0) {
            answers[index][0] = {};
            options.videoStarRatingChoices.forEach((val) => {
              answers[index][0][val.videoLabel] = 0;
            });
          }
          const { videoStarRatingChoices, videoStar, videoStarType } = options;
          return (
            <VideoRating
              key={index}
              options={{ videoStarRatingChoices, videoStar, videoStarType }}
              value={answers[index][0]}
              onChange={onChange}
            />
          );
        case "videoRanking":
          answers[index] =
            answers[index].length > 0
              ? answers[index]
              : options.videoMultiChoices;
          return (
            <VideoRanking
              key={index}
              onChange={onChange}
              value={answers[index]}
            />
          );
        case "videoAssociation":
          answers[index] =
            answers[index].length > 0
              ? answers[index]
              : options.videoAssociationChoices;
          return (
            <VideoAssociation
              key={index}
              options={options}
              onChange={onChange}
              value={answers[index]}
            />
          );
        case "age":
          return (
            <InputNumber
              key={index}
              defaultValue={answers[index]}
              onChange={onChange}
              style={{
                backgroundColor: "#004479",
                color: "white",
                border: "1px solid white",
              }}
            />
          );
        case "checkboxesSpecial":
          const { specialField, multiChoices } = options;
          return (
            <CheckboxesSpecial
              key={index}
              choices={multiChoices}
              onChange={onChange}
              value={answers[index]}
              specialField={specialField}
              color="white"
            />
          );
        case "adAnalysis":
          const { adAnalysisData } = options;
          return <AdAnalysis options={adAnalysisData} />;
        default:
          return;
      }
    }
  };

  const onQuestionSubmit = async () => {
    if (userId !== null) {
      answers = await Promise.all(
        answers.map(async (answer, index) => {
          if (
            ["text", "imageComment", "videoComment"].indexOf(
              questions[index].type
            ) > -1
          ) {
            const report = [];
            // const report = await getWordCloudAnalysis(answer, userId);
            answer.push(report);
          }
          return answer;
        })
      );
    }
    // console.log("answers", userId, answers[0] ? answers[0] : []);
    onSubmitLiveQuestion(userId, answers[0] ? answers[0] : []);
    onCloseModal();
  };

  const onQuestionSkip = () => {
    onCloseModal();
  };

  const onCloseModal = () => {
    answers = [];
    hiddenQuestions = [];
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onCloseModal} style={modalStyles}>
      <RemoveScrollBar />
      <div className={cx("survey-container")}>
        <div className={cx("title")}>
          <span style={{ flexGrow: 1, textAlign: "center" }}></span>
          <CloseCircleOutlined
            onClick={onCloseModal}
            style={{
              fontSize: 32,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          />
        </div>
        <div className={cx("center")}>
          <React.Fragment>
            <React.Fragment>
              {surveyType && (
                <p
                  style={{
                    textAlign: "left",
                    maxWidth: 800,
                    margin: "0px auto 15px",
                    color: "#8FB0C1",
                  }}
                >
                  Read the Question aloud
                </p>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  maxWidth: 800,
                  margin: "0px auto",
                }}
              >
                {/* <div>
                  <Badge
                    count={step}
                    style={{ backgroundColor: "#004479", marginRight: 10 }}
                  />
                </div> */}
                <p style={{ textAlign: "left", marginBottom: 0 }}>
                  {questions[index] && getQuestionText()}
                  <br />
                  {questions[index] && questions[index].description ? (
                    <span style={{ color: "#BAE0EB" }}>
                      {questions[index].description}
                    </span>
                  ) : null}
                  {surveyType &&
                  questions[index - 1] &&
                  questions[index - 1].clientLink &&
                  questions[index - 1].clientLink.length > 0 ? (
                    <p style={{ color: "#8FB0C1", margin: "10px 0px 0px" }}>
                      (The previous link was{" "}
                      <a
                        href={questions[index - 1].clientLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#0561BE" }}
                      >
                        {questions[index - 1].clientLink}
                      </a>
                      )
                    </p>
                  ) : null}
                </p>
              </div>
              {questions[index] &&
              questions[index].clientLink &&
              questions[index].clientLink.length > 0 ? (
                <div
                  style={{
                    backgroundColor: "#00C194",
                    borderRadius: 8,
                    padding: "10px 20px",
                    maxWidth: 600,
                    margin: "30px auto 0px",
                    cursor: "pointer",
                  }}
                >
                  Open link:{" "}
                  <a
                    href={`${questions[index].clientLink}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {questions[index].clientLink}
                  </a>{" "}
                  (Click here)
                </div>
              ) : null}
              <div className={cx("answerBox")}>
                {questions[index] && getComponentByQuestionType()}
              </div>
              {surveyType && (
                <p
                  style={{
                    textAlign: "left",
                    maxWidth: 800,
                    margin: "0px auto 15px",
                    color: "#8FB0C1",
                    marginTop: 40,
                  }}
                >
                  Take your time. Think aloud. Move on to the next step when
                  you've completed this question.
                </p>
              )}
            </React.Fragment>
            <Button
              style={{ color: "white", background: "transparent" }}
              onClick={onQuestionSubmit}
            >
              Submit
            </Button>
            <Button
              style={{ color: "white", background: "transparent" }}
              onClick={onQuestionSkip}
            >
              Cancel
            </Button>
          </React.Fragment>
        </div>
      </div>
    </Modal>
  );
};

export default QuestionPopup;
