import React from "react";
import "./sidenav.css";
import { userPages } from "../../../constants";
import { styled } from "@linaria/react";
import icons from "./iconImages";
import { Link, useHistory } from "react-router-dom";

export const SidenavItem = (props) => {
  return (
    <div
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
      className={props.active ? "sidenav-item active" : "sidenav-item"}
    >
      {props.children}
    </div>
  );
};

const StyledIcon = styled.img`
  width: 25px;
  margin-right: 10px;
  height: fit-content;
`;

const Sidenav = ({ drawer, selectedItem }) => {
  const history = useHistory();
  const className = drawer ? "sidenav-drawer" : "sidenav";
  return (
    <div className={className}>
      <Link to={`/user/${userPages.FORMS}`}>
        <SidenavItem active={selectedItem === userPages.FORMS}>
          <StyledIcon src={icons.publishedSurveyIcon} />
          Surveys
        </SidenavItem>
      </Link>
      <Link to={`/user/${userPages.PROFILE}`}>
        <SidenavItem active={selectedItem === userPages.PROFILE}>
          <StyledIcon src={icons.profileIcon} />
          Profile
        </SidenavItem>
      </Link>
      <Link to={`/user/${userPages.SETTINGS}`}>
        <SidenavItem active={selectedItem === userPages.SETTINGS}>
          <StyledIcon src={icons.settingIcon} />
          Settings
        </SidenavItem>
      </Link>
      <Link to={`/user/${userPages.HISTORY}`}>
        <SidenavItem active={selectedItem === userPages.HISTORY}>
          <StyledIcon src={icons.historyIcon} />
          History
        </SidenavItem>
      </Link>
      <SidenavItem
        onClick={() => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          history.push("/signin/user");
        }}
      >
        <StyledIcon src={icons.logOutIcon} />
        Logout
      </SidenavItem>
    </div>
  );
};

export default Sidenav;
