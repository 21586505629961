import React, { useState } from "react";
import { Modal, Input, Button } from "antd";
import { notify } from "../../utils";

const AccountModal = ({ open = false, onSave }) => {
  const [accountName, setAccountName] = useState("");

  return (
    <Modal
      visible={open}
      closable={false}
      maskClosable={false}
      keyboard={false}
      footer={null}
    >
      <div
        style={{
          fontSize: 18,
          fontWeight: "bold",
          marginBottom: 10,
        }}
      >
        Welcome to UserQual
      </div>
      <div
        style={{
          fontSize: 16,
          fontWeight: "bold",
          marginBottom: 10,
        }}
      >
        Create your account
      </div>
      <div>
        <p
          style={{
            marginBottom: 2,
          }}
        >
          Account Name
        </p>
        <Input
          value={accountName}
          onChange={(e) => setAccountName(e.target.value)}
          placeholder="Enter account name"
        />
        <p
          style={{
            fontSize: 12,
            color: "#999999",
          }}
        >
          You can change this later.
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: 24,
        }}
      >
        <Button
          onClick={() => {
            if (accountName) {
              onSave(accountName);
            } else {
              notify("error", "Error", "Account name cannot be empty");
            }
          }}
          style={{
            color: "white",
            backgroundColor: "#00c194",
            borderRadius: 5,
            border: "none",
            textTransform: "uppercase",
          }}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default AccountModal;
