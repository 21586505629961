import { confirmTimeSlot } from "../../../../../actions/users";
import { useDispatch } from "react-redux";
import {
  decrementPendingRequests,
  incrementPendingRequests,
  notify,
} from "../../../../../utils";

export const useCancelCall = () => {
  const dispatch = useDispatch();

  const cancelCall = async (surveyId) => {
    incrementPendingRequests();
    dispatch(
      confirmTimeSlot(surveyId, {
        date: null,
        time: null,
      })
    )
      .then(() => {
        notify("success", "Success", "Time slot Cancelled");
      })
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  };

  return cancelCall;
};
