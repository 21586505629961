import React, { Fragment } from "react";
import { Select } from "antd";

import { COLOR } from "../../constants";
import { CaretDownOutlined } from "@ant-design/icons";

const { Option } = Select;

const labelStyle = {
  color: COLOR.grey,
  fontSize: 14,
  margin: 5,
  padding: "5px 5px 5px 8px",
  minWidth: 110,
  display: "inline-block",
};

const Dropdown = ({
  options,
  value,
  handleChange,
  customStyle,
  children,
  label,
  color,
  disabled,
  style,
  ...rest
}) => {
  return (
    <Fragment>
      {label ? <label style={labelStyle}>{label}</label> : null}
      <Select
        {...rest}
        style={{
          width: 130,
          margin: "0 8px 10px 0",
          color: color ? color : COLOR.darkBlue,
          ...customStyle,
          ...style,
        }}
        suffixIcon={
          <CaretDownOutlined
            style={{
              color: color ? color : COLOR.darkBlue,
              fontSize: "16px",
              marginTop: -2,
            }}
          />
        }
        disabled={disabled}
        value={value}
        onChange={(value) => {
          handleChange(value);
        }}
      >
        {options.map((opt, index) => (
          <Option
            key={index}
            value={opt.value}
            style={{ color: color ? color : COLOR.darkBlue }}
          >
            {opt.label}
          </Option>
        ))}
      </Select>
    </Fragment>
  );
};

export default Dropdown;
