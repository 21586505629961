import { Input } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";

const Text = ({ key, value, onChange = () => {} }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  return (
    <Input.TextArea
      key={key}
      defaultValue={value}
      rows={5}
      placeholder="Please write your answer here"
      style={{
        maxWidth: 800,
        resize: "none",
        backgroundColor: "#0A3157",
        color: "white",
        borderColor: "#0A3157",
      }}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default Text;
