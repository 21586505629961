const questionAnswers = [
  {
    question: "How do I get paid?",
    answer:
      "Do what others do – daily – sign up and give feedback. " +
      "You can do it whenever, wherever and earn money on " +
      "the side. Companies will love you AND pay you for your " +
      "honest feedback for each survey, directly into your " +
      "account of choice.",
  },
  {
    question: "Why companies need your feedback?",
    answer:
      "Companies such as Unilever, Flipkart, Uber, Tata, etc. need " +
      "feedback from consumers while they are designing their " +
      "products, be it pricing, logo, ads, or just to get a general " +
      "sense of what consumers like. They are also interested in " +
      "doing user testing on their websites and apps to launch " +
      "stable and user friendly products.",
  },
  {
    question: "What is video testing?",
    answer:
      "Video testing as a concept is to gather live feedback from " +
      "users and seeing how they respond to questions. It is simple, " +
      "convenient and can be done using your phone or computer. " +
      "The companies gather valuable information on how you use " +
      "their products or feel about them – and you get paid to share " +
      "your honest feedback!",
  },
  {
    question: "How can I test?",
    answer:
      "Create an account using your mobile number or email and" +
      "answers a few demographic questions. Based on your" +
      "profile, we will ask you to take paid surveys. These would" +
      "either be online surveys or video surveys to gather your" +
      "honest opinions. Soon after, we will pay you directly into your" +
      "account of choice!",
  },
];

export default questionAnswers;
