import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import { styled } from "@linaria/react";
import logo from "../../../assets/logo.png";
import { useEffect } from "react";
import icons from "./iconImages";
import { BellFilled } from "@ant-design/icons";

const SidenavItem = styled.div`
  background-color: ${(props) => (props.selected ? "#8EDAD0" : "#00233F")};
  padding: 10px;
  cursor: pointer;
  margin: 15px 0;
  color: white;
  display: flex;
  font-weight: bold;
  border-radius: 5px;

  &:hover {
    background-color: #1b3a62;
    color: white;
  }
`;

const StyledIcon = styled.img`
  width: 25px;
  margin-right: 10px;
  height: fit-content;
`;

const CopyRight = styled.div`
  text-align: center;
  position: absolute;
  bottom: 15px;
  left: 10%;
  color: white;
  font-size: 10px;
`;

const Sidenav = ({ drawer, isAdmin = false }) => {
  const history = useHistory();
  const location = useLocation();
  const [selected, setSelected] = useState("/client");
  const className = drawer ? "sidenav-drawer" : "sidenav";

  useEffect(() => {
    const path = location.pathname.split("/");
    if (path.length === 2 && selected !== "/client") {
      setSelected("/client");
    } else if (
      path.length > 2 &&
      path[2] !== "new-survey" &&
      path[2] !== "project"
    ) {
      const pathString = `/${path[1]}/${path[2]}`;
      if (selected !== pathString) setSelected(pathString);
    } else if (
      path.length > 2 &&
      (path[2] === "new-survey" || path[2] === "project") &&
      selected !== "/client"
    ) {
      setSelected("/client");
    }
  }, [location, selected]);

  const handleClick = (link) => {
    setSelected(link);
    history.push(link);
  };

  return (
    <div className={className}>
      <div style={{ marginBottom: 40 }}>
        <img
          style={{ maxWidth: "100%", display: "block", margin: "auto" }}
          src={logo}
        />
      </div>
      <SidenavItem
        onClick={() => handleClick("/client")}
        selected={selected === "/client"}
      >
        <StyledIcon src={icons.createSurveyIcon} />
        Create Surveys
      </SidenavItem>
      <SidenavItem
        onClick={() => handleClick("/client/published")}
        selected={selected === "/client/published"}
      >
        <StyledIcon src={icons.publishedSurveyIcon} />
        Published Surveys
      </SidenavItem>
      {isAdmin && (
        <SidenavItem
          onClick={() => handleClick("/client/requests")}
          selected={selected === "/client/requests"}
        >
          <BellFilled
            style={{
              color: "white",
              fontSize: 24,
              marginRight: 11,
            }}
          />
          Publish Requests
        </SidenavItem>
      )}
      <SidenavItem
        onClick={() => handleClick("/client/results")}
        selected={selected === "/client/results"}
      >
        <StyledIcon src={icons.surveyResultIcon} />
        Survey Results
      </SidenavItem>
      <SidenavItem
        onClick={() => handleClick("/client/profile")}
        selected={selected === "/client/profile"}
      >
        <StyledIcon src={icons.profileIcon} />
        Profile
      </SidenavItem>
      <SidenavItem
        onClick={() => handleClick("/client/settings")}
        selected={selected === "/client/settings"}
      >
        <StyledIcon src={icons.settingIcon} />
        Settings
      </SidenavItem>
      <SidenavItem
        onClick={() => handleClick("/client/history")}
        selected={selected === "/client/history"}
      >
        <StyledIcon src={icons.historyIcon} />
        History
      </SidenavItem>
      <SidenavItem
        onClick={() => {
          localStorage.removeItem("token");
          localStorage.removeItem("client");
          localStorage.removeItem("survey");
          handleClick("/signin/client");
        }}
      >
        <StyledIcon src={icons.logOutIcon} />
        Logout
      </SidenavItem>
      <CopyRight>&copy; 2021 UserQual</CopyRight>
    </div>
  );
};

export default Sidenav;
