import React from "react";
import { Modal } from "antd";
import Button from "../../../../../../components/button/Button";
import { darkBlueButtonStyle } from "../../../constants/styles";
import { useMediaQuery } from "react-responsive";

const TranscriptModal = ({
  onClose,
  handleChange,
  transcript,
  setTranscript,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  return (
    <Modal
      footer={null}
      visible={true}
      onCancel={onClose}
      title="Transcript Editor"
      onOk={handleChange}
      centered={true}
    >
      <h3>Transcript</h3>
      <textarea
        rows="20"
        cols={isMobile ? "35" : "60"}
        value={transcript}
        onChange={(e) => setTranscript(e.target.value)}
      />
      <Button
        style={{
          ...darkBlueButtonStyle,
          minWidth: "215px",
          marginBottom: "8px",
        }}
        onClick={handleChange}
      >
        Download Transcript
      </Button>
      <br />
    </Modal>
  );
};

export default TranscriptModal;
