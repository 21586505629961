import React, { useEffect, useState } from "react";
import styles from "./survey.module.css";
import c from "classnames/bind";
import { LogoutOutlined, RightOutlined, LinkOutlined } from "@ant-design/icons";
import { InputNumber, Modal as AntdModal, Card, Button } from "antd";
import { useMediaQuery } from "react-responsive";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import RobotLarge from "../../../assets/robot-large.png";
import logo from "../../../assets/logo.png";
import Text from "../../../components/user/survey-questions/text/Text";
import MultipleChoice from "../../../components/user/survey-questions/multiple-choice/MultipleChoice";
import Checkboxes from "../../../components/user/survey-questions/checkboxes/Checkboxes";
import StarRating from "../../../components/user/survey-questions/star-rating/StarRating";
import SliderType from "../../../components/user/survey-questions/slider/Slider";
import Likert from "../../../components/user/survey-questions/likert/Likert";
import NPS from "../../../components/user/survey-questions/nps/NPS";
import Ranking from "../../../components/user/survey-questions/ranking/Ranking";
import Dropdown from "../../../components/user/survey-questions/dropdown/Dropdown";
import Association from "../../../components/user/survey-questions/association/Association";
import ImageInstructions from "../../../components/user/survey-questions/image/instructions/ImageInstructions";
import ImageComment from "../../../components/user/survey-questions/image/comment/ImageComment";
import ImageMultipleChoice from "../../../components/user/survey-questions/image/multiple-choice/ImageMultipleChoice";
import ImageCheckboxes from "../../../components/user/survey-questions/image/checkboxes/ImageCheckboxes";
import ImageRating from "../../../components/user/survey-questions/image/rating/ImageRating";
import ImageRanking from "../../../components/user/survey-questions/image/ranking/ImageRanking";
import ImageAssociation from "../../../components/user/survey-questions/image/association/ImageAssociation";
import Modal from "react-modal";
import VideoInstruction from "../../../components/user/survey-questions/video/instruction/VideoInstruction";
import VideoRating from "../../../components/user/survey-questions/video/rating/VideoRating";
import VideoAssociation from "../../../components/user/survey-questions/video/association/VideoAssociation";
import VideoRanking from "../../../components/user/survey-questions/video/ranking/VideoRanking";
import VideoMultipleChoice from "../../../components/user/survey-questions/video/multiple-choice/VideoMultipleChoice";
import VideoCheckboxes from "../../../components/user/survey-questions/video/checkboxes/VideoCheckboxes";
import VideoComment from "../../../components/user/survey-questions/video/comment/VideoComment";
import CheckboxesSpecial from "../../../components/user/profile-questions/checkboxes-special/CheckboxesSpecial";
import AdAnalysis from "../../../components/user/survey-questions/ad-analysis/AdAnalysis";
import { useRef } from "react";

const modalStyles = {
  content: {
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    border: 0,
    padding: 0,
    borderRadius: 0,
  },
};

const cx = c.bind(styles);

let answer = [];

const SurveyQuestion = ({
  question = [],
  isOpen,
  onClose,
  tryOwnForm = false,
  brandLogo = "",
  qNo,
}) => {
  // Hooks
  const TopBarResponsive = useMediaQuery({ query: "(max-width: 960px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 800px)" });
  const videoRef = useRef(null);
  console.log("Running");
  useEffect(() => {
    // Populate answers with empty array
    answer = [];
    console.log("population done");
  }, []);

  useEffect(() => {
    if (isOpen && !tryOwnForm) {
      getVideo();
    }
  }, [videoRef, isOpen, tryOwnForm]);

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { width: 300 } })
      .then((stream) => {
        let videoObject = videoRef.current;
        videoObject.srcObject = stream;
        videoObject.play();
      })
      .catch((err) => {
        console.error("error:", err);
      });
  };

  // Derived state
  const getQuestionText = () => {
    const { type, text, options } = question;

    switch (type) {
      case "netPromotorScore":
        const { npsOption } = options;
        return `How likely is it that you would recommend ${npsOption} to a friend or collegue?`;
      default:
        return text;
    }
  };

  const getComponentByQuestionType = () => {
    const { type, options } = question;

    const onChange = (values) => {
      answer = Array.isArray(values) ? values : [values];
    };

    if (answer) {
      switch (type) {
        case "text":
          return <Text onChange={onChange} value={answer[0]} />;
        case "multiChoices":
          return (
            <MultipleChoice
              choices={options.multiChoices}
              onChange={onChange}
              value={answer[0]}
            />
          );
        case "checkBoxes":
          return (
            <Checkboxes
              choices={options.multiChoices}
              onChange={onChange}
              value={answer}
            />
          );
        case "stars":
          const { stars, starType } = options;
          return (
            <StarRating
              options={{ stars, starType }}
              onChange={onChange}
              value={answer[0]}
            />
          );
        case "slider":
          answer[0] = answer[0] || 0;
          const {
            sliderType,
            sliderValue,
            sliderMin,
            sliderMax,
            sliderStartLabel,
            sliderMiddleLabel,
            sliderEndLabel,
          } = options;
          return (
            <SliderType
              options={{
                sliderType,
                sliderValue,
                sliderMin,
                sliderMax,
                sliderStartLabel,
                sliderMiddleLabel,
                sliderEndLabel,
              }}
              onChange={onChange}
              value={answer[0]}
            />
          );
        case "likert":
          const {
            likertRange,
            likertStartLabel,
            likertMiddleLabel,
            likertEndLabel,
          } = options;
          return (
            <Likert
              options={{
                likertRange,
                likertStartLabel,
                likertMiddleLabel,
                likertEndLabel,
              }}
              onChange={onChange}
              value={answer[0]}
            />
          );
        case "netPromotorScore":
          return (
            <NPS
              options={{ npsOption: options.npsOption }}
              onChange={onChange}
              value={answer[0]}
            />
          );
        case "ranking":
          answer = answer.length > 0 ? answer : options.multiChoices;
          return <Ranking onChange={onChange} value={answer} />;
        case "dropDown":
          answer[0] = answer[0] || 1;
          const range = options.range;
          return (
            <Dropdown
              options={{ range }}
              onChange={onChange}
              value={answer[0]}
            />
          );
        case "association":
          answer = answer.length > 0 ? answer : options.associationChoices;
          return <Association onChange={onChange} value={answer} />;
        case "imageInstruction":
          return <ImageInstructions options={options} />;
        case "imageComment":
          return (
            <ImageComment
              options={{
                imageData: options.imageData,
                imageLabel: options.imageLabel,
              }}
              value={answer[0]}
              onChange={onChange}
            />
          );
        case "imageMultiChoices":
          const { imageMultiChoices } = options;
          return (
            <ImageMultipleChoice
              options={{ imageMultiChoices }}
              value={answer[0]}
              onChange={onChange}
            />
          );
        case "imageCheckBoxes":
          return (
            <ImageCheckboxes
              options={{ imageMultiChoices: options.imageMultiChoices }}
              value={answer}
              onChange={onChange}
            />
          );
        case "imageStarRatingChoices":
          if (answer.length === 0) {
            answer[0] = {};
            options.imageStarRatingChoices.forEach((val) => {
              answer[0][val.imageLabel] = 0;
            });
          }
          const { imageStarRatingChoices, imageStar, imageStarType } = options;
          return (
            <ImageRating
              options={{ imageStarRatingChoices, imageStar, imageStarType }}
              value={answer[0]}
              onChange={onChange}
            />
          );
        case "imageRanking":
          answer = answer.length > 0 ? answer : options.imageMultiChoices;
          return <ImageRanking onChange={onChange} value={answer} />;
        case "imageAssociation":
          answer = answer.length > 0 ? answer : options.imageAssociationChoices;
          return (
            <ImageAssociation
              options={options}
              onChange={onChange}
              value={answer}
            />
          );
        case "videoInstruction":
          return <VideoInstruction options={options} />;
        case "videoComment":
          return (
            <VideoComment
              options={{
                videoData: options.videoData,
                videoLabel: options.videoLabel,
              }}
              value={answer[0]}
              onChange={onChange}
            />
          );
        case "videoMultiChoices":
          const { videoMultiChoices } = options;
          return (
            <VideoMultipleChoice
              options={{ videoMultiChoices }}
              value={answer[0]}
              onChange={onChange}
            />
          );
        case "videoCheckBoxes":
          return (
            <VideoCheckboxes
              options={{ videoMultiChoices: options.videoMultiChoices }}
              value={answer}
              onChange={onChange}
            />
          );
        case "videoStarRatingChoices":
          if (answer.length === 0) {
            answer[0] = {};
            options.videoStarRatingChoices.forEach((val) => {
              answer[0][val.videoLabel] = 0;
            });
          }
          const { videoStarRatingChoices, videoStar, videoStarType } = options;
          return (
            <VideoRating
              options={{ videoStarRatingChoices, videoStar, videoStarType }}
              value={answer[0]}
              onChange={onChange}
            />
          );
        case "videoRanking":
          answer = answer.length > 0 ? answer : options.videoMultiChoices;
          return <VideoRanking onChange={onChange} value={answer} />;
        case "videoAssociation":
          answer = answer.length > 0 ? answer : options.videoAssociationChoices;
          return (
            <VideoAssociation
              options={options}
              onChange={onChange}
              value={answer}
            />
          );
        case "age":
          return (
            <InputNumber
              defaultValue={answer}
              onChange={onChange}
              style={{
                backgroundColor: "#004479",
                color: "white",
                border: "1px solid white",
              }}
            />
          );
        case "checkboxesSpecial":
          const { specialField, multiChoices } = options;
          return (
            <CheckboxesSpecial
              choices={multiChoices}
              onChange={onChange}
              value={answer}
              specialField={specialField}
              color="white"
            />
          );
        case "adAnalysis":
          const { adAnalysisData } = options;
          return <AdAnalysis options={adAnalysisData} />;
        default:
          return;
      }
    }
  };

  const onCloseModal = () => {
    answer = [];
    onClose();
  };

  const shouldRenderOptions = () => {
    if (question && question.type === "questionInstruction") {
      return false;
    }

    return true;
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onCloseModal} style={modalStyles}>
      <RemoveScrollBar />
      <div className={cx("survey-container")}>
        {TopBarResponsive ? (
          <div className={cx("mobile_topbar")}>
            <div className={cx("mobile_logo")}>
              <img
                src={brandLogo ? brandLogo : logo}
                style={
                  brandLogo
                    ? { maxHeight: 200, maxWidth: 200, objectFit: "contain" }
                    : { height: 48 }
                }
              />
            </div>
            {isTablet ? (
              <LogoutOutlined
                style={{ fontSize: 24, cursor: "pointer" }}
                onClick={onCloseModal}
              />
            ) : (
              <div
                onClick={onCloseModal}
                className={cx("mobile_quit-button")}
                title="Quit Survey"
              >
                QUIT SURVEY <LogoutOutlined />
              </div>
            )}
          </div>
        ) : (
          <>
            <div className={cx("logo")}>
              <img
                src={brandLogo ? brandLogo : logo}
                style={
                  brandLogo
                    ? { maxHeight: 200, maxWidth: 200, objectFit: "contain" }
                    : { height: 48 }
                }
              />
            </div>
            <div
              onClick={onCloseModal}
              className={cx("quit-button")}
              title="Quit Survey"
            >
              QUIT SURVEY <LogoutOutlined />
            </div>
          </>
        )}
        <div className={cx("center")}>
          {/* QUESTION */}
          <div className={cx("question-box-1")}>
            <div style={{ display: "flex", marginBottom: "16px" }}>
              <div
                style={{ fontSize: 24, marginRight: 18, fontWeight: "bold" }}
              >
                {qNo}
              </div>

              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  fontSize: 16,
                }}
              >
                {question && getQuestionText()}
              </div>
            </div>

            {question.hasLink ? (
              <Card
                style={{ marginLeft: "30px" }}
                bordered={true}
                className={cx("linkCard")}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "16px",
                      padding: "10px",
                      margin: "auto 0",
                    }}
                  >
                    <LinkOutlined
                      style={{ margin: "auto", paddingLeft: "15px" }}
                    />

                    {question.clientLink}
                  </div>
                  <Button
                    onClick={() =>
                      window.open("https://" + question.clientLink, "_blank")
                    }
                    className={cx("linkButton")}
                  >
                    OPEN
                  </Button>
                </div>
              </Card>
            ) : null}
          </div>
          {/* OPTIONS */}
          {shouldRenderOptions() && question && (
            <div className={cx("options-box")}>
              {question && getComponentByQuestionType()}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SurveyQuestion;
