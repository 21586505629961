import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MultiChoiceOptions from "./MultiChoiceOptions/MultiChoiceOptions";

const MultiChoices = ({ options, setOptions, checkBox }) => {
  // const [choiceOptions, setChoiceOptions] = useState(options.multiChoices);

  // const [multiChoices, { add, remove, move, set, refresh }] = useArray(options.multiChoices);

  const multiChoices = options.multiChoices;

  const add = (item, index) => {
    setOptions({
      ...options,
      multiChoices: [
        ...multiChoices.slice(0, index),
        item,
        ...multiChoices.slice(index, multiChoices.length),
      ],
    });
  };

  const move = (source, dest) => {
    const result = Array.from(multiChoices);
    const [removed] = result.splice(source, 1);
    result.splice(dest, 0, removed);
    setOptions({ ...options, multiChoices: result });
  };

  const remove = (index) => {
    setOptions({
      ...options,
      multiChoices: [
        ...multiChoices.slice(0, index),
        ...multiChoices.slice(index + 1, multiChoices.length),
      ],
    });
  };

  const set = (item, index) => {
    const result = Array.from(multiChoices);
    result[index] = item;
    setOptions({ ...options, multiChoices: result });
  };

  const setOptionValue = (value, key, index) => {
    const result = { ...multiChoices[index], [key]: value };
    set(result, index);
  };

  // useEffect(() => {
  //   setOptions({ ...options, multiChoices: multiChoices });
  //   // eslint-disable-next-line
  // }, [multiChoices]);

  // useEffect(() => {
  //   // if(options.multiChoices !== multiChoices){
  //   //   options.multiChoices.map((c,ind)=>{
  //   //     set(c, ind);
  //   //   })
  //   // }/ useEffect(() => {
  //   // if(options.multiChoices !== multiChoices){
  //   //   options.multiChoices.map((c,ind)=>{
  //   //     set(c, ind);
  //   //   })
  //   // }
  //   refresh(options.multiChoices)
  // }, [opt
  //   refresh(options.multiChoices)
  // }, [options]);

  return (
    <DragDropContext
      onDragEnd={(result) =>
        move(result.source.index, result.destination.index)
      }
    >
      <Droppable droppableId="list">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              paddingTop: 10,
            }}
          >
            {multiChoices.map((choice, index) => (
              <Draggable
                draggableId={`option-${index}`}
                index={index}
                key={index}
              >
                {(provided) => (
                  <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    gutter={6}
                  >
                    <MultiChoiceOptions
                      dragHandleProps={provided.dragHandleProps}
                      choiceNumber={index + 1}
                      choice={choice}
                      checkBox={checkBox}
                      setLabel={(value) =>
                        setOptionValue(value, "label", index)
                      }
                      setMcq={(value) => setOptionValue(value, "isMcq", index)}
                      isFilled={choice.label !== ""}
                      totalChoices={multiChoices.length}
                      addChoice={(newIndex) => add({ label: "" }, newIndex)}
                      removeChoice={(index) => remove(index - 1)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default MultiChoices;
