import React from "react";
import { Modal, Button } from "antd";
import { CloseSquareFilled } from "@ant-design/icons";

import styles from "./ConfirmationModal.module.css";
import c from "classnames/bind";

const cx = c.bind(styles);

const ConfirmationModal = ({
  handleOk,
  handleCancel,
  isVisible,
  handleCreateNewSurvey,
}) => {
  return (
    <Modal
      className={cx("copy-survey-popup")}
      centered={true}
      okText="Yes"
      cancelText="No"
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      closeIcon={
        <CloseSquareFilled
          style={{
            color: "#dddddd",
            fontSize: 24,
          }}
        />
      }
      closable={true}
      footer={[
        <Button
          className={cx("button")}
          style={{
            backgroundColor: "rgb(189, 189, 189)",
            marginRight: "20px !important",
          }}
          onClick={() => handleCreateNewSurvey()}
        >
          Nope
        </Button>,
        <Button
          className={cx("button")}
          style={{ background: "rgb(24, 59, 105)" }}
          onClick={() => handleOk()}
        >
          Yes, Copy
        </Button>,
      ]}
    >
      <h3>Would you like to copy a previously published survey?</h3>
    </Modal>
  );
};

export default ConfirmationModal;
