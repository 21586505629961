import React, { useEffect } from "react";
import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios, { Routes } from "../../services/API";
import {
  getRoleByToken,
  getSurveyIdByToken,
  incrementPendingRequests,
  decrementPendingRequests,
  notify,
} from "../../utils";

// {
//   data: {
//     referSurveyToken: Survey.referSurveyToken
//   }
// }

// frontend endpoint:
// <Route path='user/addSurvey/:referSurveyToken' component={AddSurvey} />

//Endpoint POST to user/:surveyId/addSurvey

const AddSurvey = () => {
  const { referSurveyToken } = useParams();
  const surveyId = getSurveyIdByToken(referSurveyToken);
  const history = useHistory();
  const userId =
    getRoleByToken(localStorage.getItem("token")) === "user"
      ? localStorage.getItem("user")
      : "";

  const addSurveyAPI = useCallback(async () => {
    try {
      incrementPendingRequests();
      const res = await axios({
        ...Routes.user.addSurvey(userId, surveyId),
        data: { data: { referSurveyToken } },
      });

      if (res.status === 200) {
        return notify("success", "Success", res.data.message);
      }
    } catch (err) {
      return notify("error", "Error", err.response.data.message);
    } finally {
      decrementPendingRequests();
      history.push("/user/forms");
    }
  }, [userId, surveyId]);

  useEffect(() => {
    addSurveyAPI();
  }, []);

  return <></>;
};

export default AddSurvey;
