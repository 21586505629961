import { Form, Input, Button, Modal, Switch } from "antd";
import React from "react";
import FormItemLabel from "../../../form-item-label/FormItemLabel";

const ConfigFeaturesModal = ({ handleCancel }) => {
  const [form] = Form.useForm();

  const onAddFeature = () => {
    form.validateFields().then((values) => {
      console.log(values);
    });
  };

  const footer = (
    <div>
      <Button
        onClick={handleCancel}
        style={{ border: "1px solid #004479", marginRight: 10, width: 100 }}
      >
        Cancel
      </Button>
      <Button type="primary" onClick={onAddFeature} style={{ width: 100 }}>
        Save
      </Button>
    </div>
  );

  return (
    <Modal
      title=""
      closable={false}
      visible={true}
      onCancel={handleCancel}
      footer={footer}
    >
      <Form form={form} style={{ color: "#004479" }}>
        <FormItemLabel name="Feature name" />
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Please enter the name!" }]}
        >
          <Input className="input-border" />
        </Form.Item>
        <FormItemLabel name="Basic Plan Feature" />
        <Form.Item name="basic" valuePropName="checked">
          <Switch />
        </Form.Item>
        <FormItemLabel name="Elite Plan Feature" />
        <Form.Item name="elite" valuePropName="checked">
          <Switch />
        </Form.Item>
        <FormItemLabel name="Platinum Plan Feature" />
        <Form.Item name="platinum" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ConfigFeaturesModal;
