import React, { useState, useEffect } from "react";
import { notification, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Calendar, utils } from "react-modern-calendar-datepicker";

import styles from "./CalendarComponent.module.css";
import c from "classnames/bind";

const cx = c.bind(styles);
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const times = [
  { label: "8am", value: "08:00" },
  { label: "9am", value: "09:00" },
  { label: "10am", value: "10:00" },
  { label: "11am", value: "11:00" },
  { label: "12pm", value: "12:00" },
  { label: "1pm", value: "13:00" },
  { label: "2pm", value: "14:00" },
  { label: "3pm", value: "15:00" },
  { label: "4pm", value: "16:00" },
  { label: "5pm", value: "17:00" },
  { label: "6pm", value: "18:00" },
  { label: "7pm", value: "19:00" },
];

const DateDiv = ({ date, onClick, selected }) => {
  const [day, month, year] = date.split("/");
  return (
    <div
      className={cx("date-div")}
      onClick={() => onClick()}
      style={
        new Date(date) < new Date()
          ? {
              backgroundColor: "#88CDD5",
              borderColor: "#88CDD5",
              color: "white",
            }
          : selected.isSelectedDate
          ? {
              backgroundColor: "#FF9480",
              borderColor: "#FF9480",
              color: "white",
            }
          : selected.isTimeSlotSelected
          ? {
              backgroundColor: "#FFA999",
              borderColor: "#FFA999",
              color: "white",
            }
          : {
              backgroundColor: "white",
              borderColor: "white",
              color: "black",
            }
      }
    >
      {`${day} ${months[parseInt(month) - 1]} ${year}`}
    </div>
  );
};

const TimeDiv = ({ time, onClick, selected, isBookedTime, selectedDate }) => {
  return (
    <div
      className={cx("date-div")}
      onClick={() => {
        if (isBookedTime || new Date(selectedDate) <= new Date()) {
          notification.error({
            message:
              "Slot is already booked or time is already passed, cannot be changed",
          });
          return;
        }
        onClick();
      }}
      style={
        isBookedTime
          ? {
              backgroundColor: "#88CDD5",
              borderColor: "#88CDD5",
              color: "white",
            }
          : selected
          ? {
              backgroundColor: "#FF9480",
              borderColor: "#FF9480",
              color: "white",
            }
          : {
              backgroundColor: "white",
              borderColor: "white",
              color: "black",
            }
      }
    >
      {time}
    </div>
  );
};

const CalendarComponent = ({
  moderatedSlots,
  setModeratedSlots,
  bookedSlots,
  hideTitle = false,
  overrideStyle = {},
}) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const minimumDate = {
    ...utils().getToday(),
    day: utils().getToday().day + 1,
  };

  const dateToObject = (date) => {
    const res = {};
    date.forEach((item) => {
      const dateString = `${item.day}/${item.month}/${item.year}`;
      if (moderatedSlots && moderatedSlots[dateString])
        res[dateString] = moderatedSlots[dateString];
      else res[dateString] = {};
    });
    return res;
  };

  const objectToDate = (obj) => {
    const res = [];
    if (obj) {
      Object.keys(obj).forEach((item) => {
        const [day, month, year] = item.split("/");
        const date = {
          day: parseInt(day),
          month: parseInt(month),
          year: parseInt(year),
        };
        res.push(date);
      });
    }
    return res;
  };

  const isTimeSelected = (time) => {
    if (!selectedDate) return false;

    let res = false;
    if (moderatedSlots[selectedDate]) {
      Object.keys(moderatedSlots[selectedDate]).forEach((e) => {
        if (e === time) res = true;
      });
    }
    return res;
  };

  console.log("slots", moderatedSlots);
  console.log("bookedSlots", bookedSlots);

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "flex-start",
        ...overrideStyle,
      }}
      className={cx("lb-grid-container")}
    >
      <div style={{ justifySelf: "left" }}>
        {!hideTitle && (
          <h3>
            Select/De-select dates{" "}
            <Tooltip title="You can select multiple dates">
              <QuestionCircleOutlined />
            </Tooltip>
          </h3>
        )}
        <Calendar
          minimumDate={minimumDate}
          colorPrimary="#004479"
          shouldHighlightWeekends
          value={objectToDate(moderatedSlots)}
          onChange={(value) => {
            value.sort((a, b) => (utils().isBeforeDate(a, b) ? -1 : 1));
            const slots = dateToObject(value);
            setModeratedSlots({ ...slots, ...bookedSlots });
          }}
          calendarClassName={cx("calendar")}
        />
      </div>
      {moderatedSlots && Object.keys(moderatedSlots).length > 0 ? (
        <div>
          <div className={cx("date-div-container")}>
            {Object.keys(moderatedSlots).map((date, idx) => (
              <DateDiv
                key={idx}
                date={date}
                onClick={() => {
                  if (selectedDate === date) setSelectedDate(null);
                  else setSelectedDate(date);
                }}
                selected={{
                  isSelectedDate: selectedDate === date,
                  isTimeSlotSelected:
                    Object.keys(moderatedSlots[date]).length > 0,
                }}
              />
            ))}
          </div>
        </div>
      ) : null}
      {selectedDate && Object.keys(moderatedSlots).length > 0 ? (
        <div>
          <div className={cx("time-div-container")}>
            {times.map((e, i) => (
              <TimeDiv
                key={i}
                time={e.label}
                isBookedTime={
                  bookedSlots[selectedDate] &&
                  bookedSlots[selectedDate][e.value] !== undefined
                }
                selectedDate={selectedDate}
                selected={isTimeSelected(e.value)}
                onClick={() => {
                  if (selectedDate && moderatedSlots[selectedDate]) {
                    const slots = { ...moderatedSlots };
                    let timeExists = false;
                    const date = slots[selectedDate];
                    Object.keys(date).forEach((time) => {
                      if (time === e.value) timeExists = true;
                    });
                    if (!timeExists) slots[selectedDate][e.value] = [];
                    else delete slots[selectedDate][e.value];
                    setModeratedSlots(slots);
                  }
                }}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CalendarComponent;
