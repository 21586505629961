import React, { useEffect, useState } from "react";
import "./form.css";
// antd
import { Modal } from "antd";
import bgUser from "../../../assets/user-login-bg.png";

import Sidenav from "../../../components/user/sidenav/Sidenav";
import Topbar from "../../../components/topbar/Topbar";
import { userPages } from "../../../constants";
import { surveyStates } from "../../../features/survey/constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  decrementPendingRequests,
  getUserId,
  incrementPendingRequests,
  notify,
} from "../../../utils";
import { getSurveys } from "../../../actions/users";
import {
  getAvailableSlots,
  getScheduledSlotForUserTimeStamp,
  isSlotExpiredForUser,
} from "../../../features/survey/utils/moderated/schedule";
import { isModeratedSurvey } from "../../../features/survey/utils/utils";

import { useCancelCall } from "../../../features/survey/hooks/qualSurvey/moderatedSurvey/useCancelCall";
import SurveyCard from "../../../features/survey/components/SurveyCard";
import InstructionModal from "../../../features/survey/components/InstructionModal/InstructionModal";
import SurveyWrapper from "../../../features/survey/components/survey/SurveyWrapper";

const Form = ({ profile }) => {
  const dispatch = useDispatch();

  const cancelCall = useCancelCall();
  const allSurveys = useSelector((state) => state.user.surveys);
  const [userId, setUserId] = useState("");
  const [refinedSurveyList, setRefinedSurveyList] = useState(null);
  const [instructionModalVisible, setInstructionModalVisible] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState({});

  //modal to open survey box when user clicks on survey
  const showInstructionModal = (obj) => {
    console.log(obj);
    setSelectedSurvey(obj);
    setInstructionModalVisible(true);
  };

  const hideInstructionModal = () => {
    setInstructionModalVisible(false);
  };

  const handleSurveyClick = () => {
    hideInstructionModal();
    setShowSurveyModal(true);
  };

  //get all surveys from server
  useEffect(() => {
    if (allSurveys) {
      let surveyList = allSurveys.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      // remove surveys which are ended
      surveyList = surveyList.filter((survey) => {
        return survey.status !== surveyStates.END;
      });

      //list of surveys scheduled for the user to take
      const unScheduledSurveys = [];
      const moderatedScheduledSurveys = [];

      surveyList.forEach((survey) => {
        //find if user is scheduled for the survey
        const scheduledTimeStamp = getScheduledSlotForUserTimeStamp(
          survey.moderatedSlots,
          userId
        );
        if (isModeratedSurvey(survey) && scheduledTimeStamp) {
          survey = {
            ...survey,
            surveyScheduledTimeStamp: scheduledTimeStamp,
          };
          moderatedScheduledSurveys.push(survey);
        } else {
          unScheduledSurveys.push(survey);
        }
      });

      moderatedScheduledSurveys.sort(
        (a, b) =>
          new Date(b.surveyScheduledTimeStamp) -
          new Date(a.surveyScheduledTimeStamp)
      );
      const filteredSurveys = [
        ...countAndStoreAvailableSlots(moderatedScheduledSurveys),
        ...countAndStoreAvailableSlots(unScheduledSurveys),
      ];
      setRefinedSurveyList(
        filteredSurveys.filter((survey) => !survey.isExpire)
      );
    }
  }, [allSurveys]);

  const countAndStoreAvailableSlots = (surveys) => {
    return surveys.map((survey) => {
      if (!isModeratedSurvey(survey)) return survey;
      const availableSlots = getAvailableSlots(survey);
      const isScheduledSurveyExpired = isSlotExpiredForUser(
        survey.surveyScheduledTimeStamp
      );

      // cancel call if scheduled survey is expired and survey slots are available
      if (isScheduledSurveyExpired && availableSlots !== 0) {
        cancelCall(survey._id);
      }
      return {
        ...survey,
        availableSlots,

        isExpire:
          availableSlots === 0 &&
          (isScheduledSurveyExpired || !survey.surveyScheduledTimeStamp),
      };
    });
  };

  const handleSurveyCardClick = (survey, key) => {
    showInstructionModal(survey);
  };

  useEffect(() => {
    incrementPendingRequests();
    dispatch(getSurveys())
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  }, [dispatch]);

  useEffect(() => {
    const uid = getUserId();
    setUserId(uid);
  });

  console.log("refined survey list", refinedSurveyList);

  return (
    <React.Fragment>
      {/* Instruction Modal */}
      <Modal
        className="customModal"
        visible={instructionModalVisible}
        onOk={hideInstructionModal}
        footer={false}
        onCancel={hideInstructionModal}
      >
        <InstructionModal
          survey={selectedSurvey}
          handleCancel={hideInstructionModal}
          handleStartSurveyClick={handleSurveyClick}
        />
      </Modal>

      {showSurveyModal && (
        <SurveyWrapper
          survey={selectedSurvey}
          userId={userId}
          onExit={() => {
            setShowSurveyModal(false);
          }}
        />
      )}

      <Topbar>
        <Sidenav drawer selectedItem={userPages.FORMS} />
      </Topbar>
      <div
        className="formsPage"
        style={{ display: "flex", flexDirection: "row", height: "100%" }}
      >
        <Sidenav selectedItem={userPages.FORMS} />
        <div style={{ width: "100%", height: "100%", overflowY: "scroll" }}>
          <div className="topbarForms">
            <div
              style={{
                padding: "0 0 0 40px",
                position: "relative",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ margin: "auto" }}>
                  <div
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    Live Surveys
                  </div>
                  <div
                    style={{
                      fontSize: "1rem",
                      color: "white",
                    }}
                  >
                    Register for new upcoming surveys. The survey amount will be
                    added to your wallet once the survey is completed.
                  </div>
                </div>
                <div>
                  <img
                    style={{
                      width: "40vh",
                      padding: "0px 30px",
                    }}
                    src={bgUser}
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div style={{ padding: "20px 55px" }}>
            <div
              style={{
                color: "#6E6F6E",
                fontSize: "1rem",
                fontWeight: "bold",
                opacity: "85%",
              }}
            >
              All surveys
            </div>
            {!!refinedSurveyList && refinedSurveyList.length > 0 ? (
              <div>
                {refinedSurveyList.map((survey, index) => {
                  return (
                    <SurveyCard
                      survey={survey}
                      key={index}
                      userId={userId}
                      handleClick={handleSurveyCardClick}
                    />
                  );
                })}
              </div>
            ) : (
              <p>No surveys left...</p>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Form;
