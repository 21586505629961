import React from "react";
import styles from "./Card.module.css";
import c from "classnames/bind";

const cx = c.bind(styles);

export default function Card({ image, heading, setIndex, index }) {
  return (
    <article className={cx("card") + " card"}>
      <img src={image} alt="error" />
      <h4 className={cx("heading")}>{heading}</h4>
    </article>
  );
}
