import React from "react";
// import { message, Upload, Input, Tooltip } from "antd";
import styles from "./QuestionInstruction.module.css";
import c from "classnames/bind";
// import {
//   CheckOutlined,
//   InfoCircleOutlined,
//   LoadingOutlined,
// } from "@ant-design/icons";

const cx = c.bind(styles);

const QuestionInstruction = ({ options, setOptions }) => {
  /* const [uploading, setUploading] = useState(false);
  const uploadProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status === "uploading") {
        setUploading(true);
      }
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
        setUploading(false);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setOptions({ ...options, questionInstruction: info.file.name });
        // options.questionInstruction = info.file.name;
        setUploading(false);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        // options.questionInstruction = info.file.name;
        setUploading(false);
      }
    },
  }; */
  return (
    <div className={cx("grid-container")}>
      {/* <div className={cx("fields")}>
        <Upload
          className="uploadButton"
          {...uploadProps}
          showUploadList={false}
        >
          <div className="button">
            Upload File{uploading ? <LoadingOutlined /> : null}
          </div>
        </Upload>
        <div>
          {options.questionInstruction ? (
            <Input
              size="large"
              placeholder="No Image Uploaded"
              style={{ borderRadius: 3 }}
              value={options.questionInstruction}
              disabled
              onChange={(event) =>
                setOptions({
                  ...options,
                  questionInstruction: event.target.value,
                })
              }
              suffix={
                options.questionInstruction ? (
                  <CheckOutlined style={{ color: "green" }} />
                ) : (
                    <Tooltip title="You need to fill this option!">
                      <InfoCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  )
              }
              className="input-border"
            />
          ) : null}
        </div>
      </div> */}
    </div>
  );
};

export default QuestionInstruction;
