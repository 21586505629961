import React from "react";
import ReactPlayer from "react-player/lazy";

const AdAnalysis = ({ options }) => {
  return (
    <ReactPlayer
      className="react-player"
      url={options.url}
      light={true}
      playing={true}
      controls={true}
    />
  );
};

export default AdAnalysis;
