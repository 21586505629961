export const getAgeRange = ({ start, end, interval }) => {
  let range = [];
  let element = [];
  for (let i = start; i <= end; i++) {
    element.push(i);
    if (i % interval === 0) {
      range.push({
        label: `${element[0]}-${element[element.length - 1]}`,
        value: element,
      });
      element = [];
    }
  }
  return range;
};

export const getAgeGroupAnswerLength = (
  questionId,
  adminQuestions,
  criteria
) => {
  let count = 0;
  getAgeRange({
    start: getQuestion(questionId, adminQuestions).questionObject.start,
    end: getQuestion(questionId, adminQuestions).questionObject.end,
    interval: getQuestion(questionId, adminQuestions).questionObject.interval,
  }).map((el, index) => {
    return el.value.every((r) =>
      criteria.find((o) => o.questionId === questionId)?.answer.includes(r)
    )
      ? (count += 1)
      : null;
  });
  return count;
};

export const getQuestion = (id, adminQuestions) => {
  return (
    adminQuestions.filter((e) => e["_id"] === id)[0] || { questionObject: "" }
  );
};

export const getOptionsLength = (questionId, adminQuestions) => {
  const question = getQuestion(questionId, adminQuestions);
  if (question.questionObject.questionType == "age") {
    return getAgeRange({
      start: question.questionObject.start,
      end: question.questionObject.end,
      interval: question.questionObject.interval,
    }).length;
  }

  return question.questionObject.options.length;
};

// make a list in the Oxford comma style (eg "a, b, c, and d")
// ["a"] -> "a"
// ["a", "b"] -> "a and b"
// ["a", "b", "c"] -> "a, b, and c"
export const oxford = (arr) => {
  let l = arr.length;
  if (!l) return "";
  if (l < 2) return arr[0];
  if (l < 3) return arr.join(` and `);
  arr = arr.slice();
  arr[l - 1] = `and ${arr[l - 1]}`;
  return arr.join(", ");
};

export const findNextAvailableGroupIndex = (groups) => {
  if (!groups?.length) return 1;
  const maxGroupIndex = Math.max(...groups.map((group) => group.index));
  return maxGroupIndex + 1;
};
