export default {
  tierInfo: () => {
    return {
      url: `/tierInfo`,
      method: `get`,
    };
  },
  adminQuestions: () => {
    return {
      url: `/adminQuestions`,
      method: `get`,
    };
  },
  getInfluencerCategories: () => {
    return {
      url: `/influencerCategories`,
      method: `get`,
    };
  },
  checkAvailable: (queryString) => {
    return {
      url: `/checkAvailable?${queryString}`,
      method: `get`,
    };
  },
};
