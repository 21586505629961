import React, { useState } from "react";
import styles from "./imageMultiChoicesOptions.module.css";
import c from "classnames/bind";
import {
  CheckOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Input, Tooltip, Upload, message, Button, Spin } from "antd";
import axios, { Routes } from "../../../../../services/API";
import { GetFilename } from "../../../../../constants";

const cx = c.bind(styles);

const ImageMultiChoiceOptions = (props) => {
  // Props
  const {
    dragHandleProps,
    choiceNumber,
    choice,
    isFilled,
    addChoice,
    removeChoice,
    totalChoices,
    setLabel,
    checkBox,
  } = props;

  const [uploading, setUploading] = useState(false);

  const uploadProps = {
    accept: ".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.jif,.JIF,.gif,.GIF",
    customRequest: async function ({ file, onError }) {
      const userId = localStorage.getItem("client");
      const { name, type } = file;
      setUploading(true);
      const { data } = await axios({
        ...Routes.client.uploadFile(userId),
        data: { data: { name, type } },
      });
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", data.signedRequest);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            choice.imageData = data.url;
            setLabel({
              imageLabel: choice.imageLabel,
              imageData: data.url,
            });
            message.success(`${file.name} file uploaded successfully`);
            setUploading(false);
          } else {
            onError();
            message.error(`${file.name} file upload failed.`);
            setUploading(false);
          }
        }
      };
      xhr.send(file);
    },
  };

  return (
    <div className={cx("grid-container")}>
      <div className={cx("choiceNumber")}>
        <div
          style={{ margin: "auto", height: 20, width: 20, textAlign: "center" }}
        >
          <span className="choice-numberCircle">{choiceNumber}</span>
          <MenuOutlined className={cx("menu-outlined")} {...dragHandleProps} />
        </div>
      </div>
      <div className={cx("fields")}>
        <div className={cx("inputFields")}>
          <div>
            <Input
              size="large"
              placeholder="Image Label"
              style={{ borderRadius: 3 }}
              value={choice.imageLabel}
              onChange={(event) =>
                setLabel({
                  imageLabel: event.target.value,
                  imageData: choice.imageData,
                })
              }
              suffix={
                isFilled.imageLabel ? (
                  <CheckOutlined style={{ color: "green" }} />
                ) : (
                  <Tooltip title="You need to fill this option!">
                    <InfoCircleOutlined style={{ color: "#ff9480" }} />
                  </Tooltip>
                )
              }
              className="input-border"
            />
          </div>
          <div>
            <Input
              size="large"
              placeholder="No Image Chosen"
              style={{ borderRadius: 3 }}
              value={GetFilename(choice.imageData, 10)}
              disabled
              onChange={(event) =>
                setLabel({
                  imageLabel: choice.imageLabel,
                  imageData: event.target.value,
                })
              }
              suffix={
                isFilled.imageData ? (
                  <CheckOutlined style={{ color: "green" }} />
                ) : (
                  <Tooltip title="You need to fill this option!">
                    <InfoCircleOutlined style={{ color: "#ff9480" }} />
                  </Tooltip>
                )
              }
              className="input-border"
            />
          </div>
        </div>
        <div
          className={
            checkBox ? cx("controlFieldsSpecial") : cx("controlFields")
          }
        >
          <Upload
            className={cx("uploadButton")}
            {...uploadProps}
            showUploadList={false}
          >
            <Button style={{ height: "100%" }}>
              <Spin style={{ background: "none" }} spinning={uploading}>
                Upload Image
              </Spin>
            </Button>
          </Upload>
          <div className={cx("gridInput")} style={{ margin: "auto 0px" }}>
            <PlusCircleOutlined
              onClick={() => addChoice(choiceNumber)}
              className="action-icon"
            />
            {totalChoices > 1 && (
              <MinusCircleOutlined
                className="action-icon"
                onClick={() => removeChoice(choiceNumber)}
              />
            )}
            {/* {checkBox && (
              <div style={{ margin: "auto 0px" }}>
                <Checkbox className={cx("checkbox")} />
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageMultiChoiceOptions;
