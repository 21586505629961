import React from "react";
import { StarOutlined, HeartOutlined, SmileOutlined } from "@ant-design/icons";
import styles from "./form-star.module.css";
import c from "classnames/bind";
import { Select } from "antd";

const cx = c.bind(styles);
const { Option } = Select;

const FormStar = ({ options, setOptions }) => {
  return (
    <div
      className={cx("grid-container")}
      style={{
        paddingTop: 10,
      }}
    >
      <div>
        <div></div>
        <div className={cx("stars-div")}>
          {[...Array(options.stars)].map((_, i) => (
            <React.Fragment key={i}>
              {options.starType === "star" && (
                <StarOutlined className={cx("star")} />
              )}
              {options.starType === "heart" && (
                <HeartOutlined className={cx("star")} />
              )}
              {options.starType === "smiley" && (
                <SmileOutlined className={cx("star")} />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className={cx("starButtons")}>
        <Select
          className={cx("select")}
          value={options.starType}
          onChange={(e) => setOptions({ ...options, starType: e })}
        >
          <Option value="star">
            <StarOutlined />
          </Option>
          <Option value="heart">
            <HeartOutlined />
          </Option>
          <Option value="smiley">
            <SmileOutlined />
          </Option>
        </Select>
        <Select
          className={cx("select")}
          value={options.stars}
          onChange={(e) => setOptions({ ...options, stars: parseInt(e) })}
        >
          {[...Array(8)].map((_, i) => (
            <Option key={i} value={`${i + 3}`}>
              {i + 3}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default FormStar;
