import React, { useEffect, useState } from "react";
import styles from "./survey.module.css";
import c from "classnames/bind";
import {
  CloseCircleOutlined,
  LeftCircleOutlined,
  LeftOutlined,
  LogoutOutlined,
  RightCircleOutlined,
  RightOutlined,
  CheckCircleFilled,
  LinkOutlined,
} from "@ant-design/icons";
import {
  Button,
  message,
  Badge,
  InputNumber,
  Card,
  Modal as AntdModal,
} from "antd";
import { useMediaQuery } from "react-responsive";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import RobotLarge from "../../../assets/robot-large.png";
import logo from "../../../assets/logo.png";
import Text from "../../../components/user/survey-questions/text/Text";
import MultipleChoice from "../../../components/user/survey-questions/multiple-choice/MultipleChoice";
import Checkboxes from "../../../components/user/survey-questions/checkboxes/Checkboxes";
import StarRating from "../../../components/user/survey-questions/star-rating/StarRating";
import SliderType from "../../../components/user/survey-questions/slider/Slider";
import Likert from "../../../components/user/survey-questions/likert/Likert";
import NPS from "../../../components/user/survey-questions/nps/NPS";
import Ranking from "../../../components/user/survey-questions/ranking/Ranking";
import Dropdown from "../../../components/user/survey-questions/dropdown/Dropdown";
import Association from "../../../components/user/survey-questions/association/Association";
import ImageInstructions from "../../../components/user/survey-questions/image/instructions/ImageInstructions";
import ImageComment from "../../../components/user/survey-questions/image/comment/ImageComment";
import ImageMultipleChoice from "../../../components/user/survey-questions/image/multiple-choice/ImageMultipleChoice";
import ImageCheckboxes from "../../../components/user/survey-questions/image/checkboxes/ImageCheckboxes";
import ImageRating from "../../../components/user/survey-questions/image/rating/ImageRating";
import ImageRanking from "../../../components/user/survey-questions/image/ranking/ImageRanking";
import ImageAssociation from "../../../components/user/survey-questions/image/association/ImageAssociation";
import Modal from "react-modal";
import VideoInstruction from "../../../components/user/survey-questions/video/instruction/VideoInstruction";
import VideoRating from "../../../components/user/survey-questions/video/rating/VideoRating";
import VideoAssociation from "../../../components/user/survey-questions/video/association/VideoAssociation";
import VideoRanking from "../../../components/user/survey-questions/video/ranking/VideoRanking";
import VideoMultipleChoice from "../../../components/user/survey-questions/video/multiple-choice/VideoMultipleChoice";
import VideoCheckboxes from "../../../components/user/survey-questions/video/checkboxes/VideoCheckboxes";
import VideoComment from "../../../components/user/survey-questions/video/comment/VideoComment";
import CheckboxesSpecial from "../../../components/user/profile-questions/checkboxes-special/CheckboxesSpecial";
import AdAnalysis from "../../../components/user/survey-questions/ad-analysis/AdAnalysis";
import { getWordCloudAnalysis } from "../constants/getWordCloudReport";
import { branchingActions, branchingConditions } from "../../../constants";
import { notify } from "../../../utils";
import { useRef } from "react";

const modalStyles = {
  content: {
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    border: 0,
    padding: 0,
    borderRadius: 0,
  },
};

const cx = c.bind(styles);

// Testing data
const questions = [
  {
    text: "What is your opinion on the US elections?",
    type: "text",
    options: {},
  },
  {
    text: "What is your gender?",
    type: "multiChoices",
    options: {
      multiChoices: [
        {
          label: "female",
        },
        {
          label: "male",
        },
        {
          label: "other",
        },
      ],
    },
  },
  {
    text: "Which languages are you fluent in?",
    type: "checkBoxes",
    options: {
      multiChoices: [
        {
          label: "english",
        },
        {
          label: "hindi",
        },
        {
          label: "marathi",
        },
        {
          label: "gujarati",
        },
      ],
    },
  },
  {
    text: "Choose the image you don’t like",
    type: "imageMultiChoices",
    options: {
      imageMultiChoices: [
        {
          imageLabel: "Image 1",
          imageData: RobotLarge,
        },
        {
          imageLabel: "Image 2",
          imageData: RobotLarge,
        },
      ],
    },
  },
  {
    text: "Choose the image you don’t like",
    type: "imageCheckBoxes",
    options: {
      imageMultiChoices: [
        {
          imageLabel: "Image 1",
          imageData: RobotLarge,
        },
        {
          imageLabel: "Image 2",
          imageData: RobotLarge,
        },
      ],
    },
  },
  {
    text: "Choose the video you don’t like",
    type: "videoMultiChoices",
    options: {
      videoMultiChoices: [
        {
          videoLabel: "Video 1",
          videoData: "https://www.youtube.com/watch?v=9xwazD5SyVg",
        },
        {
          videoLabel: "Video 2",
          videoData: "https://www.youtube.com/watch?v=9xwazD5SyVg",
        },
      ],
    },
  },
  {
    text: "Choose the video you don’t like",
    type: "videoCheckBoxes",
    options: {
      videoMultiChoices: [
        {
          videoLabel: "Video 1",
          videoData: "https://www.youtube.com/watch?v=9xwazD5SyVg",
        },
        {
          videoLabel: "Video 2",
          videoData: "https://www.youtube.com/watch?v=9xwazD5SyVg",
        },
      ],
    },
  },
];

const rules = [
  {
    questions: [
      {
        index: 1, // index of question
        condition: branchingConditions.IS_NOT,
        choices: [0], // index of choices
        logicalOr: true,
      },
      /* {
        index: 2,
        condition: branchingConditions.DOES_NOT_CONTAIN_ALL_OF,
        choices: [0, 1, 2],
        logicalOr: false
      } */
    ],
    actions: [
      {
        name: branchingActions.HIDE_QUESTION,
        question: 3, // index of question
      },
      {
        name: branchingActions.HIDE_QUESTION,
        question: 4, // index of question
      },
    ],
  },
];

const isTypeRequired = (type) => {
  return [
    "text",
    "multiChoices",
    "checkBoxes",
    "stars",
    "likert",
    "likert",
    "imageMultiChoices",
    "imageCheckBoxes",
    "netPromotorScore",
    "videoComment",
    "imageComment",
    "videoMultiChoices",
    "videoCheckBoxes",
    "imageStarRatingChoices",
    "videoStarRatingChoices",
  ].includes(type);
};

const emptyCheck = (type, answer) => {
  const specialTypes = [
    "imageStarRatingChoices",
    "videoStarRatingChoices",
    "text",
    "imageComment",
    "videoComment",
  ];
  if (answer && !specialTypes.includes(type)) {
    return answer.length === 0;
  }
  let isEmpty = false;
  switch (type) {
    case "text":
      return answer.length === 0 || answer[0] === "";
    case "imageComment":
      return answer.length === 0 || answer[0] === "";
    case "videoComment":
      return answer.length === 0 || answer[0] === "";
    case "imageStarRatingChoices":
      Object.keys(answer[0]).forEach((el) => {
        if (answer[0][el] === 0) {
          isEmpty = true;
          return;
        }
      });
      return isEmpty;
    case "videoStarRatingChoices":
      Object.keys(answer[0]).forEach((el) => {
        if (answer[0][el] === 0) {
          isEmpty = true;
          return;
        }
      });
      return isEmpty;
    default:
      return false;
  }
};

function getRange(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

let answers = [];
let hiddenQuestions = [];
const Survey = ({
  isScreeningQuestion = false,
  questions = [],
  rules = [],
  isOpen,
  forceClose = () => {},
  onClose,
  onEndSurvey = () => {},
  onDisqualification = () => {},
  surveyType,
  video,
  userId = null,
  tryOwnForm = false,
  brandName = "",
  brandLogo = "",
  surveyInstructions = "",
}) => {
  // Hooks
  const TopBarResponsive = useMediaQuery({ query: "(max-width: 960px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 800px)" });
  const [step, setStep] = useState(0);
  const [isDisqualified, setIsDisqualified] = useState(false);
  const videoRef = useRef(null);
  console.log("Running");
  useEffect(() => {
    // Populate answers with empty array
    [...Array(questions.length).keys()].map((i) => (answers[i] = []));
    console.log("population done");
  }, []);

  useEffect(() => {
    if (isOpen && !tryOwnForm && surveyType) {
      getVideo();
    }
  }, [videoRef, isOpen, tryOwnForm]);

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { width: 300 } })
      .then((stream) => {
        let videoObject = videoRef.current;
        videoObject.srcObject = stream;
        videoObject.play();
      })
      .catch((err) => {
        console.error("error:", err);
      });
  };

  // Derived state
  const index = step - 1;
  const totalQuestions = questions.length;
  const endSurveySlideNumber = questions.length + 1;
  const isQuestionHidden = hiddenQuestions.includes(index);

  const getQuestionText = () => {
    const { type, text, options } = questions[index];

    switch (type) {
      case "netPromotorScore":
        const { npsOption } = options;
        return `How likely is it that you would recommend ${npsOption} to a friend or collegue?`;
      default:
        return text;
    }
  };

  const getComponentByQuestionType = () => {
    const { type, options } = questions[index];

    const onChange = (values) => {
      answers[index] = Array.isArray(values) ? values : [values];
    };

    if (answers.length === 0) {
      [...Array(questions.length)].map(() => answers.push([]));
    }

    if (answers[index]) {
      switch (type) {
        case "text":
          return (
            <Text key={index} onChange={onChange} value={answers[index][0]} />
          );
        case "multiChoices":
          return (
            <MultipleChoice
              key={index}
              choices={options.multiChoices}
              onChange={onChange}
              value={answers[index][0]}
            />
          );
        case "checkBoxes":
          return (
            <Checkboxes
              key={index}
              choices={options.multiChoices}
              onChange={onChange}
              value={answers[index]}
            />
          );
        case "stars":
          console.log(options);
          const { stars, starType } = options;
          return (
            <StarRating
              key={index}
              options={{ stars, starType }}
              onChange={onChange}
              value={answers[index][0]}
            />
          );
        case "slider":
          answers[index][0] = answers[index][0] || 0;
          const {
            sliderType,
            sliderValue,
            sliderMin,
            sliderMax,
            sliderStartLabel,
            sliderMiddleLabel,
            sliderEndLabel,
          } = options;
          return (
            <SliderType
              key={index}
              options={{
                sliderType,
                sliderValue,
                sliderMin,
                sliderMax,
                sliderStartLabel,
                sliderMiddleLabel,
                sliderEndLabel,
              }}
              onChange={onChange}
              value={answers[index][0]}
            />
          );
        case "likert":
          const {
            likertRange,
            likertStartLabel,
            likertMiddleLabel,
            likertEndLabel,
          } = options;
          return (
            <Likert
              key={index}
              options={{
                likertRange,
                likertStartLabel,
                likertMiddleLabel,
                likertEndLabel,
              }}
              onChange={onChange}
              value={answers[index][0]}
            />
          );
        case "netPromotorScore":
          return (
            <NPS
              key={index}
              options={{ npsOption: options.npsOption }}
              onChange={onChange}
              value={answers[index][0]}
            />
          );
        case "ranking":
          answers[index] =
            answers[index].length > 0 ? answers[index] : options.multiChoices;
          return (
            <Ranking key={index} onChange={onChange} value={answers[index]} />
          );
        case "dropDown":
          answers[index][0] = answers[index][0] || 1;
          const range = options.range;
          return (
            <Dropdown
              key={index}
              options={{ range }}
              onChange={onChange}
              value={answers[index][0]}
            />
          );
        case "association":
          answers[index] =
            answers[index].length > 0
              ? answers[index]
              : options.associationChoices;
          return (
            <Association
              key={index}
              onChange={onChange}
              value={answers[index]}
            />
          );
        case "imageInstruction":
          return <ImageInstructions key={index} options={options} />;
        case "imageComment":
          return (
            <ImageComment
              key={index}
              options={{
                imageData: options.imageData,
                imageLabel: options.imageLabel,
              }}
              value={answers[index][0]}
              onChange={onChange}
            />
          );
        /* case "imageABTest":
      const { imageABTestChoices } = options
      return <ImageInstructions options={{ imageABTestChoices }} />; */
        case "imageMultiChoices":
          const { imageMultiChoices } = options;
          return (
            <ImageMultipleChoice
              key={index}
              options={{ imageMultiChoices }}
              value={answers[index][0]}
              onChange={onChange}
            />
          );
        case "imageCheckBoxes":
          return (
            <ImageCheckboxes
              key={index}
              options={{ imageMultiChoices: options.imageMultiChoices }}
              value={answers[index]}
              onChange={onChange}
            />
          );
        case "imageStarRatingChoices":
          if (answers[index].length === 0) {
            answers[index][0] = {};
            options.imageStarRatingChoices.forEach((val) => {
              answers[index][0][val.imageLabel] = 0;
            });
          }
          const { imageStarRatingChoices, imageStar, imageStarType } = options;
          return (
            <ImageRating
              key={index}
              options={{ imageStarRatingChoices, imageStar, imageStarType }}
              value={answers[index][0]}
              onChange={onChange}
            />
          );
        case "imageRanking":
          answers[index] =
            answers[index].length > 0
              ? answers[index]
              : options.imageMultiChoices;
          return (
            <ImageRanking
              key={index}
              onChange={onChange}
              value={answers[index]}
            />
          );
        case "imageAssociation":
          answers[index] =
            answers[index].length > 0
              ? answers[index]
              : options.imageAssociationChoices;
          return (
            <ImageAssociation
              key={index}
              options={options}
              onChange={onChange}
              value={answers[index]}
            />
          );
        case "videoInstruction":
          return <VideoInstruction key={index} options={options} />;
        case "videoComment":
          return (
            <VideoComment
              key={index}
              options={{
                videoData: options.videoData,
                videoLabel: options.videoLabel,
              }}
              value={answers[index][0]}
              onChange={onChange}
            />
          );
        case "videoMultiChoices":
          const { videoMultiChoices } = options;
          return (
            <VideoMultipleChoice
              key={index}
              options={{ videoMultiChoices }}
              value={answers[index][0]}
              onChange={onChange}
            />
          );
        case "videoCheckBoxes":
          return (
            <VideoCheckboxes
              key={index}
              options={{ videoMultiChoices: options.videoMultiChoices }}
              value={answers[index]}
              onChange={onChange}
            />
          );
        case "videoStarRatingChoices":
          if (answers[index].length === 0) {
            answers[index][0] = {};
            options.videoStarRatingChoices.forEach((val) => {
              answers[index][0][val.videoLabel] = 0;
            });
          }
          const { videoStarRatingChoices, videoStar, videoStarType } = options;
          return (
            <VideoRating
              key={index}
              options={{ videoStarRatingChoices, videoStar, videoStarType }}
              value={answers[index][0]}
              onChange={onChange}
            />
          );
        case "videoRanking":
          answers[index] =
            answers[index].length > 0
              ? answers[index]
              : options.videoMultiChoices;
          return (
            <VideoRanking
              key={index}
              onChange={onChange}
              value={answers[index]}
            />
          );
        case "videoAssociation":
          answers[index] =
            answers[index].length > 0
              ? answers[index]
              : options.videoAssociationChoices;
          return (
            <VideoAssociation
              key={index}
              options={options}
              onChange={onChange}
              value={answers[index]}
            />
          );
        case "age":
          return (
            <InputNumber
              key={index}
              defaultValue={answers[index]}
              onChange={onChange}
              style={{
                backgroundColor: "#004479",
                color: "white",
                border: "1px solid white",
              }}
            />
          );
        case "checkboxesSpecial":
          const { specialField, multiChoices } = options;
          return (
            <CheckboxesSpecial
              key={index}
              choices={multiChoices}
              onChange={onChange}
              value={answers[index]}
              specialField={specialField}
              color="white"
            />
          );
        case "adAnalysis":
          const { adAnalysisData } = options;
          return <AdAnalysis options={adAnalysisData} />;
        default:
          return;
      }
    }
  };

  const verifyRuleConditions = (conditions) => {
    let result = "true&&";

    for (const [key, item] of Object.entries(conditions)) {
      const lastElement = key == conditions.length - 1;
      const op = lastElement ? "" : item.logicalOr ? "||" : "&&";

      switch (item.condition) {
        case branchingConditions.CONTAINS: {
          const cond = answers[item.index].includes(item.choices[0]);
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.DOES_NOT_CONTAIN: {
          const cond = !answers[item.index].includes(item.choices[0]);
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.CONTAINS_ONE_OF: {
          const cond = answers[item.index].some((x) =>
            item.choices.includes(x)
          );
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.DOES_NOT_CONTAIN_ANY_OF: {
          const cond = !item.choices.every((x) =>
            answers[item.index].includes(x)
          );
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.CONTAINS_ALL_OF: {
          const cond = item.choices.every((x) =>
            answers[item.index].includes(x)
          );
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.DOES_NOT_CONTAIN_ALL_OF: {
          const cond = !item.choices.some((x) =>
            answers[item.index].includes(x)
          );
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.IS_EXACTLY: {
          const cond =
            JSON.stringify(item.choices) ===
            JSON.stringify(answers[item.index]);
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.IS_NOT: {
          const cond =
            JSON.stringify(item.choices) !==
            JSON.stringify(answers[item.index]);
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.IS_FROM_TO: {
          const cond =
            item.from <= answers[item.index][0] &&
            answers[item.index][0] <= item.to;
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.RANKING: {
          const { type } = questions[item.index];
          let cond = false;
          if (answers[item.index].length !== 0) {
            if (type === "ranking") {
              cond = answers[item.index][item.rank - 1].label === item.label;
            } else if (type === "imageRanking") {
              cond =
                answers[item.index][item.rank - 1].imageLabel === item.label;
            } else if (type === "videoRanking") {
              cond =
                answers[item.index][item.rank - 1].videoLabel === item.label;
            }
          }
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.ASSOCIATION: {
          const { type } = questions[item.index];
          let cond = false;
          answers[item.index].length !== 0 &&
            answers[item.index].forEach((x, idx) => {
              if (
                type === "association" &&
                x.label === item.label &&
                x.association === item.association
              ) {
                cond = true;
                return;
              }

              if (type === "imageAssociation" || type === "videoAssociation") {
                const num = parseInt(item.label.substring(6)) - 1;
                if (num == idx && x.association === item.association) {
                  cond = true;
                  return;
                }
              }
            });
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.IMAGE_VIDEO_RATINGS: {
          const ans =
            answers[item.index].length !== 0 &&
            answers[item.index][0][item.label];
          const cond = item.from <= ans && ans <= item.to;
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        default:
          break;
      }
    }

    return result === "true";
  };

  const executeRuleActions = (actions) => {
    // Set step always one less than the expected step.
    for (const item of actions) {
      switch (item.name) {
        // skip -> 9
        // current -> 2
        // hide -> 3, 4, 5, 6, 7, 8
        case branchingActions.SKIP_TO_QUESTION: {
          const range = getRange(index + 1, item.question - 1);
          hiddenQuestions = hiddenQuestions.concat(range);
          break;
        }

        case branchingActions.END_SURVEY: {
          setStep(questions.length);
          const range = getRange(index + 1, questions.length - 1);
          hiddenQuestions = hiddenQuestions.concat(range);
          break;
        }

        case branchingActions.DISQUALIFY_RESPONDENT: {
          setStep(questions.length);
          setIsDisqualified(true);
          const range = getRange(index + 1, questions.length - 1);
          hiddenQuestions = hiddenQuestions.concat(range);
          break;
        }

        case branchingActions.HIDE_QUESTION: {
          hiddenQuestions = hiddenQuestions.concat([item.question]);
          break;
        }

        case branchingActions.INVALIDATE_QUESTION: {
          answers[item.question] = [];
          if (item.question > index) {
            hiddenQuestions = hiddenQuestions.concat([item.question]);
          }
          break;
        }

        default:
          break;
      }
    }
  };

  const performBranchingLogic = () => {
    let results = [];
    for (const rl of rules) {
      const maxIndex = rl.questions.reduce(
        (prev, curr) => Math.max(prev, curr.index),
        0
      );
      if (maxIndex === index) {
        results.push({
          verified: verifyRuleConditions(rl.questions),
          actions: rl.actions,
        });
      }
    }

    // false conditions must be executed first
    results.sort((a, b) => {
      if (Number(a.verified) < Number(b.verified)) {
        return -1;
      }
      if (Number(a.verified) > Number(b.verified)) {
        return 1;
      }
      return 0;
    });

    // execute actions
    for (let x of results) {
      if (x.verified) {
        executeRuleActions(x.actions);
        break;
      } else {
        x.actions.forEach((el) => {
          if (
            el.name === branchingActions.HIDE_QUESTION ||
            branchingActions.INVALIDATE_QUESTION
          ) {
            hiddenQuestions = hiddenQuestions.filter(
              (val) => el.question !== val
            );
          }

          if (el.name === branchingActions.SKIP_TO_QUESTION) {
            const range = getRange(index + 1, el.question - 1);
            hiddenQuestions = hiddenQuestions.filter(
              (val) => !range.includes(val)
            );
          }

          if (
            el.name === branchingActions.END_SURVEY ||
            el.name === branchingActions.DISQUALIFY_RESPONDENT
          ) {
            const range = getRange(index + 1, questions.length - 1);
            hiddenQuestions = hiddenQuestions.filter(
              (val) => !range.includes(val)
            );
          }
        });
      }
    }
  };

  // Handlers
  const incrementStep = () => {
    if (
      step !== 0 &&
      !isQuestionHidden &&
      isTypeRequired(questions[index].type) &&
      emptyCheck(questions[index].type, answers[index])
    ) {
      message.error("Please answer this question first!");
      return;
    }

    if (rules?.length !== 0) {
      performBranchingLogic();
    }

    setStep((prevStep) => {
      while (true) {
        if (hiddenQuestions.includes(prevStep++)) {
          continue;
        }
        return prevStep;
      }
    });
  };

  const decrementStep = () => {
    setStep((prevStep) => {
      while (true) {
        if (hiddenQuestions.includes(--prevStep - 1)) {
          continue;
        }
        return prevStep;
      }
    });
  };

  const endSurvey = async () => {
    if (isDisqualified) {
      notify(
        "info",
        "Info",
        "You have been disqualifed. Survey is now removed."
      );
      onDisqualification();
    } else {
      if (userId !== null) {
        answers = await Promise.all(
          answers.map(async (answer, index) => {
            if (
              ["text", "imageComment", "videoComment"].indexOf(
                questions[index].type
              ) > -1 &&
              !!answer?.length
            ) {
              const report = await getWordCloudAnalysis(answer, userId);
              answer.push(report);
            }
            return answer;
          })
        );
      }
      console.log("final answers array:", answers);
      onEndSurvey([...answers]);
    }
    if (isScreeningQuestion) {
      resetModal();
    } else {
      onCloseModal();
    }
  };

  const resetModal = () => {
    answers = [];
    hiddenQuestions = [];
    setStep(0);
  };

  const onCloseModal = () => {
    resetModal();
    onClose();
  };

  const shouldRenderOptions = () => {
    if (step === 0 || step === endSurveySlideNumber) {
      return false;
    }

    if (
      step > 0 &&
      questions[index] &&
      questions[index].type === "questionInstruction"
    ) {
      return false;
    }

    return true;
  };

  console.log("questions12345", answers);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        onCloseModal();
        forceClose();
      }}
      style={modalStyles}
    >
      <RemoveScrollBar />
      <div className={cx("survey-container")}>
        {TopBarResponsive ? (
          <div className={cx("mobile_topbar")}>
            <div className={cx("mobile_logo")}>
              <img
                src={brandLogo ? brandLogo : logo}
                style={
                  brandLogo
                    ? { maxHeight: 200, maxWidth: 200, objectFit: "contain" }
                    : { height: 48 }
                }
              />
            </div>
            {isTablet ? (
              <LogoutOutlined
                style={{ fontSize: 24, cursor: "pointer" }}
                onClick={() => {
                  onCloseModal();
                  forceClose();
                }}
              />
            ) : (
              <div
                onClick={() => {
                  onCloseModal();
                  forceClose();
                }}
                className={cx("mobile_quit-button")}
                title="Quit Survey"
              >
                QUIT SURVEY <LogoutOutlined />
              </div>
            )}
          </div>
        ) : (
          <>
            <div className={cx("logo")}>
              <img
                src={brandLogo ? brandLogo : logo}
                style={
                  brandLogo
                    ? { maxHeight: 200, maxWidth: 200, objectFit: "contain" }
                    : { height: 48 }
                }
              />
            </div>
            <div
              onClick={() => {
                onCloseModal();
                forceClose();
              }}
              className={cx("quit-button")}
              title="Quit Survey"
            >
              QUIT SURVEY <LogoutOutlined />
            </div>
          </>
        )}
        <div
          className={cx("center", {
            end_center: step === endSurveySlideNumber,
          })}
        >
          {/* QUESTION */}
          <div className={cx("question-box")}>
            {step !== 0 && step !== endSurveySlideNumber && (
              <div
                style={{ fontSize: 24, marginRight: 18, fontWeight: "bold" }}
              >
                {step}
              </div>
            )}
            <div
              style={{
                flexGrow: 1,
                display:
                  step === 0 || step === endSurveySlideNumber
                    ? "block"
                    : "flex",
                alignItems: "center",
                fontSize: 16,
              }}
            >
              {step == 0 && (
                <>
                  <p
                    style={{
                      fontSize: 20,
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    Welcome to {brandName ? brandName : "UserQual"}!
                  </p>
                  {!!surveyInstructions && (
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      {surveyInstructions}
                    </p>
                  )}
                  <p style={{ margin: 0, textAlign: "center" }}>
                    You can start the survey by clicking the NEXT Button.
                  </p>
                </>
              )}
              {step !== 0 && step !== endSurveySlideNumber && questions[index] && (
                <div style={{ width: "100%" }}>
                  <div>{getQuestionText()}</div>
                  {questions[index].hasLink ? (
                    <Card bordered={true} className={cx("linkCard")}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "16px",
                            padding: "10px",
                            margin: "auto 0",
                          }}
                        >
                          <LinkOutlined
                            style={{ margin: "auto", paddingLeft: "15px" }}
                          />

                          {questions[index].clientLink}
                        </div>
                        <Button
                          onClick={() =>
                            window.open(
                              "https://" + questions[index].clientLink,
                              "_blank"
                            )
                          }
                          className={cx("linkButton")}
                        >
                          OPEN
                        </Button>
                      </div>
                    </Card>
                  ) : null}
                </div>
              )}
              {step === endSurveySlideNumber && (
                <div style={{ textAlign: "center" }}>
                  {/* <CheckCircleFilled style={{ fontSize: 36 }} /> <br /><br /> */}
                  <span>Please click on End Survey to store your results.</span>{" "}
                  <br />
                  <br />
                  <Button
                    type="primary"
                    shape="round"
                    size="large"
                    onClick={endSurvey}
                    style={{
                      backgroundColor: "#FF9480",
                      color: "white",
                      border: "#FF9480",
                    }}
                  >
                    End Survey
                  </Button>
                </div>
              )}
            </div>
          </div>
          {/* OPTIONS */}
          {shouldRenderOptions() && (
            <div className={cx("options-box")}>
              {questions[index] && getComponentByQuestionType()}
            </div>
          )}
        </div>
        {/* <div className={cx("center")}>

          {surveyType && (
            <p
              style={{
                textAlign: "left",
                maxWidth: 800,
                margin: "0px auto 15px",
                color: "#8FB0C1",
              }}
            >
              Read the Question aloud
            </p>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              maxWidth: 800,
              margin: "0px auto",
            }}
          >
            <p style={{ textAlign: "left", marginBottom: 0 }}>
              {questions[index] && questions[index].description ? (
                <span style={{ color: "#BAE0EB" }}>
                  {questions[index].description}
                </span>
              ) : null}
              {surveyType &&
                questions[index - 1] &&
                questions[index - 1].clientLink &&
                questions[index - 1].clientLink.length > 0 ? (
                <p style={{ color: "#8FB0C1", margin: "10px 0px 0px" }}>
                  (The previous link was{" "}
                  <a
                    href={questions[index - 1].clientLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#0561BE" }}
                  >
                    {questions[index - 1].clientLink}
                  </a>
                  )
                </p>
              ) : null}
            </p>
          </div>
          {questions[index] &&
            questions[index].clientLink &&
            questions[index].clientLink.length > 0 ? (
            <div
              style={{
                backgroundColor: "#00C194",
                borderRadius: 8,
                padding: "10px 20px",
                maxWidth: 600,
                margin: "30px auto 0px",
                cursor: "pointer",
              }}
            >
              Open link:{" "}
              <a
                href={`${questions[index].clientLink}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {questions[index].clientLink}
              </a>{" "}
              (Click here)
            </div>
          ) : null}
          {surveyType && (
            <p
              style={{
                textAlign: "left",
                maxWidth: 800,
                margin: "0px auto 15px",
                color: "#8FB0C1",
                marginTop: 40,
              }}
            >
              Take your time. Think aloud. Move on to the next step when
              you've completed this question.
            </p>
          )}
        </div> */}
        {/* @devansh update csss */}
        <div>
          <video ref={videoRef} />
        </div>
        <div className={cx("bottom-bar")}>
          <div
            className={cx("bottom-wrapper")}
            style={{
              justifyContent: step === 0 ? "flex-end" : "space-between",
            }}
          >
            {step !== 0 && (
              <div
                onClick={decrementStep}
                className={cx("bottom-buttons")}
                title="Previous"
              >
                <LeftOutlined />
                {"  "} PREVIOUS
              </div>
            )}
            {step !== endSurveySlideNumber && (
              <div
                onClick={incrementStep}
                className={cx("bottom-buttons")}
                title="Next"
              >
                NEXT{"  "} <RightOutlined />
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Survey;
