import { GROUP_SLOTS, SLOT_TIME_HRS } from "../../constants/constants";
import { isGroupSurvey, isModeratedSurvey } from "../utils";

//Converts the moderated slot's date and time to a native date object
export const getDateObject = (date, time) => {
  let [day, month, year] = date.split("/");
  month = month.length === 2 ? month : "0" + month;
  day = day.length === 2 ? day : "0" + day;
  const dateString = `${year}-${month}-${day}T${time}`;
  return new Date(dateString);
};

//gets the date and time of the moderated survey slot for the user
export const getScheduledSlotForUser = (moderatedSlots, userId) => {
  let surveyDate = null;
  let surveyTime = null;
  if (!moderatedSlots || !userId) {
    return { surveyDate, surveyTime };
  }
  for (let date in moderatedSlots) {
    for (let timeSlot in moderatedSlots[date]) {
      if (moderatedSlots[date][timeSlot].includes(userId)) {
        surveyDate = date;
        surveyTime = timeSlot;
      }
    }
  }
  return { surveyDate, surveyTime };
};

//gets the native date object of the moderated survey slot for the user
export const getScheduledSlotForUserTimeStamp = (moderatedSlots, userId) => {
  const { surveyDate, surveyTime } = getScheduledSlotForUser(
    moderatedSlots,
    userId
  );
  if (!surveyDate || !surveyTime) {
    return null;
  }
  return getDateObject(surveyDate, surveyTime);
};

//gets the total number of available slots in the survey
export const getAvailableSlots = (survey) => {
  if (!isModeratedSurvey(survey) || !survey.moderatedSlots) {
    return null;
  }

  let availableSlots = 0;
  let bookedSlots = 0;
  const currentDate = new Date().setHours(0, 0, 0, 0);

  //finds available and booked slots
  Object.keys(survey.moderatedSlots).forEach((d) => {
    const date = new Date(getDateObject(d, "00:00"));
    if (date >= currentDate) {
      const timeSlots = Object.keys(survey.moderatedSlots[d]);

      timeSlots.forEach((ts) => {
        if (isGroupSurvey(survey)) {
          availableSlots += GROUP_SLOTS - survey.moderatedSlots[d][ts].length;
          bookedSlots += survey.moderatedSlots[d][ts].length;
        } else {
          if (survey.moderatedSlots[d][ts].length === 0) {
            availableSlots += 1;
          } else {
            bookedSlots += 1;
          }
        }
      });
    }
  });

  if (survey.numberOfParticipants - bookedSlots <= availableSlots) {
    availableSlots = survey.numberOfParticipants - bookedSlots;
  }

  return availableSlots;
};

//returns true if the scheuled scheduled slot is over and false otherwise
export const isSlotExpiredForUser = (scheduledTimeStamp) => {
  if (!scheduledTimeStamp) {
    return false;
  }
  return (
    new Date() >=
    new Date(
      scheduledTimeStamp.setHours(scheduledTimeStamp.getHours() + SLOT_TIME_HRS)
    )
  );
};

//returns true if the user has passed the window where they can schedule or change a survey slot
export const isSlotChangeDisabled = (moderatedSlots, userId) => {
  if (!moderatedSlots || !userId) return false;

  const d = getScheduledSlotForUserTimeStamp(moderatedSlots, userId);
  if (!d) return false;
  d.setHours(d.getHours() - 1);
  return d < new Date();
};
