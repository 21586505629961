import { Radio } from "antd";
import React from "react";
import { capitalizeFirstCharacter } from "../../../../utils";
import styles from "./multiple-choice.module.css";
import c from "classnames/bind";

const cx = c.bind(styles);

const MultipleChoice = ({
  key,
  choices,
  onChange = () => {},
  value,
  color,
}) => {
  return (
    <Radio.Group
      key={key}
      size="large"
      className={cx("multipleChoice")}
      onChange={(e) => onChange(e.target.value)}
      defaultValue={value}
    >
      {choices.map((val, index) => (
        <Radio
          style={{
            marginRight: 25,
            lineHeight: "2.3px",
            marginBottom: 30,
            color: color ? color : "black",
          }}
          value={index}
        >
          {capitalizeFirstCharacter(val)}
        </Radio>
      ))}
    </Radio.Group>
  );
};

export default MultipleChoice;
