import React, { Fragment, useState } from "react";
import styles from "./TextABTestChoices.module.css";
import c from "classnames/bind";
import {
  CheckOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Input, Tooltip } from "antd";

const cx = c.bind(styles);

const TextABTestChoices = (props) => {
  // Props
  const {
    dragHandleProps,
    choiceNumber,
    choice,
    isFilled,
    addChoice,
    removeChoice,
    totalChoices,
    setValue,
  } = props;

  return (
    <div className={cx("grid-container")} style={{ marginBottom: 5 }}>
      <div className={cx("choiceNumber")}>
        <div
          style={{ margin: "auto", height: 20, width: 20, textAlign: "center" }}
        >
          <span className="choice-numberCircle">{choiceNumber}</span>
          <MenuOutlined className={cx("menu-outlined")} {...dragHandleProps} />
        </div>
      </div>
      <div className={cx("fields")}>
        <div>
          <div>
            <Input
              size="large"
              placeholder="Input Word or Phrase"
              style={{ borderRadius: 3 }}
              value={choice.inputWord}
              onChange={(event) =>
                setValue({ ...choice, inputWord: event.target.value })
              }
              suffix={
                isFilled.inputWord ? (
                  <CheckOutlined style={{ color: "green" }} />
                ) : (
                  <Tooltip title="You need to fill this option!">
                    <InfoCircleOutlined style={{ color: "#ff9480" }} />
                  </Tooltip>
                )
              }
              className="input-border"
            />
          </div>
        </div>
        <div className={cx("controlFields")}>
          <div className={cx("percentageField")}>
            <Input
              size="large"
              placeholder="%"
              style={{ borderRadius: 3 }}
              value={choice.percentage}
              onChange={(event) =>
                setValue({ ...choice, percentage: event.target.value })
              }
              suffix={
                <Fragment>
                  <label
                    style={{
                      fontSize: 16,
                      color: "#004479",
                      paddingRight: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    %
                  </label>
                  {isFilled.percentage ? (
                    <CheckOutlined style={{ color: "green" }} />
                  ) : (
                    <Tooltip title="You need to fill this option!">
                      <InfoCircleOutlined style={{ color: "#ff9480" }} />
                    </Tooltip>
                  )}
                </Fragment>
              }
              className="input-border"
            />
          </div>
          {
            <div className={cx("controls")}>
              <PlusCircleOutlined
                onClick={() => addChoice(choiceNumber)}
                className="action-icon"
              />
              {totalChoices > 2 && (
                <MinusCircleOutlined
                  className="action-icon"
                  onClick={() => removeChoice(choiceNumber)}
                />
              )}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default TextABTestChoices;
