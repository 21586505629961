import { Button, Input, Form, notification } from "antd";
import { LockOutlined } from "@ant-design/icons";
import React from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Center } from "./Signup";
import axios, { Routes } from "../services/API";
import handleNetworkErrors from "./client/handleNetworkErrors";
import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { editProfile as editUserProfile } from "../actions/users";
import { editProfile as editClientProfile } from "../actions/client";
import { getEmailByToken, getRoleByToken } from "../utils";

const Verification = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const [p1, setP1] = useState("");
  const [p2, setP2] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);
  const { email } = jwtDecode(token);
  const [role, forgotPassword] = jwtDecode(token).role.split("_");
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const { url, method } = Routes.verification.verifyEmail();
      try {
        const { data } = await axios[method](url, { data: { token } });
        if (data.message && data.message.includes("Email verified")) {
          setEmailVerified(true);
          notification.success({
            message: "Email verified successfully!",
          });
          const email = getEmailByToken(token);
          if (getRoleByToken(token) === "user") {
            dispatch(editUserProfile({ email, isEmailVerified: true }));
          } else {
            dispatch(editClientProfile({ email, isEmailVerified: true }));
          }
        }
      } catch (err) {
        handleNetworkErrors(err);
      }
    })();
  }, [token]);

  const signup = async () => {
    if (emailVerified) {
      if (p1 !== p2) {
        notification.info({
          message: "Please type the same password both the times",
        });
        return;
      }
      const { url, method } =
        forgotPassword === "forgotPassword"
          ? Routes[role].forgotPassword()
          : Routes[role].signup();
      try {
        const { data } = await axios[method](url, {
          data: {
            email: email.toLowerCase(),
            password: p1,
            token,
            isEmailVerified: true,
            registeredVia: 0,
          },
        });
        if (data[role] && data.token) {
          console.log(data);
          localStorage.setItem(role, data[role]._id);
          localStorage.setItem("token", data.token);
          if (role === "user") history.push(`/user/forms`);
          else if (role === "client") history.push("/client");
        }
      } catch (err) {
        handleNetworkErrors(err);
      }
    }
  };

  return (
    <Center>
      <Form>
        <Form.Item>
          <Input
            value={p1}
            type="password"
            prefix={<LockOutlined className="site-form-item-icon" />}
            onChange={(e) => setP1(e.target.value)}
            placeholder="Enter password"
          />
        </Form.Item>
        <Form.Item>
          <Input
            value={p2}
            type="password"
            prefix={<LockOutlined className="site-form-item-icon" />}
            onChange={(e) => setP2(e.target.value)}
            placeholder="Re-enter password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            onClick={() => signup()}
            style={{ width: "100%" }}
          >
            {forgotPassword === "forgotPassword"
              ? "Change password"
              : "Sign Up"}
          </Button>
        </Form.Item>
      </Form>
    </Center>
  );
};

export default Verification;
