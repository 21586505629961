import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ImageMultiChoicesOptions from "./imageMultiChoicesOptions/imageMultiChoicesOptions";
import useArray from "../../useArray";

const ImageMultiChoices = ({ options, setOptions, checkBox }) => {
  // const [multiChoices, { add, remove, move, set }] = useArray(
  //   options.imageMultiChoices
  // );

  const imageMultiChoices = options.imageMultiChoices;

  const add = (item, index) => {
    setOptions({
      ...options,
      imageMultiChoices: [
        ...imageMultiChoices.slice(0, index),
        item,
        ...imageMultiChoices.slice(index, imageMultiChoices.length),
      ],
    });
  };

  const move = (source, dest) => {
    const result = Array.from(imageMultiChoices);
    const [removed] = result.splice(source, 1);
    result.splice(dest, 0, removed);
    setOptions({ ...options, imageMultiChoices: result });
  };

  const remove = (index) => {
    setOptions({
      ...options,
      imageMultiChoices: [
        ...imageMultiChoices.slice(0, index),
        ...imageMultiChoices.slice(index + 1, imageMultiChoices.length),
      ],
    });
  };

  const set = (item, index) => {
    const result = Array.from(imageMultiChoices);
    result[index] = item;
    setOptions({ ...options, imageMultiChoices: result });
  };

  const setOptionValue = (value, key, index) => {
    if (key == "imageLabel") {
      const result = {
        ...imageMultiChoices[index],
        imageLabel: value.imageLabel,
        imageData: value.imageData,
      };
      set(result, index);
    } else {
      const result = { ...imageMultiChoices[index], [key]: value };
      set(result, index);
    }
    console.log(options);
  };

  return (
    <DragDropContext
      onDragEnd={(result) =>
        move(result.source.index, result.destination.index)
      }
    >
      <Droppable droppableId="list">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              paddingTop: 10,
            }}
          >
            {imageMultiChoices.map((choice, index) => (
              <Draggable
                draggableId={`option-${index}`}
                index={index}
                key={index}
              >
                {(provided) => (
                  <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    gutter={6}
                  >
                    <ImageMultiChoicesOptions
                      dragHandleProps={provided.dragHandleProps}
                      choiceNumber={index + 1}
                      choice={choice}
                      checkBox={checkBox}
                      setLabel={(item) => {
                        setOptionValue(item, "imageLabel", index);
                      }}
                      totalChoices={imageMultiChoices.length}
                      isFilled={{
                        imageLabel: choice.imageLabel !== "",
                        imageData: choice.imageData !== "",
                      }}
                      setMcq={(value) => setOptionValue(value, "isMcq", index)}
                      addChoice={(newIndex) =>
                        add({ imageLabel: "", imageData: "" }, newIndex)
                      }
                      removeChoice={(index) => remove(index - 1)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ImageMultiChoices;
