import React, { useEffect } from "react";
import { Slider } from "antd";
import "./slider.css";
import { useMediaQuery } from "react-responsive";

const SliderType = ({ key, options, onChange = () => {}, value }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  console.log(options.sliderEndLabel, value);
  const marks = {
    [options.sliderMin]: {
      style: {
        marginTop: 5,
        fontSize: isTabletOrMobile ? 14 : 18,
      },
      label:
        options.sliderType === "percentage"
          ? options.sliderMin + "%"
          : options.sliderMin,
    },
    [options.sliderMax]: {
      style: {
        marginTop: 5,
        fontSize: isTabletOrMobile ? 14 : 18,
      },
      label:
        options.sliderType === "percentage"
          ? options.sliderMax + "%"
          : options.sliderMax,
    },
  };
  return (
    <div style={{ marginTop: 50 }} key={key}>
      <Slider
        tooltipVisible
        marks={marks}
        className="slider-type"
        tipFormatter={(value) =>
          options.sliderType === "percentage" ? value + "%" : value
        }
        max={options.sliderMax}
        defaultValue={value}
        onAfterChange={(val) => onChange(val)}
      />
      <div className="slider-bottom-marks" style={{ paddingTop: "10px", marginLeft:"9px" }}>
        <div className="slider-label">{options.sliderStartLabel}</div>
        <div className="slider-label">{options.sliderMiddleLabel}</div>
        <div className="slider-label">{options.sliderEndLabel}</div>
      </div>
    </div>
  );
};

export default SliderType;
