import React from "react";
import { Form, Modal, Row, Col, InputNumber, Button } from "antd";

const OtherPricingModal = (props) => {
  const { selectedOtherPricing, handleCancel, handleEditOtherPricing } = props;

  const [form] = Form.useForm();

  const onEditPlansFees = () => {
    form.validateFields().then((values) => {
      handleEditOtherPricing(values);
      handleCancel();
    });
  };

  const footer = (
    <div>
      <Button
        onClick={handleCancel}
        style={{ border: "1px solid #004479", marginRight: 10, width: 100 }}
      >
        Cancel
      </Button>
      <Button type="primary" onClick={onEditPlansFees} style={{ width: 100 }}>
        Save
      </Button>
    </div>
  );

  return (
    <Modal
      title=""
      closable={false}
      visible={true}
      onCancel={handleCancel}
      footer={footer}
    >
      <Form
        initialValues={selectedOtherPricing}
        form={form}
        style={{ color: "#004479" }}
      >
        <h2 style={{ color: "#004479", textAlign: "center" }}>
          {selectedOtherPricing.name}
        </h2>
        <Form.Item name="key"></Form.Item>
        <Row gutter={6}>
          <Col xs={16}>Basic Plan Monthly Fee</Col>
          <Col xs={8} style={{ textAlign: "right" }}>
            <Form.Item name="basic">
              <InputNumber className="input-border" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col xs={16}>Elite Plan Monthly Fee</Col>
          <Col xs={8} style={{ textAlign: "right" }}>
            <Form.Item name="elite">
              <InputNumber className="input-border" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col xs={16}>Platinum Plan Monthly Fee</Col>
          <Col xs={8} style={{ textAlign: "right" }}>
            <Form.Item name="platinum">
              <InputNumber className="input-border" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default OtherPricingModal;
