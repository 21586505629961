import { CheckOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Input, Tooltip } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { EMAIL_VALIDATION_REGEX } from "../../../constants";

const EmailInputBox = (props) => {
  const { value, onChange, options } = props;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 450px)" });

  const isValidEmail = () => {
    return EMAIL_VALIDATION_REGEX.test(value);
  };

  return (
    <Input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      size={isTabletOrMobile ? "middle" : "large"}
      placeholder="Email"
      style={{
        border: "none",
        backgroundColor: "#EEE",
        borderRadius: "5px",
      }}
      suffix={
        value && isValidEmail() ? (
          <CheckOutlined style={{ color: "green" }} />
        ) : (
          <Tooltip title="You need to fill this box!">
            <InfoCircleOutlined style={{ color: "red" }} />
          </Tooltip>
        )
      }
      {...options}
    />
  );
};

export default EmailInputBox;
