import { Radio } from "antd";
import React from "react";
import "./nps.css";

const NPS = ({ key, onChange, value }) => {
  const numbers = [...Array(11).keys()];

  return (
    <div style={{ textAlign: "center" }} key={key}>
      <div style={{ display: "inline-block", marginTop: 20 }}>
        <Radio.Group
          className="nps"
          defaultValue={value}
          onChange={(e) => onChange(e.target.value)}
        >
          {numbers.map((val) => (
            <Radio.Button value={val}>{val}</Radio.Button>
          ))}
        </Radio.Group>
        <div className="bottom-mark">
          <span className="bottom-label">Extremely Unlikely</span>
          <span className="bottom-label">Extremely Likely</span>
        </div>
      </div>
    </div>
  );
};

export default NPS;
