import React from "react";
import styles from "./RangeDropDown.css";
import c from "classnames/bind";
import { Select } from "antd";

const cx = c.bind(styles);
const { Option } = Select;

const RangeDropDown = ({ options, setOptions }) => {
  return (
    <div style={{ display: "flex", paddingTop: 10, padding: "0 2rem" }}>
      <div
        style={{ margin: "auto 0px", color: "#004479" }}
        className={cx("text-visible")}
      >
        Range:
      </div>
      <Select
        style={{ marginLeft: 5, width: 100 }}
        value={options.range}
        onChange={(value) => setOptions({ ...options, range: value })}
      >
        {[...Array(10)].map((e, i) => (
          <Option key={i + 1} value={i + 1}>
            {i + 1}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default RangeDropDown;
