import React from "react";
import "./HomeQuestionAnswers.css";
import womanGivingSurvey from "../../../assets/images/woman-giving-survey.png";
import plusIcon from "../../../assets/images/accordion-plus.png";
import minusIcon from "../../../assets/images/accordion-minus.png";
import { Col, Collapse, Row } from "antd";
import questionAnswers from "./questionAnswerData";
const { Panel } = Collapse;

const HomeQuestionAnswers = () => {
  return (
    <div className={"home-question-answers"}>
      <Row justify={"center"} align={"middle"}>
        <Col md={10} className={"image-container"}>
          <img src={womanGivingSurvey} />
        </Col>
        <Col md={10} className={"question-answer-section"}>
          <h1>Things you might be wondering about…</h1>
          <Collapse
            expandIcon={({ isActive }) => (
              <img src={isActive ? minusIcon : plusIcon} />
            )}
            accordion
            ghost
            className={"question-answer-collapse"}
          >
            {questionAnswers.map((record) => {
              return (
                <Panel header={record.question} key={record.question}>
                  <p>{record.answer}</p>
                </Panel>
              );
            })}
          </Collapse>
        </Col>
      </Row>
    </div>
  );
};

export default HomeQuestionAnswers;
