import axios, { Routes } from "../../../services/API";
import handleNetworkErrors from "./handleNetworkErrors";

export const getWordCloudAnalysis = async (comments, userId) => {
  const clientId = localStorage.getItem("user");
  if (userId) {
    const { url, method } = Routes["user"].getWordCloudReport(userId);
    try {
      const { data } = await axios[method](url, {
        data: {
          comments,
        },
      });
      return data;
    } catch (err) {
      handleNetworkErrors(err);
    }
  } else {
    console.log("no user found");
  }
};
