import { Rate } from "antd";
import { HeartFilled, SmileFilled, StarFilled } from "@ant-design/icons";
import React, { useState } from "react";
import "./image-rating.css";
import Thumbnail from "../image-thumbnail/ImageThumbnail";

const ImageRating = ({ key, options, value, onChange }) => {
  const [ratings, setRatings] = useState(value);

  const getCharacter = () => {
    switch (options.imageStarType) {
      case "star":
        return <StarFilled className="img-rating-charater" />;
      case "smiley":
        return <SmileFilled className="img-rating-charater" />;
      case "heart":
        return <HeartFilled className="img-rating-charater" />;
      default:
        return;
    }
  };

  const onRatingsChange = (rate, id) => {
    setRatings({ ...ratings, [id]: rate });
    onChange({ ...ratings, [id]: rate });
  };

  return (
    <div className="image-rating-wrapper" key={key}>
      {options.imageStarRatingChoices.map((opt) => (
        <div className="image-rating-child">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 30,
            }}
          >
            <Thumbnail imgUrl={opt.imageData} />
            <div className="img-checkboxes-label">{opt.imageLabel}</div>
          </div>
          <div className="rating_container">
            <Rate
              allowClear
              character={getCharacter()}
              count={options.imageStar}
              defaultValue={value[opt.imageLabel]}
              onChange={(rate) => onRatingsChange(rate, opt.imageLabel)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImageRating;
