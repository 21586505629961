import React, { useState } from "react";
import { Row, Col, Input } from "antd";
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  MenuOutlined,
} from "@ant-design/icons";

const Categories = (props) => {
  const {
    number,
    value,
    onChange,
    addCategory,
    removeCategory,
    showMinusIcon,
    dragHandleProps,
  } = props;
  // Component states
  const [isNumberShown, setIsNumberShown] = useState(true);
  return (
    <Row
      gutter={6}
      onMouseEnter={() => setIsNumberShown(false)}
      onMouseLeave={() => setIsNumberShown(true)}
    >
      <Col
        xs={2}
        sm={2}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: 24,
        }}
      >
        {isNumberShown ? (
          <span className="choice-numberCircle">{number}</span>
        ) : (
          <MenuOutlined {...dragHandleProps} />
        )}
      </Col>
      <Col xs={16} style={{ flexGrow: 1 }}>
        <Input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          size="large"
          placeholder="Enter category"
          className="input-border"
          style={{ marginBottom: 24 }}
        />
      </Col>
      <Col xs={5} className="no-hover actions">
        <PlusCircleOutlined onClick={addCategory} className="action-icon" />
        {showMinusIcon && (
          <MinusCircleOutlined
            className="action-icon"
            onClick={removeCategory}
          />
        )}
      </Col>
    </Row>
  );
};

export default Categories;
