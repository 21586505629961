import React, { useState } from "react";
import { Fragment } from "react";
import WordCloud from "../../../../../../components/wordcloud/WordCloud";
import SentimentGraph from "../../../../../../components/sentiment-graph/SentimentGraph";
import { dullGreenButtonStyle } from "../../../constants/styles";
import Button from "../../../../../../components/button/Button";

const WordCloudAndSentimentReport = ({
  sentimentReport,
  wordReport,
  participantIndex,
}) => {
  const [wordCloud, setWordCloud] = useState(false);
  const [sentimentAnalysis, setSentimentAnalysis] = useState(false);
  console.log("wordReport", wordReport);
  return (
    <Fragment>
      <div
        className="miscButtons"
        onClick={() => {
          setWordCloud(!wordCloud);
        }}
      >{` ${wordCloud ? "-" : "+"} World Cloud`}</div>
      <div
        className="miscButtons"
        onClick={() => {
          setSentimentAnalysis(!sentimentAnalysis);
        }}
      >{` ${sentimentAnalysis ? "-" : "+"} Sentiment Analysis`}</div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
        className="report-wrapper"
      >
        {wordCloud && <WordCloud wordCloud={wordReport} />}
        {sentimentAnalysis && (
          <SentimentGraph
            participantIndex={participantIndex}
            sentimentAnalysis={sentimentReport}
          />
        )}
      </div>
    </Fragment>
  );
};

export default WordCloudAndSentimentReport;
