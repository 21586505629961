export const questionTypes = {
  QUESTION_INSTRUCTION: "questionInstruction",
  IMAGE_INSTRUCTION: "imageInstruction",
  VIDEO_INSTRUCTION: "videoInstruction",
  SURVEY_BRIEF: "surveyBrief",
  NET_PROMOTOR_SCORE: "netPromotorScore",
  TEXT: "text",
  IMAGE_COMMENT: "imageComment",
  VIDEO_COMMENT: "videoComment",
  MULTI_CHOICE: "multiChoices",
  IMAGE_MULTI_CHOICE: "imageMultiChoices",
  VIDEO_MULTI_CHOICE: "videoMultiChoices",
  RANKING: "ranking",
  IMAGE_RANKING: "imageRanking",
  VIDEO_RANKING: "videoRanking",
  CHECK_BOX: "checkBoxes",
  IMAGE_CHECK_BOX: "imageCheckBoxes",
  VIDEO_CHECK_BOX: "videoCheckBoxes",
  STAR_RATING: "stars",
  IMAGE_RATING: "imageStarRatingChoices",
  VIDEO_RATING: "videoStarRatingChoices",
  DROPDOWN: "dropDown",
  TEXT_AB_TEST: "textABTest",
  IMAGE_AB_TEST: "imageABTest",
  VIDEO_AB_TEST: "videoABTest",
  ASSOCIATION: "association",
  IMAGE_ASSOCIATION: "imageAssociation",
  VIDEO_ASSOCIATION: "videoAssociation",
  LIKERT: "likert",
  SLIDER: "slider",
};

export const questionNotation = {
  questionInstruction: "Instructions",
  imageInstruction: "Image - Instructions",
  videoInstruction: "Video - Instructions",
  surveyBrief: "Survey Brief",
  netPromotorScore: "Net Promoter Score (NPS)",
  text: "Text",
  imageComment: "Image Comment",
  videoComment: "Video Comment",
  multiChoices: "Multiple Choice",
  imageMultiChoices: "Image Multi Choice",
  videoMultiChoices: "Video Multi Choice",
  ranking: "Ranking",
  imageRanking: "Image Ranking",
  videoRanking: "Video Ranking",
  checkBoxes: "Checkboxes",
  imageCheckBoxes: "Image Check Boxes",
  videoCheckBoxes: "Video Check Boxes",
  stars: "Star Rating",
  imageStarRatingChoices: "Image - Star Rating",
  videoStarRatingChoices: "Video - Star Rating",
  dropDown: "Dropdown",
  textABTest: "Text A/B Test",
  imageABTest: "Image - A/B Test",
  videoABTest: "Video - A/B Test",
  association: "Association",
  imageAssociation: "Image - Association",
  videoAssociation: "Video - Association",
  likert: "Likert Scale",
  slider: "Slider",
};
