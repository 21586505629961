import { Rate } from "antd";
import React from "react";
import styles from "./likert.module.css";
import { useMediaQuery } from "react-responsive";
import c from "classnames/bind";

const cx = c.bind(styles);

const likertStyles = {
  width: "100%",
  display: "flex",
  justifyContent: "space-evenly",
};

const Likert = ({ key, options, onChange = () => {}, value }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 395px)" });

  return (
    <div className="likert" key={key}>
      <Rate
        allowClear
        character={
          <span
            class="material-icons"
            style={{ fontSize: isTabletOrMobile ? 18 : 30 }}
          >
            circle
          </span>
        }
        count={options.likertRange}
        defaultValue={value}
        onChange={(val) => onChange(val)}
        style={likertStyles}
      />
      <div className={cx("likert-labels")}>
        <div className={cx("likert-label")} style={{ justifySelf: "start" }}>
          {options.likertStartLabel}
        </div>
        <div
          className={cx("likert-label")}
          style={{ justifySelf: "center", textAlign: "center" }}
        >
          {options.likertMiddleLabel}
        </div>
        <div
          className={cx("likert-label")}
          style={{ justifySelf: "end", textAlign: "end" }}
        >
          {options.likertEndLabel}
        </div>
      </div>
    </div>
  );
};

export default Likert;
