import { Select } from "antd";
import React from "react";
import "./dropdown.css";

const Dropdown = ({ key, options, value, onChange = () => {} }) => {
  const numbers = [...Array(options.range + 1).keys()].filter(
    (val) => val !== 0 && val >= 0
  );
  return (
    <div style={{ textAlign: "center" }} key={key}>
      <Select
        className="dropdown"
        style={{ minWidth: 75 }}
        defaultValue={value}
        onChange={(val) => onChange(val)}
      >
        {numbers.map((val) => (
          <Select.Option value={val}>{val}</Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default Dropdown;
