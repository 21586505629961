import React, { useState, useEffect } from "react";
import c from "classnames/bind";
import styles from "./survey.module.css";
import {
    LogoutOutlined
  } from "@ant-design/icons"

// {
//   data: {
//     referSurveyToken: Survey.referSurveyToken
//   }
// }

// frontend endpoint:
// <Route path='user/attemptSurvey/:referSurveyToken' component={AttemptSurvey} />

//Endpoint POST to user/:surveyId/addSurvey

const cx = c.bind(styles);

const PermDenied = ({onClose}) => {

  const modalStyles = {
    content: {
      top: "0",
      left: "0",
      width: "100vw",
      height: "100vh",
      border: 0,
      padding: 0,
      borderRadius: 0,
    },
  };

  return (
    <React.Fragment>
      {
        <div className={cx("survey-container")}>
            {!window.location.pathname.includes('/attemptSurvey') && <div
                    onClick={() => {
                      onClose();
                    }}
                    className={cx("quit-button")}
                    title="Quit Survey"
                  >
                    QUIT SURVEY <LogoutOutlined />
                  </div>
                  }
          <div className={cx("center")}>
            <div className={cx("question-box")}>
              <div
                style={{
                  flexGrow: 1,
                  display: "block",
                  alignItems: "center",
                  fontSize: 16,
                }}
              >
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Sorry!
                </p>
                <p style={{ margin: 0, textAlign: "center" }}>
                  Please grant the necessary permissions to begin survey. To attempt again, please refresh.
                </p>
              </div>
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default PermDenied;