import React from "react";
import { Form, Modal, Input, Select, Row, Col, Button } from "antd";
import { useMediaQuery } from "react-responsive";

const ConfigClientInfoModal = (props) => {
  const { clientInfo, handleCancel, onSubmit } = props;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const [form] = Form.useForm();

  const size = isTabletOrMobile ? "medium" : "large";
  const mode = !!clientInfo ? "edit" : "add";
  console.log(mode);
  const onEditClientInfo = () => {
    form.validateFields().then((values) => {
      onSubmit(mode, { ...clientInfo, ...values });
      handleCancel();
    });
  };

  const footer = (
    <div>
      <Button
        onClick={handleCancel}
        style={{ border: "1px solid #004479", marginRight: 10, width: 100 }}
      >
        Cancel
      </Button>
      <Button type="primary" onClick={onEditClientInfo} style={{ width: 100 }}>
        Save
      </Button>
    </div>
  );

  return (
    <Modal
      title=""
      closable={false}
      visible={true}
      footer={footer}
      onCancel={handleCancel}
    >
      <Form initialValues={clientInfo} form={form}>
        <Row gutter={6}>
          <Col xs={24} sm={12}>
            <Form.Item name="firstName">
              <Input
                placeholder="First Name"
                className="input-border"
                size={size}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name="lastName">
              <Input
                placeholder="Last Name"
                className="input-border"
                size={size}
              />
            </Form.Item>
          </Col>
        </Row>
        {mode === "add" && (
          <Form.Item name="password">
            <Input.Password
              placeholder="Password"
              className="input-border"
              size={size}
            />
          </Form.Item>
        )}
        <Row gutter={6}>
          <Col xs={24} sm={12}>
            <Form.Item name="companyName">
              <Input
                placeholder="Company Name"
                className="input-border"
                size={size}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name="gstNumber">
              <Input placeholder="GST" className="input-border" size={size} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col xs={24} sm={14}>
            <Form.Item name={["address", "otherData"]}>
              <Input
                placeholder="Address"
                className="input-border"
                size={size}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={10}>
            <Form.Item name={["address", "city"]}>
              <Input placeholder="City" className="input-border" size={size} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col xs={24} sm={14}>
            <Form.Item name={["address", "state"]}>
              <Input placeholder="State" className="input-border" size={size} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={10}>
            <Form.Item name={["address", "postalCode"]}>
              <Input
                placeholder="Postal Code"
                className="input-border"
                size={size}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col xs={24} sm={14}>
            <Form.Item name="email">
              <Input placeholder="Email" className="input-border" size={size} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={10}>
            <Form.Item name="mobileNumber">
              <Input
                placeholder="Phone Number"
                className="input-border"
                size={size}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col xs={24} sm={12}>
            <Form.Item name="accountTier">
              <Select
                placeholder="Plan Type"
                className="input-border"
                size={size}
              >
                <Select.Option value={0}>Basic</Select.Option>
                <Select.Option value={1}>Elite</Select.Option>
                <Select.Option value={2}>Platinum</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name="source">
              <Select placeholder="Source" className="input-border" size={size}>
                <Select.Option value="searc">Search</Select.Option>
                <Select.Option value="online-referral">
                  Online Referral
                </Select.Option>
                <Select.Option value="personal-referral">
                  Personal Referral
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ConfigClientInfoModal;
