import React, { useState, Fragment, useEffect } from "react";
import styles from "./NoteBox.module.css";
import c from "classnames/bind";
import { Menu, Dropdown, Input, Button, InputNumber } from "antd";
import { HighlightOutlined, MoreOutlined } from "@material-ui/icons";

const cx = c.bind(styles);

const convertMinuteSecondsString = (totalSeconds) => {
  // const totalSeconds = parseInt(date / 1000);
  const minutes = parseInt(totalSeconds / 60);
  const seconds = totalSeconds - minutes * 60;

  return (
    (minutes < 10 ? "0" + minutes : minutes) +
    ":" +
    (seconds < 10 ? "0" + seconds : seconds)
  );
};

const convertIntoSeconds = (minute, seconds) => {
  return parseInt(minute) * 60 + parseInt(seconds);
};

const NoteBox = ({
  index,
  start,
  end,
  noteText,
  isReel,
  handleNoteChange,
  updateReel,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [startMinutes, setStartMinutes] = useState(
    convertMinuteSecondsString(start).split(":")[0]
  );
  const [startSeconds, setStartSeconds] = useState(
    convertMinuteSecondsString(start).split(":")[1]
  );
  const [endMinutes, setEndMinutes] = useState(
    convertMinuteSecondsString(end).split(":")[0]
  );
  const [endSeconds, setEndSeconds] = useState(
    convertMinuteSecondsString(end).split(":")[1]
  );
  const [text, setText] = useState(noteText);

  useEffect(() => {
    setStartMinutes(convertMinuteSecondsString(start).split(":")[0]);
    setStartSeconds(convertMinuteSecondsString(start).split(":")[1]);
    setEndMinutes(convertMinuteSecondsString(end).split(":")[0]);
    setEndSeconds(convertMinuteSecondsString(end).split(":")[1]);
    setText(noteText);
  }, [editMode]);

  const menu = () => {
    return (
      <Menu>
        <Menu.Item onClick={() => setEditMode(true)}>Edit</Menu.Item>
        <Menu.Item>Copy Link</Menu.Item>
        <Menu.Item>Add Reel To Results</Menu.Item>
        <Menu.Item>Delete</Menu.Item>
      </Menu>
    );
  };

  return (
    <div
      style={
        isReel
          ? {
              backgroundColor: "#EEF9FA",
              border: "2px solid rgb(136, 205, 213)",
            }
          : {}
      }
      className={cx("note-box")}
    >
      <div style={{ display: "flex", gap: "10px" }}>
        <div
          className={cx("note-box-header")}
          style={{ display: "flex", fontSize: 16 }}
        >
          <div className={cx("time")}>
            {editMode ? (
              <Fragment>
                <InputNumber
                  size="small"
                  min={0}
                  max={59}
                  width="50px"
                  style={{ border: "none", width: 50 }}
                  value={startMinutes}
                  onChange={(value) => setStartMinutes(value)}
                />{" "}
                {":"}{" "}
                <InputNumber
                  size="small"
                  min={0}
                  max={59}
                  style={{ border: "none", width: 50 }}
                  value={startSeconds}
                  onChange={(value) => setStartSeconds(value)}
                />
                {isReel && (
                  <Fragment>
                    {"-"}
                    <InputNumber
                      size="small"
                      min={startMinutes + 1}
                      max={59}
                      width="50px"
                      style={{ border: "none", width: 50 }}
                      value={endMinutes}
                      onChange={(value) => setEndMinutes(value)}
                    />{" "}
                    {":"}{" "}
                    <InputNumber
                      size="small"
                      min={startSeconds}
                      max={59}
                      style={{ border: "none", width: 50 }}
                      value={endSeconds}
                      onChange={(value) => setEndSeconds(value)}
                    />
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <Fragment>
                {convertMinuteSecondsString(start)}
                {isReel && "- " + convertMinuteSecondsString(end)}
              </Fragment>
            )}
          </div>
        </div>
        <div>
          {editMode ? (
            <div className={cx("edit-buttons")} style={{ marginLeft: "12px" }}>
              <Button
                style={{ borderColor: "black", color: "black" }}
                size="small"
                onClick={() => {
                  setEditMode(false);
                }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  backgroundColor: "black",
                  color: "white",
                  borderColor: "black",
                }}
                size="small"
                onClick={() => {
                  handleNoteChange(
                    convertIntoSeconds(startMinutes, startSeconds),
                    convertIntoSeconds(endMinutes, endSeconds),
                    text
                  );
                  setEditMode(false);
                }}
              >
                Save
              </Button>
            </div>
          ) : (
            <div
              onClick={() => setEditMode(true)}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              Edit
            </div>
          )}
        </div>
      </div>
      {editMode ? (
        <Input
          size="small"
          style={{ border: "none", margin: "5px 0" }}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      ) : (
        <div>{noteText}</div>
      )}
    </div>
  );
};

export default NoteBox;
