import React from "react";
import { Form, Input, Button, Spin, Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { styled } from "@linaria/react";
import { Link, Route, useHistory } from "react-router-dom";
import axios, { Routes } from "../services/API";
import handleNetworkErrors from "./client/handleNetworkErrors";
import { useState } from "react";
import { signIn } from "../actions/admin";
import { useDispatch } from "react-redux";
import { notify } from "../utils";
import { useMediaQuery } from "react-responsive";
import { Fragment } from "react";
import bgClient from "../assets/client-login-1.png";
import bgUser from "../assets/user-login-bg.png";
import styles from "./Signin.module.css";
import c from "classnames/bind";

const cx = c.bind(styles);

export const Title = styled.div`
  text-align: center;
  font-size: 20px;
  margin: 10px 0 10px;
  letter-spacing: 2px;
`;

export const BackgroundImage = styled.div`
  background-url: (../assets/client-login-1.png);
`;

const FormContent = ({ isClient, onFinish, role }) => {
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 350px)" });
  const roleColor = isClient ? "#71CFD7" : "#FF9480";
  return (
    <div className={cx("form-component")}>
      <div
        style={{
          backgroundColor: isClient ? "rgb(151, 207, 212)" : "#F69380",
        }}
        className={cx("form-section-1")}
      >
        <div>
          <div className={cx("site-title")}>USERQUAL</div>
          <div className={cx("login-title")}>
            {isClient ? "Client " : "User "} Login
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <img className={cx("bg-image")} src={isClient ? bgClient : bgUser} />
          <div
            onClick={() =>
              history.push(`/signin/${isClient ? "user" : "client"}`)
            }
            style={{
              backgroundColor: isClient ? "rgb(130, 194, 197)" : "#E98579",
            }}
            className={cx("go-to-login-button")}
          >
            GOTO {isClient ? "USER" : "CLIENT"} LOGIN
          </div>
        </div>
      </div>
      <Form
        name="normal_login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className={cx("form-section-2")}
      >
        <Title>Welcome Back</Title>
        <div style={{ marginBottom: 10 }}>
          <b>Email or Phone</b>
        </div>
        <Form.Item
          name="user"
          rules={[
            {
              required: true,
              message: "Please input either your Email or your Mobile number!",
            },
          ]}
        >
          <Input
            placeholder="Email or Mobile Number"
            style={{ borderRadius: 10, height: 45, borderColor: roleColor }}
          />
        </Form.Item>
        <div style={{ marginBottom: 10 }}>
          <b>Password</b>
        </div>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your Password!" }]}
        >
          <Input.Password
            type="password"
            placeholder="Password"
            style={{ borderRadius: 10, height: 45, borderColor: roleColor }}
          />
        </Form.Item>
        {role !== "admin" && (
          <Link
            className={cx("forget-password")}
            to={`/forgotPassword/${role}/email`}
          >
            <b>Forgot Password ?</b>
          </Link>
        )}
        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            className={cx("button")}
            style={{
              background: roleColor,
              borderRadius: 10,
              border: roleColor,
            }}
            block
          >
            SIGN IN
          </Button>
        </Form.Item>
        {role !== "admin" && (
          <Fragment>
            <div style={{ textAlign: "center", margin: "10px 0" }}>OR</div>
            <Button
              type="primary"
              size="large"
              className={cx("button")}
              style={{
                background: roleColor,
                borderRadius: 10,
                border: roleColor,
                width: "100%",
              }}
              onClick={() => history.push(`/signup/${role}/email`)}
            >
              NEW ACCOUNT? REGISTER HERE
            </Button>
          </Fragment>
        )}
      </Form>
    </div>
  );
};

const Signin = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const authClient = async ({ user, password }) => {
    console.log("password", password);
    let data;
    if (user.includes("@")) {
      data = {
        email: user.toLowerCase(),
        password,
      };
    } else {
      data = {
        mobileNumber: parseInt(user),
        password,
      };
    }

    try {
      setLoading(true);
      const res = await axios.post(Routes.client.signin().url, { data });
      if (res.data && res.data.token && res.data.client) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("client", res.data.client._id);
        setLoading(false);
        history.push("/client");
      }
    } catch (err) {
      handleNetworkErrors(err);
      setLoading(false);
    }
  };

  const authUser = async ({ user, password }) => {
    let data;
    if (user.includes("@")) {
      data = {
        email: user.toLowerCase(),
        password,
      };
    } else {
      data = {
        mobileNumber: parseInt(user),
        password,
      };
    }
    try {
      setLoading(true);
      const res = await axios[Routes.user.signin().method](
        Routes.user.signin().url,
        { data }
      );
      if (res.data && res.data.token && res.data.user) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", res.data.user._id);
        setLoading(false);
        history.push("/user/form");
      }
    } catch (err) {
      handleNetworkErrors(err);
      setLoading(false);
    }
  };

  const authAdmin = async ({ user, password }) => {
    try {
      setLoading(true);
      dispatch(signIn(user.toLowerCase(), password))
        .catch((ex) => notify("error", "Error", ex))
        .finally(() => {
          setLoading(false);
          history.push("/admin/registration-questions");
        });
    } catch (err) {
      handleNetworkErrors(err);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Spin />}
      <Route
        exact
        path="/signin/client"
        component={(props) => {
          const isClient = true;
          const role = "client";
          const roleColor = isClient ? "#71CFD7" : "#FF9480";
          return (
            <div
              style={{
                backgroundColor: isClient ? "#C3E3E7" : "#FCE2DA",
              }}
              className={cx("main-page")}
              isClient={isClient}
            >
              <div>
                <FormContent
                  isClient={isClient}
                  onFinish={authClient}
                  role={role}
                />
              </div>
            </div>
          );
        }}
      />
      <Route
        exact
        path="/signin/user"
        component={(props) => {
          const isClient = false;
          const role = "user";
          const roleColor = isClient ? "#71CFD7" : "#FF9480";
          return (
            <div
              style={{
                backgroundColor: isClient ? "#C3E3E7" : "#FCE2DA",
              }}
              className={cx("main-page")}
              isClient={isClient}
            >
              <div>
                <FormContent
                  isClient={isClient}
                  onFinish={authUser}
                  role={role}
                />
              </div>
            </div>
          );
        }}
      />
      <Route
        path="/signin/admin"
        component={(props) => (
          <div
            style={{
              backgroundColor: props.isClient ? "#C3E3E7" : "#FCE2DA",
            }}
          >
            <FormContent onFinish={authAdmin} role="admin" />
          </div>
        )}
      />
    </>
  );
};

export default Signin;
