import React from "react";
import { Button, Select, Badge } from "antd";
import { RightOutlined } from "@ant-design/icons";
import "./header-buttons-style.css";

const { Option } = Select;

const valueMap = ["setup", "questions", "criteria", "participants", "publish"];

const HeaderButtons = (props) => {
  const {
    step,
    setStep,
    setup,
    questions,
    criteria,
    participants,
    publish,
    disableQuestionsPage = false,
  } = props;

  const handleSelectChange = (value) => {
    switch (value) {
      case "setup":
        setStep(1);
        break;
      case "questions":
        setStep(2);
        break;
      case "criteria":
        setStep(3);
        break;
      case "participants":
        setStep(4);
        break;
      case "publish":
        setStep(5);
        break;
      default:
        break;
    }
  };

  const getCount = () => {
    if (step === 1) return setup;
    else if (step === 2) return questions;
    else if (step === 3) return criteria;
    else if (step === 4) return participants;
    else if (step === 5) return publish;
  };

  return (
    <div className="header-buttons-container">
      <Badge
        style={{ backgroundColor: "#FF9480", color: "#000000" }}
        className="header-buttons-span-select"
        count={getCount()}
      >
        <Select
          defaultValue="setup"
          className="header-select"
          onChange={handleSelectChange}
          value={valueMap[step - 1]}
        >
          <Option value="setup">Setup</Option>
          <Option disabled={disableQuestionsPage} value="questions">
            Questions
          </Option>
          <Option value="criteria">Criteria</Option>
          <Option value="participants">Participants</Option>
          <Option value="publish">Publish</Option>
        </Select>
      </Badge>

      <Badge
        style={{
          color: "#FF9480",
          backgroundColor: "#FFFFFF",
          borderColor: "#404040",
        }}
        className="header-buttons-span"
        count={setup}
      >
        <Button
          className="header-buttons"
          style={step === 1 ? { backgroundColor: "#8EDAD0" } : {}}
          onClick={() => setStep(1)}
        >
          Setup
        </Button>
      </Badge>

      <div
        className="header-arrow"
        // style={step >= 2 ? { backgroundColor: "#8EDAD0" } : {}}
      />

      <Badge
        style={{
          color: "#FF9480",
          backgroundColor: "#FFFFFF",
          borderColor: "#404040",
        }}
        className="header-buttons-span"
        count={questions}
      >
        <Button
          className="header-buttons"
          style={step === 2 ? { backgroundColor: "#8EDAD0" } : {}}
          onClick={() => setStep(2)}
          disabled={disableQuestionsPage}
        >
          Questions
        </Button>
      </Badge>

      <div
        className="header-arrow"
        // style={step >= 3 ? { backgroundColor: "#8EDAD0" } : {}}
      />

      <Badge
        style={{
          color: "#FF9480",
          backgroundColor: "#FFFFFF",
          borderColor: "#404040",
        }}
        className="header-buttons-span"
        count={criteria}
      >
        <Button
          className="header-buttons"
          style={step === 3 ? { backgroundColor: "#8EDAD0" } : {}}
          onClick={() => setStep(3)}
        >
          Criteria
        </Button>
      </Badge>

      <div
        className="header-arrow"
        // style={step >= 4 ? { backgroundColor: "#8EDAD0" } : {}}
      />

      <Badge
        style={{
          color: "#FF9480",
          backgroundColor: "#FFFFFF",
          borderColor: "#404040",
        }}
        className="header-buttons-span"
        count={participants}
      >
        <Button
          className="header-buttons"
          style={step === 4 ? { backgroundColor: "#8EDAD0" } : {}}
          onClick={() => setStep(4)}
        >
          Participants
        </Button>
      </Badge>

      <div
        className="header-arrow"
        // style={step >= 2 ? { backgroundColor: "#8EDAD0" } : {}}
      />

      <Badge
        style={{
          color: "#FF9480",
          backgroundColor: "#FFFFFF",
          borderColor: "#404040",
        }}
        className="header-buttons-span"
        count={publish}
      >
        <Button
          className="header-buttons"
          style={step === 5 ? { backgroundColor: "#8EDAD0" } : {}}
          onClick={() => setStep(5)}
        >
          Publish
        </Button>
      </Badge>
    </div>
  );
};

export default HeaderButtons;
