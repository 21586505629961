import React, { useEffect } from "react";
import ResponsiveTable from "../../../components/responsive-table/ResponsiveTable";
import ProjectPageLayout, {
  Content,
  InnerContainer,
} from "../../../components/page-layout/PageLayout";
import Confused, {
  ExplainerVideo,
} from "../../../components/user/confused/Confused";
import { userPages } from "../../../constants";
import Sidenav from "../../../components/user/sidenav/Sidenav";
import Topbar from "../../../components/topbar/Topbar";
import moment from "moment";
import "./history.css";
import { Button, Col, Row } from "antd";

const History = ({ usedForClient, profile }) => {
  const data = (profile && profile.transactions) || [];
  const columns = [
    {
      title: "No.",
      key: "no",
      align: "center",
      width: "5%",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Survey Name",
      key: "survey_name",
      align: "left",
      dataIndex: "surveyName",
      width: "30%",
      sorter: (a, b) => a.surveyName.localeCompare(b.surveyName),
    },
    {
      title: "Date Completed",
      key: "date completed",
      align: "left",
      dataIndex: "timeStamp",
      render: (text) => moment(text).format("DD/MM/YYYY"),
      width: "15%",
      sorter: (a, b) =>
        moment(a["timeStamp"]).unix() - moment(b["timeStamp"]).unix(),
    },
    {
      title: "Paid",
      key: "paid",
      align: "center",
      dataIndex: "paid",
      width: "10%",
    },
    {
      title: "Payment Date",
      key: "payment_date",
      align: "center",
      dataIndex: "payment_date",
      width: "15%",
    },
    {
      title: "Amount",
      key: "amount",
      align: "center",
      dataIndex: "amount",
      width: "10%",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Payment Method",
      key: "payment_method",
      align: "center",
      dataIndex: "payment_method",
      width: "15%",
    },
  ];

  useEffect(() => {
    document.body.setAttribute("data-new-theme", true);

    return () => {
      document.body.removeAttribute("data-new-theme");
    };
  }, []);

  return (
    <React.Fragment>
      {!usedForClient && (
        <>
          <Topbar>
            <Sidenav drawer selectedItem={userPages.HISTORY} />
          </Topbar>
          <Sidenav selectedItem={userPages.HISTORY} />
        </>
      )}
      <ProjectPageLayout>
        <Content contentClass="history-page-content">
          <InnerContainer
            style={{
              padding: 0,
            }}
          >
            <Row gutter={[8, 20]}>
              <Col span={24} style={{ padding: 20, paddingTop: 20 }}>
                <Row gutter={8} justify="space-between">
                  <Col xs={24} sm={18}>
                    <h2 style={{ margin: 0 }}>Survey history</h2>
                    <div>
                      {usedForClient
                        ? "Account of all the spends from the published survey"
                        : "Account of all the earnings from the opted survey"}
                    </div>
                  </Col>
                  <Col>
                    <ExplainerVideo />
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ padding: 20 }}>
                <Row gutter={[32, 8]}>
                  <Col xs={24} sm={8}>
                    <div
                      style={{
                        padding: 12,
                        backgroundColor: "#f1f8f9",
                        borderRadius: 4,
                      }}
                    >
                      <div style={{ color: "#7cb342", fontSize: 20 }}>₹ 0</div>
                      <div style={{ color: "#689f38" }}>
                        Total amount credited
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={8}>
                    <div
                      style={{
                        padding: 12,
                        backgroundColor: "#eeeeee",
                        borderRadius: 4,
                      }}
                    >
                      <div style={{ color: "#424242", fontSize: 20 }}>₹ 0</div>
                      <div style={{ color: "#616161" }}>Amount pending</div>
                    </div>
                  </Col>
                  <Col xs={24} sm={8}>
                    <div
                      style={{
                        padding: 12,
                        backgroundColor: "#eeeeee",
                        borderRadius: 4,
                      }}
                    >
                      <div style={{ color: "#424242", fontSize: 20 }}>0</div>
                      <div style={{ color: "#616161" }}>Survey completed</div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ padding: 0 }}>
                <ResponsiveTable
                  columns={columns}
                  dataSource={data}
                  className="history-table"
                />
              </Col>
            </Row>
          </InnerContainer>
        </Content>
      </ProjectPageLayout>
    </React.Fragment>
  );
};

export default History;
