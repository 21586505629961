import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import User from "./pages/user/User";
import Client from "./pages/client/Client";
import Admin from "./pages/admin/Admin";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import { useState } from "react";
import { useEffect } from "react";
import Verification from "./pages/Verification";
import { getRoleByToken } from "./utils";
import Home from "./pages/Home";
import BecomeTester from "./pages/BecomeTester";
import PrivacyAndTermsAndConditions from "./pages/PrivacyAndTermsAndConditions";
import AddSurvey from "./pages/user/AddSurvey";
import AttemptSurvey from "./pages/user/AttemptSurvey";

function Routes() {
  const [role, setRole] = useState(
    getRoleByToken(localStorage.getItem("token"))
  );
  const location = useLocation();

  // eslint-disable-next-line
  useEffect(() => {
    if (role !== getRoleByToken(localStorage.getItem("token"))) {
      setRole(getRoleByToken(localStorage.getItem("token")));
    }
  });

  return (
    <>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/privacy-and-terms-and-conditions">
        <PrivacyAndTermsAndConditions />
      </Route>
      <Route exact path="/tester">
        <BecomeTester />
      </Route>
      <Route path="/signin" component={Signin} />
      <Route path="/signup/:role/:mobile" component={Signup} />
      <Route
        path="/forgotPassword/:role/:mobile"
        component={() => <Signup forgotPassword={true} />}
      />
      <Route path="/verification/:token" component={Verification} />
      {role === null && location.pathname.includes("/addSurvey") && (
        <Redirect to="/signin/user" />
      )}
      {(role === null || role === "user") && location.pathname.includes("/attemptSurvey") && (
        <Route path="/attemptSurvey/:referSurveyToken" component={AttemptSurvey} />
        )}
      {role === null &&
        !location.pathname.includes("/tester") &&
        !location.pathname.includes("/privacy-and-terms-and-conditions") &&
        !location.pathname.includes("/verification") &&
        !location.pathname.includes("/forgotPassword") &&
        !location.pathname.includes("/signup") &&
        !location.pathname.includes("/signin") &&
        !location.pathname.includes("/attemptSurvey") && <Redirect to="/" />}
       
      {role === "user" && !location.pathname.includes("/attemptSurvey") && (
          <>
            <Route path="/user" component={User} />
            <Redirect to="/user/forms" />
          </>
        )}
        {role === "user" && (location.pathname.includes("/addSurvey")) && (
          <Route
            path="/user/addSurvey/:referSurveyToken"
            component={AddSurvey}
          />
        )}
      {role === "client" && (
        <>
          <Route path="/client" component={Client} />
          <Redirect to="/client" />
        </>
      )}
      {role === "admin" && (
        <>
          <Route path="/admin" component={Admin} />
          {!location.pathname.includes("/admin") && (
            <Redirect to="/admin/registration-questions" />
          )}
        </>
      )}
    </>
  );
}

export default Routes;
