import React, { useState } from "react";
import styles from "./videoStarRatingChoices.module.css";
import c from "classnames/bind";
import {
  CheckOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Input, Tooltip, Upload, message, Button } from "antd";
import { useMediaQuery } from "react-responsive";
import axios, { Routes } from "../../../../../services/API";
import { GetFilename } from "../../../../../constants";

const cx = c.bind(styles);

const VideoStarRatingChoices = (props) => {
  // Props
  const {
    dragHandleProps,
    choiceNumber,
    choice,
    isFilled,
    addChoice,
    removeChoice,
    totalChoices,
    setLabel,
    checkBox,
  } = props;

  const [uploading, setUploading] = useState(false);
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });
  const uploadProps = {
    accept: ".mp4,.webm,.ogg",
    customRequest: async function ({ file, onError }) {
      const userId = localStorage.getItem("client");
      const { name, type } = file;
      setUploading(true);
      const { data } = await axios({
        ...Routes.client.uploadFile(userId),
        data: { data: { name, type } },
      });
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", data.signedRequest);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            choice.videoData = data.url;
            setLabel({
              videoLabel: choice.videoLabel,
              videoData: data.url,
            });
            message.success(`${file.name} file uploaded successfully`);
            setUploading(false);
          } else {
            onError();
            message.error(`${file.name} file upload failed.`);
            setUploading(false);
          }
        }
      };
      xhr.send(file);
    },
  };

  return (
    <div className={cx("grid-container")} style={{ marginBottom: 5 }}>
      <div
        style={{
          margin: "auto",
          width: 20,
          height: 20,
          textAlign: "center",
        }}
      >
        <div className={cx("choiceNumber")}>
          <span className="choice-numberCircle">{choiceNumber}</span>
          <MenuOutlined className={cx("menu-outlined")} {...dragHandleProps} />
        </div>
      </div>
      <div className={cx("fields")}>
        <div className={cx("inputFields")}>
          <div>
            <Input
              size="large"
              placeholder="Video Label"
              style={{ borderRadius: 3 }}
              value={choice.videoLabel}
              onChange={(event) =>
                setLabel({
                  videoLabel: event.target.value,
                  videoData: choice.videoData,
                })
              }
              suffix={
                isFilled.videoLabel ? (
                  <CheckOutlined style={{ color: "green" }} />
                ) : (
                  <Tooltip title="You need to fill this option!">
                    <InfoCircleOutlined style={{ color: "#ff9480" }} />
                  </Tooltip>
                )
              }
              className="input-border"
            />
          </div>
          <div>
            <Input
              size="large"
              placeholder="No Video Chosen"
              style={{ borderRadius: 3 }}
              value={GetFilename(choice.videoData, 10)}
              disabled
              onChange={(event) =>
                setLabel({
                  videoLabel: choice.videoLabel,
                  videoData: event.target.value,
                })
              }
              suffix={
                isFilled.videoData ? (
                  <CheckOutlined style={{ color: "green" }} />
                ) : (
                  <Tooltip title="You need to fill this option!">
                    <InfoCircleOutlined style={{ color: "#ff9480" }} />
                  </Tooltip>
                )
              }
              className="input-border"
            />
          </div>
        </div>
        <div className={cx("controlFields")}>
          <Upload
            className={cx("uploadButton")}
            {...uploadProps}
            showUploadList={false}
          >
            <Button style={{ height: "100%" }}>
              Upload Video{uploading ? <LoadingOutlined /> : null}
            </Button>
          </Upload>
          {
            <div className={cx("gridInput")} style={{ margin: "auto 0px" }}>
              <PlusCircleOutlined
                onClick={() => addChoice(choiceNumber)}
                className="action-icon"
              />
              {totalChoices > 1 && (
                <MinusCircleOutlined
                  className="action-icon"
                  onClick={() => removeChoice(choiceNumber)}
                />
              )}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default VideoStarRatingChoices;
