import React, { useState } from "react";
import "./Header.css";
import { Button, Col, Row } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const menuItems = [{ link: "/", text: "FAQ" }];

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  return (
    <>
      <Row
        justify="space-between"
        className={"header-container desktop-header"}
      >
        <Col className={"app-title"}>
          <Link to="/">UserQual</Link>
        </Col>
        <Col>
          {menuItems.map((record) => {
            return (
              <span className={"desktop-menu-item"} key={record.text}>
                <Link to={record.link}>{record.text}</Link>
              </span>
            );
          })}
          <Link to="/signin/client">
            <Button className="login-button">SIGN IN</Button>
          </Link>
        </Col>
      </Row>
      <Row justify="space-between" className={"header-container mobile-header"}>
        <Col xs={12}>
          <Button
            className="menu-button"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <MenuOutlined />
            Menu
          </Button>
        </Col>
        <Col xs={12} className="login-button-container">
          <Link to="/signin/client">
            <Button className="login-button">SIGN IN</Button>
          </Link>
        </Col>
        {isMobileMenuOpen ? (
          <Col xs={24} className={"mobile-toggle-menu nav-bar"}>
            {menuItems.map((record) => {
              return (
                <Link to={record.link} key={record.text}>
                  <div className={"menu-item"}>{record.text}</div>
                </Link>
              );
            })}
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default Header;
