import React from "react";

const PrivacyAndTermsAndConditions = () => {
  return (
    <div>
      <p>
        <strong>UserQual Privacy Policy</strong>
      </p>
      <p>
        At UserQual, your privacy is important to us. This Privacy Policy
        describes the personal data that UserQual and group companies
        (collectively referred to as "the Company," &ldquo;we," "our," and "us")
        processes. This Privacy Policy also explains how we process personal
        data and for what purposes.
      </p>
      <p>
        <strong>Summary of Key Points</strong>
      </p>
      <ul>
        <li>
          This Privacy Policy explains when we process Personal Data for our
          legitimate business interests. For more information on how to access
          and control your data, please see the "
          <strong>How you can control your data</strong>" section.
        </li>
        <li>
          We use cookies and other technologies to track the use of our websites
          and apps. To learn about opportunities to choose not to allow cookies,
          please see our Cookie Policy&nbsp;
          <a href="https://www.usertesting.com/cookie-policy">
            <strong>here</strong>
          </a>
          .
        </li>
        <li>We do not sell your data.</li>
        <li>
          We transfer data across borders, including to and from the US and
          other locations. Where applicable, we abide by the unmodified EU
          Standard Contractual Clauses for Processors (as defined in the GDPR)
          pursuant to the European Commission Decision of 5 February 2010. We
          also comply with, and are self-certified under, the EU-U.S. Privacy
          Shield Framework and Swiss-U.S.
        </li>
        <li>
          We comply with the General Data Protection Regulation (the&nbsp;
          <strong>"GDPR"</strong>) and other applicable European Union (
          <strong>"EU"</strong>) privacy laws. If you are a resident of the
          European Economic Area (<strong>"EEA"</strong>), please see the&nbsp;
          <a href="https://www.usertesting.com/gdpr-policy">
            <strong>GDPR Notice&nbsp;</strong>
          </a>
          that applies to you.
        </li>
        <li>
          We comply with the California Consumer Privacy Act (the&nbsp;
          <strong>"CCPA"</strong>). If you are a resident of California, please
          see the that&nbsp;
          <a href="https://www.usertesting.com/ccpa-policy">
            <strong>CCPA Notice</strong>
          </a>
          &nbsp;applies to you.
        </li>
      </ul>
      <p>
        <strong>Who we are</strong>
      </p>
      <p>
        We provide websites (<strong>"Sites"</strong>) where visitors and other
        members of our community (<strong>"Visitors"</strong>) may learn about
        our offerings, view Site materials, and/or access our platform (
        <strong>"Platform"</strong>) and related services (
        <strong>"Services"</strong>) that enable (i) businesses, including
        current and prospective customers (collectively&nbsp;
        <strong>"Customers"</strong>) to solicit feedback (
        <strong>"Tests"</strong>) on any brand, design, content, or current or
        potential offering and (ii) individuals taking part in such Tests (
        <strong>"Participants"</strong>) to perform and record Tests. In
        connection with the Site, Platform, and Services, UserQual may collect,
        record, and analyze information about Visitors, including its Customers
        and Participants, which may include individually identifiable
        information that would allow UserQual to determine the actual identity
        of or contact information of a specific individual, billing information,
        account settings, and other data (<strong>"Personal Data"</strong>).
      </p>
      <p>
        <strong>Who is my data controller?</strong>
      </p>
      <p>
        Certain data protection and privacy laws, such as the GDPR,
        differentiate between "controllers" and "processors" of personal
        information. A "controller" decides why and how to process personal
        information. A "processor" processes personal information on behalf of a
        controller based on the controller's instructions.
      </p>
      <p>
        For Visitors, the Company is the controller, to the extent that we
        collect or you provide your Personal Data.
      </p>
      <p>
        For Participants, the Company is the controller of the personal
        information collected from you by the Company, except for the
        information collected by our Customers through a Test.
      </p>
      <p>
        For Customers, you are the controller of the data we collect from you
        for us to provide you with access to our Platform or Services.
      </p>
      <p>
        <strong>How we collect and use your data</strong>
      </p>
      <p>
        How we collect Personal Data depends on how and why you use our Sites,
        our Platform, and/or our Services.
      </p>
      <p>
        We collect Personal Data directly when you submit it to us, as a
        Visitor, Participant, or Customer, as well as indirectly, such as
        through the use of automated technologies or from third parties. To help
        keep our databases current and to provide you the most relevant content
        and experiences, we may combine information provided by you with
        information from third-party sources, in accordance with applicable
        laws. For example, the size, industry, and other information about the
        company you work for (where you have provided company name) may be
        obtained from sources including professional networking sites and
        information service providers. We provide more information about how we
        collect Personal Data below.
      </p>
      <p>
        UserQual uses your Personal Data for certain legitimate business
        purposes, including the following:
      </p>
      <ul>
        <li>
          to provide you access to and use of the Platform and Services,
          including registering as a Customer or a Participant,
        </li>
        <li>
          to deliver Services to our Customers, including Recordings created by
          Participants,
        </li>
        <li>
          to improve and enhance your experience with the Platform and Services,
          including the content and general administration of the Platform and
          Services,
        </li>
        <li>
          to retain records as may be required for tax, legal, and financial
          purposes,
        </li>
        <li>
          to understand how you access, use and interact with the Services in
          order to provide technical functionality, develop new products and
          services, and analyze your use of the Services,
        </li>
        <li>to communicate with you,</li>
        <li>
          to provide you with customer support in connection with your use of
          the Services,
        </li>
        <li>to detect fraud, illegal activities, or security breaches,</li>
        <li>to receive and make payments, and</li>
        <li>
          to provide information to regulatory bodies when legally required.
        </li>
      </ul>
      <p>
        In the sections below, we are more specific about the purposes for which
        we use each category of data.
      </p>
      <p>
        <strong>
          Data provided by or collected from our Visitors ("Visitor Data")
        </strong>
      </p>
      <p>
        When Visitors use or browse one of our Sites, we collect their IP
        addresses and usage information such as page views, clicks, and browser
        type.
      </p>
      <p>
        If a Visitor submits a request for a trial on our Site, chats with us
        for support, requests to watch a webinar, subscribes to our blog or
        other news, submits a request through our Contact Us form, or requests
        to download a whitepaper or other content, we may also collect the
        Visitor&rsquo;s: name, title, email, phone number, company name, country
        and IP address.
      </p>
      <p>
        We use this Visitor Data to provide the Visitor with the information or
        materials requested, including marketing materials, newsletters and
        other related content, perform analytics on how the Site, Platform, and
        Services are used, improve the use of the Site, Platform, or Services,
        and for other legitimate business purposes.
      </p>
      <p>
        <strong>
          Data provided by or collected from our Participants&nbsp;("Participant
          Data")
        </strong>
      </p>
      <p>
        <strong>
          <em>Account Data</em>
        </strong>
      </p>
      <p>
        When a Participant creates an account with UserQual, we will collect the
        Participant&rsquo;s name, username, and password, zip code, and email
        address.
      </p>
      <p>
        We may also ask each Participant to provide us with additional
        information necessary or helpful for UserQual to be able to determine
        which Tests are best directed to that Participant. Examples of
        information we may collect are: birth year; gender; household income
        range; country; web expertise; presence of children (including gender
        and birth years); employment status, industry; company size; job role
        seniority; gaming genres; web browsers; social networks; languages
        spoken; race, ethnicity, sexual orientation, and other sensitive
        personal data, which may be provided on a voluntary basis and only
        collected as permitted by applicable law; devices owned (e.g. computer,
        smartphone, tablet); and computer operating system.
      </p>
      <p>
        UserQual uses Participant Data to provide and improve its Services, pay
        Participants, provide information on how to use our Platform and
        Services to our Participants, and for other legitimate business
        purposes.
      </p>
      <p>
        <strong>
          <em>Recordings</em>
        </strong>
      </p>
      <p>
        As a Participant takes part in a Test, we make a recording of the
        Participant&rsquo;s activities, which may include recordings of the
        Participant&rsquo;s voice, screen content, face recordings, screen
        recordings, browser content, screen interactions including mouse
        movement and clicking, text input, device configurations, and any
        background audio or video content (<strong>"Recordings"</strong>).
      </p>
      <p>
        Intellectual property rights in the Recordings, which may include
        Personal Data and face Recordings, are assigned by Participants to
        UserQual under the terms of the Participant Terms of Service in
        consideration for Participants&rsquo; use of the Platform and Services.
        Face Recordings are owned by and controlled by the Customer or UserQual,
        as applicable.
      </p>
      <p>
        UserQual uses Recordings to provide Services to Customers, to market its
        products and services, to protect against fraudulent or illegal
        activity, to improve the UserQual Platform and Services and for other
        legitimate business purposes.
      </p>
      <p>
        <strong>
          <em>Privacy for Minors</em>
        </strong>
      </p>
      <p>
        From time to time, UserQual&rsquo;s Customers seek feedback from minor
        children (ages 17 and younger) using the Platform for Tests specifically
        designed for children. To facilitate those Tests, we require that a
        parent or legal guardian consent prior to a child engaging in a Test
        through their parent&rsquo;s or legal guardian&rsquo;s Participant
        account. Our Customers are required to ensure that Tests which are
        specifically designed for children are for age-appropriate products and
        services and the Recording is limited to only captures the child&rsquo;s
        voice. No other information is to be collected about the child by the
        Customer. The child&rsquo;s responses may be used for the same purposes
        and subject to the same obligations, restrictions and conditions as any
        other similarly-situated Participant response. By accepting and
        authorizing a Test for a child, the parent or legal guardian (as
        applicable) is consenting to such child&rsquo;s participation in the
        Tests through the Platform for the benefit of the Customer. We may
        require that Customers sign additional terms for testing with children.
        In the event a parent has a reason to believe that their child is
        participating in a Test administered via the Platform without
        authorization, or wishes to exercise any of the parental rights set
        forth in this section or applicable law, such parent may contact
        UserQual at hello@userqual.com
      </p>
      <p>
        <strong>Data provided by our Customers ("Customer Data")</strong>
      </p>
      <p>
        During a Customer&rsquo;s use of the Platform and Services,
        Customer&rsquo;s employees are asked to provide information such as name
        and contact information, including email address, address, telephone, or
        other relevant Personal Data.
      </p>
      <p>
        Customer Data is used by UserQual to identify each Customer and provide
        them with access to the Platform and Services, to bill Customers, and to
        meet UserQual&rsquo;s contractual obligations. We also use Customer Data
        to improve our Platform and Services and to provide Customers with
        notices about improvements and best practices in using the Platform and
        Services, as well as other legitimate business purposes.
      </p>
      <p>
        It is the Customer&rsquo;s responsibility to ensure that collection and
        processing of Recordings from a Test it has created is handled in
        accordance with applicable laws. For Customers in the EEA, please see
        our&nbsp;
        <a href="https://www.usertesting.com/gdpr-policy">
          <strong>GDPR Notice</strong>
        </a>
        . For Customers in California, please see our&nbsp;
        <a href="https://www.usertesting.com/ccpa-policy">
          <strong>CCPA Notice</strong>
        </a>
        .
      </p>
      <p>
        <strong>Personal Data Collected Indirectly</strong>
      </p>
      <p>
        <strong>
          <em>Tracking Data, IP Addresses and Device Fingerprints</em>
        </strong>
      </p>
      <p>
        UserQual tracks whether a Visitor lands on the UserQual Sites from an
        external source (such as a link on another website or in an email), as
        well as IP addresses from which the site is accessed and information
        about the computing device (fingerprint) used to access the Sites.
        UserQual uses this information to improve the Site, Platform, and
        Services, as well as to prevent fraud and secure information.
      </p>
      <p>
        <strong>
          <em>Information from Third Parties</em>
        </strong>
      </p>
      <p>
        UserQual collects Personal Data and other data from third parties that
        provide us with lists of potential Customers and their contact
        information, if such potential Customers give permission to those third
        parties to share their information with us. UserQual uses this
        information for its own marketing purposes.
      </p>
      <p>
        <strong>
          <em>Cookies</em>
        </strong>
      </p>
      <p>
        We use cookies and other technologies to track the use of our websites
        and apps. To learn about opportunities to choose not to allow cookies,
        click here. If you are using our website in a browser, this information
        is also available in our&nbsp;
        <a href="https://www.usertesting.com/cookie-policy">
          <strong>Cookie Policy</strong>
        </a>
        .
      </p>
      <p>
        Visitors have the ability to accept or decline cookies that are not
        strictly necessary. Most web browsers automatically accept cookies, but
        individuals can usually modify browser settings to decline cookies. More
        information about disabling cookies is available at&nbsp;
        <a href="http://www.allaboutcookies.org/">
          <strong>www.allaboutcookies.org</strong>
        </a>
        . Choosing to decline certain cookies may result in decreased
        functionality on UserQual Site, Platform and Services.
      </p>
      <p>
        <strong>
          <em>"Do Not Track"</em>
        </strong>
      </p>
      <p>
        UserQual&rsquo;s Site, Platform, and Services may not respond to Do Not
        Track ("DNT") signals. For more information on DNT settings generally,
        please visit&nbsp;
        <a href="https://allaboutdnt.com/">
          <strong>https://allaboutdnt.com/</strong>
        </a>
        .
      </p>
      <p>
        <strong>
          <em>We collect data when you communicate with us</em>
        </strong>
      </p>
      <p>
        If you communicate with us directly, we will collect any Personal Data
        contained in such communications.
      </p>
      <p>
        <strong>Automated decision making</strong>
      </p>
      <p>
        UserQual may use automated decision making using a variety of signals
        derived from account activity to help identify and suspend accounts
        sending spam or engaged in other abusive or fraudulent activity or who
        have not engaged with the us for an extended period of time. Holders of
        accounts suspended under these circumstances are notified of the
        suspension and given an opportunity to request human review of the
        suspension decision.
      </p>
      <p>
        <strong>
          <em>How we share your personal data</em>
        </strong>
      </p>
      <p>
        UserQual may use automated decision making using a variety of signals
        derived from account activity to help identify and suspend accounts
        sending spam or engaged in other abusive or fraudulent activity or who
        have not engaged with the us for an extended period of time. Holders of
        accounts suspended under these circumstances are notified of the
        suspension and given an opportunity to request human review of the
        suspension decision.
      </p>
      <p>
        <strong>How we share your personal data</strong>
      </p>
      <p>
        <strong>
          <em>Data Processors and Subprocessors</em>
        </strong>
      </p>
      <p>
        UserQual discloses Personal Data to our third-party agents, contractors,
        or service providers who are hired to perform services on our behalf.
        These providers may operate or support certain functions of the
        Services, and in some cases collect information directly. Below is an
        illustrative list of functions for which we may use third-party service
        providers:
      </p>
      <ul>
        <li>Analytics services, such as DataDog and NewRelic</li>
        <li>Customer support services, such as ZenDesk and Intercom</li>
        <li>
          Billing services and payment gateway providers, such as PayPal and
          Razorpay
        </li>
        <li>
          Hosting and content delivery network services, such as AWS and Digital
          Ocean
        </li>
      </ul>
      <p>
        Unless you and the Company have agreed otherwise, we generally do not
        directly collect your payment information and we do not store your
        payment information. We use a third-party payment processor, such as
        PayPal, which collects payment information on our behalf in order to
        complete transactions such as to pay Participants. While our
        administrators are able to view and track actual transactions via the
        third-party payment processor&rsquo;s customer portal, with the
        exception of the last 4 digits of your credit card, credit card type,
        zip code and expiration date, we do not have access to or process your
        credit card information.
      </p>
      <p>
        <strong>
          <em>Business Transfers</em>
        </strong>
      </p>
      <p>
        As we continue to grow, we may purchase websites, applications,
        subsidiaries, and other businesses or business units. We may share your
        data among our corporate group companies. Alternatively, we may sell
        businesses or business units, merge with other entities, and/or sell
        assets or stock, in some cases as part of a reorganization or
        liquidation in bankruptcy. As part of these transactions, we may
        transfer your Personal Data to a successor entity upon a merger,
        consolidation or other corporate reorganization in which UserQual
        participates, or to a purchaser or acquirer of all or a portion of
        UserQual&rsquo;s assets, bankruptcy included.
      </p>
      <p>
        <strong>
          <em>Legal Obligations and Security</em>
        </strong>
      </p>
      <p>
        We will preserve or disclose your Personal Data in limited circumstances
        (other than as set forth in this Privacy Policy), including: (i) with
        your consent; (ii) when we have a good faith belief it is required by
        law, such as pursuant to a subpoena, warrant, or other judicial or
        administrative order (as further explained below); (iii) to protect the
        safety of any person; to protect the safety or security of our Services
        or to prevent spam, abuse, or other malicious activity of actors with
        respect to the Services; or (iv) to protect our rights or property or
        the rights or property of those who use the Services.
      </p>
      <p>
        If we are required to disclose Personal Data by law, such as pursuant to
        a subpoena, warrant, or other judicial or administrative order, our
        policy is to respond to legal mandates that are properly issued.
      </p>
      <p>
        <u>Note</u>&nbsp;that if we receive information that provides us with a
        good faith belief that there is an exigent emergency involving the
        danger of death or serious physical injury to a person, we may provide
        information to law enforcement trying to prevent or mitigate the danger
        (if we have it), to be determined on a case-by-case basis.
      </p>
      <p>
        <strong>How we store your data</strong>
      </p>
      <p>
        <strong>
          <em>Retention</em>
        </strong>
      </p>
      <p>
        We retain your Personal Data in accordance with your instructions,
        including those in the applicable terms of service accepted by you and
        any terms governing your use of the Platform and Services. We may also
        retain your Personal Data where we have an ongoing legitimate business
        purpose for doing so. We must retain information when it is needed for
        the establishment, exercise or defense of legal claims (also known as a
        "litigation hold"). In this case, we retain the information as long as
        needed for exercising respective potential legal claims.
      </p>
      <p>
        We use the following criteria to determine our retention periods: the
        amount, nature and sensitivity of your information, the reasons for
        which we collect and process your Personal Data, the length of time we
        have an ongoing relationship with you and provide you with access to our
        Services, and applicable legal requirements.
      </p>
      <p>
        If you have questions about, or need further information concerning, our
        data retention periods, please send an email at&nbsp;hello@userqual.com
      </p>
      <p>
        <strong>
          <em>Deletion</em>
        </strong>
      </p>
      <p>
        At UserQual&rsquo;s option, we may delete Personal Data one year after
        our business relationship ends, subject to our document retention
        policies and practices. When we have no ongoing legitimate business
        purpose to process your Personal Data, we may either delete or anonymize
        it. Deletion may not be possible if your Personal Data was anonymized.
        In that case, the anonymized data will not be able to be combined with
        other data to identify you.
      </p>
      <p>
        If you ask UserQual to delete specific Personal Data of yours, and we
        are the data controller, we will honor your request by deleting or
        anonymizing the data unless deleting that information prevents us from
        carrying out necessary business functions, such as delivering and
        billing for our services, calculating taxes, or conducting required
        audits and fulfilling contractual obligations to our Customers,
        Participants or others.
      </p>
      <p>
        If you are an individual in the EEA or a California resident, please see
        the relevant&nbsp;
        <a href="https://www.usertesting.com/gdpr-policy">
          <strong>GDPR Notice</strong>
        </a>
        &nbsp;and&nbsp;
        <a href="https://www.usertesting.com/ccpa-policy">
          <strong>CCPA Notice</strong>
        </a>
        &nbsp;with respect to exercising your right to deletion.
      </p>
      <p>
        <strong>How you can control your data</strong>
      </p>
      <p>
        Visitors, Participants, and Customers who wish to request access to or
        correction of Personal Data for which UserQual is the data controller
        should contact UserQual. Where UserQual is the data processor or
        subprocessor of your Personal Data, Visitors, Participants, or Customers
        should contact the relevant data controller to request access to or
        correction of that Personal Data.
      </p>
      <p>
        Individuals located in the EEA or residents of California have
        additional rights as set forth in our relevant&nbsp;
        <a href="https://www.usertesting.com/gdpr-policy">
          <strong>GDPR Notice</strong>
        </a>
        &nbsp;and&nbsp;
        <a href="https://www.usertesting.com/ccpa-policy">
          <strong>CCPA Notice</strong>
        </a>
        .
      </p>
      <p>
        <strong>How we keep your data safe</strong>
      </p>
      <p>
        UserQual stores most of the data on AWS and Digital Ocean servers and
        maintains highly secure databases based on the Amazon&rsquo;s storage
        policies.
      </p>
      <p>
        <strong>Security</strong>
      </p>
      <p>
        We cannot guarantee, ensure or warrant the security of any information
        transmitted to the Company. All transmissions of information are done at
        the sender&rsquo;s own risk. Once we are in possession of information,
        we will make reasonable efforts to ensure the security of the
        information within our systems.
      </p>
      <p>
        Your personal information and files are stored on UserQual&rsquo;s
        servers and the servers of companies we hire to provide services to us.
      </p>
      <p>
        UserQual has adopted physical, technological, and administrative
        procedures designed to safeguard and secure the information we process.
        By using this Site, Platform or Services or by providing Personal Data
        to us, you agree that we can communicate with you electronically
        regarding security, privacy, and administrative issues relating to your
        use of this Site, Platform or Services.
      </p>
      <p>
        <strong>Acceptance</strong>
      </p>
      <p>
        You agree that you have carefully read this document and agree to its
        contents. If you choose not to agree with this Privacy Policy, then you
        should refrain from using the Site, Services, and Platform.
      </p>
      <p>
        UserQual reserves the right to change our Privacy Policy from time to
        time. If we make material changes to this Privacy Policy, we will
        provide notice to you of these changes, which may be by email to you, by
        posting a notice of such changes on our apps and websites, or by other
        means consistent with applicable law. Unless your express consent is
        required by law, your continued use of the UserQual Site, Platform, and
        Services after having been notified of any such a revised Privacy Policy
        indicates acceptance of the revised Privacy Policy.
      </p>
      <p>
        <strong>Contact Us</strong>
      </p>
      <p>
        If you have questions, comments, or concerns about UserQual or this
        Privacy Policy, please email us at:&nbsp;hello@userqual.com
      </p>
      <p>
        <strong>Last updated: May 1, 2021</strong>
      </p>
      <p>&nbsp;</p>
    </div>
  );
};

export default PrivacyAndTermsAndConditions;
