import React, { useState } from "react";
import { Checkbox } from "antd";
import { difference } from "lodash";
import "./image-checkboxes.css";
import Thumbnail from "../image-thumbnail/ImageThumbnail";

const ImageCheckboxes = ({ key, options, value, onChange = () => {} }) => {
  const [localValue, setLocalValue] = useState(value);

  const valueChanged = (checkedValue) => {
    let newValues = [...checkedValue];
    const addedValues = difference(checkedValue, localValue);
    if (addedValues.length === 1) {
      const addedValue = addedValues[0];
      if (options.imageMultiChoices[addedValue].isMcq) {
        newValues = [addedValue];
      } else {
        newValues = newValues.filter(
          (val) => !options.imageMultiChoices[val].isMcq
        );
      }
    }

    setLocalValue(newValues);
    onChange(newValues);
  };
  return (
    <React.Fragment>
      <Checkbox.Group
        key={key}
        className="img-checkboxes"
        defaultValue={value}
        value={localValue}
        onChange={valueChanged}
      >
        {options.imageMultiChoices.map((val, index) => (
          <div className="img-multi-option-container">
            <Checkbox value={index}></Checkbox>
            <Thumbnail imgUrl={val.imageData} style={{ marginLeft: 16 }} />
            <div className="img-checkboxes-label">{val.imageLabel}</div>
          </div>
        ))}
      </Checkbox.Group>
    </React.Fragment>
  );
};

export default ImageCheckboxes;
