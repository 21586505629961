import { set } from "automate-redux";
import axios, { Routes } from "../services/API";
import admin from "../services/API/admin";
import { getRoleByToken } from "../utils";

// Admin
export const signIn = (email, password) => async (dispatch) => {
  const { data } = await axios({
    ...Routes.admin.signin(),
    data: { data: { email, password } },
  });
  dispatch(setTierInfo(data.admin.tierInfo));
  localStorage.setItem("token", data.token);
  localStorage.setItem("admin", data.admin._id);
};

export const editAdmin = (newTierInfo) => async (dispatch) => {
  const localStorageId =
    getRoleByToken(localStorage.getItem("token")) === "admin"
      ? localStorage.getItem("admin")
      : "";
  try {
    await axios({
      ...Routes.admin.editAdmin(localStorageId),
      data: { data: { tierInfo: newTierInfo } },
    });
    dispatch(setTierInfo(newTierInfo));
  } catch (err) {
    throw err.toString();
  }
};

export const getTierInfo = () => async (dispatch) => {
  try {
    const { data } = await axios({ ...Routes.others.tierInfo() });
    dispatch(setTierInfo(data.tiers));
  } catch (err) {
    throw err.toString();
  }
};

// Questions
export const getAdminQuestions = () => async () => {
  const localStorageId =
    getRoleByToken(localStorage.getItem("token")) === "admin"
      ? localStorage.getItem("admin")
      : "";
  try {
    const { data } = await axios({
      ...Routes.admin.getAdminQuestions(localStorageId),
    });
    return data.adminQuestions.sort((a, b) => a.index - b.index);
  } catch (err) {
    throw err.toString();
  }
};

export const updateAdminQuestions = (adminQuestions) => async () => {
  const localStorageId =
    getRoleByToken(localStorage.getItem("token")) === "admin"
      ? localStorage.getItem("admin")
      : "";
  try {
    const newData = adminQuestions.map((el, index) => ({ ...el, index }));
    console.log("ADMINS", newData);
    await axios({
      ...Routes.admin.updateAdminQuestions(localStorageId),
      data: { data: newData },
    });
  } catch (err) {
    throw err.toString();
  }
};

export const deleteAdminQuestion = (newData) => async () => {
  const localStorageId =
    getRoleByToken(localStorage.getItem("token")) === "admin"
      ? localStorage.getItem("admin")
      : "";
  try {
    await axios({
      ...Routes.admin.updateAdminQuestion(localStorageId, newData._id),
      data: { data: newData },
    });
  } catch (err) {
    throw err.toString();
  }
};

// Surveys
export const getSurveys = () => async () => {
  const localStorageId =
    getRoleByToken(localStorage.getItem("token")) === "admin"
      ? localStorage.getItem("admin")
      : "";
  try {
    const { data } = await axios({
      ...Routes.admin.getSurveys(localStorageId),
    });
    return data.surveys;
  } catch (err) {
    throw err.toString();
  }
};

export const publishSurvey = (data) => async () => {
  const localStorageId =
    getRoleByToken(localStorage.getItem("token")) === "admin"
      ? localStorage.getItem("admin")
      : "";
  try {
    // await axios({
    //   ...Routes.admin.editSurvey(localStorageId, data._id),
    //   data: { data },
    // });
    await axios({ ...Routes.admin.verifySurvey(localStorageId, data._id) });
  } catch (err) {
    return err.toString();
  }
};

// Influencers
export const getInfluencers = () => async () => {
  const localStorageId =
    getRoleByToken(localStorage.getItem("token")) === "admin"
      ? localStorage.getItem("admin")
      : "";
  try {
    const { data } = await axios({
      ...Routes.admin.getInfluencers(localStorageId),
    });
    return data.influencers;
  } catch (err) {
    throw err.toString();
  }
};

export const updateInfluencers = (influencers) => async () => {
  const localStorageId =
    getRoleByToken(localStorage.getItem("token")) === "admin"
      ? localStorage.getItem("admin")
      : "";
  try {
    await axios({
      ...Routes.admin.updateInfluencers(localStorageId),
      data: { data: influencers },
    });
  } catch (err) {
    throw err.toString();
  }
};

export const deleteInfluencer = (influencerId) => async () => {
  const localStorageId =
    getRoleByToken(localStorage.getItem("token")) === "admin"
      ? localStorage.getItem("admin")
      : "";
  try {
    await axios({
      ...Routes.admin.deleteInfluencer(localStorageId, influencerId),
    });
  } catch (err) {
    throw err.toString();
  }
};

export const getInfluencerCategories = () => async (dispatch) => {
  try {
    const { data } = await axios({
      ...Routes.others.getInfluencerCategories(),
    });
    return data.influencerCategories;
  } catch (err) {
    throw err.toString();
  }
};

export const editInfluencerCategories = (influencerCategories) => async () => {
  const localStorageId =
    getRoleByToken(localStorage.getItem("token")) === "admin"
      ? localStorage.getItem("admin")
      : "";
  try {
    await axios({
      ...Routes.admin.editAdmin(localStorageId),
      data: { data: { influencerCategories } },
    });
  } catch (err) {
    throw err.toString();
  }
};

// Clients
export const getClients = () => async () => {
  const localStorageId =
    getRoleByToken(localStorage.getItem("token")) === "admin"
      ? localStorage.getItem("admin")
      : "";
  try {
    const { data } = await axios({
      ...Routes.admin.getClients(localStorageId),
    });
    return data.clients;
  } catch (err) {
    throw err.toString();
  }
};

export const addClient = (data) => async () => {
  const localStorageId =
    getRoleByToken(localStorage.getItem("token")) === "admin"
      ? localStorage.getItem("admin")
      : "";
  try {
    await axios({
      ...Routes.admin.addClient(localStorageId),
      data: { data },
    });
  } catch (err) {
    throw err.toString();
  }
};

export const editClient = (clientId, data) => async () => {
  const localStorageId =
    getRoleByToken(localStorage.getItem("token")) === "admin"
      ? localStorage.getItem("admin")
      : "";
  try {
    await axios({
      ...Routes.admin.editClient(localStorageId, clientId),
      data: { data },
    });
  } catch (err) {
    throw err.toString();
  }
};

// Setters
const setTierInfo = (data) => set(`admin.tierInfo`, data);
