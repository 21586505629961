import React, { Fragment } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useArray from "../../useArray";
import VideoMultiChoiceOptions from "./videoMultiChoicesOptions/VideoMultiChoicesOptions";

const VideoMultiChoices = ({ options, setOptions, checkBox }) => {
  // const [multiChoices, { add, remove, move, set }] = useArray(
  //   options.videoMultiChoices
  // );

  const videoMultiChoices = options.videoMultiChoices;

  const add = (item, index) => {
    setOptions({
      ...options,
      videoMultiChoices: [
        ...videoMultiChoices.slice(0, index),
        item,
        ...videoMultiChoices.slice(index, videoMultiChoices.length),
      ],
    });
  };

  const move = (source, dest) => {
    const result = Array.from(videoMultiChoices);
    const [removed] = result.splice(source, 1);
    result.splice(dest, 0, removed);
    setOptions({ ...options, videoMultiChoices: result });
  };

  const remove = (index) => {
    setOptions({
      ...options,
      videoMultiChoices: [
        ...videoMultiChoices.slice(0, index),
        ...videoMultiChoices.slice(index + 1, videoMultiChoices.length),
      ],
    });
  };

  const set = (item, index) => {
    const result = Array.from(videoMultiChoices);
    result[index] = item;
    setOptions({ ...options, videoMultiChoices: result });
  };

  const setOptionValue = (value, key, index) => {
    if (key == "videoLabel") {
      const result = {
        ...videoMultiChoices[index],
        videoLabel: value.videoLabel,
        videoData: value.videoData,
      };
      set(result, index);
    } else {
      const result = { ...videoMultiChoices[index], [key]: value };
      set(result, index);
    }
    console.log(options);
  };

  return (
    <DragDropContext
      onDragEnd={(result) =>
        move(result.source.index, result.destination.index)
      }
    >
      <Droppable droppableId="list">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              paddingTop: 10,
            }}
          >
            {videoMultiChoices.map((choice, index) => (
              <Draggable
                draggableId={`option-${index}`}
                index={index}
                key={index}
              >
                {(provided) => (
                  <div
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    gutter={6}
                  >
                    <VideoMultiChoiceOptions
                      dragHandleProps={provided.dragHandleProps}
                      choiceNumber={index + 1}
                      choice={choice}
                      checkBox={checkBox}
                      setLabel={(item) => {
                        setOptionValue(item, "videoLabel", index);
                      }}
                      totalChoices={videoMultiChoices.length}
                      isFilled={{
                        videoLabel: choice.videoLabel !== "",
                        videoData: choice.videoData !== "",
                      }}
                      addChoice={(newIndex) =>
                        add({ videoLabel: "", videoData: "" }, newIndex)
                      }
                      setMcq={(value) => setOptionValue(value, "isMcq", index)}
                      removeChoice={(index) => remove(index - 1)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default VideoMultiChoices;
