import React, { useState } from "react";
import styles from "./reg-questions.module.css";
import c from "classnames/bind";
import { Button, InputNumber, message } from "antd";
import MultipleChoice from "../../../components/user/profile-questions/multiple-choice/MultipleChoice";
import Checkboxes from "../../../components/user/profile-questions/checkboxes/Checkboxes";
import Modal from "react-modal";
import { PlayCircleFilled } from "@ant-design/icons";
import CheckboxesSpecial from "../../../components/user/profile-questions/checkboxes-special/CheckboxesSpecial";
// import questions from "./dummyQuestions"

const modalStyles = {
  content: {
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    border: 0,
    padding: 0,
    borderRadius: 0,
  },
};

const cx = c.bind(styles);

let answers = [{ answer: [] }];
const RegQuestions = ({
  isOpen,
  onClose,
  questions,
  onEndSurvey = () => {},
}) => {
  const [step, setStep] = useState(0);

  // Derived state
  const endSurveySlideNumber = questions.length;

  const getComponentByQuestionType = () => {
    const {
      questionType,
      options,
      start,
      end,
      interval,
      specialField,
    } = questions[step].questionObject;

    const onChange = (values) => {
      answers[step] = {
        answer: Array.isArray(values) ? values : [values],
        questionId: questions[step]._id,
      };
    };

    switch (questionType) {
      case "multipleChoice":
        return (
          <MultipleChoice
            key={step}
            choices={options}
            onChange={onChange}
            value={answers[step][0]}
            color="#004479"
          />
        );
      case "checkboxes":
        return (
          <Checkboxes
            key={step}
            choices={options}
            onChange={onChange}
            value={answers[step]}
          />
        );
      case "checkboxesSpecial":
        return (
          <CheckboxesSpecial
            key={step}
            choices={options}
            onChange={onChange}
            value={answers[step].answer}
            specialField={specialField}
          />
        );
      case "age":
        return (
          <InputNumber
            key={step}
            defaultValue={answers[step][0]}
            onChange={onChange}
            style={{
              color: "#004479",
              border: "1px solid #004479",
            }}
          />
        );
      case "location":
        return (
          <MultipleChoice
            key={step}
            choices={options}
            onChange={onChange}
            value={answers[step][0]}
            color="#004479"
          />
        );
      default:
        return;
    }
  };

  // Handlers
  const incrementStep = () => {
    if (answers[step].answer.length === 0) {
      message.error("Please answer this question first!");
      return;
    }

    if (step === endSurveySlideNumber - 1) {
      onEndSurvey(answers);
      return;
    }
    answers[step + 1] = { answer: [] };
    setStep(step + 1);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyles}>
      <div className={cx("survey-container")}>
        <div className={cx("center")}>
          {endSurveySlideNumber === 0 && (
            <span>
              <p>
                Thanks for becoming part of the UserQual team!. <br />
                <br /> Please see this short video to learn how surveys are
                conducted and how EASY it is to make money by giving your honest
                feedback!.
              </p>
              <Button
                size="large"
                className="informational-video-btn"
                style={{ border: "1px solid #194475", color: "#194475" }}
                onClick={() => onEndSurvey([])}
              >
                Survey Informational Video{" "}
                <PlayCircleFilled style={{ color: "#C22B7D" }} />
              </Button>
            </span>
          )}
          {step !== endSurveySlideNumber && (
            <React.Fragment>
              <span>
                <p>
                  Thanks for becoming part of the UserQual team!. <br />
                  <br /> To qualify for surveys and get paid, we need to get
                  some demographic information. This will NOT be shared with
                  clients.
                </p>
                <p>{questions[step] && questions[step].userField}</p>
              </span>
              <div className={cx("answerBox")}>
                {questions[step] && getComponentByQuestionType()}
              </div>
              <Button
                type="primary"
                size="large"
                onClick={incrementStep}
                style={{
                  backgroundColor: "#00C194",
                  color: "white",
                  border: "#00C194",
                  padding: "5px 25px",
                }}
              >
                Next
              </Button>
            </React.Fragment>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RegQuestions;
