import React from "react";
import "./form-box-style.css";
import { styled } from "@linaria/react";
import {
  CopyOutlined,
  DeleteOutlined,
  DesktopOutlined,
  EditOutlined,
  MobileOutlined,
  TeamOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { css } from "@linaria/core";
import { useState } from "react";
import { Typography, Modal } from "antd";
import moment from "moment";

const { confirm } = Modal;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  justify-content: ${(props) => (props.justify ? props.justify : "flex-start")};
  align-items: ${(props) => (props.align ? props.align : "")};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "")};
`;

const FormBox = styled.div`
  height: 250px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  color: #404040;
  padding: 0.5rem;
  font-size: 110%;
`;

const mx2 = css`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

const my2 = css`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const icon = css`
  cursor: pointer;
  font-size: 20px;
`;

const NewFormBox = ({
  name,
  edit,
  deleteSurvey,
  copySurvey,
  surveyType,
  mode,
  device,
  setting,
  timingType,
  createdBy,
  updatedAt,
}) => {
  const [hover, setHover] = useState(false);

  function showDeleteConfirm() {
    confirm({
      title: "Are you sure you want to delete this Survey?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteSurvey();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }
  return (
    <FormBox
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ border: "2px solid #71CFD7" }}
    >
      <Flex style={{ height: "250px" }} justify="space-between">
        <Flex row justify="space-between">
          <Flex row>
            <Typography
              style={{
                fontSize: "110%",
                color: "#404040",
              }}
            >
              Draft
            </Typography>
            {hover && (
              <EditOutlined
                size="lg"
                className={`${mx2} ${icon}`}
                onClick={edit}
                title="Edit Survey"
              />
            )}
          </Flex>
          <Flex>
            {hover && (
              <>
                <DeleteOutlined
                  title="Delete Survey"
                  className={`${icon}`}
                  size="lg"
                  onClick={showDeleteConfirm}
                />
                <CopyOutlined
                  title="Copy Survey"
                  className={`${my2} ${icon}`}
                  size="lg"
                  onClick={copySurvey}
                />
              </>
            )}
          </Flex>
        </Flex>
        <Flex>
          <Flex row justify="space-between">
            <Flex justify="flex-end" style={{ width: "50%" }}>
              <div
                style={{
                  fontSize: "110%",
                  overflowX: "hidden",
                  maxWidth: "100%",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <b>{name}</b>
              </div>
              <div>Created by {createdBy}</div>
              <div>{moment(updatedAt).format("DD/MM/YYYY")}</div>
            </Flex>
            <Flex justify="flex-end" style={{ textAlign: "end" }}>
              {surveyType !== undefined && (
                <div>
                  {surveyType === true ? "Qualitative" : "Quantitative"}
                </div>
              )}
              {surveyType === true && mode !== undefined && (
                <div>{mode === true ? "Moderated" : "Unmoderated"}</div>
              )}
              {surveyType === true && device !== undefined && (
                <div>
                  {device === true ? <MobileOutlined /> : <DesktopOutlined />}
                </div>
              )}
              {surveyType === true &&
                mode === true &&
                setting !== undefined && (
                  <div>
                    {setting === true ? <TeamOutlined /> : <UserOutlined />}
                  </div>
                )}
              {surveyType === true && timingType !== undefined && (
                <div>
                  {timingType === 1 && "15 mins"}
                  {timingType === 2 && "30 mins"}
                  {timingType === 3 && "45 mins"}
                </div>
              )}
              {surveyType === false && (
                <div>
                  <MobileOutlined /> / <DesktopOutlined />
                </div>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </FormBox>
  );
};

export default NewFormBox;
