import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useArray from "../../useArray";
import VideoABTestChoices from "./videoABTestChoices/VideoABTestChoices";

const VideoABTest = ({ options, setOptions }) => {
  // const [multiChoices, { add, remove, move, set }] = useArray(
  //   options.videoABTestChoices
  // );

  const videoABTestChoices = options.videoABTestChoices;

  const add = (item, index) => {
    setOptions({
      ...options,
      videoABTestChoices: [
        ...videoABTestChoices.slice(0, index),
        item,
        ...videoABTestChoices.slice(index, videoABTestChoices.length),
      ],
    });
  };

  const move = (source, dest) => {
    const result = Array.from(videoABTestChoices);
    const [removed] = result.splice(source, 1);
    result.splice(dest, 0, removed);
    setOptions({ ...options, videoABTestChoices: result });
  };

  const remove = (index) => {
    setOptions({
      ...options,
      videoABTestChoices: [
        ...videoABTestChoices.slice(0, index),
        ...videoABTestChoices.slice(index + 1, videoABTestChoices.length),
      ],
    });
  };

  const set = (item, index) => {
    const result = Array.from(videoABTestChoices);
    result[index] = item;
    setOptions({ ...options, videoABTestChoices: result });
  };

  const setOptionValue = (item, index) => {
    set(item, index);
  };

  return (
    <DragDropContext
      onDragEnd={(result) =>
        move(result.source.index, result.destination.index)
      }
    >
      <Droppable droppableId="list">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              paddingTop: 20,
            }}
          >
            {videoABTestChoices.map((choice, index) => (
              <Draggable
                draggableId={`option-${index}`}
                index={index}
                key={index}
              >
                {(provided) => (
                  <div
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    gutter={6}
                  >
                    <VideoABTestChoices
                      dragHandleProps={provided.dragHandleProps}
                      choiceNumber={index + 1}
                      choice={choice}
                      setValue={(item) => setOptionValue(item, index)}
                      isFilled={{
                        videoData: choice.videoData !== "",
                        percentage:
                          videoABTestChoices
                            .filter((choice) => choice && choice !== "")
                            .reduce(
                              (sum, choice) =>
                                parseInt(choice.percentage) + sum,
                              0
                            ) === 100,
                      }}
                      totalChoices={videoABTestChoices.length}
                      addChoice={(newIndex) =>
                        add(
                          { videoLabel: "", videoData: "", percentage: "" },
                          newIndex
                        )
                      }
                      removeChoice={(index) => remove(index - 1)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default VideoABTest;
