import React, { useEffect, useState } from "react";
import { capitalizeFirstCharacter, Sum } from "../../../../../utils.js";
import { Modal, Form, Input, Select, Spin } from "antd";
import { PlusSquareOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  getAgeRange,
  getOptionsLength,
  getQuestion,
  getAgeGroupAnswerLength,
} from "../../CriteriaUtils.js";
import "./RatioModal.css";
import Errors from "../../../new-survey/Errors.jsx";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Option } = Select;

const ratios = [
  0,
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  85,
  90,
  95,
  100,
];

const RatioModal = ({
  isModalVisible,
  hideModal,
  isGroupSurvey,
  groupName,
  groupParticipants,
  updateGroupName,
  updateGroupParticipants,
  adminQuestions,
  criteria,
  setCriteria,
}) => {
  const [criteriaObject, setCriteriaObj] = useState(null);
  const [group, setGroup] = useState({});
  const [disableForm, setDisableForm] = useState(false);

  useEffect(() => {
    setGroup({
      name: groupName,
      numberOfParticipants: groupParticipants,
    });
  }, [groupName, groupParticipants]);

  useEffect(() => {
    syncCriteriaObjwCriteria();
  }, [criteria]);

  useEffect(() => {
    validateRatios().length > 0 ? setDisableForm(true) : setDisableForm(false);
  }, [criteriaObject]);

  const groupNameChangeHandler = (e) => {
    setGroup({ ...group, name: e.target.value });
    updateGroupName(e.target.value);
  };

  const validateRatios = () => {
    const err = [];
    if (criteriaObject) {
      Object.keys(criteriaObject).forEach((criterion) => {
        if (
          criteriaObject[criterion]?.ratios?.length &&
          Sum(criteriaObject[criterion].ratios) != 100 &&
          !(
            criteriaObject[criterion].selectedValues.length === 1 &&
            criteriaObject[criterion].selectedValues[0] === -1
          )
        ) {
          err.push({
            message: `Ratios for selected criteria, "${capitalizeFirstCharacter(
              getQuestion(criterion, adminQuestions).clientField
            )}" do not add upto 100%.`,
            description: `Please select ratio(s) for criteria, "${capitalizeFirstCharacter(
              getQuestion(criterion, adminQuestions).clientField
            )}" such that it adds upto 100%".`,
          });
        }
      });
    }
    return err;
  };

  const groupParticipantsChangeHandler = (e) => {
    setGroup({ ...group, numberOfParticipants: e.target.value });
    updateGroupParticipants(e.target.value);
  };

  //update criteria on OK button click
  const handleOk = () => {
    const newCriteria = criteria.map((criterion) => {
      const selectedValues =
        criteriaObject[criterion.questionId].selectedValues;
      const ratios =
        selectedValues.length === 1 && selectedValues[0] === -1
          ? []
          : criteriaObject[criterion.questionId].ratios;
      return {
        ...criterion,
        selectedValues,
        ratios,
      };
    });
    setCriteria(newCriteria);
    hideModal();
  };

  const syncCriteriaObjwCriteria = () => {
    const newCriteriaObject = {};
    criteria.map((c) => {
      if (!c?.selectedValues?.length || !c?.ratios?.length) {
        newCriteriaObject[c.questionId] = {
          selectedValues: [-1],
          ratios: new Array(
            getOptionsLength(c.questionId, adminQuestions)
          ).fill(0),
        };
      } else {
        let selectedValues = [];
        let ratios = new Array(
          getOptionsLength(c.questionId, adminQuestions)
        ).fill(0);
        if (
          getQuestion(c.questionId, adminQuestions).questionObject
            .questionType == "age"
        ) {
          getAgeRange({
            start: getQuestion(c.questionId, adminQuestions).questionObject
              .start,
            end: getQuestion(c.questionId, adminQuestions).questionObject.end,
            interval: getQuestion(c.questionId, adminQuestions).questionObject
              .interval,
          }).map((el, index) => {
            if (
              el.value.every((r) => c.answer.includes(r)) &&
              c.selectedValues.includes(index)
            ) {
              selectedValues.push(index);
              ratios[index] = c.ratios[index];
            }
          });
        } else {
          c.selectedValues.map((sv) => {
            if (c.answer.includes(sv)) {
              selectedValues.push(sv);
              ratios[sv] = c.ratios[sv];
            }
          });
        }
        newCriteriaObject[c.questionId] = {
          selectedValues: [...selectedValues],
          ratios: [...ratios],
        };
      }
    });
    setCriteriaObj(newCriteriaObject);
  };

  const handleCancel = () => {
    const newCriteriaObject = {};
    criteria.map((criterion) => {
      if (!criterion?.selectedValues?.length) {
        newCriteriaObject[criterion.questionId] = {
          selectedValues: [-1],
          ratios: new Array(
            getOptionsLength(criterion.questionId, adminQuestions)
          ).fill(0),
        };
      } else {
        newCriteriaObject[criterion.questionId] = {
          selectedValues: [...criterion.selectedValues],
          ratios: [...criterion.ratios],
        };
      }
    });
    setCriteriaObj(newCriteriaObject);
    hideModal();
  };

  const handleOptionChange = (event, questionId, index) => {
    if (event === -1) {
      const newCriteriaObject = { ...criteriaObject };
      newCriteriaObject[questionId].ratios = new Array(
        getOptionsLength(questionId, adminQuestions)
      ).fill(0);
      newCriteriaObject[questionId].selectedValues = [-1];
      setCriteriaObj(newCriteriaObject);
    } else {
      const newCriteriaObject = { ...criteriaObject };
      newCriteriaObject[questionId].selectedValues[index] = event;
      setCriteriaObj(newCriteriaObject);
    }
  };

  const handleAdd = (questionId, i) => {
    let newCriteriaObject = { ...criteriaObject };
    newCriteriaObject[questionId].selectedValues.push(null);
    setCriteriaObj(newCriteriaObject);
  };

  const handleRatioChange = (questionId, event, index) => {
    const arr = criteriaObject[questionId].ratios;
    arr[criteriaObject[questionId].selectedValues[index]] = event;
    const newCriteriaObject = { ...criteriaObject };
    newCriteriaObject[questionId].ratios = arr;
    setCriteriaObj(newCriteriaObject);
  };

  const checkSelectedOption = (questionId, index, i) => {
    for (let j = 0; j < i; j++) {
      if (criteriaObject[questionId].selectedValues[j] == index) {
        return false;
      }
    }
    return true;
  };

  return (
    <Modal
      width={"55vw"}
      visible={isModalVisible}
      okText="Save"
      okButtonProps={{ disabled: disableForm }}
      className="ratioModal"
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div
        style={{
          fontWeight: "bold",
          fontSize: "1rem",
          padding: "0 0 20px 0",
        }}
      >
        {isGroupSurvey ? "Select group filters" : "Select filters"}
      </div>
      <div>
        <Form initialValues={{ remember: true }} autoComplete="off">
          {isGroupSurvey ? (
            <div
              style={{
                margin: "20px 0",
                display: "flex",
                flexDirection: "row",
                columnGap: "20px",
              }}
            >
              <Form.Item style={{ position: "relative", width: "35%" }}>
                <Input
                  value={group.name}
                  onChange={(e) => groupNameChangeHandler(e)}
                  style={{
                    borderRadius: "5px",
                    border: "none",
                    backgroundColor: "#EEEEEE",
                    height: "36px",
                  }}
                  placeholder="Enter group name here"
                />
              </Form.Item>
              <Form.Item style={{ position: "relative", width: "35%" }}>
                <Input
                  value={group.numberOfParticipants || undefined}
                  onChange={(e) => groupParticipantsChangeHandler(e)}
                  type={"number"}
                  min="1"
                  style={{
                    borderRadius: "5px",
                    border: "none",
                    backgroundColor: "#EEEEEE",
                    height: "36px",
                  }}
                  placeholder="Select no of participants"
                ></Input>
              </Form.Item>
            </div>
          ) : null}
          {criteriaObject ? (
            Object.keys(criteriaObject).map((c, i) => {
              return (
                <div key={i}>
                  <Form.Item
                    label={capitalizeFirstCharacter(
                      getQuestion(c, adminQuestions).clientField
                    )}
                    style={{ display: "grid", gridTemplateColumns: "25% 60%" }}
                  >
                    {[
                      "multipleChoice",
                      "checkboxes",
                      "location",
                      "checkboxesSpecial",
                    ].includes(
                      getQuestion(c, adminQuestions).questionObject.questionType
                    )
                      ? criteriaObject[c].selectedValues.map((x, i) => {
                          return (
                            <div
                              key={i}
                              style={{
                                display: "grid",
                                gridTemplateColumns: "40% 30% 10%",
                                gridGap: "20px",
                                paddingBottom: "10px",
                              }}
                            >
                              <Select
                                defaultValue={i == 0 ? -1 : null}
                                value={criteriaObject[c].selectedValues[i]}
                                onChange={(e) => handleOptionChange(e, c, i)}
                              >
                                {getQuestion(
                                  c,
                                  adminQuestions
                                ).questionObject.options.map((opt, idx) => {
                                  return criteria
                                    .find((o) => o.questionId === c)
                                    ?.answer?.includes(idx) &&
                                    checkSelectedOption(c, idx, i) ? (
                                    <Option key={idx} value={idx}>
                                      {opt}
                                    </Option>
                                  ) : null;
                                })}
                                {i === 0 ? (
                                  <Option value={-1}>All</Option>
                                ) : null}
                              </Select>
                              <Select
                                onChange={(e) => handleRatioChange(c, e, i)}
                                disabled={
                                  criteriaObject[c].selectedValues.length ===
                                    1 &&
                                  criteriaObject[c].selectedValues[0] === -1
                                }
                                defaultValue={100}
                                value={
                                  criteriaObject[c].selectedValues.length ===
                                    1 &&
                                  criteriaObject[c].selectedValues[0] === -1
                                    ? 100
                                    : criteriaObject[c].ratios[
                                        criteriaObject[c].selectedValues[i]
                                      ]
                                }
                              >
                                {ratios.map((r) => {
                                  return (
                                    <Option key={r} value={r}>
                                      {r} %
                                    </Option>
                                  );
                                })}
                              </Select>
                              {i ==
                                criteriaObject[c].selectedValues.length - 1 &&
                              criteria.find((o) => o.questionId === c)?.answer
                                .length >
                                i + 1 &&
                              criteriaObject[c].selectedValues[0] != -1 ? (
                                <PlusSquareOutlined
                                  onClick={() => handleAdd(c, i)}
                                  style={{ margin: "auto", fontSize: "1rem" }}
                                />
                              ) : null}
                            </div>
                          );
                        })
                      : null}

                    {getQuestion(c, adminQuestions).questionObject
                      .questionType === "age"
                      ? criteriaObject[c].selectedValues.map((x, i) => {
                          return (
                            <div
                              key={i}
                              style={{
                                display: "grid",
                                gridTemplateColumns: "40% 30% 10%",
                                paddingBottom: "10px",
                                gridGap: "20px",
                              }}
                            >
                              <Select
                                defaultValue={i == 0 ? -1 : null}
                                value={criteriaObject[c].selectedValues[i]}
                                onChange={(e) => handleOptionChange(e, c, i)}
                              >
                                {getAgeRange({
                                  start: getQuestion(c, adminQuestions)
                                    .questionObject.start,
                                  end: getQuestion(c, adminQuestions)
                                    .questionObject.end,
                                  interval: getQuestion(c, adminQuestions)
                                    .questionObject.interval,
                                }).map((el, index) => {
                                  return el.value.every(
                                    (r) =>
                                      criteria
                                        .find((o) => o.questionId === c)
                                        ?.answer.includes(r) &&
                                      checkSelectedOption(c, index, i)
                                  ) ? (
                                    <Option key={index} value={index}>
                                      {el.label}
                                    </Option>
                                  ) : null;
                                })}
                                {i === 0 ? (
                                  <Option value={-1}>All</Option>
                                ) : null}
                              </Select>
                              <Select
                                defaultValue={100}
                                disabled={
                                  criteriaObject[c].selectedValues.length ===
                                    1 &&
                                  criteriaObject[c].selectedValues[0] === -1
                                }
                                onChange={(e) => handleRatioChange(c, e, i)}
                                value={
                                  criteriaObject[c].selectedValues.length ===
                                    1 &&
                                  criteriaObject[c].selectedValues[0] === -1
                                    ? 100
                                    : criteriaObject[c].ratios[
                                        criteriaObject[c].selectedValues[i]
                                      ]
                                }
                              >
                                {ratios.map((r) => {
                                  return (
                                    <Option key={r} value={r}>
                                      {r} %
                                    </Option>
                                  );
                                })}
                              </Select>
                              {i ==
                                criteriaObject[c].selectedValues.length - 1 &&
                              getAgeGroupAnswerLength(
                                c,
                                adminQuestions,
                                criteria
                              ) >
                                i + 1 &&
                              criteriaObject[c].selectedValues[0] != -1 ? (
                                <PlusSquareOutlined
                                  onClick={() => handleAdd(c, i)}
                                  style={{ margin: "auto", fontSize: "1rem" }}
                                />
                              ) : null}
                            </div>
                          );
                        })
                      : null}
                  </Form.Item>
                </div>
              );
            })
          ) : (
            <Spin indicator={antIcon} />
          )}
        </Form>
        <Errors errors={validateRatios()}></Errors>
      </div>
    </Modal>
  );
};

export default RatioModal;
