import { Button, message, Affix } from "antd";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Confused from "../../../components/user/confused/Confused";
import QuestionBox from "../questions/QuestionBox";
import styles from "./questions-styles.module.css";
import c from "classnames/bind";
import useArray from "../useArray";
import Modal from "react-modal";
import { PlusCircleOutlined } from "@ant-design/icons";
import Survey from "../../user/survey/Survey";
import Errors from "../new-survey/Errors";
import BranchingLogic from "../branching-logic/BranchingLogic";
import { generateId } from "../../../utils";
import { branchingActions, questionTemplate } from "../../../constants";
import dotProp from "dot-prop-immutable";
import { isNumber } from "lodash-es";

const cx = c.bind(styles);

const modalStyles = {
  content: {
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    border: 0,
    padding: 0,
    borderRadius: 0,
  },
};

const actionsCleanUpOnMove = (rules) => {
  const getMaxQuestionIndex = (ruleIndex) => {
    const rule = dotProp.get(rules, `${ruleIndex}`);
    const maxIndex = rule.questions.reduce(
      (prev, curr) => Math.max(prev, curr.index),
      0
    );
    return maxIndex;
  };
  const newRules = rules.map((rl, i) => {
    const maxIndex = getMaxQuestionIndex(i);

    const actions = rl.actions.map((x) => {
      if (
        x.name === branchingActions.SKIP_TO_QUESTION &&
        x.question <= maxIndex
      ) {
        return {
          name: x.name,
        };
      }
      if (x.name === branchingActions.HIDE_QUESTION && x.question <= maxIndex) {
        return {
          name: x.name,
        };
      }
      return x;
    });

    return {
      ...rl,
      actions,
    };
  });

  return newRules;
};

const actionsCleanUpOnDelete = (rules) => {
  const newRules = rules.map((rl) => {
    let clearAction = false;
    rl.questions.forEach((x) => {
      if (!isNumber(x.index)) {
        clearAction = true;
        return;
      }
    });

    return {
      ...rl,
      actions: clearAction ? [{}] : rl.actions,
    };
  });

  return newRules;
};

const Questions = ({
  formQuestions,
  branchingRules,
  surveyType,
  setFormQuestions,
  setBranchingRules,
  errors,
  branchingErrors,
  createdAt,
  brandLogo,
  brandName,
  surveyInstructions,
  viewOnly,
}) => {
  const [questions, { add, remove, move, set }] = useArray(formQuestions);
  const [formModal, setFormModal] = useState(false);
  const [hideTryFormButton, setHideTryFormButton] = useState(false);
  const [branchingLogicModal, setBranchingLogicModal] = useState(false);
  useEffect(() => {
    setFormQuestions(questions);
  }, [questions, setFormQuestions]);

  const handleSaveBranchingRules = (rules) => {
    setBranchingRules(rules);
  };

  const handleAddQuestion = (index) => {
    add({ id: generateId(), ...questionTemplate }, index);
    const newBranchingRules = branchingRules.map((rl) => {
      const questions = rl.questions.map((x) => {
        if (x.index >= index) {
          return { ...x, index: x.index + 1 };
        }

        return x;
      });

      const actions = rl.actions.map((x) => {
        if (x.question >= index) {
          return { ...x, question: x.question + 1 };
        }

        return x;
      });

      return { questions, actions };
    });
    setBranchingRules(newBranchingRules);
  };

  const handleRemoveQuestion = (index) => {
    remove(index);
    const newBranchingRules = branchingRules.map((rl) => {
      const questions = rl.questions.map((x) => {
        if (x.index === index) {
          return { logicalOr: false };
        }

        if (x.index > index) {
          return { ...x, index: x.index - 1 };
        }

        return x;
      });

      const actions = rl.actions.map((x) => {
        if (x.question === index) {
          return { name: x.name };
        }

        if (x.question > index) {
          return { ...x, question: x.question - 1 };
        }

        return x;
      });

      return { questions, actions };
    });

    setBranchingRules(actionsCleanUpOnDelete(newBranchingRules));
  };

  const handleMoveQuestion = (source, destination) => {
    move(source, destination);
    const newBranchingRules = branchingRules.map((rl) => {
      const questions = rl.questions.map((x) => {
        if (x.index === source) {
          return { ...x, index: destination };
        }

        if (
          source > destination &&
          x.index >= destination &&
          x.index < source
        ) {
          // source > destination
          return { ...x, index: x.index + 1 };
        }

        if (
          source < destination &&
          x.index > source &&
          x.index <= destination
        ) {
          // source < destination
          return { ...x, index: x.index - 1 };
        }

        return x;
      });

      const actions = rl.actions.map((x) => {
        if (x.question === source) {
          return { ...x, question: destination };
        }

        if (
          source > destination &&
          x.question >= destination &&
          x.question < source
        ) {
          // source > destination
          return { ...x, question: x.question + 1 };
        }

        if (
          source < destination &&
          x.question > source &&
          x.question <= destination
        ) {
          // source < destination
          return { ...x, question: x.question - 1 };
        }

        return x;
      });

      return { questions, actions };
    });
    setBranchingRules(actionsCleanUpOnMove(newBranchingRules));
  };

  return (
    <React.Fragment>
      {formModal || hideTryFormButton ? null : (
        <div
          style={{
            position: "fixed",
            bottom: "200px",
            right: "10px",
            zIndex: "1000",
          }}
        >
          <Button
            type="primary"
            style={{ backgroundColor: "#00233F", borderRadius: "5px" }}
            onClick={() => {
              if (errors.length !== 0) {
                message.error(
                  "You have errors in your form. Please fix them first."
                );
                return;
              }
              setFormModal(true);
            }}
          >
            Try your form
          </Button>
        </div>
      )}

      <Confused style={{ width: "100%" }} />
      <DragDropContext
        onDragEnd={(result) => {
          if (result.destination)
            handleMoveQuestion(result.source.index, result.destination.index);
        }}
      >
        <Droppable droppableId="client-questions">
          {(provided) => (
            <div
              style={{
                width: "100%",
                pointerEvents: viewOnly ? "none" : "auto",
              }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {questions.map((question, index) => (
                <Draggable
                  key={`${question.id}-${index}`}
                  draggableId={`question-${question.id}-${index}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <QuestionBox
                      setHideTryFormButton={(value) =>
                        setHideTryFormButton(value)
                      }
                      ref={provided.innerRef}
                      index={index}
                      surveyType={surveyType}
                      brandLogo={brandLogo}
                      question={question}
                      setQuestion={(question) => set(question, index)}
                      addQuestion={(idx) => handleAddQuestion(idx)}
                      dragHandleProps={provided.dragHandleProps}
                      draggableProps={provided.draggableProps}
                      removeQuestion={() => handleRemoveQuestion(index)}
                      isDragging={snapshot.isDragging}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        className={cx("add-question-button")}
        onClick={() =>
          add({
            id: generateId(),
            ...JSON.parse(JSON.stringify(questionTemplate)),
          })
        }
        disabled={viewOnly}
      >
        <PlusCircleOutlined className={cx("add-question-icon")} />
        Add Question
      </Button>
      <Button
        className={cx("add-question-button", "grey-question-button")}
        onClick={() => setBranchingLogicModal(true)}
        disabled={new Date(createdAt) < new Date("2021-04-24") || viewOnly}
      >
        <PlusCircleOutlined className={cx("add-question-icon")} />
        Add Branching Logic
      </Button>
      <Modal
        isOpen={formModal}
        onRequestClose={() => setFormModal(false)}
        style={modalStyles}
      >
        {formModal && (
          <Survey
            questions={questions}
            rules={branchingRules}
            surveyType={surveyType}
            isOpen={formModal}
            onClose={() => setFormModal(false)}
            tryOwnForm={true}
            brandLogo={brandLogo}
            brandName={brandName}
            surveyInstructions={surveyInstructions}
          />
        )}
      </Modal>
      {branchingLogicModal && (
        <Modal
          isOpen={branchingLogicModal}
          onRequestClose={() => setBranchingLogicModal(false)}
          style={modalStyles}
        >
          <BranchingLogic
            closeModal={() => setBranchingLogicModal(false)}
            questions={questions}
            oldBranchingRules={branchingRules}
            onSaveRules={handleSaveBranchingRules}
          />
        </Modal>
      )}
      <Errors errors={[...errors, ...branchingErrors]} />
    </React.Fragment>
  );
};

export default Questions;
