import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Button,
  Modal,
  Input,
  notification,
  Dropdown,
  Menu,
  Typography,
  Spin,
} from "antd";
import ProjectLayout from "../../../components/page-layout/PageLayout";
import styles from "./project.module.css";
import c from "classnames/bind";
import { notify } from "../../../utils";
import axios, { Routes } from "../../../services/API";
import { FolderIcon, SharedFolderIcon } from "../../../components/folderIcon";
import {
  ShareAltOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import ShareModal from "../ShareModal";
import { cloneDeep } from "lodash";
import { getClientDisplayName } from "../../../constants";
import { data } from "jquery";

const cx = c.bind(styles);
const { Text } = Typography;

const getProjects = async () => {
  try {
    const clientId = localStorage.getItem("client");
    const { data } = await axios({
      ...Routes.client.getProjects(clientId),
    });
    return data;
  } catch (err) {
    console.log("failed to fetch projects", err);
  }
};

const Project = ({ createProject, profile }) => {
  const pendingRequests = useSelector((state) => state.pendingRequests);
  const history = useHistory();
  const [projectName, setProjectName] = useState("");
  const [createProjectModal, setCreateProjectModal] = useState(false);
  const [selfProjects, setSelfProjects] = useState([]);
  const [sharedProjects, setSharedProjects] = useState([]);

  const [allUsers, setAllUsers] = useState([]);

  const [shareModal, setShareModal] = useState(false);
  const [projectToShare, setProjectToShare] = useState(null);
  const [projectToEdit, setProjectToEdit] = useState(null);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [deleteProjectModal, setDeleteProjectModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isFetching = false;
    (async () => {
      try {
        setLoading(true);
        isFetching = true;
        const data = await getProjects();

        if (isFetching) {
          setSelfProjects(data.selfProjects);
          setSharedProjects(data.sharedProjects);
          setLoading(false);
        }
      } catch (err) {
        console.log("failed to fetch projects", err);
      }
    })();

    return () => {
      isFetching = false;
    };
  }, []);

  useEffect(() => {
    let fetchingUsers = true;
    if (profile?.account?._id) {
      (async () => {
        const clientId = localStorage.getItem("client");
        const { data } = await axios({
          ...Routes.client.getAccountUsers(clientId, profile.account._id),
        });
        if (fetchingUsers) {
          setAllUsers(data.allUsers);
        }
      })();
    }
    return () => {
      fetchingUsers = false;
    };
  }, [profile?.account?._id]);

  const updateProjectShare = async (sharedClients) => {
    const clientId = localStorage.getItem("client");
    await axios({
      ...Routes.client.editProjectShare(clientId, projectToShare._id),
      data: { data: { shared: sharedClients } },
    });
    const newSelfProjects = cloneDeep(selfProjects);
    const projectToUpdateIndex = newSelfProjects.findIndex(
      (project) => project._id === projectToShare._id
    );
    newSelfProjects[projectToUpdateIndex].shared = sharedClients;
    setSelfProjects(newSelfProjects);
    // setSharedUsers(sharedClients);
    notification.success({
      message: "Updated shared users list for the project",
    });
  };

  const closeModal = () => {
    setProjectName("");
    setProjectToEdit(null);
    setCreateProjectModal(false);
  };

  const closeDeleteModal = () => {
    setDeleteProjectModal(false);
    setProjectToDelete(null);
  };

  const deleteProject = async () => {
    const clientId = localStorage.getItem("client");
    await axios({
      ...Routes.client.deleteProject(clientId, projectToDelete._id),
    });
    const newSelfProjects = cloneDeep(selfProjects);
    setSelfProjects(
      newSelfProjects.filter((project) => project._id !== projectToDelete._id)
    );
    notification.success({
      message: "Project deleted successfully",
    });
    closeDeleteModal();
  };

  const createProjectHandler = async () => {
    if (!projectName) {
      notify("error", "Error", "Project name cannot be empty");
      return;
    }
    if (projectToEdit === null) {
      await createProject(projectName);
    } else {
      const clientId = localStorage.getItem("client");
      const { data } = await axios({
        ...Routes.client.editProjectName(clientId, projectToEdit._id),
        data: { data: { projectName: projectName } },
      });
      const newSelfProjects = cloneDeep(selfProjects);
      const projectToUpdateIndex = newSelfProjects.findIndex(
        (project) => project._id === projectToEdit._id
      );
      newSelfProjects[projectToUpdateIndex].name = projectName;
      setSelfProjects(newSelfProjects);
      notification.success({
        message: "Project name updated successfully",
      });
    }

    closeModal();
  };

  const routeToProject = (id) => {
    history.push(`/client/project/${id}`);
  };

  return (
    <React.Fragment>
      <ProjectLayout>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0 5% 10px",
          }}
        >
          <div style={{ fontSize: 18 }}>Your projects</div>
          <Button
            style={{
              backgroundColor: "#00233f",
              color: "white",
              border: "none",
              textTransform: "uppercase",
              borderRadius: 4,
              width: 150,
            }}
            onClick={() => setCreateProjectModal(true)}
          >
            New Project
          </Button>
        </div>
        <Spin
          style={{ background: "none" }}
          spinning={pendingRequests == 0 && loading}
          size={"middle"}
        >
          <div className="grid-form-container">
            {selfProjects
              .sort((p1, p2) => {
                return (
                  new Date(p2.createdAt).getTime() -
                  new Date(p1.createdAt).getTime()
                );
              })
              .map((project) => (
                <div
                  key={project._id}
                  className={cx("project-container")}
                  onClick={() => routeToProject(project._id)}
                >
                  {project.shared.length > 0 ? (
                    <SharedFolderIcon />
                  ) : (
                    <FolderIcon />
                  )}
                  <div style={{ width: "calc(100% - 80px)" }}>
                    <div>{project.name}</div>
                    <Text
                      style={{
                        fontSize: 14,
                        color: "#777777",
                      }}
                      ellipsis={{
                        tooltip: true,
                      }}
                    >
                      {project.surveyCount} Survey
                      {project.surveyCount !== 1 && "s"} - Created by{" "}
                      {getClientDisplayName(profile)}
                    </Text>
                  </div>
                  <div style={{ position: "absolute", bottom: 16, right: 16 }}>
                    <Dropdown
                      trigger={["click"]}
                      overlay={
                        <Menu>
                          <Menu.Item
                            icon={<EditOutlined />}
                            onClick={(e) => {
                              e.domEvent.stopPropagation();
                              setProjectToEdit({ ...project });
                              setProjectName(project.name);
                              setCreateProjectModal(true);
                            }}
                          >
                            Edit
                          </Menu.Item>
                          <Menu.Item
                            icon={<ShareAltOutlined />}
                            onClick={(e) => {
                              e.domEvent.stopPropagation();
                              setProjectToShare({ ...project });
                              setShareModal(true);
                            }}
                          >
                            Share
                          </Menu.Item>
                          <Menu.Item
                            icon={<DeleteOutlined />}
                            onClick={(e) => {
                              e.domEvent.stopPropagation();
                              setProjectToDelete({ ...project });
                              setDeleteProjectModal(true);
                            }}
                          >
                            Delete
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <MoreOutlined
                        style={{ fontSize: 24 }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    </Dropdown>
                  </div>
                </div>
              ))}
            <div
              className={cx("add-project-container")}
              onClick={() => setCreateProjectModal(true)}
            >
              <FolderIcon />
              <div>Add New Project</div>
            </div>
          </div>
        </Spin>
        {sharedProjects.length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "24px 5% 10px",
              }}
            >
              <div style={{ fontSize: 18 }}>Projects shared with you</div>
            </div>
            <div className="grid-form-container">
              {sharedProjects
                .sort((p1, p2) => {
                  return (
                    new Date(p2.createdAt).getTime() -
                    new Date(p1.createdAt).getTime()
                  );
                })
                .map((project) => (
                  <div
                    key={project._id}
                    className={cx("project-container")}
                    onClick={() => routeToProject(project._id)}
                  >
                    {/* <FolderIcon /> */}
                    <SharedFolderIcon />
                    <div style={{ width: "calc(100% - 40px)" }}>
                      <div>{project.name}</div>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#777777",
                        }}
                        ellipsis={{ tooltip: true }}
                      >
                        {project.surveyCount} Survey
                        {project.surveyCount !== 1 && "s"} - Created by{" "}
                        {getClientDisplayName(
                          allUsers?.find(
                            (user) => user.clientId === project.owner
                          )
                        )}
                      </Text>
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </ProjectLayout>
      <Modal
        visible={createProjectModal}
        closable={false}
        footer={null}
        onCancel={closeModal}
      >
        <div
          style={{
            fontSize: 16,
            marginBottom: 10,
          }}
        >
          {projectToEdit === null ? "Create new project" : "Edit project name"}
        </div>
        <div>
          <Input
            placeholder="Enter the project name here..."
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            className={cx("input-box")}
          />
        </div>
        <div
          style={{
            paddingBottom: 24,
            marginTop: 18,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            columnGap: 8,
          }}
        >
          <Button
            style={{
              backgroundColor: "#b5b5b5",
              color: "white",
              border: "none",
              textTransform: "uppercase",
              borderRadius: 4,
              width: 150,
            }}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#00233f",
              color: "white",
              border: "none",
              textTransform: "uppercase",
              borderRadius: 4,
              width: 150,
            }}
            onClick={createProjectHandler}
          >
            {projectToEdit === null ? "Create" : "Update"}
          </Button>
        </div>
      </Modal>
      {projectToDelete !== null && (
        <Modal
          visible={deleteProjectModal}
          closable={false}
          footer={null}
          onCancel={closeDeleteModal}
        >
          <div
            style={{
              fontSize: 16,
              marginBottom: 10,
            }}
          >
            Delete Project
          </div>
          <p>
            Are you sure you want to delete "{projectToDelete.name}" project?
            All the surveys in the project will be deleted.
          </p>
          <div
            style={{
              paddingBottom: 24,
              marginTop: 18,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              columnGap: 8,
            }}
          >
            <Button
              style={{
                backgroundColor: "#b5b5b5",
                color: "white",
                border: "none",
                textTransform: "uppercase",
                borderRadius: 4,
                width: 150,
              }}
              onClick={closeDeleteModal}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: "#eb3f4a",
                color: "white",
                border: "none",
                textTransform: "uppercase",
                borderRadius: 4,
                width: 150,
              }}
              onClick={deleteProject}
            >
              Delete
            </Button>
          </div>
        </Modal>
      )}
      {projectToShare && (
        <ShareModal
          open={shareModal}
          onCancel={() => setShareModal(false)}
          updateSharedList={updateProjectShare}
          projectName={projectToShare.name}
          owner={projectToShare.owner}
          sharedUsers={projectToShare.shared || []}
          allUsers={allUsers}
        />
      )}
    </React.Fragment>
  );
};

export default Project;
