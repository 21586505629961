import "./reg-questions.css";
import React, { useCallback } from "react";
// libraries
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// antd
import { Form, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
// components
import ProjectPageLayout, {
  Content,
} from "../../../components/page-layout/PageLayout";
import Topbar from "../../../components/topbar/Topbar";
import Sidenav from "../../../components/admin/sidenav/Sidenav";
import QuestionBox from "../../../components/admin/question-box/QuestionBox";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getAdminQuestions,
  updateAdminQuestions,
} from "../../../actions/admin";
// actions
import {
  decrementPendingRequests,
  incrementPendingRequests,
  notify,
} from "../../../utils";
import { adminPages } from "../../../constants";
const ObjectID = require("bson-objectid");

const RegQuestions = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const displayAt = "regQuestions";

  // Api calls
  const getAllQuestions = useCallback(() => {
    incrementPendingRequests();
    dispatch(getAdminQuestions())
      .then((questions) => {
        const activeQuestions = questions.filter(
          (val) => val.isActive && val.displayAt === displayAt
        );
        form.setFieldsValue({ questions: activeQuestions });
      })
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  }, [dispatch, form]);

  useEffect(() => {
    getAllQuestions();
  }, [getAllQuestions]);

  // Handlers
  const onFormSubmit = ({ questions }) => {
    questions = questions.filter((val) => val);
    questions.forEach((obj) =>
      Object.keys(obj).forEach(
        (key) => obj[key] === undefined && delete obj[key]
      )
    );

    questions.forEach((val) => {
      if (!val._id) {
        val._id = ObjectID();
      }
    });

    questions.forEach((val) => {
      if (val.questionObject.options) {
        val.questionObject.options = val.questionObject.options.filter(
          (el) => el
        );
      }
    });

    incrementPendingRequests();
    dispatch(updateAdminQuestions(questions))
      .then(() => {
        notify("success", "Success", "Questions updated");
        getAllQuestions();
      })
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  };

  return (
    <React.Fragment>
      <Topbar>
        <Sidenav drawer selectedItem={adminPages.REGQUESTIONS} />
      </Topbar>
      <Sidenav selectedItem={adminPages.REGQUESTIONS} />
      <ProjectPageLayout>
        <Content style={{ display: "flex", justifyContent: "center" }}>
          <div className="reg-questions-container">
            <Form form={form} style={{ marginTop: 20 }} onFinish={onFormSubmit}>
              <Form.List name="questions">
                {(fields, { add, remove, move }) => {
                  return (
                    <React.Fragment key={fields.name}>
                      <div style={{ minHeight: 350 * fields.length }}>
                        <DragDropContext
                          onDragEnd={(result) =>
                            move(result.source.index, result.destination.index)
                          }
                        >
                          <Droppable droppableId="questions">
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {fields.map((field, index) => (
                                  <Draggable
                                    draggableId={field.name.toString()}
                                    index={index}
                                    key={index}
                                  >
                                    {(provided) => (
                                      <div
                                        {...provided.draggableProps}
                                        ref={provided.innerRef}
                                      >
                                        <Form.Item {...field}>
                                          <QuestionBox
                                            questionNumber={index + 1}
                                            removeQuestion={() =>
                                              remove(field.name)
                                            }
                                            addQuestion={(newIndex) =>
                                              add(null, newIndex)
                                            }
                                            dragHandleProps={
                                              provided.dragHandleProps
                                            }
                                            displayAt={displayAt}
                                          />
                                        </Form.Item>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          onClick={() => add()}
                          size="large"
                          style={{ width: "50%" }}
                        >
                          Add question <PlusOutlined />
                        </Button>
                      </div>
                    </React.Fragment>
                  );
                }}
              </Form.List>
              <Button
                htmlType="submit"
                size="large"
                type="primary"
                className="save-btn"
              >
                Save
              </Button>
            </Form>
          </div>
        </Content>
      </ProjectPageLayout>
    </React.Fragment>
  );
};

export default RegQuestions;
