import React, { Fragment, useState, useEffect } from "react";
import { Button, Row, Col, notification } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import c from "classnames/bind";
import { CustomModal } from "../Components";
import useArray from "../useArray";
import styles from "./criteria.module.css";
import AdditionalCriteria from "./AdditionalCriteria";
import ScreeningQuestion from "./ScreeningQuestion";

const cx = c.bind(styles);

const questionTemplate = {
  questionObject: {
    text: "",
    hasLink: false,
    link: "",
    type: "default",
    options: {
      multiChoices: [
        {
          label: "",
        },
        {
          label: "",
        },
      ],
    },
  },
  answer: [],
};

const AddScreeningQuestion = ({
  screeningQuestions,
  setScreeningQuestions,
}) => {
  const [modal, setModal] = useState(false);
  const [incompleteQues, setIncompleteQues] = useState(questionTemplate);
  const [questions, { add, remove }] = useArray(screeningQuestions);

  useEffect(() => {
    setScreeningQuestions(questions);
  }, [questions, setScreeningQuestions]);

  const checkAdditionalQuestion = () => {
    const { questionObject } = incompleteQues;
    const text = questionObject.text === "" ? false : true;
    const type = questionObject.type === "default" ? false : true;
    const options =
      questionObject.options.multiChoices.filter((item) => item.label === "")
        .length > 0
        ? false
        : true;
    console.log(options);
    if (text && type && options) return true;
    else return false;
  };

  const handleOk = () => {
    if (checkAdditionalQuestion()) {
      add(incompleteQues);
      setModal(false);
      setIncompleteQues(questionTemplate);
    } else {
      notification.error({
        message: "Please complete the question first",
      });
    }
  };

  return (
    <Fragment>
      <Row justify="space-between" gutter={[8, 8]}>
        <Col xs={24} sm={16}>
          <h3 style={{ margin: 0, fontWeight: "bold" }}>
            Add screening questions
          </h3>
          <div>
            Only participants who answer all the screening questions correctly
            will be able to take the survey.
          </div>
        </Col>
        <Col>
          <Button
            style={{
              backgroundColor: "#00233f",
              color: "white",
              border: "none",
              borderRadius: 4,
              height: 36,
              textTransform: "uppercase",
            }}
            onClick={() => {
              setIncompleteQues(questionTemplate);
              setModal(true);
            }}
          >
            Add question
          </Button>
        </Col>
      </Row>
      {screeningQuestions.length !== 0 && (
        <div
          style={{
            margin: "16px 0px",
          }}
        >
          Select qualifying options for the questions
        </div>
      )}
      {screeningQuestions.map((e, i) => (
        <ScreeningQuestion
          key={i}
          screeningQuestions={screeningQuestions}
          i={i}
          setScreeningQuestions={setScreeningQuestions}
          question={e}
          remove={remove}
        />
      ))}
      <div className={cx("modal-container")}>
        <CustomModal
          className={cx("customModal")}
          visible={modal}
          onCancel={() => {
            setIncompleteQues(questionTemplate);
            setModal(false);
          }}
          width={600}
          maskClosable={false}
          okText="Add Question"
          onOk={handleOk}
          closeIcon={
            <CloseCircleOutlined
              style={{
                color: "#404040",
                fontSize: "24px",
                position: "relative",
                top: "calc(-50% + 23px)",
                right: "calc(-50% + 18px)",
              }}
            />
          }
        >
          <AdditionalCriteria
            question={incompleteQues.questionObject}
            setQuestion={(questionObject) =>
              setIncompleteQues({ answer: [], questionObject })
            }
          />
        </CustomModal>
      </div>
    </Fragment>
  );
};

export default AddScreeningQuestion;
