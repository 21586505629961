import React from "react";
import { capitalizeFirstCharacter } from "../../../utils";
import { Row, Radio, Checkbox, Col } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import c from "classnames/bind";
import styles from "./criteria.module.css";
const cx = c.bind(styles);

const ScreeningQuestion = ({
  screeningQuestions,
  i,
  setScreeningQuestions,
  question,
  remove,
}) => {
  return (
    <div className={cx("question-box")}>
      <Row justify="space-between">
        <Col span={20}>
          {capitalizeFirstCharacter(question.questionObject.text)}

          <div className={cx("container")}>
            {question.questionObject.options.multiChoices.map((opt, idx) => (
              <label key={idx} className={cx("option-button")}>
                {question.questionObject.type === "multiChoices" && (
                  <Radio
                    // type="radio"
                    name={question.questionObject.text}
                    checked={question.answer.includes(idx)}
                    className={cx("option-button-icon")}
                    onChange={(event) => {
                      const res = [...screeningQuestions];
                      res[i].answer = [idx];
                      setScreeningQuestions(res);
                    }}
                  />
                )}
                {question.questionObject.type === "checkBoxes" && (
                  <Checkbox
                    // type="checkbox"
                    name={question.questionObject.text}
                    checked={question.answer.includes(idx)}
                    className={cx("option-button-icon")}
                    onChange={(event) => {
                      const res = [...screeningQuestions];
                      const exists = question.answer.includes(idx);
                      if (exists) {
                        res[i].answer = question.answer.filter(
                          (it) => it !== idx
                        );
                      } else {
                        res[i].answer.push(idx);
                      }
                      setScreeningQuestions(res);
                    }}
                  />
                )}
                {opt.label}
              </label>
            ))}
          </div>
        </Col>
        <Col
          style={{
            alignSelf: "center",
          }}
        >
          <DeleteOutlined
            style={{
              float: "right",
              cursor: "pointer",
              fontSize: "150%",
              backgroundColor: "#8edad0",
              color: "white",
              padding: 10,
              borderRadius: 4,
            }}
            onClick={() => {
              remove(i);
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ScreeningQuestion;
