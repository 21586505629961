import React, { useEffect, useRef } from "react";
import {
  Modal,
  Input,
  InputNumber,
  Button,
  Checkbox,
  notification,
} from "antd";
import { cloneDeep, isEqual } from "lodash";
import { useSelector } from "react-redux";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import styles from "./TranscriptVideoModal.module.css";
import c from "classnames/bind";
import { useState } from "react";
import {
  CloseCircleFilled,
  VideoCameraFilled,
  EditFilled,
  DeleteFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import {
  dullGreenButtonStyle,
  greyButtonStyle,
} from "../../../constants/styles";
import {
  convertReadableToSecond,
  convertSecondsToReadable,
} from "../../../../../../constants";

const cx = c.bind(styles);

const Note = ({ note, updateNote }) => {
  const [editing, setEditing] = useState(false);
  const [tempNote, setTempNote] = useState(null);

  const updateNoteTime = (value, subKey) => {
    const splitIndex = subKey === "min" ? 0 : 1;
    const tempValue = convertSecondsToReadable(tempNote.start).split(":");
    tempValue[splitIndex] = (value || 0).toString();
    const newStart = convertReadableToSecond(tempValue.join(":"));
    setTempNote({ ...tempNote, start: newStart, end: newStart });
  };

  return (
    <div style={{ marginBottom: 20 }}>
      {editing ? (
        <>
          <div
            style={{
              color: "rgb(130, 163, 182)",
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
            }}
          >
            <InputNumber
              className={cx("edit-time-input")}
              size="small"
              max="59"
              min="0"
              value={convertSecondsToReadable(tempNote.start).split(":")[0]}
              onChange={(value) => {
                updateNoteTime(value, "min");
              }}
            />{" "}
            :{" "}
            <InputNumber
              className={cx("edit-time-input")}
              size="small"
              max="59"
              min="0"
              value={convertSecondsToReadable(tempNote.start).split(":")[1]}
              onChange={(value) => {
                updateNoteTime(value, "sec");
              }}
            />
            <CheckCircleOutlined
              style={{ color: "white", margin: "0 10px", fontSize: 22 }}
              onClick={() => {
                updateNote(tempNote);
                setEditing(false);
              }}
            />
            <CloseCircleOutlined
              style={{ color: "white", fontSize: 22 }}
              onClick={() => setEditing(false)}
            />
          </div>
          <div>
            <Input
              value={tempNote.text}
              onChange={(e) => {
                setTempNote({ ...tempNote, text: e.target.value });
              }}
              className={cx("edit-text-input")}
            />
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              color: "rgb(130, 163, 182)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: "white",
                backgroundColor: "#0a3157",
                fontSize: 8,
                marginRight: 10,
                display: "inline-flex",
                width: 24,
                height: 24,
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid white",
                borderRadius: "50%",
              }}
            >
              {note.userInitial}
            </span>
            <span>{convertSecondsToReadable(note.start)}</span>
            <span
              style={{
                cursor: "pointer",
                marginLeft: 8,
                textDecoration: "underline",
              }}
              onClick={() => {
                setTempNote(cloneDeep(note));
                setEditing(true);
              }}
            >
              Edit
            </span>
          </div>
          <div style={{ color: "white" }}>{note.text}</div>
        </>
      )}
    </div>
  );
};

const TranscriptVideoModal = ({
  visible,
  subtitles,
  videoUrl,
  onCancel,
  transcript,
  notes,
  updateTranscript,
  updateNote,
  reels,
  createReel,
  removeReel,
  participantIndex,
}) => {
  const [videoPlayerSourceUrl, setVideoPlayerSourceUrl] = useState(videoUrl);
  const [subtitlesUrl, setSubtitleUrl] = useState(null);
  const [notesVisible, setNotesVisible] = useState(false);
  const [transcriptVisible, setTranscriptVisible] = useState(false);
  const [recordingReel, setRecordingReel] = useState(false);
  const [reelStartTime, setReelStartTime] = useState(0);
  const [reelEndTime, setReelEndTime] = useState(0);
  const [timer, setTimer] = useState(null);
  const [draftTranscript, setDraftTranscript] = useState([]);
  const [editTranscript, setEditTranscript] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [addToTranscript, setAddToTranscript] = useState(false);
  const [deleteReelModal, setDeleteReelModal] = useState(false);
  const [reelToDelete, setReelToDelete] = useState(null);

  const [createReelModal, setCreateReelModal] = useState(false);
  const [reelToCreate, setReelToCreate] = useState(null);

  const [editReelModal, setEditReelModal] = useState(false);
  const [reelToEdit, setReelToEdit] = useState(null);
  const [editReelStartTime, setEditReelStartTime] = useState(0);
  const [editReelEndTime, setEditReelEndTime] = useState(0);
  const [recordingEditReel, setRecordingEditReel] = useState(0);
  const [reelTimeEdited, setReelTimeEdited] = useState(false);
  const [editTimer, setEditTimer] = useState(null);

  useEffect(() => {
    if (!editReelModal) {
      setEditReelStartTime(0);
      setEditReelEndTime(0);
      setReelTimeEdited(false);
      setRecordingEditReel(false);
    }
  }, [editReelModal]);

  const videoPlayer = useRef();
  const editVideoPlayer = useRef();

  const [userInitials, setUserInitials] = useState("");
  const profile = useSelector((state) => state.client.profile);

  const [tempReels, setTempReels] = useState([]);

  const [unsavedReelsModal, setUnsavedReelsModal] = useState(false);
  const [reelName, setReelName] = useState("");

  useEffect(() => {
    let initial = "";
    if (profile.firstName) {
      initial += profile.firstName[0].toUpperCase();
      if (profile.lastName) {
        initial += profile.lastName[0].toUpperCase();
      }
    } else if (profile.email) {
      initial += profile.email[0].toUpperCase();
    } else {
      initial = "??";
    }
    setUserInitials(initial);
  }, [profile]);

  const onCloseVideoModal = () => {
    if (tempReels.length > 0) {
      setUnsavedReelsModal(true);
    } else {
      onCancel();
    }
  };

  const playReel = (start, end) => {
    setVideoPlayerSourceUrl(`${videoUrl}#t=${start},${end}`);
    if (videoPlayer.current) {
      videoPlayer.current.load();
      videoPlayer.current.play();
    }
  };

  const deleteReel = async () => {
    if (reelToDelete.url) {
      await removeReel(reelToDelete.url, reelToDelete._id);
    } else {
      setTempReels(tempReels.filter((reel) => !isEqual(reel, reelToDelete)));
    }
    setReelToDelete(null);
  };

  const editReel = () => {
    const newTempReels = cloneDeep(tempReels);
    const reelIndex = newTempReels.findIndex((reel) =>
      isEqual(reel, reelToEdit)
    );
    const newReel = {
      ...newTempReels[reelIndex],
      start: editReelStartTime,
      end: editReelEndTime,
    };
    newTempReels.splice(reelIndex, 1, newReel);
    setTempReels(newTempReels);
  };

  const createNewReel = async () => {
    await createReel(reelToCreate.start, reelToCreate.end, reelName);
    setTempReels(tempReels.filter((reel) => !isEqual(reel, reelToCreate)));
    setReelToCreate(null);
  };

  const addNote = () => {
    if (!newNote) {
      notification.error({ message: "Please Enter some text" });
      return;
    }
    const tempNotes = cloneDeep(notes || []);
    const time = videoPlayer.current ? videoPlayer.current.currentTime : 0;
    const noteObj = {
      key: new Date().getSeconds(),
      identifier: Date.now(),
      start: time,
      text: newNote,
      end: time,
      isReel: false,
      reelUrl: "",
      isNote: true,
      userInitial: userInitials,
    };

    tempNotes.push(noteObj);
    updateNote(tempNotes.sort((n1, n2) => n1.start - n2.start));

    if (addToTranscript) {
      const tempTranscript = cloneDeep(transcript || []);
      tempTranscript.push(noteObj);
      updateTranscript(tempTranscript.sort((t1, t2) => t1.start - t2.start));
    }

    setNewNote("");
    setAddToTranscript(false);
  };

  useEffect(() => {
    if (subtitles) {
      const file = new Blob([subtitles], { type: "text/vtt" });
      setSubtitleUrl(URL.createObjectURL(file));
    }
  }, [subtitles]);

  useEffect(() => {
    if (recordingReel && videoPlayer?.current && !timer) {
      setTimer(
        setInterval(() => {
          const videoTime = videoPlayer.current.currentTime;
          setReelEndTime(videoTime);
        }, 500)
      );
    } else if (!recordingReel && timer) {
      clearInterval(timer);
      setTimer(null);
    }
  }, [recordingReel, timer, videoPlayer.current]);

  useEffect(() => {
    if (reelEndTime < reelStartTime) {
      setReelStartTime(reelEndTime);
    }
  }, [reelStartTime, reelEndTime]);

  useEffect(() => {
    if (recordingEditReel && editVideoPlayer?.current && !editTimer) {
      setEditTimer(
        setInterval(() => {
          const videoTime = editVideoPlayer.current.currentTime;
          setEditReelEndTime(videoTime);
        }, 500)
      );
    } else if (!recordingEditReel && editTimer) {
      clearInterval(editTimer);
      setEditTimer(null);
    }
  }, [recordingEditReel, editTimer, editVideoPlayer.current]);

  useEffect(() => {
    if (editReelEndTime < editReelStartTime) {
      setEditReelStartTime(editReelEndTime);
    }
  }, [editReelStartTime, editReelEndTime]);

  const updateDraftTranscript = (index, key, value, subKey) => {
    const tempTranscript = cloneDeep(draftTranscript);
    if (key === "start" || key === "end") {
      const splitIndex = subKey === "min" ? 0 : 1;
      const tempValue = convertSecondsToReadable(
        tempTranscript[index][key]
      ).split(":");
      tempValue[splitIndex] = (value || 0).toString();
      tempTranscript[index][key] = convertReadableToSecond(tempValue.join(":"));
    } else {
      tempTranscript[index][key] = value;
    }
    setDraftTranscript(tempTranscript);
  };

  return (
    <Modal
      visible={visible}
      className={cx("transcript-video-modal")}
      centered={true}
      width="100%"
      onCancel={onCloseVideoModal}
      footer={null}
      closable={false}
    >
      <RemoveScrollBar />
      <div className={cx("video-modal-header")}>
        <div className={cx("participant-title")}>
          Participant no. {participantIndex + 1}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 22,
          }}
        >
          <span
            className="fi fi-rr-edit"
            style={{
              height: 22,
              marginRight: 32,
              cursor: "pointer",
              color: notesVisible ? "white" : "black",
            }}
            onClick={() => setNotesVisible(!notesVisible)}
          ></span>
          <span
            className="fi fi-rr-subtitles"
            style={{
              height: 24,
              marginRight: 60,
              cursor: "pointer",
              color: transcriptVisible ? "white" : "black",
            }}
            onClick={() => setTranscriptVisible(!transcriptVisible)}
          ></span>
          <CloseCircleFilled
            style={{
              fontSize: 26,
            }}
            onClick={onCloseVideoModal}
          />
        </div>
      </div>
      <div className={cx("video-modal-body")}>
        <div className={cx("video-reel-container")}>
          <div className={cx("video-container")}>
            <video
              ref={videoPlayer}
              autoPlay={true}
              width="100%"
              height="auto"
              background="black"
              controls={true}
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                backgroundColor: "black",
                borderRadius: 16,
              }}
            >
              <source src={videoPlayerSourceUrl}></source>
              <track src={subtitlesUrl} srcLang="en" default />
            </video>
          </div>
          <div className={cx("reels-container")}>
            <div className={cx("record-reel-container")}>
              <Button
                style={{
                  ...dullGreenButtonStyle,
                  fontSize: 20,
                  height: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: 0,
                  border: "none",
                  textTransform: "uppercase",
                }}
                onClick={() => {
                  if (!recordingReel && videoPlayer.current) {
                    const videoTime = videoPlayer.current.currentTime;
                    setReelEndTime(videoTime);
                    setReelStartTime(videoTime);
                  } else {
                    setTempReels([
                      ...tempReels,
                      {
                        start: reelStartTime,
                        end: reelEndTime,
                      },
                    ]);
                    setReelStartTime(0);
                    setReelEndTime(0);
                  }
                  setRecordingReel(!recordingReel);
                }}
              >
                <VideoCameraFilled />
                {recordingReel ? "Stop recording" : "Record reel"}
              </Button>
              <div
                style={{
                  color: "white",
                  fontSize: 18,
                  padding: 4,
                }}
              >
                {convertSecondsToReadable(reelStartTime)} -{" "}
                {convertSecondsToReadable(reelEndTime)}
              </div>
            </div>
            <div
              style={{
                whiteSpace: "nowrap",
                overflowX: "scroll",
                display: "flex",
                alignItems: "center",
                columnGap: 8,
              }}
            >
              {[...tempReels, ...reels]
                .sort((r1, r2) => r1.start - r2.start)
                .map((reel, index) => (
                  <div key={`reel-${index}`} className={cx("reel-container")}>
                    {reel.url ? (
                      <div
                        style={{
                          color: "rgb(130, 163, 182)",
                          height: 22,
                          overflow: "hidden",
                          maxWidth: "100%",
                        }}
                      >
                        <div
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          title={reel.name ?? ""}
                        >
                          {reel.name ?? ""}
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          color: "rgb(0, 193, 148)",
                          textDecoration: "underline",
                          cursor: "pointer",
                          height: 22,
                        }}
                        onClick={() => {
                          setReelToCreate(reel);
                          setReelName("");
                          setCreateReelModal(true);
                        }}
                      >
                        Add To reel
                      </div>
                    )}
                    <div className={cx("reel-wrapper")}>
                      <video
                        width="100%"
                        height="auto"
                        style={{
                          maxHeight: "100%",
                          maxWidth: "100%",
                          borderRadius: 6,
                        }}
                      >
                        <source
                          src={
                            reel.url
                              ? reel.url
                              : `${videoUrl}#t=${reel.start},${reel.end}`
                          }
                        />
                      </video>
                      <div className={cx("reel-overlay")}>
                        <CaretRightOutlined
                          onClick={() => {
                            playReel(reel.start, reel.end);
                          }}
                        />
                        {!reel.url && (
                          <EditFilled
                            onClick={() => {
                              setReelToEdit(reel);
                              setEditReelModal(true);
                            }}
                          />
                        )}
                        <DeleteFilled
                          onClick={() => {
                            setReelToDelete(reel);
                            setDeleteReelModal(true);
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        color: "rgb(130, 163, 182)",
                      }}
                    >
                      {convertSecondsToReadable(reel.start)} -{" "}
                      {convertSecondsToReadable(reel.end)}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {(notesVisible || transcriptVisible) && (
          <div className={cx("notes-transcript-container")}>
            {transcriptVisible && (
              <div className={cx("transcript-container")}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "rgb(130, 163, 182)",
                      fontSize: 18,
                    }}
                  >
                    Video Transcript
                  </div>
                  <div>
                    {editTranscript ? (
                      <>
                        <Button
                          onClick={() => {
                            setEditTranscript(false);
                          }}
                          style={{
                            backgroundColor: "#0c4674",
                            color: "white",
                            border: "none",
                            margin: "0px 12px 0px 0px",
                            textTransform: "uppercase",
                            transition: "none",
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            const tempTranscript = cloneDeep(draftTranscript);

                            updateTranscript(
                              tempTranscript.sort(
                                (t1, t2) => t1.start - t2.start
                              )
                            );
                            setEditTranscript(false);
                          }}
                          style={{
                            ...dullGreenButtonStyle,
                            margin: 0,
                            border: "none",
                            textTransform: "uppercase",
                            transition: "none",
                          }}
                        >
                          Save
                        </Button>
                      </>
                    ) : (
                      <Button
                        onClick={() => {
                          setDraftTranscript(cloneDeep(transcript || []));
                          setEditTranscript(true);
                        }}
                        style={{
                          ...dullGreenButtonStyle,
                          margin: 0,
                          border: "none",
                          textTransform: "uppercase",
                          transition: "none",
                        }}
                      >
                        Edit transcript
                      </Button>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    overflow: "scroll",
                  }}
                >
                  {editTranscript
                    ? draftTranscript.map((t, index) => (
                        <div
                          style={{ marginTop: 22 }}
                          key={`transcript-edit-${index}`}
                        >
                          <div
                            style={{
                              color: "rgb(130, 163, 182)",
                              fontSize: 14,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {t.isNote && (
                              <span
                                style={{
                                  color: "white",
                                  backgroundColor: "#0a3157",
                                  fontSize: 8,
                                  marginRight: 10,
                                  display: "inline-flex",
                                  width: 24,
                                  height: 24,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  border: "1px solid white",
                                  borderRadius: "50%",
                                }}
                              >
                                {t.userInitial}
                              </span>
                            )}
                            {convertSecondsToReadable(t.start)}{" "}
                            {!t.isNote && (
                              <span>- {convertSecondsToReadable(t.end)}</span>
                            )}
                          </div>
                          <div>
                            <Input.TextArea
                              value={t.text}
                              onChange={(e) => {
                                updateDraftTranscript(
                                  index,
                                  "text",
                                  e.target.value
                                );
                              }}
                              className={cx("edit-text-input")}
                              autoSize={{ minRows: 1, maxRows: 3 }}
                            />
                          </div>
                        </div>
                      ))
                    : transcript.map((t, index) => (
                        <div
                          style={{ marginTop: 16 }}
                          key={`transcript-${index}`}
                        >
                          <div
                            style={{
                              color: "rgb(130, 163, 182)",
                              fontSize: 14,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {t.isNote && (
                              <span
                                style={{
                                  color: "white",
                                  backgroundColor: "#0a3157",
                                  fontSize: 8,
                                  marginRight: 10,
                                  display: "inline-flex",
                                  width: 24,
                                  height: 24,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  border: "1px solid white",
                                  borderRadius: "50%",
                                }}
                              >
                                {t.userInitial}
                              </span>
                            )}
                            {convertSecondsToReadable(t.start)}{" "}
                            {!t.isNote && (
                              <span>- {convertSecondsToReadable(t.end)}</span>
                            )}
                          </div>
                          <div
                            style={{
                              color: "white",
                            }}
                          >
                            {t.text}
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            )}
            {notesVisible && (
              <div
                className={cx("notes-container")}
                style={{ paddingTop: transcriptVisible ? 0 : 20 }}
              >
                <div className={cx("notes")}>
                  <div className={cx("notes-list")}>
                    {notes.map((note, index) => (
                      <Note
                        key={`note-${index}`}
                        note={note}
                        updateNote={(newNoteObject) => {
                          const tempNotes = cloneDeep(notes);
                          tempNotes[index] = newNoteObject;
                          updateNote(
                            tempNotes.sort((n1, n2) => n1.start - n2.start)
                          );
                        }}
                      />
                    ))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Input
                        value={newNote}
                        onChange={(e) => setNewNote(e.target.value)}
                        className={cx("edit-text-input")}
                        style={{ margin: "0px 10px 0px 0px" }}
                        onPressEnter={addNote}
                        placeholder="Enter notes here"
                      />
                      <Button
                        style={{
                          ...dullGreenButtonStyle,
                          margin: 0,
                          border: "none",
                          textTransform: "uppercase",
                          transition: "none",
                        }}
                        onClick={addNote}
                      >
                        Add
                      </Button>
                    </div>
                    <Checkbox
                      checked={addToTranscript}
                      onChange={(e) => setAddToTranscript(e.target.checked)}
                      className={cx("add-transcript-check")}
                    >
                      Add to transcript
                    </Checkbox>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {reelToDelete !== null && (
        <Modal
          visible={deleteReelModal}
          centered={true}
          onCancel={() => {
            setDeleteReelModal(false);
            setReelToDelete(null);
          }}
          footer={null}
          closable={false}
          width={"500px"}
          className={cx("confirmation-modal")}
        >
          <div
            style={{
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Delete Reel?
          </div>
          <div
            style={{
              margin: "10px 0",
            }}
          >
            This is {reelToDelete.url ? "a saved" : "an unsaved"} reel.
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                ...greyButtonStyle,
                margin: 0,
                textTransform: "uppercase",
                border: "none",
              }}
              onClick={() => {
                setDeleteReelModal(false);
                setReelToDelete(null);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: "#ff4e4e",
                color: "white",
                border: "none",
                margin: "0px 0px 0px 16px",
                textTransform: "uppercase",
                transition: "none",
                borderRadius: 6,
                border: "none",
              }}
              onClick={() => {
                deleteReel();
                setDeleteReelModal(false);
              }}
            >
              Delete
            </Button>
          </div>
        </Modal>
      )}
      {reelToCreate !== null && (
        <Modal
          visible={createReelModal}
          centered={true}
          onCancel={() => {
            setCreateReelModal(false);
            setReelToCreate(null);
          }}
          footer={null}
          closable={false}
          width={"500px"}
          className={cx("confirmation-modal")}
        >
          <div
            style={{
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Add to reels
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 8,
            }}
          >
            <div>
              <div style={{ fontSize: 12, color: "grey" }}>Reel duration</div>
              <div
                style={{ color: "#07c194", fontSize: 14, fontWeight: "bold" }}
              >
                {convertSecondsToReadable(reelToCreate.start)} -{" "}
                {convertSecondsToReadable(reelToCreate.end)}
              </div>
            </div>
            <div style={{ marginLeft: 40 }}>
              <div style={{ fontSize: 12, color: "grey" }}>
                Reel name (optional)
              </div>
              <Input
                value={reelName}
                onChange={(e) => setReelName(e.target.value)}
                size="small"
                placeholder="Enter reel name"
                style={{
                  width: 220,
                  borderRadius: 6,
                  border: "1px solid #0a3157",
                }}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: 10,
            }}
          >
            The selected portion of this video will be cropped and saved as a
            reel.
          </div>
          <div
            style={{
              marginBottom: 10,
            }}
          >
            You can view these later from the reels section.
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                ...greyButtonStyle,
                margin: 0,
                textTransform: "uppercase",
                border: "none",
              }}
              onClick={() => {
                setCreateReelModal(false);
                setReelToCreate(null);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                ...dullGreenButtonStyle,
                border: "none",
                margin: "0px 0px 0px 16px",
                textTransform: "uppercase",
                transition: "none",
                border: "none",
              }}
              onClick={() => {
                createNewReel();
                setCreateReelModal(false);
              }}
            >
              Add
            </Button>
          </div>
        </Modal>
      )}
      {reelToEdit !== null && (
        <Modal
          visible={editReelModal}
          centered={true}
          onCancel={() => {
            setEditReelModal(false);
            setReelToEdit(null);
          }}
          footer={null}
          closable={false}
          width={"500px"}
          className={cx("confirmation-modal")}
        >
          <div
            style={{
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Edit Reel
          </div>
          <div
            style={{
              margin: "10px 0",
              width: "100%",
              maxHeight: 300,
            }}
          >
            <video
              ref={editVideoPlayer}
              width="100%"
              height="auto"
              background="black"
              controls={true}
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                backgroundColor: "black",
                borderRadius: 16,
              }}
            >
              <source
                src={`${videoUrl}#t=${reelToEdit.start},${reelToEdit.end}`}
              ></source>
              <track src={subtitlesUrl} srcLang="en" default />
            </video>
          </div>
          <div
            style={{
              margin: "10px 0",
            }}
          >
            <span>Current reel time: </span>
            <span
              style={{ color: "#07c194", fontSize: 14, fontWeight: "bold" }}
            >
              {convertSecondsToReadable(reelToEdit.start)} -{" "}
              {convertSecondsToReadable(reelToEdit.end)}
            </span>
          </div>
          <div style={{ margin: "10px 0" }}>
            <Button
              style={{
                ...dullGreenButtonStyle,
                height: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: 0,
                border: "none",
                textTransform: "uppercase",
              }}
              onClick={() => {
                if (!recordingEditReel && editVideoPlayer.current) {
                  const videoTime = editVideoPlayer.current.currentTime;
                  setEditReelStartTime(videoTime);
                  setEditReelEndTime(videoTime);
                  setReelTimeEdited(true);
                  editVideoPlayer.current.play();
                } else if (editVideoPlayer.current) {
                  editVideoPlayer.current.pause();
                }
                setRecordingEditReel(!recordingEditReel);
              }}
            >
              {recordingEditReel ? "Stop Recording" : "Start Recording"}
            </Button>
          </div>
          {reelTimeEdited && (
            <div
              style={{
                margin: "10px 0",
              }}
            >
              <span>Edited reel time: </span>
              <span
                style={{ color: "#07c194", fontSize: 14, fontWeight: "bold" }}
              >
                {convertSecondsToReadable(editReelStartTime)} -{" "}
                {convertSecondsToReadable(editReelEndTime)}
              </span>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                ...greyButtonStyle,
                margin: 0,
                textTransform: "uppercase",
                border: "none",
              }}
              onClick={() => {
                setEditReelModal(false);
                setReelToEdit(null);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                ...dullGreenButtonStyle,
                border: "none",
                margin: "0px 0px 0px 16px",
                textTransform: "uppercase",
                transition: "none",
                border: "none",
                opacity: recordingEditReel || !reelTimeEdited ? 0.6 : 1,
              }}
              disabled={recordingEditReel || !reelTimeEdited}
              onClick={() => {
                editReel();
                setEditReelModal(false);
              }}
            >
              Update Reel
            </Button>
          </div>
        </Modal>
      )}

      <Modal
        visible={unsavedReelsModal}
        centered={true}
        onCancel={() => {
          setUnsavedReelsModal(false);
        }}
        footer={null}
        closable={false}
        width={"500px"}
        className={cx("confirmation-modal")}
      >
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          Unsaved Reels
        </div>
        <div
          style={{
            margin: "10px 0px",
          }}
        >
          You have unsaved reels, are you sure you want to exit?
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              ...greyButtonStyle,
              margin: 0,
              textTransform: "uppercase",
              border: "none",
            }}
            onClick={() => {
              setUnsavedReelsModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              ...dullGreenButtonStyle,
              border: "none",
              margin: "0px 0px 0px 16px",
              textTransform: "uppercase",
              transition: "none",
              border: "none",
            }}
            onClick={() => {
              setUnsavedReelsModal(false);
              onCancel();
            }}
          >
            Exit
          </Button>
        </div>
      </Modal>
    </Modal>
  );
};

export default TranscriptVideoModal;
