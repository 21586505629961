import React from "react";
import { Form, Modal, Row, Col, InputNumber, Button, Select } from "antd";
import ConditionalFormBlock from "../../../conditional-form-block/ConditionalFormBlock";

const EditPricingModal = (props) => {
  const { selectedPricing, handleCancel, handleEditPricing } = props;
  const [form] = Form.useForm();

  const surveyType = selectedPricing.surveyType;
  const surveyTime = selectedPricing.time;

  const onEditPricing = () => {
    form.validateFields().then((values) => {
      const newValues =
        surveyType === "Quantitative"
          ? {
              ...selectedPricing,
              basic: {
                ...selectedPricing.basic,
                ...values.basic,
              },
              elite: {
                ...selectedPricing.elite,
                ...values.elite,
              },
              platinum: {
                ...selectedPricing.platinum,
                ...values.platinum,
              },
            }
          : {
              ...selectedPricing,
              basic: {
                ...selectedPricing.basic,
                [surveyTime]: {
                  ...selectedPricing.basic[surveyTime],
                  ...values.basic[surveyTime],
                },
              },
              elite: {
                ...selectedPricing.elite,
                [surveyTime]: {
                  ...selectedPricing.elite[surveyTime],
                  ...values.elite[surveyTime],
                },
              },
              platinum: {
                ...selectedPricing.platinum,
                [surveyTime]: {
                  ...selectedPricing.platinum[surveyTime],
                  ...values.platinum[surveyTime],
                },
              },
            };
      const newPricing = [newValues.basic, newValues.elite, newValues.platinum];

      console.log("New Pricing", newValues, values);
      handleEditPricing(surveyType, newPricing);
      handleCancel();
    });
  };

  const initialValues = selectedPricing && {
    basicUserType: selectedPricing.userType,
    eliteUserType: selectedPricing.userType,
    platinumUserType: selectedPricing.userType,
    basic: selectedPricing.basic[selectedPricing.time],
    elite: selectedPricing.elite[selectedPricing.time],
    platinum: selectedPricing.platinum[selectedPricing.time],
    ...selectedPricing,
  };

  const footer = (
    <div>
      <Button
        onClick={handleCancel}
        style={{ border: "1px solid #004479", marginRight: 10, width: 100 }}
      >
        Cancel
      </Button>
      <Button type="primary" onClick={onEditPricing} style={{ width: 100 }}>
        Save
      </Button>
    </div>
  );

  console.log("Initial Values: ", initialValues);

  return (
    <Modal
      title=""
      closable={false}
      visible={true}
      onCancel={handleCancel}
      footer={footer}
    >
      <Form
        form={form}
        initialValues={initialValues}
        style={{ color: "#004479" }}
      >
        <Row gutter={[6, 6]}>
          <Col xs={24}>Basic Plan Monthly Fee</Col>
          <Col xs={24} sm={12}>
            <Form.Item name="basicUserType">
              <Select
                placeholder="Survey Type"
                className="input-border"
                style={{ width: "100%" }}
              >
                <Select.Option value="clientsUser">Client</Select.Option>
                <Select.Option value="UQUser">UserQual</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} style={{ textAlign: "center" }}>
            <ConditionalFormBlock
              dependency="basicUserType"
              condition={() =>
                form.getFieldValue("basicUserType") === "clientsUser"
              }
            >
              <Form.Item
                name={
                  surveyType === "Quantitative"
                    ? ["basic", "clientsUser"]
                    : ["basic", surveyTime, "clientsUser"]
                }
              >
                <InputNumber
                  placeholder="Fee"
                  className="input-border"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </ConditionalFormBlock>
            <ConditionalFormBlock
              dependency="basicUserType"
              condition={() => form.getFieldValue("basicUserType") === "UQUser"}
            >
              <Form.Item
                name={
                  surveyType === "Quantitative"
                    ? ["basic", "UQUser"]
                    : ["basic", surveyTime, "UQUser"]
                }
              >
                <InputNumber
                  placeholder="Fee"
                  className="input-border"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </ConditionalFormBlock>
          </Col>
        </Row>
        <Row gutter={[6, 6]}>
          <Col xs={24}>Elite Plan Monthly Fee</Col>
          <Col xs={24} sm={12}>
            <Form.Item name="eliteUserType">
              <Select
                placeholder="Survey Type"
                className="input-border"
                style={{ width: "100%" }}
              >
                <Select.Option value="clientsUser">Client</Select.Option>
                <Select.Option value="UQUser">UserQual</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} style={{ textAlign: "center" }}>
            <ConditionalFormBlock
              dependency="eliteUserType"
              condition={() =>
                form.getFieldValue("eliteUserType") === "clientsUser"
              }
            >
              <Form.Item
                name={
                  surveyType === "Quantitative"
                    ? ["elite", "clientsUser"]
                    : ["elite", surveyTime, "clientsUser"]
                }
              >
                <InputNumber
                  placeholder="Fee"
                  className="input-border"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </ConditionalFormBlock>
            <ConditionalFormBlock
              dependency="eliteUserType"
              condition={() => form.getFieldValue("eliteUserType") === "UQUser"}
            >
              <Form.Item
                name={
                  surveyType === "Quantitative"
                    ? ["elite", "UQUser"]
                    : ["elite", surveyTime, "UQUser"]
                }
              >
                <InputNumber
                  placeholder="Fee"
                  className="input-border"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </ConditionalFormBlock>
          </Col>
        </Row>
        <Row gutter={[6, 6]}>
          <Col xs={24}>Platinum Plan Monthly Fee</Col>
          <Col xs={24} sm={12}>
            <Form.Item name="platinumUserType">
              <Select
                placeholder="Survey Type"
                className="input-border"
                style={{ width: "100%" }}
              >
                <Select.Option value="clientsUser">Client</Select.Option>
                <Select.Option value="UQUser">UserQual</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} style={{ textAlign: "center" }}>
            <ConditionalFormBlock
              dependency="platinumUserType"
              condition={() =>
                form.getFieldValue("platinumUserType") === "clientsUser"
              }
            >
              <Form.Item
                name={
                  surveyType === "Quantitative"
                    ? ["platinum", "clientsUser"]
                    : ["platinum", surveyTime, "clientsUser"]
                }
              >
                <InputNumber
                  placeholder="Fee"
                  className="input-border"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </ConditionalFormBlock>
            <ConditionalFormBlock
              dependency="platinumUserType"
              condition={() =>
                form.getFieldValue("platinumUserType") === "UQUser"
              }
            >
              <Form.Item
                name={
                  surveyType === "Quantitative"
                    ? ["platinum", "UQUser"]
                    : ["platinum", surveyTime, "UQUser"]
                }
              >
                <InputNumber
                  placeholder="Fee"
                  className="input-border"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </ConditionalFormBlock>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditPricingModal;
