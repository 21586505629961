import React from "react";

import "./meeting.css";
import AgoraVideoCall from "../../../../../../components/AgoraVideoCall/index";
import { AGORA_APP_ID } from "../../../../../../agora.config";

class Meeting extends React.Component {
  constructor(props) {
    super(props);
    this.videoProfile = "480p_4";
    this.channel = props.surveyAccess? `${props.token}` : `${props.surveyId}_${props.date}_${props.time}`;
    this.transcode = "interop";
    this.attendeeMode = "video";
    this.baseMode = "avc";
    this.appId = AGORA_APP_ID;
    this.uid = undefined;
    this.meeId = props.surveyId;
    this.date = props.date;
    this.time = props.time;
    this.isClient = props.isClient;
    this.isOneToOne = props.isOneToOne;
    this.token = props.token
  }

  render() {
    return (
      <div className="wrapper meeting" style={{ width: "100%" }}>
        <div className="ag-header">
          <div className="ag-header-msg" style={{ color: "white" }}>
            USERQUAL{" "}
            <span id="room-name">
              {this.isOneToOne ? "ONE TO ONE" : "ONE TO MANY"} Survey{" "}
              {this.channel}
            </span>
          </div>
        </div>
        <div className="ag-main">
          <div className="ag-container">
            <AgoraVideoCall
              videoProfile={this.videoProfile}
              channel={this.channel}
              transcode={this.transcode}
              attendeeMode={this.attendeeMode}
              baseMode={this.baseMode}
              appId={this.appId}
              uid={this.uid}
              isClient={this.isClient}
              isOneToOne={this.isOneToOne}
              uploadURL={this.channel}
              surveyId={this.props.surveyId}
              handleEndSurvey={this.props.handleEndSurvey}
              recordingStartTime={this.props.recordingStartTime}
              setRecordingStartTime={this.props.setRecordingStartTime}
              stopRecordingHandler={this.props.stopRecordingHandler}
              notes={this.props.notes}
              setNotes={this.props.setNotes}
              triggerQuestionPublished={this.props.triggerQuestionPublished}
              setTriggerQuestionPublished={
                this.props.setTriggerQuestionPublished
              }
              triggerSubmitAnswer={this.props.triggerSubmitAnswer}
              setTriggerSubmitAnswer={this.props.setTriggerSubmitAnswer}
              showQuestionPopup={this.props.showQuestionPopup}
              showAnswer={this.props.showAnswer}
              setOpenUID = {this.props.setOpenUID}
              employLink = {this.props.employLink}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Meeting;
