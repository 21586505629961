import React, { useEffect } from "react";
import { Drawer, Input, Button, Menu, Dropdown, notification } from "antd";

import styles from "./ChatComponent.module.css";
import c from "classnames/bind";
import { useState } from "react";
import {
  MoreOutlined,
  SearchOutlined,
  AlignLeftOutlined,
} from "@ant-design/icons";
import { questionTemplate } from "../../../../constants";
import NoteBox from "./NoteBox/NoteBox";
import QuestionBuilder from "./QuestionBuilder/QuestionBuilder";
import { getRoleByToken } from "../../../../utils";
import axios, { Routes } from "../../../../services/API";
import handleNetworkErrors from "../../handleNetworkErrors";

const cx = c.bind(styles);

const ChatComponent = ({
  slot,
  recordingStartTime,
  notes,
  setNotes,
  publishQuestion,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editorTab, setEditorTab] = useState(true);
  const [inputText, setInputText] = useState("");
  const [searchMode, setSearchMode] = useState(false);
  const [question, setQuestion] = useState(questionTemplate);

  const handleModeChange = () => {
    setSearchMode(!searchMode);
  };

  const handleEnterOnInput = (value) => {
    if (value && value === "") {
      notification.error({ message: "Please Enter some text" });
      return;
    }
    if (searchMode) {
      console.log("Search functionality is still not completed");
    } else {
      const newNotes = [
        ...notes,
        {
          key: new Date().getSeconds(),
          identifier: +new Date(),
          start: recordingStartTime
            ? parseInt((new Date() - recordingStartTime) / 1000)
            : 0,
          text: inputText,
          end: null,
          isReel: false,
        },
      ];
      setNotes(newNotes);
      updateNotes(newNotes);
      setInputText("");
    }
  };

  const updateNotes = async (notes) => {
    const id =
      getRoleByToken(localStorage.getItem("token")) === "client"
        ? localStorage.getItem("client")
        : "";
    const { url, method } = Routes.client.upsertQualitativeData(
      id,
      slot.surveyId
    );
    try {
      const { data } = await axios[method](url, {
        data: {
          slotKey: slot.date + "_" + slot.time,
          notes: notes,
        },
      });
      // console.log("notes updated", data);
    } catch (err) {
      handleNetworkErrors(err);
    }
  };

  const menu = () => {
    return (
      <Menu>
        <Menu.Item>Save</Menu.Item>
        <Menu.Item>Save as</Menu.Item>
        <Menu.Item>Download</Menu.Item>
        <Menu.Item>Rename</Menu.Item>
        <Menu.Item>Delete</Menu.Item>
      </Menu>
    );
  };

  console.log("Notes", notes);

  return (
    <div className={cx("chat-component")}>
      {!drawerVisible && (
        <div
          className={cx("drawer-button")}
          onClick={() => setDrawerVisible(true)}
        >
          <i
            className="fi-rr-angle-double-left"
            style={{ color: "white", fontSize: 24 }}
          />
        </div>
      )}
      <Drawer
        closable={false}
        placement="right"
        width="450px"
        getContainer={false}
        visible={drawerVisible}
        mask={false}
        onClose={() => setDrawerVisible(false)}
        style={{
          position: "relative",
          display: drawerVisible ? "block" : "none",
        }}
      >
        <div style={{ height: "100%" }}>
          <div
            style={{
              color: "white",
              display: "flex",
              fontSize: 16,
            }}
          >
            <div onClick={() => setDrawerVisible(false)}>
              <i
                className="fi-rr-angle-double-right"
                style={{ color: "white", fontSize: 24 }}
              />
            </div>
            <div style={{ margin: "auto 16px" }}>Ask in-video questions</div>
            {/* <div>
              <Dropdown overlay={menu} placement="bottomRight">
                <MoreOutlined
                  style={{
                    color: "white",
                    fontSize: 12,
                    border: "1px solid",
                    borderRadius: 15,
                    padding: 5,
                    cursor: "pointer",
                  }}
                />
              </Dropdown>
            </div> */}
          </div>
          <div
            style={{
              margin: "5px",
              height: "93%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <QuestionBuilder
              question={question}
              setQuestion={setQuestion}
              publishQuestion={() => publishQuestion(question)}
            />
            <div style={{ color: "white" }}>
              <div
                style={{
                  color: "white",
                  paddinBottom: "10px",
                  fontWeight: "bold",
                }}
              >
                Notes
              </div>
              <div
                style={{
                  maxHeight: "250px",
                  overflowY: "auto",
                  marginBottom: "10px",
                }}
              >
                {notes &&
                  notes
                    .sort((note1, note2) => note1.start - note2.start)
                    .filter((note) =>
                      searchMode
                        ? note.text.match(new RegExp(inputText, "i")) !== null
                        : true
                    )
                    .map((note, index) => {
                      return (
                        <NoteBox
                          index={index + 1}
                          key={note.identifier}
                          start={note.start}
                          end={note.end}
                          noteText={note.text}
                          isReel={note.isReel}
                          updateReel={() => {
                            const updatedNotes = [...notes];
                            updatedNotes[index] = {
                              ...note,
                              isReel: !note.isReel,
                              end: !note.isReel ? note.start + 60 : null,
                            };
                            setNotes(updatedNotes);
                          }}
                          handleNoteChange={(start, end, noteText) => {
                            const updatedNotes = [...notes];
                            updatedNotes[index] = {
                              ...note,
                              text: noteText,
                              start: start,
                              end: end,
                            };
                            setNotes(updatedNotes);
                          }}
                        />
                      );
                    })}
              </div>

              <div className={cx("bottom-bar")}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "3fr 1fr",
                    columnGap: "10px",
                  }}
                >
                  <Input
                    size="large"
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    onPressEnter={(e) => handleEnterOnInput(e.target.value)}
                    placeholder="Enter a note"
                    prefix={
                      searchMode ? (
                        <SearchOutlined
                          style={{ fontSize: 24, color: "#BFBFBF" }}
                          onClick={handleModeChange}
                        />
                      ) : (
                        <AlignLeftOutlined
                          style={{ fontSize: 24, color: "#BFBFBF" }}
                          onClick={handleModeChange}
                        />
                      )
                    }
                  />
                  <Button
                    style={{
                      marginRight: 8,
                      marginTop: 0,
                      height: "auto",
                      backgroundColor: "#00233f",
                      color: "white",
                      borderRadius: 5,
                      border: "none",
                    }}
                    onClick={(e) => handleEnterOnInput(e.target.value)}
                  >
                    Add
                  </Button>
                </div>
              </div>
              {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  style={
                    editorTab ? { background: "#1A4375", color: "white" } : {}
                  }
                  onClick={() => setEditorTab(true)}
                >
                  Transcript
                </Button>
                <Button
                  style={
                    !editorTab ? { background: "#1A4375", color: "white" } : {}
                  }
                  onClick={() => setEditorTab(false)}
                >
                  Add Notes
                </Button>
              </div> */}
            </div>
            {/* {[...Array(5)].map((v, index) => {
              return (
                <ReelBox
                  index={index + 1}
                  key={index}
                  reelTime="00:00 - 00:05"
                  reelText="Please match the labels to their closest association,Please match the labels to their closest association,Please match the labels to their closest association,Please match the labels to their closest association,"
                />
              );
            })} */}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default ChatComponent;
