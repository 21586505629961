import React, { useState } from "react";
import { Modal } from "antd";
import "./image-thumbnail.css";
import { CloseCircleOutlined } from "@ant-design/icons";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

export const ImageModal = ({ onCancel, imgUrl }) => {
  return (
    <Modal
      footer={null}
      visible={true}
      onCancel={onCancel}
      closable={false}
      className="zoom-image"
    >
      <img
        src={imgUrl}
        alt="img"
        style={{ maxWidth: "100%", height: "auto" }}
      />
      <CloseCircleOutlined
        style={{
          fontSize: 24,
          color: "white",
          position: "absolute",
          cursor: "pointer",
          top: "-12px",
          right: "-12px",
        }}
        onClick={onCancel}
      />
    </Modal>
  );
};

const Thumbnail = ({ imgUrl, label, style = {} }) => {
  const [zoom, setZoom] = useState(false);

  return (
    <div className="img-container" style={{ ...style }}>
      <img
        className="img-element"
        src={imgUrl}
        alt="img"
        style={{ objectFit: "contain", cursor: "pointer", borderRadius: 5 }}
        onClick={() => setZoom(true)}
      />
      {label && <div className="imgLabel">{label}</div>}
      {
        zoom && (
          <Lightbox
            image={imgUrl}
            onClose={() => setZoom(false)}
            allowRotate={false}
            showTitle={false}
            doubleClickZoom={2}
          />
        )
        /* <ImageModal imgUrl={imgUrl} onCancel={() => setZoom(false)} /> */
      }
    </div>
  );
};

export default Thumbnail;
