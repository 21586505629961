import React, { useState, useRef } from "react";
import Dropdown from "../../../../components/dropdown/dropdown";
import SwitchButton from "../../../../components/switch/switch";
// import Button from "../../../../components/button/Button";
import { COLOR } from "../../../../constants";
import axios, { Routes } from "../../../../services/API";
import { useEffect } from "react";
import TabBar from "../../../../components/tabbar/TabBar";
import FlexRow from "../../../../components/flex-row";
import { Row, Col, Select, Button, Cascader, Divider, Card } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { greyButtonStyle, darkBlueButtonStyle } from "../constants/styles";
import ReactToPrint from "react-to-print";
import { useHistory } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import styles from "./navbar.module.css";
import c from "classnames/bind";

const cx = c.bind(styles);
const { Option } = Select;

const audienceType = {
  general: false,
  influencer: true,
};
const surveyType = {
  quantitative: false,
  qualitative: true,
};

const mode = {
  unmoderated: false,
  moderated: true,
};

const device = {
  desktop: false,
  mobile: true,
};

const setting = {
  individual: false,
  group: true,
};

const groupedFilter = [
  { key: "surveyType", value: surveyType },
  { key: "mode", value: mode },
  { key: "device", value: device },
  { key: "setting", value: setting },
];

// const setting = {
//   "one-to-one": false,
//   "one-to-many": true,
// };

const timingType = {
  0: "15min",
  1: "30min",
  2: "45min",
};

const userType = {
  userQual: false,
  clientProvided: true,
};

const filterOptions = [
  {
    value: "qualitative",
    label: "Qualitative",
    children: [
      {
        value: "unmoderated",
        label: "Unmoderated",
        children: [
          {
            value: "desktop",
            label: "Desktop",
          },
          {
            value: "mobile",
            label: "Mobile",
          },
        ],
      },
      {
        value: "moderated",
        label: "Moderated",
        children: [
          {
            value: "desktop",
            label: "Desktop",
            children: [
              {
                value: "individual",
                label: "Individual",
              },
              {
                value: "group",
                label: "Group",
              },
            ],
          },
          {
            value: "mobile",
            label: "Mobile",
            children: [
              {
                value: "individual",
                label: "Individual",
              },
              {
                value: "group",
                label: "Group",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    value: "quantitative",
    label: "Quantitative",
  },
];

const Navbar = (props) => {
  const history = useHistory();

  const [filter, setFilter] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [filterButtons, setFilterButtons] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const [selectedSurvey, setSelectedSurvey] = useState(null);

  const [filteredSurvey, setFilteredSurvey] = useState(props.surveys);
  const filterContainerRef = useRef(null);
  const mountedRef = useRef();

  useEffect(() => {
    if (history.location.state && history.location.state.surveyId) {
      setSelectedSurvey(history.location.state.surveyId);
    }
  }, [history.location.state]);

  const fetchSurveyData = async (surveyId) => {
    const clientId = localStorage.getItem("client");
    const { method, url } = Routes.client.getSurvey(clientId, surveyId);
    const surveyResponse = await axios[method](url);
    console.log("got survey", surveyResponse.data);
    props.setSurveyData(surveyResponse.data.survey);
  };
  const fetchQualitativeSurveySummary = async (surveyId) => {
    const clientId = localStorage.getItem("client");
    const { method, url } = Routes.client.qualitativeSummaries(
      clientId,
      surveyId
    );
    const qualityResponse = await axios[method](url);
    console.log("got qualitative survey", qualityResponse.data);
  };

  useEffect(() => {
    if (selectedSurvey) {
      fetchSurveyData(selectedSurvey);
      fetchQualitativeSurveySummary(selectedSurvey);
    } else {
      props.setSurveyData(null);
    }
  }, [selectedSurvey]);

  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
      return;
    }
    setSelectedSurvey(null);
    if (appliedFilters.length === 0) {
      setFilteredSurvey(props.surveys);
      return;
    }
    let filteredSurveys = [];
    for (const appliedFilter of appliedFilters) {
      filteredSurveys = [
        ...filteredSurveys,
        ...props.surveys.filter((s) => {
          let isValid = true;
          for (const index in appliedFilter) {
            const { key, value } = groupedFilter[index];
            isValid = isValid && s[key] === value[appliedFilter[index]];
          }
          return isValid;
        }),
      ];
    }

    setFilteredSurvey(filteredSurveys);
  }, [appliedFilters, mountedRef]);

  useEffect(() => {
    function outsideFilterClickHandler(event) {
      if (
        filter &&
        filterContainerRef.current &&
        !filterContainerRef.current.contains(event.target)
      ) {
        setFilter(false);
        setSelectedFilters(appliedFilters);
      }
    }

    document.addEventListener("click", outsideFilterClickHandler);

    return () => {
      document.removeEventListener("click", outsideFilterClickHandler);
    };
  }, [filterContainerRef, filter, appliedFilters]);

  useEffect(() => {
    const filterBtns = [];
    for (const appliedFilter of appliedFilters) {
      let text = "";
      for (const key in appliedFilter) {
        switch (appliedFilter[key]) {
          case "qualitative":
            text += "Qualitative";
            break;
          case "quantitative":
            text += "Quantitative";
            break;
          case "moderated":
            text += "Moderated";
            break;
          case "unmoderated":
            text += "Unmoderated";
            break;
          case "desktop":
            text += "Desktop";
            break;
          case "mobile":
            text += "Mobile";
            break;
          case "individual":
            text += "Individual";
            break;
          case "group":
            text += "Group";
            break;
        }
        if (parseInt(key) !== appliedFilter.length - 1) {
          text += " > ";
        }
      }

      if (!filterBtns.includes(text)) {
        filterBtns.push({
          value: appliedFilter,
          text,
        });
      }
    }

    setFilterButtons(filterBtns);
  }, [appliedFilters]);

  console.log("Surveys", props.surveys);
  console.log("Filtered Survey", filteredSurvey);

  const printPDF = () => {
    const input = document.getElementById("printDocument");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Document of a4WidthMm wide and inputHeightMm high
      let pdf = new jsPDF();

      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save(`question_summary.pdf`);
    });
  };

  const isQualModeratedIndividual =
    props.surveyData &&
    props.surveyData.surveyType &&
    props.surveyData.mode &&
    !props.surveyData.setting;

  return (
    <>
      <div>
        <div style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
          Survey Results
        </div>
        <div style={{ opacity: "80%", paddingBottom: "20px" }}>
          View results and responses for all completed surveys
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "50% 15%",
            width: "100%",
          }}
        >
          <Col span={24}>
            <Select
              placeholder="Select from completed surveys"
              size="large"
              className={cx("survey-select")}
              dropdownClassName={cx("survey-select-dropdown")}
              value={selectedSurvey}
              onChange={setSelectedSurvey}
            >
              {filteredSurvey.map((surv) => (
                <Option
                  key={surv._id}
                  value={surv._id}
                  className={cx("survey-select-option")}
                >
                  {surv.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col
            span={24}
            style={{
              marginLeft: "18px",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              rowGap: 6,
              columnGap: 10,
            }}
          >
            <Cascader
              options={filterOptions}
              multiple
              value={selectedFilters}
              onChange={(value) => {
                // console.log("cascader value changed", value, selectedOptions);
                setSelectedFilters(value);
              }}
              expandTrigger="click"
              open={filter}
              dropdownClassName={cx("filter-dropdown")}
              dropdownRender={(menu) => (
                <div ref={filterContainerRef}>
                  {filter && menu}
                  <Divider style={{ margin: 0 }} />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      padding: "4px 8px",
                    }}
                  >
                    <Button
                      size="small"
                      type="text"
                      onClick={() => {
                        setFilter(false);
                        setSelectedFilters(appliedFilters);
                      }}
                      style={{
                        marginRight: 20,
                        color: "#555",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      type="text"
                      onClick={() => {
                        setFilter(false);
                        setAppliedFilters(selectedFilters);
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              )}
            >
              <Button
                style={{
                  backgroundColor: "#B9E8F7",
                  borderRadius: 4,
                  height: "-webkit-fill-available",
                  border: "none",
                }}
                onClick={() => setFilter(true)}
              >
                <FilterOutlined /> Filter surveys
              </Button>
            </Cascader>
          </Col>
        </div>
        <div style={{ marginTop: "12px", marginBottom: "24px" }}>
          {filterButtons.map((filter) => (
            <Button
              style={{
                backgroundColor: "rgb(192, 225, 250)",
                color: "#01223f",
                borderRadius: 4,
                height: 36,
                border: "none",
                minWidth: 120,
                marginRight: "12px",
              }}
              key={filter.text}
            >
              {filter.text}
            </Button>
          ))}
        </div>
      </div>
      {/* <Row
        className={cx("grey-container")}
        gutter={[8, 16]}
        style={{ marginLeft: 0, marginRight: 0 }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "85% 15%",
            width: "100%",
          }}
        >
          <Col span={24}>
            <Select
              placeholder="Select from completed surveys"
              size="large"
              className={cx("survey-select")}
              dropdownClassName={cx("survey-select-dropdown")}
              value={selectedSurvey}
              onChange={setSelectedSurvey}
            >
              {filteredSurvey.map((surv) => (
                <Option
                  key={surv._id}
                  value={surv._id}
                  className={cx("survey-select-option")}
                >
                  {surv.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col
            span={24}
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              rowGap: 6,
              columnGap: 10,
            }}
          >
            <Cascader
              options={filterOptions}
              multiple
              value={selectedFilters}
              onChange={(value) => {
                // console.log("cascader value changed", value, selectedOptions);
                setSelectedFilters(value);
              }}
              expandTrigger="click"
              open={filter}
              dropdownClassName={cx("filter-dropdown")}
              dropdownRender={(menu) => (
                <div ref={filterContainerRef}>
                  {filter && menu}
                  <Divider style={{ margin: 0 }} />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      padding: "4px 8px",
                    }}
                  >
                    <Button
                      size="small"
                      type="text"
                      onClick={() => {
                        setFilter(false);
                        setSelectedFilters(appliedFilters);
                      }}
                      style={{
                        marginRight: 20,
                        color: "#555",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      type="text"
                      onClick={() => {
                        setFilter(false);
                        setAppliedFilters(selectedFilters);
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              )}
            >
              <Button
                style={{
                  backgroundColor: "#01223f",
                  color: "#ffffff",
                  borderRadius: 4,
                  height: 36,
                  border: "none",
                  minWidth: 120,
                }}
                onClick={() => setFilter(true)}
              >
                <FilterOutlined /> Filter
              </Button>
            </Cascader>
            {filterButtons.map((filter) => (
              <Button
                style={{
                  backgroundColor: "rgb(192, 225, 250)",
                  color: "#01223f",
                  borderRadius: 4,
                  height: 36,
                  border: "none",
                  minWidth: 120,
                }}
                key={filter.text}
              >
                {filter.text}
              </Button>
            ))}
          </Col>
        </div>

        {props.surveyData != null && (
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                {props.surveyData.name}
              </Col>
              <Col>
                <ReactToPrint
                  trigger={() => (
                    <Button className={cx("green-button")}>Print</Button>
                  )}
                  bodyClass={cx("print-body")}
                  content={() => props.questionComponentRef.current}
                ></ReactToPrint>
              </Col>
            </Row>
            <Row gutter={[4, 4]}>
              <Col span={24}>
                {props.surveyData.surveyType ? "Qualitative" : "Quantitative"}{" "}
                {props.surveyData.surveyType
                  ? props.surveyData.mode
                    ? "/ Moderated "
                    : "/ Un Moderated "
                  : null}
                {props.surveyData.surveyType
                  ? props.surveyData.setting
                    ? "/ Group "
                    : "/ Individual "
                  : null}
              </Col>
              <Col span={24}>
                Published:{" "}
                {new Date(props.surveyData.createdAt)
                  .toString()
                  .split(" ")
                  .map((stringPart, index) => {
                    if (index <= 4) {
                      return <span key={stringPart}>{stringPart} </span>;
                    }
                  })}{" "}
                IST
              </Col>
              <Col span={24} style={{ marginTop: 6 }}>
                <span
                  style={{
                    backgroundColor: "rgb(192, 225, 250)",
                    color: "#01223f",
                    borderRadius: 4,
                    padding: 6,
                  }}
                >
                  Participants:{" "}
                  {isQualModeratedIndividual
                    ? props.surveyData.qualitativeData.length
                    : props.surveyData.responses.length}{" "}
                  / {props.surveyData.numberOfParticipants}
                </span>
              </Col>
            </Row>
          </Col>
        )}
      </Row> */}

      {/* {props.surveyData != null && (
        <Card>
          <div>{props.surveyData.name}</div>
          <div
            style={{
              width: "100%",
              height: 48,
              borderRadius: 24,
              margin: "32px 0",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#dddddd",
            }}
          >
            <div
              style={{
                flexBasis: "50%",
                height: 48,
                borderRadius: 24,
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor:
                  props.selectedTab === "1" ? "#01223f" : "transparent",
                color: props.selectedTab === "1" ? "white" : "#01223f",
                pointerEvents: props.selectedTab === "1" ? "none" : "auto",
              }}
              onClick={() => {
                props.onTabChange("1");
              }}
            >
              Question Summaries
            </div>
            <div
              style={{
                flexBasis: "50%",
                height: 48,
                borderRadius: 24,
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor:
                  props.selectedTab === "2" ? "#01223f" : "transparent",
                color: props.selectedTab === "2" ? "white" : "#01223f",
                pointerEvents: props.selectedTab === "2" ? "none" : "auto",
              }}
              onClick={() => {
                props.onTabChange("2");
              }}
            >
              Individual Responses
            </div>
          </div>
        </Card>
      )} */}
    </>
  );
};

export default Navbar;
