import React from "react";

const FlexRow = ({ style, children, ...rest }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      ...style,
    }}
  >
    {children}
  </div>
);

export default FlexRow;
