import React from "react";
import "./HomeFeedback.css";
import checkMark from "../../../assets/images/check-mark.png";
import womanChilling from "../../../assets/images/woman-chilling.png";
import { Button, Col, Row } from "antd";
import { Link } from "react-router-dom";

const feedbackPoints = [
  "Atleast 16 years old",
  "Have a PC/Mac/desktop or Android/Apple phone",
  "Have access to the internet",
  "Speak atleast one of these languages fluently: " +
    "English, Hindi, Marathi, Gujarati",
];
const HomeFeedback = () => {
  return (
    <Row className={"home-feedback"} justify={"center"} align={"middle"}>
      <Col md={10} className={"text-col"}>
        <h1>Anyone can give feedback.</h1>
        {feedbackPoints.map((point) => {
          return (
            <div className={"home-point"}>
              <img src={checkMark} />
              {point}
            </div>
          );
        })}
        <Link to={"/tester"}>
          <Button>BECOME A TESTER</Button>
        </Link>
      </Col>
      <Col md={10} className={"image-container"}>
        {/*<Row justify={'center'} align={'middle'}>*/}
        {/*    <Col>*/}
        <img src={womanChilling} />
        {/*    </Col>*/}
        {/*</Row>*/}
      </Col>
    </Row>
  );
};

export default HomeFeedback;
