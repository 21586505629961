import { useDispatch } from "react-redux";
import { removeSurvey } from "../../../actions/users";
import {
  decrementPendingRequests,
  getUserId,
  incrementPendingRequests,
  notify,
} from "../../../utils";
import { passedScreeningTest } from "../utils/screeningUtils";

function useHandleScreeningQuestions() {
  const dispatch = useDispatch();

  const handleScreeningQuestions = async (survey, answers) => {
    try {
      const passed = passedScreeningTest(survey, answers);
      if (passed) {
        notify(
          "success",
          "Success",
          "You passed the screening test. You can now begin with actual survey."
        );
        return true;
      } else {
        incrementPendingRequests();
        if (!!getUserId()) {
          await dispatch(removeSurvey(survey._id));
        }
        notify(
          "info",
          "Info",
          "You failed the screening test. The survey is now removed."
        );
        decrementPendingRequests();
        return false;
      }
    } catch (error) {
      notify("error", "Error", error);
      decrementPendingRequests();
      return false;
    }
  };
  return handleScreeningQuestions;
}

export default useHandleScreeningQuestions;
