export const npsValues = [
  {
    name: "Manufacturing",
    detractors: 15.21,
    passives: 26.53,
    promotors: 58.27,
    npsValue: 43,
    lower: -23,
    median: 7,
    upper: 33,
  },
  {
    name: "Pharmaceuticals",
    detractors: 38.25,
    passives: 47.36,
    promotors: 14.39,
    npsValue: -24,
    lower: -65,
    median: -19,
    upper: 19,
  },
  {
    name: "Technology",
    detractors: 9.83,
    passives: 12.96,
    promotors: 77.21,
    npsValue: 67,
    lower: -12,
    median: 15,
    upper: 51,
  },
  {
    name: "FMCG",
    detractors: 21.34,
    passives: 24.89,
    promotors: 53.77,
    npsValue: 32,
    lower: -5,
    median: 22,
    upper: 47,
  },
  {
    name: "Chemicals/Petrochemicals",
    detractors: 12.51,
    passives: 52.97,
    promotors: 34.52,
    npsValue: 17,
    lower: -33,
    median: 15,
    upper: 38,
  },
  {
    name: "Financial Servies",
    detractors: 32.67,
    passives: 9.99,
    promotors: 57.34,
    npsValue: 25,
    lower: -12,
    median: 21,
    upper: 56,
  },
  {
    name: "Construction/Engineering",
    detractors: 18.94,
    passives: 56.61,
    promotors: 24.45,
    npsValue: 6,
    lower: -27,
    median: 19,
    upper: 24,
  },
  {
    name: "Food/Beverages",
    detractors: 22.95,
    passives: 5.71,
    promotors: 71.34,
    npsValue: 40,
    lower: 3,
    median: 35,
    upper: 66,
  },
  {
    name: "Textiles/Apparels",
    detractors: 12.52,
    passives: 14.04,
    promotors: 73.44,
    npsValue: 61,
    lower: 8,
    median: 41,
    upper: 72,
  },
  {
    name: "Real Estate",
    detractors: 43.22,
    passives: 35.34,
    promotors: 21.44,
    npsValue: -22,
    lower: -69,
    median: -4,
    upper: 17,
  },
];
