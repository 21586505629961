import { CheckOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Input, Tooltip } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";

const InputBox = (props) => {
  const {
    value,
    onChange,
    placeholder,
    style = {},
    rule = false,
    limit,
    options,
  } = props;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 450px)" });

  const isRulePassed = () => {
    if (!rule) return true;
    return value.length === limit;
  };

  return (
    <Input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      size={isTabletOrMobile ? "middle" : "large"}
      placeholder={placeholder}
      style={{ ...style }}
      suffix={
        value && isRulePassed() ? (
          <CheckOutlined style={{ color: "green" }} />
        ) : (
          <Tooltip title="You need to fill this box!">
            <InfoCircleOutlined style={{ color: "red" }} />
          </Tooltip>
        )
      }
      {...options}
    />
  );
};

export default InputBox;
