import VideoStreamMerger from "video-stream-merger";
import io from "socket.io-client";

const uploader = (url, uploadURL) => {
  return {
    init: function (data) {
      this.socket = io(this.url);
      let $ = this;
      let ss = this.socket;
      ss.on("connect", () => {
        ss.emit("subscribe", {
          ...this.data,
          ...data,
        });
        ss.on("host-leave", () => {
          this.onHostLeave();
        });
      });
      return navigator.mediaDevices
        .getDisplayMedia({
          video: true,
          audio: false,
        })
        .then((screen) => {
          return navigator.mediaDevices
            .getUserMedia({
              audio: true,
              video: true,
            })
            .then((video) => {
              $.videoObject = video;
              return [screen, video];
            })
            .catch(() => {
              screen.getTracks().forEach((track) => track.stop());
              throw "Permissions denied";
            });
        })
        .then(([screen, video]) => {
          $.screen = screen;
          $.video = video;
          let merger = new VideoStreamMerger({
            width: 1280,
            height: 720,
            fps: 20,
            clearRect: true,
            audioContext: null,
          });
          merger.addStream(screen, {
            x: 0,
            y: 0,
            width: merger.width,
            height: merger.height,
            mute: true,
          });
          merger.addStream(video, {
            x: merger.width - merger.width / 4,
            y: 0,
            width: merger.width / 4,
            height: merger.height / 4,
            mute: false,
          });
          merger.start();
          $.merger = merger;
          let mediaRecorder = new MediaRecorder(merger.result, {
            mimeType: "video/webm;codecs=vp8,opus",
          });
          $.mediaRecorder = mediaRecorder;
          mediaRecorder.ondataavailable = function (event) {
            if (event.data.size > 0) {
              $.upload(event.data);
            }
          };
          mediaRecorder.start(1000);
        });
    },
    onHostLeave: () => {
      //do something
    },
    startUpload: function () {
      let ss = this.socket;
      ss.emit("subscribe-upload", {
        ...this.data,
      });
    },
    upload: function (chunk) {
      let ss = this.socket;
      ss.emit("data_available", {
        ...this.data,
        chunk,
      });
    },
    endUpload: function (ss, mediaRecorder, merger, video, screen) {
      if (ss)
        ss.emit("leave-upload", {
          ...this.data,
        });
      if (mediaRecorder && merger && video && screen) {
        mediaRecorder.stop();
        merger.destroy();
        video.getTracks().forEach((track) => track.stop());
        screen.getTracks().forEach((track) => track.stop());
      }
    },
    url: url,
    data: {
      uploadURL: uploadURL,
    },
  };
};

export default uploader;
