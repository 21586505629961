import React, { useEffect, useRef } from "react";
import { Modal, Button, notification } from "antd";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import styles from "./ReelsModal.module.css";
import c from "classnames/bind";
import { useState } from "react";
import {
  CloseCircleFilled,
  DeleteFilled,
  CaretRightFilled,
} from "@ant-design/icons";
import { greyButtonStyle } from "../../../constants/styles";
import { convertSecondsToReadable } from "../../../../../../constants";

const cx = c.bind(styles);

const ReelsModal = ({
  reels,
  removeReel,
  participantIndex,
  visible,
  onCancel,
}) => {
  const [deleteReelModal, setDeleteReelModal] = useState(false);
  const [reelToDelete, setReelToDelete] = useState(null);
  const [currentReel, setCurrentReel] = useState(0);

  const [videoUrl, setVideoUrl] = useState("");

  const videoPlayer = useRef();

  useEffect(() => {
    setVideoUrl(reels[currentReel].url);
    if (videoPlayer.current) {
      videoPlayer.current.load();
      videoPlayer.current.play();
    }
  }, [currentReel, videoPlayer.current]);

  const deleteReel = async () => {
    await removeReel(reelToDelete.url, reelToDelete._id);
    setReelToDelete(null);
  };

  return (
    <Modal
      visible={visible}
      className={cx("transcript-video-modal")}
      centered={true}
      width="100%"
      onCancel={onCancel}
      footer={null}
      closable={false}
    >
      <RemoveScrollBar />
      <div className={cx("video-modal-header")}>
        <div className={cx("participant-title")}>
          Reels for participant no. {participantIndex + 1}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 22,
          }}
        >
          <CloseCircleFilled
            style={{
              fontSize: 26,
            }}
            onClick={onCancel}
          />
        </div>
      </div>
      <div className={cx("video-modal-body")}>
        <div className={cx("video-reel-container")}>
          <div className={cx("video-container")}>
            <video
              ref={videoPlayer}
              autoPlay={true}
              width="100%"
              height="auto"
              background="black"
              controls={true}
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                backgroundColor: "black",
                borderRadius: 16,
              }}
            >
              <source src={videoUrl}></source>
            </video>
          </div>
          <div className={cx("reels-container")}>
            <div
              style={{
                whiteSpace: "nowrap",
                overflowX: "scroll",
                display: "flex",
                alignItems: "center",
                columnGap: 8,
              }}
            >
              {[...reels].map((reel, index) => (
                <div key={`reel-${index}`} className={cx("reel-container")}>
                  <div
                    style={{
                      color: "rgb(130, 163, 182)",
                      height: 22,
                      overflow: "hidden",
                      maxWidth: "100%",
                    }}
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      title={reel.name ?? ""}
                    >
                      {reel.name ?? ""}
                    </div>
                  </div>
                  <div className={cx("reel-wrapper")}>
                    <video
                      width="100%"
                      height="auto"
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        borderRadius: 6,
                        border:
                          currentReel === index
                            ? "2px solid #00c194"
                            : "2px solid transparent",
                      }}
                    >
                      <source src={reel.url} />
                    </video>
                    <div className={cx("reel-overlay")}>
                      <CaretRightFilled onClick={() => setCurrentReel(index)} />
                      <DeleteFilled
                        onClick={() => {
                          setReelToDelete(reel);
                          setDeleteReelModal(true);
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      color: "rgb(130, 163, 182)",
                    }}
                  >
                    {convertSecondsToReadable(reel.start)} -{" "}
                    {convertSecondsToReadable(reel.end)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {reelToDelete !== null && (
        <Modal
          visible={deleteReelModal}
          centered={true}
          onCancel={() => {
            setDeleteReelModal(false);
            setReelToDelete(null);
          }}
          footer={null}
          closable={false}
          width={"500px"}
          className={cx("confirmation-modal")}
        >
          <div
            style={{
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Delete Reel?
          </div>
          <div
            style={{
              margin: "10px 0",
            }}
          >
            This is {reelToDelete.url ? "a saved" : "an unsaved"} reel.
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                ...greyButtonStyle,
                margin: 0,
                textTransform: "uppercase",
                border: "none",
              }}
              onClick={() => {
                setDeleteReelModal(false);
                setReelToDelete(null);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: "#ff4e4e",
                color: "white",
                border: "none",
                margin: "0px 0px 0px 16px",
                textTransform: "uppercase",
                transition: "none",
                borderRadius: 6,
                border: "none",
              }}
              onClick={() => {
                deleteReel();
                setDeleteReelModal(false);
              }}
            >
              Delete
            </Button>
          </div>
        </Modal>
      )}
    </Modal>
  );
};

export default ReelsModal;
