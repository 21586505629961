import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";

const Errors = ({ errors }) => {
  return (
    <div
      style={{
        alignSelf: "flex-start",
        marginTop: "2rem",
      }}
    >
      {errors.length !== 0 && (
        <Typography
          style={{
            lineHeight: "1.2",
            marginBottom: "0.5rem",
            display: "inline-block",
          }}
        >
          Before you Publish the survey
        </Typography>
      )}
      {errors.map((err, idx) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
          key={idx}
        >
          <div
            style={{
              marginRight: "0.5rem",
            }}
          >
            <InfoCircleOutlined size="lg" style={{ color: "#FF9480" }} />
          </div>
          <div>
            {/* <Typography
              style={{
                color: "#FF9480",
              }}
            >
              {err.message}
            </Typography> */}
            <Typography
              style={{
                color: "#FF9480",
                // textDecoration: "underline",
              }}
            >
              {err.description}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Errors;
