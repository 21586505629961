import React, { useState } from "react";
import "./imageAssociation.css";
import { Input, Row, Col, Form, Checkbox, Tooltip } from "antd";
import {
  CheckOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import CustomButton from "../../button/Button";

const ImageAssociation = (props) => {
  // Props
  const {
    dragHandleProps,
    choiceNumber,
    questionType,
    field,
    isFilled,
    addChoice,
    removeChoice,
    totalChoices,
  } = props;

  // Component states
  const [isChoiceNumberShown, setIsChoiceNumberShown] = useState(true);

  return (
    <Row
      gutter={6}
      onMouseEnter={() => setIsChoiceNumberShown(false)}
      onMouseLeave={() => setIsChoiceNumberShown(true)}
    >
      <Col
        xs={2}
        sm={2}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: 24,
        }}
      >
        {isChoiceNumberShown ? (
          <span className="choice-numberCircle">{choiceNumber}</span>
        ) : (
          <MenuOutlined {...dragHandleProps} />
        )}
      </Col>
      <Col xs={7} style={{ flexGrow: 1 }}>
        <Form.Item
          name={[field.name, "imageData"]}
          key={[field.name, "imageData"]}
          rules={[
            {
              required: true,
              message: "Please enter the value!",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="No Image Uploaded"
            style={{ borderRadius: 3 }}
            suffix={
              isFilled ? (
                <CheckOutlined style={{ color: "green" }} />
              ) : (
                <Tooltip title="You need to fill this option!">
                  <InfoCircleOutlined style={{ color: "red" }} />
                </Tooltip>
              )
            }
            className="input-border"
          />
        </Form.Item>
      </Col>
      <Col xs={4} style={{ flexGrow: 1 }}>
        <CustomButton>Upload Image</CustomButton>
      </Col>
      <Col xs={7} style={{ flexGrow: 1 }}>
        <Form.Item
          name={[field.name, "association"]}
          key={[field.name, "association"]}
          rules={[
            {
              required: true,
              message: "Please enter the value!",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="Association"
            style={{ borderRadius: 3 }}
            suffix={
              isFilled ? (
                <CheckOutlined style={{ color: "green" }} />
              ) : (
                <Tooltip title="You need to fill this option!">
                  <InfoCircleOutlined style={{ color: "red" }} />
                </Tooltip>
              )
            }
            className="input-border"
          />
        </Form.Item>
      </Col>
      <Col
        xs={3}
        className={
          questionType === "checkboxes-special" ? "no-hover actions" : "actions"
        }
      >
        <PlusCircleOutlined
          onClick={() => addChoice(choiceNumber)}
          className="action-icon"
        />
        {totalChoices > 2 && (
          <MinusCircleOutlined
            className="action-icon"
            onClick={() => removeChoice(choiceNumber)}
          />
        )}
        {questionType === "checkboxes-special" && (
          <Checkbox className="special" />
        )}
      </Col>
    </Row>
  );
};

export default ImageAssociation;
