import React, { Fragment, useEffect } from "react";
import Errors from "../new-survey/Errors";
import FilterUsers from "./FilterUsers";
import AddScreeningQuestion from "./AddScreeningQuestion";
import { InnerContainer } from "../../../components/page-layout/PageLayout";
import GroupContainer from "./GroupContainer";
import RatioContainer from "./RatioContainer";

const Criteria = ({
  adminQuestions,
  criteria,
  setCriteria,
  groups,
  surveyAccess,
  setGroups,
  isGroupSurvey,
  screeningQuestions,
  setScreeningQuestions,
  errors,
  viewOnly,
}) => {
  const innerContainerStyle = {
    padding: 40,
    marginTop: 32,
    pointerEvents: viewOnly ? "none" : "auto",
  };
  useEffect(() => {
    if(surveyAccess) setCriteria([])
  }, [surveyAccess])

  return (
    <Fragment>
      {!surveyAccess && <InnerContainer style={innerContainerStyle}>
         <FilterUsers
          adminQuestions={adminQuestions}
          groups={groups}
          setGroups={setGroups}
          criteria={criteria}
          setCriteria={setCriteria}
        />
      </InnerContainer>
}
      {!isGroupSurvey && !!criteria?.length && (
        <InnerContainer style={innerContainerStyle}>
          <RatioContainer
            adminQuestions={adminQuestions}
            criteria={criteria}
            setCriteria={setCriteria}
          />
        </InnerContainer>
      )}

      {isGroupSurvey && !!criteria.length && (
        <InnerContainer style={innerContainerStyle}>
          <GroupContainer
            adminQuestions={adminQuestions}
            groups={groups}
            setGroups={setGroups}
            criteria={criteria}
          />
        </InnerContainer>
      )}
      <InnerContainer style={innerContainerStyle}>
        <AddScreeningQuestion
          screeningQuestions={screeningQuestions}
          setScreeningQuestions={setScreeningQuestions}
        />
      </InnerContainer>
      <Errors errors={errors} />
    </Fragment>
  );
};

export default Criteria;
