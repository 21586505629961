import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getUserId, getSurveyIdByToken, isUserRole } from "../../utils";
import SurveyWrapper from "../../features/survey/components/survey/SurveyWrapper";
import c from "classnames/bind";
import styles from "./survey/survey.module.css";
import useGetSurvey from "../../features/survey/hooks/qualSurvey/useGetSurvey";
import useGetOpenSurvey from "../../features/survey/hooks/openSurvey/useGetOpenSurvey";
import useIsOpenSurvey from "../../features/survey/hooks/openSurvey/useIsOpenSurvey";
// {
//   data: {
//     referSurveyToken: Survey.referSurveyToken
//   }
// }

// frontend endpoint:
// <Route path='user/attemptSurvey/:referSurveyToken' component={AttemptSurvey} />

//Endpoint POST to user/:surveyId/addSurvey

const cx = c.bind(styles);

const AttemptSurvey = () => {
  const { referSurveyToken } = useParams();
  const surveyId = getSurveyIdByToken(referSurveyToken);
  const history = useHistory();
  const getOpenSurveyAPI = useGetOpenSurvey();
  const isOpenSurveyAPI = useIsOpenSurvey();
  const getSurveyAPI = useGetSurvey();
  const [userId, setUserId] = useState(getUserId());
  const [isOpen, setIsOpen] = useState(null);
  const [survey, setSurvey] = useState([]);
  const [showSurvey, setShowSurvey] = useState(false);
  const [showError, setShowError] = useState(false);
  const params = new URLSearchParams(window.location.search)
  const token = params.get('token')

  const setSurveyObject = (survey) => {
    if (survey === null) {
      setShowError(true);
    }
    if (survey) {
      setSurvey(survey);
      setShowSurvey(true);
    }
  };

  useEffect(() => {
    (async () => {
      if (!isUserRole()) {
        const isOpenSurvey = await isOpenSurveyAPI(surveyId);
        if (isOpenSurvey === null) {
          setShowError(true);
        } else {
          setIsOpen(isOpenSurvey);
        }
      } else {
        setIsOpen(false);
        setUserId(getUserId());
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (isOpen !== null) {
        if (isOpen) {
          setSurveyObject(await getOpenSurveyAPI(surveyId));
        } else {
          if (!isUserRole()) setShowError(true);
          else setSurveyObject(await getSurveyAPI(surveyId));
        }
      }
    })();
  }, [isOpen]);

  const modalStyles = {
    content: {
      top: "0",
      left: "0",
      width: "100vw",
      height: "100vh",
      border: 0,
      padding: 0,
      borderRadius: 0,
    },
  };

  return (
    <React.Fragment>
      {showSurvey && <SurveyWrapper survey={survey} userId={userId} token={token} />}
      {showError && (
        <div className={cx("survey-container")}>
          <div className={cx("center")}>
            <div className={cx("question-box")}>
              <div
                style={{
                  flexGrow: 1,
                  display: "block",
                  alignItems: "center",
                  fontSize: 16,
                }}
              >
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Sorry!
                </p>
                <p style={{ margin: 0, textAlign: "center" }}>
                  Survey is not taking responses. Please contact the admin.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AttemptSurvey;
