import React, { Fragment } from "react";
import { Switch, Row, Col } from "antd";
import "./UserQualParticipants.css";

const UserQualParticipants = ({ useUserqualUsers, setUseUserqualUsers }) => {
  return (
    <Fragment>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Row justify="space-between">
            <Col span={18}>
              <div style={{ margin: 0, fontSize: "1rem", fontWeight: "bold" }}>
                Userqual participants
              </div>
            </Col>
            <Col>
              <Switch
                checked={!!useUserqualUsers}
                onChange={(checked) => setUseUserqualUsers(checked)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {useUserqualUsers && (
        <Row gutter={[20, 20]}>
          <Col span={30}>
            <span>
              Userqual will add users to the survey which match your given
              category
            </span>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default UserQualParticipants;
