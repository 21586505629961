import React, { Fragment, useState } from "react";
import { capitalizeFirstCharacter } from "../../../utils";
import { Row, Col } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import c from "classnames/bind";
import styles from "./criteria.module.css";
import "./FilterUsers.css";
import { GreenCheckbox } from "../Components";
import { getAgeRange, getQuestion } from "./CriteriaUtils";

const cx = c.bind(styles);

const CustomButton = ({ selected, children, onClick }) => {
  return (
    <div
      className={cx("button", { selected: selected })}
      onClick={() => {
        onClick();
      }}
    >
      <PlusCircleOutlined
        className={cx("button-icon", { selected: selected })}
      />
      {children}
    </div>
  );
};

const FilterUsers = ({ adminQuestions, criteria, setCriteria }) => {
  const [showAllFilters, setShowAllFilters] = useState(() => {
    if (Array.isArray(criteria) && Array.isArray(adminQuestions)) {
      const filterAdminQuestions = adminQuestions.slice(6);
      return criteria.some(
        ({ questionId }) =>
          !!filterAdminQuestions.find(({ _id }) => _id === questionId)
      );
    }
    return false;
  });

  const checkQuestionInCriteria = (id) => {
    const res =
      criteria.filter((e) => e.questionId === id).length > 0 ? true : false;
    return res;
  };

  return (
    <Fragment>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <h3 style={{ margin: 0, fontWeight: "bold" }}>Filter users</h3>
          <div>
            Use the below filters to create the perfect sample set of users for
            your survey.
          </div>
        </Col>
        {adminQuestions && (
          <Fragment>
            <Col span={24}>
              <div className={cx("container")}>
                {adminQuestions
                  .filter(
                    (e, i) =>
                      e.isActive === true &&
                      e.criteriaType === false &&
                      !!e.clientField &&
                      (showAllFilters || i < 6)
                  )
                  .map((e, i) => (
                    <CustomButton
                      selected={checkQuestionInCriteria(e["_id"])}
                      onClick={() => {
                        if (!checkQuestionInCriteria(e["_id"]))
                          setCriteria([
                            ...criteria,
                            {
                              questionId: e["_id"],
                              answer: [],
                              ratios: [],
                              selectedValues: [],
                            },
                          ]);
                        else
                          setCriteria(
                            criteria.filter((cr) => cr.questionId !== e["_id"])
                          );
                      }}
                      key={i}
                    >
                      {capitalizeFirstCharacter(e.clientField)}
                    </CustomButton>
                  ))}
              </div>
            </Col>
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowAllFilters(!showAllFilters)}
                >
                  {showAllFilters ? "Hide" : "Show"} advanced filters{" "}
                  {showAllFilters ? <UpOutlined /> : <DownOutlined />}
                </div>
              </div>
            </Col>
          </Fragment>
        )}
        {criteria.map((e, i) => (
          <div
            key={i}
            className={cx("question-box")}
            style={{
              backgroundColor: "#ecf9fa",
            }}
          >
            {capitalizeFirstCharacter(
              getQuestion(e.questionId, adminQuestions).clientField
            )}
            <DeleteOutlined
              style={{
                float: "right",
                cursor: "pointer",
                fontSize: "125%",
              }}
              onClick={() => {
                setCriteria(criteria.filter((item) => item !== e));
              }}
            />
            <div className={cx("container")}>
              {[
                "multipleChoice",
                "checkboxes",
                "location",
                "checkboxesSpecial",
              ].includes(
                getQuestion(e.questionId, adminQuestions).questionObject
                  .questionType
              ) &&
                getQuestion(
                  e.questionId,
                  adminQuestions
                ).questionObject.options.map((opt, idx) => (
                  <label
                    key={idx}
                    className={cx("option-button")}
                    style={{
                      backgroundColor: "white",
                      padding: 12,
                      borderRadius: 4,
                    }}
                  >
                    <GreenCheckbox
                      name={
                        getQuestion(e.questionId, adminQuestions).clientField
                      }
                      checked={e.answer.includes(idx)}
                      className={cx("option-button-icon")}
                      onChange={(event) => {
                        const res = [...criteria];
                        const exists = e.answer.includes(idx);
                        if (exists) {
                          res[i].answer = e.answer.filter((it) => it !== idx);
                        } else {
                          res[i].answer.push(idx);
                        }
                        setCriteria(res);
                      }}
                    />
                    {opt}
                  </label>
                ))}
              {getQuestion(e.questionId, adminQuestions).questionObject
                .questionType === "age" &&
                getAgeRange({
                  start: getQuestion(e.questionId, adminQuestions)
                    .questionObject.start,
                  end: getQuestion(e.questionId, adminQuestions).questionObject
                    .end,
                  interval: getQuestion(e.questionId, adminQuestions)
                    .questionObject.interval,
                }).map((el, index) => (
                  <label
                    key={index}
                    className={cx("option-button")}
                    style={{
                      backgroundColor: "white",
                      padding: 12,
                      borderRadius: 4,
                    }}
                  >
                    <GreenCheckbox
                      // type="checkbox"
                      name={
                        getQuestion(e.questionId, adminQuestions).clientField
                      }
                      checked={el.value.every((r) => e.answer.includes(r))}
                      className={cx("option-button-icon")}
                      onChange={(event) => {
                        const res = [...criteria];
                        const exists = el.value.every((r) =>
                          e.answer.includes(r)
                        );
                        if (exists) {
                          res[i].answer = e.answer.filter(
                            (it) => !el.value.includes(it)
                          );
                        } else {
                          el.value.forEach((x) => res[i].answer.push(x));
                        }
                        setCriteria(res);
                      }}
                    />
                    {el.label}
                  </label>
                ))}
            </div>
          </div>
        ))}
      </Row>
    </Fragment>
  );
};

export default FilterUsers;
