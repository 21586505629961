export const branchingConditions = {
  CONTAINS: "Contains",
  DOES_NOT_CONTAIN: "Does not contain",
  CONTAINS_ONE_OF: "Contains one of",
  DOES_NOT_CONTAIN_ANY_OF: "Does not contain any of",
  CONTAINS_ALL_OF: "Contains all of",
  DOES_NOT_CONTAIN_ALL_OF: "Does not contain all of",
  IS_EXACTLY: "Is exactly",
  IS_NOT: "Is not",
  IS_FROM_TO: "Is from / to",
  RANKING: "Ranking",
  ASSOCIATION: "Association",
  IMAGE_VIDEO_RATINGS: "Image video ratings",
};

export const branchingActions = {
  SKIP_TO_QUESTION: "Skip to question",
  END_SURVEY: "End survey",
  DISQUALIFY_RESPONDENT: "Disqualify respondent",
  HIDE_QUESTION: "Hide question",
  INVALIDATE_QUESTION: "Invalidate question",
};
