import React from "react";
import { Modal, Checkbox, Radio } from "antd";
import { styled } from "@linaria/react";

export const GreenCheckbox = styled(Checkbox)`
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #71cfd7;
    border-color: #71cfd7;
  }
`;
export const GreenRadio = styled(Radio)`
  & .ant-radio-checked .ant-radio-inner {
    border-color: #71cfd7 !important ;
  }
  & .ant-radio-checked .ant-radio-inner:after {
    background-color: #71cfd7;
  }
  & .ant-radio:hover .ant-radio-inner {
    border-color: #71cfd7;
  }
  & .ant-radio-checked .ant-radio-inner:focus {
    border-color: #71cfd7;
  }
`;
export const CustomModal = styled(Modal)`
  & .ant-btn-primary {
    background-color: #71cfd7;
    border-color: #71cfd7;
  }
  & .ant-btn-primary:hover {
    background-color: #61afb7;
    border-color: #61afb7;
  }
`;
