import React from "react";
import { questionTypes } from "../../../constants/questions";
import Text from "./text/Text";
import MultipleChoice from "./multiple-choice/MultipleChoice";
import Checkboxes from "./checkboxes/Checkboxes";
import StarRating from "./star-rating/StarRating";
import SliderType from "./slider/Slider";
import Likert from "./likert/Likert";
import NPS from "./nps/NPS";
import Ranking from "./ranking/Ranking";
import Dropdown from "./dropdown/Dropdown";
import Association from "./association/Association";
import ImageInstructions from "./image/instructions/ImageInstructions";
import ImageComment from "./image/comment/ImageComment";
import ImageMultipleChoice from "./image/multiple-choice/ImageMultipleChoice";
import ImageCheckboxes from "./image/checkboxes/ImageCheckboxes";
import ImageRating from "./image/rating/ImageRating";
import ImageRanking from "./image/ranking/ImageRanking";
import ImageAssociation from "./image/association/ImageAssociation";
import VideoInstruction from "./video/instruction/VideoInstruction";
import VideoRating from "./video/rating/VideoRating";
import VideoAssociation from "./video/association/VideoAssociation";
import VideoRanking from "./video/ranking/VideoRanking";
import VideoMultipleChoice from "./video/multiple-choice/VideoMultipleChoice";
import VideoCheckboxes from "./video/checkboxes/VideoCheckboxes";
import VideoComment from "./video/comment/VideoComment";
import CheckboxesSpecial from "../../../../../components/user/profile-questions/checkboxes-special/CheckboxesSpecial";
import AdAnalysis from "./ad-analysis/AdAnalysis";
import { InputNumber } from "antd";

export const getComponentByQuestionType = (
  question,
  answer,
  index,
  onChange,
  updateAnswer
) => {
  const { type, options } = question;

  if (answer) {
    switch (type) {
      case questionTypes.TEXT:
        return <Text key={index} onChange={onChange} value={answer[0]} />;
      case questionTypes.MULTI_CHOICE:
        return (
          <MultipleChoice
            key={index}
            choices={options.multiChoices}
            onChange={onChange}
            value={answer[0]}
          />
        );
      case questionTypes.CHECKBOX:
        return (
          <Checkboxes
            key={index}
            choices={options.multiChoices}
            onChange={onChange}
            value={answer}
          />
        );
      case questionTypes.STARS:
        console.log(options);
        const { stars, starType } = options;
        return (
          <StarRating
            key={index}
            options={{ stars, starType }}
            onChange={onChange}
            value={answer[0]}
          />
        );
      case questionTypes.SLIDER:
        answer[0] = answer[0] || 0;
        updateAnswer(answer);
        const {
          sliderType,
          sliderValue,
          sliderMin,
          sliderMax,
          sliderStartLabel,
          sliderMiddleLabel,
          sliderEndLabel,
        } = options;
        return (
          <SliderType
            key={index}
            options={{
              sliderType,
              sliderValue,
              sliderMin,
              sliderMax,
              sliderStartLabel,
              sliderMiddleLabel,
              sliderEndLabel,
            }}
            onChange={onChange}
            value={answer[0]}
          />
        );
      case questionTypes.LIKERT:
        const {
          likertRange,
          likertStartLabel,
          likertMiddleLabel,
          likertEndLabel,
        } = options;
        return (
          <Likert
            key={index}
            options={{
              likertRange,
              likertStartLabel,
              likertMiddleLabel,
              likertEndLabel,
            }}
            onChange={onChange}
            value={answer[0]}
          />
        );
      case questionTypes.NPS:
        return (
          <NPS
            key={index}
            options={{ npsOption: options.npsOption }}
            onChange={onChange}
            value={answer[0]}
          />
        );
      case questionTypes.RANKING:
        answer = answer.length > 0 ? answer : options.multiChoices;
        updateAnswer(answer);
        return <Ranking key={index} onChange={onChange} value={answer} />;
      case questionTypes.DROPDOWN:
        answer[0] = answer[0] || 1;
        updateAnswer(answer);
        const range = options.range;
        return (
          <Dropdown
            key={index}
            options={{ range }}
            onChange={onChange}
            value={answer[0]}
          />
        );
      case questionTypes.ASSOCIATION:
        answer = answer.length > 0 ? answer : options.associationChoices;
        updateAnswer(answer);
        return <Association key={index} onChange={onChange} value={answer} />;
      case questionTypes.IMAGE_INSTRUCTION:
        return <ImageInstructions key={index} options={options} />;
      case questionTypes.IMAGE_COMMENT:
        return (
          <ImageComment
            key={index}
            options={{
              imageData: options.imageData,
              imageLabel: options.imageLabel,
            }}
            value={answer[0]}
            onChange={onChange}
          />
        );
      //   case questionTypes.IMAGE_ABTEST:
      // const { imageABTestChoices } = options
      // return <ImageInstructions options={{ imageABTestChoices }} />;
      case questionTypes.IMAGE_MULTICHOICE:
        const { imageMultiChoices } = options;
        return (
          <ImageMultipleChoice
            key={index}
            options={{ imageMultiChoices }}
            value={answer[0]}
            onChange={onChange}
          />
        );
      case questionTypes.IMAGE_CHECKBOX:
        return (
          <ImageCheckboxes
            key={index}
            options={{ imageMultiChoices: options.imageMultiChoices }}
            value={answer}
            onChange={onChange}
          />
        );
      case questionTypes.IMAGE_STARRATINGCHOICE:
        if (answer.length === 0) {
          answer[0] = {};
          options.imageStarRatingChoices.forEach((val) => {
            answer[0][val.imageLabel] = 0;
          });
          updateAnswer(answer);
        }
        const { imageStarRatingChoices, imageStar, imageStarType } = options;
        return (
          <ImageRating
            key={index}
            options={{ imageStarRatingChoices, imageStar, imageStarType }}
            value={answer[0]}
            onChange={onChange}
          />
        );
      case questionTypes.IMAGE_RANKING:
        answer = answer.length > 0 ? answer : options.imageMultiChoices;
        updateAnswer(answer);
        return <ImageRanking key={index} onChange={onChange} value={answer} />;
      case questionTypes.IMAGE_ASSOCIATION:
        answer = answer.length > 0 ? answer : options.imageAssociationChoices;
        updateAnswer(answer);
        return (
          <ImageAssociation
            key={index}
            options={options}
            onChange={onChange}
            value={answer}
          />
        );
      case questionTypes.VIDEO_INSTRUCTION:
        return <VideoInstruction key={index} options={options} />;
      case questionTypes.VIDEO_COMMENT:
        return (
          <VideoComment
            key={index}
            options={{
              videoData: options.videoData,
              videoLabel: options.videoLabel,
            }}
            value={answer[0]}
            onChange={onChange}
          />
        );
      case questionTypes.VIDEO_MULTICHOICE:
        const { videoMultiChoices } = options;
        return (
          <VideoMultipleChoice
            key={index}
            options={{ videoMultiChoices }}
            value={answer[0]}
            onChange={onChange}
          />
        );
      case questionTypes.VIDEO_CHECKBOX:
        return (
          <VideoCheckboxes
            key={index}
            options={{ videoMultiChoices: options.videoMultiChoices }}
            value={answer}
            onChange={onChange}
          />
        );
      case questionTypes.VIDEO_STARRATINGCHOICE:
        if (answer.length === 0) {
          answer[0] = {};
          options.videoStarRatingChoices.forEach((val) => {
            answer[0][val.videoLabel] = 0;
          });
          updateAnswer(answer);
        }
        const { videoStarRatingChoices, videoStar, videoStarType } = options;
        return (
          <VideoRating
            key={index}
            options={{ videoStarRatingChoices, videoStar, videoStarType }}
            value={answer[0]}
            onChange={onChange}
          />
        );
      case questionTypes.VIDEO_RANKING:
        answer = answer.length > 0 ? answer : options.videoMultiChoices;
        updateAnswer(answer);
        return <VideoRanking key={index} onChange={onChange} value={answer} />;
      case questionTypes.VIDEO_ASSOCIATION:
        answer = answer.length > 0 ? answer : options.videoAssociationChoices;
        updateAnswer(answer);
        return (
          <VideoAssociation
            key={index}
            options={options}
            onChange={onChange}
            value={answer}
          />
        );
      case questionTypes.AGE:
        return (
          <InputNumber
            key={index}
            defaultValue={answer}
            onChange={onChange}
            style={{
              backgroundColor: "#004479",
              color: "white",
              border: "1px solid white",
            }}
          />
        );
      case questionTypes.CHECKBOXESSPECIAL:
        const { specialField, multiChoices } = options;
        return (
          <CheckboxesSpecial
            key={index}
            choices={multiChoices}
            onChange={onChange}
            value={answer}
            specialField={specialField}
            color="white"
          />
        );
      case questionTypes.AD_ANALYSIS:
        const { adAnalysisData } = options;
        return <AdAnalysis options={adAnalysisData} />;
      default:
        return;
    }
  }
};
