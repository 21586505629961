import React, { useEffect, useState } from "react";
import styles from "./slider.module.css";
import c from "classnames/bind";
import { CheckOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Input, Select, Slider, Tooltip } from "antd";
import { useMediaQuery } from "react-responsive";

const cx = c.bind(styles);
const { Option } = Select;

const FormSlider = ({ options, setOptions }) => {
  const {
    sliderMin,
    sliderMax,
    sliderStartLabel,
    sliderMiddleLabel,
    sliderEndLabel,
    sliderType,
  } = options;

  const [sliderValue, setSliderValue] = useState(
    Math.floor((sliderMin + sliderMax) / 2)
  );

  const marks = {
    [sliderMin]: {
      style: {
        color: "#004791",
        top: -35,
      },
      label: sliderType === "percentage" ? sliderMin + "%" : sliderMin,
    },
    [sliderMax]: {
      style: {
        color: "#004791",
        top: -35,
      },
      label: sliderType === "percentage" ? sliderMax + "%" : sliderMax,
    },
  };

  // React.useEffect(() => {
  //   // console.log(marks);
  //   setSliderValue((sliderMin + sliderMax) / 2);
  // });
  useEffect(() => {
    setSliderValue(Math.floor((parseInt(sliderMax) + parseInt(sliderMin)) / 2));
  }, [sliderMin, sliderMax]);

  const isMobile = useMediaQuery({ query: "(max-width: 400px)" });

  console.log("slider type=", sliderValue);

  return (
    <div
      className={cx("grid-container")}
      style={{
        paddingTop: 20,
      }}
    >
      <div className={cx("slider")}>
        <Slider
          tooltipVisible
          tipFormatter={(value) =>
            sliderType === "percentage" ? value + "%" : value
          }
          className="slider-type"
          min={sliderMin}
          max={sliderMax}
          value={sliderValue}
          marks={marks}
        />
        <div className={cx("likert-labels")}>
          <div
            className={cx("likert-label")}
            style={{ textAlign: "left", justifySelf: "start" }}
          >
            {sliderStartLabel}
          </div>
          <div className={cx("likert-label")} style={{ justifySelf: "center" }}>
            {sliderMiddleLabel}
          </div>
          <div
            className={cx("likert-label")}
            style={{ textAlign: "right", justifySelf: "end" }}
          >
            {sliderEndLabel}
          </div>
        </div>
      </div>
      <div className={cx("span-all-cols")}>
        <Select
          value={sliderType}
          onChange={(value) => {
            if (value === "percentage") {
              setOptions({ ...options, sliderMax: 100, sliderType: value });
            } else {
              setOptions({ ...options, sliderType: value });
            }
          }}
        >
          <Option value="percentage">Percentage</Option>
          <Option value="number">Number</Option>
        </Select>
        <div>
          <p>Start Value: </p>
          <Select
            value={sliderMin}
            onChange={(value) => setOptions({ ...options, sliderMin: value })}
          >
            <Option value={1}>1</Option>
            {/* <Option value={2}>2</Option> */}
          </Select>
        </div>
        <div>
          <p>End Value: </p>
          <Select
            value={sliderType === "percentage" ? 100 : sliderMax}
            disabled={sliderType === "percentage" ? true : false}
            onChange={(value) => {
              console.log(value, typeof value);
              setOptions({ ...options, sliderMax: value });
            }}
          >
            {[...Array(98)].map((e, i) => (
              <Option key={i} value={`${i + 3}`}>
                {i + 3}
              </Option>
            ))}
          </Select>
        </div>
      </div>

      <div className={cx("inputFields")}>
        <div>
          <span className={cx("text")}>Start Label:</span>
          <div className={cx("input")}>
            <Input
              placeholder="Start Label"
              value={sliderStartLabel}
              onChange={(e) =>
                setOptions({ ...options, sliderStartLabel: e.target.value })
              }
              className="input-border"
              suffix={
                sliderStartLabel !== "" ? (
                  <CheckOutlined style={{ color: "green" }} />
                ) : (
                  <Tooltip title="You need to fill this option!">
                    <InfoCircleOutlined style={{ color: "#ff9480" }} />
                  </Tooltip>
                )
              }
              // placeholder={isMobile ? "Start Label" : ""}
            />
          </div>
        </div>
        <div>
          <span className={cx("text")}>Middle Label:</span>
          <div className={cx("input")}>
            <Input
              placeholder="Middle Label (Optional)"
              value={sliderMiddleLabel}
              onChange={(e) =>
                setOptions({ ...options, sliderMiddleLabel: e.target.value })
              }
              className="input-border"
              suffix={
                <CheckOutlined style={{ display: "none", color: "green" }} />
              }
              // placeholder={isMobile ? "Middle Label" : ""}
            />
          </div>
        </div>
        <div>
          <span className={cx("text")}>End Label:</span>
          <div className={cx("input")}>
            <Input
              placeholder="End Label"
              value={sliderEndLabel}
              onChange={(e) =>
                setOptions({ ...options, sliderEndLabel: e.target.value })
              }
              className="input-border"
              suffix={
                sliderEndLabel !== "" ? (
                  <CheckOutlined style={{ color: "green" }} />
                ) : (
                  <Tooltip title="You need to fill this option!">
                    <InfoCircleOutlined style={{ color: "#ff9480" }} />
                  </Tooltip>
                )
              }
              // placeholder={isMobile ? "End Label" : ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSlider;
