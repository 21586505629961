import React, { useState } from "react";
import Lightbox from "react-awesome-lightbox";
import "./image-instructions.css";

const ImageInstructions = ({ key, options }) => {
  const [zoom, setZoom] = useState(false);

  return (
    <React.Fragment>
      <div style={{ textAlign: "center" }}>
        <img
          src={options.imageData}
          style={{
            width: "80%",
            height: "auto",
            borderRadius: 5,
            cursor: "pointer",
          }}
          onClick={() => setZoom(true)}
        />
        <br />
        <br />
        <span>{options.imageLabel}</span>
        {zoom && (
          <Lightbox
            image={options.imageData}
            onClose={() => setZoom(false)}
            allowRotate={false}
            showTitle={false}
            doubleClickZoom={2}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default ImageInstructions;
