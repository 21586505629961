import React, { useEffect, useState } from "react";
// antd
import {
  MinusOutlined,
  PlusOutlined,
  PlayCircleFilled,
} from "@ant-design/icons";
import { Collapse, Button } from "antd";
// components
import ProjectPageLayout, {
  Content,
} from "../../../components/page-layout/PageLayout";
import Sidenav from "../../../components/user/sidenav/Sidenav";
import Topbar from "../../../components/topbar/Topbar";
import Confused from "../../../components/user/confused/Confused";
import { userPages } from "../../../constants";
import Survey from "../survey/Survey";
import { useDispatch, useSelector } from "react-redux";
import {
  decrementPendingRequests,
  incrementPendingRequests,
  notify,
} from "../../../utils";
import { getProfileQuestions, editProfile } from "../../../actions/users";
const { Panel } = Collapse;

const getTiming = (type) => {
  switch (type) {
    case 0:
      return "15 mins";
    case 1:
      return "30 mins";
    case 2:
      return "45 mins";
    default:
      return;
  }
};

const getInstructions = (surveyType) => {
  if (surveyType === "quantitative") {
    return (
      <ul>
        <li>You will be asked a set of questions on your background</li>
        <li>
          We will <b>NOT</b> share this information with clients
        </li>
        <li>
          Please be <b>HONEST</b> as this is used to qualify you for surveys
        </li>
        {/* <li>
      See Informational Video for this survey for more information
    </li> */}
        <li>On completion please click the End button to upload the results</li>
        <li>Click on Green button below to start</li>
      </ul>
    );
  }
};

const getSurveyType = (type, mode) => {
  if (!type) return "quantitative";
  if (type && !mode) return "qualitative/unModerated";
  if (type && mode) return "qualitative/moderated";
};

const surveyIndex = 0;
const SampleSurvey = () => {
  const dispatch = useDispatch();
  const allSurveys = useSelector((state) => state.user.surveys);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    incrementPendingRequests();
    dispatch(getProfileQuestions("sampleQuestions"))
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  }, [dispatch]);

  // Derived state
  const survey = allSurveys && allSurveys.length > 0 && allSurveys[surveyIndex];
  const surveyHeader =
    survey &&
    `${survey.name} 
  ${survey.paymentValue ? "/ Rs. " + survey.paymentValue : ""}
  ${survey.timingType ? "/ " + getTiming(survey.timingType) : ""}
  ${survey.questions ? "/ " + survey.questions.length + " questions" : ""}
  `;
  const questions = !!allSurveys ? survey.questions : [];
  const surveyType = survey && getSurveyType(survey.surveyType, survey.mode);

  // Handlers
  const onStartSurvey = () => {
    setShowModal(true);
  };

  const onEndSurvey = (answers) => {
    console.log(answers);
    const values = {
      areQuestionsAnswered: {
        registration: true,
        sample: true,
        additional: false,
      },
    };
    incrementPendingRequests();
    dispatch(editProfile(values))
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  };

  return (
    <React.Fragment>
      <Topbar>
        <Sidenav drawer selectedItem={userPages.FORMS} />
      </Topbar>
      <Sidenav selectedItem={userPages.FORMS} />
      <ProjectPageLayout>
        <Content>
          <Confused />
          {!!survey ? (
            <Collapse
              style={{ marginTop: 24 }}
              expandIconPosition="right"
              expandIcon={({ isActive }) =>
                isActive ? <MinusOutlined /> : <PlusOutlined />
              }
              className="basic-questions-collapse"
            >
              <Panel
                header={surveyHeader}
                key="1"
                style={{ textAlign: "center" }}
              >
                <Button
                  size="large"
                  className="informational-video-btn"
                  style={{ border: "1px solid #194475", color: "#194475" }}
                >
                  Survey Informational Video{" "}
                  <PlayCircleFilled style={{ color: "#C22B7D" }} />
                </Button>
                <p style={{ color: "#194475", textAlign: "left" }}>
                  <h3 style={{ color: "#194475" }}>Instructions:</h3>
                  {survey && getInstructions(surveyType)}
                </p>
                {surveyType === "quantitative" && (
                  <Button
                    size="large"
                    className="start-survey"
                    style={{ backgroundColor: " #00C194", color: "white" }}
                    onClick={onStartSurvey}
                  >
                    {"Start Survey"}
                  </Button>
                )}
                {showModal && (
                  <Survey
                    isOpen={showModal}
                    questions={questions}
                    surveyType={survey.surveyType}
                    onEndSurvey={onEndSurvey}
                    onClose={() => setShowModal(false)}
                    brandName={survey.brandName}
                    brandLogo={survey.brandLogo}
                    surveyInstructions={survey.instructions}
                  />
                )}
              </Panel>
            </Collapse>
          ) : (
            <p>No surveys left...</p>
          )}
        </Content>
      </ProjectPageLayout>
    </React.Fragment>
  );
};

export default SampleSurvey;
