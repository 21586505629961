import React from "react";
import { FolderOpenFilled, UsergroupAddOutlined } from "@ant-design/icons";

export const FolderIcon = () => {
  return (
    <div>
      <FolderOpenFilled
        style={{
          fontSize: 40,
          color: "#bdbdbd",
        }}
      />
    </div>
  );
};

export const SharedFolderIcon = () => {
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <FolderOpenFilled
        style={{
          fontSize: 40,
          color: "#bdbdbd",
        }}
      />
      <UsergroupAddOutlined
        style={{
          position: "absolute",
          top: 19,
          left: 14,
          color: "#f5f5f5",
        }}
      />
    </div>
  );
};
