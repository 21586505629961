import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import "./page-layout.css";

export default function ProjectPageLayout({ children, noTop, style }) {
  let classes = "project-page";
  if (noTop) classes = "project-page no-top";
  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
}

export function Content({ children, style = {}, contentClass = "" }) {
  return (
    <div
      className={
        contentClass
          ? "project-page-content " + contentClass
          : "project-page-content"
      }
      style={style}
    >
      {children}
    </div>
  );
}

export function InnerContainer({ children, contentClass = "", style = {} }) {
  return (
    <div
      className={
        contentClass ? `inner-container ${contentClass}` : "inner-container"
      }
      style={style}
    >
      {children}
    </div>
  );
}

export function InnerTopBar({ title }) {
  const history = useHistory();
  return (
    <div className="project-page-inner-topbar">
      <Button type="link" className="go-back-button" onClick={history.goBack}>
        <LeftOutlined />
        Go back
      </Button>
      <span className="title">{title}</span>
    </div>
  );
}
