export default {
  signin: () => {
    return {
      url: `client/signin`,
      method: `post`,
    };
  },
  signup: () => {
    return {
      url: `client/signup`,
      method: `post`,
    };
  },
  forgotPassword: () => {
    return {
      url: `client/forgotPassword`,
      method: `post`,
    };
  },
  getProfile: (id) => {
    return {
      url: `client/${id}/profile`,
      method: `get`,
    };
  },
  editProfile: (id) => {
    return {
      url: `client/${id}/profile`,
      method: `put`,
    };
  },
  createAccount: (id) => {
    return {
      url: `client/${id}/account`,
      method: "post",
    };
  },
  updateAccountName: (id) => {
    return {
      url: `client/${id}/account/name`,
      method: "put",
    };
  },
  getAccountUsers: (clientId, accountId, invitedUsers = false) => {
    return {
      url: `client/${clientId}/account/users?accountId=${accountId}&invitedUsers=${invitedUsers}`,
      method: "get",
    };
  },
  inviteClient: (clientId) => {
    return {
      url: `client/${clientId}/account/invite`,
      method: "post",
    };
  },
  editAccess: (clientId) => {
    return {
      url: `client/${clientId}/account/edit-access`,
      method: "put",
    };
  },
  removeClient: (clientId) => {
    return {
      url: `client/${clientId}/account/user`,
      method: "delete",
    };
  },
  addBalance: (id) => {
    return {
      url: `client/${id}/profile/addBalance`,
      method: `post`,
    };
  },
  upgradeTier: (id) => {
    return {
      url: `client/${id}/profile/upgradeTier`,
      method: `post`,
    };
  },
  uploadFile: (id) => {
    return {
      url: `client/${id}/fileUpload`,
      method: `post`,
    };
  },
  mergeVideos: (id) => {
    return {
      url: `client/${id}/mergeVideos`,
      method: `post`,
    };
  },
  getSurveys: (id) => {
    return {
      url: `client/${id}/survey`,
      method: `get`,
    };
  },
  createSurvey: (id, projectId) => {
    return {
      url: `client/${id}/survey?projectId=${projectId}`,
      method: `post`,
    };
  },
  getSurvey: (id, surveyId) => {
    return {
      url: `client/${id}/survey/${surveyId}`,
      method: `get`,
    };
  },
  editSurvey: (id, surveyId, sharedCopy = false) => {
    return {
      url: `client/${id}/survey/${surveyId}?sharedCopy=${sharedCopy}`,
      method: `put`,
    };
  },
  editSurveyShared: (id, surveyId) => {
    return {
      url: `client/${id}/survey/${surveyId}/shared`,
      method: "put",
    };
  },
  deleteSurvey: (id, surveyId) => {
    return {
      url: `client/${id}/survey/${surveyId}`,
      method: `delete`,
    };
  },
  copySurvey: (id, surveyId, projectId) => {
    return {
      url: `client/${id}/survey/${surveyId}/copySurvey?projectId=${projectId}`,
      method: `get`,
    };
  },
  publishSurvey: (id, surveyId) => {
    return {
      url: `client/${id}/survey/${surveyId}/publishSurvey`,
      method: `post`,
    };
  },
  sendForApproval: (id, surveyId) => {
    return {
      url: `client/${id}/survey/${surveyId}/sendForApproval`,
      method: "post",
    };
  },
  rejectSurvey: (id, surveyId) => {
    return {
      url: `client/${id}/survey/${surveyId}/rejectSurvey`,
      method: "post",
    };
  },
  upsertQualitativeData: (id, surveyId) => {
    return {
      url: `client/${id}/survey/${surveyId}/upsertQualitativeData`,
      method: `post`,
    };
  },
  fetchQualitativeData: (id, surveyId, slotKey) => {
    return {
      url: `client/${id}/survey/${surveyId}/fetchQualitativeData?slotKey=${slotKey}`,
      method: `get`,
    };
  },
  generateOpenModeratedLink: (id, surveyId) => {
    return {
      url: `client/${id}/openSurvey/${surveyId}/generateModeratedLink`,
      method: `get`,
    };
  },
  removeReel: (id, surveyId) => {
    return {
      url: `client/${id}/survey/${surveyId}/removeReel`,
      method: `delete`,
    };
  },
  createReel: (id, surveyId) => {
    return {
      url: `client/${id}/survey/${surveyId}/createReel`,
      method: `post`,
      data: {
        inputs: null,
        output: {
          resolution: {
            height: 720,
            width: 1280,
          },
          nameModifier: "newClip",
        },
      },
    };
  },
  addReel: (id, surveyId) => {
    return {
      url: `client/${id}/survey/${surveyId}/addReel`,
      method: `post`,
    };
  },
  numberOfUsersMatchingCriteria: (id, surveyId) => {
    return {
      url: `client/${id}/survey/${surveyId}/numberOfUsersMatchingCriteria`,
      method: `get`,
    };
  },
  getVideo: (id, surveyId) => {
    return {
      url: `client/${id}/survey/${surveyId}/video`,
      method: `get`,
    };
  },
  getVideoAnalysis: (id, surveyId, jobId, nextToken) => {
    return {
      url: `client/${id}/survey/${surveyId}/getVideoAnalysis/${jobId}?nextToken=${nextToken}`,
      method: `get`,
    };
  },
  qualitativeSummaries: (id, surveyId) => {
    return {
      url: `client/${id}/survey/${surveyId}/qualitativeSummaries`,
      method: `get`,
    };
  },
  getTranscript: (id, surveyId) => {
    return {
      url: `client/${id}/survey/${surveyId}/transcript`,
      method: `get`,
    };
  },
  getWordCloudReport: (cliendId) => {
    return {
      url: `client/${cliendId}/getAnalysis`,
      method: `post`,
    };
  },
  getPanels: (id) => {
    return {
      url: `client/${id}/panel`,
      method: `get`,
    };
  },
  updatePanels: (id) => {
    return {
      url: `client/${id}/panel`,
      method: `put`,
    };
  },
  createPanel: (id) => {
    return {
      url: `client/${id}/panel`,
      method: `post`,
    };
  },
  getPanel: (id, panelId) => {
    return {
      url: `client/${id}/panel/${panelId}`,
      method: `get`,
    };
  },
  getPanelUsers: (id, panelId) => {
    return {
      url: `client/${id}/panel/${panelId}/users`,
      method: `get`,
    };
  },
  editPanel: (id, panelId) => {
    return {
      url: `client/${id}/panel/${panelId}`,
      method: `put`,
    };
  },
  addUserToPanel: (id, panelId) => {
    return {
      url: `client/${id}/panel/${panelId}`,
      method: `post`,
    };
  },
  deletePanel: (id, panelId) => {
    return {
      url: `client/${id}/panel/${panelId}`,
      method: `delete`,
    };
  },
  getProjects: (id) => {
    return {
      url: `client/${id}/project`,
      method: "get",
    };
  },
  createProject: (id) => {
    return {
      url: `client/${id}/project`,
      method: "post",
    };
  },
  getProject: (clientId, projectId) => {
    return {
      url: `client/${clientId}/project/${projectId}`,
      method: "get",
    };
  },
  editProjectName: (clientId, projectId) => {
    return {
      url: `client/${clientId}/project/${projectId}/name`,
      method: "put",
    };
  },
  editProjectShare: (clientId, projectId) => {
    return {
      url: `client/${clientId}/project/${projectId}/shared`,
      method: "put",
    };
  },
  deleteProject: (clientId, projectId) => {
    return {
      url: `client/${clientId}/project/${projectId}`,
      method: "delete",
    };
  },
  fetchSurveyInfo: (clientId) => {
    return {
      url: `client/${clientId}/account/surveyInfo`,
      method: "get",
    };
  },
  getClientInformation: (clientId, targetClientId) => {
    return {
      url: `client/${clientId}/information/${targetClientId}`,
      method: `get`,
    };
  },
  employOpenModeratedLink: (clientId, surveyId, token) => {
    return {
      url: `client/${clientId}/openSurvey/${surveyId}/employModeratedLink/${token}`,
      method: 'get'
    } 
  }
};
