import createSurveyIcon from "../../../assets/create-survey.png";
import historyIcon from "../../../assets/history.png";
import logOutIcon from "../../../assets/logout.png";
import profileIcon from "../../../assets/profile-icon.png";
import publishedSurveyIcon from "../../../assets/published-survey.png";
import settingIcon from "../../../assets/settings.png";
import surveyResultIcon from "../../../assets/survey-result.png";

export default {
  createSurveyIcon,
  historyIcon,
  logOutIcon,
  profileIcon,
  publishedSurveyIcon,
  settingIcon,
  surveyResultIcon,
};
