import { Radio } from "antd";
import React from "react";
import Thumbnail from "../image-thumbnail/ImageThumbnail";
import "./multiple-choice.css";

const ImageMultipleChoice = ({ key, options, value, onChange = () => {} }) => {
  return (
    <Radio.Group
      key={key}
      className="img-multiple-choice"
      defaultValue={value}
      onChange={(e) => onChange(e.target.value)}
    >
      {options.imageMultiChoices.map((val, index) => (
        <div className="img-multi-option-container">
          <Radio value={index}></Radio>
          <Thumbnail imgUrl={val.imageData} style={{ marginLeft: 8 }} />
          <div className="img-checkboxes-label">{val.imageLabel}</div>
        </div>
      ))}
    </Radio.Group>
  );
};

export default ImageMultipleChoice;
