import React, { useState } from "react";
import styles from "./AssociationChoices.module.css";
import c from "classnames/bind";
import {
  CheckOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Input, Tooltip } from "antd";

const cx = c.bind(styles);

const AssociationChoices = (props) => {
  // Props
  const {
    dragHandleProps,
    choiceNumber,
    choice,
    isFilled,
    addChoice,
    removeChoice,
    totalChoices,
    setValue,
  } = props;

  return (
    <div className={cx("grid-container")} style={{ marginBottom: 5 }}>
      <div className={cx("choiceNumber")}>
        <div
          style={{ margin: "auto", height: 20, width: 20, textAlign: "center" }}
        >
          <span className="choice-numberCircle">{choiceNumber}</span>
          <MenuOutlined className={cx("menu-outlined")} {...dragHandleProps} />
        </div>
      </div>
      <div className={cx("fields")}>
        <div className={cx("inputFields")}>
          <div>
            <Input
              size="large"
              placeholder="Label"
              style={{ borderRadius: 3 }}
              value={choice.label}
              onChange={(event) =>
                setValue({ ...choice, label: event.target.value })
              }
              suffix={
                isFilled.label ? (
                  <CheckOutlined style={{ color: "green" }} />
                ) : (
                  <Tooltip title="You need to fill this option!">
                    <InfoCircleOutlined style={{ color: "#ff9480" }} />
                  </Tooltip>
                )
              }
              className="input-border"
            />
          </div>
          <div>
            <Input
              size="large"
              placeholder="Association"
              style={{ borderRadius: 3 }}
              value={choice.association}
              onChange={(event) =>
                setValue({ ...choice, association: event.target.value })
              }
              suffix={
                isFilled.association ? (
                  <CheckOutlined style={{ color: "green" }} />
                ) : (
                  <Tooltip title="You need to fill this option!">
                    <InfoCircleOutlined style={{ color: "#ff9480" }} />
                  </Tooltip>
                )
              }
              className="input-border"
            />
          </div>
        </div>
        <div className={cx("controlFields")} style={{ margin: "auto 2px" }}>
          {
            <div className={cx("controls")} style={{ margin: "auto 2px" }}>
              <PlusCircleOutlined
                onClick={() => addChoice(choiceNumber)}
                className="action-icon"
              />
              {totalChoices > 2 && (
                <MinusCircleOutlined
                  className="action-icon"
                  onClick={() => removeChoice(choiceNumber)}
                />
              )}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default AssociationChoices;
