import React from "react";

export const dataColumns = [
  {
    title: "Answer Choices",
    dataIndex: "answerChoices",
  },
  {
    title: "Responses",
    dataIndex: "responses",
    render: (text) => text + "%",
    align: "center",
    sorter: (a, b) => a.responses - b.responses,
  },
  {
    title: "Number",
    dataIndex: "number",
    align: "center",
    sorter: (a, b) => a.number - b.number,
  },
];

export const sliderDataColumns = [
  {
    title: "Answer Choices",
    dataIndex: "answerChoices",
  },
  {
    title: "Average Number",
    dataIndex: "averageNumber",
    align: "center",
    sorter: (a, b) => a.averageNumber - b.averageNumber,
  },
  {
    title: "Total Number",
    dataIndex: "totalNumber",
    align: "center",
    sorter: (a, b) => a.totalNumber - b.totalNumber,
  },
  {
    title: "Responses",
    dataIndex: "responses",
    align: "center",
    sorter: (a, b) => a.responses - b.responses,
  },
];

export const npsDataColumns = [
  {
    title: "",
    dataIndex: "Responses",
  },
  {
    title: "DETRACTORS(0-6)",
    dataIndex: "detractors",
    align: "center",
  },
  {
    title: "PASSIVES(7-8)",
    dataIndex: "passives",
    align: "center",
  },
  {
    title: "PROMOTORS(9-10)",
    dataIndex: "promotors",
    align: "center",
  },
  {
    title: "NET PROMOTOR SCORE",
    dataIndex: "nps",
    align: "center",
  },
];

export const npsStatsColumns = [
  {
    title: "",
    dataIndex: "title",
    align: "center",
  },
  {
    title: "MINIMUM",
    dataIndex: "min",
    align: "center",
  },
  {
    title: "LOWER QUARTILE",
    dataIndex: "lower",
    align: "center",
  },
  {
    title: "MEDIAN",
    dataIndex: "median",
    align: "center",
  },
  {
    title: "UPPER QUARTILE",
    dataIndex: "upper",
    align: "center",
  },
  {
    title: "MAXIMUM",
    dataIndex: "max",
    align: "center",
  },
];

export const starDataColumns = [];

export const basicStatisticsColumns = [
  {
    title: "Minimum",
    dataIndex: "min",
    align: "center",
    sorter: (a, b) => a.min - b.min,
  },
  {
    title: "Maximum",
    dataIndex: "max",
    align: "center",
    sorter: (a, b) => a.max - b.max,
  },
  {
    title: "Median",
    dataIndex: "median",
    align: "center",
    sorter: (a, b) => a.median - b.median,
  },
  {
    title: "Mean",
    dataIndex: "mean",
    align: "center",
    sorter: (a, b) => a.mean - b.mean,
  },
  {
    title: "Standard Daviation",
    dataIndex: "sd",
    align: "center",
    sorter: (a, b) => a.sd - b.sd,
  },
];

export const starTableColumns = (totalStar, labels) => {
  let columns = [];
  columns.push({
    title: "",
    dataIndex: "option",
  });
  [...new Array(totalStar)].map((value, index) => {
    columns.push({
      title: labels ? labels[index] : index + 1,
      dataIndex: index + 1,
      align: "center",
      sorter: (a, b) => a[index + 1].props.value - b[index + 1].props.value,
    });
  });
  columns.push({
    title: "Total",
    dataIndex: "total",
    align: "center",
    sorter: (a, b) => a.total - b.total,
  });
  columns.push({
    title: "Weighted Average",
    dataIndex: "weightedAverage",
    align: "center",
    sorter: (a, b) => a.weightedAverage - b.weightedAverage,
  });
  return columns;
};

export const starTableData = (options, decimalPlaces) => {
  let dataColumns = [];
  options.map((option, index) => {
    let column = {};
    let total = 0;
    let weightedTotal = 0;
    const percentageValue = calculatePercentage(option.answered);
    column.key = index;
    column["option"] = option.label;
    option.answered.map((value, innerIndex) => {
      column[innerIndex + 1] = (
        <TableCell
          value={value}
          percentageValue={percentageValue[innerIndex]}
          decimalPlaces={decimalPlaces}
        />
      );
      total += value;
      weightedTotal += value * (innerIndex + 1);
    });
    column["total"] = total;
    column["weightedAverage"] = (weightedTotal / total).toFixed(decimalPlaces);
    dataColumns.push(column);
  });
  return dataColumns;
};

export const calculatePercentage = (arr) => {
  const totalAnswered = arr.reduce((sum, value) => sum + value, 0);
  console.log(totalAnswered);
  return arr.map((value) => (value * 100) / totalAnswered);
};

const sort = (a, b) => {
  const check = a;
  console.log(a.props.value);
};

export const TableCell = ({ value, percentageValue, decimalPlaces }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>{value}</div>
      <div>{percentageValue.toFixed(decimalPlaces) + "%"}</div>
    </div>
  );
};
