import { useState } from "react";
import uploader from "../../../../utils/qual_unmoderated/uploader";
import {
  decrementPendingRequests,
  incrementPendingRequests,
  notify,
} from "../../../../../../utils";
import { BASEURL } from "../../../../../../constants";

function useUploadVideoHandler(surveyId, userId = "") {
  const [ss, setSS] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [merger, setMerger] = useState(null);
  const [video, setVideo] = useState(null);
  const [screen, setScreen] = useState(null);
  const up = uploader(`${BASEURL}/stream`, `${surveyId}_${userId}`);

  const initUpload = async () => {
    incrementPendingRequests();
    return up
      .init()
      .then(() => {
        up.startUpload();
        setMediaRecorder(up.mediaRecorder);
        setMerger(up.merger);
        setVideo(up.videoObject);
        setScreen(up.screen);
        setSS(up.socket);
        return true;
      })
      .catch((err) => {
        notify(
          "error",
          "Permission Denied",
          "Please give the required permissions to continue with the survey."
        );
        return false;
      })
      .finally(() => {
        decrementPendingRequests();
      });
  };

  const endUpload = (includeSS = true) => {
    if (!includeSS) {
      return up.endUpload(null, mediaRecorder, merger, video, screen);
    }
    up.endUpload(ss, mediaRecorder, merger, video, screen);
  };

  return [initUpload, endUpload];
}

export default useUploadVideoHandler;
