import React from "react";
import { Modal } from "antd";

import styles from "./DeletePanelUserConfirmModal.module.css";
import c from "classnames/bind";

const cx = c.bind(styles);

const DeletePanelUserConfirmModal = ({ handleOk, handleCancel, isVisible }) => {
  return (
    <Modal
      className={cx("delete-panel-confirm-modal")}
      centered={true}
      okText="Delete Panel"
      cancelText="Cancel"
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={true}
      okButtonProps={{ size: "large", className: "yesButton" }}
      cancelButtonProps={{ size: "large", className: "noButton" }}
    >
      <h3>Are you sure you want to delete the panel ?</h3>
    </Modal>
  );
};

export default DeletePanelUserConfirmModal;
