import {
  DeleteOutlined,
  LeftOutlined,
  SwapOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Button, Col, Row, Select, Badge, Popover, Modal } from "antd";
import React, { useState, Fragment } from "react";
import { branchingConditions, branchingActions } from "../../../constants";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import { useMediaQuery } from "react-responsive";
import dotProp from "dot-prop-immutable";
import { isNumber } from "lodash-es";
import { notify } from "../../../utils";
import Errors from "../new-survey/Errors";
import getBranchingErrors from "./handleErrors";
import "./branching-logic.css";

const dropdownStyle = {
  boxShadow: "rgb(0 0 0 / 15%) 1px 1px 2px",
  width: "100%",
  borderRadius: 4,
  border: "none",
};

// Testing data
const rules = [
  {
    questions: [
      {
        index: 0, // index of question
        condition: branchingConditions.CONTAINS,
        choices: [0], // index of choices
        logicalOr: false,
      },
      /* {
              index: 2,
              condition: branchingConditions.DOES_NOT_CONTAIN_ALL_OF,
              choices: [0, 1, 2],
              logicalOr: false
            } */
    ],
    actions: [
      {
        name: branchingActions.HIDE_QUESTION,
        question: 3, // index of question
      },
    ],
  },
];

const DEFAULT_VAULES = {
  RULE: {
    questions: [{ logicalOr: false }],
    actions: [{}],
  },
  QUESTION: { logicalOr: false },
  ACTION: {},
};

const QUESTION_TYPE_ONE = [
  "multiChoices",
  "checkBoxes",
  "imageMultiChoices",
  "imageCheckBoxes",
  "videoMultiChoices",
  "videoCheckBoxes",
];
const QUESTION_TYPE_TWO = [
  "stars",
  "slider",
  "likert",
  "netPromotorScore",
  "dropDown",
];
const QUESTION_TYPE_THREE = ["ranking", "imageRanking", "videoRanking"];
const QUESTION_TYPE_FOUR = [
  "association",
  "imageAssociation",
  "videoAssociation",
];
const QUESTION_TYPE_FIVE = ["imageStarRatingChoices", "videoStarRatingChoices"];
export const CONDITION_TYPE_ONE = [
  branchingConditions.CONTAINS,
  branchingConditions.DOES_NOT_CONTAIN,
  branchingConditions.CONTAINS_ONE_OF,
  branchingConditions.DOES_NOT_CONTAIN_ANY_OF,
  branchingConditions.CONTAINS_ALL_OF,
  branchingConditions.DOES_NOT_CONTAIN_ALL_OF,
  branchingConditions.IS_EXACTLY,
  branchingConditions.IS_NOT,
];
export const CONDITION_TYPE_TWO = [branchingConditions.IS_FROM_TO];
export const CONDITION_TYPE_THREE = [branchingConditions.RANKING];
export const CONDITION_TYPE_FOUR = [branchingConditions.ASSOCIATION];
export const CONDITION_TYPE_FIVE = [branchingConditions.IMAGE_VIDEO_RATINGS];

const questionTypeRange = (type, options) => {
  function getRange(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }

  switch (type) {
    case "stars": {
      const { stars } = options;
      return getRange(1, stars);
    }
    case "netPromotorScore": {
      return getRange(0, 10);
    }
    case "likert": {
      const { likertRange } = options;
      return getRange(1, likertRange);
    }
    case "slider": {
      const { sliderMin, sliderMax } = options;
      return getRange(sliderMin, sliderMax);
    }
    case "dropDown": {
      const { range } = options;
      return getRange(1, range);
    }
    case "ranking": {
      const { multiChoices } = options;
      return getRange(1, multiChoices.length);
    }

    case "imageRanking": {
      const { imageMultiChoices } = options;
      return getRange(1, imageMultiChoices.length);
    }

    case "videoRanking": {
      const { videoMultiChoices } = options;
      return getRange(1, videoMultiChoices.length);
    }

    case "imageStarRatingChoices": {
      const { imageStar } = options;
      return getRange(1, imageStar);
    }
    case "videoStarRatingChoices": {
      const { videoStar } = options;
      return getRange(1, videoStar);
    }
  }
};

const BranchingLogic = ({
  closeModal,
  questions,
  oldBranchingRules,
  onSaveRules,
}) => {
  // Global states
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 430px)" });

  // Component states
  const [rules, setRules] = useState(oldBranchingRules);

  // Derived states
  const allowedQuestions = questions.filter(
    (el) =>
      QUESTION_TYPE_ONE.includes(el.type) ||
      QUESTION_TYPE_TWO.includes(el.type) ||
      QUESTION_TYPE_THREE.includes(el.type) ||
      QUESTION_TYPE_FOUR.includes(el.type) ||
      QUESTION_TYPE_FIVE.includes(el.type)
  );
  const errors = getBranchingErrors(rules);

  // Rule handlers
  const addRule = (index) => {
    setRules((prevRules) => {
      if (index === undefined) {
        return prevRules.concat([DEFAULT_VAULES.RULE]);
      } else {
        return [
          ...prevRules.slice(0, index + 1),
          DEFAULT_VAULES.RULE,
          ...prevRules.slice(index + 1),
        ];
      }
    });
  };

  const removeRule = (ruleIndex) => {
    setRules((prevRules) => dotProp.delete(prevRules, `${ruleIndex}`));
  };

  // Question handlers
  const addQuestion = (ruleIndex) => {
    setRules((prevRules) =>
      dotProp.merge(
        prevRules,
        `${ruleIndex}.questions`,
        DEFAULT_VAULES.QUESTION
      )
    );
  };

  const getQuestionAttribute = (ruleIndex, questionIndex, attr) => {
    return dotProp.get(
      rules,
      `${ruleIndex}.questions.${questionIndex}.${attr}`
    );
  };

  const setQuestionAttribute = (ruleIndex, questionIndex, attr, value) => {
    setRules((prevRules) =>
      dotProp.set(
        prevRules,
        `${ruleIndex}.questions.${questionIndex}.${attr}`,
        value
      )
    );
  };

  const deleteQuestion = (ruleIndex, questionIndex) => {
    setRules((prevRules) =>
      dotProp.delete(prevRules, `${ruleIndex}.questions.${questionIndex}`)
    );
  };

  // Action handlers
  const addAction = (ruleIndex) => {
    setRules((prevRules) =>
      dotProp.merge(prevRules, `${ruleIndex}.actions`, DEFAULT_VAULES.ACTION)
    );
  };

  const getActionAttribute = (ruleIndex, actionIndex, attr) => {
    return dotProp.get(rules, `${ruleIndex}.actions.${actionIndex}.${attr}`);
  };

  const setActionAttribute = (ruleIndex, actionIndex, attr, value) => {
    setRules((prevRules) =>
      dotProp.set(
        prevRules,
        `${ruleIndex}.actions.${actionIndex}.${attr}`,
        value
      )
    );
  };

  const deleteAction = (ruleIndex, actionIndex) => {
    setRules((prevRules) =>
      dotProp.delete(prevRules, `${ruleIndex}.actions.${actionIndex}`)
    );
  };

  // Misc
  const getQuestionChoices = (ruleIndex, questionIndex) => {
    const idx = getQuestionAttribute(ruleIndex, questionIndex, "index");
    const { type, options } = questions[idx];

    switch (type) {
      case "multiChoices":
        return options.multiChoices.map((x) => x.label);
      case "checkBoxes":
        return options.multiChoices.map((x) => x.label);
      case "imageMultiChoices":
        return options.imageMultiChoices.map((x) => x.imageLabel);
      case "imageCheckBoxes":
        return options.imageMultiChoices.map((x) => x.imageLabel);
      case "videoMultiChoices":
        return options.videoMultiChoices.map((x) => x.videoLabel);
      case "videoCheckBoxes":
        return options.videoMultiChoices.map((x) => x.videoLabel);
      case "ranking":
        return options.multiChoices.map((x) => x.label);
      case "imageRanking":
        return options.imageMultiChoices.map((x) => x.imageLabel);
      case "videoRanking":
        return options.videoMultiChoices.map((x) => x.videoLabel);
      case "association":
        return {
          labels: options.associationChoices.map((x) => x.label),
          associations: options.associationChoices.map((x) => x.association),
        };
      case "imageAssociation":
        return {
          labels: options.imageAssociationChoices.map(
            (_, i) => `Image ${i + 1}`
          ),
          associations: options.imageAssociationChoices.map(
            (x) => x.association
          ),
        };
      case "videoAssociation":
        return {
          labels: options.videoAssociationChoices.map(
            (_, i) => `Video ${i + 1}`
          ),
          associations: options.videoAssociationChoices.map(
            (x) => x.association
          ),
        };
      case "imageStarRatingChoices":
        return options.imageStarRatingChoices.map((x) => x.imageLabel);
      case "videoStarRatingChoices":
        return options.videoStarRatingChoices.map((x) => x.videoLabel);
      default:
        return [];
    }
  };

  const showSelectQuestion = (ruleIndex, actionIndex) => {
    const actionName = getActionAttribute(ruleIndex, actionIndex, "name");
    return [
      branchingActions.SKIP_TO_QUESTION,
      branchingActions.HIDE_QUESTION,
      branchingActions.INVALIDATE_QUESTION,
    ].includes(actionName);
  };

  const handleChangeLogicalOperator = (ruleIndex, questionIndex) => {
    setRules((prevRules) =>
      dotProp.toggle(
        prevRules,
        `${ruleIndex}.questions.${questionIndex}.logicalOr`
      )
    );
  };

  const isLogicalOrOperator = (ruleIndex, questionIndex) => {
    return dotProp.get(
      rules,
      `${ruleIndex}.questions.${questionIndex}.logicalOr`
    );
  };

  const getSelectMode = (ruleIndex, questionIndex) => {
    const cond = dotProp.get(
      rules,
      `${ruleIndex}.questions.${questionIndex}.condition`
    );
    return [
      branchingConditions.CONTAINS_ONE_OF,
      branchingConditions.DOES_NOT_CONTAIN_ANY_OF,
      branchingConditions.CONTAINS_ALL_OF,
      branchingConditions.DOES_NOT_CONTAIN_ALL_OF,
    ].includes(cond)
      ? "multiple"
      : "";
  };

  const getMaxQuestionIndex = (ruleIndex) => {
    const rule = dotProp.get(rules, `${ruleIndex}`);
    const maxIndex = rule.questions.reduce(
      (prev, curr) => Math.max(prev, curr.index),
      0
    );
    return maxIndex;
  };

  const renderConditionsAndOptions = (ruleIndex, questionIndex) => {
    const idx = getQuestionAttribute(ruleIndex, questionIndex, "index");
    const type = questions[idx]?.type;
    const options = questions[idx]?.options;

    if (QUESTION_TYPE_ONE.includes(type)) {
      return (
        <Fragment>
          {/*CONDITIONS*/}
          <Col xs={24} sm={24} md={8}>
            {isNumber(
              getQuestionAttribute(ruleIndex, questionIndex, "index")
            ) && (
              <Select
                className="logic-dropdown"
                size="large"
                placeholder="Select Condition"
                style={dropdownStyle}
                value={getQuestionAttribute(
                  ruleIndex,
                  questionIndex,
                  "condition"
                )}
                onChange={(value) => {
                  setQuestionAttribute(
                    ruleIndex,
                    questionIndex,
                    "condition",
                    value
                  );
                  setQuestionAttribute(ruleIndex, questionIndex, "choices", []);
                }}
              >
                {CONDITION_TYPE_ONE.map((el) => (
                  <Select.Option key={el} value={el}>
                    {el}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Col>
          {/* CHOICES */}
          <Col xs={24} sm={24} md={6}>
            {getQuestionAttribute(ruleIndex, questionIndex, "condition") && (
              <Select
                className="logic-dropdown"
                size="large"
                mode={getSelectMode(ruleIndex, questionIndex)}
                placeholder="Select choices"
                style={dropdownStyle}
                value={getQuestionAttribute(
                  ruleIndex,
                  questionIndex,
                  "choices"
                )}
                onChange={(value) => {
                  const arr = Array.isArray(value) ? value : [value];
                  setQuestionAttribute(
                    ruleIndex,
                    questionIndex,
                    "choices",
                    arr
                  );
                }}
              >
                {getQuestionChoices(ruleIndex, questionIndex).map((el, idx) => (
                  <Select.Option key={idx} value={idx}>
                    {el}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Col>
        </Fragment>
      );
    }

    if (QUESTION_TYPE_TWO.includes(type)) {
      const range = questionTypeRange(type, options);
      return (
        <>
          <Col xs={24} sm={24} md={4}>
            <Select
              className="logic-dropdown"
              size="large"
              style={dropdownStyle}
              value="Is from"
            >
              <Select.Option value="Is from">Is from</Select.Option>
            </Select>
          </Col>
          <Col xs={24} sm={24} md={3}>
            <Select
              className="logic-dropdown"
              size="large"
              placeholder="From"
              style={dropdownStyle}
              value={getQuestionAttribute(ruleIndex, questionIndex, "from")}
              onChange={(value) =>
                setQuestionAttribute(ruleIndex, questionIndex, "from", value)
              }
            >
              {range.map((el) => (
                <Select.Option key={el} value={el}>
                  {el}
                </Select.Option>
              ))}
            </Select>
          </Col>
          {isNumber(getQuestionAttribute(ruleIndex, questionIndex, "from")) && (
            <>
              <Col xs={24} sm={24} md={4}>
                <Select
                  className="logic-dropdown"
                  size="large"
                  style={dropdownStyle}
                  value="To"
                >
                  <Select.Option value="To">To</Select.Option>
                </Select>
              </Col>
              <Col xs={24} sm={24} md={3}>
                <Select
                  className="logic-dropdown"
                  size="large"
                  placeholder="To"
                  style={dropdownStyle}
                  value={getQuestionAttribute(ruleIndex, questionIndex, "to")}
                  onChange={(value) =>
                    setQuestionAttribute(ruleIndex, questionIndex, "to", value)
                  }
                >
                  {range
                    .filter(
                      (x) =>
                        x >=
                        getQuestionAttribute(ruleIndex, questionIndex, "from")
                    )
                    .map((el) => (
                      <Select.Option key={el} value={el}>
                        {el}
                      </Select.Option>
                    ))}
                </Select>
              </Col>
            </>
          )}
        </>
      );
    }

    if (QUESTION_TYPE_THREE.includes(type)) {
      const range = questionTypeRange(type, options);
      return (
        <>
          {/*LABEL*/}
          <Col xs={24} sm={24} md={8}>
            {isNumber(
              getQuestionAttribute(ruleIndex, questionIndex, "index")
            ) && (
              <Select
                className="logic-dropdown"
                size="large"
                placeholder="Select Label"
                style={{ ...dropdownStyle, width: "80%" }}
                value={getQuestionAttribute(ruleIndex, questionIndex, "label")}
                onChange={(value) =>
                  setQuestionAttribute(ruleIndex, questionIndex, "label", value)
                }
              >
                {getQuestionChoices(ruleIndex, questionIndex).map((el) => (
                  <Select.Option key={el} value={el}>
                    {el}
                  </Select.Option>
                ))}
              </Select>
            )}
            <div
              style={{
                width: "20%",
                display: "inline-block",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              is
            </div>
          </Col>
          {/* RANK */}
          <Col xs={24} sm={24} md={6}>
            {getQuestionAttribute(ruleIndex, questionIndex, "label") && (
              <Select
                className="logic-dropdown"
                size="large"
                mode={getSelectMode(ruleIndex, questionIndex)}
                placeholder="Rank"
                style={dropdownStyle}
                value={getQuestionAttribute(ruleIndex, questionIndex, "rank")}
                onChange={(value) =>
                  setQuestionAttribute(ruleIndex, questionIndex, "rank", value)
                }
              >
                {range.map((el) => (
                  <Select.Option key={el} value={el}>
                    {el}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Col>
        </>
      );
    }

    if (QUESTION_TYPE_FOUR.includes(type)) {
      return (
        <>
          {/*LABEL*/}
          <Col xs={24} sm={24} md={8}>
            {isNumber(
              getQuestionAttribute(ruleIndex, questionIndex, "index")
            ) && (
              <Select
                className="logic-dropdown"
                size="large"
                placeholder="Select Label"
                style={{ ...dropdownStyle, width: "80%" }}
                value={getQuestionAttribute(ruleIndex, questionIndex, "label")}
                onChange={(value) =>
                  setQuestionAttribute(ruleIndex, questionIndex, "label", value)
                }
              >
                {getQuestionChoices(ruleIndex, questionIndex).labels.map(
                  (el) => (
                    <Select.Option key={el} value={el}>
                      {el}
                    </Select.Option>
                  )
                )}
              </Select>
            )}
            <div
              style={{
                width: "20%",
                display: "inline-block",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <Popover content="Is associated with">==</Popover>
            </div>
          </Col>
          {/* RANK */}
          <Col xs={24} sm={24} md={6}>
            {getQuestionAttribute(ruleIndex, questionIndex, "label") && (
              <Select
                className="logic-dropdown"
                size="large"
                mode={getSelectMode(ruleIndex, questionIndex)}
                placeholder="Association"
                style={dropdownStyle}
                value={getQuestionAttribute(
                  ruleIndex,
                  questionIndex,
                  "association"
                )}
                onChange={(value) =>
                  setQuestionAttribute(
                    ruleIndex,
                    questionIndex,
                    "association",
                    value
                  )
                }
              >
                {getQuestionChoices(ruleIndex, questionIndex).associations.map(
                  (el) => (
                    <Select.Option key={el} value={el}>
                      {el}
                    </Select.Option>
                  )
                )}
              </Select>
            )}
          </Col>
        </>
      );
    }

    if (QUESTION_TYPE_FIVE.includes(type)) {
      const range = questionTypeRange(type, options);
      return (
        <>
          <Col xs={24} sm={24} md={4}>
            <Select
              className="logic-dropdown"
              size="large"
              style={dropdownStyle}
              placeholder="Label"
              value={getQuestionAttribute(ruleIndex, questionIndex, "label")}
              onChange={(value) =>
                setQuestionAttribute(ruleIndex, questionIndex, "label", value)
              }
            >
              {getQuestionChoices(ruleIndex, questionIndex).map((el) => (
                <Select.Option key={el} value={el}>
                  {el}
                </Select.Option>
              ))}
            </Select>
          </Col>
          {getQuestionAttribute(ruleIndex, questionIndex, "label") && (
            <>
              <Col xs={24} sm={24} md={3}>
                <Select
                  className="logic-dropdown"
                  size="large"
                  style={dropdownStyle}
                  value="Is from"
                >
                  <Select.Option value="Is from">Is from</Select.Option>
                </Select>
              </Col>
              <Col xs={24} sm={24} md={2}>
                <Select
                  className="logic-dropdown"
                  size="large"
                  placeholder="From"
                  style={dropdownStyle}
                  value={getQuestionAttribute(ruleIndex, questionIndex, "from")}
                  onChange={(value) =>
                    setQuestionAttribute(
                      ruleIndex,
                      questionIndex,
                      "from",
                      value
                    )
                  }
                >
                  {range.map((el) => (
                    <Select.Option key={el} value={el}>
                      {el}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </>
          )}
          {isNumber(getQuestionAttribute(ruleIndex, questionIndex, "from")) && (
            <>
              <Col xs={24} sm={24} md={3}>
                <Select
                  className="logic-dropdown"
                  size="large"
                  style={dropdownStyle}
                  value="To"
                >
                  <Select.Option value="To">To</Select.Option>
                </Select>
              </Col>
              <Col xs={24} sm={24} md={2}>
                <Select
                  className="logic-dropdown"
                  size="large"
                  placeholder="To"
                  style={dropdownStyle}
                  value={getQuestionAttribute(ruleIndex, questionIndex, "to")}
                  onChange={(value) =>
                    setQuestionAttribute(ruleIndex, questionIndex, "to", value)
                  }
                >
                  {range
                    .filter(
                      (x) =>
                        x >=
                        getQuestionAttribute(ruleIndex, questionIndex, "from")
                    )
                    .map((el) => (
                      <Select.Option key={el} value={el}>
                        {el}
                      </Select.Option>
                    ))}
                </Select>
              </Col>
            </>
          )}
        </>
      );
    }
    return;
  };

  const filterActionQuestions = (idx, ruleIndex, actionIndex) => {
    const actionName = getActionAttribute(ruleIndex, actionIndex, "name");
    const maxIndex = getMaxQuestionIndex(ruleIndex);

    switch (actionName) {
      case branchingActions.SKIP_TO_QUESTION: {
        return idx > maxIndex;
      }
      case branchingActions.END_SURVEY: {
        return;
      }
      case branchingActions.DISQUALIFY_RESPONDENT: {
        return;
      }
      case branchingActions.HIDE_QUESTION: {
        return idx > maxIndex;
      }
      case branchingActions.INVALIDATE_QUESTION: {
        return true;
      }
      default:
        return;
    }
  };

  const getQuestionNumber = (id) => {
    return questions.findIndex((el) => el.id === id);
  };

  const getQuestionType = (index) => {
    return questions[index]?.type;
  };

  const saveBranchingLogic = () => {
    if (errors.length !== 0) {
      notify(
        "error",
        "Error",
        "You have errors in the logic. Please fix them first."
      );
      return false;
    }
    onSaveRules(rules);
    notify("success", "Success", "Branching logic saved.");
    return true;
  };

  const handleBackNavigation = () => {
    const areRulesChanged =
      JSON.stringify(rules) !== JSON.stringify(oldBranchingRules);
    if (areRulesChanged) {
      Modal.confirm({
        title: "You have changed the rules without saving.",
        okText: "Exit & Save",
        onOk() {
          if (saveBranchingLogic()) {
            closeModal();
          }
        },
        cancelText: "Exit without Saving",
        onCancel: closeModal,
        maskClosable: true,
      });
      return;
    }
    closeModal();
  };

  return (
    <div style={{ backgroundColor: "#f5f5f5", minHeight: "100vh" }}>
      <RemoveScrollBar />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          backgroundColor: "#10213c",
          padding: "16px",
          position: "sticky",
          top: "0px",
          color: "white",
          alignItems: "center",
          zIndex: 100,
        }}
      >
        <Button
          size={isTabletOrMobile ? "small" : "middle"}
          type=""
          onClick={handleBackNavigation}
          style={{
            backgroundColor: "#0C4675",
            color: "white",
            borderColor: "#0C4675",
            height: "36px",
            borderRadius: "4px",
            textTransform: "uppercase",
          }}
        >
          <LeftOutlined /> {!isTabletOrMobile && "Back to Survey"}
        </Button>
        <h2 style={{ margin: "0px", color: "inherit" }}>Branching Logic</h2>
        <Button
          size={isTabletOrMobile ? "small" : "middle"}
          style={{
            backgroundColor: "#f19984",
            color: "white",
            borderRadius: 4,
            borderColor: "#f19984",
            height: "36px",
            textTransform: "uppercase",
          }}
          onClick={saveBranchingLogic}
        >
          {isTabletOrMobile ? <SaveOutlined /> : "SAVE BRANCHING LOGIC"}
        </Button>
      </div>
      <div
        className="branching-logic-container"
        style={{
          padding: isTabletOrMobile ? "32px 12px" : "32px",
        }}
      >
        {rules.map((ruleObject, ruleIndex) => (
          <Fragment key={`frag-${ruleIndex}`}>
            <div
              key={ruleIndex}
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.15) 0px 0px 2px, rgba(0, 0, 0, 0.15) 0px 0px 2px",
                padding: 20,
                backgroundColor: "white",
                marginBottom: 20,
                borderRadius: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "16px",
                  flexWrap: "wrap",
                }}
              >
                <h3
                  style={{
                    margin: 0,
                    padding: "4px 20px",
                    backgroundColor: "#eeeeee",
                    borderRadius: 16,
                    fontSize: isTabletOrMobile ? 12 : 16,
                    height: 32,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  RULE {ruleIndex + 1}
                </h3>
                <div>
                  <Button
                    style={{
                      backgroundColor: "#e6f2fc",
                      color: "#5682b6",
                      marginRight: isTabletOrMobile ? 10 : 20,
                      textTransform: "uppercase",
                      height: 32,
                      border: "none",
                      borderRadius: 16,
                      width: isTabletOrMobile ? "auto" : 120,
                      fontSize: isTabletOrMobile ? 10 : 14,
                    }}
                    onClick={saveBranchingLogic}
                  >
                    SAVE RULE
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#f7cfd2",
                      color: "#994a47",
                      textTransform: "uppercase",
                      border: "none",
                      height: 32,
                      borderRadius: 16,
                      width: isTabletOrMobile ? "auto" : 120,
                      fontSize: isTabletOrMobile ? 10 : 14,
                    }}
                    onClick={() => removeRule(ruleIndex)}
                  >
                    Delete Rule
                  </Button>
                </div>
              </div>
              <Row gutter={[50, 24]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={14}>
                  {/* QUESTIONS */}
                  <div style={{ marginBottom: 16 }}>
                    <b>CONDITIONS (IF ANSWER TO)</b>
                  </div>
                  {ruleObject.questions.map((_, questionIndex) => (
                    <>
                      <Row
                        key={questionIndex}
                        gutter={[10, 10]}
                        style={{ marginBottom: 10 }}
                        align="middle"
                      >
                        <Col xs={24} sm={24} md={1}>
                          <div
                            style={{
                              backgroundColor: "#eeeeee",
                              height: 28,
                              width: 28,
                              borderRadius: 4,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {questionIndex + 1}
                          </div>
                        </Col>
                        {/* INDEX */}
                        <Col xs={24} sm={24} md={8}>
                          <Select
                            className="logic-dropdown"
                            size="large"
                            placeholder="Select Criteria"
                            style={dropdownStyle}
                            value={getQuestionAttribute(
                              ruleIndex,
                              questionIndex,
                              "index"
                            )}
                            onChange={(value) => {
                              setQuestionAttribute(
                                ruleIndex,
                                questionIndex,
                                "index",
                                value
                              );
                              setQuestionAttribute(
                                ruleIndex,
                                questionIndex,
                                "choices",
                                []
                              );
                              setQuestionAttribute(
                                ruleIndex,
                                questionIndex,
                                "condition",
                                null
                              );
                              setQuestionAttribute(
                                ruleIndex,
                                questionIndex,
                                "label",
                                null
                              );

                              if (
                                QUESTION_TYPE_TWO.includes(
                                  getQuestionType(value)
                                )
                              ) {
                                setQuestionAttribute(
                                  ruleIndex,
                                  questionIndex,
                                  "condition",
                                  branchingConditions.IS_FROM_TO
                                );
                              }
                              if (
                                QUESTION_TYPE_THREE.includes(
                                  getQuestionType(value)
                                )
                              ) {
                                setQuestionAttribute(
                                  ruleIndex,
                                  questionIndex,
                                  "condition",
                                  branchingConditions.RANKING
                                );
                              }
                              if (
                                QUESTION_TYPE_FOUR.includes(
                                  getQuestionType(value)
                                )
                              ) {
                                setQuestionAttribute(
                                  ruleIndex,
                                  questionIndex,
                                  "condition",
                                  branchingConditions.ASSOCIATION
                                );
                              }
                              if (
                                QUESTION_TYPE_FIVE.includes(
                                  getQuestionType(value)
                                )
                              ) {
                                setQuestionAttribute(
                                  ruleIndex,
                                  questionIndex,
                                  "condition",
                                  branchingConditions.IMAGE_VIDEO_RATINGS
                                );
                              }
                            }}
                          >
                            {allowedQuestions.map((el, idx) => (
                              <Select.Option
                                key={idx}
                                value={getQuestionNumber(el.id)}
                              >{`Q${getQuestionNumber(el.id) + 1}. ${
                                el.text
                              }`}</Select.Option>
                            ))}
                          </Select>
                        </Col>
                        {/* CONDITION & OPTIONS */}
                        {renderConditionsAndOptions(ruleIndex, questionIndex)}
                        {/* DELETE BTN */}
                        <Col xs={24} sm={24} md={1}>
                          <DeleteOutlined
                            style={{
                              float: "right",
                              fontSize: 18,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              deleteQuestion(ruleIndex, questionIndex)
                            }
                          />
                        </Col>
                      </Row>
                      {/* LOGICAL OPERATOR */}
                      {questionIndex !== ruleObject.questions.length - 1 && (
                        <Button
                          style={{
                            marginBottom: 20,
                            backgroundColor: "#8fd9d1",
                            border: "none",
                            height: 28,
                            borderRadius: 14,
                            fontSize: 12,
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() =>
                            handleChangeLogicalOperator(
                              ruleIndex,
                              questionIndex
                            )
                          }
                        >
                          {isLogicalOrOperator(ruleIndex, questionIndex)
                            ? "OR"
                            : "AND"}{" "}
                          <SwapOutlined
                            style={{
                              fontSize: 14,
                            }}
                          />
                        </Button>
                      )}
                    </>
                  ))}
                  <Button
                    onClick={() => addQuestion(ruleIndex)}
                    style={{
                      backgroundColor: "#10213c",
                      height: "28px",
                      fontSize: 12,
                      color: "white",
                      borderRadius: "4px",
                    }}
                  >
                    ADD CONDITION
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                  {/* ACTIONS */}
                  <div style={{ marginBottom: 16 }}>
                    <b>ACTIONS (THEN)</b>
                  </div>
                  {ruleObject.actions.map((_, actionIndex) => (
                    <React.Fragment key={actionIndex}>
                      <Row
                        gutter={[10, 10]}
                        style={{ marginBottom: 10 }}
                        align="middle"
                      >
                        <Col xs={24} sm={2}>
                          <div
                            style={{
                              backgroundColor: "#eeeeee",
                              height: 28,
                              width: 28,
                              borderRadius: 4,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {actionIndex + 1}
                          </div>
                        </Col>
                        {/* NAME */}
                        <Col xs={24} sm={10}>
                          <Select
                            className="logic-dropdown"
                            size="large"
                            placeholder="Select Action"
                            style={dropdownStyle}
                            value={getActionAttribute(
                              ruleIndex,
                              actionIndex,
                              "name"
                            )}
                            onChange={(value) => {
                              setActionAttribute(
                                ruleIndex,
                                actionIndex,
                                "name",
                                value
                              );
                              setActionAttribute(
                                ruleIndex,
                                actionIndex,
                                "question",
                                null
                              );
                            }}
                          >
                            {Object.keys(branchingActions).map((el) => (
                              <Select.Option
                                key={el}
                                value={branchingActions[el]}
                              >
                                {branchingActions[el]}
                              </Select.Option>
                            ))}
                          </Select>
                        </Col>
                        {/* QUESTIONS */}
                        <Col xs={24} sm={10}>
                          {showSelectQuestion(ruleIndex, actionIndex) && (
                            <Select
                              className="logic-dropdown"
                              size="large"
                              placeholder="Select Question"
                              style={dropdownStyle}
                              value={getActionAttribute(
                                ruleIndex,
                                actionIndex,
                                "question"
                              )}
                              onChange={(value) =>
                                setActionAttribute(
                                  ruleIndex,
                                  actionIndex,
                                  "question",
                                  value
                                )
                              }
                            >
                              {questions
                                .filter((_, idx) =>
                                  filterActionQuestions(
                                    idx,
                                    ruleIndex,
                                    actionIndex
                                  )
                                )
                                .map((el, idx) => (
                                  <Select.Option
                                    key={idx}
                                    value={getQuestionNumber(el.id)}
                                  >{`Q${getQuestionNumber(el.id) + 1}. ${
                                    el.text
                                  }`}</Select.Option>
                                ))}
                            </Select>
                          )}
                        </Col>
                        {/* DELETE BTN */}
                        <Col xs={24} sm={2}>
                          <DeleteOutlined
                            style={{
                              float: "right",
                              fontSize: 18,
                              cursor: "pointer",
                            }}
                            onClick={() => deleteAction(ruleIndex, actionIndex)}
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  ))}
                  <Button
                    onClick={() => addAction(ruleIndex)}
                    style={{
                      backgroundColor: "#10213c",
                      height: "28px",
                      fontSize: 12,
                      color: "white",
                      borderRadius: "4px",
                    }}
                  >
                    ADD ACTION
                  </Button>
                </Col>
              </Row>
            </div>
            <Button
              onClick={() => addRule(ruleIndex)}
              style={{
                backgroundColor: "#10213c",
                height: "42px",
                display: "block",
                margin: "20px 0",
                color: "white",
                borderRadius: "4px",
              }}
            >
              ADD NEW RULE
            </Button>
          </Fragment>
        ))}
        {rules.length === 0 && (
          <Button
            onClick={addRule}
            style={{
              backgroundColor: "#10213c",
              height: "42px",
              display: "block",
              margin: "20px 0",
              color: "white",
              borderRadius: "4px",
            }}
          >
            ADD NEW RULE
          </Button>
        )}

        {/* <div style={{ textAlign: "center", marginTop: 20 }}>
          <Button
            size="large"
            style={{
              backgroundColor: "#BEC8C8",
              color: "black",
              borderRadius: 20,
              marginRight: 10,
              marginBottom: 10,
            }}
            onClick={handleBackNavigation}
          >
            Back to Survey
          </Button>
          <Button
            size="large"
            style={{
              backgroundColor: "#71CFD7",
              color: "white",
              borderRadius: 20,
            }}
            onClick={saveBranchingLogic}
          >
            SAVE BRANCHING LOGIC
          </Button>
        </div> */}
        <Errors errors={errors} />
      </div>
    </div>
  );
};

export default BranchingLogic;
