import React, { useEffect, useState } from "react";
import styles from "./survey.module.css";
import c from "classnames/bind";
import {
  LeftOutlined,
  LogoutOutlined,
  RightOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import logo from "../../../../assets/logo.png";
import { Button, message, Card, Modal as AntdModal } from "antd";
import Modal from "react-modal";
import { useMediaQuery } from "react-responsive";
import {
  isTypeRequired,
  isAnswerEmpty,
  getQuestionText,
} from "../../utils/questionUtils";
import { useVideoRef } from "../../hooks/qualSurvey/useVideoRef";
import { surveyTypes } from "../../constants/constants";
import { notify } from "../../../../utils";
import { getComponentByQuestionType } from "./questions/Index";
import { getRange } from "../../utils/utils";
import {
  branchingActions,
  branchingConditions,
} from "../../constants/branching";
import { getRoleByToken } from "../../../../utils";

const modalStyles = {
  content: {
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    border: 0,
    padding: 0,
    borderRadius: 0,
  },
};

const cx = c.bind(styles);

let answers = [];
let hiddenQuestions = [];

const Survey = ({
  surveyType,
  surveyAccess,
  questions = [],
  rules = [],
  isOpen,
  onClose,
  onEndSurvey = () => {},
  onDisqualification = () => {},
  tryOwnForm = false,
  brandName = "",
  brandLogo = "",
  surveyInstructions = "",
  showPermDeniedPage = false
}) => {
  // Hooks
  const TopBarResponsive = useMediaQuery({ query: "(max-width: 960px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 800px)" });
  const [step, setStep] = useState(0);
  const [isDisqualified, setIsDisqualified] = useState(false);
  const [videoRef, getVideo, stopVideo] = useVideoRef();
  const [tempAnswers, setTempAnswers] = useState([]);
  const [showThanksPage, setShowThanksPage] = useState(false);
  const [role, setRole] = useState(
    getRoleByToken(localStorage.getItem("token"))
  );
  // eslint-disable-next-line
  useEffect(() => {
    if (role !== getRoleByToken(localStorage.getItem("token"))) {
      setRole(getRoleByToken(localStorage.getItem("token")));
    }
  });

  useEffect(() => {
    setTempAnswers(answers);
    console.log("updatedAnswerS", answers);
  }, [answers]);

  useEffect(() => {
    if (!(surveyType === surveyTypes.QUANTITATIVE) && !tryOwnForm && isOpen) {
      getVideo();
    }
  }, [videoRef]);

  useEffect(() => {
    // Populate answers with empty array
    [...Array(questions.length).keys()].map((i) => (answers[i] = []));
  }, []);

  // Derived state
  const index = step - 1;
  const totalQuestions = questions.length;
  const endSurveySlideNumber = questions.length + 1;
  const isQuestionHidden = hiddenQuestions.includes(index);
  const verifyRuleConditions = (conditions) => {
    let result = "true&&";

    for (const [key, item] of Object.entries(conditions)) {
      const lastElement = key == conditions.length - 1;
      const op = lastElement ? "" : item.logicalOr ? "||" : "&&";

      switch (item.condition) {
        case branchingConditions.CONTAINS: {
          const cond = answers[item.index].includes(item.choices[0]);
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.DOES_NOT_CONTAIN: {
          const cond = !answers[item.index].includes(item.choices[0]);
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.CONTAINS_ONE_OF: {
          const cond = answers[item.index].some((x) =>
            item.choices.includes(x)
          );
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.DOES_NOT_CONTAIN_ANY_OF: {
          const cond = !item.choices.every((x) =>
            answers[item.index].includes(x)
          );
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.CONTAINS_ALL_OF: {
          const cond = item.choices.every((x) =>
            answers[item.index].includes(x)
          );
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.DOES_NOT_CONTAIN_ALL_OF: {
          const cond = !item.choices.some((x) =>
            answers[item.index].includes(x)
          );
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.IS_EXACTLY: {
          const cond =
            JSON.stringify(item.choices) ===
            JSON.stringify(answers[item.index]);
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.IS_NOT: {
          const cond =
            JSON.stringify(item.choices) !==
            JSON.stringify(answers[item.index]);
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.IS_FROM_TO: {
          const cond =
            item.from <= answers[item.index][0] &&
            answers[item.index][0] <= item.to;
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.RANKING: {
          const { type } = questions[item.index];
          let cond = false;
          if (answers[item.index].length !== 0) {
            if (type === "ranking") {
              cond = answers[item.index][item.rank - 1].label === item.label;
            } else if (type === "imageRanking") {
              cond =
                answers[item.index][item.rank - 1].imageLabel === item.label;
            } else if (type === "videoRanking") {
              cond =
                answers[item.index][item.rank - 1].videoLabel === item.label;
            }
          }
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.ASSOCIATION: {
          const { type } = questions[item.index];
          let cond = false;
          answers[item.index].length !== 0 &&
            answers[item.index].forEach((x, idx) => {
              if (
                type === "association" &&
                x.label === item.label &&
                x.association === item.association
              ) {
                cond = true;
                return;
              }

              if (type === "imageAssociation" || type === "videoAssociation") {
                const num = parseInt(item.label.substring(6)) - 1;
                if (num == idx && x.association === item.association) {
                  cond = true;
                  return;
                }
              }
            });
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        case branchingConditions.IMAGE_VIDEO_RATINGS: {
          const ans =
            answers[item.index].length !== 0 &&
            answers[item.index][0][item.label];
          const cond = item.from <= ans && ans <= item.to;
          result += cond.toString();
          result = eval(result).toString() + op;
          break;
        }

        default:
          break;
      }
    }

    return result === "true";
  };

  const executeRuleActions = (actions) => {
    // Set step always one less than the expected step.
    for (const item of actions) {
      switch (item.name) {
        // skip -> 9
        // current -> 2
        // hide -> 3, 4, 5, 6, 7, 8
        case branchingActions.SKIP_TO_QUESTION: {
          const range = getRange(index + 1, item.question - 1);
          hiddenQuestions = hiddenQuestions.concat(range);
          break;
        }

        case branchingActions.END_SURVEY: {
          setStep(questions.length);
          const range = getRange(index + 1, questions.length - 1);
          hiddenQuestions = hiddenQuestions.concat(range);
          break;
        }

        case branchingActions.DISQUALIFY_RESPONDENT: {
          setStep(questions.length);
          setIsDisqualified(true);
          const range = getRange(index + 1, questions.length - 1);
          hiddenQuestions = hiddenQuestions.concat(range);
          break;
        }

        case branchingActions.HIDE_QUESTION: {
          hiddenQuestions = hiddenQuestions.concat([item.question]);
          break;
        }

        case branchingActions.INVALIDATE_QUESTION: {
          answers[item.question] = [];
          if (item.question > index) {
            hiddenQuestions = hiddenQuestions.concat([item.question]);
          }
          break;
        }

        default:
          break;
      }
    }
  };

  const performBranchingLogic = () => {
    let results = [];
    for (const rl of rules) {
      const maxIndex = rl.questions.reduce(
        (prev, curr) => Math.max(prev, curr.index),
        0
      );
      if (maxIndex === index) {
        results.push({
          verified: verifyRuleConditions(rl.questions),
          actions: rl.actions,
        });
      }
    }

    // false conditions must be executed first
    results.sort((a, b) => {
      if (Number(a.verified) < Number(b.verified)) {
        return -1;
      }
      if (Number(a.verified) > Number(b.verified)) {
        return 1;
      }
      return 0;
    });

    // execute actions
    for (let x of results) {
      if (x.verified) {
        executeRuleActions(x.actions);
        break;
      } else {
        x.actions.forEach((el) => {
          if (
            el.name === branchingActions.HIDE_QUESTION ||
            branchingActions.INVALIDATE_QUESTION
          ) {
            hiddenQuestions = hiddenQuestions.filter(
              (val) => el.question !== val
            );
          }

          if (el.name === branchingActions.SKIP_TO_QUESTION) {
            const range = getRange(index + 1, el.question - 1);
            hiddenQuestions = hiddenQuestions.filter(
              (val) => !range.includes(val)
            );
          }

          if (
            el.name === branchingActions.END_SURVEY ||
            el.name === branchingActions.DISQUALIFY_RESPONDENT
          ) {
            const range = getRange(index + 1, questions.length - 1);
            hiddenQuestions = hiddenQuestions.filter(
              (val) => !range.includes(val)
            );
          }
        });
      }
    }
  };

  // Handlers
  const incrementStep = () => {
    if (
      step !== 0 &&
      !isQuestionHidden &&
      isTypeRequired(questions[index].type) &&
      isAnswerEmpty(questions[index].type, answers[index])
    ) {
      message.error("Please answer this question first!");
      return;
    }

    if (rules?.length !== 0) {
      performBranchingLogic();
    }

    setStep((prevStep) => {
      while (true) {
        if (hiddenQuestions.includes(prevStep++)) {
          continue;
        }
        return prevStep;
      }
    });
  };

  const decrementStep = () => {
    setStep((prevStep) => {
      while (true) {
        if (hiddenQuestions.includes(--prevStep - 1)) {
          continue;
        }
        return prevStep;
      }
    });
  };

  const onChangeAnswer = (values) => {
    answers[index] = Array.isArray(values) ? values : [values];
    console.log("newAnswers", answers);
  };

  const updateAnswer = (answer) => {
    answers[index] = answer;
    console.log("updatedAnswer", answer);
  };

  const endSurvey = async () => {
    if (!(surveyType === surveyTypes.QUANTITATIVE) && !tryOwnForm && isOpen) {
      stopVideo()
        }
    if (isDisqualified) {
      notify(
        "info",
        "Info",
        "You have been disqualifed. Survey is now removed."
      );
      onDisqualification();
    } else {
      console.log("final answers array:", answers);
      onEndSurvey([...answers]);
    }
    if (!surveyAccess) resetModal();
    else showThankYouPage();
  };

  const resetModal = () => {
    answers = [];
    hiddenQuestions = [];
    setStep(0);
  };

  const showThankYouPage = () => {
    setStep(endSurveySlideNumber);
    setShowThanksPage(true);
  };

  const onCloseModal = () => {
    resetModal();
    onClose();
  };

  const shouldRenderOptions = () => {
    if (step === 0 || step === endSurveySlideNumber) {
      return false;
    }

    if (
      step > 0 &&
      questions[index] &&
      questions[index].type === "questionInstruction"
    ) {
      return false;
    }

    return true;
  };

  console.log("questions12345", answers);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        onCloseModal();
      }}
      style={modalStyles}
    >
      <RemoveScrollBar />
      <div className={cx("survey-container")}>
        {TopBarResponsive ? (
          <div className={cx("mobile_topbar")}>
            <div className={cx("mobile_logo")}>
              <img
                src={brandLogo ? brandLogo : logo}
                style={
                  brandLogo
                    ? { maxHeight: 200, maxWidth: 200, objectFit: "contain" }
                    : { height: 48 }
                }
              />
            </div>
            {(!surveyAccess || role === "user") &&
              window.location.pathname.includes("/user") && (
                <div>
                  {isTablet ? (
                    <LogoutOutlined
                      style={{ fontSize: 24, cursor: "pointer" }}
                      onClick={() => {
                        onCloseModal();
                      }}
                    />
                  ) : (
                    <div
                      onClick={() => {
                        onCloseModal();
                      }}
                      className={cx("mobile_quit-button")}
                      title="Quit Survey"
                    >
                      QUIT SURVEY <LogoutOutlined />
                    </div>
                  )}
                </div>
              )}
          </div>
        ) : (
          <>
            <div className={cx("logo")}>
              <img
                src={brandLogo ? brandLogo : logo}
                style={
                  brandLogo
                    ? { maxHeight: 200, maxWidth: 200, objectFit: "contain" }
                    : { height: 48 }
                }
              />
            </div>
            {(!surveyAccess || role === "user") &&
              window.location.pathname.includes("/user") && (
                <div>
                  <div
                    onClick={() => {
                      onCloseModal();
                    }}
                    className={cx("quit-button")}
                    title="Quit Survey"
                  >
                    QUIT SURVEY <LogoutOutlined />
                  </div>
                </div>
              )}
          </>
        )}
        <div
          className={cx("center", {
            end_center: step === endSurveySlideNumber,
          })}
        >
          {/* QUESTION */}
          <div className={cx("question-box")}>
            {step !== 0 && step !== endSurveySlideNumber && (
              <div
                style={{ fontSize: 24, marginRight: 18, fontWeight: "bold" }}
              >
                {step}
              </div>
            )}
            <div
              style={{
                flexGrow: 1,
                display:
                  step === 0 || step === endSurveySlideNumber
                    ? "block"
                    : "flex",
                alignItems: "center",
                fontSize: 16,
              }}
            >
              {step == 0 && (
                <>
                  <p
                    style={{
                      fontSize: 20,
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    Welcome to {brandName ? brandName : "UserQual"}!
                  </p>
                  {!!surveyInstructions && (
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      {surveyInstructions}
                    </p>
                  )}
                  <p style={{ margin: 0, textAlign: "center" }}>
                    You can start the survey by clicking the NEXT Button.
                  </p>
                </>
              )}
              {step !== 0 && step !== endSurveySlideNumber && questions[index] && (
                <div style={{ width: "100%" }}>
                  <div>{getQuestionText(questions[index])}</div>
                  {questions[index].hasLink ? (
                    <Card bordered={true} className={cx("linkCard")}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "16px",
                            padding: "10px",
                            margin: "auto 0",
                          }}
                        >
                          <LinkOutlined
                            style={{ margin: "auto", paddingLeft: "15px" }}
                          />

                          {questions[index].clientLink}
                        </div>
                        <Button
                          onClick={() =>
                            window.open(
                              "https://" + questions[index].clientLink,
                              "_blank"
                            )
                          }
                          className={cx("linkButton")}
                        >
                          OPEN
                        </Button>
                      </div>
                    </Card>
                  ) : null}
                </div>
              )}
              {/* {(step === endSurveySlideNumber && !showThanksPage) ?  :} */}
              <div>
                {(() => {
                  switch (true) {
                    case step === endSurveySlideNumber && !showThanksPage:
                      {
                        return (
                          <div style={{ textAlign: "center" }}>
                            {/* <CheckCircleFilled style={{ fontSize: 36 }} /> <br /><br /> */}
                            <span>
                              Please click on End Survey to store your results.
                            </span>{" "}
                            <br />
                            <br />
                            <Button
                              type="primary"
                              shape="round"
                              size="large"
                              onClick={endSurvey}
                              style={{
                                backgroundColor: "#FF9480",
                                color: "white",
                                border: "#FF9480",
                              }}
                            >
                              End Survey
                            </Button>
                          </div>
                        );
                      }
                      break;

                    case step === endSurveySlideNumber && showThanksPage && window.location.pathname.includes('attemptSurvey'):
                      {
                        return (
                          <div style={{ textAlign: "center" }}>
                            {/* <CheckCircleFilled style={{ fontSize: 36 }} /> <br /><br /> */}
                            <span>Thank you for filling the survey</span> <br />
                            <br />
                          </div>
                        );
                      }
                      break;
                  }
                })()}
              </div>
            </div>
          </div>
          {/* OPTIONS */}
          {shouldRenderOptions() && (
            <div className={cx("options-box")}>
              {questions[index] &&
                getComponentByQuestionType(
                  questions[index],
                  answers[index],
                  index,
                  onChangeAnswer,
                  updateAnswer
                )}
            </div>
          )}
        </div>
        <div>
          <video ref={videoRef} />
        </div>
        <div className={cx("bottom-bar")}>
          <div
            className={cx("bottom-wrapper")}
            style={{
              justifyContent: step === 0 ? "flex-end" : "space-between",
            }}
          >
            {step !== 0 && !showThanksPage && (
              <div
                onClick={decrementStep}
                className={cx("bottom-buttons")}
                title="Previous"
              >
                <LeftOutlined />
                {"  "} PREVIOUS
              </div>
            )}
            {step !== endSurveySlideNumber && (
              <div
                onClick={incrementStep}
                className={cx("bottom-buttons")}
                title="Next"
              >
                NEXT{"  "} <RightOutlined />
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Survey;
