import { createStore, applyMiddleware, compose } from "redux";
import { generateReducers } from "automate-redux";
import thunk from "redux-thunk";

// Initial state of redux
const initialState = {
  pendingRequests: 0,
  user: {
    profileQuestions: [],
  },
  admin: {},
  client: {
    surveys: [],
  },
};

// Generate reducers with the initial state and pass it to the redux store
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default createStore(
  generateReducers(initialState),
  composeEnhancers(applyMiddleware(thunk))
);
