import React, { Fragment, useState } from "react";
import "./imageABTest.css";
import { Input, Row, Col, Form, Checkbox, Tooltip } from "antd";
import {
  CheckOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { COLOR } from "../../../constants";
import CustomButton from "../../button/Button";

const ImageABTest = (props) => {
  // Props
  const {
    dragHandleProps,
    choiceNumber,
    questionType,
    field,
    isFilled,
    addChoice,
    removeChoice,
    totalChoices,
    isSumHundred,
  } = props;

  // Component states
  const [isChoiceNumberShown, setIsChoiceNumberShown] = useState(true);

  // console.log(isCorrect);

  return (
    <Row
      gutter={6}
      onMouseEnter={() => setIsChoiceNumberShown(false)}
      onMouseLeave={() => setIsChoiceNumberShown(true)}
    >
      <Col
        xs={2}
        sm={2}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: 24,
        }}
      >
        {isChoiceNumberShown ? (
          <span className="choice-numberCircle">{choiceNumber}</span>
        ) : (
          <MenuOutlined {...dragHandleProps} />
        )}
      </Col>
      <Col xs={6} style={{ flexGrow: 1 }}>
        <Form.Item
          name={[field.name, "imageLabel"]}
          key={[field.name, "imageLabel"]}
          rules={[
            {
              required: true,
              message: "Please enter the label!",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="Image Label"
            style={{ borderRadius: 3 }}
            suffix={
              isFilled ? (
                <CheckOutlined style={{ color: "green" }} />
              ) : (
                <Tooltip title="You need to fill this option!">
                  <InfoCircleOutlined style={{ color: "red" }} />
                </Tooltip>
              )
            }
            className="input-border"
          />
        </Form.Item>
      </Col>
      <Col xs={7} style={{ flexGrow: 1 }}>
        <Form.Item
          name={[field.name, "imageData"]}
          key={[field.name, "imageData"]}
          rules={[
            {
              required: true,
              message: "Please upload the image!",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="No Image Chosen"
            style={{ borderRadius: 3 }}
            suffix={
              isFilled ? (
                <CheckOutlined style={{ color: "green" }} />
              ) : (
                <Tooltip title="You need to fill this option!">
                  <InfoCircleOutlined style={{ color: "red" }} />
                </Tooltip>
              )
            }
            className="input-border"
          />
        </Form.Item>
      </Col>
      <Col xs={3} style={{ marginRight: 5 }}>
        <CustomButton style={{ width: "100px" }}>Upload File</CustomButton>
      </Col>
      <Col xs={3} style={{ flexGrow: 1 }}>
        <Form.Item
          name={[field.name, "percentage"]}
          key={[field.name, "percentage"]}
          rules={[
            {
              required: true,
              message: "Please enter the value!",
            },
          ]}
        >
          <Input
            size="large"
            style={{ borderRadius: 3 }}
            suffix={
              isSumHundred ? (
                <Fragment>
                  <label
                    style={{
                      fontSize: 16,
                      color: COLOR.darkBlue,
                      padding: "0px 5px",
                      fontWeight: "bold",
                    }}
                  >
                    %
                  </label>
                  <CheckOutlined style={{ color: "green" }} />
                </Fragment>
              ) : (
                <Tooltip title="You need to fill this option!">
                  <label
                    style={{
                      fontSize: 16,
                      color: COLOR.darkBlue,
                      padding: "0px 5px",
                      fontWeight: "bold",
                    }}
                  >
                    %
                  </label>
                  <InfoCircleOutlined style={{ color: "red" }} />
                </Tooltip>
              )
            }
            className="input-border"
          />
        </Form.Item>
      </Col>
      <Col
        xs={2}
        className={
          questionType === "checkboxes-special" ? "no-hover actions" : "actions"
        }
      >
        <PlusCircleOutlined
          onClick={() => addChoice(choiceNumber)}
          className="action-icon"
        />
        {totalChoices > 2 && (
          <MinusCircleOutlined
            className="action-icon"
            onClick={() => removeChoice(choiceNumber)}
          />
        )}
        {questionType === "checkboxes-special" && (
          <Checkbox className="special" />
        )}
      </Col>
    </Row>
  );
};

export default ImageABTest;
