import { Button, Col, Row, Radio, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import ProjectPageLayout from "../../../components/page-layout/PageLayout";
import Topbar from "../../../components/topbar/Topbar";
import Sidenav from "../../../components/admin/sidenav/Sidenav";
import "./pricing.css";
import ResponsiveTable from "../../../components/responsive-table/ResponsiveTable";
import PlansFeesModal from "../../../components/admin/pricing/plans-fees-modal/PlansFeesModal";
import ConfigPricingModal from "../../../components/admin/pricing/config-pricing-modal/ConfigPricingModal";
import { adminPages } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { editAdmin } from "../../../actions/admin";
import {
  decrementPendingRequests,
  incrementPendingRequests,
  notify,
} from "../../../utils";
// import { tierInfo } from "./priceSegment";
import { Select } from "antd";
import Dropdown from "../../../components/dropdown/dropdown";
import { questionNotation } from "../../../components/constants/questionType";
import QuestionPricingModal from "../../../components/admin/pricing/question-pricing-modal/QuestionPricingModal";
import OtherPricingModal from "../../../components/admin/pricing/other-pricing-modal/OtherPricingModal";

const { Option } = Select;

const Pricing = () => {
  const dispatch = useDispatch();
  const tierInfo = useSelector((state) => state.admin.tierInfo);
  const [data, setData] = useState([]);
  const [questionPricingData, setQuestionPricingData] = useState(null);
  const [otherData, setOtherData] = useState(null);
  const [plansFeesModal, setPlansFeesModal] = useState(false);
  const [questionFeesModal, setQuestionFeesModal] = useState(false);
  const [otherFeesModal, setOtherFeesModal] = useState(false);
  const [configPricingModal, setConfigPricingModal] = useState(false);
  const [selectedPricing, setSelectedPricing] = useState();
  const [selectedQuestionPricing, setSelectedQuestionPricing] = useState(null);
  const [selectedOtherPricing, setSelectedOtherPricing] = useState(null);
  const [surveyTimings, setSurveyTimings] = useState({
    15: "15 min",
    30: "30 min",
    45: "45 min",
  });

  const TIER_INFO = {
    0: "basic",
    1: "elite",
    2: "platinum",
  };

  const OTHER_INFO = {
    influencerAudience: "Influencer Audience",
    additionalCriteria: "Additional Criteria (more than the 6 basic ones)",
  };

  useEffect(() => {
    if (tierInfo) {
      // set question pricing
      let questionPricingData = [];
      Object.keys(tierInfo[0].pricing.additionalCost.questionType).map(
        (type, index) => {
          let obj = { questionName: questionNotation[type], type: type };
          tierInfo.map((tier, index) => {
            obj = {
              ...obj,
              [TIER_INFO[index]]:
                tier.pricing.additionalCost.questionType[type],
            };
          });
          questionPricingData.push(obj);
        }
      );
      setQuestionPricingData(questionPricingData);

      // setOtherData
      defineOtherData(tierInfo);

      const DEFAULT_TIME = Object.keys(surveyTimings)[0];
      let tableDataSource = [];
      while (tableDataSource.length <= 2) {
        let obj = {};
        obj.userType = "clientsUser";
        obj.time = DEFAULT_TIME;
        switch (tableDataSource.length) {
          case 0:
            obj.surveyType = "Quantitative";
            obj.basic = tierInfo[0].pricing.quantitative;
            obj.elite = tierInfo[1].pricing.quantitative;
            obj.platinum = tierInfo[2].pricing.quantitative;
            obj.time = null;
            tableDataSource.push(obj);
            break;

          case 1:
            obj.surveyType = "Qualitative/Moderated";
            obj.basic = tierInfo[0].pricing.qualitative.moderated;
            obj.elite = tierInfo[1].pricing.qualitative.moderated;
            obj.platinum = tierInfo[2].pricing.qualitative.moderated;
            tableDataSource.push(obj);
            break;

          case 2:
            obj.surveyType = "Qualitative/Unmoderated";
            obj.basic = tierInfo[0].pricing.qualitative.unModerated;
            obj.elite = tierInfo[1].pricing.qualitative.unModerated;
            obj.platinum = tierInfo[2].pricing.qualitative.unModerated;
            // obj.time = DEFAULT_TIME;
            tableDataSource.push(obj);
            break;

          default:
            break;
        }
      }
      setData(tableDataSource);
    }
  }, [tierInfo]);

  const defineOtherData = (tierInfo) => {
    let otherData = [];
    Object.keys(OTHER_INFO).map((key, index) => {
      let data = { name: OTHER_INFO[key], key: key };
      tierInfo.map((tier, index) => {
        data = {
          ...data,
          [TIER_INFO[index]]: tier.pricing.additionalCost[key],
        };
      });
      otherData.push(data);
    });
    setOtherData(otherData);
  };

  // Derived data
  const subscriptions = tierInfo
    ? tierInfo.map((val) => val.subscription)
    : [0, 0, 0];

  // Handlers
  const onChangeUserType = (userType, record) => {
    const newData = data.map((val) => {
      if (val.surveyType !== record.surveyType) return val;

      return { ...val, userType };
    });
    setData(newData);
  };

  const onTimingChange = (timing, record) => {
    const newData = data.map((val) => {
      if (val.surveyType !== record.surveyType) return val;

      return { ...val, time: timing };
    });
    setData(newData);
  };

  const handleEditPricing = (surveyType, values) => {
    const newTierInfo = tierInfo.map((tier, index) => {
      switch (surveyType) {
        case "Quantitative":
          return {
            ...tier,
            pricing: {
              ...tier.pricing,
              quantitative: values[index],
            },
          };
        case "Qualitative/Moderated":
          return {
            ...tier,
            pricing: {
              ...tier.pricing,
              qualitative: {
                ...tier.pricing.qualitative,
                moderated: values[index],
              },
            },
          };
        case "Qualitative/Unmoderated":
          return {
            ...tier,
            pricing: {
              ...tier.pricing,
              qualitative: {
                ...tier.pricing.qualitative,
                unModerated: values[index],
              },
            },
          };
        default:
          return {};
      }
    });
    incrementPendingRequests();
    dispatch(editAdmin(newTierInfo))
      .then(() => notify("success", "Success", "Pricing modified"))
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  };

  const handleEditPlansFees = (values) => {
    const newTierInfo = tierInfo.map((tier, index) => {
      return {
        ...tier,
        subscription: values[index],
      };
    });
    incrementPendingRequests();
    dispatch(editAdmin(newTierInfo))
      .then(() => notify("success", "Success", "Pricing modified"))
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  };

  const handleEditOtherPricing = (value) => {
    console.log("other values", value);
    const newTierInfo = tierInfo.map((tier, index) => {
      return {
        ...tier,
        pricing: {
          ...tier.pricing,
          additionalCost: {
            ...tier.pricing.additionalCost,
            [value.key]: value[TIER_INFO[index]],
          },
        },
      };
    });
    incrementPendingRequests();
    dispatch(editAdmin(newTierInfo))
      .then(() => notify("success", "Success", "Pricing modified"))
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  };

  const handleEditQuestionPlansFees = (questionValues) => {
    console.log("updated value", questionValues);
    const newTierInfo = tierInfo.map((tier, index) => {
      return {
        ...tier,
        pricing: {
          ...tier.pricing,
          additionalCost: {
            ...tier.pricing.additionalCost,
            questionType: {
              ...tier.pricing.additionalCost.questionType,
              [questionValues.type]: questionValues[TIER_INFO[index]],
            },
          },
        },
      };
    });

    incrementPendingRequests();
    dispatch(editAdmin(newTierInfo))
      .then(() => notify("success", "Success", "Pricing modified"))
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  };

  const otherColumns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Basic Plan - Price Per Question Type",
      dataIndex: "basic",
      align: "center",
    },
    {
      title: "Elite Plan - Price Per Question Type",
      dataIndex: "elite",
      align: "center",
    },
    {
      title: "Platinum Plan - Price Per Question Type",
      dataIndex: "platinum",
      align: "center",
    },
    {
      title: "",
      dataIndex: "edit",
      align: "center",
      render: (_, record) => (
        <Button
          onClick={() => {
            setOtherFeesModal(true);
            setSelectedOtherPricing(record);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  const questionColumns = [
    {
      title: "Question Type",
      dataIndex: "questionName",
      align: "center",
    },
    {
      title: "Basic Plan - Price Per Question Type",
      dataIndex: "basic",
      align: "center",
    },
    {
      title: "Elite Plan - Price Per Question Type",
      dataIndex: "elite",
      align: "center",
    },
    {
      title: "Platinum Plan - Price Per Question Type",
      dataIndex: "platinum",
      align: "center",
    },
    {
      title: "",
      dataIndex: "edit",
      align: "center",
      render: (_, record) => (
        <Button
          onClick={() => {
            setQuestionFeesModal(true);
            setSelectedQuestionPricing(record);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  const columns = [
    {
      title: "#",
      key: "no",
      align: "center",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Survey Type",
      align: "center",
      dataIndex: "surveyType",
      key: "survey_type",
    },
    {
      title: "Time Per Survey",
      dataIndex: "time",
      align: "center",
      key: "time",
      render: (text, record) => {
        console.log("record:", text, record);
        if (null === text) {
          return "N/A";
        }
        return (
          <Dropdown
            defaultValue={"15 min"}
            handleChange={(value) => onTimingChange(value, record)}
            options={Object.keys(surveyTimings).map((key, index) => {
              return {
                value: key,
                label: surveyTimings[key],
              };
            })}
          ></Dropdown>
        );
      },
    },
    {
      title: "Client Users / UQ Users",
      key: "users",
      align: "center",
      dataIndex: "userType",
      render: (text, record) => (
        <Radio.Group
          buttonStyle="solid"
          defaultValue={text}
          onChange={(e) => onChangeUserType(e.target.value, record)}
        >
          <Radio.Button value="clientsUser">Client</Radio.Button>
          <Radio.Button value="UQUser">UQ</Radio.Button>
        </Radio.Group>
      ),
    },
    {
      title: "Basic Plan - Price Per User",
      align: "center",
      key: "basic",
      render: (text, record) =>
        record.time === null
          ? record.basic[record.userType]
          : record.basic[record.time][record.userType],
    },
    {
      title: "Elite Plan - Price Per User",
      align: "center",
      key: "elite",
      render: (text, record) =>
        record.time === null
          ? record.elite[record.userType]
          : record.elite[record.time][record.userType],
    },
    {
      title: "Platinum Plan",
      align: "center",
      key: "platinum",
      render: (text, record) =>
        record.time === null
          ? record.platinum[record.userType]
          : record.platinum[record.time][record.userType],
    },
    {
      key: "edit",
      align: "center",
      render: (_, record) => (
        <Button
          onClick={() => {
            setConfigPricingModal(true);
            setSelectedPricing(record);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Topbar>
        <Sidenav drawer selectedItem={adminPages.PRICING} />
      </Topbar>
      <Sidenav selectedItem={adminPages.PRICING} />
      <ProjectPageLayout>
        <div style={{ padding: 5 }}>
          <div className="admin-page-container">
            <Row
              gutter={[6, 6]}
              justify="space-between"
              style={{ marginBottom: 10 }}
            >
              <Col>
                Basic Monthly Plan{" "}
                <InputNumber
                  value={subscriptions[0]}
                  className="input-border"
                />
              </Col>
              <Col>
                Elite Monthly Plan{" "}
                <InputNumber
                  value={subscriptions[1]}
                  className="input-border"
                />
              </Col>
              <Col>
                Platinum Monthly Plan{" "}
                <InputNumber
                  value={subscriptions[2]}
                  className="input-border"
                />
              </Col>
              <Button onClick={() => setPlansFeesModal(true)} type="primary">
                Edit
              </Button>
            </Row>
            {data && columns && (
              <ResponsiveTable
                className="pricing"
                columns={columns}
                dataSource={data}
              />
            )}
            {questionColumns && questionPricingData && (
              <div style={{ padding: "10px 0" }}>
                <ResponsiveTable
                  className="pricing"
                  columns={questionColumns}
                  dataSource={questionPricingData}
                />
              </div>
            )}
            {otherColumns && otherData && (
              <div style={{ padding: "10px 0" }}>
                <ResponsiveTable
                  className="pricing"
                  columns={otherColumns}
                  dataSource={otherData}
                />
              </div>
            )}
          </div>
        </div>
      </ProjectPageLayout>
      {plansFeesModal && (
        <PlansFeesModal
          plansFees={{
            basic: subscriptions[0],
            elite: subscriptions[1],
            platinum: subscriptions[2],
          }}
          handleEditPlansFees={handleEditPlansFees}
          handleCancel={() => setPlansFeesModal(false)}
        />
      )}
      {configPricingModal && (
        <ConfigPricingModal
          selectedPricing={selectedPricing}
          handleEditPricing={handleEditPricing}
          handleCancel={() => setConfigPricingModal(false)}
        />
      )}
      {questionFeesModal && (
        <QuestionPricingModal
          selectedQuestionPricing={selectedQuestionPricing}
          handleCancel={() => setQuestionFeesModal(false)}
          handleEditQuestionPricing={(questionInfo) =>
            handleEditQuestionPlansFees(questionInfo)
          }
        />
      )}
      {otherFeesModal && (
        <OtherPricingModal
          selectedOtherPricing={selectedOtherPricing}
          handleCancel={() => setOtherFeesModal(false)}
          handleEditOtherPricing={(otherValues) =>
            handleEditOtherPricing(otherValues)
          }
        />
      )}
    </React.Fragment>
  );
};

export default Pricing;
