import { MenuOutlined } from "@ant-design/icons";
import React from "react";
import "./image-association.css";
import { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Thumbnail from "../image-thumbnail/ImageThumbnail";
import { reorder } from "../../../../../utils";

const ListItem = ({ dragHandleProps, title }) => {
  return (
    <div
      style={{
        background: "#0b385f",
        textAlign: "left",
        padding: 10,
        height: "100%",
        wordBreak: "break-word",
        borderRadius: 5,
        display: "flex",
        alignItems: "center",
      }}
    >
      <div style={{ marginRight: 18 }}>
        <MenuOutlined {...dragHandleProps} />
      </div>
      <div style={{ flexGrow: 1 }}>{title}</div>
    </div>
  );
};

const ImageAssociation = ({ key, onChange = () => {}, value }) => {
  const [list, setList] = useState(value.map((val) => val.association));

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(list, result.source.index, result.destination.index);

    let arr = [];
    value.forEach((key, index) => {
      arr.push({
        imageData: key.imageData,
        association: items[index],
      });
    });

    setList(items);
    onChange(arr);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    height: "100%",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  return (
    <div key={key}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="association">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {value.map((val, index) => (
                <div key={index} className="img-association-box">
                  <Thumbnail
                    imgUrl={val.imageData}
                    noBorder
                    style={{ margin: "auto 10px auto 0px" }}
                  />
                  <div style={{ flexGrow: 1 }}>
                    <Draggable
                      draggableId={index.toString()}
                      key={index.toString()}
                      index={index}
                      style={{ height: "100%" }}
                    >
                      {(provided, snapshot) => (
                        <div
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <ListItem
                            title={list[index]}
                            dragHandleProps={provided.dragHandleProps}
                          />
                        </div>
                      )}
                    </Draggable>
                  </div>
                </div>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default ImageAssociation;
