import React, { useState } from "react";
import "./topbar.css";
import { Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";

const Topbar = (props) => {
  // component states
  const [drawer, setDrawer] = useState(false);

  return (
    <React.Fragment>
      <div className="topbar">
        <Button
          className="hamburger"
          style={{ marginBottom: 0, zIndex: 1 }}
          onClick={() => setDrawer(true)}
        >
          <MenuOutlined /> MENU
        </Button>
      </div>
      {drawer && (
        <Drawer
          className="menu-drawer"
          placement="left"
          closable={false}
          onClose={() => setDrawer(false)}
          visible={drawer}
          width={135}
        >
          <div style={{ backgroundColor: "#004479", height: "100%" }}>
            {props.children}
          </div>
        </Drawer>
      )}
    </React.Fragment>
  );
};

export default Topbar;
