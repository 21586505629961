import React from "react";
import styles from "./likert.module.css";
import c from "classnames/bind";
import { CheckOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Select, Input, Tooltip } from "antd";

const { Option } = Select;
const cx = c.bind(styles);

const Cirle = () => {
  return <div className={cx("circle")} />;
};

const Likert = ({ options, setOptions }) => {
  return (
    <div
      className={cx("grid-container")}
      style={{
        paddingTop: 10,
      }}
    >
      <div className={cx("bar")}>
        <div className={cx("likert")}>
          {[...Array(options.likertRange)].map((e, i) => (
            <Cirle key={i} />
          ))}
        </div>
        <div className={cx("likert-labels")}>
          <div
            className={cx("likert-label")}
            style={{ textAlign: "left", justifySelf: "start" }}
          >
            {options.likertStartLabel}
          </div>
          <div className={cx("likert-label")} style={{ justifySelf: "center" }}>
            {options.likertMiddleLabel}
          </div>
          <div
            className={cx("likert-label")}
            style={{ textAlign: "right", justifySelf: "end" }}
          >
            {options.likertEndLabel}
          </div>
        </div>
      </div>

      <div className={cx("inputFields")}>
        <div>
          <div className={cx("text")}>Range:</div>
          <div className={cx("input")}>
            <div className={cx("text-visible")}>Range:</div>
            <Select
              value={options.likertRange}
              onChange={(value) =>
                setOptions({ ...options, likertRange: value })
              }
            >
              {[...Array(8)].map((e, i) => (
                <Option key={i} value={i + 3}>
                  {i + 3}
                </Option>
              ))}
            </Select>
          </div>
        </div>

        <div>
          <div className={cx("text")}>Start Label:</div>
          <div className={cx("input")}>
            <Input
              placeholder="Start Label"
              value={options.likertStartLabel}
              onChange={(e) =>
                setOptions({ ...options, likertStartLabel: e.target.value })
              }
              className="input-border"
              suffix={
                options.likertStartLabel !== "" ? (
                  <CheckOutlined style={{ color: "green" }} />
                ) : (
                  <Tooltip title="You need to fill this option!">
                    <InfoCircleOutlined style={{ color: "#ff9480" }} />
                  </Tooltip>
                )
              }
            />
          </div>
        </div>

        <div>
          <div className={cx("text")}>Middle Label:</div>
          <div className={cx("input")}>
            <Input
              placeholder="Middle Label (Optional)"
              value={options.likertMiddleLabel}
              onChange={(e) =>
                setOptions({ ...options, likertMiddleLabel: e.target.value })
              }
              className="input-border"
              suffix={
                <CheckOutlined style={{ display: "none", color: "green" }} />
              }
            />
          </div>
        </div>

        <div>
          <div className={cx("text")}>End Label:</div>
          <div className={cx("input")}>
            <Input
              placeholder="End Label"
              value={options.likertEndLabel}
              onChange={(e) =>
                setOptions({ ...options, likertEndLabel: e.target.value })
              }
              className="input-border"
              suffix={
                options.likertEndLabel !== "" ? (
                  <CheckOutlined style={{ color: "green" }} />
                ) : (
                  <Tooltip title="You need to fill this option!">
                    <InfoCircleOutlined style={{ color: "#ff9480" }} />
                  </Tooltip>
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Likert;
