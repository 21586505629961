import React, { Fragment } from "react";
import { Input, Select, Divider, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import c from "classnames/bind";
import styles from "./NetPromotorScore.module.css";
import { useState, useRef } from "react";

const cx = c.bind(styles);

const { Option } = Select;

const NetPromotorScore = ({ options, setOptions }) => {
  const dullGreenButtonStyle = {
    marginRight: 8,
    marginBottom: 12,
    backgroundColor: "#00c194",
    color: "white",
    borderRadius: 5,
  };

  const greyButtonStyle = {
    marginRight: 8,
    marginBottom: 12,
    backgroundColor: "grey",
    color: "white",
    borderRadius: 5,
  };

  const [company, setCompany] = useState("");
  const [items, setItems] = useState(["This Product", "This Service"]);
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const addItem = () => {
    // items.push(company);
    setItems([...items, company]);
    setCompany("");
  };

  const toggleOpen = (e, close) => {
    console.log("target", e.target.innerText);
    if (close !== undefined) {
      setOpen(close);
    } else if (e.target.className === "ant-select-selection-item") {
      setOpen(!open);
    }
  };

  return (
    <Fragment>
      <Select
        bordered={false}
        value={options.npsOption}
        ref={ref}
        className={cx("dropdown-button", {
          "dropdown-button-active": options.npsOption !== "This Company",
        })}
        suffixIcon={
          <DownOutlined
            className={cx("select-arrow", {
              "select-arrow-active": options.npsOption !== "This Company",
            })}
          />
        }
        dropdownStyle={{ width: "auto", color: "#004479" }}
        onClick={toggleOpen}
        onSelect={() => setOpen(false)}
        open={open}
        dropdownRender={(menu) => (
          <div style={{ color: "#004479", fontWeight: "bold" }}>
            {/* {console.log(menu)} */}
            {menu}
            <Divider style={{ margin: "4px 0" }} />
            <div style={{ padding: "0px 12px" }}>
              Specific company, brand, product or service:
            </div>
            <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
              <Input
                style={{ flex: "auto" }}
                placeholder="Company Name"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <Button
                style={dullGreenButtonStyle}
                disabled={company === "" ? true : false}
                onClick={addItem}
              >
                Save
              </Button>
              <Button
                id="cancel"
                style={greyButtonStyle}
                onClick={(e) => toggleOpen(e, false)}
              >
                CANCEL
              </Button>
            </div>
          </div>
        )}
        onChange={(value) => setOptions({ ...options, npsOption: value })}
      >
        {items.map((item, index) => (
          <Option
            key={index}
            style={{ color: "#004479", fontWeight: "bold" }}
            value={item}
          >
            {item}
          </Option>
        ))}
      </Select>
    </Fragment>
  );
};

export default NetPromotorScore;
