export const getDateObject = (date, time) => {
  let [day, month, year] = date.split("/");
  month = month.length === 2 ? month : "0" + month;
  day = day.length === 2 ? day : "0" + day;
  const dateString = `${year}-${month}-${day}T${time}`;
  return new Date(dateString);
};

export const times = [
  { label: "8am", value: "08:00" },
  { label: "9am", value: "09:00" },
  { label: "10am", value: "10:00" },
  { label: "11am", value: "11:00" },
  { label: "12pm", value: "12:00" },
  { label: "1pm", value: "13:00" },
  { label: "2pm", value: "14:00" },
  { label: "3pm", value: "15:00" },
  { label: "4pm", value: "16:00" },
  { label: "5pm", value: "17:00" },
  { label: "6pm", value: "18:00" },
  { label: "7pm", value: "19:00" },
];
