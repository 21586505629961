import { Button, Switch, Row, Col, Select, Input, InputNumber } from "antd";
import React, { useState } from "react";
import ProjectPageLayout from "../../../components/page-layout/PageLayout";
import Topbar from "../../../components/topbar/Topbar";
import Sidenav from "../../../components/admin/sidenav/Sidenav";
import styles from "./user-survey.module.css";
import c from "classnames/bind";
import ResponsiveTable from "../../../components/responsive-table/ResponsiveTable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { publishSurvey, getSurveys } from "../../../actions/admin";
import {
  incrementPendingRequests,
  decrementPendingRequests,
  notify,
} from "../../../utils";
import { adminPages } from "../../../constants";
import Survey from "../../user/survey/Survey";

const cx = c.bind(styles);

const calculatePercent = (clientRate, value) => {
  return (clientRate * value) / 100;
};

const calculateNumber = (clientRate, paymentValue) => {
  return (paymentValue * 100) / clientRate;
};

const UserSurvey = () => {
  const dispatch = useDispatch();
  const tierInfo = useSelector((state) => state.admin.tierInfo);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [questions, setQuestions] = useState();
  const [surveyType, setSurveyType] = useState();
  const [surveyBrandName, setSurveyBrandName] = useState();
  const [surveyBrandLogo, setSurveyBrandLogo] = useState();
  const [surveyInstructions, setSurveyInstructions] = useState();

  useEffect(() => {
    if (tierInfo) {
      incrementPendingRequests();
      dispatch(getSurveys())
        .then((surveys) => {
          const surveysDataSource = surveys
            .filter((val) => val.isPublished)
            .map((val) => {
              const surveyType = val.surveyType
                ? "qualitative"
                : "quantitative";
              const mode = val.mode ? "moderated" : "unModerated";
              const userType = val.userType ? "clientUser" : "UQUser";
              const accountTier = val.clientId.accountTier;
              const clientRate =
                surveyType === "qualitative"
                  ? tierInfo[accountTier].pricing[surveyType][mode][userType]
                  : tierInfo[accountTier].pricing[surveyType][userType];

              return {
                ...val,
                clientRate,
                number: val.paymentType
                  ? calculateNumber(clientRate, val.paymentValue)
                  : val.paymentValue,
              };
            });
          setData(surveysDataSource);
        })
        .catch((ex) => notify("error", "Error", ex))
        .finally(() => decrementPendingRequests());
    }
  }, [dispatch, tierInfo]);

  // Handlers
  const onNumberChange = (record, newNumber) => {
    const { _id, clientRate, paymentType } = record;
    const newData = data.map((val) => {
      if (val._id !== _id) return val;

      return {
        ...val,
        paymentValue: paymentType
          ? calculatePercent(clientRate, newNumber)
          : newNumber,
        number: newNumber,
      };
    });

    setData(newData);
  };

  const changePaymentType = (record, value) => {
    const { _id, clientRate, number } = record;
    const newData = data.map((val) => {
      if (val._id !== _id) return val;

      return {
        ...val,
        paymentType: value,
        paymentValue: value ? calculatePercent(clientRate, number) : number,
      };
    });

    setData(newData);
  };

  const onPublish = (record) => {
    delete record.number;
    delete record.clientRate;

    incrementPendingRequests();
    dispatch(publishSurvey(record))
      .then(() => {
        const newData = data.map((val) => {
          if (val._id !== record._id) return val;

          return { ...val, isAdminVerified: true };
        });
        setData(newData);
        notify("success", "Success", "Survey published");
      })
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  };

  const openSurveyView = (record) => {
    setShowModal(true);
    setQuestions(record.questions);
    const surveyTypeBool = record.surveyType === "qualitative" ? true : false;
    setSurveyType(surveyTypeBool);
    setSurveyBrandName(record.brandName);
    setSurveyBrandLogo(record.brandLogo);
    setSurveyInstructions(record.instructions);
  };

  const columns = [
    {
      title: "#",
      key: "no",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Survey View",
      align: "center",
      key: "survey_view",
      render: (text, record) => (
        <span>
          {record.name} <br />{" "}
          <a onClick={() => openSurveyView(record)}>User View</a>
        </span>
      ),
    },
    {
      title: "Survey Name",
      dataIndex: "name",
      align: "center",
      key: "name",
    },
    {
      title: "Survey Type",
      dataIndex: "surveyType",
      align: "center",
      key: "survey_type",
      render: (text) => (text ? "Qualitative" : "Quantitative"),
    },
    {
      title: "Users",
      dataIndex: "usersType",
      align: "center",
      key: "users",
      render: (text) => (text ? "Client" : "UQ"),
    },
    {
      title: "Conducted",
      dataIndex: "mode",
      align: "center",
      key: "conducted",
      render: (text) => (text ? "Moderated" : "Unmoderated"),
    },
    {
      title: "Device",
      dataIndex: "device",
      align: "center",
      key: "device",
      render: (text) => (text ? "Mobile" : "Desktop"),
    },
    {
      title: "User type",
      dataIndex: "audienceType",
      align: "center",
      key: "user_type",
      render: (text) => (text ? "Influencer" : "General"),
    },
    {
      title: "Ques",
      dataIndex: "questions",
      align: "center",
      key: "ques",
      render: (text) => text.length,
    },
    {
      title: "Time Limit",
      dataIndex: "timingType",
      align: "center",
      key: "time_limit",
      render: (text) => {
        switch (text) {
          case 0:
            return "15 mins";
          case 1:
            return "30 mins";
          case 2:
            return "45 mins";
          default:
            return "N/A";
        }
      },
    },
    {
      title: "Client Rate",
      dataIndex: "clientRate",
      align: "center",
      key: "client_rate",
    },
    {
      title: "%",
      dataIndex: "paymentType",
      key: "percent",
      align: "center",
      render: (text, record) => (
        <Switch
          size="small"
          defaultChecked={text}
          disabled={record.isAdminVerified}
          onChange={(val) => changePaymentType(record, val)}
        />
      ),
    },
    {
      title: "Number",
      align: "center",
      dataIndex: "number",
      key: "number",
      render: (text, record) => {
        return (
          <InputNumber
            defaultValue={text}
            disabled={record.isAdminVerified}
            onChange={(val) => onNumberChange(record, val)}
          />
        );
      },
    },
    {
      title: "Final Amount",
      align: "center",
      dataIndex: "paymentValue",
      key: "final_amount",
    },
    {
      title: "Publish",
      align: "center",
      dataIndex: "isAdminVerified",
      key: "publish",
      render: (text, record) => (
        <Button onClick={() => onPublish({ ...record })} disabled={text}>
          {text ? "Published" : "Publish"}
        </Button>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Topbar>
        <Sidenav drawer selectedItem={adminPages.SURVEYS} />
      </Topbar>
      <Sidenav selectedItem={adminPages.SURVEYS} />
      <ProjectPageLayout>
        <div className={cx("user-survey")}>
          <Row
            gutter={[6, 6]}
            style={{ marginBottom: 10 }}
            justify="space-between"
          >
            <Col>
              <Select
                placeholder="Sort by"
                className="input-border"
                size="large"
                style={{ minWidth: 188 }}
              >
                <Select.Option value="ascend">Ascending</Select.Option>
                <Select.Option value="descend">Descending</Select.Option>
              </Select>
            </Col>
            <Col>
              <Input
                placeholder="Search"
                className="input-border"
                size="large"
              />
            </Col>
          </Row>
          <ResponsiveTable
            className="user-survey"
            columns={columns}
            dataSource={data}
          />
        </div>
      </ProjectPageLayout>
      <Survey
        isOpen={showModal}
        questions={questions}
        surveyType={surveyType}
        onClose={() => {
          setShowModal(false);
          setQuestions(undefined);
          setSurveyType(undefined);
          setSurveyBrandName(undefined);
          setSurveyInstructions(undefined);
          setSurveyBrandLogo(undefined);
        }}
        brandName={surveyBrandName}
        brandLogo={surveyBrandLogo}
        surveyInstructions={surveyInstructions}
      />
    </React.Fragment>
  );
};

export default UserSurvey;
