import React, { useEffect } from "react";
import { Button, Card } from "antd";
import RatioModal from "./Modals/RatioModal/RatioModal";
import { useState } from "react";
import { oxford, getQuestion } from "./CriteriaUtils.js";
import RatioDescription from "./RatioDescription";

const criteriaHasRatios = (criteria) => {
  if (!criteria) return false;
  return criteria.some((criterion) => {
    return (
      criterion?.selectedValues?.length &&
      criterion.selectedValues.some((selectedValue) => selectedValue !== -1)
    );
  });
};

const RatioContainer = ({ criteria, adminQuestions, setCriteria }) => {
  const [isRatioModalVisible, setIsRatioModalVisible] = useState(false);
  const [criteriaListClient, setCriteriaListClient] = useState([]);
  const [hasRatios, setHasRatios] = useState(false);

  useEffect(() => {
    setHasRatios(criteriaHasRatios(criteria));
  }, [criteria]);

  //When criteria changes, update each group's criteria and criteria list diplayed on the UI
  useEffect(() => {
    //update Criteria list
    const newCriteriaListClient = criteria.map((criterion) => {
      return getQuestion(criterion.questionId, adminQuestions).clientField;
    });
    setCriteriaListClient(newCriteriaListClient);
  }, [criteria]);

  const showRatioModal = () => {
    setIsRatioModalVisible(true);
  };

  const hideRatioModal = () => {
    setIsRatioModalVisible(false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ fontSize: "1rem", fontWeight: "bold" }}>Add Ratios</div>
          <div>{`You can add ratios for ${oxford(criteriaListClient)}`}</div>
        </div>
        <Button
          style={{
            backgroundColor: "#00233f",
            color: "white",
            border: "none",
            borderRadius: 4,
            height: 36,
          }}
          onClick={() => showRatioModal()}
        >
          {hasRatios ? "Edit Ratios" : "Add Ratios"}
        </Button>

        <RatioModal
          isModalVisible={isRatioModalVisible}
          hideModal={() => hideRatioModal()}
          isGroupSurvey={false}
          criteria={criteria}
          adminQuestions={adminQuestions}
          setCriteria={setCriteria}
        />
      </div>
      {hasRatios && (
        <div style={{ padding: "20px 0 0 0" }}>
          <Card style={{ backgroundColor: "#EEEEEE", borderRadius: "5px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontSize: "1em", fontWeight: "bold" }}>Ratios:</div>
            </div>
            <div>
              {criteria.map((criterion) => (
                <RatioDescription
                  criterion={criterion}
                  adminQuestions={adminQuestions}
                  key={criterion.questionId}
                />
              ))}
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default RatioContainer;
