import React from "react";
import "bootstrap/dist/js/bootstrap.min.js";
import HomeTextBanner from "../components/HomeV2/HomeTextBanner/HomeTextBanner";
import HomeQuestionAnswers from "../components/HomeV2/HomeQuestionAnswers/HomeQuestionAnswers";
import HomeFeedback from "../components/HomeV2/HomeFeedback/HomeFeedback";
import Layout from "../components/Layout/Layout/Layout";

const BecomeTester = () => {
  return (
    <Layout>
      <HomeTextBanner />
      <HomeQuestionAnswers />
      <HomeFeedback />
    </Layout>
  );
};

export default BecomeTester;
