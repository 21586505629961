import React, { useState, useEffect, useRef } from "react";
import ProjectPageLayout, {
  Content,
} from "../../../components/page-layout/PageLayout";
import IndividualResponses from "./IndividualReponses";
import Navbar from "./Navbar";
import QuestionSummary from "./QuestionSummary";
import handleNetworkErrors from "../handleNetworkErrors";
import axios, { Routes } from "../../../services/API";
import styles from "./result.module.css";
import c from "classnames/bind";
import ReactToPrint from "react-to-print";
import { PieChartOutlined, PrinterFilled } from "@ant-design/icons";
import { Card, Button, Select, Col } from "antd";

const cx = c.bind(styles);
const { Option } = Select

const Result = ({ id, surveys }) => {
  const [selectedTab, setSelectedTab] = useState("1");
  const [surveyData, setSurveyData] = useState(null);
  const [adminQuestions, setAdminQuestions] = useState(null);
  const [criteria, setCriteria] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const questionComponentRef = useRef(null);

  useEffect(() => {
    document.body.setAttribute("data-new-theme", true);

    return () => {
      document.body.removeAttribute("data-new-theme");
    };
  }, []);

  useEffect(() => {
    (async () => {
      const { url, method } = Routes.others.adminQuestions();
      try {
        const { data } = await axios[method](url);
        if (data) {
          setAdminQuestions(data.adminQuestions);
        }
      } catch (err) {
        handleNetworkErrors(err);
      }
    })();
  }, []);
  
  console.log("refinedSurvey", surveyData);

  return (
    <ProjectPageLayout>
      <Content>
        <Navbar
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          surveys={surveys.filter((s) => s.isPublished)}
          surveyData={surveyData}
          setSurveyData={setSurveyData}
          questionComponentRef={questionComponentRef}
        ></Navbar>
        {surveyData == null ? (
          <div
            className={cx("grey-container")}
            style={{
              marginTop: 32,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: 400,
            }}
          >
            <PieChartOutlined
              style={{
                fontSize: 64,
                color: "#dddddd",
                marginBottom: 20,
              }}
            />
            <span
              style={{
                fontSize: 16,
                fontWeight: "bold",
                color: "#dddddd",
              }}
            >
              Select survey to view results
            </span>
          </div>
        ) : (
          <>
            <Card>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  {surveyData.name}
                </div>
                <div>
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        size={"default"}
                        style={{
                          backgroundColor: "#B9E8F6",
                          color: "black",
                          border: "none",
                          marginRight: 0,
                          borderRadius: "5px",
                        }}
                      >
                        <PrinterFilled /> Print Survey
                      </Button>
                    )}
                    bodyClass={cx("print-body")}
                    content={() => questionComponentRef.current}
                  ></ReactToPrint>
                </div>
              </div>
              <div style={{ display: "flex", gap: "68px", marginTop: "16px" }}>
                <div>
                  <div style={{ opacity: "80%", fontSize: "smaller" }}>
                    Survey type
                  </div>
                  <div>
                    {surveyData.surveyType
                      ? "Qualitative" +
                        " / " +
                        (surveyData.mode ? "Moderated" : "Un Moderated")
                      : "Quantitative"}
                  </div>
                </div>
                <div>
                  <div style={{ opacity: "80%", fontSize: "smaller" }}>
                    Published on
                  </div>
                  <div>
                    {new Date(surveyData.createdAt)
                      .toString()
                      .split(" ")
                      .map((stringPart, index) => {
                        if (index <= 4) {
                          return <span key={stringPart}>{stringPart} </span>;
                        }
                      })}{" "}
                    IST
                  </div>
                </div>
                <div>
                  <div style={{ opacity: "80%", fontSize: "smaller" }}>
                    Participants
                  </div>
                  <div>
                    Completed
                    {" " +
                      surveyData.responses.length +
                      " / " +
                      surveyData.numberOfParticipants}
                  </div>
                </div>
              </div>
              {
              surveyData.surveyAccess && surveyData.mode && surveyData.surveyType &&
              <div style={{ width: "30%", marginTop: "20px" }}> 
              <Select
              placeholder="Select from completed calls"
              size="large" 
              className={cx("survey-select")}
              dropdownClassName={cx("survey-select-dropdown")}
              value={selectedGroup}
              onChange={setSelectedGroup}
            >
              {surveyData.qualitativeData.map((group, i) => (
                <Option
                  key={group._id}
                  value={i}
                  className={cx("survey-select-option")}
                >
                  Group {i}
                </Option>
              ))}
            </Select>
            </div>
            }
              <div
                style={{
                  width: "50%",
                  height: 48,
                  borderRadius: 5,
                  margin: "32px 0",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#dddddd",
                }}
              >
                <div
                  style={{
                    flexBasis: "50%",
                    height: 48,
                    borderRadius: 5,
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor:
                      selectedTab === "1" ? "#01223f" : "#B9E8F7",
                    color: selectedTab === "1" ? "white" : "#01223f",
                    pointerEvents: selectedTab === "1" ? "none" : "auto",
                  }}
                  onClick={() => {
                    setSelectedTab("1");
                  }}
                >
                  Question Summaries
                </div>
                <div
                  style={{
                    flexBasis: "50%",
                    height: 48,
                    borderRadius: 5,
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor:
                      selectedTab === "2" ? "#01223f" : "#B9E8F7",
                    color: selectedTab === "2" ? "white" : "#01223f",
                    pointerEvents: selectedTab === "2" ? "none" : "auto",
                  }}
                  onClick={() => {
                    setSelectedTab("2");
                  }}
                >
                  Individual Responses
                </div>
              </div>
               {
   (() => {
       if (surveyData.surveyAccess && surveyData.mode && surveyData.surveyType) {
        if(selectedGroup !== null) return <div>
        {selectedTab === "1" ? (
          <QuestionSummary
          surveyData={surveyData}
          adminQuestions={adminQuestions}
          criteria={criteria}
          setCriteria={setCriteria}
          questionComponentRef={questionComponentRef}
          moderatedCallIndex = {selectedGroup}
          />
          ) : (
            <IndividualResponses
            surveyData={surveyData}
            adminQuestions={adminQuestions}
            criteria={criteria}
            setCriteria={setCriteria}
            questionComponentRef={questionComponentRef}
            moderatedCallIndex = {selectedGroup}
            />
            )}
      </div>
        else return <div>Please select call</div>
        }
       else 
          return <div>
          {selectedTab === "1" ? (
            <QuestionSummary
            surveyData={surveyData}
            adminQuestions={adminQuestions}
            criteria={criteria}
            setCriteria={setCriteria}
            questionComponentRef={questionComponentRef}
            />
            ) : (
              <IndividualResponses
              surveyData={surveyData}
              adminQuestions={adminQuestions}
              criteria={criteria}
              setCriteria={setCriteria}
              questionComponentRef={questionComponentRef}
              />
              )}
        </div>
   })()
}

            </Card>
          </>
        )}
      </Content>
    </ProjectPageLayout>
  );
};

export default Result;
