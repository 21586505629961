import React from "react";
import { Form, Modal, Row, Col, InputNumber, Button } from "antd";

const PlansFeesModal = (props) => {
  const { plansFees, handleCancel, handleEditPlansFees } = props;
  const [form] = Form.useForm();

  const onEditPlansFees = () => {
    form.validateFields().then((values) => {
      const newSubscriptionValues = [
        values.basic,
        values.elite,
        values.platinum,
      ];
      handleEditPlansFees(newSubscriptionValues);
      handleCancel();
    });
  };

  const footer = (
    <div>
      <Button
        onClick={handleCancel}
        style={{ border: "1px solid #004479", marginRight: 10, width: 100 }}
      >
        Cancel
      </Button>
      <Button type="primary" onClick={onEditPlansFees} style={{ width: 100 }}>
        Save
      </Button>
    </div>
  );

  return (
    <Modal
      title=""
      closable={false}
      visible={true}
      onCancel={handleCancel}
      footer={footer}
    >
      <Form initialValues={plansFees} form={form} style={{ color: "#004479" }}>
        <Row gutter={6}>
          <Col xs={16}>Basic Plan Monthly Fee</Col>
          <Col xs={8} style={{ textAlign: "right" }}>
            <Form.Item name="basic">
              <InputNumber className="input-border" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col xs={16}>Elite Plan Monthly Fee</Col>
          <Col xs={8} style={{ textAlign: "right" }}>
            <Form.Item name="elite">
              <InputNumber className="input-border" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col xs={16}>Platinum Plan Monthly Fee</Col>
          <Col xs={8} style={{ textAlign: "right" }}>
            <Form.Item name="platinum">
              <InputNumber className="input-border" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PlansFeesModal;
