import React, { useState, Fragment, useEffect } from "react";
import { Modal, Button, InputNumber, notification, Divider } from "antd";
import {
  CloseSquareFilled,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import TagsInput from "react-tagsinput";
import { useMediaQuery } from "react-responsive";

import styles from "./SurveyStateModal.module.css";
import c from "classnames/bind";
import { surveyStates, EMAIL_VALIDATION_REGEX } from "../../../../../constants";
// import UploadCSV from "../../../criteria/UploadCSV";
import { notify } from "../../../../../utils";
import CalendarComponent from "../CalendarComponent";

const cx = c.bind(styles);

const SurveyStateModal = ({
  handleOk,
  handleCancel,
  updateSurvey,
  isVisible,
  surveyState,
  survey,
  surveyCost,
}) => {
  const preBookedSlots = survey.moderatedSlots
    ? JSON.parse(JSON.stringify(survey.moderatedSlots))
    : null;
  const [addParticipantBox, setAddParticipantBox] = useState(false);
  const [numberOfParticipants, setNumberOfParticipants] = useState(0);
  const [clientProvidedUsers, setClientProvidedUsers] = useState([]);
  const [moderatedSlots, setModeratedSlots] = useState(preBookedSlots);
  // const [calendarComponent, setCalendarComponent] = useState(false);
  const isTabletOrMobile = useMediaQuery({
    query: "(max-width: 500px)",
  });

  useEffect(() => {
    setNumberOfParticipants(clientProvidedUsers.length);
  }, [clientProvidedUsers]);

  useEffect(() => {
    setAddParticipantBox(false);
  }, []);

  // useEffect(() => {
  //   setCalendarComponent(false);
  // }, [addParticipantBox]);

  const handleAddParticipants = () => {
    let newValues = {};
    if (survey.usersType) {
      if (clientProvidedUsers.length === numberOfParticipants) {
        newValues = {
          ...newValues,
          clientProvidedUsers: [
            ...survey.clientProvidedUsers,
            ...clientProvidedUsers,
          ],
        };
      } else {
        notification.error({
          message:
            "Number of participants are not matching with number of emails",
        });
        return;
      }
    }
    newValues = {
      ...newValues,
      numberOfParticipants: numberOfParticipants + survey.numberOfParticipants,
    };
    if (survey.mode) {
      newValues = {
        ...newValues,
        moderatedSlots: moderatedSlots,
      };
    }
    updateSurvey(newValues);
  };

  return (
    <Modal
      className={cx("survey-state-modal")}
      centered={true}
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={true}
      width={700}
      closeIcon={
        <CloseSquareFilled
          style={{
            fontSize: 24,
            color: "#dddddd",
          }}
        />
      }
      footer={null}
    >
      <div
        style={{
          fontSize: !addParticipantBox && 18,
          marginBottom: !addParticipantBox && 24,
        }}
        className={addParticipantBox ? cx("action-title") : ""}
      >
        {addParticipantBox ? "Add more participants" : "Survey options"}
      </div>
      {addParticipantBox && (
        <div
          className={cx("action-container")}
          style={{
            marginBottom: 24,
          }}
        >
          <div className={cx("action-info-text")}>
            You can add more participants to the existing survey, this will not
            affect your existing data.
          </div>
        </div>
      )}
      {addParticipantBox ? (
        <Fragment>
          <div className={cx("action-container")}>
            <div className={cx("action-info-text")}>
              <div
                className={cx("action-container")}
                style={{
                  justifyContent: "flex-start",
                  marginBottom: 4,
                }}
              >
                <div className={cx("action-info-text")}>
                  Current participant count
                </div>
                <div
                  style={{
                    alignSelf: "flex-start",
                  }}
                >
                  <b>{survey.numberOfParticipants}</b>
                </div>
              </div>
              <div
                className={cx("action-container")}
                style={{
                  justifyContent: "flex-start",
                  marginBottom: 4,
                }}
              >
                <div className={cx("action-info-text")}>
                  Price per participant
                </div>
                <div
                  style={{
                    alignSelf: "flex-start",
                  }}
                >
                  <b>{surveyCost}</b>
                </div>
              </div>
              <div
                className={cx("action-container")}
                style={{
                  justifyContent: "flex-start",
                  marginBottom: 4,
                }}
              >
                <div className={cx("action-info-text")}>
                  <div>Total additional cost</div>
                  <div style={{ fontSize: 10 }}>Including 18% GST</div>
                </div>
                <div
                  style={{
                    alignSelf: "flex-start",
                  }}
                >
                  <b>{surveyCost * numberOfParticipants}</b>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: isTabletOrMobile ? "column-reverse" : "row",
                alignSelf: "flex-start",
              }}
            >
              <Button
                className={cx("button")}
                onClick={() => {
                  if (numberOfParticipants > 0) {
                    setNumberOfParticipants(numberOfParticipants - 1);
                  }
                }}
                style={{
                  minWidth: isTabletOrMobile ? "36px" : "44px",
                  height: isTabletOrMobile ? 36 : 44,
                  marginRight: isTabletOrMobile ? 0 : 8,
                  padding: isTabletOrMobile ? "0 8px" : "0 15px",
                }}
              >
                <MinusOutlined />
              </Button>
              <InputNumber
                size="small"
                min={0}
                max={1000}
                value={numberOfParticipants}
                controls={false}
                onChange={(value) => setNumberOfParticipants(value)}
                className={cx("input-box")}
              />
              <Button
                className={cx("button")}
                onClick={() => {
                  if (numberOfParticipants < 1000) {
                    setNumberOfParticipants(numberOfParticipants + 1);
                  }
                }}
                style={{
                  minWidth: isTabletOrMobile ? "36px" : "44px",
                  height: isTabletOrMobile ? 36 : 44,
                  marginLeft: isTabletOrMobile ? 0 : 8,
                  padding: isTabletOrMobile ? "0 8px" : "0 15px",
                }}
              >
                <PlusOutlined />
              </Button>
            </div>
          </div>
          {survey.usersType && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "1rem",
                flexWrap: "wrap",
              }}
            >
              <div className={cx("db-text")}>
                <p>Enter user emails</p>
              </div>
              <div className={cx("input-container emails")}>
                <TagsInput
                  value={clientProvidedUsers}
                  onChange={(tags) => setClientProvidedUsers(tags)}
                  onlyUnique={true}
                  inputProps={{
                    className: "react-tagsinput-input",
                    placeholder: "Add email",
                  }}
                  tagProps={{
                    className: "react-tagsinput-tag",
                    classNameRemove: "react-tagsinput-remove",
                    style: {
                      backgroundColor: "#00233F",
                      border: 0,
                      color: "white",
                    },
                  }}
                  validationRegex={EMAIL_VALIDATION_REGEX}
                  onValidationReject={() =>
                    notify("error", "Error", "Invalid email")
                  }
                />
              </div>
              {/* <UploadCSV
                clientProvidedUsers={clientProvidedUsers}
                setClientProvidedUsers={setClientProvidedUsers}
              /> */}
            </div>
          )}
          {survey.mode && (
            <Fragment>
              <div className={cx("action-title")} style={{ marginTop: 20 }}>
                Modify survey dates
              </div>
              <CalendarComponent
                moderatedSlots={moderatedSlots}
                bookedSlots={preBookedSlots}
                setModeratedSlots={(moderatedSlots) => {
                  console.log("updatedSlots", moderatedSlots);
                  setModeratedSlots(moderatedSlots);
                }}
                hideTitle={true}
                overrideStyle={{
                  margin: "10px 0",
                }}
              />
            </Fragment>
          )}
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              className={cx("button")}
              style={{ background: "#bdbdbd", marginRight: 20 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className={cx("button")}
              disabled={numberOfParticipants === 0}
              onClick={() => {
                handleAddParticipants();
              }}
            >
              Confirm
            </Button>
          </div>
        </Fragment>
      ) : surveyState === surveyStates.IN_PROGRESS ? (
        <div>
          <div>
            <div className={cx("action-title")}>Add more participants</div>
            <div className={cx("action-container")}>
              <div className={cx("action-info-text")}>
                You can add more participants to the existing survey, this will
                not affect your existing data.
              </div>
              <Button
                className={cx("button")}
                onClick={() => setAddParticipantBox(true)}
              >
                Add Participants
              </Button>
            </div>
          </div>
          <Divider />
          <div>
            <div className={cx("action-title")}>Pause survey</div>
            <div className={cx("action-container")}>
              <div className={cx("action-info-text")}>
                You can pause the survey temporarily, resume anytime from this
                menu.
              </div>
              <Button
                className={cx("button")}
                onClick={() => handleOk(surveyStates.PAUSE)}
              >
                Pause Survey
              </Button>
            </div>
          </div>
          <Divider />
          <div>
            <div className={cx("action-title")}>End survey</div>
            <div className={cx("action-container")}>
              <div className={cx("action-info-text")}>
                This will terminate the survey. You cannot undo this action, you
                can view survey data in result section.
              </div>
              <Button
                className={cx("button")}
                style={{
                  backgroundColor: "rgb(235, 63, 74)",
                }}
                onClick={() => handleOk(surveyStates.END)}
              >
                End Survey
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <div className={cx("action-title")}>Restart survey</div>
            <div className={cx("action-container")}>
              <div className={cx("action-info-text")}>
                This will resume the survey and make it available to the users.
              </div>
              <Button
                className={cx("button")}
                onClick={() => handleOk(surveyStates.IN_PROGRESS)}
              >
                Restart Survey
              </Button>
            </div>
          </div>
          <Divider />
          <div>
            <div className={cx("action-title")}>End survey</div>
            <div className={cx("action-container")}>
              <div className={cx("action-info-text")}>
                This will terminate the survey. You cannot undo this action, you
                can view survey data in result section.
              </div>
              <Button
                className={cx("button")}
                style={{
                  backgroundColor: "rgb(235, 63, 74)",
                }}
                onClick={() => handleOk(surveyStates.END)}
              >
                End Survey
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default SurveyStateModal;
