import React, { useState, useEffect } from "react";
import PublishedFormBox from "./PublishedFormBox";
import ProjectLayout from "../../../components/page-layout/PageLayout";
import { CloseCircleOutlined } from "@ant-design/icons";
import styles from "./published-forms.module.css";
import c from "classnames/bind";
import { Button, notification } from "antd";
import Meeting from "../../user/meeting";
import Modal from "react-modal";
import { Fragment } from "react";
import PublishedFormBoxRefactored from "./PublishedFormBoxRefactored";
import handleNetworkErrors from "../handleNetworkErrors";
import axios, { Routes } from "../../../services/API";
import { CostCounter } from "../new-survey/CostCounter";
import ChatComponent from "./ChatComponent/ChatComponent";
import { getRoleByToken, getDateObject, fetchUsers } from "../../../utils";
import PublishedFormBoxRefactoredCopy from "./PublishedFormBoxRefactoredCopy";

const cx = c.bind(styles);

const times = [
  { label: "8am", value: "08:00" },
  { label: "9am", value: "09:00" },
  { label: "10am", value: "10:00" },
  { label: "11am", value: "11:00" },
  { label: "12pm", value: "12:00" },
  { label: "1pm", value: "13:00" },
  { label: "2pm", value: "14:00" },
  { label: "3pm", value: "15:00" },
  { label: "4pm", value: "16:00" },
  { label: "5pm", value: "17:00" },
  { label: "6pm", value: "18:00" },
  { label: "7pm", value: "19:00" },
];

const modalStyles = {
  content: {
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    border: 0,
    padding: 0,
    borderRadius: 0,
  },
};

const isJoinCallButtonEnabled = (date, time) => {
  const d = getDateObject(date, time);
  d.setMinutes(d.getMinutes() - 5);
  return d < new Date();
};

const getNumberOfResponses = (survey) => {
  if (survey.surveyType && survey.mode && !survey.setting) {
    // qual moderated individual survey
    return Array.isArray(survey.qualitativeData)
      ? survey.qualitativeData.length
      : 0;
  } else if (
    survey.surveyType &&
    survey.mode &&
    survey.setting &&
    survey.qualitativeData[0]
  ) {
    // qual moderated group survey
    return Array.isArray(survey.qualitativeData[0].responses)
      ? survey.qualitativeData[0].responses.length
      : 0;
  } else {
    return survey.responses.filter((r) => r.answers.length > 0).length;
  }
};

const PublishedForms = ({ id, surveys, profile, updateSurveys }) => {
  const [availableSlots, setAvailableSlots] = useState();
  const [slot, setSlot] = useState();
  const [tierInfo, setTierInfo] = useState();
  const [recordingStartTime, setRecordingStartTime] = useState(null);
  const [notes, setNotes] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [triggerQuestionPublished, setTriggerQuestionPublished] = useState({
    trigger: false,
    question: null,
  });
  const [accountUsersInfo, setAccountUsersInfo] = useState(null);
  const [showOpenModal, setShowOpenModal] = useState(false);
  const [openLink, setOpenLink] = useState('');
  const [showOpenMeet, setShowOpenMeet] = useState(false);
  const [surveyLink, setSurveyLink] = useState(``);

 
  useEffect(() => {
    (async () => {
      if (!profile?.account?._id) return;
      const users = await fetchUsers(profile.account._id);
      setAccountUsersInfo(users);
    })();
  }, [profile]);

  const createdBy = profile.firstName
    ? profile.firstName + " " + profile.lastName
    : profile.email;

  const onScheduledCallsClick = (surveyId) => {
    const selectedSurvey = surveys.find(
      (val) => val._id === surveyId
    );
    setSelectedSurvey(selectedSurvey);
    if(!selectedSurvey.surveyAccess) {
    const {moderatedSlots, setting} = selectedSurvey;
    let arr = [];
    for (const date in moderatedSlots) {
      for (const time in moderatedSlots[date]) {
        if (
          moderatedSlots[date][time].length > 0 &&
          new Date() < getDateObject(date, time)
        ) {
          arr.push({
            surveyId,
            setting,
            date,
            time,
            label: times.find((val) => val.value === time).label + " " + date,
          });
        }
      }
    }
    setAvailableSlots(arr);
  } else {
    setShowOpenModal(true)
  }
  };

  const startModeratedSurvey = (surveyId, date, time, setting) => {
    setSlot({ surveyId, date, time, setting });
  };
  const startOpenModeratedSurvey = (surveyId, openLink) => {
    setShowOpenMeet(true)
  };

  useEffect(() => {
    (async () => {
      const { url, method } = Routes.others.tierInfo();
      try {
        const { data } = await axios[method](url);
        if (data) {
          setTierInfo(data);
        }
      } catch (err) {
        handleNetworkErrors(err);
      }
    })();
  }, [surveys]);

  const publishQuestion = (question, slot) => {
    setTriggerQuestionPublished({ trigger: true, question: question });
    upsertQualitativeData(question, slot);
  };

  const stopRecordingHandler = () => {
    upsertQualitativeData(null, slot);
  };

  const showAnswer = (answerData) => {
    // console.log("show answer", answerData);
    if (answerData && answerData.answer && answerData.answer.message) {
      notification.success({
        message: answerData.answer.message,
      });
    }
  };

  const upsertQualitativeData = async (question, slot) => {
    const id =
      getRoleByToken(localStorage.getItem("token")) === "client"
        ? localStorage.getItem("client")
        : "";
    const { url, method } = Routes.client.upsertQualitativeData(
      id,
      selectedSurvey._id
    );
    try {
      const { data } = await axios[method](url, {
        data: {
          slotKey: selectedSurvey.surveyAccess? openLink : slot.date + "_" + slot.time,
          question: question,
        },
      });
      if (question) {
        notification.success({
          message: "Question Published Successfully",
        });
      }
    } catch (err) {
      handleNetworkErrors(err);
    }
  };

  const generateOpenModLink = async () => {
    const {url, method} = Routes.client.generateOpenModeratedLink(selectedSurvey.clientId, selectedSurvey._id)
    try {
      debugger
      const {data} = await axios[method](url);
      setOpenLink(data.newSurveyLink.link);
    } catch (err) {
      handleNetworkErrors(err);
    }
  }
  const employLink = async () => {
    if(selectedSurvey.surveyAccess) {
      const {url, method} = Routes.client.employOpenModeratedLink(selectedSurvey.clientId,selectedSurvey._id, openLink)
      const res = await axios[method](url)
        console.log(res)
    }
  }
  useEffect(() => {
    if(selectedSurvey) setSurveyLink(`https://userqual.com/attemptSurvey/${selectedSurvey.referSurveyToken}` + `?token=${openLink}`)
  }, [openLink])

  return (
    <React.Fragment>
      <ProjectLayout>
        {availableSlots ? (
          <div className={cx("slots-container")}>
            <div className={cx("calls-box")}>
              <div style={{ textAlign: "center" }}>
                <b>Scheduled Calls</b>
                <CloseCircleOutlined
                  style={{ float: "right", fontSize: 20, cursor: "pointer" }}
                  onClick={() => setAvailableSlots(undefined)}
                />{" "}
                <br />
                <div style={{ color: "red", marginBottom: 10 }}>
                  You can call in 5 mins before the call time!
                </div>
                {availableSlots.map((item, index) => (
                  <div
                    style={{
                      display: "flex",
                      marginBottom: 10,
                      alignItems: "center",
                      textAlign: "left",
                    }}
                  >
                    <span style={{ flexGrow: 1, fontSize: 16 }}>
                      Participant #{index + 1}: {item.label}
                    </span>
                    <Button
                      type="primary"
                      style={{ borderRadius: 50 }}
                      // disabled={!isJoinCallButtonEnabled(item.date, item.time)}
                      onClick={() =>
                        startModeratedSurvey(
                          item.surveyId,
                          item.date,
                          item.time,
                          item.setting
                        )
                      }
                    >
                      Join Call
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : showOpenModal ? (
          <div className={cx("slots-container")}>
            <div className={cx("calls-box")}>
              <div style={{ textAlign: "center" }}>
                <b>Scheduled Calls</b>
                <CloseCircleOutlined
                  style={{ float: "right", fontSize: 20, cursor: "pointer" }}
                  onClick={() => setShowOpenModal(false)}
                />{" "}
                <br />
                <div style={{ color: "red", marginBottom: 10 }}>
                  You can call in 5 mins before the call time!
                </div>
                {  <div
                    style={{
                      display: "flex",
                      marginBottom: 10,
                      alignItems: "center",
                      textAlign: "left",
                    }}
                  >
                    <span style={{ flexGrow: 1, fontSize: 16 }}>
                     {surveyLink}
                    </span>
                    </div>
                    }
                <Button
                      type="primary"
                      style={{ borderRadius: 50 }}
                      // disabled={!isJoinCallButtonEnabled(item.date, item.time)}
                      onClick={() =>
                        generateOpenModLink()
                      }
                    >
                      Generate Link
                    </Button>
                    <Button
                      type="primary"
                      style={{ borderRadius: 50 }}
                      // disabled={!isJoinCallButtonEnabled(item.date, item.time)}
                      onClick={() =>
                        startOpenModeratedSurvey(
                          selectedSurvey.surveyId,
                          openLink
                        )
                      }
                    >
                      Join Call
                    </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="grid-form-container">
            <>
              {surveys && tierInfo && (
                <>
                  {surveys
                    .filter((s) => s.isPublished === true)
                    .map((item, idx) => (
                      <Fragment>
                        <PublishedFormBoxRefactoredCopy
                          survey={item}
                          tierInfo={tierInfo}
                          surveyId={item._id}
                          surveyCost={CostCounter(
                            item,
                            tierInfo.tiers[profile.accountTier]
                          )}
                          updateSurveys={updateSurveys}
                          accountUsersInfo={accountUsersInfo}
                          name={item.name}
                          surveyType={item.surveyType}
                          mode={item.mode}
                          device={item.device}
                          questions={item.questions}
                          setting={item.setting}
                          timingType={item.timingType}
                          updatedAt={item.updatedAt}
                          numberOfParticipants={item.numberOfParticipants}
                          numberOfResponses={getNumberOfResponses(item)}
                          isAdminVerified={item.isAdminVerified}
                          onScheduledCallsClick={onScheduledCallsClick}
                          key={item._id}
                        />
                        {/* <PublishedFormBoxRefactored
                          survey={item}
                          tierInfo={tierInfo}
                          surveyId={item._id}
                          surveyCost={CostCounter(
                            item,
                            tierInfo.tiers[profile.accountTier]
                          )}
                          updateSurveys={updateSurveys}
                          name={item.name}
                          surveyType={item.surveyType}
                          mode={item.mode}
                          device={item.device}
                          questions={item.questions}
                          setting={item.setting}
                          timingType={item.timingType}
                          createdBy={createdBy}
                          updatedAt={item.updatedAt}
                          numberOfParticipants={item.numberOfParticipants}
                          numberOfResponses={item.responses.length}
                          isAdminVerified={item.isAdminVerified}
                          onScheduledCallsClick={onScheduledCallsClick}
                        /> */}
                      </Fragment>
                    ))}
                </>
              )}
            </>
          </div>
        )}
      </ProjectLayout>
      {slot && (
        <Modal
          isOpen={!!slot}
          onRequestClose={() => setSlot(undefined)}
          style={modalStyles}
        >
          <div
            style={{ position: "relative", height: "100%", display: "flex" }}
          >
            <Meeting
              recordingStartTime={recordingStartTime}
              setRecordingStartTime={setRecordingStartTime}
              stopRecordingHandler={stopRecordingHandler}
              surveyId={slot.surveyId}
              date={slot.date.replace(/\//g, "|")}
              time={slot.time}
              isClient={true}
              isOneToOne={!slot.setting}
              notes={notes}
              setNotes={setNotes}
              triggerQuestionPublished={triggerQuestionPublished}
              setTriggerQuestionPublished={setTriggerQuestionPublished}
              showAnswer={showAnswer}
              token={openLink}
            />
            <ChatComponent
              slot={slot}
              notes={notes}
              setNotes={setNotes}
              publishQuestion={(question) => publishQuestion(question, slot)}
              recordingStartTime={recordingStartTime}
            />
          </div>
        </Modal>
      )}
      {showOpenMeet && (
        <Modal
        isOpen={!!showOpenMeet}
        onRequestClose={() => setShowOpenMeet(false)}
        style={modalStyles}
      >
        <div
          style={{ position: "relative", height: "100%", display: "flex" }}
        >
          <Meeting
            recordingStartTime={recordingStartTime}
            setRecordingStartTime={setRecordingStartTime}
            stopRecordingHandler={stopRecordingHandler}
            surveyId={selectedSurvey._id}
            isClient={true}
            isOneToOne={!selectedSurvey.setting}
            notes={notes}
            setNotes={setNotes}
            triggerQuestionPublished={triggerQuestionPublished}
            setTriggerQuestionPublished={setTriggerQuestionPublished}
            showAnswer={showAnswer}
            token={openLink}
            surveyAccess={selectedSurvey.surveyAccess}
            clientId={selectedSurvey.clientId}
            employLink = {employLink}
          />
          <ChatComponent
            notes={notes}
            setNotes={setNotes}
            publishQuestion={(question) => publishQuestion(question, slot)}
            recordingStartTime={recordingStartTime}
          />
        </div>
      </Modal>

      )}
    </React.Fragment>
  );
};

export default PublishedForms;
