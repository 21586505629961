import { Form, Popconfirm, Select } from "antd";
import React, { useEffect, useState } from "react";
import ProjectPageLayout, {
  Content,
} from "../../../components/page-layout/PageLayout";
import Topbar from "../../../components/topbar/Topbar";
import Sidenav from "../../../components/admin/sidenav/Sidenav";
import FormItemLabel from "../../../components/form-item-label/FormItemLabel";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Row, Col, Input, Button, InputNumber } from "antd";
import {
  CloseCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import "./influencer.css";
import Categories from "../../../components/admin/influencer/categories/Categories";
import EditInfluencerModal from "../../../components/admin/influencer/edit-influencer/EditInfluencerModal";
import {
  decrementPendingRequests,
  incrementPendingRequests,
  notify,
} from "../../../utils";
import { useDispatch } from "react-redux";
import {
  deleteInfluencer,
  editInfluencerCategories,
  getInfluencerCategories,
  getInfluencers,
  updateInfluencers,
} from "../../../actions/admin";
import { adminPages } from "../../../constants";
const ObjectID = require("bson-objectid");

const Influencer = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [editInfluencerModal, setEditInfluencerModal] = useState(false);

  useEffect(() => {
    incrementPendingRequests();
    dispatch(getInfluencerCategories())
      .then((categories) => form.setFieldsValue({ categories }))
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  }, [dispatch, form]);

  useEffect(() => {
    incrementPendingRequests();
    dispatch(getInfluencers())
      .then((influencers) => form.setFieldsValue({ influencers }))
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  }, [dispatch, form]);

  const handleSubmit = ({ categories, influencers }) => {
    influencers.forEach((val) => {
      if (!val._id) {
        val._id = ObjectID();
      }
    });

    incrementPendingRequests();
    Promise.race([
      dispatch(updateInfluencers(influencers)),
      dispatch(editInfluencerCategories(categories.filter((val) => val))),
    ])
      .then(() => notify("success", "Success", "Updated influencers"))
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  };

  return (
    <React.Fragment>
      <Topbar>
        <Sidenav drawer selectedItem={adminPages.INFLUENCERS} />
      </Topbar>
      <Sidenav selectedItem={adminPages.INFLUENCERS} />
      <ProjectPageLayout>
        <Content>
          <Form
            form={form}
            onFinish={handleSubmit}
            initialValues={{
              categories: [""],
              influencers: [{}],
            }}
          >
            <FormItemLabel name="Influencer Categories" />
            <Form.List name="categories">
              {(fields, { add, remove, move }) => {
                return (
                  <DragDropContext
                    onDragEnd={(result) =>
                      move(result.source.index, result.destination.index)
                    }
                  >
                    <Droppable droppableId="list">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="shadow-box"
                          style={{ maxWidth: 850, marginBottom: 40 }}
                        >
                          {fields.map((field, index) => (
                            <Draggable
                              draggableId={field.name.toString()}
                              index={index}
                              key={index}
                            >
                              {(provided) => (
                                <div
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                >
                                  <Form.Item
                                    {...field}
                                    /* rules={[
                                      {
                                        required: true,
                                        message: "Please enter the value!",
                                      },
                                    ]} */
                                    noStyle
                                  >
                                    <Categories
                                      dragHandleProps={provided.dragHandleProps}
                                      number={index + 1}
                                      addCategory={() =>
                                        add(null, field.name + 1)
                                      }
                                      removeCategory={() => remove(field.name)}
                                      showMinusIcon={fields.length > 1}
                                    />
                                  </Form.Item>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                );
              }}
            </Form.List>
            <FormItemLabel name="Add Influencers" />
            <Form.List name="influencers">
              {(fields, { add, remove, move }) => {
                console.log(fields);
                return (
                  <DragDropContext
                    onDragEnd={(result) =>
                      move(result.source.index, result.destination.index)
                    }
                  >
                    <Droppable droppableId="list">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={{ maxWidth: 850, marginBottom: 40 }}
                        >
                          {fields.map((field, index) => (
                            <Draggable
                              draggableId={field.name.toString()}
                              index={index}
                              key={index}
                            >
                              {(provided) => (
                                <div
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                >
                                  <div
                                    className="shadow-box"
                                    style={{ maxWidth: 850, marginBottom: 40 }}
                                  >
                                    <Popconfirm
                                      title="Are you sure delete this influencer?"
                                      onConfirm={() => {
                                        const influencerId = form.getFieldValue(
                                          ["influencers", field.name]
                                        )._id;
                                        incrementPendingRequests();
                                        dispatch(deleteInfluencer(influencerId))
                                          .then(() => {
                                            notify(
                                              "success",
                                              "Success",
                                              "Influencer deleted"
                                            );
                                            remove(field.name);
                                          })
                                          .catch((ex) =>
                                            notify("error", "Error", ex)
                                          )
                                          .finally(() =>
                                            decrementPendingRequests()
                                          );
                                      }}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <CloseCircleOutlined className="close-icon" />
                                    </Popconfirm>
                                    <Row gutter={12}>
                                      <Col xs={24} sm={10}>
                                        <Form.Item
                                          name={[field.name, "firstName"]}
                                          key={[field.name, "firstName"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please enter the value!",
                                            },
                                          ]}
                                        >
                                          <Input
                                            placeholder="First Name"
                                            size="large"
                                            className="input-border"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={10}>
                                        <Form.Item
                                          name={[field.name, "lastName"]}
                                          key={[field.name, "lastName"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please enter the value!",
                                            },
                                          ]}
                                        >
                                          <Input
                                            placeholder="Last Name"
                                            size="large"
                                            className="input-border"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={4}>
                                        <Form.Item
                                          name={[field.name, "gender"]}
                                          key={[field.name, "gender"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please enter the value!",
                                            },
                                          ]}
                                        >
                                          <Select
                                            placeholder="Gender"
                                            size="large"
                                            className="input-border"
                                          >
                                            <Select.Option value="male">
                                              Male
                                            </Select.Option>
                                            <Select.Option value="female">
                                              Female
                                            </Select.Option>
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                    <Row gutter={12}>
                                      <Col xs={24} sm={10}>
                                        <Form.Item
                                          name={[field.name, "email"]}
                                          key={[field.name, "email"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please enter the value!",
                                            },
                                          ]}
                                        >
                                          <Input
                                            placeholder="Email"
                                            size="large"
                                            className="input-border"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={10}>
                                        <Form.Item
                                          name={[field.name, "mobileNumber"]}
                                          key={[field.name, "mobileNumber"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please enter the value!",
                                            },
                                          ]}
                                        >
                                          <Input
                                            placeholder="Phone Number"
                                            size="large"
                                            className="input-border"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={4}>
                                        <Form.Item
                                          name={[field.name, "age"]}
                                          key={[field.name, "age"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please enter the value!",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            placeholder="Age"
                                            style={{ width: "100%" }}
                                            size="large"
                                            className="input-border"
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                    <Form.List name={[field.name, "platform"]}>
                                      {(fields, { add, remove }) => {
                                        return (
                                          <>
                                            {fields.map((smField, index) => (
                                              <Row
                                                gutter={12}
                                                key={smField.name}
                                              >
                                                <Col span={10}>
                                                  <Form.Item
                                                    name={[
                                                      smField.name,
                                                      "platform",
                                                    ]}
                                                    key={[
                                                      smField.name,
                                                      "platform",
                                                    ]}
                                                  >
                                                    <Select
                                                      placeholder="Platform"
                                                      size="large"
                                                      className="input-border"
                                                    >
                                                      <Select.Option value="facebook">
                                                        Facebook
                                                      </Select.Option>
                                                      <Select.Option value="instagram">
                                                        Instagram
                                                      </Select.Option>
                                                      <Select.Option value="snapchat">
                                                        Snapchat
                                                      </Select.Option>
                                                      <Select.Option value="tiktok">
                                                        Tiktok
                                                      </Select.Option>
                                                    </Select>
                                                  </Form.Item>
                                                </Col>
                                                <Col span={10}>
                                                  <Form.Item
                                                    name={[
                                                      smField.name,
                                                      "followers",
                                                    ]}
                                                    key={[
                                                      smField.name,
                                                      "followers",
                                                    ]}
                                                  >
                                                    <InputNumber
                                                      style={{ width: "100%" }}
                                                      placeholder="Number of followers"
                                                      className="input-border"
                                                      size="large"
                                                    />
                                                  </Form.Item>
                                                </Col>
                                                <Col
                                                  span={4}
                                                  className="no-hover actions"
                                                >
                                                  <PlusCircleOutlined
                                                    onClick={() =>
                                                      add(
                                                        null,
                                                        smField.name + 1
                                                      )
                                                    }
                                                    className="action-icon"
                                                  />
                                                  <MinusCircleOutlined
                                                    className="action-icon"
                                                    onClick={() =>
                                                      remove(smField.name)
                                                    }
                                                  />
                                                </Col>
                                              </Row>
                                            ))}
                                            {fields.length === 0 && (
                                              <Button
                                                size="large"
                                                style={{
                                                  marginBottom: 26,
                                                  border: "1px solid #004479",
                                                  color: "#004479",
                                                }}
                                                onClick={() => add()}
                                              >
                                                Add Social Media
                                              </Button>
                                            )}
                                          </>
                                        );
                                      }}
                                    </Form.List>
                                    <Row gutter={12}>
                                      <Col xs={24} sm={10}>
                                        <Form.Item
                                          name={[field.name, "rate"]}
                                          key={[field.name, "rate"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please enter the value!",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            placeholder="Approximate Rate"
                                            style={{ width: "100%" }}
                                            size="large"
                                            className="input-border"
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                    <Row gutter={12}>
                                      <Col xs={24} sm={10}>
                                        <Form.Item
                                          name={[field.name, "companyName"]}
                                          key={[field.name, "companyName"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please enter the value!",
                                            },
                                          ]}
                                        >
                                          <Input
                                            placeholder="Company Name"
                                            size="large"
                                            className="input-border"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={10}>
                                        <Form.Item
                                          name={[field.name, "gst"]}
                                          key={[field.name, "gst"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please enter the value!",
                                            },
                                          ]}
                                        >
                                          <Input
                                            placeholder="GST Number"
                                            size="large"
                                            className="input-border"
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                    <Row gutter={12}>
                                      <Col span={24}>
                                        <Form.Item
                                          name={[field.name, "address"]}
                                          key={[field.name, "address"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please enter the value!",
                                            },
                                          ]}
                                        >
                                          <Input
                                            placeholder="Address"
                                            size="large"
                                            className="input-border"
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                    {/* <Button
                                      style={{
                                        border: "1px solid #004479",
                                        color: "#004479",
                                      }}
                                      onClick={() =>
                                        setEditInfluencerModal(true)
                                      }
                                    >
                                      Edit this influencer
                                    </Button> */}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    <div style={{ textAlign: "center", maxWidth: 850 }}>
                      <Button
                        style={{
                          marginBottom: 26,
                          border: "1px solid #004479",
                          color: "#004479",
                        }}
                        onClick={() => add()}
                      >
                        Add Influencer
                      </Button>
                    </div>
                  </DragDropContext>
                );
              }}
            </Form.List>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: 200, marginBottom: 40 }}
            >
              Save
            </Button>
          </Form>
        </Content>
      </ProjectPageLayout>
      {editInfluencerModal && (
        <EditInfluencerModal
          handleCancel={() => setEditInfluencerModal(false)}
        />
      )}
    </React.Fragment>
  );
};

export default Influencer;
