import { surveyTypes, surveyAccess } from "../constants/constants";

export const isQuantSurvey = (survey) => {
  return !survey.surveyType;
};

export const isUnmoderatedQualSurvey = (survey) => {
  return survey.surveyType && !survey.mode;
};

export const isModeratedSurvey = (survey) => {
  return survey.surveyType && survey.mode;
};

export const isGroupSurvey = (survey) => {
  return isModeratedSurvey(survey) && survey.setting;
};

export const getSurveyType = (survey) => {
  if (isQuantSurvey(survey)) return surveyTypes.QUANTITATIVE;
  if (isUnmoderatedQualSurvey(survey))
    return surveyTypes.QUALITATIVE_UNMODERATED;
  if (isModeratedSurvey(survey)) return surveyTypes.QUALITATIVE_MODERATED;
};
export const isOpenSurvey = (survey) => {
  return survey.surveyAccess
}
export const getSurveyAccess = (survey) => {
  if(isOpenSurvey(survey)) return surveyAccess.OPEN
}
//Returns the time it takes to complete a survey
export const getSurveyTime = (survey) => {
  if (!survey.timingType) {
    return Math.round((survey.questions.length * 10) / 60) + " mins";
  }
  switch (survey.timingType) {
    case 1:
      return "15 mins";
    case 2:
      return "30 mins";
    case 3:
      return "45 mins";
    default:
      return;
  }
};

export const getRange = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
};
