import { CheckCircleFilled, EditFilled } from "@ant-design/icons";
import React from "react";
import styles from "./profile-question-box.module.css";
import c from "classnames/bind";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import MultipleChoice from "../profile-questions/multiple-choice/MultipleChoice";
import Checkboxes from "../profile-questions/checkboxes/Checkboxes";
import { InputNumber } from "antd";
import CheckboxesSpecial from "../profile-questions/checkboxes-special/CheckboxesSpecial";

const cx = c.bind(styles);

const Age = ({ key, options, value, onChange }) => {
  return (
    <InputNumber
      key={key}
      defaultValue={value}
      onChange={onChange}
      style={{
        backgroundColor: "#004479",
        color: "white",
        border: "1px solid white",
      }}
    />
  );
};

const ProfileQuestionBox = (props) => {
  const { key, question, questionObject, value, onChange } = props;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const [editable, setEditable] = useState(false);

  const renderQuestion = () => {
    const { questionType, options } = questionObject;
    switch (questionType) {
      case "multipleChoice":
        return (
          <MultipleChoice
            key={key}
            choices={options}
            value={value && value[0]}
            onChange={onChange}
            color="white"
          />
        );
      case "checkboxes":
        return (
          <Checkboxes
            key={key}
            choices={options}
            value={value}
            onChange={onChange}
            color="white"
          />
        );
      case "checkboxesSpecial":
        return (
          <CheckboxesSpecial
            key={key}
            choices={options}
            specialField={questionObject.specialField}
            value={value}
            onChange={onChange}
            color="white"
          />
        );
      case "location":
        return (
          <MultipleChoice
            key={key}
            choices={options}
            value={value && value[0]}
            onChange={onChange}
            color="white"
          />
        );
      case "age":
        return (
          <Age
            key={key}
            options={questionObject}
            value={value && value[0]}
            onChange={onChange}
          />
        );
      default:
        return;
    }
  };

  return (
    <div className={cx("box", { "edit-mode": editable })}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {question}
        {!editable ? (
          <div
            style={{
              fontSize: isTabletOrMobile ? 10 : 14,
              color: "#1A5787",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => setEditable(!editable)}
          >
            Edit
          </div>
        ) : (
          <CheckCircleFilled
            style={{
              fontSize: isTabletOrMobile ? 18 : 24,
              color: "white",
              cursor: "pointer",
            }}
            onClick={() => setEditable(!editable)}
          />
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {editable
          ? renderQuestion()
          : value
          ? questionObject.questionType !== "age"
            ? value.map((val) => questionObject.options[val]).join(", ")
            : value[0]
          : ""}
      </div>
    </div>
  );
};

export default ProfileQuestionBox;
