import React from "react";
import "./Layout.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Layout = ({ children }) => {
  return (
    <div className={"layout-container"}>
      <Header />
      <div className={"main-container"}>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
