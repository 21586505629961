import React from "react";
import styles from "./form-item-label.module.css";
import c from "classnames/bind";

const cx = c.bind(styles);

export default function FormItemLabel({ name, description, hint, extra }) {
  return (
    <p className={cx("label")}>
      <span style={{ color: "#404040" }}>{name}</span>{" "}
      {hint && <span style={{ fontSize: "14px" }}>{hint}</span>} {extra}
      {description && (
        <React.Fragment>
          <br />
          <span
            style={{
              marginTop: 4,
              fontSize: "14px",
            }}
          >
            {description}
          </span>
        </React.Fragment>
      )}
    </p>
  );
}
