import { increment, decrement } from "automate-redux";
import { notification } from "antd";
import store from "./store";
import jwt_decode from "jwt-decode";
import axios, { Routes } from "./services/API";

export function incrementPendingRequests() {
  store.dispatch(increment("pendingRequests"));
}

export function decrementPendingRequests() {
  store.dispatch(decrement("pendingRequests"));
}

export const notify = (type, title, msg, duration) => {
  notification[type]({
    message: title,
    description: String(msg),
    duration: duration,
  });
};

export function capitalizeFirstCharacter(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getRoleByToken = (token) => {
  try {
    const decoded = jwt_decode(token);
    return decoded.role;
  } catch (err) {
    return null;
  }
};

export const getEmailByToken = (token) => {
  try {
    const decoded = jwt_decode(token);
    return decoded.email;
  } catch (err) {
    return null;
  }
};

export const getSurveyIdByToken = (token) => {
  try {
    const decoded = jwt_decode(token);
    return decoded.surveyId;
  } catch (err) {
    return null;
  }
};

export const getVideoUrl = (surveyId, userId) => {
  // return `https://userqual-video-recording.s3.ap-south-1.amazonaws.com/${surveyId}_${userId}.mp4`;
  return `?filePath=${surveyId}_${userId}.mp4`;
};

export const getModeratedVideoUrl = (surveyId, qualitativeId, surveyAccess) => {
  qualitativeId = qualitativeId?.replace(/\//g, "|");
  if (surveyAccess) return `?filePath=${qualitativeId}.mp4`
  else return `?filePath=${surveyId}_${qualitativeId}.mp4`;
};

export const getTranscriptUrl = (surveyId, userId) => {
  return `?filePath=${surveyId}_${userId}.json`;
};

export const getModeratedSubtitlesUrl = (surveyId, qualitativeId) => {
  qualitativeId = qualitativeId?.replace(/\||:|\//gi, ".");
  return `?filePath=${surveyId}_${qualitativeId}.json&format=vtt`;
};

export const getSubtitlesUrl = (surveyId, userId) => {
  return `?filePath=${surveyId}_${userId}.json&format=vtt`;
};

export const generateId = (len = 32) => {
  return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx"
    .slice(0, len)
    .replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
};

export const Sum = (array) => {
  return array.reduce((element, total) => Number(total) + Number(element), 0);
};

export const getDateObject = (date, time) => {
  let [day, month, year] = date.split("/");
  month = month.length === 2 ? month : "0" + month;
  day = day.length === 2 ? day : "0" + day;
  const dateString = `${year}-${month}-${day}T${time}`;
  return new Date(dateString);
};

export const getClientInformation = async (targetId) => {
  const clientId = localStorage.getItem("client");
  const { data } = await axios({
    ...Routes.client.getClientInformation(clientId, targetId),
  });

  return data;
};

export const isLoggedIn = () => {
  return !!getRoleByToken(localStorage.getItem("token"));
};

export const getClientId = () => {
  return getRoleByToken(localStorage.getItem("token")) === "client"
    ? localStorage.getItem("client")
    : "";
};

export const isUserRole = () => {
  return getRoleByToken(localStorage.getItem("token")) === "user";
};

export const getUserId = () => {
  return isUserRole() ? localStorage.getItem("user") : "";
};

export const fetchUsers = async (accountId) => {
  if (!accountId) return;
  const clientId = getClientId();
  const { data } = await axios({
    ...Routes.client.getAccountUsers(clientId, accountId, true),
  });

  return data.allUsers;
};
