import React, { Fragment, useEffect, useState, useRef } from "react";
import Button from "../../../../components/button/Button";
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  DeleteOutlined,
  PlusCircleFilled,
  SaveFilled,
  RollbackOutlined,
  FilterFilled,
  UsergroupAddOutlined,
  DownCircleFilled,
  UpCircleFilled,
} from "@ant-design/icons";
import { difference, cloneDeep } from "lodash";
import ReactToPrint from "react-to-print";
import { COLOR, colorConstant } from "../../../../constants";
import filterUsers from "../constants/filterUsers";
import TabBar from "../../../../components/tabbar/TabBar";
import FlexRow from "../../../../components/flex-row";
import QuestionBox, { graphTypes } from "./QuestionBox";
import {
  initFilterAttributes,
  initChartAttributes,
} from "../constants/attributes";
import {
  greyButtonStyle,
  darkBlueButtonStyle,
  dullGreenButtonStyle,
  tealButtonStyle,
} from "../constants/styles";
import { std, min, max, median, clone } from "mathjs";
import { capitalizeFirstCharacter } from "../../../../utils";
import styles from "./QuestionSummary.module.css";
import c from "classnames/bind";
import { defaultAdminQuestion } from "../../criteria/defaultCriteria";
import { Drawer, Space, Collapse, Checkbox, Tabs } from "antd";
const { Panel } = Collapse;
const { TabPane } = Tabs;

const cx = c.bind(styles);

const questionTypes = {
  type1: [
    "questionInstruction",
    "videoInstruction",
    "videoInstruction",
    "textABTest",
    "imageABTest",
    "videoABTest",
  ],
  type2: ["text", "imageComment", "videoComment"],
  type3: [
    "multiChoices",
    "imageMultiChoices",
    "videoMultiChoices",
    "checkBoxes",
    "imageCheckBoxes",
    "videoCheckBoxes",
  ],
  type4: [
    "stars",
    "imageStarRatingChoices",
    "videoStarRatingChoices",
    "likert",
    "dropDown",
  ],
  type6: ["slider"],
  type7: ["netPromotorScore"],
  type8: [
    "ranking",
    "imageRanking",
    "videoRanking",
    "association",
    "imageAssociation",
    "videoAssociation",
  ],
};

const compareTabs = [
  {
    key: "1",
  },
  {
    key: "2",
  },
  {
    key: "3",
  },
];

const getAgeRange = ({ start, end, interval }) => {
  let range = [];
  let element = [];
  for (let i = start; i <= end; i++) {
    element.push(i);
    if (i % interval === 0) {
      range.push({
        label: `${element[0]}-${element[element.length - 1]}`,
        value: element,
      });
      element = [];
    }
  }
  return range;
};

const CollapseFilter = ({
  activeKeys,
  filterAnswers,
  setActiveKeys,
  setFilterAnswers,
  adminQuestions,
  isFilterActive,
}) => {
  return (
    <Collapse
      ghost
      activeKey={activeKeys}
      expandIconPosition="right"
      destroyInactivePanel={true}
      expandIcon={({ isActive }) => {
        return isActive ? (
          <UpCircleFilled style={{ color: "rgb(160, 160, 160)" }} />
        ) : (
          <DownCircleFilled style={{ color: "rgb(160, 160, 160)" }} />
        );
      }}
      onChange={(openKeys) => {
        const addedKeys = difference(openKeys, activeKeys);
        const removedKeys = difference(activeKeys, openKeys);
        const tempAnswers = cloneDeep(filterAnswers);
        for (const key of addedKeys) {
          tempAnswers[key] = [];
        }
        for (const key of removedKeys) {
          delete tempAnswers[key];
        }
        setActiveKeys(openKeys);
        setFilterAnswers(tempAnswers);
      }}
    >
      {adminQuestions &&
        adminQuestions
          .filter(
            (e) =>
              e.isActive === true &&
              e.criteriaType === false &&
              !!e.clientField &&
              isFilterActive.indexOf(e["_id"]) > -1 &&
              [
                "multipleChoice",
                "checkboxes",
                "location",
                "checkboxesSpecial",
                "age",
              ].includes(e.questionObject.questionType)
          )
          .map((e, i) => (
            <Panel
              key={e["_id"]}
              header={capitalizeFirstCharacter(e.clientField)}
              className={cx("panel-container")}
            >
              <Checkbox.Group
                value={filterAnswers[e._id]}
                onChange={(checkedValue) => {
                  const tempAnswers = cloneDeep(filterAnswers);
                  tempAnswers[e._id] = checkedValue;
                  setFilterAnswers(tempAnswers);
                }}
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: 8,
                }}
              >
                {e.questionObject.questionType === "age"
                  ? getAgeRange({
                      start: e.questionObject.start,
                      end: e.questionObject.end,
                      interval: e.questionObject.interval,
                    }).map((op, idx) => (
                      <Checkbox
                        key={`filter-${e._id}-${idx}`}
                        value={op.value.join()}
                        style={{ margin: 0 }}
                      >
                        {op.label}
                      </Checkbox>
                    ))
                  : e.questionObject.options.map((op, idx) => (
                      <Checkbox
                        key={`filter-${e._id}-${idx}`}
                        value={idx}
                        style={{ margin: 0 }}
                      >
                        {op}
                      </Checkbox>
                    ))}
              </Checkbox.Group>
            </Panel>
          ))}
    </Collapse>
  );
};

const QuestionSummary = ({
  surveyData,
  criteria,
  setCriteria,
  adminQuestions,
  setSurveyData,
  questionComponentRef,
  moderatedCallIndex
}) => {
  const [question, setQuestion] = useState(1);
  const [filter, setFilter] = useState(false);
  const [criteriaData, setCriteriaData] = useState(null);
  const [compare, setCompare] = useState(false);

  const [filterAttributes, setFilterAttributes] = useState(
    initFilterAttributes
  );
  const [chartAttributes, setChartAttributes] = useState(initChartAttributes);

  const [survey, setSurvey] = useState(surveyData);

  const [compareTab, setCompareTab] = useState("1");
  const [compareFilter, setCompareFilter] = useState({
    1: [],
    2: [],
    3: [],
  });
  const [compareSurvey1, setCompareSurvey1] = useState([]);
  const [compareSurvey2, setCompareSurvey2] = useState([]);
  const [compareSurvey3, setCompareSurvey3] = useState([]);
  const [compareResponse1, setCompareResponse1] = useState([]);
  const [compareResponse2, setCompareResponse2] = useState([]);
  const [compareResponse3, setCompareResponse3] = useState([]);
  const [criteriaData1, setCriteriaData1] = useState(null);
  const [criteriaData2, setCriteriaData2] = useState(null);
  const [criteriaData3, setCriteriaData3] = useState(null);
  const [compareMode, setCompareMode] = useState(false);
  const [compareChartTitle, setCompareChartTitle] = useState({
    graph1: "",
    graph2: "",
    graph3: "",
  });

  //question Responses
  const [answered, setAnswered] = useState(0);
  const [questionResponses, setQuestionResponses] = useState([]);
  const [isFilterActive, setIsFilterActive] = useState([]);

  //Graph Configurations
  const [axisScale, setAxisScale] = useState(true);
  const [decimalPlaces, setDecimalPlaces] = useState(1);
  const [depth, setDepth] = useState(true);
  const [colors, setColors] = useState(colorConstant);
  const [chartType, setChartType] = useState("");
  const [customize, setCustomize] = useState(false);

  const [activeKeys, setActiveKeys] = useState([]);
  const [filterAnswers, setFilterAnswers] = useState({});

  const [compareActiveKeys, setCompareActiveKeys] = useState({
    1: [],
    2: [],
    3: [],
  });
  const [compareFilterAnswers, setCompareFilterAnswers] = useState({
    1: {},
    2: {},
    3: {},
  });

  //Clone Configuration
  const [cloneConfiguration, setCloneConfiguration] = useState({
    axisScale: true,
    decimalPlaces: 1,
    depth: true,
    colors: colorConstant,
    chartType: "",
    compareChartTitle,
  });

  const onSaveOptions = () => {
    setCloneConfiguration({
      axisScale,
      decimalPlaces,
      depth,
      colors,
      chartType,
      compareChartTitle,
    });
    setCustomize(false);
  };

  useEffect(() => {
    const questionBox = questionResponses[question - 1];
    if (questionBox) {
      const tempChartType =
        graphTypes.type1.indexOf(questionBox.type) > -1 ||
        graphTypes.type2.indexOf(questionBox.type) > -1
          ? ""
          : "bar";
      setAxisScale(true);
      setDepth(true);
      setChartType(tempChartType);
      setCloneConfiguration({
        ...cloneConfiguration,
        axisScale: true,
        depth: true,
        chartType: tempChartType,
      });
    }
  }, [question]);

  useEffect(() => {
    setSurvey(surveyData);
    if (surveyData) {
      const responses = surveyData.responses;
      const qArray = [];
      if (surveyData.criteria.length === 0) {
        setIsFilterActive(defaultAdminQuestion);
      } else {
        surveyData.criteria.map((c, index) => {
          qArray.push(c.questionId);
        });
        defaultAdminQuestion.map((questionId, index) => {
          if (qArray.indexOf(questionId) === -1) {
            qArray.push(questionId);
          }
        });
        setIsFilterActive(qArray);
      }
      setQuestion(1);
      resetFilterAndCompare();
    }
  }, [surveyData]);

  useEffect(() => {
    getFilteredUsers();
  }, [criteria]);

  useEffect(() => {
    if (filter) {
      setActiveKeys(criteria.map((c) => c.questionId));
      const filters = {};
      for (const { questionId, answer } of criteria) {
        filters[questionId] = [
          ...answer.map((a) => (Array.isArray(a) ? a.join() : a)),
        ];
      }
      setFilterAnswers(filters);
    }
  }, [criteria, filter]);

  useEffect(() => {
    if (compare) {
      for (const key in compareFilter) {
        setCompareActiveKeys((prevValue) => ({
          ...prevValue,
          [key]: compareFilter[key].map((cf) => cf.questionId),
        }));
        const filters = {};
        for (const { questionId, answer } of compareFilter[key]) {
          filters[questionId] = [
            ...answer.map((a) => (Array.isArray(a) ? a.join() : a)),
          ];
        }
        setCompareFilterAnswers((prevValue) => ({
          ...prevValue,
          [key]: filters,
        }));
      }
    }
  }, [compare, compareFilter]);

  useEffect(() => {
    initiateCompare();
  }, [compareFilter]);

  useEffect(() => {
    if (survey) {
      if (!survey.surveyAccess && survey.surveyType && survey.mode && survey.qualitativeData[0]) {
        setAnswered(
          survey.qualitativeData[0].responses.filter(
            (a) => a.answers.length > 0
          ).length
        );
        const questions = survey.qualitativeData[0].questions;
        const responses = survey.qualitativeData[0].responses;
        setQuestionResponses(
          getQuestionResponses(
            questions,
            responses.filter((r) => r.answers.length > 0)
          )
        );
        return;
      } else if(survey.surveyAccess && survey.mode && survey.surveyType) {
      const questions = survey.qualitativeData[moderatedCallIndex]?.questions;
      const responses = survey.qualitativeData[moderatedCallIndex]?.responses;
      setQuestionResponses(
        getQuestionResponses(
          questions,
          responses.filter((r) => r.answers.length > 0)
        )
      );
      setAnswered(responses.filter((a) => a.answers.length > 0).length)
      return
      }
      setAnswered(survey.responses.filter((a) => a.answers.length > 0).length);
      const questions = survey.questions;
      const responses = survey.responses;
      setQuestionResponses(
        getQuestionResponses(
          questions,
          responses.filter((r) => r.answers.length > 0)
        )
      );
    }
  }, [survey, moderatedCallIndex]);

  const getQuestionResponses = (questions, responses) => {
    return questions.map((question, questionIndex) => {
      if (!question) {
        return;
      }
      const questionData = { ...question };
      switch (true) {
        case questionTypes.type1.indexOf(question.type) > -1:
          break;
        case questionTypes.type2.indexOf(question.type) > -1:
          questionData.comments = responses.map((userAnswer, index) => {
            return userAnswer.answers[questionIndex];
          });
          break;
        case questionTypes.type3.indexOf(question.type) > -1:
          switch (true) {
            case ["multiChoices", "checkBoxes"].indexOf(question.type) > -1:
              questionData.options = questionData.options.multiChoices.map(
                (choice) => {
                  return { ...choice, answered: 0, label: choice.label };
                }
              );
              break;
            case ["imageMultiChoices", "imageCheckBoxes"].indexOf(
              question.type
            ) > -1:
              questionData.options = questionData.options.imageMultiChoices.map(
                (choice) => {
                  return {
                    ...choice,
                    answered: 0,
                    label: choice.imageLabel,
                  };
                }
              );
              break;
            case ["videoMultiChoices", "videoCheckBoxes"].indexOf(
              question.type
            ) > -1:
              questionData.options = questionData.options.videoMultiChoices.map(
                (choice) => {
                  return {
                    ...choice,
                    answered: 0,
                    label: choice.videoLabel,
                  };
                }
              );
          }
          if (
            ["checkBoxes", "imageCheckBoxes", "videoCheckBoxes"].indexOf(
              question.type
            ) > -1
          ) {
            responses.map((userAnswer, index) => {
              if (userAnswer.answers[questionIndex]) {
                userAnswer.answers[questionIndex].map((answer, innerIndex) => {
                  questionData.options[answer].answered += 1;
                });
              }
            });
          } else {
            responses.map((userAnswer, index) => {
              if (
                questionData.options?.[
                  userAnswer.answers?.[questionIndex]?.[0]
                ] &&
                userAnswer.answers[questionIndex]
              ) {
                questionData.options[
                  userAnswer.answers[questionIndex][0]
                ].answered += 1;
              }
            });
          }
          break;
        case questionTypes.type4.indexOf(question.type) > -1:
          let isComplex = false;
          switch (question.type) {
            case "stars":
              questionData.totalStar = questionData.options.stars;
              questionData.options = [
                {
                  starType: questionData.options.starType,
                  label: "",
                  answered: new Array(questionData.totalStar).fill(0),
                },
              ];
              responses.map((userAnswer, index) => {
                if (userAnswer.answers[questionIndex]) {
                  questionData.options[0].answered[
                    userAnswer.answers[questionIndex][0] - 1
                  ] += 1;
                }
              });
              break;
            case "likert":
              questionData.totalStar = questionData.options.likertRange;
              questionData.options = [
                {
                  label: "",
                  answered: new Array(questionData.totalStar).fill(0),
                },
              ];
              responses.map((userAnswer, index) => {
                if (userAnswer.answers[questionIndex]) {
                  questionData.options[0].answered[
                    userAnswer.answers[questionIndex][0] - 1
                  ] += 1;
                }
              });
              break;
            case "dropDown":
              questionData.totalStar = questionData.options.range;
              questionData.options = [
                {
                  label: "",
                  answered: new Array(questionData.totalStar).fill(0),
                },
              ];
              responses.map((userAnswer, index) => {
                if (userAnswer.answers[questionIndex]) {
                  questionData.options[0].answered[
                    userAnswer.answers[questionIndex][0] - 1
                  ] += 1;
                }
              });
              break;
            case "imageStarRatingChoices":
              questionData.totalStar = questionData.options.imageStar;
              questionData.options = questionData.options.imageStarRatingChoices.map(
                (choice) => {
                  return {
                    ...choice,
                    answered: new Array(questionData.totalStar).fill(0),
                    label: choice.imageLabel,
                  };
                }
              );
              isComplex = true;
              break;
            case "videoStarRatingChoices":
              questionData.totalStar = questionData.options.videoStar;
              questionData.options = questionData.options.videoStarRatingChoices.map(
                (choice) => {
                  return {
                    ...choice,
                    answered: new Array(questionData.totalStar).fill(0),
                    label: choice.videoLabel,
                  };
                }
              );
              isComplex = true;
              break;
          }
          if (isComplex) {
            responses.map((userAnswer, index) => {
              if (userAnswer.answers[questionIndex]) {
                const answers = Object.values(
                  userAnswer.answers[questionIndex][0]
                );
                answers.map((answer, index) => {
                  questionData.options[index].answered[answer - 1] += 1;
                });
              }
            });
          }
          break;
        case questionTypes.type6.indexOf(question.type) > -1:
          const totalSum = responses.reduce((sum, userAnswer) => {
            if (userAnswer.answers[questionIndex]) {
              return sum + userAnswer.answers[questionIndex][0];
            } else {
              return sum;
            }
          }, 0);
          const responseArr = responses.map((userAnswer, index) => {
            if (userAnswer.answers[questionIndex]) {
              return userAnswer.answers[questionIndex][0];
            }
          });
          questionData.options = [
            {
              // min: parseInt(question.options.sliderMin),
              // max: parseInt(question.options.sliderMax),
              min:
                responseArr.length > 0 && responseArr instanceof Number
                  ? min(responseArr)
                  : 0,
              max:
                responseArr.length > 0 && responseArr instanceof Number
                  ? max(responseArr)
                  : 0,
              median:
                responseArr.length > 0 && responseArr instanceof Number
                  ? median(responseArr)
                  : 0,
              sd:
                responseArr.length > 0 && responseArr instanceof Number
                  ? std(responseArr)
                  : 0,
              label: questionData.text,
              answered:
                responseArr.length > 0 ? totalSum / responses.length : 0,
              totalNumber: totalSum,
            },
          ];
          break;
        case questionTypes.type7.indexOf(question.type) > -1:
          questionData.options = [
            {
              label: "Detractors(0-6)",
              answered: 0,
            },
            {
              label: "Passives(7-8)",
              answered: 0,
            },
            {
              label: "Promoters(9-10)",
              answered: 0,
            },
          ];
          responses.map((userAnswer, index) => {
            if (userAnswer.answers[questionIndex]) {
              const userResponse = userAnswer.answers[questionIndex][0];
              switch (true) {
                case userResponse >= 0 && userResponse <= 6:
                  questionData.options[0].answered += 1;
                  break;
                case userResponse === 7 || userResponse === 8:
                  questionData.options[1].answered += 1;
                  break;
                case userResponse === 9 || userResponse === 10:
                  questionData.options[2].answered += 1;
                  break;
              }
            }
          });
          const detractors = Math.round(
            (questionData.options[0].answered / responses.length) * 100
          );
          const promoters = Math.round(
            (questionData.options[2].answered / responses.length) * 100
          );
          questionData.NPS = promoters - detractors;
          break;
        case questionTypes.type8.indexOf(question.type) > -1:
          let isRanking = false;
          let labels = [];
          let label = null;
          let choices = null;
          switch (question.type) {
            case "ranking":
              isRanking = true;
              label = "label";
              choices = "multiChoices";
              break;
            case "imageRanking":
              isRanking = true;
              label = "imageLabel";
              choices = "imageMultiChoices";
              break;
            case "videoRanking":
              isRanking = true;
              label = "videoLabel";
              choices = "videoMultiChoices";
              break;
            case "association":
              isRanking = false;
              label = "label";
              choices = "associationChoices";
              break;
            case "imageAssociation":
              isRanking = false;
              label = "imageData";
              choices = "imageAssociationChoices";
              break;
            case "videoAssociation":
              isRanking = false;
              label = "videoData";
              choices = "videoAssociationChoices";
              break;
          }
          const length = question.options[choices].length;
          questionData.totalStar = length;
          questionData.options = question.options[choices].map(
            (choice, index) => {
              if (isRanking) {
                labels.push(choice[label]);
              } else {
                labels.push(choice.association);
              }
              return {
                ...choice,
                answered: new Array(length).fill(0),
                label: isRanking ? choice[label] : GetFilename(choice[label]),
              };
            }
          );
          responses.map((userAnswer, index) => {
            if (userAnswer.answers[questionIndex]) {
              userAnswer.answers[questionIndex].map((answer, rank) => {
                if (
                  isRanking &&
                  questionData.options[labels.indexOf(answer[label])]
                ) {
                  questionData.options[labels.indexOf(answer[label])].answered[
                    rank
                  ] += 1;
                } else {
                  if (answer.association) {
                    questionData.options[
                      labels.indexOf(answer.association)
                    ].answered[rank] += 1;
                  }
                  questionData.labels = labels;
                }
              });
            }
          });
          break;
      }
      return questionData;
    });
  };

  console.log("Admin Questions", adminQuestions);
  console.log("isFilterActive", isFilterActive);

  // From: https://stackoverflow.com/questions/511761/js-function-to-get-filename-from-url
  function GetFilename(url) {
    if (url) {
      var m = url.toString().match(/.*\/(.+?)\./);
      if (m && m.length > 1) {
        return m[1];
      }
    }
    return url;
  }

  const getQuestion = (id) => {
    return adminQuestions.filter((e) => e["_id"] === id)[0];
  };

  const getFilteredUsers = () => {
    if(!surveyData.surveyAccess){
    const users = surveyData.responses.map((response) => response.userId);
    const finalUsers = filterUsers(criteria, users);
    const userIds = finalUsers.map((user, index) => user._id);
    console.log("refinedUserIds", userIds, criteria);
    const refinedSurvey = { ...surveyData };
    refinedSurvey.responses = surveyData.responses.filter(
      (response, index) => userIds.indexOf(response.userId._id) > -1
    );
    setCriteriaData(prepareFilterCriteriaData(criteria));
    setSurvey(refinedSurvey);
    } else {
      setSurvey({...surveyData})
    }
  };

  const applyCriteria = () => {
    const newCriteria = [];
    for (const key of activeKeys) {
      if (filterAnswers[key].length === 0) {
        continue;
      }
      const isAge =
        adminQuestions.find((q) => q._id === key)?.questionObject
          ?.questionType === "age";
      const answer = filterAnswers[key].map((a) =>
        isAge ? a.split(",").map((age) => parseInt(age, 10)) : a
      );
      newCriteria.push({
        questionId: key,
        answer,
      });
    }

    setCriteria(newCriteria);
  };

  const applyCompareFilter = () => {
    for (const index in compareFilter) {
      const newFilter = [];
      for (const key of compareActiveKeys[index]) {
        if (compareFilterAnswers[index][key].length === 0) {
          continue;
        }
        const isAge =
          adminQuestions.find((q) => q._id === key)?.questionObject
            ?.questionType === "age";
        const answer = compareFilterAnswers[index][key].map((a) =>
          isAge ? a.split(",").map((age) => parseInt(age, 10)) : a
        );
        newFilter.push({
          questionId: key,
          answer,
        });
      }
      setCompareFilter((prevValue) => ({ ...prevValue, [index]: newFilter }));
    }
  };

  const initiateCompare = () => {
    const users = surveyData.responses.map((response) => response.userId);
    const survey1 = getRefinedSurvey(compareFilter["1"], users);
    const survey2 = getRefinedSurvey(compareFilter["2"], users);
    const survey3 = getRefinedSurvey(compareFilter["3"], users);
    setCompareSurvey1(survey1);
    setCompareSurvey2(survey2);
    setCompareSurvey3(survey3);
    setCriteriaData1(prepareFilterCriteriaData(compareFilter["1"]));
    setCriteriaData2(prepareFilterCriteriaData(compareFilter["2"]));
    setCriteriaData3(prepareFilterCriteriaData(compareFilter["3"]));
    setCompareResponse1(
      getQuestionResponses(survey1.questions, survey1.responses)
    );
    setCompareResponse2(
      getQuestionResponses(survey2.questions, survey2.responses)
    );
    setCompareResponse3(
      getQuestionResponses(survey3.questions, survey3.responses)
    );
  };

  const prepareFilterCriteriaData = (criteria) => {
    let filterData = [];
    criteria.map((c, index) => {
      const question = getQuestion(c.questionId);
      if (question.questionObject.questionType === "age") {
        const answers = c.answer.map((ageArray, index) => {
          return ageArray[0] + "-" + ageArray[ageArray.length - 1];
        });
        filterData.push({
          fieldName: question.clientField,
          selectedValues: answers,
        });
      } else {
        const answers = c.answer.map((value, index) => {
          return question.questionObject.options[value];
        });
        filterData.push({
          fieldName: question.clientField,
          selectedValues: answers,
        });
      }
    });
    return filterData;
  };

  const getRefinedSurvey = (criteria, users) => {
    if(!surveyData.surveyAccess){
    const finalUsers = filterUsers(criteria, users);
    const userIds = finalUsers.map((user, index) => user._id);
    const refinedSurvey = { ...surveyData };
    refinedSurvey.responses = surveyData.responses.filter(
      (response, index) => userIds.indexOf(response.userId._id) > -1
    );
    return refinedSurvey;
    } else {
      return {...surveyData}
    }
  };

  const resetFilterAndCompare = () => {
    setCompareMode(false);
    setCriteria([]);
    setCompareFilter({
      1: [],
      2: [],
      3: [],
    });
    setCompare(false);
    setFilter(false);
    setCriteriaData(null);
    setCriteriaData1(null);
    setCriteriaData2(null);
    setCriteriaData3(null);
    setSurvey(surveyData);
  };

  console.log("Criteria", criteria);

  return (
    <Fragment>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "30% 68%",
          columnGap: "15px",
        }}
      >
        <div className={cx("grey-container")} style={{ height: "fit-content" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <div style={{ fontWeight: "bold", margin: "auto 0" }}>
              Questions
            </div>
            { !surveyData.surveyAccess && 
            <div
              style={{
                display: "flex",
                backgroundColor: "#B9E8F6",
                gap: "16px",
                borderRadius: "5px",
                padding: "8px",
              }}
            >
              <RollbackOutlined
                onClick={resetFilterAndCompare}
                style={{ fontSize: 14, margin: "auto" }}
              />
              <UsergroupAddOutlined
                onClick={() => {
                  setCompare(!compare);
                  setFilter(false);
                }}
                style={{ fontSize: 14, margin: "auto" }}
              />
              <FilterFilled
                style={{ fontSize: 14, margin: "auto" }}
                onClick={() => {
                  setFilter(!filter);
                  setCompare(false);
                }}
              />
            </div>
            }
          </div>
          <div
            style={{
              paddingRight: "8px",
              maxHeight: "450px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {questionResponses.map((qstn, index) => {
              return (
                <div
                  className={
                    question == index + 1
                      ? cx("question-container-selected")
                      : cx("question-container")
                  }
                  onClick={() => setQuestion(index + 1)}
                >
                  <div
                    style={{
                      padding: "0px 10px 0px 10px",
                      opacity: "60%",
                      fontWeight: "bold",
                      fontSize: "1.25rem",
                    }}
                  >
                    {index + 1}
                  </div>
                  <div
                  // style={{
                  //   whiteSpace: "nowrap",
                  //   textOverflow: "ellipsis",
                  //   overflow: "hidden",
                  //   paddingRight: "10px",
                  // }}
                  >
                    {qstn.text}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={cx("grey-container")}
          style={{
            minHeight: "450px",
            height: "fit-content",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div>
              {/* <Button
                style={
                  questionResponses && questionResponses[question - 1]
                    ? tealButtonStyle
                    : greyButtonStyle
                }
              >
                {`Question #${question}`}
              </Button> */}
              {/* <Button
                style={
                  !(questionResponses && questionResponses[question - 1]) ||
                  question === 1
                    ? greyButtonStyle
                    : darkBlueButtonStyle
                }
                disabled={
                  !(questionResponses && questionResponses[question - 1]) ||
                  question === 1
                }
                onClick={() => {
                  if (question > 1) {
                    setQuestion(question - 1);
                  }
                }}
                icon={<CaretLeftOutlined />}
              />
              <Button
                style={
                  !(questionResponses && questionResponses[question - 1]) ||
                  question === questionResponses.length
                    ? greyButtonStyle
                    : darkBlueButtonStyle
                }
                disabled={
                  !(questionResponses && questionResponses[question - 1]) ||
                  question === questionResponses.length
                }
                onClick={() => {
                  if (true) {
                    setQuestion(question + 1);
                  }
                }}
                icon={<CaretRightOutlined />}
              /> */}
            </div>
          </div>
          <Drawer
            visible={filter}
            onClose={() => setFilter(false)}
            closable={false}
            headerStyle={{ padding: "8px 24px" }}
            title={"Filter"}
            destroyOnClose={true}
            width={340}
            extra={
              <Space>
                <Button
                  type="text"
                  style={{ color: "rgb(0, 35, 63)" }}
                  onClick={() => setFilter(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="text"
                  style={{ color: "rgb(0, 35, 63)" }}
                  onClick={() => {
                    applyCriteria();
                    setFilter(false);
                  }}
                >
                  Apply
                </Button>
              </Space>
            }
          >
            <CollapseFilter
              activeKeys={activeKeys}
              filterAnswers={filterAnswers}
              setActiveKeys={setActiveKeys}
              setFilterAnswers={setFilterAnswers}
              adminQuestions={adminQuestions}
              isFilterActive={isFilterActive}
            />
          </Drawer>
          <Drawer
            visible={compare}
            onClose={() => setCompare(false)}
            closable={false}
            headerStyle={{ padding: "8px 24px" }}
            bodyStyle={{ paddingTop: 0 }}
            title={"Compare"}
            destroyOnClose={true}
            width={340}
            extra={
              <Space>
                <Button
                  type="text"
                  style={{ color: "rgb(0, 35, 63)" }}
                  onClick={() => setCompare(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="text"
                  style={{ color: "rgb(0, 35, 63)" }}
                  onClick={() => {
                    applyCompareFilter();
                    setCompare(false);
                    setCompareMode(true);
                  }}
                >
                  Apply
                </Button>
              </Space>
            }
          >
            <Tabs activeKey={compareTab} onChange={setCompareTab}>
              {compareTabs.map((tab) => (
                <TabPane tab={`Option ${tab.key}`} key={tab.key}>
                  <CollapseFilter
                    adminQuestions={adminQuestions}
                    isFilterActive={isFilterActive}
                    activeKeys={compareActiveKeys[tab.key]}
                    filterAnswers={compareFilterAnswers[tab.key]}
                    setActiveKeys={(newActiveKeys) => {
                      setCompareActiveKeys((prevValue) => {
                        return { ...prevValue, [tab.key]: newActiveKeys };
                      });
                    }}
                    setFilterAnswers={(newFilterAnswers) => {
                      setCompareFilterAnswers((prevValue) => {
                        return { ...prevValue, [tab.key]: newFilterAnswers };
                      });
                    }}
                    key={`collapseFilter-${tab.key}`}
                  />
                </TabPane>
              ))}
            </Tabs>
          </Drawer>
          <div ref={questionComponentRef}>
            {!compareMode &&
              questionResponses &&
              questionResponses[question - 1] && (
                <QuestionBox
                  questionResponses={questionResponses}
                  setQuestion={setQuestion}
                  keyInfo="Graph"
                  criteriaData={criteriaData}
                  axisScale={axisScale}
                  setAxisScale={setAxisScale}
                  decimalPlaces={decimalPlaces}
                  setDecimalPlaces={setDecimalPlaces}
                  depth={depth}
                  setDepth={setDepth}
                  colors={colors}
                  setColors={setColors}
                  chartType={chartType}
                  setChartType={setChartType}
                  onSaveOptions={onSaveOptions}
                  cloneConfiguration={cloneConfiguration}
                  questionNumber={question}
                  answered={ survey.surveyAccess && survey.mode && survey.surveyType ? answered :
                    survey?.responses?.filter(
                      (r) => r?.answers?.[question - 1]?.length > 0
                    )?.length ?? 0
                  }
                  question={questionResponses[question - 1]}
                  chartAttributes={chartAttributes}
                  setChartAttributes={setChartAttributes}
                  customize={customize}
                  setCustomize={setCustomize}
                />
              )}
            {compareMode && (
              <Fragment>
                {compareResponse1 && compareResponse1[question - 1] && (
                  <QuestionBox
                    questionResponses={questionResponses}
                    setQuestion={setQuestion}
                    key={1}
                    keyInfo="CompareGraph1"
                    compareMode={compareMode}
                    criteriaData={criteriaData1}
                    axisScale={axisScale}
                    setAxisScale={setAxisScale}
                    decimalPlaces={decimalPlaces}
                    setDecimalPlaces={setDecimalPlaces}
                    compareChartTitle={compareChartTitle}
                    setCompareChartTitle={setCompareChartTitle}
                    depth={depth}
                    setDepth={setDepth}
                    colors={colors}
                    setColors={setColors}
                    chartType={chartType}
                    setChartType={setChartType}
                    questionNumber={question}
                    graphTitle={cloneConfiguration.compareChartTitle.graph1}
                    onSaveOptions={onSaveOptions}
                    cloneConfiguration={cloneConfiguration}
                    answered={
                      compareSurvey1?.responses?.filter(
                        (r) => r?.answers?.[question - 1]?.length > 0
                      )?.length ?? 0
                    }
                    question={compareResponse1[question - 1]}
                    chartAttributes={chartAttributes}
                    setChartAttributes={setChartAttributes}
                    customize={customize}
                    setCustomize={setCustomize}
                  />
                )}
                {compareResponse2 && compareResponse2[question - 1] && (
                  <QuestionBox
                    questionResponses={questionResponses}
                    setQuestion={setQuestion}
                    key={2}
                    keyInfo="CompareGraph2"
                    criteriaData={criteriaData2}
                    cloneConfiguration={cloneConfiguration}
                    questionNumber={question}
                    graphTitle={cloneConfiguration.compareChartTitle.graph2}
                    answered={
                      compareSurvey2?.responses?.filter(
                        (r) => r?.answers?.[question - 1]?.length > 0
                      )?.length ?? 0
                    }
                    question={compareResponse2[question - 1]}
                    chartAttributes={chartAttributes}
                    setChartAttributes={setChartAttributes}
                  />
                )}
                {compareResponse3 && compareResponse3[question - 1] && (
                  <QuestionBox
                    questionResponses={questionResponses}
                    setQuestion={setQuestion}
                    key={3}
                    keyInfo="CompareGraph3"
                    criteriaData={criteriaData3}
                    cloneConfiguration={cloneConfiguration}
                    questionNumber={question}
                    graphTitle={cloneConfiguration.compareChartTitle.graph3}
                    answered={
                      compareSurvey3?.responses?.filter(
                        (r) => r?.answers?.[question - 1]?.length > 0
                      )?.length ?? 0
                    }
                    question={compareResponse3[question - 1]}
                    chartAttributes={chartAttributes}
                    setChartAttributes={setChartAttributes}
                  />
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default QuestionSummary;
