import React, { useState } from "react";
import "./question-textbox.css";
import { Input, Row, Col, Select, Button, Form, Tooltip } from "antd";
import { CheckOutlined, InfoCircleOutlined } from "@ant-design/icons";

const QuestionTextbox = ({
  selectOptions,
  isOptionSelected,
  buttonText,
  onButtonClick,
  isButtonActive,
  onSelectChange,
}) => {
  const [value, setValue] = useState();
  return (
    <Row
      gutter={[6, 6]}
      style={{
        marginTop: 20,
        paddingBottom: 20,
      }}
      justify="center"
    >
      <Col style={{ flexGrow: 1 }}>
        <Form.Item
          name="userField"
          rules={[{ required: true, message: "Please enter some value!" }]}
        >
          <Input.TextArea
            rows={3}
            placeholder="New question"
            onChange={(e) => setValue(e.target.value)}
            style={{ resize: "none", paddingRight: 20 }}
            className="input-border"
          />
        </Form.Item>
        <span style={{ position: "absolute", top: 3, right: 8, fontSize: 16 }}>
          {value ? (
            <CheckOutlined style={{ color: "green" }} />
          ) : (
            <Tooltip title="Please enter a question!">
              <InfoCircleOutlined style={{ color: "red" }} />
            </Tooltip>
          )}
        </span>
      </Col>
      <Col className="actions-column">
        <Form.Item
          name={["questionObject", "questionType"]}
          rules={[{ required: true, message: "Please select a type!" }]}
          style={{ marginBottom: 12 }}
        >
          <Select
            placeholder="Question type"
            className={isOptionSelected ? "select-background" : ""}
            onChange={onSelectChange}
            style={{ width: "100%" }}
          >
            {selectOptions.map((opt) => (
              <Select.Option
                key={opt.value}
                value={opt.value}
                className="select-options"
              >
                {opt.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button
          block
          type={isButtonActive ? "primary" : "default"}
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      </Col>
    </Row>
  );
};

export default QuestionTextbox;
