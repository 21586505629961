import React, { useState } from "react";
import { Input } from "antd";
import Lightbox from "react-awesome-lightbox";
import { useMediaQuery } from "react-responsive";

const ImageComment = ({ key, options, value, onChange = () => {} }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [zoom, setZoom] = useState(false);

  return (
    <div key={key} style={{ textAlign: "center" }}>
      <img
        src={options.imageData}
        style={{
          width: "80%",
          height: "auto",
          borderRadius: 5,
          cursor: "pointer",
        }}
        onClick={() => setZoom(true)}
      />
      <br />
      <br />
      <span>{options.imageLabel}</span>
      {zoom && (
        <Lightbox
          image={options.imageData}
          onClose={() => setZoom(false)}
          allowRotate={false}
          showTitle={false}
          doubleClickZoom={2}
        />
      )}
      <Input.TextArea
        defaultValue={value}
        rows={5}
        placeholder="Please write your answer here"
        style={{
          maxWidth: 800,
          marginTop: 20,
          resize: "none",
          backgroundColor: "#0A3157",
          color: "white",
          borderRadius: 5,
          borderColor: "#0A3157",
        }}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default ImageComment;
