import { set } from "automate-redux";
import axios, { Routes } from "../services/API";
import { getUserId } from "../utils";

// Profile
export const getProfile = () => async (dispatch) => {
  const userId = getUserId();
  try {
    const { data } = await axios({
      ...Routes.user.getProfile(userId),
    });
    dispatch(setProfile(data.user));
  } catch (err) {
    throw err.toString();
  }
};

export const editProfile = (values) => async (dispatch) => {
  const userId = getUserId();
  try {
    const { data } = await axios({
      ...Routes.user.editProfile(userId),
      data: { data: values },
    });
    dispatch(setProfile(data.user));
  } catch (err) {
    throw err.toString();
  }
};

// Surveys
export const getSurveys = () => async (dispatch) => {
  const userId = getUserId();
  try {
    const { data } = await axios({ ...Routes.user.getSurveys(userId) });
    dispatch(
      setSurveys(
        data.surveys.filter(
          (item) => item.responses.length !== item.numberOfParticipants
        )
      )
    );
  } catch (err) {
    throw err.toString();
  }
};

export const startSurvey = (surveyId, surveyAccess) => async () => {
  const userId = getUserId();
  if (!surveyAccess) {
    try {
      await axios({ ...Routes.user.startSurvey(userId, surveyId) });
    } catch (err) {
      throw err.toString();
    }
  } else {
    console.log("survey started");
    try {
      await axios({ ...Routes.user.startOpenSurvey(surveyId) });
    } catch (err) {
      throw err.toString();
    }
  }
};

export const removeSurvey = (surveyId) => async (dispatch, getState) => {
  const userId = getUserId();
  try {
    await axios({ ...Routes.user.removeSurvey(userId, surveyId) });
    dispatch(
      setSurveys(getState().user.surveys.filter((val) => val._id !== surveyId))
    );
  } catch (err) {
    throw err.toString();
  }
};

export const completeSurvey = (surveyId, surveyAccess, response) => async (
  dispatch,
  getState
) => {
  console.log("responses:", response);
  const userId = getUserId();
  if (userId || !surveyAccess) {
    try {
      await axios({
        ...Routes.user.completeSurvey(userId, surveyId),
        data: { data: { response } },
      }).then((data) => console.log(data));
      if (!window.location.pathname.includes("attemptSurvey")) {
        dispatch(
          setSurveys(
            getState().user.surveys.filter((val) => val._id !== surveyId)
          )
        );
      }
    } catch (err) {
      throw err.toString();
    }
  } else {
    console.log("survey completed");
    try {
      await axios({
        ...Routes.user.completeOpenSurvey(surveyId),
        data: { data: { response } },
      }).then((data) => console.log(data));
    } catch (err) {
      throw err.toString();
    }
  }
};

export const confirmTimeSlot = (surveyId, data) => async (dispatch) => {
  const userId = getUserId();
  try {
    await axios({
      ...Routes.user.confirmTimeSlot(userId, surveyId),
      data: { data: { ...data } },
    });
    dispatch(getSurveys());
  } catch (err) {
    throw err.toString();
  }
};

// Email
export const sendEmail = (email) => async (dispatch) => {
  try {
    await axios({
      ...Routes.verification.sendEmail(email, "user"),
    });
    dispatch(editProfile({ email }));
  } catch (err) {
    throw err.toString();
  }
};

// OTP
export const sendOtp = (mobileNumber) => async () => {
  try {
    await axios({
      ...Routes.verification.sendOTP(mobileNumber),
    });
  } catch (err) {
    throw err.toString();
  }
};

export const verifyOtp = (mobileNumber, OTP) => async (dispatch) => {
  try {
    await axios({
      ...Routes.verification.verifyOTP(),
      data: {
        data: { mobileNumber, OTP, role: "user", isMobileNumberVerified: true },
      },
    });
    dispatch(editProfile({ mobileNumber, isMobileNumberVerified: true }));
  } catch (err) {
    throw err.toString();
  }
};

// Profile adminQuestions
export const getProfileQuestions = (displayAt) => async (dispatch) => {
  try {
    const { data } = await axios({
      ...Routes.others.adminQuestions(),
    });
    const activeProfileQuestions = data.adminQuestions.filter(
      (val) => val.isActive && displayAt.includes(val.displayAt)
    );
    if (displayAt === "sampleQuestions") {
      if (activeProfileQuestions.length === 0) {
        dispatch(
          editProfile({
            areQuestionsAnswered: {
              registration: true,
              sample: true,
              additional: false,
            },
          })
        );
        return;
      }
      const sampleQuestionsArray = activeProfileQuestions.map(
        ({ questionObject, userField }) => {
          switch (questionObject.questionType) {
            case "multipleChoice":
              return {
                text: userField,
                type: "multiChoices",
                options: {
                  multiChoices: questionObject.options.map((label) => ({
                    label,
                  })),
                },
              };
            case "checkboxes":
              return {
                text: userField,
                type: "checkBoxes",
                options: {
                  multiChoices: questionObject.options.map((label) => ({
                    label,
                  })),
                },
              };
            case "checkboxesSpecial":
              return {
                text: userField,
                type: "checkboxesSpecial",
                options: {
                  multiChoices: questionObject.options,
                  specialField: questionObject.specialField,
                },
              };
            case "location":
              return {
                text: userField,
                type: "multiChoices",
                options: {
                  multiChoices: questionObject.options.map((label) => ({
                    label,
                  })),
                },
              };
            case "age":
              return {
                text: userField,
                type: "age",
                options: {
                  start: questionObject.start,
                  end: questionObject.end,
                  interval: questionObject.interval,
                },
              };
            default:
              return;
          }
        }
      );
      const sampleSurveyObject = {
        userType: false,
        questions: sampleQuestionsArray,
        paymentType: false,
        paymentValue: 0,
        screeningQuestions: [],
        mode: false,
        name: "Sample Survey",
        surveyType: false,
      };
      dispatch(setSurveys([sampleSurveyObject]));
    } else {
      dispatch(setProfileQuestions(activeProfileQuestions));
    }
  } catch (err) {
    throw err.toString();
  }
};

// Setters
const setProfile = (data) => set(`user.profile`, data);
const setProfileQuestions = (data) => set(`user.profileQuestions`, data);
const setSurveys = (data) => set(`user.surveys`, data);
