import { Button } from "antd";
import React, { Fragment } from "react";
import { useState } from "react";
import TimeSlot from "../../../../pages/user/time-slot/TimeSlot";
import { getUserId } from "../../../../utils";
import { surveyTypes, times } from "../../constants/constants";
import { useCancelCall } from "../../hooks/qualSurvey/moderatedSurvey/useCancelCall";
import {
  getScheduledSlotForUser,
  getScheduledSlotForUserTimeStamp,
  isSlotChangeDisabled,
} from "../../utils/moderated/schedule";
import { hasScreeningQuestions } from "../../utils/screeningUtils";
import { getSurveyType } from "../../utils/utils";

const buttonStyle = {
  backgroundColor: "#71cfd7",
  color: "white",
  borderRadius: 10,
};

const getDateAndTimeString = (date, time) => {
  return " at " + times.find((x) => x.value === time).label + " on " + date;
};

function SurveyStartOptions({ survey, handleStartSurveyClick, handleCancel }) {
  const [showTimeSlot, setShowTimeSlot] = useState(false);
  const hasScreeningTest = hasScreeningQuestions(survey);
  const surveyType = getSurveyType(survey);
  const cancelCall = useCancelCall();
  const { surveyDate, surveyTime } = getScheduledSlotForUser(
    survey.moderatedSlots,
    getUserId()
  );
  const scheduledCallTimeStamp = getScheduledSlotForUserTimeStamp(
    survey.moderatedSlots,
    getUserId()
  );

  const handleCancelCall = () => {
    handleCancel();
    cancelCall(survey._id);
  };

  const handleAvailability = () => {
    handleCancel();
    setShowTimeSlot(true);
  };

  return (
    <Fragment>
      {hasScreeningTest && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            size="large"
            className="start-survey"
            style={buttonStyle}
            onClick={handleStartSurveyClick}
          >
            Start Screening
          </Button>
        </div>
      )}

      {!hasScreeningTest && surveyType !== surveyTypes.QUALITATIVE_MODERATED && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            size="large"
            className="start-survey"
            style={buttonStyle}
            onClick={handleStartSurveyClick}
          >
            Start Survey
          </Button>
        </div>
      )}

      {!hasScreeningTest && surveyType === surveyTypes.QUALITATIVE_MODERATED && (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px 0",
            }}
          >
            <div style={{ width: "100%", textAlign: "center" }}>
              <Button
                size="large"
                className="start-survey"
                style={{
                  backgroundColor: "#FF9480",
                  color: "white",
                  marging: "auto",
                  borderRadius: 10,
                }}
                onClick={handleAvailability}
                disabled={isSlotChangeDisabled(
                  survey.moderatedSlots,
                  getUserId()
                )}
              >
                {!!scheduledCallTimeStamp
                  ? "Change availability"
                  : "Share availability"}
              </Button>
            </div>
            <div style={{ width: "100%", textAlign: "center" }}>
              <Button
                size="large"
                className="start-survey"
                style={{
                  backgroundColor: "#667E92",

                  color: "white",
                  borderRadius: 10,
                }}
                onClick={handleCancelCall}
                disabled={
                  !scheduledCallTimeStamp ||
                  isSlotChangeDisabled(survey.moderatedSlots, getUserId())
                }
              >
                Cancel Call
              </Button>
            </div>
            {scheduledCallTimeStamp && (
              <div style={{ width: "100%", textAlign: "center" }}>
                <Button
                  size="large"
                  className="start-survey"
                  style={{
                    backgroundColor:
                      scheduledCallTimeStamp > new Date() ||
                      !survey.surveyScheduledTimeStamp
                        ? "#BAE7EA"
                        : "#71cfd7",
                    color: "white",
                    borderRadius: 10,
                  }}
                  onClick={handleStartSurveyClick}
                  // disabled={
                  //   getSelectedSlotDateObject() >
                  //     new Date().setMinutes(new Date().getMinutes() + 5) ||
                  //   !survey.surveyScheduledTimeStamp
                  // }
                >
                  Start Survey
                  {surveyDate &&
                    surveyTime &&
                    getDateAndTimeString(surveyDate, surveyTime)}
                </Button>
              </div>
            )}
          </div>
          {showTimeSlot && (
            <TimeSlot
              isOpen={showTimeSlot}
              onClose={() => setShowTimeSlot(false)}
              slots={survey.moderatedSlots}
              surveyId={survey._id}
              setting={survey.setting}
              prevSlot={{ date: surveyDate, time: surveyTime }}
            />
          )}
        </React.Fragment>
      )}
    </Fragment>
  );
}

export default SurveyStartOptions;
