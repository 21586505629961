const intersection = (s1, s2) => {
  return [...s1].some((s) => s2.has(s));
};
const isMatch = (filters, criteria) => {
  for (const filter in filters) {
    if (
      !intersection(
        filters[filter],
        criteria[filter] ? criteria[filter] : new Set()
      )
    )
      return false;
  }
  return true;
};

const filteredUsers = (criteria, users) => {
  if(!criteria?.length) return users
  let criteriaFilter = {};
  criteria.forEach((question) => {
    if (question.answer.length > 0 && !Array.isArray(question.answer[0])) {
      criteriaFilter[question.questionId] = new Set(question.answer);
    } else if (Array.isArray(question.answer[0])) {
      let arr = [];
      question.answer.map((ans, index) => {
        ans.map((a, i) => arr.push(a));
      });
      criteriaFilter[question.questionId] = new Set(arr);
    }
  });
  return users
    .map((user) => {
      let adminQuestionsAnswers = {};
      user.adminQuestionsAnswers.forEach((question) => {
        adminQuestionsAnswers[question.questionId] = new Set(question.answer);
      });
      return { _id: user._id, adminQuestionsAnswers, email: user.email };
    })
    .filter((user) => isMatch(criteriaFilter, user.adminQuestionsAnswers));
};

export default filteredUsers;
