import React from "react";
import "./confused.css";
import { Button } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";

const Confused = ({ style }) => {
  return (
    <Button className="explainer-video-btn" style={{ marginTop: "5%" }}>
      Explainer Video
    </Button>
    // <Button className="confused-video-button">
    //   <div className="video-content-container">
    //     <span className="confused-video-button-text">Explainer Video</span>
    //     <PlayCircleOutlined style={{ fontSize: "200%", color: "#FF9480" }} />
    //   </div>
    // </Button>
  );
};

export const ExplainerVideo = () => {
  return <Button className="explainer-video-btn">Explainer Video</Button>;
};

export default Confused;
