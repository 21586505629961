import React from "react";
import styles from "./MultiChoiceOptions.module.css";
import c from "classnames/bind";
import {
  CheckOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Input, Tooltip, Checkbox } from "antd";

const cx = c.bind(styles);

const MultiChoiceOptions = (props) => {
  // Props
  const {
    dragHandleProps,
    choiceNumber,
    choice,
    isFilled,
    addChoice,
    removeChoice,
    totalChoices,
    setLabel,
    checkBox = false,
    setMcq,
  } = props;

  return (
    <div className={cx("grid-container")} style={{ marginBottom: 5 }}>
      <div className={cx("choiceNumber")}>
        <div
          style={{
            margin: "auto",
            height: 20,
            width: 20,
            textAlign: "center",
          }}
        >
          <span
            className="choice-numberCircle"
            style={{ color: "#404040", borderColor: "#404040" }}
          >
            {choiceNumber}
          </span>

          <MenuOutlined className={cx("menu-outlined")} {...dragHandleProps} />
        </div>
      </div>
      <div className={cx("fields")}>
        <Input
          size="large"
          placeholder="Input Option"
          style={{
            borderRadius: 3,
            border: "none",
            boxShadow: "0 1px 2px rgb(0 0 0 / 20%)",
          }}
          value={choice.label}
          onChange={(event) => setLabel(event.target.value)}
          suffix={
            isFilled ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <Tooltip title="You need to fill this option!">
                <InfoCircleOutlined style={{ color: "#FF9480" }} />
              </Tooltip>
            )
          }
          className="input-border"
        />
        <div className={cx("controls")}>
          {
            <div
              style={{ paddingTop: 2, display: "flex", alignItems: "center" }}
            >
              <PlusCircleOutlined
                // style={{  color:"#404040" }}
                onClick={() => addChoice(choiceNumber)}
                className={
                  checkBox ? "action-icon action-icon-checkbox" : "action-icon"
                }
              />
              {totalChoices > 2 && (
                <MinusCircleOutlined
                  // style={{  color:"#404040" }}
                  className={
                    checkBox
                      ? "action-icon action-icon-checkbox"
                      : "action-icon"
                  }
                  onClick={() => removeChoice(choiceNumber)}
                />
              )}
              {checkBox && (
                <Tooltip
                  title="When checked this option will act like a MCQ. Example: 'None of the above'"
                  mouseLeaveDelay={0}
                  mouseEnterDelay={0.3}
                >
                  <Checkbox
                    className={cx("mcq-checkbox")}
                    checked={choice.isMcq}
                    onChange={(e) => setMcq(e.target.checked)}
                  ></Checkbox>
                </Tooltip>
              )}
            </div>
          }
          {/* <div style={{ margin: "auto 0px" }}>
          {checkBox && <Checkbox className="special" />}
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default MultiChoiceOptions;
