export const userPages = {
  FORMS: "forms",
  PROFILE: "profile",
  SETTINGS: "settings",
  HISTORY: "history",
};

export const adminPages = {
  REGQUESTIONS: "registration-questions",
  SAMPLEQUESTIONS: "sample-questions",
  ADDITIONALQUESTIONS: "additional-questions",
  SURVEYS: "surveys",
  INFLUENCERS: "influencers",
  CLIENTS: "clients",
  PRICING: "pricing",
  FEATURES: "features",
};

export const COLOR = {
  darkBlue: "#00233f",
  dullLightGreen: "#00c194",
  ultraLightGrey: "#f2f2f2",
  grey: "#7f7f7f",
  teal: "rgb(192, 225, 250)",
};

export const urlPattern = new RegExp(
  "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
  "i"
);

export const EMAIL_VALIDATION_REGEX = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const graphConfigTemplate = {
  type: "",
  options: {
    title: {
      display: true,
      text: "",
      fontSize: 20,
    },
    legend: {
      display: true,
      position: "bottom",
    },
    scales: {
      xAxes: [
        {
          stacked: false,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          stacked: false,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    tooltips: {
      enabled: false,
    },
    aspectRatio: 1,
  },
  data: {
    labels: [],
    datasets: [
      {
        barPercentage: 0.5,
        minBarLength: 2,
        label: "",
        backgroundColor: [],
        borderColor: "",
        borderWidth: 2,
        barPecentage: 0.5,
        data: "",
      },
    ],
  },
};

export const colorConstant = [
  {
    r: "0",
    g: "193",
    b: "148",
    a: "1",
  },
  {
    r: "0",
    g: "68",
    b: "121",
    a: "1",
  },
  {
    r: "55",
    g: "132",
    b: "165",
    a: "1",
  },
  {
    r: "246",
    g: "200",
    b: "95",
    a: "1",
  },
  {
    r: "111",
    g: "78",
    b: "124",
    a: "1",
  },
  {
    r: "157",
    g: "216",
    b: "102",
    a: "1",
  },
  {
    r: "202",
    g: "71",
    b: "47",
    a: "1",
  },
  {
    r: "243",
    g: "159",
    b: "86",
    a: "1",
  },
  {
    r: "141",
    g: "221",
    b: "208",
    a: "1",
  },
  {
    r: "150",
    g: "150",
    b: "150",
    a: "1",
  },
  {
    r: "150",
    g: "150",
    b: "0",
    a: "1",
  },
];

export const branchingConditions = {
  CONTAINS: "Contains",
  DOES_NOT_CONTAIN: "Does not contain",
  CONTAINS_ONE_OF: "Contains one of",
  DOES_NOT_CONTAIN_ANY_OF: "Does not contain any of",
  CONTAINS_ALL_OF: "Contains all of",
  DOES_NOT_CONTAIN_ALL_OF: "Does not contain all of",
  IS_EXACTLY: "Is exactly",
  IS_NOT: "Is not",
  IS_FROM_TO: "Is from / to",
  RANKING: "Ranking",
  ASSOCIATION: "Association",
  IMAGE_VIDEO_RATINGS: "Image video ratings",
};

export const branchingActions = {
  SKIP_TO_QUESTION: "Skip to question",
  END_SURVEY: "End survey",
  DISQUALIFY_RESPONDENT: "Disqualify respondent",
  HIDE_QUESTION: "Hide question",
  INVALIDATE_QUESTION: "Invalidate question",
};

export const surveyStates = {
  IN_PROGRESS: "inProgress",
  PAUSE: "paused",
  END: "ended",
};

export const questionTemplate = {
  text: "",
  type: "default",
  clientLink: "",
  options: {
    // Star Rating
    stars: 5,
    starType: "star",
    // Likert Rating
    likertRange: 5,
    likertStartLabel: "",
    likertMiddleLabel: "",
    likertEndLabel: "",
    // Slider
    sliderType: "number",
    sliderValue: 1,
    sliderMin: 1,
    sliderMax: 100,
    sliderStartLabel: "",
    sliderMiddleLabel: "",
    sliderEndLabel: "",
    // Net Promotor Score
    npsOption: "This Company",
    // MultiChoice
    multiChoices: [
      {
        label: "",
      },
      {
        label: "",
      },
    ],
    // ImageMultiChoice
    imageMultiChoices: [
      {
        imageLabel: "",
        imageData: "",
      },
      {
        imageLabel: "",
        imageData: "",
      },
    ],
    // VideoMultiChoice
    videoMultiChoices: [
      {
        videoLabel: "",
        videoData: "",
      },
      {
        videoLabel: "",
        videoData: "",
      },
    ],
    // ImageStarRating
    imageStarRatingChoices: [
      {
        imageLabel: "",
        imageData: "",
      },
    ],
    imageStar: 5,
    imageStarType: "star",
    // Dropdown
    range: 5,
    // Question Instruction
    questionInstuction: "",
    // Video Instruction
    videoLabel: "",
    videoData: "",
    // Image Instruction
    imageLabel: "",
    imageData: "",
    // VideoStarRating
    videoStarRatingChoices: [
      {
        videoLabel: "",
        videoData: "",
      },
    ],
    videoStar: 5,
    videoStarType: "star",
    // Text A/B Test
    textABTestChoices: [
      {
        inputWord: "",
        percentage: "",
      },
      {
        inputWord: "",
        percentage: "",
      },
    ],
    // Image A/B Test
    imageABTestChoices: [
      {
        imageLabel: "",
        imageData: "",
        percentage: "",
      },
      {
        imageLabel: "",
        imageData: "",
        percentage: "",
      },
    ],
    // Video A/B Test
    videoABTestChoices: [
      {
        videoLabel: "",
        videoData: "",
        percentage: "",
      },
      {
        videoLabel: "",
        videoData: "",
        percentage: "",
      },
    ],
    // Association
    associationChoices: [
      {
        label: "",
        association: "",
      },
      {
        label: "",
        association: "",
      },
    ],
    imageAssociationChoices: [
      {
        imageData: "",
        association: "",
      },
      {
        imageData: "",
        association: "",
      },
    ],
    videoAssociationChoices: [
      {
        videoData: "",
        association: "",
      },
      {
        videoData: "",
        association: "",
      },
    ],
    surveyBrief: "",
    adAnalysisChoices: [
      {
        videoData: "",
      },
    ],
    adAnalysisData: {
      url: "",
    },
  },
};

export const BASEURL =
  process.env.NODE_ENV === "development"
    ? `https://test.api.userqual.com`
    : `https://api.userqual.com`;

export const convertSecondsIntoStandardFormat = (seconds) => {
  return (
    new Date(seconds * 1000).toISOString().substring(11, 19) +
    `:${Math.floor((seconds % 1) * 100)}`
  );
};

export function GetFilename(url, stripRandomChars = 0) {
  let fileName = url.replace(/^.*[\\\/]/, "");
  if (stripRandomChars > 0) {
    const replaceRegex = new RegExp(`(.{${stripRandomChars}})(\\..+?)$`, "g");
    fileName = fileName.replace(replaceRegex, (...val) => {
      return val[2];
    });
  }
  return fileName;
}

export const convertSecondsToReadable = (seconds) => {
  const secondsString = Math.floor(seconds % 60)
    .toString()
    .padStart(2, "0");
  const minutesString = Math.floor(seconds / 60)
    .toString()
    .padStart(2, "0");

  return `${minutesString}:${secondsString}`;
};

export const convertReadableToSecond = (timeString) => {
  const seconds = parseInt(timeString.split(":")[1]);
  const minutes = parseInt(timeString.split(":")[0]);

  return minutes * 60 + seconds;
};

const convertSecondsToStdString = (secs) => {
  const secondsString = (secs % 60).toFixed(3).padStart(6, "0");
  const mins = Math.floor(secs / 60);
  const minutesString = (mins % 60).toString().padStart(2, "0");
  const hourString = Math.floor(mins / 60)
    .toString()
    .padStart(2, "0");

  return `${hourString}:${minutesString}:${secondsString}`;
};

export const convertTranscriptToWebVtt = (transcript = []) => {
  return `WEBVTT\n\n${transcript.reduce((acc, t, index) => {
    if (t.isNote) {
      return acc;
    }
    return `${acc}${index + 1}\n${convertSecondsToStdString(
      t.start
    )} --> ${convertSecondsToStdString(t.end)}\n${t.text}\n\n`;
  }, "")}\n`;
};

export const getClientInitials = (client) => {
  let initials = "";
  if (client) {
    if (client.firstName) {
      initials += client.firstName[0].toUpperCase();
      if (client.lastName) {
        initials += client.lastName[0].toUpperCase();
      }
    } else if (client.email) {
      initials += client.email[0].toUpperCase();
    } else {
      initials = "??";
    }
  }
  return initials;
};

export const getClientDisplayName = (client) => {
  let name = "";
  if (client) {
    if (client.firstName) {
      name += client.firstName;
      if (client.lastName) {
        name += " " + client.lastName;
      }
    } else if (client.email) {
      name += client.email;
    }
  }

  return name;
};

export const ACCESS_LEVEL = {
  create: "create",
  publish: "publish",
  admin: "admin",
};

export const PERMISSION = {
  VIEW: "view",
  EDIT: "edit",
};
