//returns if the survey has screening questions
export const hasScreeningQuestions = (survey) => {
  return survey.screeningQuestions.length > 0;
};

//returns the screening questions
export const getScreeningQuestions = (survey) => {
  if (!hasScreeningQuestions(survey)) return null;
  return survey.screeningQuestions.map((val) => val.questionObject);
};

//returs true if the user passed the screening test and false otherwise
export const passedScreeningTest = (survey, answers) => {
  let result = true;
  survey.screeningQuestions.forEach((val, index) => {
    if (!answers[index].some((el) => val.answer.includes(el))) {
      result = false
    }
  });
  return result;
};

export const surveyScreeningTest = (survey, userId) => {
  if (!hasScreeningQuestions(survey)) return false;
  if (!userId) return false;
  if (survey?.usersPassedScreening.includes(userId)) {
    return false;
  }
  return true;
};
