import React, { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import Form from "./form/Form";
import Profile from "./profile/Profile";
import Settings from "./settings/Settings";
import History from "./history/History";
import Survey from "./survey/Survey";
import SampleSurvey from "./sample-survey/SampleSurvey";
import {
  decrementPendingRequests,
  incrementPendingRequests,
  notify,
} from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  editProfile,
  getProfile,
  getProfileQuestions,
  sendEmail,
  sendOtp,
  verifyOtp,
} from "../../actions/users";
import RegQuestions from "./registration-questions/RegQuestions";

const User = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector((state) => state.user.profile);
  const profileQuestions = useSelector((state) => state.user.profileQuestions);
  const isRegQuestionsAnswered =
    profile && profile.areQuestionsAnswered.registration;

  useEffect(() => {
    incrementPendingRequests();
    dispatch(getProfile())
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());

    if (!isRegQuestionsAnswered) {
      incrementPendingRequests();
      dispatch(getProfileQuestions("regQuestions"))
        .catch((ex) => notify("error", "Error", ex))
        .finally(() => decrementPendingRequests());
    }
  }, [dispatch]);

  const onEndSurvey = (adminQuestionsAnswers) => {
    const values = {
      adminQuestionsAnswers,
      areQuestionsAnswered: {
        registration: true,
        sample: false,
        additional: false,
      },
    };
    incrementPendingRequests();
    dispatch(editProfile(values))
      .then(() => history.push("/user/forms"))
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  };

  const handleEditProfile = (values, profileAnswers) => {
    incrementPendingRequests();
    dispatch(editProfile({ ...values, adminQuestionsAnswers: profileAnswers }))
      .then(() => notify("success", "Success", "Profile edited"))
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  };

  const handleSendEmail = async (email) => {
    incrementPendingRequests();
    dispatch(sendEmail(email))
      .then(() =>
        notify(
          "success",
          "Email sent!",
          "Please click on the link sent to your email to verify it."
        )
      )
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  };

  const handleSendOtp = (mobileNumber) => {
    return new Promise((resolve, reject) => {
      incrementPendingRequests();
      dispatch(sendOtp(mobileNumber))
        .then(() => {
          notify(
            "success",
            "OTP sent!",
            "Please verify the OTP sent on your mobile"
          );
          resolve();
        })
        .catch((ex) => {
          notify("error", "Error", ex);
          reject();
        })
        .finally(() => decrementPendingRequests());
    });
  };

  const handleVerifyOtp = (value, mobileNumber) => {
    return new Promise((resolve, reject) => {
      incrementPendingRequests();
      dispatch(verifyOtp(mobileNumber, value))
        .then(() => {
          notify("success", "Success", "OTP Verified");
          resolve();
        })
        .catch((ex) => {
          notify("error", "Error", "Wrong OTP. Please enter the correct OTP.");
          reject();
        })
        .finally(() => decrementPendingRequests());
    });
  };

  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/user/forms">
          {profile && profile.areQuestionsAnswered.sample ? (
            <Form profile={profile} />
          ) : (
            <SampleSurvey />
          )}
        </Route>
        <Route exact path="/user/profile">
          <Profile
            profile={profile}
            usedForClient={profile && profile.isClientUser}
            handleEditProfile={handleEditProfile}
            handleSendEmail={handleSendEmail}
            handleSendOtp={handleSendOtp}
            handleVerifyOtp={handleVerifyOtp}
          />
        </Route>
        <Route exact path="/user/settings">
          <Settings
            profile={profile}
            usedForClient={profile && profile.isClientUser}
            handleEditProfile={handleEditProfile}
          />
        </Route>
        <Route exact path="/user/history">
          <History usedForClient={false} profile={profile} />
        </Route>
        <Route exact path="/user/survey" component={Survey} />
      </Switch>
      {!isRegQuestionsAnswered && (
        <RegQuestions
          isOpen={!isRegQuestionsAnswered}
          questions={profileQuestions}
          onEndSurvey={onEndSurvey}
        />
      )}
    </React.Fragment>
  );
};

export default User;
