import React, { Fragment, useState } from "react";
import styles from "./VideoMultiChoicesOptions.module.css";
import c from "classnames/bind";
import {
  CheckOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Input, Tooltip, Upload, message, Button, Checkbox, Spin } from "antd";
import axios, { Routes } from "../../../../../services/API";
import { GetFilename } from "../../../../../constants";

const cx = c.bind(styles);

const VideoMultiChoiceOptions = (props) => {
  // Props
  const {
    dragHandleProps,
    choiceNumber,
    choice,
    isFilled,
    addChoice,
    removeChoice,
    totalChoices,
    setLabel,
    checkBox = false,
    setMcq,
  } = props;

  const [uploading, setUploading] = useState(false);
  const uploadProps = {
    accept: ".mp4,.webm,.ogg",
    customRequest: async function ({ file, onError }) {
      const userId = localStorage.getItem("client");
      const { name, type } = file;
      setUploading(true);
      const { data } = await axios({
        ...Routes.client.uploadFile(userId),
        data: { data: { name, type } },
      });
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", data.signedRequest);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            choice.videoData = data.url;
            setLabel({
              videoLabel: choice.videoLabel,
              videoData: data.url,
            });
            message.success(`${file.name} file uploaded successfully`);
            setUploading(false);
          } else {
            onError();
            message.error(`${file.name} file upload failed.`);
            setUploading(false);
          }
        }
      };
      xhr.send(file);
    },
  };

  return (
    <div className={cx("grid-container")}>
      <div className={cx("choiceNumber")}>
        <div
          style={{
            margin: "auto",
            height: 20,
            width: 20,
            textAlign: "center",
          }}
        >
          <span className="choice-numberCircle">{choiceNumber}</span>
          <MenuOutlined className={cx("menu-outlined")} {...dragHandleProps} />
        </div>
      </div>
      <div className={cx("fields")}>
        <div className={cx("inputFields")}>
          <div className={cx("firstField")}>
            <Input
              size="large"
              placeholder="Video Label"
              style={{ borderRadius: 3 }}
              value={choice.videoLabel}
              onChange={(event) =>
                setLabel({
                  videoLabel: event.target.value,
                  videoData: choice.videoData,
                })
              }
              suffix={
                isFilled.videoLabel ? (
                  <CheckOutlined style={{ color: "green" }} />
                ) : (
                  <Tooltip title="You need to fill this option!">
                    <InfoCircleOutlined style={{ color: "#ff9480" }} />
                  </Tooltip>
                )
              }
              className="input-border"
            />
          </div>
          <div className={cx("secondField")}>
            <Input
              size="large"
              placeholder="No Video Chosen"
              style={{ borderRadius: 3 }}
              value={GetFilename(choice.videoData, 10)}
              disabled
              onChange={(event) =>
                setLabel({
                  videoLabel: choice.videoLabel,
                  videoData: event.target.value,
                })
              }
              suffix={
                isFilled.videoData ? (
                  <CheckOutlined style={{ color: "green" }} />
                ) : (
                  <Tooltip title="You need to fill this option!">
                    <InfoCircleOutlined style={{ color: "#ff9480" }} />
                  </Tooltip>
                )
              }
              className="input-border"
            />
            <Upload
              className={cx("uploadButton")}
              {...uploadProps}
              showUploadList={false}
            >
              <Button style={{ height: "100%" }}>
                <Spin style={{ background: "none" }} spinning={uploading}>
                  Upload Video
                </Spin>
              </Button>
            </Upload>
          </div>
        </div>
        {
          <div className={cx("controls")}>
            <PlusCircleOutlined
              onClick={() => addChoice(choiceNumber)}
              className="action-icon"
            />
            {totalChoices > 2 && (
              <MinusCircleOutlined
                className="action-icon"
                onClick={() => removeChoice(choiceNumber)}
              />
            )}
            {/* {checkBox && (
              <div style={{ margin: "auto 0px" }}>
                <Checkbox className={cx("checkbox")} />
              </div>
            )} */}
            {checkBox && (
              <Tooltip
                title="When checked this option will act like a MCQ. Example: 'None of the above'"
                mouseLeaveDelay={0}
                mouseEnterDelay={0.3}
              >
                <Checkbox
                  className={cx("mcq-checkbox")}
                  checked={choice.isMcq}
                  onChange={(e) => setMcq(e.target.checked)}
                ></Checkbox>
              </Tooltip>
            )}
          </div>
        }
      </div>
    </div>
  );
};

export default VideoMultiChoiceOptions;
