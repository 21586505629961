import React from "react";
import { Tabs } from "antd";
import { COLOR } from "../../constants";
import "./tabbar.css";

const { TabPane } = Tabs;

const TabBar = ({ tabs, activeKey, onChange, tabBarStyle, ...rest }) => {
  return (
    <Tabs
      activeKey={activeKey}
      tabBarStyle={tabBarStyle || { color: COLOR.darkBlue }}
      onChange={(e) => {
        console.log("tab change", e, typeof e);
        onChange(e);
      }}
      {...rest}
    >
      {tabs.map((tab, index) => (
        <TabPane
          tab={
            <div
              style={
                activeKey === String(index + 1)
                  ? { color: COLOR.dullLightGreen }
                  : {}
              }
            >
              {tab}
            </div>
          }
          key={String(index + 1)}
        />
      ))}
    </Tabs>
  );
};

export default TabBar;
