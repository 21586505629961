export const initFilterAttributes = {
  gender: [
    {
      label: "Female",
      value: false,
    },
    {
      label: "Male",
      value: false,
    },
    {
      label: "Neutral",
      value: false,
    },
  ],
  age: [
    { label: "16-20", value: false },
    { label: "21-25", value: false },
    { label: "26-30", value: false },
    { label: "31-35", value: false },
    { label: "35-40", value: false },
    { label: "41-45", value: false },
    { label: "46-50", value: false },
    { label: "51-55", value: false },
    { label: "56-60", value: false },
    { label: "61-65", value: false },
    { label: "66-70", value: false },
    { label: "71-75", value: false },
  ],
  income: [
    { label: "No income pm", value: false },
    { label: "0-50,000 pm", value: false },
    { label: "50,001-1,00,000 pm", value: false },
    { label: "1,00,001-3,00,000 pm", value: false },
    { label: "3,00,001-5,00,000 pm", value: false },
    { label: "5,00,001-10,00,000 pm", value: false },
    { label: "10,00,000+ pm", value: false },
  ],
};

export const initChartAttributes = {
  showFilter: [
    {
      label: "Chart",
      value: true,
    },
    {
      label: "Table",
      value: false,
    },
    {
      label: "Data in chart",
      value: true,
    },
    {
      label: "Basic statistics",
      value: false,
    },
  ],
};
