import React from "react";
import { styled } from "@linaria/react";
import styles from "./DraftFormBoxCopy.module.css";
import {
  UserOutlined,
  VideoCameraOutlined,
  FileTextOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { Modal, Button, notification } from "antd";
import moment from "moment";
import c from "classnames/bind";
import images from "./imageIndex";
import { Fragment } from "react";
import { ShareAltOutlined } from "@ant-design/icons";
import ShareModal from "../ShareModal";
import axios, { Routes } from "../../../services/API";

const cx = c.bind(styles);

// const { confirm } = Modal;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  justify-content: ${(props) => (props.justify ? props.justify : "flex-start")};
  align-items: ${(props) => (props.align ? props.align : "")};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "")};
`;

const DraftFormBoxCopy = ({
  name,
  edit,
  deleteSurvey,
  copySurvey,
  surveyType,
  mode,
  device,
  setting,
  timingType,
  createdBy,
  updatedAt,
  isOwner,
  projectName,
  allUsers,
  surveySharedUsers,
  surveyId,
}) => {
  const [hover, setHover] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [sharedUsers, setSharedUsers] = useState(surveySharedUsers);

  const updateSharedUsers = async (shared) => {
    const clientId = localStorage.getItem("client");
    await axios({
      ...Routes.client.editSurveyShared(clientId, surveyId),
      data: { data: { shared } },
    });
    setSharedUsers(shared);
    notification.success({
      message: "Updated shared users list for the survey",
    });
  };

  /* function showDeleteConfirm() {
    confirm({
      title: "Are you sure you want to delete this Survey?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteSurvey();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  } */
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={cx("draft-box")}
    >
      <div className={cx("firstSection")}>
        <div className={cx("header")}>
          <div className={cx("draft-button")}>DRAFT</div>
          {hover && (
            <div className={cx("survey-icons")}>
              <div>
                <span title={surveyType ? "Qualitative Survey" : "Quantative"}>
                  {surveyType ? (
                    <VideoCameraOutlined></VideoCameraOutlined>
                  ) : (
                    <FileTextOutlined></FileTextOutlined>
                  )}
                </span>
                {/* <span
                  className={
                    surveyType ? "fi-rr-video-camera" : "fi-rr-document"
                  }
                  title={surveyType ? "Qualitative Survey" : "Quantative"}
                /> */}
              </div>
              <div>
                <span title={mode ? "Moderated" : "UnModerated"}>
                  {mode ? (
                    <UserAddOutlined></UserAddOutlined>
                  ) : (
                    <UserDeleteOutlined></UserDeleteOutlined>
                  )}
                </span>
                {/* <span
                  className={mode ? "fi-rr-user-add" : "fi-rr-user-delete"}
                  title={mode ? "Moderated" : "UnModerated"}
                /> */}
              </div>
              <div>
                <span title={setting ? "Group" : "Individual"}>
                  {mode ? (
                    <UsergroupAddOutlined></UsergroupAddOutlined>
                  ) : (
                    <UserOutlined></UserOutlined>
                  )}
                </span>
                {/* <span
                  className={setting ? "fi-rr-users" : "fi-rr-user"}
                  title={setting ? "Group" : "Individual"}
                /> */}
              </div>
              {surveyType === true && timingType !== undefined && (
                <span>
                  {timingType === 1 && <strong>15</strong>}
                  {timingType === 2 && <strong>30</strong>}
                  {timingType === 3 && <strong>45</strong>} mins
                </span>
              )}
            </div>
          )}
        </div>
        <div className={cx("surveyDetails")}>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflowX: "auto",
            }}
          >
            {name}
          </div>
          <div style={{ fontSize: 14 }}>
            {moment(updatedAt).format("DD MMMM YYYY")}
          </div>
        </div>
      </div>
      <div className={cx("secondSection")}>
        <div className={cx("action-buttons")}>
          {hover && isOwner && (
            <Fragment>
              <div>
                <img
                  src={images.trash}
                  onClick={() => setShowModal(true)}
                  title="Delete Survey"
                />
              </div>
              <div>
                <img
                  src={images.copy}
                  onClick={copySurvey}
                  title="Copy Survey"
                />
              </div>
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setShareModal(true)}
              >
                <ShareAltOutlined
                  style={{
                    color: "#fff",
                    fontSize: 20,
                  }}
                />
              </div>
            </Fragment>
          )}
        </div>
        <div onClick={edit} className={cx("edit-button")} title="Edit Survey">
          EDIT
        </div>
      </div>
      <Modal
        visible={showModal}
        closable={false}
        footer={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 12,
            }}
          >
            <Button
              style={{
                backgroundColor: "#bdbdbd",
                border: "none",
                borderRadius: 4,
                color: "white",
                marginRight: 24,
                width: 100,
                textTransform: "uppercase",
              }}
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: "#eb3f4a",
                border: "none",
                borderRadius: 4,
                color: "white",
                marginRight: 24,
                width: 100,
                textTransform: "uppercase",
              }}
              onClick={deleteSurvey}
            >
              Delete
            </Button>
          </div>
        }
      >
        <div
          style={{
            fontSize: 16,
            textAlign: "center",
            marginBottom: 8,
          }}
        >
          Are you sure you want to delete this Survey?
        </div>
      </Modal>
      {isOwner && (
        <ShareModal
          open={shareModal}
          onCancel={() => setShareModal(false)}
          projectName={projectName}
          surveyName={name}
          allUsers={allUsers}
          sharedUsers={sharedUsers}
          updateSharedList={updateSharedUsers}
        />
      )}
    </div>
  );
};

export default DraftFormBoxCopy;
