import axios, { Routes } from "../services/API";
import { set } from "automate-redux";
import { getRoleByToken } from "../utils";

const { client } = Routes;

const dateSort = (a, b) => {
  const d1 = new Date(a.updatedAt);
  const d2 = new Date(b.updatedAt);
  return d2.getTime() - d1.getTime();
};

export const updateSurveysAction = () => async (dispatch, getState) => {
  const id =
    getRoleByToken(localStorage.getItem("token")) === "client"
      ? localStorage.getItem("client")
      : "";
  const { url, method } = client.getSurveys(id);
  try {
    const { data } = await axios[method](url);
    if (data) {
      dispatch(setSurveysAction(data.surveys.sort((a, b) => dateSort(a, b))));
    }
  } catch (err) {
    throw err;
  }
};

export const updateSurveyAction = (survey) => async (dispatch, getState) => {
  if (survey) {
    dispatch(setSurveyAction(survey));
  }
};

export const updateSurveysFromStoreAction = () => async (
  dispatch,
  getState
) => {
  const survey = getState().client.survey;
  const remainingSurveys = getState().client.surveys.filter(
    (s) => s["_id"] !== survey["_id"]
  );
  dispatch(setSurveysAction([survey, ...remainingSurveys]));
  dispatch(editSurveyAction(survey));
};

export const generateNewSurveyAction = (projectId) => async (
  dispatch,
  getState
) => {
  const id =
    getRoleByToken(localStorage.getItem("token")) === "client"
      ? localStorage.getItem("client")
      : "";
  const { url, method } = client.createSurvey(id, projectId);
  try {
    const surveys = getState().client.surveys;
    const { data } = await axios[method](url);
    if (data) {
      dispatch(setSurveysAction([data.survey, ...surveys]));
      return data.survey["_id"];
    }
  } catch (err) {
    throw err;
  }
};

export const editSurveyAction = (survey) => async (dispatch, getState) => {
  const id =
    getRoleByToken(localStorage.getItem("token")) === "client"
      ? localStorage.getItem("client")
      : "";
  if (survey && survey["_id"]) {
    const { url, method } = client.editSurvey(id, survey["_id"]);
    try {
      await axios[method](url, { data: survey });
    } catch (err) {
      throw err;
    }
  }
};

export const deleteSurveyAction = (surveyId) => async (dispatch, getState) => {
  const id =
    getRoleByToken(localStorage.getItem("token")) === "client"
      ? localStorage.getItem("client")
      : "";
  const { url, method } = client.deleteSurvey(id, surveyId);
  const { data } = await axios[method](url);
  if (data) {
    dispatch(updateSurveysAction());
    const surveys = getState().client.surveys.filter(
      (s) => s["_id"] !== surveyId
    );
    dispatch(setSurveysAction(surveys));
  }
};

export const getSurveyAction = (surveyId) => (dispatch, getState) => {
  const surveys = getState().client.surveys;
  if (surveys && surveys.length > 0) {
    return surveys.filter((s) => s && s["_id"] === surveyId)[0];
  }
  return null;
};

export const copySurveyAction = (survey, projectId) => async (
  dispatch,
  getState
) => {
  const id =
    getRoleByToken(localStorage.getItem("token")) === "client"
      ? localStorage.getItem("client")
      : "";
  const { url, method } = client.createSurvey(id, projectId);
  const surveys = getState().client.surveys;
  try {
    const { data } = await axios[method](url);
    if (data && data.survey) {
      const surveyId = data.survey["_id"];
      data.survey = { ...survey };
      data.survey["_id"] = surveyId;
      if (data.survey.name !== undefined && data.survey.name !== "") {
        const copyName = "Copy_" + data.survey.name;
        data.survey.name = copyName;
      } else data.survey.name = "Copy_undefined";
      await axios[client.editSurvey(id, surveyId).method](
        client.editSurvey(id, surveyId, true).url,
        {
          data: data.survey,
        }
      );
      dispatch(setSurveysAction([data.survey, ...surveys]));
    }
  } catch (err) {
    throw err;
  }
};

export const getSignedRequest = (file) => async () => {
  const id =
    getRoleByToken(localStorage.getItem("token")) === "client"
      ? localStorage.getItem("client")
      : "";
  try {
    const { data } = await axios({
      ...Routes.client.uploadFile(id),
      data: { data: { ...file } },
    });
    console.log(data);
  } catch (err) {
    throw err.toString();
  }
};

export const getProfile = () => async (dispatch) => {
  const id =
    getRoleByToken(localStorage.getItem("token")) === "client"
      ? localStorage.getItem("client")
      : "";
  try {
    const { data } = await axios({
      ...Routes.client.getProfile(id),
    });
    dispatch(setProfile({ ...data.client, accessLevel: data.accessLevel }));
  } catch (err) {
    throw err.toString();
  }
};

export const editProfile = (values) => async (dispatch) => {
  const id =
    getRoleByToken(localStorage.getItem("token")) === "client"
      ? localStorage.getItem("client")
      : "";
  try {
    if (!values || !Object.keys(values)?.length) {
      return await dispatch(getProfile());
    }
    const { data } = await axios({
      ...Routes.client.editProfile(id),
      data: { data: values },
    });
    dispatch(setProfile({ ...data.client, accessLevel: data.accessLevel }));
  } catch (err) {
    throw err.toString();
  }
};

export const createAccount = (values) => async (dispatch) => {
  const id =
    getRoleByToken(localStorage.getItem("token")) === "client"
      ? localStorage.getItem("client")
      : "";
  try {
    const { data } = await axios({
      ...Routes.client.createAccount(id),
      data: { data: values },
    });
  } catch (err) {
    throw err.toString();
  }
};

export const updateAccountName = (values) => async (dispatch) => {
  const id =
    getRoleByToken(localStorage.getItem("token")) === "client"
      ? localStorage.getItem("client")
      : "";
  try {
    const { data } = await axios({
      ...Routes.client.updateAccountName(id),
      data: { data: values },
    });
    return data.message;
  } catch (err) {
    throw err.toString();
  }
};

// Email
export const sendEmail = (email) => async (dispatch) => {
  try {
    await axios({
      ...Routes.verification.sendEmail(email, "client"),
    });
    dispatch(editProfile({ email }));
  } catch (err) {
    throw err.toString();
  }
};

// OTP
export const sendOtp = (mobileNumber) => async () => {
  try {
    await axios({
      ...Routes.verification.sendOTP(mobileNumber),
    });
  } catch (err) {
    throw err.toString();
  }
};

export const verifyOtp = (mobileNumber, OTP) => async (dispatch) => {
  try {
    await axios({
      ...Routes.verification.verifyOTP(),
      data: {
        data: {
          mobileNumber,
          OTP,
          role: "client",
          isMobileNumberVerified: true,
        },
      },
    });
    dispatch(editProfile({ mobileNumber, isMobileNumberVerified: true }));
  } catch (err) {
    throw err.toString();
  }
};

export const createProjectAction = (projectName) => async (dispatch) => {
  try {
    const id =
      getRoleByToken(localStorage.getItem("token")) === "client"
        ? localStorage.getItem("client")
        : "";
    const { data } = await axios({
      ...Routes.client.createProject(id),
      data: { data: { projectName } },
    });

    return data.project;
  } catch (err) {
    throw err;
  }
};

export const getProjectAction = (projectId) => async (dispatch) => {
  try {
    const clientId =
      getRoleByToken(localStorage.getItem("token")) === "client"
        ? localStorage.getItem("client")
        : "";

    const { data } = await axios({
      ...Routes.client.getProject(clientId, projectId),
    });

    return data;
  } catch (err) {
    throw err.toString();
  }
};

const setSurveysAction = (data) => set(`client.surveys`, data);
const setSurveyAction = (data) => set(`client.survey`, data);
const setProfile = (data) => set(`client.profile`, data);
