import copy from "../../../assets/copy.png";
import trash from "../../../assets/trash (1).png";
import stop from "../../../assets/stop (1).png";
import play from "../../../assets/fi-rr-caret-right.svg";
export default {
  copy,
  stop,
  trash,
  play,
};
