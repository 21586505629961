import React from "react";
import "./form-box-style.css";
import { PlusCircleFilled } from "@ant-design/icons";

const NewFormBox = () => {
  return (
    <div className="form-box-new" style={{ flexDirection: "column" }}>
      <div style={{ marginBottom: 10 }}>
        <PlusCircleFilled style={{ fontSize: 50 }} />
      </div>
      <div style={{ fontWeight: "bold" }}>CREATE NEW SURVEY</div>
    </div>
  );
};

export default NewFormBox;
