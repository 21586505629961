import React from "react";
import styles from "./AddLink.module.css";
import c from "classnames/bind";
import { Input, Tooltip } from "antd";
import { CheckOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { urlPattern } from "../../../../constants";

const cx = c.bind(styles);

const AddLink = ({ link, setLink }) => {
  return (
    <div className={cx("grid-container")}>
      <Input
        size="large"
        placeholder="Enter a Valid URL"
        style={{
          borderRadius: 3,
          border: "1.25px solid #BFBFBF",
          boxShadow: "0 1px 2px rgb(0 0 0 / 20%)",
        }}
        value={link}
        onChange={(event) => setLink(event.target.value)}
        suffix={
          urlPattern.test(link) ? (
            <CheckOutlined style={{ color: "green" }} />
          ) : (
            <Tooltip title="You need to fill this option!">
              <InfoCircleOutlined style={{ color: "#FF9480" }} />
            </Tooltip>
          )
        }
        className="input-border"
      />
    </div>
  );
};

export default AddLink;
