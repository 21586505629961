import React, { Fragment, useEffect } from "react";
import styles from "./QuestionBuilder.module.css";
import { Button, Popconfirm, Select } from "antd";
import { useState } from "react";
import c from "classnames/bind";
import {
  DownOutlined,
  CheckSquareOutlined,
  UnorderedListOutlined,
  StarOutlined,
  PicCenterOutlined,
  MenuOutlined,
  CloseCircleOutlined,
  PlusCircleFilled,
  SmallDashOutlined,
  ControlOutlined,
  VideoCameraAddOutlined,
  PercentageOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import FormStar from "../../../questions/form-star/FormStar";
import Likert from "../../../questions/likert/Likert";
import FormSlider from "../../../questions/slider/FormSlider";
import MultiChoices from "../../../questions/multi-choices/MultiChoices";
import ImageMultiChoices from "../../../questions/image-multi-choices/imageMultiChoices";
import ImageStarRating from "../../../questions/image-star-rating/ImageStarRating";
import RangeDropDown from "../../../questions/rangeDropDown/RangeDropDown";
import VideoInstruction from "../../../questions/videoInstruction/VideoInstruction";
import VideoStarRating from "../../../questions/video-star-rating/videoStarRating";
import TextABTest from "../../../questions/textABTest/TextABTest";
import ImageABTest from "../../../questions/imageABTest/ImageABTest";
import VideoABTest from "../../../questions/videoABTest/VideoABTest";
import Association from "../../../questions/association/Association";
import ImageAssociation from "../../../questions/image-association/ImageAssociation";
import VideoAssociation from "../../../questions/video-association/VideoAssociation";
import AddLink from "../../../questions/add-link/AddLink";
import ImageInstruction from "../../../questions/imageInstruction/imageInstruction";
import VideoMultiChoices from "../../../questions/video-multi-choices/VideoMultiChoices";
import NetPromotorScore from "../../../questions/net-promotor-score/NetPromotorScore";
import SurveyBrief from "../../../questions/survey-brief/SurveyBrief";
import AdAnalysis from "../../../questions/ad-analysis/AdAnalysis";
import { getQuestionErrors } from "../QuestionBuilderError/questionErrors";
import Errors from "../QuestionBuilderError/Errors";

const cx = c.bind(styles);
const { Option } = Select;

const extraSpace = [
  "multiChoices",
  "checkBoxes",
  "imageMultiChoices",
  "videoMultiChoices",
  "imageCheckBoxes",
  "videoCheckBoxes",
  "ranking",
  "imageRanking",
  "videoRanking",
  "imageStarRatingChoices",
  "videoStarRatingChoices",
  "textABTest",
  "imageABTest",
  "videoABTest",
  "association",
  "imageAssociation",
  "videoAssociation",
  "adAnalysis",
];

// let NPS = null;

const NPS = ({ options, setOptions }) => {
  return (
    <Fragment>
      <span>
        How likely is it that you would recommend{" "}
        <NetPromotorScore options={options} setOptions={setOptions} /> to a
        friend or collegue?
      </span>
    </Fragment>
  );
};

const questionTypes = {
  stars: ({ options, setOptions }) => (
    <FormStar options={options} setOptions={setOptions} />
  ),
  likert: ({ options, setOptions }) => (
    <Likert options={options} setOptions={setOptions} />
  ),
  slider: ({ options, setOptions }) => (
    <FormSlider options={options} setOptions={setOptions} />
  ),
  netPromotorScore: ({ question, setQuestion, options, setOptions }) => {
    question.text = "NPS";
    // return <NetPromotorScore options={options} setOptions={setOptions} />
  },
  multiChoices: ({ options, setOptions }) => (
    <MultiChoices options={options} setOptions={setOptions} />
  ),
  imageMultiChoices: ({ options, setOptions }) => (
    <ImageMultiChoices options={options} setOptions={setOptions} />
  ),
  videoMultiChoices: ({ options, setOptions }) => (
    <VideoMultiChoices options={options} setOptions={setOptions} />
  ),
  checkBoxes: ({ options, setOptions }) => (
    <MultiChoices checkBox={true} options={options} setOptions={setOptions} />
  ),
  imageCheckBoxes: ({ options, setOptions }) => (
    <ImageMultiChoices
      checkBox={true}
      options={options}
      setOptions={setOptions}
    />
  ),
  videoCheckBoxes: ({ options, setOptions }) => (
    <VideoMultiChoices
      checkBox={true}
      options={options}
      setOptions={setOptions}
    />
  ),
  ranking: ({ options, setOptions }) => (
    <MultiChoices options={options} setOptions={setOptions} />
  ),
  imageRanking: ({ options, setOptions }) => (
    <ImageMultiChoices options={options} setOptions={setOptions} />
  ),
  videoRanking: ({ options, setOptions }) => (
    <VideoMultiChoices options={options} setOptions={setOptions} />
  ),
  imageStarRatingChoices: ({ options, setOptions }) => (
    <ImageStarRating options={options} setOptions={setOptions} />
  ),
  videoStarRatingChoices: ({ options, setOptions }) => (
    <VideoStarRating options={options} setOptions={setOptions} />
  ),
  dropDown: ({ options, setOptions }) => (
    <RangeDropDown options={options} setOptions={setOptions} />
  ),
  questionInstruction: ({ options, setOptions }) => null,
  videoInstruction: ({ options, setOptions }) => (
    <VideoInstruction options={options} setOptions={setOptions} />
  ),
  imageInstruction: ({ options, setOptions }) => (
    <ImageInstruction options={options} setOptions={setOptions} />
  ),
  videoComment: ({ options, setOptions }) => (
    <VideoInstruction options={options} setOptions={setOptions} />
  ),
  imageComment: ({ options, setOptions }) => (
    <ImageInstruction options={options} setOptions={setOptions} />
  ),
  textABTest: ({ options, setOptions }) => (
    <TextABTest options={options} setOptions={setOptions} />
  ),
  imageABTest: ({ options, setOptions }) => (
    <ImageABTest options={options} setOptions={setOptions} />
  ),
  videoABTest: ({ options, setOptions }) => (
    <VideoABTest options={options} setOptions={setOptions} />
  ),
  association: ({ options, setOptions }) => (
    <Association options={options} setOptions={setOptions} />
  ),
  imageAssociation: ({ options, setOptions }) => (
    <ImageAssociation options={options} setOptions={setOptions} />
  ),
  videoAssociation: ({ options, setOptions }) => (
    <VideoAssociation options={options} setOptions={setOptions} />
  ),
  text: ({ options, setOptions }) => {
    return;
  },
  surveyBrief: ({ options, setOptions }) => (
    <SurveyBrief options={options} setOptions={setOptions} />
  ),
  adAnalysis: ({ options, setOptions }) => (
    <AdAnalysis options={options} setOptions={setOptions} />
  ),
};

const QuestionBuilder = React.forwardRef(
  ({ index, question, setQuestion, surveyType, publishQuestion }, ref) => {
    const { hasLink } = question;
    const [mouseOver, setMouseOver] = useState(false);
    const [questionError, setQuestionError] = useState(
      getQuestionErrors(question)
    );
    const setOptions = (options) => setQuestion({ ...question, options });

    useEffect(() => {
      const errors = getQuestionErrors(question);
      setQuestionError(errors);
    }, [question]);

    return (
      <div
        onMouseOver={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        ref={ref}
      >
        {/* <Popconfirm
          title="Delete this question?"
          //   onConfirm={removeQuestion}
          okText="Yes"
          cancelText="No"
        >
          <CloseCircleOutlined
            className={cx("question-box-close", { visible: mouseOver })}
          />
        </Popconfirm> */}
        <div className={cx("question-box")}>
          <div className={cx("question-common-component")}>
            {/* <div style={{ display: "flex" }}>
              {!mouseOver && (
                <div className={styles["question-number-icon"]}>
                  {index + 1}
                </div>
              )}
              {mouseOver && (
                <div className={cx("question-icon")}>
                  <MenuOutlined />
                </div>
              )}
            </div> */}
            <div className={cx("question-text-type")}>
              {question.type === "netPromotorScore" ? (
                <div
                  style={{
                    height: "auto",
                    color: "#00233F",
                    flexGrow: 6,
                    width: "min-content",
                  }}
                  className={cx("textarea", {
                    "textarea-invalid": question.text === "",
                  })}
                >
                  <NPS options={question.options} setOptions={setOptions} />
                </div>
              ) : (
                <textarea
                  className={cx("textarea", {
                    "textarea-invalid": question.text === "",
                  })}
                  style={{ flexGrow: 6 }}
                  value={question.text}
                  onChange={(e) =>
                    setQuestion({ ...question, text: e.target.value })
                  }
                />
              )}
              <div className={styles["question-buttons"]}>
                <Select
                  bordered={false}
                  value={question.type}
                  style={{ height: "fit-content" }}
                  className={cx("question-button", {
                    "question-button-active": question.type !== "default",
                  })}
                  suffixIcon={
                    <DownOutlined
                      className={cx("select-arrow", {
                        "select-arrow-active": question.type !== "default",
                      })}
                    />
                  }
                  onChange={(value) =>
                    setQuestion({ ...question, type: value })
                  }
                >
                  <Option value="default">Question Type</Option>
                  <Option value="questionInstruction">
                    <InfoCircleOutlined style={{ marginRight: 4 }} />
                    Question Instruction
                  </Option>
                  <Option value="surveyBrief">
                    <InfoCircleOutlined style={{ marginRight: 4 }} />
                    Survey Brief
                  </Option>
                  <Option value="imageInstruction">
                    <InfoCircleOutlined style={{ marginRight: 4 }} />
                    Image Instruction
                  </Option>
                  <Option value="videoInstruction">
                    <VideoCameraAddOutlined style={{ marginRight: 4 }} />
                    Video Instruction
                  </Option>
                  <Option value="netPromotorScore">
                    <PercentageOutlined style={{ marginRight: 4 }} />
                    Net Promotor Score
                  </Option>
                  <Option value="text">
                    <PicCenterOutlined style={{ marginRight: 4 }} />
                    Text
                  </Option>
                  <Option value="imageComment">
                    <PicCenterOutlined style={{ marginRight: 4 }} />
                    Image Comment
                  </Option>
                  <Option value="videoComment">
                    <PicCenterOutlined style={{ marginRight: 4 }} />
                    Video Comment
                  </Option>
                  <Option value="multiChoices">
                    <UnorderedListOutlined style={{ marginRight: 4 }} />
                    <span style={{ fontSize: 12.5 }}>Multiple choices</span>
                  </Option>
                  <Option value="imageMultiChoices">
                    <UnorderedListOutlined style={{ marginRight: 4 }} />
                    <span style={{ fontSize: 12.5 }}>
                      Images Multiple choices
                    </span>
                  </Option>
                  <Option value="videoMultiChoices">
                    <UnorderedListOutlined style={{ marginRight: 4 }} />
                    <span style={{ fontSize: 12.5 }}>
                      Video Multiple choices
                    </span>
                  </Option>
                  <Option value="ranking">
                    <UnorderedListOutlined style={{ marginRight: 4 }} />
                    <span style={{ fontSize: 12.5 }}>Ranking choices</span>
                  </Option>
                  <Option value="imageRanking">
                    <UnorderedListOutlined style={{ marginRight: 4 }} />
                    <span style={{ fontSize: 12.5 }}>
                      Images Ranking choices
                    </span>
                  </Option>
                  <Option value="videoRanking">
                    <UnorderedListOutlined style={{ marginRight: 4 }} />
                    <span style={{ fontSize: 12.5 }}>
                      Video Ranking choices
                    </span>
                  </Option>
                  <Option value="checkBoxes">
                    <CheckSquareOutlined style={{ marginRight: 4 }} />
                    Checkboxes
                  </Option>
                  <Option value="imageCheckBoxes">
                    <CheckSquareOutlined style={{ marginRight: 4 }} />
                    <span style={{ fontSize: 12.5 }}>Images Check Boxes</span>
                  </Option>
                  <Option value="videoCheckBoxes">
                    <CheckSquareOutlined style={{ marginRight: 4 }} />
                    <span style={{ fontSize: 12.5 }}>Video Check Boxes</span>
                  </Option>
                  <Option value="stars">
                    <StarOutlined style={{ marginRight: 4 }} />
                    Star Rating
                  </Option>
                  <Option value="imageStarRatingChoices">
                    <StarOutlined style={{ marginRight: 4 }} />
                    Image Star Rating
                  </Option>
                  <Option value="videoStarRatingChoices">
                    <VideoCameraAddOutlined style={{ marginRight: 4 }} />
                    Video Star Rating Choices
                  </Option>
                  <Option value="dropDown">
                    <StarOutlined style={{ marginRight: 4 }} />
                    Dropdown
                  </Option>
                  <Option value="textABTest">
                    <PercentageOutlined style={{ marginRight: 4 }} />
                    Text A/B Test
                  </Option>
                  <Option value="imageABTest">
                    <PercentageOutlined style={{ marginRight: 4 }} />
                    Image A/B Test
                  </Option>
                  <Option value="videoABTest">
                    <PercentageOutlined style={{ marginRight: 4 }} />
                    Video A/B Test
                  </Option>
                  <Option value="association">
                    <UnorderedListOutlined style={{ marginRight: 4 }} />
                    <span style={{ fontSize: 12.5 }}>Association</span>
                  </Option>
                  <Option value="imageAssociation">
                    <UnorderedListOutlined style={{ marginRight: 4 }} />
                    <span style={{ fontSize: 12.5 }}>Image Association</span>
                  </Option>
                  <Option value="videoAssociation">
                    <UnorderedListOutlined style={{ marginRight: 4 }} />
                    <span style={{ fontSize: 12.5 }}>Video Association</span>
                  </Option>
                  {/* <Option value="file">
                <CloudUploadOutlined style={{ marginRight: 4 }} />
                File Upload
              </Option> */}
                  <Option value="likert">
                    <SmallDashOutlined style={{ marginRight: 4 }} />
                    Likert Scale
                  </Option>
                  <Option value="slider">
                    <ControlOutlined style={{ marginRight: 4 }} />
                    Slider
                  </Option>
                  {surveyType && (
                    <Option value="adAnalysis">Ad Analysis</Option>
                  )}
                </Select>
                <Button
                  style={{ alignSelf: "end" }}
                  className={cx("question-button", {
                    "question-button-active": hasLink,
                  })}
                  onClick={() => {
                    setQuestion({ ...question, hasLink: !hasLink });
                  }}
                >
                  Add Link
                </Button>
              </div>
            </div>
          </div>
          {typeof questionTypes[question.type] === "function" && (
            <React.Fragment>
              {(hasLink ||
                !(
                  ["questionInstruction", "netPromotorScore", "text"].indexOf(
                    question.type
                  ) > -1
                )) && <hr className={cx("hr")} />}
              {hasLink ? (
                <Fragment>
                  <AddLink
                    link={question.clientLink}
                    setLink={(value) =>
                      setQuestion({ ...question, clientLink: value })
                    }
                  />
                  {question.type &&
                    !(
                      [
                        "questionInstruction",
                        "netPromotorScore",
                        "text",
                      ].indexOf(question.type) > -1
                    ) && <hr className={cx("hr")} />}
                </Fragment>
              ) : null}
              <div
                className={
                  extraSpace.indexOf(question.type) > -1
                    ? cx("question-grid-extra")
                    : cx("question-grid")
                }
                style={{ flexGrow: 1, width: "inherit" }}
              >
                {question.type === "netPromotorScore"
                  ? questionTypes[question.type]({
                      question: question,
                      setQuestion: setQuestion,
                      options: question.options,
                      setOptions,
                    })
                  : questionTypes[question.type]({
                      options: question.options,
                      setOptions,
                    })}
              </div>
            </React.Fragment>
          )}
        </div>
        <Errors errors={questionError} />
        <Button
          style={{
            marginRight: 8,
            marginBottom: 12,
            backgroundColor: "#00233f",
            color: "white",
            borderRadius: 5,
            border: "none",
          }}
          disabled={questionError.length > 0}
          className={cx("publish-button")}
          onClick={() => publishQuestion()}
        >
          Publish Question
        </Button>
      </div>
    );
  }
);

export default QuestionBuilder;
