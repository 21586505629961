import React from "react";
import "./HomeTextBanner.css";
import { Col, Row, Button } from "antd";
import { Link } from "react-router-dom";

const HomeTextBanner = () => {
  return (
    <div className="home-text-banner">
      <Row
        className={"text-container-bottom"}
        justify={"center"}
        align={"middle"}
      >
        <Col md={10}>
          <h1 className={"left-header-h1"}>
            Earn while giving your frank opinion!
          </h1>
          <p className={"left-content-text"}>
            Join thousands of users who give feedback and make money! Companies
            will love you AND pay you for your honest feedback!
          </p>
        </Col>
        <Col md={10}>
          <h2 className={"right-header-h2"}>
            GIVE HONEST FEEDBACK AND EARN MONEY!
          </h2>
          <Link to="/signup/user/email">
            <Button className="signup-button">SIGN UP for FREE</Button>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default HomeTextBanner;
