import React from "react";
import { useMediaQuery } from "react-responsive";
import { Button as AntdButton } from "antd";

const Button = (props) => {
  const { type, style, children, ...rest } = props;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 450px)" });

  return (
    <AntdButton
      type={type}
      size={isTabletOrMobile ? "default" : "large"}
      style={{ ...style, fontSize: "14px" }}
      {...rest}
    >
      {children}
    </AntdButton>
  );
};

export default Button;
