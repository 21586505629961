import { Table } from "antd";
import React from "react";
import "./responsive-table.css";
import { useMediaQuery } from "react-responsive";

const MobileViewTable = (props) => {
  const { columns, dataSource } = props;

  return (
    <div className="mobile-table-container">
      {dataSource.map((data, index) => (
        <div className="card" style={{ margin: "0px 10px 30px" }}>
          {columns.map((col) => (
            <div className="table-row">
              <div className="row-key">
                <b>{col.title}</b>
              </div>
              <div className="row-value">
                {!!col.render
                  ? col.render(data[col.dataIndex], data, index)
                  : data[col.dataIndex]}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const ResponsiveTable = (props) => {
  const { columns, dataSource, className = "" } = props;

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  return (
    <div className={className}>
      {isTabletOrMobile ? (
        <MobileViewTable columns={columns} dataSource={dataSource} />
      ) : (
        <Table
          {...props}
          size="small"
          style={{ wordBreak: "break-word" }}
          pagination={false}
        />
      )}
    </div>
  );
};

export default ResponsiveTable;
