import React from "react";
import { Radio } from "antd";
import "./ButtonToggle.css";

const ButtonToggle = ({ value, setValue, options, activeClass, disabled }) => {
  const buttonStyle = {
    height: "auto",
    padding: 5,
    margin: 10,
    border: "none",
  };
  return (
    <Radio.Group
      className={(activeClass ? activeClass : null) + " chart-type-buttons"}
      buttonStyle="solid"
      onChange={(e) => setValue(e.target.value)}
      value={value}
    >
      {options.map((option, index) => {
        return (
          <Radio.Button
            style={buttonStyle}
            icon
            key={index}
            value={option.value}
            disabled={disabled ? disabled[index] : true}
          >
            {option.icon ? (
              <img
                style={{ height: "50px", width: "50px", position: "relative" }}
                src={option.icon}
                alt=""
              ></img>
            ) : (
              <label>{option.label}</label>
            )}
          </Radio.Button>
        );
      })}
    </Radio.Group>
  );
};

export default ButtonToggle;
