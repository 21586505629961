export default {
  signin: () => {
    return {
      url: `user/signin`,
      method: `post`,
    };
  },
  signup: () => {
    return {
      url: `user/signup`,
      method: `post`,
    };
  },
  forgotPassword: () => {
    return {
      url: `user/forgotPassword`,
      method: `post`,
    };
  },
  getProfile: (id) => {
    return {
      url: `user/${id}/profile`,
      method: `get`,
    };
  },
  editProfile: (id) => {
    return {
      url: `user/${id}/profile`,
      method: `put`,
    };
  },
  getSurveys: (id) => {
    return {
      url: `user/${id}/survey`,
      method: `get`,
    };
  },
  getSurvey: (id, surveyId) => {
    return {
      url: `user/${id}/survey/${surveyId}`,
      method: `get`,
    };
  },
  getOpenSurvey: (surveyId) => {
    return {
      url: `openSurvey/${surveyId}`,
      method: `get`,
    };
  },
  isOpenSurvey: (surveyId) => {
    return {
      url: `/openSurvey/${surveyId}/isOpenSurvey`,
      method: 'get',
    }
  },
  completeSurvey: (id, surveyId) => {
    return {
      url: `user/${id}/survey/${surveyId}`,
      method: `post`,
    };
  },
  completeOpenSurvey: (surveyId) => {
return {
  url: `openSurvey/${surveyId}`,
  method: `post`,
}
  },
  startSurvey: (id, surveyId) => {
    return {
      url: `user/${id}/survey/${surveyId}`,
      method: `put`,
    };
  },
  startOpenSurvey: (surveyId) => {
    return {
      url: `openSurvey/${surveyId}`,
      method: `put`,
    };
  },
  addSurvey: (id, surveyId) => {
    return {
      url: `user/${id}/survey/${surveyId}/addSurvey`,
      method: `delete`,
    };
  },
  removeSurvey: (id, surveyId) => {
    return {
      url: `user/${id}/survey/${surveyId}`,
      method: `delete`,
    };
  },
  getWordCloudReport: (id) => {
    return {
      url: `user/${id}/getAnalysis`,
      method: `post`,
    };
  },
  confirmTimeSlot: (id, surveyId) => {
    return {
      url: `user/${id}/survey/${surveyId}/confirmTimeSlot`,
      method: `post`,
    };
  },
  freeSlot: (id, surveyId) => {
    return {
      url: `user/${id}/survey/${surveyId}/freeSlot`,
      method: `post`,
    };
  },
  addSurvey: (id, surveyId) => {
    return {
      url: `user/${id}/survey/${surveyId}/addSurvey`,
      method: `post`,
    };
  },
  fetchModeratedSurveyQuestions: (id, surveyId) => {
    return {
      url: `user/${id}/survey/${surveyId}/moderatedSurvey`,
      method: `get`,
    };
  },
  fetchOpenModeratedSurveyQuestions: (id, surveyId) => {
    return {
      url: `openSurvey/${surveyId}/moderatedSurvey/${id}`,
      method: `get`,
    };
  },
  answerModeratedSurveyQuestions: (id, surveyId) => {
    return {
      url: `user/${id}/survey/${surveyId}/moderatedSurvey`,
      method: `post`,
    };
  },
  answerOpenModeratedSurveyQuestions: (id, surveyId) => {
    return {
      url: `openSurvey/${surveyId}/moderatedSurvey/${id}`,
      method: `post`,
    };
  }
};
