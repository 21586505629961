import { Button, Switch } from "antd";
import React, { useEffect, useState } from "react";
import ConfigFeaturesModal from "../../../components/admin/features/config-features-modal/ConfigFeaturesModal";
import ProjectPageLayout, {
  Content,
} from "../../../components/page-layout/PageLayout";
import Topbar from "../../../components/topbar/Topbar";
import Sidenav from "../../../components/admin/sidenav/Sidenav";
import ResponsiveTable from "../../../components/responsive-table/ResponsiveTable";
import { adminPages } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import dotProp from "dot-prop-immutable";
import {
  decrementPendingRequests,
  incrementPendingRequests,
  notify,
} from "../../../utils";
import { editAdmin } from "../../../actions/admin";

const Features = () => {
  const dispatch = useDispatch();
  const tierInfo = useSelector((state) => state.admin.tierInfo);
  const [addFeature, setAddFeature] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (tierInfo) {
      let tableDataSource = [];
      let obj = {};

      obj.label = "Influencer Audience";
      obj.name = "influencerAudience";
      obj.basic = tierInfo[0].influencerAudience;
      obj.elite = tierInfo[1].influencerAudience;
      obj.platinum = tierInfo[2].influencerAudience;
      tableDataSource.push(obj);

      obj = {};
      obj.label = "Advanced Criteria";
      obj.name = "advancedCriteria";
      obj.basic = tierInfo[0].advancedCriteria;
      obj.elite = tierInfo[1].advancedCriteria;
      obj.platinum = tierInfo[2].advancedCriteria;
      tableDataSource.push(obj);

      obj = {};
      obj.label = "Advanced Criteria Questions";
      obj.name = "advancedCriteriaQuestions";
      obj.basic = tierInfo[0].advancedCriteriaQuestions;
      obj.elite = tierInfo[1].advancedCriteriaQuestions;
      obj.platinum = tierInfo[2].advancedCriteriaQuestions;
      tableDataSource.push(obj);

      obj = {};
      obj.label = "Individual Responses Compare";
      obj.name = "results.individualResponsesCompare";
      obj.basic = tierInfo[0].results.individualResponsesCompare;
      obj.elite = tierInfo[1].results.individualResponsesCompare;
      obj.platinum = tierInfo[2].results.individualResponsesCompare;
      tableDataSource.push(obj);

      obj = {};
      obj.label = "Question Summaries Compare";
      obj.name = "results.questionSummariesCompare";
      obj.basic = tierInfo[0].results.questionSummariesCompare;
      obj.elite = tierInfo[1].results.questionSummariesCompare;
      obj.platinum = tierInfo[2].results.questionSummariesCompare;
      tableDataSource.push(obj);

      obj = {};
      obj.label = "Image A/B";
      obj.name = "questionType.imageAB";
      obj.basic = tierInfo[0].questionType.imageAB;
      obj.elite = tierInfo[1].questionType.imageAB;
      obj.platinum = tierInfo[2].questionType.imageAB;
      tableDataSource.push(obj);

      obj = {};
      obj.label = "Video A/B";
      obj.name = "questionType.videoAB";
      obj.basic = tierInfo[0].questionType.videoAB;
      obj.elite = tierInfo[1].questionType.videoAB;
      obj.platinum = tierInfo[2].questionType.videoAB;
      tableDataSource.push(obj);

      obj = {};
      obj.label = "Video Star";
      obj.name = "questionType.videoStar";
      obj.basic = tierInfo[0].questionType.videoStar;
      obj.elite = tierInfo[1].questionType.videoStar;
      obj.platinum = tierInfo[2].questionType.videoStar;
      tableDataSource.push(obj);

      obj = {};
      obj.label = "Video Association";
      obj.name = "questionType.videoAssociation";
      obj.basic = tierInfo[0].questionType.videoAssociation;
      obj.elite = tierInfo[1].questionType.videoAssociation;
      obj.platinum = tierInfo[2].questionType.videoAssociation;
      tableDataSource.push(obj);

      setData(tableDataSource);
    }
  }, [tierInfo]);

  // Handlers
  const handleValueChange = (value, plan, featureName) => {
    const newData = data.map((val) => {
      if (val.name !== featureName) return val;

      return {
        ...val,
        [plan]: value,
      };
    });
    setData(newData);
  };

  const handleSubmit = () => {
    let newTierInfo = [];
    // basic
    let obj = {};
    data.forEach((val) => {
      obj = dotProp.set(obj, val.name, val.basic);
    });
    newTierInfo.push({ ...tierInfo[0], ...obj });
    obj = {};
    // elite
    data.forEach((val) => {
      obj = dotProp.set(obj, val.name, val.elite);
    });
    newTierInfo.push({ ...tierInfo[1], ...obj });
    obj = {};
    // platinum
    data.forEach((val) => {
      obj = dotProp.set(obj, val.name, val.platinum);
    });
    newTierInfo.push({ ...tierInfo[1], ...obj });

    incrementPendingRequests();
    dispatch(editAdmin(newTierInfo))
      .then(() => notify("success", "Success", "Features modified"))
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  };

  const columns = [
    {
      dataIndex: "label",
      key: "name",
      align: "center",
    },
    {
      title: "Basic Plan Features",
      dataIndex: "basic",
      align: "center",
      key: "basic",
      render: (text, record) => (
        <Switch
          size="small"
          defaultChecked={text}
          onChange={(e) => handleValueChange(e, "basic", record.name)}
        />
      ),
    },
    {
      title: "Elite Plan Features",
      dataIndex: "elite",
      align: "center",
      key: "elite",
      render: (text, record) => (
        <Switch
          size="small"
          defaultChecked={text}
          onChange={(e) => handleValueChange(e, "elite", record.name)}
        />
      ),
    },
    {
      title: "Platinum Plan Features",
      dataIndex: "platinum",
      align: "center",
      key: "platinum",
      render: (text, record) => (
        <Switch
          size="small"
          defaultChecked={text}
          onChange={(e) => handleValueChange(e, "platinum", record.name)}
        />
      ),
    },
    /* {
      key: "actions",
      align: "center",
      render: (text, record) => (
        <Popconfirm
          title="Are you sure you want to delete?"
          onConfirm={() => console.log(record)}
        >
          <Button type="ghost">Delete</Button>
        </Popconfirm>
      ),
    }, */
  ];

  return (
    <React.Fragment>
      <Topbar>
        <Sidenav drawer selectedItem={adminPages.FEATURES} />
      </Topbar>
      <Sidenav selectedItem={adminPages.FEATURES} />
      <ProjectPageLayout>
        <Content style={{ padding: 5 }}>
          <div className="admin-page-container">
            <ResponsiveTable columns={columns} dataSource={data} />
            <div style={{ margin: "20px 0px", textAlign: "right" }}>
              <Button
                onClick={handleSubmit}
                type="primary"
                style={{ width: 200 }}
              >
                Save
              </Button>
            </div>
            {/* <div style={{ marginTop: 10, textAlign: "right" }}>
              <Button
                type="primary"
                style={{ width: 200 }}
                onClick={() => setAddFeature(true)}
              >
                Add
              </Button>
            </div> */}
          </div>
        </Content>
      </ProjectPageLayout>
      {addFeature && (
        <ConfigFeaturesModal handleCancel={() => setAddFeature(false)} />
      )}
    </React.Fragment>
  );
};

export default Features;
