import { CheckOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Input, Tooltip, Button } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";

function isNumeric(n) {
  if (n === "") return true;
  return !isNaN(parseFloat(n)) && isFinite(n);
}

const MobileInputBox = (props) => {
  const {
    value,
    onChange,
    handleCancel,
    placeholder,
    style = {},
    rule = false,
    limit,
    options,
  } = props;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 450px)" });
  console.log(handleCancel);
  const isRulePassed = () => {
    if (!rule) return true;
    return value.length === limit;
  };

  return (
    <Input
      value={value}
      onChange={(e) => isNumeric(e.target.value) && onChange(e.target.value)}
      maxLength={10}
      size={isTabletOrMobile ? "middle" : "large"}
      placeholder={placeholder}
      style={{
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        border: "none",
        backgroundColor: "#EEE",
        height: "100%",
      }}
      suffix={
        <>
          <div style={{ marginRight: "10px" }}>
            {value && isRulePassed() ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <Tooltip title="You need to fill this box!">
                <InfoCircleOutlined style={{ color: "red" }} />
              </Tooltip>
            )}
          </div>
          <Button
            style={{ height: "100%" }}
            type="primary"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </>
      }
      {...options}
    />
  );
};

export default MobileInputBox;
