import React, { useState } from "react";
import { Modal } from "antd";
import { Fragment } from "react";

import { CloseOutlined } from "@ant-design/icons";

import ImgsViewer from "react-images-viewer";

const IModal = ({ onCancel, imgUrl }) => {
  return (
    <Modal
      footer={null}
      visible={true}
      onCancel={onCancel}
      closable={false}
      className="zoom-image"
    >
      <img
        src={imgUrl}
        alt="img"
        style={{ maxWidth: "100%", height: "auto" }}
      />
      <CloseOutlined
        style={{
          fontSize: 24,
          color: "white",
          position: "absolute",
          cursor: "pointer",
        }}
        onClick={onCancel}
      />
    </Modal>
  );
};

const ImageModal = (url) => {
  const [imageModal, setImageModal] = useState(false);
  return (
    url.url && (
      <Fragment>
        <img src={url.url} onClick={() => setImageModal(true)} />
        {/* {imageModal && (
          <IModal imgUrl={url.url} onCancel={() => setImageModal(false)} />
        )} */}
        <ImgsViewer
          closeBtnTitle="Close"
          width={400}
          imgs={[{ src: url.url }]}
          isOpen={imageModal}
          onClose={() => setImageModal(false)}
        />
      </Fragment>
    )
  );
};

export default ImageModal;
