import React, { Fragment } from "react";
import { Row, Col } from "antd";
import { InnerContainer } from "../../../components/page-layout/PageLayout";
import Errors from "../new-survey/Errors";
import UserQualParticipants from "./UserQualParticipants";
import ImportParticipants from "./ImportParticipants";
import PastSurveyParticipants from "./PastSurveyParticipants";

const Participants = ({
  useUserqualUsers,
  setUseUserqualUsers,
  useImportedUsers,
  setUseImportedUsers,
  clientProvidedUsers,
  setClientProvidedUsers,
  usePanel,
  setUsePanel,
  panelUsers,
  setPanelUsers,
  errors,
  viewOnly,
}) => {
  const innerContainerStyle = {
    padding: 20,
    marginTop: 32,
    pointerEvents: viewOnly ? "none" : "auto",
  };

  return (
    <Fragment>
      <Row style={{ width: "100%" }}>
        <Col>
          <h3 style={{ margin: 0, marginTop: 32, fontWeight: "bold" }}>
            Add participants
          </h3>
          <div>
            You can either import your own users or source users from Userqual.
          </div>
        </Col>
      </Row>

      <InnerContainer style={innerContainerStyle}>
        <UserQualParticipants
          useUserqualUsers={useUserqualUsers}
          setUseUserqualUsers={setUseUserqualUsers}
        />
      </InnerContainer>

      <InnerContainer style={innerContainerStyle}>
        <ImportParticipants
          useImportedUsers={useImportedUsers}
          setUseImportedUsers={setUseImportedUsers}
          clientProvidedUsers={clientProvidedUsers}
          setClientProvidedUsers={setClientProvidedUsers}
        />
      </InnerContainer>

      <InnerContainer style={innerContainerStyle}>
        <PastSurveyParticipants
          usePanel={usePanel}
          setUsePanel={setUsePanel}
          panelUsers={panelUsers}
          setPanelUsers={setPanelUsers}
        />
      </InnerContainer>

      <Errors errors={errors} />
    </Fragment>
  );
};

export default Participants;
