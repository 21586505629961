import { Modal, Input, Button, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./share-modal.module.css";
import c from "classnames/bind";
import { SearchOutlined } from "@ant-design/icons";
import {
  PERMISSION,
  getClientInitials,
  getClientDisplayName,
  ACCESS_LEVEL,
} from "../../constants";
import { cloneDeep, isEqual } from "lodash";
import { getClientId } from "../../utils";

const cx = c.bind(styles);

const permissionOptions = [
  {
    text: "View Access",
    value: PERMISSION.VIEW,
  },
  {
    text: "Edit Access",
    value: PERMISSION.EDIT,
  },
];

const ShareModal = ({
  open,
  onCancel,
  updateSharedList,
  projectName,
  surveyName,
  allUsers,
  sharedUsers = [],
}) => {
  const ownersClientId = getClientId();
  const availableUsers = allUsers.filter(
    (user) => user.clientId !== ownersClientId
  );

  const updateSharedUser = async (clientId, sharedUserObj) => {
    const newSharedUsers = sharedUsers.filter(
      (sharedUser) => sharedUser.client !== clientId
    );
    if (sharedUserObj !== null) {
      newSharedUsers.push(sharedUserObj);
    }
    await updateSharedList(newSharedUsers);
  };

  return (
    <Modal
      visible={open}
      closable={false}
      footer={null}
      onCancel={onCancel}
      width={750}
    >
      <div
        style={{
          fontSize: 16,
          marginBottom: 10,
        }}
      >
        Share with team members
      </div>

      {allUsers.length < 2 ? (
        <div style={{ padding: "1rem 0", fontSize: "0.9rem", opacity: "65%" }}>
          You do not have any team members!
        </div>
      ) : (
        <div>
          <div>
            <p>Project: {projectName}</p>
            {!!surveyName && <p>Survey: {surveyName}</p>}
          </div>
          {availableUsers.map((user) => {
            return (
              <TeamMemberShareControl
                user={user}
                key={user.clientId}
                updateSharedUser={updateSharedUser}
                sharedUserObj={sharedUsers.find(
                  (sharedUser) => sharedUser.client === user.clientId
                )}
              />
            );
          })}
        </div>
      )}
    </Modal>
  );
};

const TeamMemberShareControl = ({ user, sharedUserObj, updateSharedUser }) => {
  const [isUserSelected, setIsUserSelected] = useState(!!sharedUserObj);

  const switchHandler = async (checked) => {
    if (!checked) {
      await updateSharedUser(user.clientId, null);
    } else {
      await updateUserPermission(PERMISSION.VIEW);
    }
    setIsUserSelected(checked);
  };

  const updateUserPermission = async (permission) => {
    await updateSharedUser(user.clientId, {
      client: user.clientId,
      permission,
    });
  };

  useEffect(() => {
    setIsUserSelected(!!sharedUserObj);
  }, [sharedUserObj]);

  let initial = "";
  let name = "";
  if (user.firstName) {
    initial += user.firstName[0].toUpperCase();
    name += user.firstName;
    if (user.lastName) {
      initial += user.lastName[0].toUpperCase();
      name += ` ${user.lastName}`;
    }
  } else if (user.email) {
    initial += user.email[0].toUpperCase();
    name += user.email;
  } else {
    initial = "??";
    name = user.mobileNumber;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 12,
        flexWrap: "wrap",
        paddingRight: 8,
        columnGap: 8,
        rowGap: 8,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <span
          style={{
            display: "inline-flex",
            width: 32,
            height: 32,
            borderRadius: "50%",
            backgroundColor: "#01223f",
            color: "white",
            marginRight: 12,
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          {initial}
        </span>
        <span style={{ fontSize: 16 }}>{name}</span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: 8,
        }}
      >
        <Select
          disabled={!isUserSelected}
          value={
            isUserSelected && sharedUserObj?.permission
              ? sharedUserObj.permission
              : PERMISSION.VIEW
          }
          defaultValue={PERMISSION.VIEW}
          onChange={(value) => {
            updateUserPermission(value);
          }}
          className={cx("access-selector")}
        >
          {permissionOptions.map(({ value, text }) => (
            <Select.Option key={value} value={value}>
              {text}
            </Select.Option>
          ))}
        </Select>

        <Switch
          checked={isUserSelected}
          onChange={(checked) => switchHandler(checked)}
        />
      </div>
    </div>
  );
};

export default ShareModal;
