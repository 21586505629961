import React, { Fragment, useState } from "react";
import styles from "./ImageABTestChoices.module.css";
import c from "classnames/bind";
import {
  CheckOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Input, Tooltip, Upload, message, Button } from "antd";
import { useMediaQuery } from "react-responsive";
import { GetFilename } from "../../../../../constants";
import axios, { Routes } from "../../../../../services/API";

const cx = c.bind(styles);

const ImageABTestChoices = (props) => {
  const [uploading, setUploading] = useState(false);
  const uploadProps = {
    accept: ".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.jif,.JIF,.gif,.GIF",
    customRequest: async function ({ file, onError }) {
      const userId = localStorage.getItem("client");
      const { name, type } = file;
      setUploading(true);
      const { data } = await axios({
        ...Routes.client.uploadFile(userId),
        data: { data: { name, type } },
      });
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", data.signedRequest);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            setValue({
              ...choice,
              imageData: data.url,
            });
            message.success(`${file.name} file uploaded successfully`);
            setUploading(false);
          } else {
            onError();
            message.error(`${file.name} file upload failed.`);
            setUploading(false);
          }
        }
      };
      xhr.send(file);
    },
  };
  // Props
  const {
    dragHandleProps,
    choiceNumber,
    choice,
    isFilled,
    addChoice,
    removeChoice,
    totalChoices,
    setValue,
  } = props;

  return (
    <div className={cx("grid-container")} style={{ marginBottom: 5 }}>
      <div className={cx("choiceNumber")}>
        <div
          style={{ margin: "auto", height: 20, width: 20, textAlign: "center" }}
        >
          <span className="choice-numberCircle">{choiceNumber}</span>
          <MenuOutlined
            className={cx("menu-outlined")}
            style={{ margin: "auto" }}
            {...dragHandleProps}
          />
        </div>
      </div>
      <div className={cx("fields")}>
        <div className={cx("inputFields")}>
          <div>
            <Input
              size="large"
              placeholder="Image Label (Optional)"
              style={{ borderRadius: 3 }}
              value={choice.imageLabel}
              onChange={(event) =>
                setValue({ ...choice, imageLabel: event.target.value })
              }
              // suffix={
              //   isFilled.imageLabel ? (
              //     <CheckOutlined style={{ color: "green" }} />
              //   ) : (
              //       <Tooltip title="You need to fill this option!">
              //         <InfoCircleOutlined style={{ color: "red" }} />
              //       </Tooltip>
              //     )
              // }
              className="input-border"
            />
          </div>
          <div className={cx("secondField")}>
            <Input
              size="large"
              placeholder="Image Label (Optional)"
              style={{ borderRadius: 3 }}
              value={GetFilename(choice.imageData, 10)}
              disabled
              onChange={(event) =>
                setValue({ ...choice, imageData: event.target.value })
              }
              suffix={
                isFilled.imageData ? (
                  <CheckOutlined style={{ color: "green" }} />
                ) : (
                  <Tooltip title="You need to fill this option!">
                    <InfoCircleOutlined style={{ color: "#ff9480" }} />
                  </Tooltip>
                )
              }
              className="input-border"
            />
            <Upload
              className={cx("uploadButton")}
              {...uploadProps}
              showUploadList={false}
            >
              <Button style={{ height: "100%" }}>
                Upload Image{uploading ? <LoadingOutlined /> : null}
              </Button>
            </Upload>
          </div>
        </div>
        <div className={cx("controlFields")}>
          <div className={cx("percentageField")}>
            <Input
              size="large"
              placeholder="%"
              style={{ borderRadius: 3 }}
              value={choice.percentage}
              onChange={(event) =>
                setValue({ ...choice, percentage: event.target.value })
              }
              suffix={
                <Fragment>
                  <label
                    style={{
                      fontSize: 16,
                      color: "#004479",
                      paddingRight: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    %
                  </label>
                  {isFilled.percentage ? (
                    <CheckOutlined style={{ color: "green" }} />
                  ) : (
                    <Tooltip title="You need to fill this option!">
                      <InfoCircleOutlined style={{ color: "#ff9480" }} />
                    </Tooltip>
                  )}
                </Fragment>
              }
              className="input-border"
            />
          </div>
          {
            <div className={cx("controls")}>
              <PlusCircleOutlined
                onClick={() => addChoice(choiceNumber)}
                className="action-icon"
              />
              {totalChoices > 2 && (
                <MinusCircleOutlined
                  className="action-icon"
                  onClick={() => removeChoice(choiceNumber)}
                />
              )}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default ImageABTestChoices;
