import { useDispatch } from "react-redux";
import { removeSurvey } from "../../../actions/users";
import {
  decrementPendingRequests,
  incrementPendingRequests,
  notify,
} from "../../../utils";

function useHandleDisqualification() {
  const dispatch = useDispatch();

  const handleDisqualification = async (survey) => {
    incrementPendingRequests();
    dispatch(removeSurvey(survey._id))
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  };

  return handleDisqualification;
}

export default useHandleDisqualification;
