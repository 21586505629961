export const getQuestionErrors = (question) => {
  const err = [];

  if (question.text === "" && question.type !== "netPromotorScore") {
    err.push({
      message: `There is no text for question`,
      description: `Please provide some text for question`,
    });
  }

  if (question.type === "likert") {
    if (question.options.likertStartLabel === "") {
      err.push({
        message: `There is no start label for question`,
        description: `Please provide start label for question`,
      });
    }

    // if (question.options.likertMiddleLabel === "") {
    //   err.push({
    //     message: `There is no middle label for question`,
    //     description: `Please provide middle label for question`,
    //   });
    // }

    if (question.options.likertEndLabel === "") {
      err.push({
        message: `There is no end label for question`,
        description: `Please provide end label for question`,
      });
    }
  }

  if (question.type === "slider") {
    if (question.options.sliderStartLabel === "") {
      err.push({
        message: `There is no start label for question`,
        description: `Please provide start label for question`,
      });
    }

    // if (question.options.sliderMiddleLabel === "") {
    //   err.push({
    //     message: `There is no middle label for question`,
    //     description: `Please provide middle label for question`,
    //   });
    // }

    if (question.options.sliderEndLabel === "") {
      err.push({
        message: `There is no end label for question`,
        description: `Please provide end label for question`,
      });
    }
  }

  if (question.type === "multiChoices" || question.type === "checkBoxes") {
    question.options.multiChoices.forEach((opt) => {
      if (opt.label === "") {
        err.push({
          message: `There is no label for question`,
          description: `Please provide a label for question`,
        });
      }
    });
  }

  if (
    question.type === "imageMultiChoices" ||
    question.type === "imageCheckBoxes"
  ) {
    question.options.imageMultiChoices.forEach((opt) => {
      if (opt.imageLabel === "") {
        err.push({
          message: `There is no label for question`,
          description: `Please provide a label for question`,
        });
      }
      if (opt.imageData === "") {
        err.push({
          message: `There is no image for question`,
          description: `Please provide an image for question`,
        });
      }
    });
  }

  if (question.type === "imageStarRatingChoices") {
    question.options.imageStarRatingChoices.forEach((opt) => {
      if (opt.imageLabel === "") {
        err.push({
          message: `There is no label for question`,
          description: `Please provide a label for question`,
        });
      }
      if (opt.imageData === "") {
        err.push({
          message: `There is no image for question`,
          description: `Please provide an image for question`,
        });
      }
    });
  }

  if (question.type === "videoStarRatingChoices") {
    question.options.videoStarRatingChoices.forEach((opt) => {
      if (opt.videoLabel === "") {
        err.push({
          message: `There is no label for question`,
          description: `Please provide a label for question`,
        });
      }
      if (opt.videoData === "") {
        err.push({
          message: `There is no video for question`,
          description: `Please provide a video for question`,
        });
      }
    });
  }

  if (question.type === "association") {
    question.options.associationChoices.forEach((opt) => {
      if (opt.association === "") {
        err.push({
          message: `There is no association for question`,
          description: `Please provide association for label for question`,
        });
      }
      if (opt.label === "") {
        err.push({
          message: `There is no label for question`,
          description: `Please provide a label for question`,
        });
      }
    });
  }

  if (question.type === "imageAssociation") {
    question.options.imageAssociationChoices.forEach((opt) => {
      if (opt.association === "") {
        err.push({
          message: `There is no association for question`,
          description: `Please provide association for label for question`,
        });
      }
      if (opt.imageData === "") {
        err.push({
          message: `There is no image for question`,
          description: `Please provide an image for question`,
        });
      }
    });
  }

  if (question.type === "videoAssociation") {
    question.options.videoAssociationChoices.forEach((opt) => {
      if (opt.association === "") {
        err.push({
          message: `There is no association for question`,
          description: `Please provide association label for question`,
        });
      }
      if (opt.videoData === "") {
        err.push({
          message: `There is no video for question`,
          description: `Please provide a video for question`,
        });
      }
    });
  }

  if (question.type === "adAnalysis") {
    question.options.adAnalysisChoices.forEach((opt) => {
      if (!opt.videoType) {
        err.push({
          description: `Please provide video type for question`,
        });
      }
      if (opt.videoData === "") {
        err.push({
          description: `Please provide a video for question`,
        });
      }
    });

    if (!question.options.adAnalysisData.url) {
      err.push({
        description: `Please combine the video of question`,
      });
    }
  }
  return err;
};
