import React, { useEffect } from "react";
import { Button, Card } from "antd";
import RatioModal from "./Modals/RatioModal/RatioModal";
import { useState } from "react";
import {
  oxford,
  findNextAvailableGroupIndex,
  getQuestion,
  getOptionsLength,
  getAgeRange,
} from "./CriteriaUtils.js";
import RatioDescription from "./RatioDescription";

const GroupContainer = ({ criteria, groups, setGroups, adminQuestions }) => {
  const [isRatioModalVisible, setIsRatioModalVisible] = useState(false);
  const [activeGroup, setActiveGroup] = useState({});
  const [criteriaListClient, setCriteriaListClient] = useState([]);

  const syncSelectedValuesAndRatios = (newCriteria) => {
    const syncedCriteria = newCriteria.map((c) => {
      if (!c?.selectedValues?.length || !c?.ratios?.length) {
        return {
          ...c,
          selectedValues: [-1],
          ratios: [],
        };
      } else {
        let selectedValues = [];
        let ratios = new Array(
          getOptionsLength(c.questionId, adminQuestions)
        ).fill(0);
        if (
          getQuestion(c.questionId, adminQuestions).questionObject
            .questionType == "age"
        ) {
          getAgeRange({
            start: getQuestion(c.questionId, adminQuestions).questionObject
              .start,
            end: getQuestion(c.questionId, adminQuestions).questionObject.end,
            interval: getQuestion(c.questionId, adminQuestions).questionObject
              .interval,
          }).map((el, index) => {
            if (
              el.value.every((r) => c.answer.includes(r)) &&
              c.selectedValues.includes(index)
            ) {
              selectedValues.push(index);
              ratios[index] = c.ratios[index];
            }
          });
        } else {
          c.selectedValues.map((sv) => {
            if (c.answer.includes(sv)) {
              selectedValues.push(sv);
              ratios[sv] = c.ratios[sv];
            }
          });
        }
        return {
          ...c,
          selectedValues: [...selectedValues],
          ratios: [...ratios],
        };
      }
    });
    return syncedCriteria;
  };

  //When criteria changes, update each group's criteria and criteria list diplayed on the UI
  useEffect(() => {
    //update Criteria list
    const newCriteriaListClient = criteria.map((criterion) => {
      return getQuestion(criterion.questionId, adminQuestions).clientField;
    });
    setCriteriaListClient(newCriteriaListClient);

    if (!groups) return;

    //if an option is added or removed
    const newGroups = groups.map((group) => {
      const newCriteria = group.criteria.map((groupCriterion) => {
        return {
          ...criteria.find(
            (criterion) => criterion.questionId === groupCriterion.questionId
          ),
          ratios: groupCriterion.ratios,
          selectedValues: groupCriterion.selectedValues,
        };
      });

      //If an existing criterion is removed
      const removedCriteria = newCriteria.filter((groupCriterion) => {
        return criteria.some((criterion) => {
          return groupCriterion.questionId === criterion.questionId;
        });
      });

      //If a new criterion is added
      let addedCriteria = criteria.map((criterion) => {
        if (
          !newCriteria.some(
            (groupCriterion) =>
              groupCriterion.questionId === criterion.questionId
          )
        ) {
          return { ...criterion, raitos: [], selectedValues: [] };
        }
      });
      addedCriteria = addedCriteria.filter(
        (addedCriterion) => !!addedCriterion
      );

      return {
        ...group,
        criteria: syncSelectedValuesAndRatios([
          ...addedCriteria,
          ...removedCriteria,
        ]),
      };
    });

    setGroups(newGroups);
  }, [criteria]);

  const editGroup = async (index) => {
    setActiveGroup(groups.find((group) => group.index === index));
    showRatioModal();
  };

  const deleteGroup = async (index) => {
    const newGroups = groups.filter((group) => {
      return group.index !== index;
    });
    setActiveGroup({});
    setGroups(newGroups);
  };

  const showRatioModal = () => {
    setIsRatioModalVisible(true);
  };

  const hideRatioModal = () => {
    setIsRatioModalVisible(false);
  };

  const updateActiveGroupsName = (name) => {
    const newGroups = groups.map((group) => {
      if (group.index === activeGroup.index) {
        return { ...group, name };
      }
      return group;
    });
    setGroups(newGroups);
  };

  const updateActiveGroupsParticipants = (numberOfParticipants) => {
    const newGroups = groups.map((group) => {
      if (group.index === activeGroup.index) {
        return { ...group, numberOfParticipants };
      }
      return group;
    });
    setGroups(newGroups);
  };

  const updateActiveGroupsCriteria = (newCriteria) => {
    const newGroups = groups.map((group) => {
      if (group.index === activeGroup.index) {
        return { ...group, criteria: newCriteria };
      }
      return group;
    });
    setGroups(newGroups);
  };

  const createNewGroup = () => {
    const groupIndex = findNextAvailableGroupIndex(groups);
    const newGroup = {
      name: `Group ${groupIndex}`,
      numberOfParticipants: 0,
      index: groupIndex,
      criteria: criteria.map((criterion) => {
        return { ...criterion, ratios: [], selectedValues: [] };
      }),
      responses: [],
    };

    if (!groups) setGroups([newGroup]);
    else setGroups([...groups, newGroup]);

    setActiveGroup(newGroup);
    showRatioModal();
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
            Create user groups
          </div>
          <div>{`You can filter participants based on ${oxford(
            criteriaListClient
          )}`}</div>
        </div>
        <Button
          style={{
            backgroundColor: "#00233f",
            color: "white",
            border: "none",
            borderRadius: 4,
            height: 36,
          }}
          onClick={createNewGroup}
        >
          Add group
        </Button>

        <RatioModal
          isModalVisible={isRatioModalVisible}
          groupName={activeGroup.name}
          groupParticipants={activeGroup.numberOfParticipants}
          isGroupSurvey={true}
          hideModal={hideRatioModal}
          updateGroupName={updateActiveGroupsName}
          updateGroupParticipants={updateActiveGroupsParticipants}
          criteria={activeGroup.criteria ? activeGroup.criteria : []}
          setCriteria={updateActiveGroupsCriteria}
          adminQuestions={adminQuestions}
        />
      </div>
      {!!groups?.length &&
        groups.map((group) => {
          return (
            <GroupCard
              key={group.index}
              group={group}
              editHandler={editGroup}
              adminQuestions={adminQuestions}
              deleteHandler={deleteGroup}
            />
          );
        })}
    </>
  );
};

const GroupCard = ({ group, editHandler, deleteHandler, adminQuestions }) => {
  return (
    <div style={{ padding: "20px 0 0 0" }}>
      <Card style={{ backgroundColor: "#EEEEEE", borderRadius: "5px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontSize: "1em", fontWeight: "bold" }}>
            {`${group.name}${
              group.numberOfParticipants
                ? ` - ${group.numberOfParticipants} participants`
                : ""
            }`}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              color: "#013B69",
              columnGap: "10px",
              fontSize: "0.9em",
            }}
          >
            <div
              onClick={() => editHandler(group.index)}
              style={{ cursor: "pointer" }}
            >
              EDIT
            </div>
            <div
              onClick={() => deleteHandler(group.index)}
              style={{ cursor: "pointer" }}
            >
              DELETE
            </div>
          </div>
        </div>
        <div>
          {group.criteria.map((criterion) => (
            <RatioDescription
              criterion={criterion}
              adminQuestions={adminQuestions}
              key={criterion.questionId}
            />
          ))}
        </div>
      </Card>
    </div>
  );
};

export default GroupContainer;
