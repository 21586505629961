import React from "react";
import Thumbnail from "../video-thumbnail/VideoThumbnail";

const VideoInstruction = ({ key, options }) => {
  return (
    <div key={key}>
      <Thumbnail url={options.videoData} label={options.videoLabel} />
    </div>
  );
};

export default VideoInstruction;
