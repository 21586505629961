import React, { Fragment } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useArray from "../../useArray";
import FormStar from "../form-star/FormStar";
import VideoStarRatingChoices from "./videoStarRatingChoices/videoStarRatingChoices";

const VideoStarRating = ({ options, setOptions }) => {
  // const [multiChoices, { add, remove, move, set }] = useArray(
  //   options.videoStarRatingChoices
  // );

  const videoStarRatingChoices = options.videoStarRatingChoices;

  const add = (item, index) => {
    setOptions({
      ...options,
      videoStarRatingChoices: [
        ...videoStarRatingChoices.slice(0, index),
        item,
        ...videoStarRatingChoices.slice(index, videoStarRatingChoices.length),
      ],
    });
  };

  const move = (source, dest) => {
    const result = Array.from(videoStarRatingChoices);
    const [removed] = result.splice(source, 1);
    result.splice(dest, 0, removed);
    setOptions({ ...options, videoStarRatingChoices: result });
  };

  const remove = (index) => {
    setOptions({
      ...options,
      videoStarRatingChoices: [
        ...videoStarRatingChoices.slice(0, index),
        ...videoStarRatingChoices.slice(
          index + 1,
          videoStarRatingChoices.length
        ),
      ],
    });
  };

  const set = (item, index) => {
    const result = Array.from(videoStarRatingChoices);
    result[index] = item;
    setOptions({ ...options, videoStarRatingChoices: result });
  };

  const setOptionValue = (item, index) => {
    set(item, index);
  };

  const setStarValue = (item) => {
    console.log("star", item);
    setOptions({
      ...options,
      videoStar: item.stars,
      videoStarType: item.starType,
    });
  };

  return (
    <Fragment>
      <FormStar
        options={{ starType: options.videoStarType, stars: options.videoStar }}
        setOptions={(item) => setStarValue(item)}
      ></FormStar>
      <DragDropContext
        onDragEnd={(result) =>
          move(result.source.index, result.destination.index)
        }
      >
        <Droppable droppableId="list">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                paddingTop: 20,
              }}
            >
              {videoStarRatingChoices.map((choice, index) => (
                <Draggable
                  draggableId={`option-${index}`}
                  index={index}
                  key={index}
                >
                  {(provided) => (
                    <div
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                      gutter={6}
                    >
                      <VideoStarRatingChoices
                        dragHandleProps={provided.dragHandleProps}
                        choiceNumber={index + 1}
                        choice={choice}
                        checkBox={false}
                        setLabel={(item) => setOptionValue(item, index)}
                        totalChoices={videoStarRatingChoices.length}
                        isFilled={{
                          videoLabel: choice.videoLabel !== "",
                          videoData: choice.videoData !== "",
                        }}
                        addChoice={(newIndex) =>
                          add({ videoLabel: "", videoData: "" }, newIndex)
                        }
                        removeChoice={(index) => remove(index - 1)}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Fragment>
  );
};

export default VideoStarRating;
