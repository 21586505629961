import React, { useState } from "react";
import { Form, Input, Button, notification, Popover, Modal } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { styled } from "@linaria/react";
import { useParams, useHistory } from "react-router-dom";
import axios, { Routes } from "../services/API";
import handleNetworkErrors from "./client/handleNetworkErrors";
import { decrementPendingRequests, incrementPendingRequests } from "../utils";
import { useMediaQuery } from "react-responsive";

export const Center = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.isClient ? "#ECF9FA" : "#FFE2DC")};
`;

const MobileForm = (props) => {
  const history = useHistory();
  const { role } = useParams();
  const [token, setToken] = useState("");
  const [step, setStep] = useState(0);
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [p1, setP1] = useState("");
  const [p2, setP2] = useState("");
  const { forgotPassword } = props;
  const isClient = role === "client";
  const roleColor = isClient ? "#71CFD7" : "#FF9480";
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 300px)" });

  const sendOTP = async () => {
    var { url, method } = Routes.others.checkAvailable(
      `userType=${role}&mobileNumber=${parseInt(mobile)}`
    );
    var { data } = await axios[method](url);
    console.log(data);
    if (!forgotPassword && !data.isAvailable) {
      return Modal.error({
        title: "Mobile Number Not Available!",
      });
    }
    if (forgotPassword && data.isAvailable) {
      return Modal.error({
        title: "Mobile Number Not Registered!",
      });
    }
    const parsedMobile = parseInt(mobile);
    try {
      const res = await axios[Routes.verification.sendOTP(parsedMobile).method](
        Routes.verification.sendOTP(parsedMobile).url,
        {
          data: { mobileNumber: parsedMobile },
        }
      );
      if (res.data && res.data.message && res.data.message.includes("OTP sent"))
        setStep(1);
    } catch (err) {
      handleNetworkErrors(err);
    }
  };

  const verifyOtp = async () => {
    const parsedMobile = parseInt(mobile);
    const parsedOtp = parseInt(otp);
    const { url, method } = Routes.verification.verifyOTP();
    try {
      const { data } = await axios[method](url, {
        data: { mobileNumber: parsedMobile, OTP: parsedOtp },
      });
      if (data && data.message && data.message.includes("OTP verified")) {
        notification.success({
          message: "OTP verified successfully.",
        });
        if (data.token) setToken(data.token);
        setStep(2);
      }
    } catch (err) {
      handleNetworkErrors(err);
    }
  };

  const signup = async () => {
    const parsedMobile = parseInt(mobile);
    const { url, method } = forgotPassword
      ? Routes[role].forgotPassword()
      : Routes[role].signup();
    if (p1 !== p2) {
      notification.info({
        message: "Please type the same password both the times!",
      });
      return;
    }
    try {
      const { data } = await axios[method](url, {
        data: {
          mobileNumber: parsedMobile,
          password: p1,
          token,
          isMobileNumberVerified: true,
          registeredVia: 1,
        },
      });
      if (!forgotPassword && data && data.token && data[role]) {
        localStorage.setItem("token", data.token);
        localStorage.setItem(`${role}`, data[role]._id);
        if (role === "user") history.push(`/user/forms`);
        else if (role === "client") history.push("/client");
        return;
      }
      notification.success({
        message:
          "Password changed successfully. Now please login with the new password.",
      });
      history.push(`/signin/${role}`);
    } catch (err) {
      handleNetworkErrors(err);
    }
  };

  return (
    <div>
      <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
        {forgotPassword ? (
          <>
            {`${role.charAt(0).toUpperCase() + role.slice(1)}`} password reset
          </>
        ) : (
          <>
            Hey, welcome! <br />
            Please register yourself as a {role}.
          </>
        )}
      </h2>
      <Form
        style={{
          minWidth: isTabletOrMobile ? "100%" : "300px",
          background: "white",
          borderRadius: 10,
          marginBottom: "0.5em",
          padding: "30px 20px",
          boxShadow:
            "2px 2px 8px rgba(0, 0, 0, 0.15), -2px -2px 8px rgba(0, 0, 0, 0.15)",
        }}
      >
        {step === 0 && (
          <>
            <div style={{ marginBottom: 10 }}>
              <b>Phone</b>
            </div>
            <Form.Item
              name="mobileNumber"
              rules={[
                {
                  required: true,
                  validator: (_, value) => {
                    if (/[A-Za-z]/.test(value)) {
                      return Promise.reject(
                        "Please enter a valid mobile number!"
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              style={{ width: "100%" }}
            >
              <Input
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                style={{ borderRadius: 10, height: 45, borderColor: roleColor }}
                placeholder="e.g. 9876543210"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                onClick={sendOTP}
                disabled={mobile.length !== 10 || /[A-Za-z]/.test(mobile)}
                style={{
                  background: roleColor,
                  borderRadius: 10,
                  height: 45,
                  border: roleColor,
                }}
                block
              >
                Send OTP
              </Button>
            </Form.Item>
            <h3
              style={{
                textAlign: "center",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() =>
                history.push(
                  `/${
                    forgotPassword ? "forgotPassword" : "signup"
                  }/${role}/email`
                )
              }
            >
              <b>
                Use Email to {forgotPassword ? "reset password" : "Register"}
              </b>
            </h3>
          </>
        )}
        {step === 1 && (
          <>
            <div style={{ marginBottom: 10 }}>
              <b>OTP</b>
            </div>
            <Form.Item
              name="OTP"
              rules={[{ required: true, message: "Please input the OTP!" }]}
              style={{ width: "100%" }}
            >
              <Input
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Confirm OTP"
                style={{ borderRadius: 10, height: 45, borderColor: roleColor }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                onClick={verifyOtp}
                style={{
                  background: roleColor,
                  borderRadius: 10,
                  height: 45,
                  border: roleColor,
                }}
                block
              >
                Confirm OTP
              </Button>
            </Form.Item>
            <h3
              style={{
                textAlign: "center",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() =>
                history.push(
                  `/${
                    forgotPassword ? "forgotPassword" : "signup"
                  }/${role}/email`
                )
              }
            >
              <b>
                Use Email to {forgotPassword ? "reset password" : "Register"}
              </b>
            </h3>
          </>
        )}
        {step === 2 && (
          <>
            <div style={{ marginBottom: 10 }}>
              <b>Password</b>
            </div>
            <Form.Item
              rules={[
                { required: true, message: "Please enter your Password!" },
              ]}
              style={{ width: "100%" }}
            >
              <Input.Password
                value={p1}
                type="password"
                onChange={(e) => setP1(e.target.value)}
                placeholder="Enter Password"
                style={{ borderRadius: 10, height: 45, borderColor: roleColor }}
              />
            </Form.Item>
            <div style={{ marginBottom: 10 }}>
              <b>Confirm Password</b>
            </div>
            <Form.Item
              rules={[
                { required: true, message: "Please confirm your Password!" },
              ]}
              style={{ borderRadius: 10, height: 45, borderColor: roleColor }}
            >
              <Input.Password
                value={p2}
                type="password"
                onChange={(e) => setP2(e.target.value)}
                placeholder="Re-enter password"
                style={{ borderRadius: 10, height: 45, borderColor: roleColor }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                onClick={signup}
                style={{
                  background: roleColor,
                  borderRadius: 10,
                  height: 45,
                  border: roleColor,
                }}
                block
              >
                {forgotPassword ? "Change password" : "Sign Up"}
              </Button>
            </Form.Item>
            <h3
              style={{
                textAlign: "center",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() =>
                history.push(
                  `/${
                    forgotPassword ? "forgotPassword" : "signup"
                  }/${role}/email`
                )
              }
            >
              <b>
                Use Email to {forgotPassword ? "reset password" : "Register"}
              </b>
            </h3>
          </>
        )}
      </Form>
      {forgotPassword ? (
        <h3
          style={{ textAlign: "center", cursor: "pointer", color: roleColor }}
          onClick={() => history.push(`/signin/${role}`)}
        >
          <b>
            Back to {`${role.charAt(0).toUpperCase() + role.slice(1)}`} sign in
          </b>
        </h3>
      ) : (
        <>
          <h3
            style={{ textAlign: "center", cursor: "pointer", color: roleColor }}
            onClick={() => history.push(`/signin/${role}`)}
          >
            <b>Already have an account? Sign in here</b>
          </h3>
          <h3
            style={{
              textAlign: "center",
              cursor: "pointer",
              color: isClient ? "#FF9480" : "#71CFD7",
            }}
            onClick={() =>
              history.push(`/signup/${isClient ? "user" : "client"}/email`)
            }
          >
            <b>
              Would you like to be a {isClient ? "user" : "client"}?{" "}
              <Popover
                content={
                  isClient
                    ? "Users are users who take surveys and get paid for it"
                    : "Clients create surveys to get their products tested"
                }
              >
                <QuestionCircleOutlined />
              </Popover>{" "}
              Click here!
            </b>
          </h3>
        </>
      )}
    </div>
  );
};

const EmailForm = (props) => {
  const { role } = useParams();
  const history = useHistory();
  const [email, setEmail] = useState();
  const { forgotPassword } = props;
  const isClient = role === "client";
  const roleColor = isClient ? "#71CFD7" : "#FF9480";

  const verify = async () => {
    try {
      var { url, method } = Routes.others.checkAvailable(
        `userType=${role}&email=${email.toLowerCase()}`
      );
      var { data } = await axios[method](url);
      console.log(data);
      if (!forgotPassword && !data.isAvailable) {
        return Modal.error({
          title: "Email Not Available!",
        });
      }
      if (forgotPassword && data.isAvailable) {
        return Modal.error({
          title: "Email Not Registered!",
        });
      }
      var { url, method } = forgotPassword
        ? Routes.verification.sendEmail(
            email.toLowerCase(),
            role + "_forgotPassword"
          )
        : Routes.verification.sendEmail(email.toLowerCase(), role + "_signup");
      incrementPendingRequests();
      var { data } = await axios[method](url);
      if (data && data.message && data.message.includes("link sent")) {
        Modal.success({
          title:
            "Please click on registration link sent to your email to complete the process. Thanks!",
        });
      }
    } catch (err) {
      console.log("ERRORRRRR");
      handleNetworkErrors(err);
    } finally {
      decrementPendingRequests();
    }
  };

  return (
    <div>
      <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
        {forgotPassword ? (
          <>
            {`${role.charAt(0).toUpperCase() + role.slice(1)}`} password reset
          </>
        ) : (
          <>
            Hey, welcome! <br />
            Please register yourself as a {role}.
          </>
        )}
      </h2>
      <Form
        style={{
          background: "white",
          borderRadius: 10,
          marginBottom: "0.5em",
          padding: "30px 20px",
          boxShadow:
            "2px 2px 8px rgba(0, 0, 0, 0.15), -2px -2px 8px rgba(0, 0, 0, 0.15)",
        }}
      >
        <div style={{ marginBottom: 10 }}>
          <b>Email</b>
        </div>
        <Form.Item style={{ width: "100%" }}>
          <Input
            placeholder="e.g. user@example.com"
            value={email}
            style={{ borderRadius: 10, height: 45, borderColor: roleColor }}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            size="large"
            onClick={() => verify()}
            style={{
              background: roleColor,
              borderRadius: 10,
              height: 45,
              border: roleColor,
            }}
            block
          >
            Send Verification Link
          </Button>
        </Form.Item>
        <h3
          style={{
            textAlign: "center",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() =>
            history.push(
              `/${forgotPassword ? "forgotPassword" : "signup"}/${role}/mobile`
            )
          }
        >
          <b>
            Use Mobile Phone to {forgotPassword ? "reset password" : "Register"}
          </b>
        </h3>
      </Form>
      {forgotPassword ? (
        <h3
          style={{ textAlign: "center", cursor: "pointer", color: roleColor }}
          onClick={() => history.push(`/signin/${role}`)}
        >
          <b>
            Back to {`${role.charAt(0).toUpperCase() + role.slice(1)}`} sign in
          </b>
        </h3>
      ) : (
        <>
          <h3
            style={{ textAlign: "center", cursor: "pointer", color: roleColor }}
            onClick={() => history.push(`/signin/${role}`)}
          >
            <b>Already have an account? Sign in here</b>
          </h3>
          <h3
            style={{
              textAlign: "center",
              cursor: "pointer",
              color: isClient ? "#FF9480" : "#71CFD7",
            }}
            onClick={() =>
              history.push(`/signup/${isClient ? "user" : "client"}/email`)
            }
          >
            <b>
              Would you like to be a {isClient ? "user" : "client"}?{" "}
              <Popover
                content={
                  isClient
                    ? "Users are users who take surveys and get paid for it"
                    : "Clients create surveys to get their products tested"
                }
              >
                <QuestionCircleOutlined />
              </Popover>{" "}
              Click here!
            </b>
          </h3>
        </>
      )}
    </div>
  );
};

const Signup = (props) => {
  const { mobile, role } = useParams();

  return (
    <Center isClient={role === "client"}>
      {mobile === "email" && <EmailForm {...props} />}
      {mobile === "mobile" && <MobileForm {...props} />}
    </Center>
  );
};

export default Signup;
