import React, { Fragment, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import ProjectLayout from "../../../components/page-layout/PageLayout";
import NewFormBox from "./NewFormBox";
import ConfirmationModal from "./CopySurveyModals/ConfirmationModal";
import PublishedSurveyListModal from "./CopySurveyModals/PublishedSurveyListModal";
import { getRoleByToken } from "../../../utils";
import handleNetworkErrors from "../handleNetworkErrors";
import axios, { Routes } from "../../../services/API";
import { notification, Button, Modal, Input } from "antd";
import {
  LeftOutlined,
  ShareAltOutlined,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import DraftFormBoxCopy from "./DraftFormBoxCopy";
import c from "classnames/bind";
import styles from "./draft-forms.module.css";
import ShareModal from "../ShareModal";

const cx = c.bind(styles);

const DraftForms = ({
  surveys,
  copySurvey,
  updateSurveys,
  deleteSurvey,
  generateNewSurvey,
  profile,
  getProject,
}) => {
  const history = useHistory();
  const { projectId } = useParams();
  const firstName = profile.firstName ? profile.firstName : "";
  const lastName = profile.lastName ? profile.lastName : "";
  const email = profile.email ? profile.email : "";
  const createdBy = firstName.length > 0 ? firstName + " " + lastName : email;

  const [copySurveyPopup, setCopySurveyPopup] = useState(false);
  const [publishedSurveyPopup, setPublishedSurveyPopup] = useState(false);
  const [publishedSurveysList, setPublishedSurveysList] = useState(
    surveys.filter((s) => s && s.isPublished === true)
  );
  const [draftSurveys, setDraftSurveys] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [isProjectOwner, setIsProjectOwner] = useState(false);
  const [editNameModal, setEditNameModal] = useState(false);
  const [tempProjectName, setTempProjectName] = useState("");
  const [deleteProjectModal, setDeleteProjectModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [sharedUsers, setSharedUsers] = useState([]);
  const [owner, setOwner] = useState("");

  useEffect(() => {
    let fetchingSurvey = true;
    getProject(projectId).then(({ project, surveys }) => {
      if (fetchingSurvey) {
        if (!project) {
          history.push("/client");
          return;
        }
        setDraftSurveys(surveys);
        setProjectName(project.name);
        setTempProjectName(project.name);
        const clientId = localStorage.getItem("client");
        setOwner(project.owner);
        setIsProjectOwner(clientId === project.owner);
        setSharedUsers(project.shared);
      }
    });

    return () => {
      fetchingSurvey = false;
    };
  }, [projectId, getProject]);

  useEffect(() => {
    let fetchingUsers = true;
    if (profile?.account?._id) {
      (async () => {
        const clientId = localStorage.getItem("client");
        const { data } = await axios({
          ...Routes.client.getAccountUsers(clientId, profile.account._id),
        });
        if (fetchingUsers) {
          setAllUsers(data.allUsers);
        }
      })();
    }

    return () => {
      fetchingUsers = false;
    };
  }, [profile?.account?._id]);

  const copyPublishedSurvey = async (survey) => {
    try {
      const id =
        getRoleByToken(localStorage.getItem("token")) === "client"
          ? localStorage.getItem("client")
          : "";
      const { url, method } = Routes.client.copySurvey(
        id,
        survey._id,
        projectId
      );
      const { data } = await axios[method](url);

      notification.success({
        message: "Survey is copied successfully",
      });
      updateSurveys();
    } catch (err) {
      handleNetworkErrors(err);
    } finally {
      setPublishedSurveyPopup(false);
    }
  };

  const closeEditModal = () => {
    setEditNameModal(false);
    setTempProjectName(projectName);
  };

  const updateProjectName = async () => {
    if (!tempProjectName) {
      notification.error({ message: "Project name cannot be empty" });
      return;
    }
    const clientId = localStorage.getItem("client");
    const { data } = await axios({
      ...Routes.client.editProjectName(clientId, projectId),
      data: { data: { projectName: tempProjectName } },
    });
    setProjectName(tempProjectName);
    closeEditModal();
    notification.success({
      message: "Project name updated successfully",
    });
  };

  const deleteProject = async () => {
    const clientId = localStorage.getItem("client");
    await axios({
      ...Routes.client.deleteProject(clientId, projectId),
    });
    notification.success({
      message: "Project deleted successfully",
    });
    history.push("/client");
  };

  const updateProjectShare = async (sharedClients) => {
    const clientId = localStorage.getItem("client");
    await axios({
      ...Routes.client.editProjectShare(clientId, projectId),
      data: { data: { shared: sharedClients } },
    });
    setSharedUsers(sharedClients);
    notification.success({
      message: "Updated shared users list for the project",
    });
  };

  return (
    <React.Fragment>
      <ProjectLayout>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            color: "#00233f",
            padding: 12,
            margin: "0 5% 24px",
            borderRadius: 10,
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              fontSize: 18,
            }}
          >
            <LeftOutlined
              onClick={() => {
                history.push(`/client`);
              }}
            />
            <span style={{ marginLeft: 20 }}>{projectName}</span>
          </div>
          {isProjectOwner && (
            <div
              style={{
                display: "flex",
                columnGap: 8,
                flexWrap: "wrap",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#dfdfdf",
                  color: "#00233f",
                  border: "none",
                  borderRadius: 5,
                  textTransform: "uppercase",
                  width: 120,
                  height: 36,
                }}
                onClick={() => setShareModal(true)}
              >
                <ShareAltOutlined /> Share
              </Button>
              <Button
                style={{
                  backgroundColor: "#dfdfdf",
                  color: "#00233f",
                  border: "none",
                  borderRadius: 5,
                  textTransform: "uppercase",
                  width: 120,
                  height: 36,
                }}
                onClick={() => setEditNameModal(true)}
              >
                <EditFilled /> Edit
              </Button>
              <Button
                style={{
                  backgroundColor: "#dfdfdf",
                  color: "#00233f",
                  border: "none",
                  borderRadius: 5,
                  textTransform: "uppercase",
                  width: 120,
                  height: 36,
                }}
                onClick={() => {
                  setDeleteProjectModal(true);
                }}
              >
                <DeleteFilled /> Delete
              </Button>
            </div>
          )}
        </div>
        <div className="grid-form-container">
          <>
            {surveys && (
              <>
                {isProjectOwner && (
                  <div
                    onClick={() => {
                      if (publishedSurveysList.length > 0) {
                        setCopySurveyPopup(true);
                      } else {
                        generateNewSurvey(projectId);
                      }
                    }}
                  >
                    <NewFormBox />
                  </div>
                )}
                <ConfirmationModal
                  handleOk={() => {
                    setCopySurveyPopup(false);
                    setPublishedSurveyPopup(true);
                  }}
                  handleCancel={() => {
                    setCopySurveyPopup(false);
                  }}
                  handleCreateNewSurvey={() => {
                    setCopySurveyPopup(false);
                    generateNewSurvey(projectId);
                  }}
                  isVisible={copySurveyPopup}
                />
                <PublishedSurveyListModal
                  surveyList={publishedSurveysList}
                  surveyListModal={publishedSurveyPopup}
                  handleOk={(survey) => copyPublishedSurvey(survey)}
                  handleCancel={() => setPublishedSurveyPopup(false)}
                />
                {draftSurveys.length > 0 &&
                  draftSurveys.map((item, idx) => (
                    <Fragment key={`fragment-${idx}`}>
                      <DraftFormBoxCopy
                        key={idx + idx}
                        name={item.name}
                        edit={() =>
                          history.push(`/client/new-survey/${item["_id"]}`)
                        }
                        deleteSurvey={() => deleteSurvey(item["_id"])}
                        surveyType={item.surveyType}
                        mode={item.mode}
                        device={item.device}
                        copySurvey={() => copySurvey(item, projectId)}
                        setting={item.setting}
                        timingType={item.timingType}
                        createdBy={createdBy}
                        updatedAt={item.updatedAt}
                        isOwner={isProjectOwner}
                        allUsers={allUsers}
                        surveySharedUsers={item.shared}
                        projectName={projectName}
                        surveyId={item._id}
                      />
                    </Fragment>
                  ))}
              </>
            )}
          </>
        </div>
      </ProjectLayout>
      <Modal
        visible={editNameModal}
        closable={false}
        footer={null}
        onCancel={closeEditModal}
      >
        <div
          style={{
            fontSize: 16,
            marginBottom: 10,
          }}
        >
          Edit Project
        </div>
        <div>
          <Input
            placeholder="Enter the project name here..."
            value={tempProjectName}
            onChange={(e) => setTempProjectName(e.target.value)}
            className={cx("input-box")}
          />
        </div>
        <div
          style={{
            paddingBottom: 24,
            marginTop: 18,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            columnGap: 8,
          }}
        >
          <Button
            style={{
              backgroundColor: "#b5b5b5",
              color: "white",
              border: "none",
              textTransform: "uppercase",
              borderRadius: 4,
              width: 150,
            }}
            onClick={closeEditModal}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#00233f",
              color: "white",
              border: "none",
              textTransform: "uppercase",
              borderRadius: 4,
              width: 150,
            }}
            onClick={updateProjectName}
          >
            Update
          </Button>
        </div>
      </Modal>
      <Modal
        visible={deleteProjectModal}
        closable={false}
        footer={null}
        onCancel={() => setDeleteProjectModal(false)}
      >
        <div
          style={{
            fontSize: 16,
            marginBottom: 10,
          }}
        >
          Delete Project
        </div>
        <p>
          Are you sure you want to delete this project? All the surveys in the
          project will be deleted.
        </p>
        <div
          style={{
            paddingBottom: 24,
            marginTop: 18,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            columnGap: 8,
          }}
        >
          <Button
            style={{
              backgroundColor: "#b5b5b5",
              color: "white",
              border: "none",
              textTransform: "uppercase",
              borderRadius: 4,
              width: 150,
            }}
            onClick={() => setDeleteProjectModal(false)}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#eb3f4a",
              color: "white",
              border: "none",
              textTransform: "uppercase",
              borderRadius: 4,
              width: 150,
            }}
            onClick={deleteProject}
          >
            Delete
          </Button>
        </div>
      </Modal>
      <ShareModal
        open={shareModal}
        onCancel={() => setShareModal(false)}
        updateSharedList={updateProjectShare}
        projectName={projectName}
        sharedUsers={sharedUsers}
        owner={owner}
        allUsers={allUsers}
      />
    </React.Fragment>
  );
};

export default DraftForms;
