import { Checkbox } from "antd";
import React, { useState } from "react";
import { difference } from "lodash";
import { capitalizeFirstCharacter } from "../../../../utils";
import "./checkboxes.css";

const Checkboxes = ({ key, choices, onChange = () => {}, value }) => {
  const [localValue, setLocalValue] = useState(value);

  const valueChanged = (checkedValue) => {
    let newValues = [...checkedValue];
    const addedValues = difference(checkedValue, localValue);
    if (addedValues.length === 1) {
      const addedValue = addedValues[0];
      if (choices[addedValue].isMcq) {
        newValues = [addedValue];
      } else {
        newValues = newValues.filter((val) => !choices[val].isMcq);
      }
    }

    setLocalValue(newValues);
    onChange(newValues);
  };

  return (
    <Checkbox.Group
      key={key}
      className="survey-checkboxes"
      value={localValue}
      onChange={valueChanged}
    >
      {choices.map((val, index) => (
        <Checkbox className="multi-choice-box" value={index}>
          {capitalizeFirstCharacter(val.label)}
        </Checkbox>
      ))}
    </Checkbox.Group>
  );
};

export default Checkboxes;
