import { useReducer, useCallback } from "react";

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD": {
      const index = action.payload.index;
      const item = action.payload.item;
      return [
        ...state.slice(0, index),
        item,
        ...state.slice(index, state.length),
      ];
    }
    case "MOVE": {
      const result = Array.from(state);
      const startIndex = action.payload.source;
      const endIndex = action.payload.dest;
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    }
    case "REMOVE": {
      const index = action.payload.index;
      return [
        ...state.slice(0, index),
        ...state.slice(index + 1, state.length),
      ];
    }
    case "SET": {
      const index = action.payload.index;
      const result = Array.from(state);
      result[index] = action.payload.item;
      return result;
    }
    case "SETALL":
      return action.payload.questions;
    default:
      throw Error("Invalid action");
  }
};

const useArray = (initialState = []) => {
  const [array, dispatch] = useReducer(reducer, initialState);

  const add = useCallback(
    (item = {}, index = array.length) => {
      dispatch({
        type: "ADD",
        payload: {
          index,
          item,
        },
      });
    },
    [dispatch, array.length]
  );

  const move = useCallback(
    (source, dest) => {
      dispatch({
        type: "MOVE",
        payload: {
          source,
          dest,
        },
      });
    },
    [dispatch]
  );

  const remove = useCallback(
    (index) => {
      dispatch({
        type: "REMOVE",
        payload: {
          index,
        },
      });
    },
    [dispatch]
  );

  const set = useCallback(
    (item, index) => {
      dispatch({
        type: "SET",
        payload: {
          item,
          index,
        },
      });
    },
    [dispatch]
  );

  return [array, { add, remove, move, set }];
};

export default useArray;
