import { notification } from "antd";
import { useDispatch } from "react-redux";
import { completeSurvey, startSurvey } from "../../../actions/users";
import { surveyStates } from "../constants/constants";
import { questionTypes } from "../constants/questions";
import {
  isModeratedSurvey,
  isQuantSurvey,
  isUnmoderatedQualSurvey,
} from "../utils/utils";
import useUploadVideoHandler from "./qualSurvey/moderatedSurvey/qual_unmoderatd/useUploadVideoHandler";
import {
  decrementPendingRequests,
  incrementPendingRequests,
  notify,
} from "../../../utils";
import { isMobile } from "react-device-detect";
import { getScheduledSlotForUser } from "../utils/moderated/schedule";
import axios, { Routes } from "../../../services/API";
import { getWordCloudAnalysis } from "../../../pages/user/constants/getWordCloudReport";
import handleNetworkErrors from "../../../pages/user/constants/handleNetworkErrors";
import { useState } from "react";

export const useSurveyEventHandlers = (survey, userId) => {
  const [startedAt, setStartedAt] = useState(null);
  const [endsAt, setEndsAt] = useState(null);
  const [slot, setSlot] = useState({});
  const [initUpload, endUpload] = useUploadVideoHandler(survey._id, userId);
  const dispatch = useDispatch();
  //const [handleCompleteSurvey] = useSurveyAPIs()

  const onSubmitLiveQuestion = async (answers) => {
    try {
      const { url, method } = Routes.user.answerModeratedSurveyQuestions(
        userId,
        survey._id
      );

      await axios[method](url, {
        data: {
          slotKey: slot.date + "_" + slot.time,
          answer: answers,
        },
      });

      notification.success({ message: "Response Submitted Successfully" });
    } catch (err) {
      handleNetworkErrors(err);
    }
  };

  const handleStartSurvey = async (surveyId, surveyAccess) => {
    incrementPendingRequests();
    dispatch(startSurvey(surveyId, surveyAccess))
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  };

  const handleEndSurvey = async (surveyId, surveyAccess, response) => {
    incrementPendingRequests();
    dispatch(completeSurvey(surveyId, surveyAccess, response))
      .then(() => notify("success", "Success", "Survey completed"))
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  };

  //returns true if the survey is started successfully else false
  const onStartSurvey = async () => {
    if (survey.status === surveyStates.PAUSE) {
      notification.info({
        message:
          "Survey is temporarily paused, Please try again after sometime or contact administrator",
      });
      return false;
    }
    setStartedAt(new Date());

    if (!isQuantSurvey(survey) && isMobile) {
      return false;
    }
    if (isUnmoderatedQualSurvey(survey)) {
      const upload_started = await initUpload();
      //test this part out
      if (!upload_started) {
        return {'upload_started': false, showPermissionDeniedPage: true};
      }
    }
    if (isModeratedSurvey(survey)) {
      const { surveyDate, surveyTime } = getScheduledSlotForUser(
        survey.moderatedSlots,
        userId
      );
      setSlot({ surveyDate, surveyTime });
    }

    handleStartSurvey(survey._id, survey.surveyAccess);
    return true;
  };

  const addWordCloudAnalysis = async (answers, questions) => {
    if (!questions?.length || !answers?.length) return;
    if (userId !== null) {
      answers = await Promise.all(
        answers.map(async (answer, index) => {
          if (
            [
              questionTypes.TEXT,
              questionTypes.IMAGE_COMMENT,
              questionTypes.VIDEO_COMMENT,
            ].indexOf(questions[index].type) > -1
          ) {
            const report = await getWordCloudAnalysis(answer, userId);
            answer.push(report);
          }
          return answer;
        })
      );
    }
    return answers;
  };

  const onEndSurvey = async (answers, questions, surveyAccess) => {
    await addWordCloudAnalysis(answers, questions);

    if (isModeratedSurvey(survey)) {
      onSubmitLiveQuestion(answers);
      return;
    }
    const endDate = new Date();
    setEndsAt(endDate);

    let response = {
      userId,
      answers,
      filePath: isUnmoderatedQualSurvey(survey)
        ? survey._id + "_" + userId + ".json"
        : "",
      startedAt,
      endsAt: endDate,
    };
    if(surveyAccess) {
      response = {
        answers,
      filePath: isUnmoderatedQualSurvey(survey)
        ? survey._id + "_" + userId + ".json"
        : "",
      startedAt,
      endsAt: endDate,
      }
    }
    if (isUnmoderatedQualSurvey(survey)) {
      endUpload();
    }
    handleEndSurvey(survey._id, survey.surveyAccess, response);
  };

  const closeSurvey = () => {
    if (isUnmoderatedQualSurvey(survey)) {
      endUpload(false);
    }
  };

  return [onStartSurvey, onEndSurvey, closeSurvey];
};
