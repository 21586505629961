import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useArray from "../../useArray";
import TextABTestChoices from "./textABTestChoices/TextABTestChoices";

const TextABTest = ({ options, setOptions, checkBox }) => {
  // const [multiChoices, { add, remove, move, set }] = useArray(
  //   options.textABTestChoices
  // );

  const textABTestChoices = options.textABTestChoices;

  const add = (item, index) => {
    setOptions({
      ...options,
      textABTestChoices: [
        ...textABTestChoices.slice(0, index),
        item,
        ...textABTestChoices.slice(index, textABTestChoices.length),
      ],
    });
  };

  const move = (source, dest) => {
    const result = Array.from(textABTestChoices);
    const [removed] = result.splice(source, 1);
    result.splice(dest, 0, removed);
    setOptions({ ...options, textABTestChoices: result });
  };

  const remove = (index) => {
    setOptions({
      ...options,
      textABTestChoices: [
        ...textABTestChoices.slice(0, index),
        ...textABTestChoices.slice(index + 1, textABTestChoices.length),
      ],
    });
  };

  const set = (item, index) => {
    const result = Array.from(textABTestChoices);
    result[index] = item;
    setOptions({ ...options, textABTestChoices: result });
  };

  const setOptionValue = (item, index) => {
    set(item, index);
  };

  return (
    <DragDropContext
      onDragEnd={(result) =>
        move(result.source.index, result.destination.index)
      }
    >
      <Droppable droppableId="list">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              paddingTop: 20,
            }}
          >
            {textABTestChoices.map((choice, index) => (
              <Draggable
                draggableId={`option-${index}`}
                index={index}
                key={index}
              >
                {(provided) => (
                  <div
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    gutter={6}
                  >
                    <TextABTestChoices
                      dragHandleProps={provided.dragHandleProps}
                      choiceNumber={index + 1}
                      choice={choice}
                      checkBox={checkBox}
                      setValue={(item) => setOptionValue(item, index)}
                      isFilled={{
                        inputWord: choice.inputWord !== "",
                        percentage:
                          textABTestChoices
                            .filter((choice) => choice && choice !== "")
                            .reduce(
                              (sum, choice) =>
                                parseInt(choice.percentage) + sum,
                              0
                            ) === 100,
                      }}
                      totalChoices={textABTestChoices.length}
                      addChoice={(newIndex) =>
                        add({ inputWord: "", percentage: "" }, newIndex)
                      }
                      removeChoice={(index) => remove(index - 1)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default TextABTest;
