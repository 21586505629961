export default {
  signin: () => {
    return {
      url: `admin/signin`,
      method: `post`,
    };
  },
  editAdmin: (id) => {
    return {
      url: `admin/${id}`,
      method: `put`,
    };
  },
  getAdminQuestions: (id) => {
    return {
      url: `admin/${id}/adminQuestion`,
      method: `get`,
    };
  },
  addAdminQuestion: (id) => {
    return {
      url: `admin/${id}/adminQuestion`,
      method: `post`,
    };
  },
  updateAdminQuestions: (id) => {
    return {
      url: `admin/${id}/adminQuestion`,
      method: `put`,
    };
  },
  getAdminQuestion: (id) => {
    return {
      url: `admin/${id}/adminQuestion/:adminQuestionId`,
      method: `get`,
    };
  },
  updateAdminQuestion: (id, questionId) => {
    return {
      url: `admin/${id}/adminQuestion/${questionId}`,
      method: `put`,
    };
  },
  getClients: (id) => {
    return {
      url: `admin/${id}/client`,
      method: `get`,
    };
  },
  getClient: (id, clientId) => {
    return {
      url: `admin/${id}/client/${clientId}`,
      method: `get`,
    };
  },
  addClient: (id) => {
    return {
      url: `admin/${id}/client`,
      method: `post`,
    };
  },
  editClient: (id, clientId) => {
    return {
      url: `admin/${id}/client/${clientId}`,
      method: `put`,
    };
  },
  getSurveys: (id) => {
    return {
      url: `admin/${id}/survey`,
      method: `get`,
    };
  },
  getSurvey: (id, surveyId) => {
    return {
      url: `admin/${id}/survey/${surveyId}`,
      method: `get`,
    };
  },
  editSurvey: (id, surveyId) => {
    return {
      url: `admin/${id}/survey/${surveyId}`,
      method: `put`,
    };
  },
  verifySurvey: (id, surveyId) => {
    return {
      url: `admin/${id}/survey/${surveyId}/verifySurvey`,
      method: `post`,
    };
  },
  getInfluencers: (id) => {
    return {
      url: `admin/${id}/influencer`,
      method: `get`,
    };
  },
  updateInfluencers: (id) => {
    return {
      url: `admin/${id}/influencer`,
      method: `put`,
    };
  },
  createInfluencer: (id) => {
    return {
      url: `admin/${id}/influencer`,
      method: `post`,
    };
  },
  getInfluencer: (id, influencerId) => {
    return {
      url: `admin/${id}/influencer/${influencerId}`,
      method: `get`,
    };
  },
  editInfluencer: (id, influencerId) => {
    return {
      url: `admin/${id}/influencer/${influencerId}`,
      method: `put`,
    };
  },
  deleteInfluencer: (id, influencerId) => {
    return {
      url: `admin/${id}/influencer/${influencerId}`,
      method: `delete`,
    };
  },
};
