import { notification } from "antd";

const handleNetworkErrors = (err) => {
  if (err.response) {
    console.log(err.response);
    const messageExists =
      err.response.data && err.response.data.message ? true : false;
    const errorExists =
      err.response.data &&
      err.response.data.error &&
      err.response.data.error.message
        ? true
        : false;
    let desc = "Unknown Error";
    if (messageExists && !errorExists) {
      desc = err.response.data.message;
    } else if (!messageExists && errorExists) {
      desc = err.response.data.error.message;
    }
    notification.error({
      message: desc,
      description: `${err.response.status} ${err.response.statusText}`,
    });
  } else if (err.request) {
    notification.info({
      message: "Can't connect with the server",
      description: "Seems like there is no internet connectivity.",
    });
  } else {
    notification.error({
      message: "Unknown error occurred",
      description: "Please try again",
    });
  }
};

export default handleNetworkErrors;
