import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import {
  Table,
  Modal,
  Dropdown,
  Menu,
  Button,
  Checkbox,
  Card,
  List,
  Radio,
} from "antd";
import { FilterFilled, CloseSquareFilled } from "@ant-design/icons";
import moment from "moment";
import "./CopyPanelModal.css";
import "./CopyPanelModal.module.css";
import styles from "./CopyPanelModal.module.css";
import c from "classnames/bind";
import axios, { Routes } from "../../../../../services/API";
import { getClientId } from "../../../../../utils";
import handleNetworkErrors from "../../../handleNetworkErrors";
const RadioGroup = Radio.Group;
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const cx = c.bind(styles);

const filters = [
  {
    text: "Qualitative",
    value: "Qualitative",
  },
  {
    text: "Quantitative",
    value: "Quantitative",
  },
  {
    text: "Moderated",
    value: "Moderated",
  },
  {
    text: "UnModerated",
    value: "UnModerated",
  },
  /* {
    text: "Mobile",
    value: "Mobile",
  },
  {
    text: "Desktop",
    value: "Desktop",
  }, */
  {
    text: "Group",
    value: "Group",
  },
  {
    text: "Individual",
    value: "Individual",
  },
  {
    text: "15 min",
    value: "15 min",
  },
  {
    text: "30 min",
    value: "30 min",
  },
  {
    text: "45 min",
    value: "45 min",
  },
  {
    text: "Custom Panels",
    value: "Custom Panels",
  },
];

const CopyPanelModal = ({ surveyList, isVisible, handleOk, handleCancel }) => {
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [radioState, setRadioState] = useState(null);
  const [customPanels, setCustomPanels] = useState([]);
  const timing = {
    1: "15 min",
    2: "30 min",
    3: "45 min",
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios({
          ...Routes.client.getPanels(getClientId()),
        });
        setCustomPanels(data.panels);
      } catch (err) {
        handleNetworkErrors(err);
      }
    })();
  }, []);

  const surveyListColumns = [
    {
      title: "Name",
      dataIndex: "name",
      fixed: true,
      width: 100,
    },
    {
      title: "Published Date",
      dataIndex: "date",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      render: (date) => moment(date).format("DD MMM YYYY"),
      align: "center",
    },
    {
      title: "Survey Type",
      dataIndex: "type",
    },
    Table.SELECTION_COLUMN,
  ];

  const getSurveyType = (survey) => {
    let type = "";
    type += survey.surveyType ? "Qual" : "Quant";
    if (survey.surveyType) {
      type += "/" + (survey.mode ? "Mod" : "unmod");
      type += "/" + (survey.device ? "Mobile" : "Desktop");
      if (survey.mode) {
        type += "/" + (survey.setting ? "Group" : "Individual");
      }
      type += "/" + timing[survey.timingType];
    }
    return type;
  };

  const getSurveyTags = (survey) => {
    let tags = [];
    tags.push(survey.surveyType ? "Qualitative" : "Quantitative");
    if (survey.surveyType) {
      tags.push(survey.mode ? "Moderated" : "UnModerated");
      tags.push(survey.device ? "Mobile" : "Desktop");
      if (survey.mode) {
        tags.push(survey.setting ? "Group" : "Individual");
      }
      tags.push(timing[survey.timingType]);
    }
    return tags;
  };

  const handleRadioSelection = (e) => {
    const surveyObj = {
      ...e.target.value.survey,
      isSurvey: e.target.value.isSurvey,
    };
    setSelectedSurvey(surveyObj);
  };

  const getSurveyData = () => {
    return surveyList
      .filter((publishedSurvey) => publishedSurvey?.responses?.length)
      .map((publishedSurvey, index) => {
        return {
          key: publishedSurvey._id,
          name: publishedSurvey.name,
          date: publishedSurvey.createdAt,
          type: getSurveyType(publishedSurvey),
          tags: getSurveyTags(publishedSurvey),
          survey: publishedSurvey,
          isSurvey: true,
        };
      })
      .filter(({ tags }) => {
        if (appliedFilters.length === 0) {
          return true;
        }
        if (
          appliedFilters.length === 1 &&
          appliedFilters.includes("Custom Panels")
        ) {
          return false;
        }
        return appliedFilters.some((f) => tags.includes(f));
      });
  };

  const includeCustomPanels = () => {
    if (
      appliedFilters.length === 0 ||
      appliedFilters.includes("Custom Panels")
    ) {
      return true;
    }
    return false;
  };

  const getCustomPanelsData = () => {
    return includeCustomPanels()
      ? customPanels
          .filter((customPanel) => customPanel?.users?.length)
          .map((customPanel, index) => {
            return {
              key: customPanel._id,
              name: customPanel.name,
              date: "",
              type: "",
              tags: ["Custom Panel"],
              survey: customPanel,
              isSurvey: false,
            };
          })
      : [];
  };

  const surveyListData = [...getSurveyData(), ...getCustomPanelsData()];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const surveyObj = {
        ...selectedRows[0].survey,
        isSurvey: selectedRows[0].isSurvey,
      };
      setSelectedSurvey(surveyObj);
    },
  };

  return (
    <Fragment>
      <Modal
        visible={isVisible}
        onOk={() => handleOk(selectedSurvey)}
        onCancel={handleCancel}
        width={600}
        centered={true}
        className={cx("published-survey-popup")}
        okText="Use Panel"
        cancelText="Cancel"
        closable={false}
        okButtonProps={{
          size: "large",
          className: "yesButton",
          disabled: selectedSurvey ? false : true,
        }}
        cancelButtonProps={{ size: "large", className: "noButton" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 25px",
          }}
        >
          <div style={{ fontSize: 18 }}>Select any one past survey</div>
          <Dropdown
            overlay={
              <Menu>
                <Checkbox.Group
                  value={appliedFilters}
                  onChange={setAppliedFilters}
                >
                  {filters.map(({ text, value }) => {
                    return (
                      <Menu.Item key={value}>
                        <Checkbox value={value}>{text}</Checkbox>
                      </Menu.Item>
                    );
                  })}
                </Checkbox.Group>
              </Menu>
            }
            trigger={["click"]}
            visible={filterVisible}
            onVisibleChange={setFilterVisible}
            placement="bottomCenter"
          >
            <Button
              style={{
                backgroundColor: "#00233f",
                color: "white",
                borderRadius: 4,
                height: 32,
                textTransform: "uppercase",
              }}
            >
              <FilterFilled /> Filter
            </Button>
          </Dropdown>
        </div>
        <RadioGroup
          defaultValue=""
          onChange={handleRadioSelection}
          style={{ width: "100%" }}
          className={cx("radioGroup")}
        >
          <List
            itemLayout="vertical"
            size="large"
            pagination={{
              pageSize: 3,
              showSizeChanger: false,
              width: "100%",
            }}
            dataSource={surveyListData}
            renderItem={(item) => {
              return (
                <List.Item key={item.title}>
                  <Card
                    bordered={false}
                    style={{
                      overflowX: "auto",
                      width: "auto",
                      borderRadius: "10px",
                      background: "#EEEEEE",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            color: "#013B69",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: "1.5rem",
                            }}
                          >
                            {item.date && new Date(item.date).getUTCDate()}
                          </div>
                          <div
                            style={{ fontWeight: "bold", lineHeight: "0px" }}
                          >
                            {item.date &&
                              months[new Date(item.date).getUTCMonth()]}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "35px",
                          }}
                        >
                          <div
                            style={{ fontSize: "1.0rem", fontWeight: "600" }}
                          >
                            {item.name}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              opacity: "70%",
                              fontSize: "0.8rem",
                            }}
                          >
                            {item.tags.map((it, index) => {
                              return index === item.tags.length - 1 ? (
                                <div>{it}</div>
                              ) : (
                                <div>{it}/</div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="radio-buttons">
                        <Radio value={item}></Radio>
                      </div>
                    </div>
                  </Card>
                </List.Item>
              );
            }}
          />
        </RadioGroup>
      </Modal>
    </Fragment>
  );
};

export default CopyPanelModal;
