import { Checkbox } from "antd";
import React, { useState } from "react";
import { capitalizeFirstCharacter } from "../../../../utils";

const CheckboxesSpecial = ({
  key,
  choices,
  specialField,
  onChange = () => {},
  value,
  color,
}) => {
  const [checkedList, setCheckedList] = useState(value ? value : []);

  const onValuesChange = (checkedValue) => {
    if (checkedValue[checkedValue.length - 1] === specialField) {
      const newCheckedList = checkedValue.filter((val) => val === specialField);
      setCheckedList(newCheckedList);
      onChange(newCheckedList);
    } else {
      const newCheckedList = checkedValue.filter((val) => val !== specialField);
      setCheckedList(newCheckedList);
      onChange(newCheckedList);
    }
  };
  return (
    <Checkbox.Group
      key={key}
      className="survey-checkboxes"
      onChange={onValuesChange}
      value={checkedList}
    >
      {choices.map((val, index) => (
        <Checkbox
          style={{
            color: color ? color : "#004479",
            fontSize: 16,
            marginLeft: 25,
            lineHeight: 2.3,
          }}
          value={index}
        >
          {capitalizeFirstCharacter(val)}
        </Checkbox>
      ))}
    </Checkbox.Group>
  );
};

export default CheckboxesSpecial;
