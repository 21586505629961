import React from "react";
import { Link } from "react-router-dom";
import footer_wave from "../../../assets/footer_wave.png";
import "./Footer.css";
import { Col, Row } from "antd";

const footerLinks = [
  { title: "Home", link: "/" },
  { title: "About UserQual", link: "/" },
  { title: "FAQ", link: "/" },
  { title: "Privacy and T&Cs", link: "/" },
  { title: "Blog", link: "/" },
];

export default function Footer() {
  return (
    <div
      className="footer-layout"
      style={{
        height: "45vh",
        background: `url(${footer_wave})`,
        position: "relative",
      }}
    >
      <div className="desktop-footer content">
        <div className="section_1">
          {footerLinks.map((link) => {
            return (
              <Link to={link.link} className="footer_link">
                {link.title}
              </Link>
            );
          })}
        </div>
        <div className="section_2">
          <Link to="/" className="footer_link_2">
            Happy Testing !
          </Link>
        </div>
      </div>
      <div className="mobile-footer content">
        <Row className="section_1" justify={"start"}>
          {footerLinks.map((link, index) => {
            return (
              <Col
                key={link.title}
                xs={12}
                className={(index + 1) % 2 === 0 ? "right-text" : "left-text"}
              >
                <Link to={link.link} className="footer_link">
                  {link.title}
                </Link>
              </Col>
            );
          })}
        </Row>
        <div className="section_2">
          <Link to="/" className="footer_link_2">
            Happy Testing !
          </Link>
        </div>
      </div>
    </div>
  );
}
