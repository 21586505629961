import { Checkbox } from "antd";
import React from "react";
import { capitalizeFirstCharacter } from "../../../../utils";

const Checkboxes = ({ key, choices, onChange = () => {}, value, color }) => {
  return (
    <Checkbox.Group
      key={key}
      className="survey-checkboxes"
      onChange={(checkedValue) => onChange(checkedValue)}
    >
      {choices.map((val, index) => (
        <Checkbox
          style={{
            color: color ? color : "#004479",
            fontSize: 16,
            marginLeft: 25,
            lineHeight: 2.3,
          }}
          value={index}
        >
          {capitalizeFirstCharacter(val)}
        </Checkbox>
      ))}
    </Checkbox.Group>
  );
};

export default Checkboxes;
