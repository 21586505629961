export const GROUP_SLOTS = 5;
export const SLOT_TIME_HRS = 1;

export const surveyStates = {
  IN_PROGRESS: "inProgress",
  PAUSE: "paused",
  END: "ended",
};

export const times = [
  { label: "8am", value: "08:00" },
  { label: "9am", value: "09:00" },
  { label: "10am", value: "10:00" },
  { label: "11am", value: "11:00" },
  { label: "12pm", value: "12:00" },
  { label: "1pm", value: "13:00" },
  { label: "2pm", value: "14:00" },
  { label: "3pm", value: "15:00" },
  { label: "4pm", value: "16:00" },
  { label: "5pm", value: "17:00" },
  { label: "6pm", value: "18:00" },
  { label: "7pm", value: "19:00" },
];

export const surveyTypes = {
  QUANTITATIVE: "quantitative",
  QUALITATIVE_UNMODERATED: "qualitative/unModerated",
  QUALITATIVE_MODERATED: "qualitative/moderated",
};

export const surveyAccess = {
  OPEN: 'open',
  CLOSED: 'closed'
}
