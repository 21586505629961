import React, { useState } from "react";
import styles from "./VideoInstruction.module.css";
import c from "classnames/bind";
import {
  CheckOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Input, Tooltip, Upload, message, Button, Spin } from "antd";
import axios, { Routes } from "../../../../services/API";
import { GetFilename } from "../../../../constants";

const cx = c.bind(styles);

const VideoInstruction = ({ options, setOptions }) => {
  const [uploading, setUploading] = useState(false);
  const uploadProps = {
    accept: ".mp4,.webm,.ogg",
    customRequest: async function ({ file, onError }) {
      const userId = localStorage.getItem("client");
      const { name, type } = file;
      setUploading(true);
      const { data } = await axios({
        ...Routes.client.uploadFile(userId),
        data: { data: { name, type } },
      });
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", data.signedRequest);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            options.videoData = data.url;
            setOptions({ ...options, videoData: data.url });
            message.success(`${file.name} file uploaded successfully`);
            setUploading(false);
          } else {
            onError();
            message.error(`${file.name} file upload failed.`);
            setUploading(false);
          }
        }
      };
      xhr.send(file);
    },
  };

  return (
    <div className={cx("grid-container")}>
      <div className={cx("gridInputs")} style={{ padding: "0 0 0 30px" }}>
        <div className={cx("secondField")}>
          <Upload
            className={cx("uploadButton")}
            {...uploadProps}
            showUploadList={false}
          >
            <Button style={{ height: "100%" }}>
              <Spin style={{ background: "none" }} spinning={uploading}>
                Upload Video
              </Spin>
            </Button>
          </Upload>
          <Input
            size="large"
            placeholder="No Video Chosen"
            style={{ borderRadius: 3 }}
            value={GetFilename(options.videoData, 10)}
            disabled
            onChange={(event) =>
              setOptions({ ...options, videoData: event.target.value })
            }
            suffix={
              options.videoData !== "" ? (
                <CheckOutlined style={{ color: "green" }} />
              ) : (
                <Tooltip title="You need to fill this option!">
                  <InfoCircleOutlined style={{ color: "#ff9480" }} />
                </Tooltip>
              )
            }
            className="input-border"
          />
        </div>
        <div>
          <Input
            size="large"
            placeholder="Video Label (Optional)"
            style={{ borderRadius: 3 }}
            value={options.videoLabel}
            onChange={(event) =>
              setOptions({ ...options, videoLabel: event.target.value })
            }
            suffix={
              options.videoLabel !== "" ? (
                <CheckOutlined style={{ color: "green" }} />
              ) : (
                <span></span>
              )
            }
            className="input-border"
          />
        </div>
      </div>
    </div>
  );
};

export default VideoInstruction;
