import React, { useEffect, useState } from "react";
import styles from "./timeslot.module.css";
import c from "classnames/bind";
import Modal from "react-modal";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import {
  decrementPendingRequests,
  incrementPendingRequests,
  notify,
} from "../../../utils";
import { useDispatch } from "react-redux";
import { confirmTimeSlot } from "../../../actions/users";
import { useMediaQuery } from "react-responsive";
import { getDateObject, times } from "../constants/commonFunctions";
import { Fragment } from "react";

const modalStyles = {
  content: {
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    border: 0,
    padding: 0,
    borderRadius: 0,
  },
};

const cx = c.bind(styles);

const filterSlots = (moderatedSlots, setting) => {
  const userId = localStorage.getItem("user");
  for (const date in moderatedSlots) {
    for (const time in moderatedSlots[date]) {
      if (
        moderatedSlots[date][time].length > 0 &&
        setting === false &&
        !moderatedSlots[date][time].includes(userId)
      ) {
        delete moderatedSlots[date][time];
      } else if (
        moderatedSlots[date][time].length === 5 &&
        setting === true &&
        !moderatedSlots[date][time].includes(userId)
      ) {
        delete moderatedSlots[date][time];
      } else if (
        new Date() > getDateObject(date, time) &&
        !moderatedSlots[date][time].includes(userId)
      ) {
        delete moderatedSlots[date][time];
      }
    }
    if (Object.entries(moderatedSlots[date]).length === 0) {
      delete moderatedSlots[date];
    }
  }
  return moderatedSlots;
};

const TimeSlot = ({ isOpen, onClose, slots, surveyId, prevSlot, setting }) => {
  const dispatch = useDispatch();
  const [selectedDate, setSelecteDate] = useState(prevSlot.date);
  const [selectedTime, setSelectedTime] = useState(prevSlot.time);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 400px" });
  const newSlots = filterSlots(JSON.parse(JSON.stringify(slots)), setting);
  console.log(newSlots, "slots");
  const date = newSlots && Object.keys(newSlots);
  console.log("dates", date);
  // const time = !!selectedDate && Object.keys(newSlots[selectedDate]);

  // useEffect(() => {
  //   if (!selectedDate) {
  //     setSelectedTime(undefined);
  //   }
  // }, [selectedDate]);

  // DATE
  const DateDiv = ({ children, value }) => {
    return (
      <div
        style={{
          display: "inline-block",
          color: selectedDate !== value ? "white" : "#004479",
          backgroundColor: selectedDate !== value ? "#004479" : "white",
          marginBottom: 5,
          borderRadius: 5,
          cursor: "pointer",
          margin: "15px 0px",
          height: "fit-content",
          textAlign: "center",
          padding: 5,
        }}
        onClick={() => {
          // setSelecteDate(value);
          // setSelectedTime(undefined);
        }}
      >
        {children}
      </div>
    );
  };

  // TIME
  const TimeDiv = ({ children, value }) => {
    const isActive = selectedDate === value.date && selectedTime === value.time;
    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + 2);
    const isDisabled =
      getDateObject(value.date, value.time) <= currentDate && !isActive;
    return (
      !isDisabled && (
        <div
          style={{
            display: "inline-block",
            color: isActive ? "#004479" : "white",
            border: isActive ? "1px solid #004479" : "1px solid white",
            backgroundColor: isActive ? "white" : "#004479",
            marginBottom: 5,
            marginRight: 5,
            borderRadius: 5,
            cursor: "pointer",
            width: isMobile ? 50 : 70,
            textAlign: "center",
            padding: 5,
          }}
          onClick={() => {
            console.log("values", value);
            if (value.date === selectedDate && value.time === selectedTime) {
              setSelecteDate(null);
              setSelectedTime(null);
            } else {
              setSelecteDate(value.date);
              setSelectedTime(value.time);
            }
          }}
        >
          {times.find((x) => x.value === children).label}
        </div>
      )
    );
  };

  // Handlers
  const onConfirmSlot = () => {
    if (!selectedDate) {
      notify("error", "Error", "Please select Date and Time");
      return;
    }
    if (!selectedTime) {
      notify("error", "Error", "Please select time");
      return;
    }
    incrementPendingRequests();
    dispatch(
      confirmTimeSlot(surveyId, {
        date: selectedDate,
        time: selectedTime,
        prevDate: prevSlot.date,
        prevTime: prevSlot.time,
      })
    )
      .then(() => {
        notify("success", "Success", "Time slot confirmed");
        onClose();
      })
      .catch((ex) => notify("error", "Error", ex))
      .finally(() => decrementPendingRequests());
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyles}>
      <div className={cx("survey-container")}>
        <div className={cx("title")}>
          <span style={{ flexGrow: 1, textAlign: "center" }}></span>
          <CloseCircleOutlined
            onClick={() => onClose()}
            style={{
              fontSize: 32,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          />
        </div>
        <div className={cx("center")}>
          <p style={{ textAlign: "center", fontSize: 18 }}>
            Please select a time for Moderated Video Survey
          </p>
          <div className={cx("time-selector")} style={{ fontSize: "1rem" }}>
            <div>
              <div style={{ textAlign: "center" }}>Date</div>
              <div style={{ textAlign: "center" }}>Time</div>
            </div>
            {date &&
              date.map((d, index) => (
                <div>
                  <DateDiv value={d}>{d}</DateDiv>
                  <div key={index} style={{ margin: "15px 0px" }}>
                    {Object.keys(newSlots[d])
                      .sort()
                      .map(
                        (val, i) => (
                          console.log(newSlots[d], "val"),
                          (
                            <TimeDiv
                              key={i}
                              value={{
                                date: d,
                                time: val,
                              }}
                            >
                              {val}
                            </TimeDiv>
                          )
                        )
                      )}
                  </div>
                </div>
              ))}
          </div>
          {/* <Row gutter={[6, 6]} style={{ marginTop: 20 }}>
            <Col
              xs={24}
              sm={24}
              md={12}
              style={{ textAlign: isTabletOrMobile ? "left" : "center" }}
            >
              <div className={cx("box")}>Date</div>
              <div className={cx("date-box")}>
                {date &&
                  date.map((val) => <DateDiv value={val}>{val}</DateDiv>)}
              </div>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <div className={cx("box")}>Time</div>
              {date &&
                time &&
                date.map((d, index) => (
                  <div key={index} style={{ margin: "15px 0px" }}>
                    {Object.keys(newSlots[d]).map((val, i) => (
                      <TimeDiv key={i} value={d + "+" + val}>
                        {val}
                      </TimeDiv>
                    ))}
                  </div>
                ))}
            </Col>
          </Row> */}
          <div style={{ textAlign: "center" }}>
            <Button
              type="primary"
              className="start-survey"
              size="large"
              onClick={onConfirmSlot}
              disabled={!(selectedDate && selectedTime)}
              style={
                !(selectedDate && selectedTime)
                  ? {
                      backgroundColor: "#BFBFBF",
                      color: "white",
                      borderRadius: 10,
                    }
                  : {
                      backgroundColor: "white",
                      color: "#004479",
                      borderRadius: 10,
                    }
              }
            >
              Confirm availability
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TimeSlot;
