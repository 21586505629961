import { MenuOutlined } from "@ant-design/icons";
import React from "react";
import { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./ranking.css";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ListItem = ({ dragHandleProps, title, number }) => {
  return (
    <div
      style={{
        background: "#0b385f",
        textAlign: "left",
        padding: 10,
        display: "flex",
        alignItems: "center",
        borderRadius: 10,
      }}
    >
      <MenuOutlined {...dragHandleProps} style={{ marginRight: 10 }} />
      <div>
        <span className="list-numberCircle">{number}</span>
      </div>
      <span>{title}</span>
    </div>
  );
};

const Ranking = ({ key, onChange = () => {}, value }) => {
  const [list, setList] = useState(value.map((val) => val.label));

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(list, result.source.index, result.destination.index);

    setList(items);
    onChange(items.map((val) => ({ label: val })));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} key={key}>
      <Droppable droppableId="ranking">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "0px auto",
              maxWidth: 475,
            }}
          >
            {list.map((field, index) => (
              <Draggable
                draggableId={index.toString()}
                key={index.toString()}
                index={index}
              >
                {(provided) => (
                  <div
                    className="ranking-box"
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                  >
                    <ListItem
                      title={field}
                      number={index + 1}
                      dragHandleProps={provided.dragHandleProps}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Ranking;
