import React, { useState } from "react";
import { Checkbox } from "antd";
import Thumbnail from "../video-thumbnail/VideoThumbnail";
import { difference } from "lodash";
import { useMediaQuery } from "react-responsive";

const VideoCheckboxes = ({ key, options, value, onChange = () => {} }) => {
  const [localValue, setLocalValue] = useState(value);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const valueChanged = (checkedValue) => {
    let newValues = [...checkedValue];
    const addedValues = difference(checkedValue, localValue);
    if (addedValues.length === 1) {
      const addedValue = addedValues[0];
      if (options.videoMultiChoices[addedValue].isMcq) {
        newValues = [addedValue];
      } else {
        newValues = newValues.filter(
          (val) => !options.videoMultiChoices[val].isMcq
        );
      }
    }

    setLocalValue(newValues);
    onChange(newValues);
  };

  return (
    <Checkbox.Group
      key={key}
      className="img-checkboxes"
      defaultValue={value}
      value={localValue}
      onChange={valueChanged}
    >
      {options.videoMultiChoices.map((val, index) => (
        <div className="img-multi-option-container">
          <Checkbox value={index}></Checkbox>
          <div style={{ marginLeft: 16 }}>
            <Thumbnail url={val.videoData} />
          </div>
          <div className="img-checkboxes-label">{val.videoLabel}</div>
        </div>
      ))}
    </Checkbox.Group>
  );
};

export default VideoCheckboxes;
