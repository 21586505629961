import React from "react";
import { Upload, Button } from "antd";
import * as XLSX from "xlsx";
import styles from "./UploadCSV.module.css";
import c from "classnames/bind";
import { notify } from "../../../utils";
import { EMAIL_VALIDATION_REGEX } from "../../../constants";

const cx = c.bind(styles);

const isValidFile = (fileType) => {
  const validFileTypes = [
    ".csv",
    ".xlsx",
    ".xls",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
  ];
  if (validFileTypes.includes(fileType)) return true;
  return false;
};

const UploadCSV = ({ clientProvidedUsers, setClientProvidedUsers }) => {
  const props = {
    accept: ".csv, .xlsx, .xls",
    beforeUpload: (file) => {
      if (!isValidFile(file.type)) {
        notify("error", "Error", "Invalid file type");
        return Upload.LIST_IGNORE;
      }
      const reader = new FileReader();
      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        let users = data.split("\n").join(";").split(";").join(",").split(",");
        users = users.map((user) => user.trim());
        users = users.filter(
          (email) =>
            email !== "" &&
            email !== " " &&
            EMAIL_VALIDATION_REGEX.test(email) &&
            clientProvidedUsers.indexOf(email) === -1
        );
        users = [...new Set(users)];
        setClientProvidedUsers([...clientProvidedUsers, ...users]);
      };
      reader.readAsBinaryString(file);
      return false;
    },
  };

  return (
    <div className={cx("upload-csv")}>
      <Upload maxCount={1} {...props}>
        <Button
          style={{
            backgroundColor: "#00233f",
            color: "white",
            border: "none",
            borderRadius: 4,
            height: 36,
            textTransform: "uppercase",
          }}
        >
          Upload CSV
        </Button>
      </Upload>
    </div>
  );
};

export default UploadCSV;
