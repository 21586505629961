import React, { Fragment, useState } from "react";
import { notify } from "../../../utils";
import { DeleteOutlined } from "@ant-design/icons";
import { Switch, Tag, Row, Col, Input } from "antd";
import UploadCSV from "./UploadCSV";
import { BASEURL, EMAIL_VALIDATION_REGEX } from "../../../constants";
import c from "classnames/bind";
import styles from "./criteria.module.css";

const cx = c.bind(styles);

const ImportParticipants = ({
  useImportedUsers,
  setUseImportedUsers,
  clientProvidedUsers,
  setClientProvidedUsers,
}) => {
  const [userEmail, setUserEmail] = useState("");

  return (
    <Fragment>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Row justify="space-between">
            <Col span={18}>
              <div style={{ margin: 0, fontSize: "1rem", fontWeight: "bold" }}>
                Import your participants
              </div>
            </Col>
            <Col>
              <Switch
                checked={!!useImportedUsers}
                onChange={(checked) => {
                  if (!clientProvidedUsers) {
                    setClientProvidedUsers([]);
                  }
                  setUseImportedUsers(checked);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {useImportedUsers && (
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Row justify="space-between">
              <Col span={18}>
                <h3 style={{ margin: 0 }}>Bulk upload from excel file</h3>
                <div>
                  Upload .csv file with the list of users.{" "}
                  <a
                    href={`${BASEURL}/downloads/client/sample.csv`}
                    style={{
                      textDecoration: "underline",
                    }}
                    download
                  >
                    Download sample
                  </a>
                </div>
              </Col>
              <Col>
                <UploadCSV
                  clientProvidedUsers={clientProvidedUsers}
                  setClientProvidedUsers={setClientProvidedUsers}
                />
              </Col>
            </Row>
          </Col>
          <Col
            xs={24}
            sm={18}
            style={{
              marginTop: 20,
            }}
          >
            <h3 style={{ margin: 0 }}>Add individual users</h3>
            <Input
              placeholder="Enter email and hit enter"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  if (!EMAIL_VALIDATION_REGEX.test(userEmail)) {
                    notify("error", "Error", "Invalid email");
                    return;
                  }
                  if (
                    !!clientProvidedUsers &&
                    clientProvidedUsers.includes(userEmail)
                  ) {
                    notify("error", "Error", "Email already added");
                    return;
                  }
                  setClientProvidedUsers([...clientProvidedUsers, userEmail]);
                  setUserEmail("");
                }
              }}
              className={cx("user-email-input")}
              size="large"
            />
          </Col>
          {clientProvidedUsers?.length > 0 && (
            <Col
              span={24}
              style={{
                marginTop: 20,
                marginLeft: 8,
                marginRight: 8,
                backgroundColor: "#ecf9fa",
                padding: 16,
                display: "flex",
                flexWrap: "wrap",
                maxWidth: "calc(100% - 16px)",
              }}
            >
              {clientProvidedUsers.map((emailId, index) => (
                <Tag
                  color={"#8edad0"}
                  key={index}
                  closable
                  closeIcon={
                    <DeleteOutlined
                      style={{
                        fontSize: 14,
                        color: "white",
                        marginLeft: 8,
                      }}
                    />
                  }
                  onClose={(e) => {
                    e.preventDefault();
                    setClientProvidedUsers(
                      clientProvidedUsers.filter((user) => user !== emailId)
                    );
                  }}
                  style={{
                    fontSize: 14,
                    marginBottom: 10,
                  }}
                >
                  {emailId}
                </Tag>
              ))}
            </Col>
          )}
        </Row>
      )}
    </Fragment>
  );
};

export default ImportParticipants;
