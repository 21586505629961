import React, { useState, useEffect } from "react";
import styles from "./settings.module.css";
import c from "classnames/bind";
import ProjectPageLayout, {
  Content,
  InnerContainer,
} from "../../../components/page-layout/PageLayout";
import { Form, Radio, Checkbox, Button, Input, Select, Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  userPages,
  ACCESS_LEVEL,
  EMAIL_VALIDATION_REGEX,
} from "../../../constants";
import Sidenav from "../../../components/user/sidenav/Sidenav";
import Topbar from "../../../components/topbar/Topbar";
import "./settings.css";
import { GreenRadio, CustomModal } from "../../client/Components";
import { capitalizeFirstCharacter, notify, fetchUsers } from "../../../utils";
import axios, { Routes } from "../../../services/API";
import { useMediaQuery } from "react-responsive";

const cx = c.bind(styles);

const accessOptions = [
  {
    value: ACCESS_LEVEL.create,
    text: "Create Access",
  },
  {
    value: ACCESS_LEVEL.publish,
    text: "Publish Access",
  },
  {
    value: ACCESS_LEVEL.admin,
    text: "Admin Access",
  },
];

const plans = [
  {
    value: "basic",
    text: "Basic (Free)",
  },
  {
    value: "premium",
    text: "Premium",
  },
  {
    value: "elite",
    text: "Elite",
  },
];

const InviteUser = ({ inviteUser }) => {
  const [email, setEmail] = useState("");
  const [access, setAccess] = useState(ACCESS_LEVEL.create);

  async function onInviteClick() {
    if (!email || !EMAIL_VALIDATION_REGEX.test(email)) {
      notify("error", "Error", "Invalid email");
      return;
    }
    await inviteUser(email.toLowerCase(), access);

    setEmail("");
    setAccess(ACCESS_LEVEL.create);
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
        borderRadius: 8,
        columnGap: 8,
        rowGap: 8,
        padding: 8,
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          bordered={false}
          className={cx("input-box")}
          placeholder="Enter user email here to invite"
          style={{
            maxWidth: 380,
          }}
        />
      </div>
      <div>
        <Select
          value={access}
          onChange={(value) => setAccess(value)}
          className={cx("access-selector")}
          style={{ marginRight: 8 }}
        >
          {accessOptions.map(({ value, text }) => (
            <Select.Option key={value} value={value}>
              {text}
            </Select.Option>
          ))}
        </Select>
        <Button
          style={{
            backgroundColor: "#00233f",
            color: "white",
            border: "none",
            textTransform: "uppercase",
            borderRadius: 4,
            width: 150,
          }}
          onClick={onInviteClick}
        >
          Invite
        </Button>
      </div>
    </div>
  );
};

const Settings = ({
  usedForClient,
  profile,
  handleEditProfile,
  updateAccountName,
}) => {
  console.log(usedForClient, profile, handleEditProfile, updateAccountName);
  const [modal, setModal] = useState(false);
  const [form] = Form.useForm();
  const [localAccountName, setLocalAccountName] = useState(null);
  const [selectedOption, setSelectedOption] = useState(profile?.paymentMethod);
  const [localPaymentId, setLocalPaymentId] = useState(profile?.paymentId);
  const [clientToDelete, setClientToDelete] = useState("");
  const [clientToDeleteIsTemp, setClientToDeleteIsTemp] = useState(false);
  const [clientNameToDelete, setClientNameToDelete] = useState("");
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [users, setUsers] = useState([]);
  const accessLevel = profile?.accessLevel;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 450px)" });

  useEffect(() => {
    document.body.setAttribute("data-new-theme", true);

    return () => {
      document.body.removeAttribute("data-new-theme");
    };
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      localAccountName: profile?.account?.accountName,
    });
    setLocalAccountName(profile?.account?.accountName);
  }, [profile?.account?.accountName]);

  useEffect(() => {
    if (profile?.paymentId) {
      form.setFieldsValue({
        paymentId: profile.paymentId,
      });
      setLocalPaymentId(profile.paymentId);
    }
  }, [profile?.paymentId]);

  useEffect(() => {
    if (usedForClient && profile?.account?._id) {
      (async () => {
        const fetchedUsers = await fetchUsers(profile.account._id);
        setUsers(fetchedUsers);
      })();
    }
  }, [usedForClient, accessLevel, profile?.account?._id]);

  const onSettingsChange = (newValues) => {
    // onChange({
    //   ...value,
    //   ...newValues,
    // });
  };

  const onRadioChange = (e) => {
    setSelectedOption(e.target.value);
    setLocalPaymentId("");
    form.setFieldsValue({
      paymentId: "",
    });
    onSettingsChange({ method: e.target.value });
  };

  const onInputChange = (e) => {
    setLocalPaymentId(e.target.value);
    onSettingsChange({ email: e.target.value });
  };

  const radioOptionStyle = {
    fontSize: isTabletOrMobile ? 12 : 16,
    color: "#004479",
  };

  const onFinish = async (values) => {
    if (profile?.account?.accountName !== localAccountName) {
      if (!localAccountName) {
        notify("error", "Error", "Account name cannot be empty");
        return;
      }
      await updateAccountName(localAccountName);
    }
    const dupValues = { ...values };
    if (dupValues.localAccountName) {
      delete dupValues.localAccountName;
    }

    handleEditProfile(dupValues, profile.adminQuestionsAnswers);
  };

  const sendInvitation = async (email, accessLevel) => {
    let inviter = "";
    if (profile.firstName) {
      inviter += profile.firstName;
      if (profile.lastName) {
        inviter += " " + profile.lastName;
      }
    } else if (profile.email) {
      inviter += profile.email;
    }

    const accountId = profile.account._id;
    const clientId = localStorage.getItem("client");
    const { data } = await axios({
      ...Routes.client.inviteClient(clientId),
      data: {
        data: {
          inviter,
          accountId,
          email,
          accessLevel,
        },
      },
    });
    if (data.success) {
      notify("success", "Success", data.message);
      const fetchedUsers = await fetchUsers(profile.account._id);
      setUsers(fetchedUsers);
    } else {
      notify("error", "Error", data.message);
    }
  };

  const editAccess = async (clientId, accessLevel, pending) => {
    const accountId = profile.account._id;
    const id = localStorage.getItem("client");
    const { data } = await axios({
      ...Routes.client.editAccess(id),
      data: { data: { accountId, clientId, accessLevel, pending } },
    });
    const fetchedUsers = await fetchUsers(accountId);
    setUsers(fetchedUsers);
    notify("success", "Success", data.message);
  };

  const closeDeleteModal = () => {
    setClientNameToDelete("");
    setClientToDelete("");
    setRemoveUserModal(false);
  };

  const removeUser = async () => {
    const id = localStorage.getItem("client");
    const { data } = await axios({
      ...Routes.client.removeClient(id),
      data: {
        data: { clientId: clientToDelete, pending: clientToDeleteIsTemp },
      },
    });
    notify("success", "Success", data.message);
    closeDeleteModal();
    const fetchedUsers = await fetchUsers(profile.account._id);
    setUsers(fetchedUsers);
  };

  return (
    <React.Fragment>
      {!usedForClient && (
        <>
          <Topbar>
            <Sidenav drawer selectedItem={userPages.SETTINGS} />
          </Topbar>
          <Sidenav selectedItem={userPages.SETTINGS} />
        </>
      )}
      <ProjectPageLayout>
        <Content>
          <InnerContainer style={{ padding: 32 }}>
            <Form form={form} initialValues={profile} onFinish={onFinish}>
              <div className={cx("settings")}>
                <h3
                  style={{
                    marginBottom: 0,
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  Account Details
                </h3>
                <div style={{ fontSize: 14, marginBottom: 20 }}>
                  This information will only be shared with us.
                </div>
                <div>
                  {usedForClient && (
                    <>
                      <div style={{ fontSize: "90%", marginBottom: "0.2em" }}>
                        Account Name
                      </div>
                      <div style={{ marginBottom: 20 }}>
                        {accessLevel === ACCESS_LEVEL.admin ? (
                          <Form.Item
                            name="localAccountName"
                            rules={[
                              {
                                required: true,
                                message: "Account name cannot be empty!",
                              },
                            ]}
                            initialValue={localAccountName}
                          >
                            <Input
                              value={localAccountName}
                              onChange={(e) =>
                                setLocalAccountName(e.target.value)
                              }
                              placeholder="Account name"
                              className={cx("input-box")}
                            />
                          </Form.Item>
                        ) : (
                          <div className={cx("input-box")}>
                            {localAccountName}
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  {usedForClient &&
                  profile?.accessLevel === ACCESS_LEVEL.admin ? (
                    <div>
                      <div className={cx("settings")}>
                        <div style={{ fontSize: "90%", marginBottom: "0.2em" }}>
                          Plan
                        </div>
                        <div style={{ fontSize: "70%", marginBottom: "0.6em" }}>
                          (What monthly plan are you on?)
                        </div>

                        <div>
                          <Radio.Group
                            style={{ width: "100%" }}
                            className="radioButton"
                            onChange={(e) => {
                              setModal(true);
                            }}
                            value={
                              plans[
                                profile?.account?.accountTier ??
                                  profile?.accountTier
                              ]?.value
                            }
                          >
                            {plans.map(({ value, text }) => (
                              <GreenRadio
                                value={value}
                                className={cx("option")}
                                key={value}
                              >
                                {text}
                              </GreenRadio>
                            ))}
                          </Radio.Group>
                        </div>
                      </div>

                      <div className={cx("modal-container")}>
                        <CustomModal
                          visible={modal}
                          onCancel={() => {
                            setModal(false);
                          }}
                          style={{
                            height: 500,
                          }}
                          closeIcon={
                            <CloseCircleOutlined
                              style={{
                                color: "#004479",
                                fontSize: "24px",
                                position: "relative",
                                top: "calc(-50% + 23px)",
                                right: "calc(-50% + 18px)",
                              }}
                            />
                          }
                          footer={
                            <Button
                              type="primary"
                              onClick={() => {
                                setModal(false);
                              }}
                            >
                              OK
                            </Button>
                          }
                        >
                          <div style={{ textAlign: "center" }}>
                            Please contact hello@userqual.com to change plan
                            type
                          </div>
                        </CustomModal>
                      </div>
                    </div>
                  ) : null}
                  {!usedForClient && (
                    <>
                      <div className={cx("settings")}>
                        <div style={{ fontWeight: "bold", fontSize: "0.9rem" }}>
                          Payment Settings
                        </div>
                        <div style={{ fontSize: "0.85rem" }}>
                          How should we pay you?
                        </div>
                        <div>
                          <div style={{ paddingTop: "12px" }}>
                            <div
                              style={{
                                marginBottom: 20,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Form.Item name="paymentMethod" noStyle>
                                <Radio.Group
                                  style={{ width: "100%" }}
                                  className="radioButton"
                                  onChange={onRadioChange}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      columnGap: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor: "#EEE",
                                        padding: "6px 10px 6px 8px",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <Radio value="paypal">Paypal</Radio>
                                    </div>
                                    <div
                                      style={{
                                        backgroundColor: "#EEE",
                                        padding: "7px 10px 7px 8px",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <Radio value="paytm">Paytm</Radio>
                                    </div>
                                    <div
                                      style={{
                                        backgroundColor: "#EEE",
                                        padding: "7px 10px 7px 8px",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <Radio value="googlePay">GooglePay</Radio>
                                    </div>
                                  </div>
                                </Radio.Group>
                              </Form.Item>

                              <Form.Item
                                name="paymentId"
                                initialValue={localPaymentId}
                                noStyle
                              >
                                <div
                                  style={{
                                    paddingTop: "15px",
                                    width: "355px",
                                  }}
                                  className="paymentInput"
                                >
                                  {selectedOption === "paypal" ? (
                                    <Input
                                      value={localPaymentId}
                                      placeholder="Paypal ID"
                                      style={{ maxWidth: 400 }}
                                      onChange={onInputChange}
                                    />
                                  ) : selectedOption === "paytm" ? (
                                    <Input
                                      value={localPaymentId}
                                      placeholder="Paytm ID"
                                      style={{ maxWidth: 400 }}
                                      onChange={onInputChange}
                                    />
                                  ) : selectedOption === "googlePay" ? (
                                    <Input
                                      value={localPaymentId}
                                      placeholder="GooglePay ID"
                                      style={{ maxWidth: 400 }}
                                      onChange={onInputChange}
                                    />
                                  ) : null}
                                </div>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={cx("settings")}>
                        <div style={{ fontWeight: "bold", fontSize: "0.9rem" }}>
                          Devices
                        </div>
                        <div style={{ fontSize: "0.85rem" }}>
                          What devices would you fill your form in?
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          <Form.Item name="devices" noStyle>
                            <Checkbox.Group
                              className="contact_method"
                              style={{ fontSize: 16 }}
                            >
                              <Checkbox
                                className={cx("option")}
                                value="desktop"
                              >
                                Desktop/Laptop
                              </Checkbox>
                              <Checkbox className={cx("option")} value="ios">
                                iPhone/iPad
                              </Checkbox>
                              <Checkbox
                                className={cx("option")}
                                value="android"
                              >
                                Android Phone
                              </Checkbox>
                            </Checkbox.Group>
                          </Form.Item>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {((usedForClient && accessLevel === ACCESS_LEVEL.admin) ||
                  !usedForClient) && (
                  <div className={cx("settings")}>
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      style={{
                        marginTop: 10,
                        width: "25%",
                        backgroundColor: "#00233f",
                        // marginLeft: "80%",
                        float: "right",
                        borderColor: "#00233f",
                        borderRadius: 4,
                      }}
                    >
                      Save
                    </Button>
                    <br style={{ clear: "both" }} />
                  </div>
                )}
              </div>
            </Form>
          </InnerContainer>
          {usedForClient && accessLevel === ACCESS_LEVEL.admin && (
            <InnerContainer style={{ padding: 32, marginTop: 32 }}>
              <div className={cx("settings")}>
                <h3 style={{ marginBottom: 0 }}>Manage Team</h3>
                <div style={{ fontSize: 14, marginBottom: 20 }}>
                  Invite new user from your organization
                </div>
                <InviteUser inviteUser={sendInvitation} />
                <div style={{ marginTop: 20 }}>
                  <div style={{ fontSize: "90%", marginBottom: "0.2em" }}>
                    Manage users
                  </div>
                  {users.map((user) => {
                    let initial = "";
                    let name = "";
                    if (user.firstName) {
                      initial += user.firstName[0].toUpperCase();
                      name += user.firstName;
                      if (user.lastName) {
                        initial += user.lastName[0].toUpperCase();
                        name += ` ${user.lastName}`;
                      }
                    } else if (user.email) {
                      initial += user.email[0].toUpperCase();
                      name += user.email;
                    } else {
                      initial = "??";
                      name = user.mobileNumber;
                    }
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: 12,
                          flexWrap: "wrap",
                          paddingRight: 8,
                          columnGap: 8,
                          rowGap: 8,
                        }}
                        key={user.clientId}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexGrow: 1,
                          }}
                        >
                          <span
                            style={{
                              display: "inline-flex",
                              width: 32,
                              height: 32,
                              borderRadius: "50%",
                              backgroundColor: "#01223f",
                              color: "white",
                              marginRight: 12,
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: 12,
                            }}
                          >
                            {initial}
                          </span>
                          <span style={{ fontSize: 16 }}>{name}</span>
                          {user.pending && (
                            <span
                              style={{
                                fontSize: 12,
                                marginLeft: 12,
                                backgroundColor: "#c2c2c2",
                                padding: "0 8px",
                                borderRadius: 4,
                                color: "white",
                              }}
                            >
                              Invited
                            </span>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: 8,
                          }}
                        >
                          <Select
                            value={user.accessLevel}
                            onChange={(value) => {
                              editAccess(user.clientId, value, user.pending);
                            }}
                            className={cx("access-selector")}
                          >
                            {accessOptions.map(({ value, text }) => (
                              <Select.Option key={value} value={value}>
                                {text}
                              </Select.Option>
                            ))}
                          </Select>

                          <Button
                            style={{
                              backgroundColor: "#00233f",
                              color: "white",
                              border: "none",
                              textTransform: "uppercase",
                              borderRadius: 4,
                              width: 150,
                            }}
                            onClick={() => {
                              setClientToDeleteIsTemp(user.pending);
                              setClientToDelete(user.clientId);
                              setClientNameToDelete(name);
                              setRemoveUserModal(true);
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </InnerContainer>
          )}

          {usedForClient && accessLevel !== ACCESS_LEVEL.admin && (
            <InnerContainer style={{ padding: 32, marginTop: 32 }}>
              <div className={cx("settings")}>
                <h3 style={{ marginBottom: 0 }}>Team</h3>
                <div style={{ marginTop: 20 }}>
                  {users.map((user) => {
                    let initial = "";
                    let name = "";
                    let access = `${capitalizeFirstCharacter(
                      user.accessLevel
                    )} Access`;
                    if (user.firstName) {
                      initial += user.firstName[0].toUpperCase();
                      name += user.firstName;
                      if (user.lastName) {
                        initial += user.lastName[0].toUpperCase();
                        name += ` ${user.lastName}`;
                      }
                    } else if (user.email) {
                      initial += user.email[0].toUpperCase();
                      name += user.email;
                    } else {
                      initial = "??";
                      name = user.mobileNumber;
                    }
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: 12,
                          flexWrap: "wrap",
                          paddingRight: 8,
                          columnGap: 8,
                          rowGap: 8,
                        }}
                        key={user.clientId}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexGrow: 1,
                          }}
                        >
                          <span
                            style={{
                              display: "inline-flex",
                              width: 32,
                              height: 32,
                              borderRadius: "50%",
                              backgroundColor: "#01223f",
                              color: "white",
                              marginRight: 12,
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: 12,
                            }}
                          >
                            {initial}
                          </span>
                          <span style={{ fontSize: 16 }}>{name}</span>
                          {user.pending && (
                            <span
                              style={{
                                fontSize: 12,
                                marginLeft: 12,
                                backgroundColor: "#c2c2c2",
                                padding: "0 8px",
                                borderRadius: 4,
                                color: "white",
                              }}
                            >
                              Invited
                            </span>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: 8,
                          }}
                        >
                          {access}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </InnerContainer>
          )}
        </Content>
      </ProjectPageLayout>
      <Modal
        visible={removeUserModal}
        closable={false}
        footer={null}
        onCancel={closeDeleteModal}
      >
        <div
          style={{
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Remove team member
        </div>
        <p>Are you sure you want to remove {clientNameToDelete}?</p>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            columnGap: 12,
            paddingBottom: 24,
          }}
        >
          <Button
            style={{
              backgroundColor: "#aaaaaa",
              color: "white",
              border: "none",
              textTransform: "uppercase",
              borderRadius: 4,
              width: 150,
            }}
            onClick={closeDeleteModal}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#00233f",
              color: "white",
              border: "none",
              textTransform: "uppercase",
              borderRadius: 4,
              width: 150,
            }}
            onClick={removeUser}
          >
            Remove
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Settings;
