import React from "react";
import Ratings from "react-ratings-declarative";
import styles from "./StarRating.module.css";
import c from "classnames/bind";
import { StarFilled, SmileFilled, HeartFilled } from "@ant-design/icons";

const cx = c.bind(styles);

const StarRatingElement = ({
  totalStars,
  ratings,
  label,
  starType,
  ratingLabel,
}) => {
  const getCharacter = () => {
    switch (starType) {
      case "star":
        return <StarFilled className="character" />;
      case "smiley":
        return <SmileFilled className="character" />;
      case "heart":
        return <HeartFilled className="character" />;
      default:
        return;
    }
  };
  return (
    <div className={cx(["star-rating-container"])}>
      <h3>{label}</h3>
      <h1 style={{ fontSize: 40 }}>
        {ratingLabel}
        {starType === "star" ? (
          <StarFilled
            style={{ fontSize: 35, color: "rgb(76, 194, 148)", marginLeft: 5 }}
          />
        ) : starType === "smiley" ? (
          <SmileFilled
            style={{ fontSize: 35, color: "rgb(76, 194, 148)", marginLeft: 5 }}
          />
        ) : starType === "heart" ? (
          <HeartFilled
            style={{ fontSize: 35, color: "rgb(76, 194, 148)", marginLeft: 5 }}
          />
        ) : null}
      </h1>
      <Ratings
        rating={ratings}
        starRatedColor="blue"
        numberOfStars={6}
        name="rating"
        widgetRatedColors="rgb(76,194,148)"
        widgetDimensions="25px"
        widgetSpacings="2px"
      >
        {[...new Array(totalStars)].map((v, i) => {
          return <Ratings.Widget svg={getCharacter()} key={i} />;
        })}
      </Ratings>
    </div>
  );
};

export default StarRatingElement;
