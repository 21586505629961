import React, { useState } from "react";
import { Fragment } from "react";
import ReactPlayer from "react-player";
import Modal from "react-responsive-modal";

export const Vmodal = ({ isOpen, onCancel, videoUrl }) => {
  console.log("video", videoUrl);
  return (
    <Modal
      open={isOpen}
      onClose={() => onCancel()}
      styles={{
        modal: {
          maxWidth: "unset",
          width: "auto",
          padding: 45,
          position: "fixed",
          top: "10%",
          left: "25%",
        },
        overlay: {
          background: "rgba(0, 0, 0, 0.5)",
          position: "fixed",
          height: "100%",
          width: "100%",
          top: 0,
        },
        closeButton: {
          background: "#40E0D0",
          position: "absolute",
          top: 0,
          right: 0,
        },
      }}
      center
    >
      <ReactPlayer
        playing={true}
        url={videoUrl}
        width="auto"
        background="black"
        controls={true}
      />
    </Modal>
  );
};

const VideoModal = (url) => {
  const [videoModal, setVideoModal] = useState(false);
  return (
    url.url && (
      <Fragment>
        <video
          src={url.url}
          onClick={() => setVideoModal(true)}
          style={{
            display: "inline",
            verticalAlign: "middle",
            borderStyle: "none",
          }}
        />
        {videoModal && (
          <Vmodal
            videoUrl={url.url}
            isOpen={videoModal}
            onCancel={() => setVideoModal(false)}
          />
        )}
      </Fragment>
    )
  );
};

export default VideoModal;
