import React, { useEffect, useState } from "react";
import { Button, Input, notification, Select } from "antd";
import { Option } from "antd/lib/mentions";

import { darkBlueButtonStyle } from "../../../constants/styles";
import styles from "./AddParticipantToPanel.module.css";
import c from "classnames/bind";
import {
  getClientId,
  getRoleByToken,
  incrementPendingRequests,
} from "../../../../../../utils";
import axios, { Routes } from "../../../../../../services/API";
import handleNetworkErrors from "../../../../handleNetworkErrors";

const cx = c.bind(styles);

const AddParticipantToPanel = ({ userId }) => {
  const [open, setOpen] = useState(false);
  const [createPanelBox, setCreatePanelBox] = useState(false);
  const [newPanelName, setNewPanelName] = useState("");
  const [panels, setPanels] = useState(null);

  const clientId = getClientId();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios({
          ...Routes.client.getPanels(clientId),
        });
        setPanels(data.panels);
        console.log("service call", data);
      } catch (err) {
        handleNetworkErrors(err);
      }
    })();
  }, [userId]);

  const createPanel = async (panelName) => {
    try {
      const { data } = await axios({
        ...Routes.client.createPanel(clientId),
        data: {
          data: {
            name: panelName,
          },
        },
      });
      notification.success({
        message: "Panel Created Successfully with name: " + panelName,
      });
      setPanels([...panels, data.panels]);
      setCreatePanelBox(false);
    } catch (err) {
      setCreatePanelBox(false);
      handleNetworkErrors(err);
    }
  };

  const addUserToPanel = async (panelId) => {
    try {
      const { data } = await axios({
        ...Routes.client.addUserToPanel(clientId, panelId),
        data: {
          data: {
            userId: userId,
          },
        },
      });
      notification.success({ message: "user is added to panel" });
    } catch (err) {
      handleNetworkErrors(err);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Button
        style={{
          ...darkBlueButtonStyle,
          backgroundColor: "#B9E8F6",
          color: "black",
          border: "none",
          marginRight: 0,
        }}
        onClick={() => {
          setOpen(!open);
        }}
      >
        Add to Panel
      </Button>
      <Select
        open={open}
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "0px",
        }}
        onChange={(panelId) => {
          setOpen(false);
          addUserToPanel(panelId);
        }}
        dropdownRender={(menu) => {
          return (
            <div>
              {!createPanelBox && (
                <div
                  onClick={() => setCreatePanelBox(!createPanelBox)}
                  className={cx("new-panel-option")}
                >
                  + New Panel
                </div>
              )}
              {createPanelBox && (
                <div className={cx("new-panel-box")}>
                  <div>
                    <Input
                      value={newPanelName}
                      onChange={(e) => setNewPanelName(e.target.value)}
                      placeholder="Panel Name"
                    />
                  </div>
                  <div>
                    <Button
                      className={cx("button")}
                      style={{ background: "#88CDD5" }}
                      onClick={() => createPanel(newPanelName)}
                    >
                      Create
                    </Button>
                    <Button
                      className={cx("button")}
                      style={{
                        background: "white",
                        border: "1px solid #0A223D",
                        color: "#7F7F7F",
                      }}
                      onClick={() => setCreatePanelBox(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
              {menu}
            </div>
          );
        }}
      >
        {panels &&
          panels.map((panel, index) => (
            <Option value={panel._id} key={index}>
              {panel.name}
            </Option>
          ))}
      </Select>
    </div>
  );
};

export default AddParticipantToPanel;
